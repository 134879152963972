import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Agent, Categories, Category } from "@shared/models/agent";
import { FileUpload } from "@shared/models/file-upload.model";
import { FilesService } from "@services/files/files.service";
import { KmdKeyValueOption } from "@shared/models/kmd-key-value-option";
import { AskOurDocsService } from "@services/ask-our-docs/ask-our-docs.service";

@Component({
  selector: 'app-agent-form',
  templateUrl: './agent-form.component.html',
  styleUrls: ['./agent-form.component.css']
})
export class AgentFormComponent implements OnChanges {
  @Input() agent?: Agent;
  @Input() instructionsTemplate = '';
  @Output() formStatusChanged = new EventEmitter<boolean>()
  @Output() onSubmitValidForm = new EventEmitter<Agent>()

  categories = [...Categories];
  files: FileUpload[] = [];
  selectedFiles: FileUpload[] = [];
  repositories: KmdKeyValueOption[] = [];
  selectedRepositories: KmdKeyValueOption[] = [];
  showAODTool = true;

  public agentForm = new FormGroup({
    id: new FormControl(this.agent?.id, { nonNullable: true }),
    name: new FormControl('', { nonNullable: true, validators: [Validators.pattern('^\\s*\\S+.*$')] }),
    description: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    conversationStarters: new FormArray([
      new FormControl('', { nonNullable: true })
    ]),
    instructions: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    category: new FormControl<Category>('', { nonNullable: true, validators: [Validators.required] }),
    capabilities: new FormArray<FormControl>([]),
    files: new FormArray<FormControl>([]),
    aodRepositories: new FormArray<FormControl>([])
  })

  constructor(private filesService: FilesService, private askOurDocsService: AskOurDocsService) {
    this.agentForm.statusChanges.subscribe((valid) => {
      let validAndChanged = valid == 'VALID' && this.isValidAskMyDocs() && this.isValidAskOurDocs() && (!this.agent?.id || !this.isSameObject());
      this.formStatusChanged.emit(validAndChanged)
    });
  }

  ngOnChanges(): void {
    this.agentForm.patchValue(this.agent!);

    if (this.agent?.id && this.isAgentPublicAndPublishedByUser())
      this.agentForm.controls.name.disable();

    if (!this.agent?.id && !this.agent?.instructions) {
      this.agentForm.patchValue({ instructions: this.instructionsTemplate });
    }

    this.filesService.list('.txt,.docx,.doc,.pdf').subscribe(files => {
      this.files = files
      this.selectedFiles = this.files.filter(file => this.agent?.files?.includes(file.id!))
    });

    this.askOurDocsService.roleRepositories().subscribe(repositories => {
      if (repositories.length === 0) {
        this.showAODTool = false;
      }

      this.repositories = repositories;
      this.selectedRepositories = this.repositories.filter(repository => this.agent?.aodRepositories?.includes(repository.id))
    })

    this.agent?.files?.forEach((fileId: string) => {
      this.agentForm.controls.files.push(new FormControl(fileId))
    });

    this.agent?.aodRepositories?.forEach((repository: string) => {
      this.agentForm.controls.aodRepositories.push(new FormControl(repository))
    });

    if (this.agent?.conversationStarters && this.agent?.conversationStarters.length > 0) {
      this.agentForm.controls.conversationStarters.controls[0].patchValue(this.agent?.conversationStarters[0]);
    }

    this.agentForm.controls.capabilities.clear()
    this.agentForm.controls.files.clear()
    this.agentForm.controls.aodRepositories.clear()

    if (this.agent?.capabilities?.includes('WEBSEARCH')) {
      this.agentForm.controls.capabilities.push(new FormControl('WEBSEARCH'))
    }

    if (this.agent?.capabilities?.includes('ASK_MY_DOCS')) {
      this.agentForm.controls.capabilities.push(new FormControl('ASK_MY_DOCS'))
    }

    if (this.agent?.capabilities?.includes('ASK_OUR_DOCS')) {
      this.agentForm.controls.capabilities.push(new FormControl('ASK_OUR_DOCS'))
    }

    this.agent?.files?.forEach((fileId: string) => {
      this.agentForm.controls.files.push(new FormControl(fileId))
    })

    this.agent?.aodRepositories?.forEach((repository: string) => {
      this.agentForm.controls.aodRepositories.push(new FormControl(repository))
    })
  }

  submit() {
    if (this.agentForm.valid) {
      let rawValue = this.agentForm.getRawValue();
      rawValue.name = rawValue.name.trim();
      this.onSubmitValidForm.emit({ ...this.agent, ...rawValue });
    }
  }

  switchToggle() {
    if (!this.webSearchEnabled()) {
      this.agentForm.controls.capabilities.push(new FormControl('WEBSEARCH'))
    } else {
      let number = this.agentForm.controls.capabilities.value.indexOf('WEBSEARCH');
      this.agentForm.controls.capabilities.removeAt(number);
    }
  }

  webSearchEnabled() {
    return this.agentForm.controls.capabilities.value.includes('WEBSEARCH')
  }

  isAskMyDocsEnabled() {
    return this.agentForm.controls.capabilities.value.includes('ASK_MY_DOCS')
  }

  isAskOurDocsEnabled() {
    return this.agentForm.controls.capabilities.value.includes('ASK_OUR_DOCS')
  }

  isValidAskMyDocs() {
    if (this.isAskMyDocsEnabled()) {
      return this.agentForm.getRawValue().files.length > 0
    } else {
      return true
    }
  }

  isValidAskOurDocs() {
    return !this.isAskOurDocsEnabled() || this.agentForm.getRawValue().aodRepositories.length > 0;
  }

  fileSelectionChange($event: any) {
    this.agentForm.controls.files.clear();
    $event.selectedOptions.forEach((file: FileUpload) => {
      this.agentForm.controls.files.push(new FormControl(file.id!))
    });
  }

  repositorySelectionChange($event: any) {
    if ($event.selectedOptions.length > 1) {
      $event.selectedOptions.shift();
      this.selectedRepositories = $event.selectedOptions;
    }

    this.agentForm.controls.aodRepositories.clear();
    this.selectedRepositories.forEach((repository: KmdKeyValueOption) => {
      this.agentForm.controls.aodRepositories.push(new FormControl(repository.id))
    });
  }

  toggleAskMyDocs() {
    if (!this.isAskMyDocsEnabled()) {
      this.agentForm.controls.capabilities.push(new FormControl('ASK_MY_DOCS'))
      this.agent?.files?.forEach((fileId: string) => {
        this.agentForm.controls.files.push(new FormControl(fileId))
      })
    } else {
      let number = this.agentForm.controls.capabilities.value.indexOf('ASK_MY_DOCS');
      this.agentForm.controls.capabilities.removeAt(number);
      this.agentForm.controls.files.clear()
      this.selectedFiles = [];
    }
  }

  toggleAskOurDocs() {
    if (!this.isAskOurDocsEnabled()) {
      this.agentForm.controls.capabilities.push(new FormControl('ASK_OUR_DOCS'))
      this.agent?.aodRepositories?.forEach((repository: string) => {
        this.agentForm.controls.aodRepositories.push(new FormControl(repository))
      })
    } else {
      let number = this.agentForm.controls.capabilities.value.indexOf('ASK_OUR_DOCS');
      this.agentForm.controls.capabilities.removeAt(number);
      this.agentForm.controls.aodRepositories.clear()
      this.selectedRepositories = [];
    }
  }

  private isSameObject() {
    let sameObject = true;
    if (this.agent?.name.trim() !== this.agentForm.getRawValue().name?.trim()) {
      sameObject = false
    }
    if (this.agent?.description !== this.agentForm.value.description) {
      sameObject = false
    }
    if (this.agent?.instructions !== this.agentForm.value.instructions) {
      sameObject = false
    }
    if (this.agent?.category !== this.agentForm.value.category) {
      sameObject = false
    }
    if (this.agent?.conversationStarters && (
      this.agent.conversationStarters[0] != this.agentForm.getRawValue().conversationStarters[0] && (
        this.agent.conversationStarters?.length > 0 || this.agentForm.getRawValue().conversationStarters[0] !== ''))) {
      sameObject = false
    }
    this.agentForm.value.capabilities?.forEach((capability: string) => {
      if (!this.agent?.capabilities?.includes(capability)) {
        sameObject = false
      }
    });

    let originalFiles = this.agent?.files;
    let newFiles = this.agentForm.getRawValue().files;

    if (!this.isEqual(originalFiles, newFiles)) {
      sameObject = false;
    }

    let originalRepositories = this.agent?.aodRepositories;
    let newRepositories = this.agentForm.getRawValue().aodRepositories;

    if (!this.isEqual(originalRepositories, newRepositories)) {
      sameObject = false;
    }

    let originalCapabilities = this.agent?.capabilities
    let newCapabilities = this.agentForm.getRawValue().capabilities;

    if (!this.isEqual(originalCapabilities, newCapabilities)) {
      sameObject = false;
    }
    return sameObject;
  }

  storeFormValues(): Agent {
    return this.agentForm.getRawValue();
  }

  private isEqual(a: any, b: any) {
    return JSON.stringify(a?.sort()) === JSON.stringify(b.sort());
  }

  protected isAgentPublicAndPublishedByUser() {
    if (this.agent && this.agent.id)
      return this.agent.isPublic === true && this.agent.publishedByUser === true;
    return false;
  }
}
