export type FileUploadType = {
  fileType: string;
  acceptedFileTypes: string;
  extensions: string[];
  filesInstructions: string;
  mobileFileInstructions: string[];
  filesText: string;
  maxFiles: number;
};

export const FileUploadOptions: {
  [key: string]: FileUploadType;
} = {
  TEXT: {
    fileType: 'text',
    acceptedFileTypes: '.txt, .docx, .pdf',
    extensions: [
      '.txt',
      '.docx',
      '.doc',
      '.pdf'
    ],
    filesInstructions: 'Max 5 files at a time | Max ~ 1000 pages/file',
    mobileFileInstructions: [
      'Max 5 files at a time',
      'Max ~ 1000 pages/file'
    ],
    filesText: 'Text file type(s): .txt, .docx, .pdf',
    maxFiles: 5
  },
  SPREADSHEETS: {
    fileType: 'spreadsheets',
    acceptedFileTypes: '.csv, .xlsx, .xls',
    extensions: [
      '.csv',
      '.xlsx',
      '.xls'
    ],
    filesInstructions: 'Data file type: .xlsx Only one file at a time | Max 15 MB',
    mobileFileInstructions: [
      'Data file type: .xlsx Only one file at a time | Max 15 MB'
    ],
    filesText: 'Data file type: .csv Only one file at a time | Max 80 MB',
    maxFiles: 1
  }
};

export const FileTypes: { [key: string]: any } = {
  TEXT: {
    TXT: 'TXT',
    DOCX: 'DOCX',
    PDF: 'PDF',
    DOC: 'DOC'
  },
  SPREADSHEETS: {
    XLSX: 'XLSX',
    XLS: 'XLS',
    CSV: 'CSV'
  }
};

