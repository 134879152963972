import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { slideUpDownAnimation } from '@shared/animations/slide-up-down.animation';
import { ContextMenuItem, ContextMenuSlideDirection } from './context-menu';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css'],
  animations: [slideUpDownAnimation]
})
export class ContextMenuComponent implements OnInit {
  @Input() items: ContextMenuItem[] = [];
  @Input() defaultSelectedItem?: ContextMenuItem | null = null;
  @Input() slideDirection: ContextMenuSlideDirection = 'up-to-down';
  @Output() itemClicked = new EventEmitter<ContextMenuItem>();
  @Output() isContextMenuOpen = new EventEmitter<boolean>();

  selectedItem: ContextMenuItem | null = null;
  isMenuOpen = false;

  get containerClasses() {
    return {
      'disabled': !this.isMenuOpen,
      'down-to-up-context-menu': this.slideDirection === 'down-to-up',
    };
  }


  ngOnInit(): void {
    if (this.defaultSelectedItem) {
      this.selectedItem = this.defaultSelectedItem;
    }
  }

  onItemClick(item: ContextMenuItem) {
    this.selectedItem = item;
    this.toggleMenu();
    this.itemClicked.emit(item);
  }

  getAnimationState() {
    if (this.slideDirection === 'up-to-down') {
      return this.isMenuOpen ? 'openUpToDown' : 'closedUpToDown';
    } else {
      return this.isMenuOpen ? 'openDownToUp' : 'closedDownToUp';
    }
  }

  itemHasIcon(item: ContextMenuItem) {
    return item.imageUrl !== undefined;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.isContextMenuOpen.emit(this.isMenuOpen);
  }

  closeMenu() {
    this.isMenuOpen = false;
    this.isContextMenuOpen.emit(false);
  }
}
