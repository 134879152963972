<kmd-popover #contextMenuPopover id="user-context-menu" position='left' popoverPosition='bottom'>
  <div class="context-pop-over-container">
    <div class="context-pop-over-option" (click)="redirect('/about')">
      <span class="text">About Gene.AI</span>
    </div>
    <div class="context-pop-over-option" (click)="redirect('/guidelines')">
      <span class="text">Gene.AI guidelines</span>
    </div>
    <div class="context-pop-over-option flyout" (click)="openInnovationHub()">
      <span class="text">Gen AI Innovation Hub<i></i></span>
    </div>
    <div class="context-pop-over-option flyout" (click)="openRaiPolicy()">
      <span class="text">RAI policy<i></i></span>
    </div>
    <div class="context-pop-over-option flyout" (click)="submitFeedback()">
      <span class="text">Submit feedback<i></i></span>
    </div>
    <div class="context-pop-over-option" (click)="signOut()">
      <span class="text">Sign out</span>
    </div>
  </div>
</kmd-popover>
