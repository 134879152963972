import { Model } from "@app/shared/models/prompt-settings.model";
import { ContextMenuItem } from "../context-menu/context-menu";
import { modelMap } from "../prompt-settings/prompt-settings.data";
import { defaultFileManagerConfig, FileManagerConfig } from "../file-manager/file-manager";

export type PromptInputConfig = {
  features: {
    addFiles: boolean;
    changeModel: boolean;
  },
  display: {
    promptSettings: boolean;
  }
  fileManagerConfig: FileManagerConfig
}

export const defaultPromptInputConfig: PromptInputConfig = {
  features: {
    addFiles: true,
    changeModel: true
  },
  display: {
    promptSettings: true
  },
  fileManagerConfig: defaultFileManagerConfig
}

export type SubmitButtonStatus = 'inactive' | 'active' | 'stop';
export type SubmitButtonState = {
  status: SubmitButtonStatus;
  url: string;
  cssClass: string;
  onClick: () => void;
}
export type SubmitButtonStates = Map<SubmitButtonStatus, SubmitButtonState>;
export type SubmitActions = { [key in SubmitButtonStatus]: () => void };

export const getSubmitButtonStates = (
  actions: SubmitActions
): Map<SubmitButtonStatus, SubmitButtonState> => {
  return new Map([
    [
      'inactive', {
        status: 'inactive',
        url: '../../../../assets/icons/send/Send-Inactive.svg',
        cssClass: 'inactive',
        onClick: actions.inactive
      }
    ],
    [
      'active', {
        status: 'active',
        url: '../../../../assets/icons/send/Send-Active.svg',
        cssClass: '',
        onClick: actions.active
      }
    ],
    [
      'stop', {
        status: 'stop',
        url: '../../../../assets/icons/stop/stop-white.svg',
        cssClass: 'stop',
        onClick: actions.stop
      }
    ]
  ]);
}

export const defaultModel: Model = 'gpt-4-o';
export const failSafeMenuModel: ContextMenuItem = {
  id: defaultModel,
  label: modelMap.get(defaultModel)?.title || '',
  description: modelMap.get(defaultModel)?.description || '',
}
