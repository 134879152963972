export const AssistantMessageTypeConstant = {
  DEFAULT: {
    regenerateResponse: true,
    copyResponse: true,
    icon : 'assistant'
  },
  AGENT: {
    regenerateResponse: false,
    copyResponse: true,
    icon : 'agent'
  }
};
