<div class="container-maintenance">
  <div class="container-content">
    <img ngSrc="assets/images/maintenance.png" alt="maintenance img" height="117" width="118">
    <div class="announcement">
      <img ngSrc="assets/images/DNA.svg" alt="DNA ask gene logo" height="48" width="58">
      <h1>We'll be back soon</h1>
    </div>
    <p>Gene.AI is temporarily unavailable while we update the service.<br>
      We apologize for the inconvenience. Please try again later.</p>
  </div>
</div>
