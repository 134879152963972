import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutsideDrawer]'
})
export class ClickOutsideDrawerDirective {

  constructor(private elementRef: ElementRef) { }

  @Output() appClickOutsideDrawer: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const isExcludedElement = targetElement.closest('.exclude-from-drawer-directive') !== null;

    if (!clickedInside && !isExcludedElement) {
      this.appClickOutsideDrawer.emit();
    }
  }

}
