<div class="record-container" *ngIf="record">
  <div class="record-updated-section">{{ formatDate(record.updatedAt)}}</div>
  <div class="record-title-section">
    <span *ngIf="!editingEnabled">{{record.title}}</span>
    <textarea #titleTextarea class="textarea-title" *ngIf="editingEnabled" [(ngModel)]="inputValue" (blur)="stopEditing(undefined)"
              (keydown)="onKeyDown($event)" minlength="3"
              maxlength="120" rows="1"></textarea>
  </div>
  <div class="record-chat-section" (click)="startChat()">
    <img src="../assets/icons/chat/chat-mono.svg" class="img-icon menu-item-icon">
  </div>
  <fa-icon class="img-icon record-actions-section" [icon]="faEllipsis" (click)="showActionMenu($event)"></fa-icon>
</div>
<app-action-buttons #actionsMenu (editChatEvent)="onEditChat($event)" (deleteChatEvent)="onDeleteChat($event)" ></app-action-buttons>
<app-delete-modal #deleteModal  *ngIf="record" [headline]="'Delete Chat'"
                  [description]="'You\'re about to delete this chat, this action is permanent and you won\'t be able to recover it. Do you want to delete it?'"
                  (confirmDeleteEvent)="doDeleteChat(record)"></app-delete-modal>
