export const Messages = {
    GENERATE_SQL_ERROR: 'General SQL or query error, please re-write your prompt and submit again.',
    GENERATE_SQL_ERROR_NLQ: 'SQL generation error',
    GENERATE_SQL_PROMPT_ISSUE: 'Please rewrite your prompt',
    GENERATE_ERROR: 'We could not understand your request. Please rephrase your prompt or include specific terms related to the database you are inquiring about.',
    GENERATE_ERROR_NLQ: 'Rewrite your prompt or enter the relevant SQL query',
    NO_RESPONSE:  'No results or match was found for your query. Please try again with a different prompt. ',
    NO_ACTION_ALLOWED: 'This database is read-only, no changes or modifications are permitted.',
    UNAVAILABLE: 'This information is temporarily unavailable. Please try again at a later time.',
    DATABASE_VERIFIED: 'Your database connection has been verified. Please continue the configuration by connecting to the database.',
    UNABLE_TO_CONNECT_DATABASE: "We couldn't connect to the database. Please refresh the page or try again later.",
    UNABLE_TO_VERIFY_DATABASE: "We couldn't verify the database connection. Please verify your information and try again.",
    UNABLE_TO_GET_DATABASE: 'Unable to get database details. Please try again.',
    UNAUTHORIZED: 'You are not authorized to view this page',
    DDL_GENERATED: 'DDL generated successfully',
    UNABLE_TO_SAVE_TABLES: 'Unable to save the tables and generate DDL, please try again',
    UNABLE_TO_GET_DDL_STATUS: 'Unable to get DDL generation status, please try again',
    UNABLE_TO_GET_DDL: 'Unable to generate DDL, please try again',
    UNABLE_TO_TRAIN_COLUMN: 'Unable to train column with its description, please try again',
    COLUMN_TRAINING_SUCCESS: 'Saved successfully.',
    SQL_PAIRS_TRAINING_SUCCESS: 'Your changes have been saved.',
    NO_NEW_SQL_PAIRS_TO_TRAIN: 'No new SQL pairs to train.',
    DATABASE_DRAFT_SUCCESS: 'Database draft saved.',
    DATABASE_DRAFT_FAILED: 'Failed to save database draft.',
    UNABLE_TO_GET_COLUMN_DETAILS: 'Unable to extract table contents, please try again.',
    DRAFT_SAVED: 'Draft saved successfully',
    UNABLE_TO_SAVE_DRAFT: 'Unable to save the tables, please try again',
    UNABLE_TO_GET_SQL_PAIRS: 'Unable to get SQL pairs, please try again',
    UNABLE_TO_SAVE_SQL_PAIRS: 'Had some technical issues at saving the details, please try again later.',
    SQL_PAIRS_DRAFT_SAVED: 'SQL pairs draft saved successfully',
    SQL_PAIR_DELETE_SUCCESS: 'SQL pair deleted successfully',
    UNABLE_TO_DELETE_SQL_PAIR: 'Unable to delete SQL pair, please try again',
    COPIED_TO_CLIPBOARD: 'Copied to clipboard',
    DUPLICATE_QUERY: 'Duplicated prompt with PROMPTID. Please review or remove to continue.',
    NOT_ALLOWED: 'User does not have necessary rights to perform this operation.',
    UNABLE_TRAIN_NLQ: 'Had some technical issues in training the model, your progress has been saved in the draft. Please try again later.',
    UNABLE_TO_CONNECT_TO_SAVE_NLQ: 'Unable to connect to database. Please recheck and try again.',
    SQL_INJECTION_IN_NLQ: 'This database is read-only, no changes or modifications are permitted.',
    TABLE_NOT_CURATED: 'SQL Query referencing non curated tables',
    UNABLE_TO_CONNECT_MAIN_ERROR: 'Unable to generate the query due to technical connection issues with the Database. Please try again later.',
    UNABLE_TO_CONNECT_GENERATE_SQL: 'Connection issues with database: Unable to generate query',
    UNABLE_TO_TRAIN_PARTICULAR_PAIR: 'Unable to train with this pair, revise and try again.',
    REQUIRED_FIELD: 'This is a required field.',
    DOWNLOADED_CHART: 'Chart downloaded successfully.',
    UNABLE_TO_DOWNLOAD_CHART: 'Unable to download chart, please try again.',
    SYNTAX_ERROR: 'Syntax error',
    NUMBER_OF_SAMPLE_PROMPTS: 5,
    UNABLE_TO_GET_CHAT_HISTORY: 'Unable to retrieve chat history now, please try again.',
    UNABLE_TO_DELETE_CHAT_HISTORY: 'Unable to delete the chat history, please try again.',
    CHAT_HISTORY_DELETED: 'Chat history deleted successfully.',
    CHAT_HISTORY_RENAMED: 'Chat title renamed successfully.',
    UNABLE_RENAME_CHAT_TITLE: 'Unable to rename the chat title, please try again.',
    UNABLE_TO_OPEN_CHAT_DETAILS: 'Unable to retrieve the selected chat history details, please try again.',
    INTERNAL_SERVER_ERROR: 'Unable to retrieve the results from the database due to technical connectivity issues. Please try again later.'
};

export const RoleStrings = {
    DB_OWNER: 'dpe_geneai_aodata_dbowner',
    DB_OWNER_SUFFIX: 'dpe_geneai_aodata_dbowner_'
}