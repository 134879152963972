export type ChatMetaData = {
  title: string;
  id: string;
  agentId?: string;
  updatedAt: Date;
  created: Date;
}

export function buildRoute(chat: ChatMetaData) {
  return chat.agentId ? `/agents/${chat.agentId}/chat/${chat.id}` : `/chat/${chat.id}`;
}
