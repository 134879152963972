import { EventEmitter, Component, Input, ElementRef, Output } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'input-select-box',
  templateUrl: './input-select-box.component.html',
  styleUrls: ['./input-select-box.component.css']
})
export class InputSelectBoxComponent {

  @Input() value: string = '';
  @Input() readOnly : boolean = false;
  @Output() valueChange = new EventEmitter<string>();
  selectedOptionIndex: number = -1;

  @Input() options: string[] = [];
  showDropdown: boolean = false;
  filteredOptions: string[] = [];

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  constructor(private elementRef: ElementRef) { }

  showOptions(): void {
    this.filterOptions();

    if (this.filteredOptions.length > 0) {
      this.showDropdown = true;
    }
  }

  hideOptions(): void {
    this.showDropdown = false;
  }

  hideOptionsBlur(): void {
    setTimeout(() => {
      const inputElement: HTMLInputElement = this.elementRef.nativeElement.querySelector('#inputBoxId');
      if (inputElement && document.activeElement != inputElement)
        this.showDropdown = false;
    }, 200);
  }

  selectOption(option: string): void {
    this.value = option;
    this.showDropdown = false;
    this.valueChange.emit(this.value);
  }

  onInputChange() {
    this.filterOptions();
    this.valueChange.emit(this.value);
    this.filteredOptions.forEach(option => {
      if (option.toLowerCase() == (this.value.toLowerCase())) {
        this.value = option;
        this.valueChange.emit(this.value);
      }
    });
  }

  filterOptions(): void {
    this.filteredOptions = this.options.filter(option =>
      option.toLowerCase().startsWith(this.value.toLowerCase())
    );

    this.showDropdown = this.filteredOptions.length != 0;
  }

  onKeyDown(event: KeyboardEvent): void {

    if (event.key === 'ArrowDown') {
      if (this.filteredOptions.length > 0)
        this.showDropdown = true;
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      const optionsCount = this.filteredOptions.length;
      if (optionsCount > 0) {
        if (event.key === 'ArrowDown') {
          this.selectedOptionIndex = (this.selectedOptionIndex + 1) % optionsCount;
        } else if (event.key === 'ArrowUp') {
          this.selectedOptionIndex = (this.selectedOptionIndex - 1 + optionsCount) % optionsCount;
        }
        this.value = this.filteredOptions[this.selectedOptionIndex];
      }
    } else if (event.key === 'Enter')
      this.selectOption(this.value);
  }

  focusInput(): void {
    const inputElement: HTMLInputElement = this.elementRef.nativeElement.querySelector('#inputBoxId');
    if (inputElement)
      inputElement.focus();
  }

}
