import {
  ComponentRef,
  Directive,
  ElementRef, EmbeddedViewRef,
  HostListener,
  Input,
  Renderer2,
  ViewContainerRef,
  Injector, OnDestroy
} from '@angular/core';
import { Positioning } from "@shared/models/positioning";
import {TooltipComponent} from "@shared/components/tooltip/tooltip.component";
import { featureFlags } from "@environments/environment";
import { isMobileResolution } from "@shared/constants/navbar/screen-resolutions";

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnDestroy{

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private injector: Injector,
              private viewContainerRef: ViewContainerRef) { }

  @Input() text: string = '';
  @Input() position: Positioning = 'top';
  private componentRef: ComponentRef<TooltipComponent> | null = null;

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!isMobileResolution() && this.text && this.componentRef === null && featureFlags.customTooltip) {
      this.componentRef = this.viewContainerRef.createComponent(TooltipComponent, {
        injector: this.injector
      });
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      this.renderer.appendChild(document.body, domElem);

      this.componentRef.instance.text = this.text;
      this.componentRef.instance.isVisible = true;
      this.componentRef.instance.position = this.position;
      this.componentRef.instance.setCoordinates(this.el.nativeElement.getBoundingClientRect());
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.clearTooltip();
  }

  ngOnDestroy(): void {
    this.clearTooltip();
  }

  private clearTooltip() {
    if (this.componentRef !== null) {
      this.renderer.removeChild(document.body, this.componentRef.location.nativeElement);
      this.viewContainerRef.detach();
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
