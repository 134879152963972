import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { faFolderOpen, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { PromptSettings } from 'src/app/shared/models/prompt-settings.model';
import { FileUpload } from '@shared/models/file-upload.model';
import { FilesService } from '@services/files/files.service';
import { KmdModalService } from 'gds-atom-components';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChatService } from '@services/chat/chat.service';
import { Prompt } from '@shared/models/prompt.model';
import { Author, Message } from '@shared/models/message.model';
import { Chat } from '@shared/models/chat.model';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserData } from '@shared/models/user-data.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ChatHistoryComponent } from '../chathistory/chathistory.component';
import { HttpErrorResponse } from "@angular/common/http";
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { PromptListComponent } from "../promptlist/promptlist.component";
import { ChatComponent } from '../chat/chat.component';
import { FileReference } from "@shared/models/file-reference.model";
import { DrawerComponent } from '../drawer/drawer.component';
import { ModelFactory } from "@shared/models/gpt-models/model-factory";
import { PromptTokenRatio } from "../prompt/prompttokenratio/prompt-token-ratio";
import { ChatHistory } from "@services/chathistory/chat-history";


@Component({
  selector: 'app-document-query',
  templateUrl: './document-query.component.html',
  styleUrls: ['./document-query.component.css', '../chat/chat.component.markdown.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: 'ChatHistoryService', useClass: ChatHistory },
    { provide: 'chatHistoryType', useValue: 'ask_my_docs' }
  ],
  animations: [
    trigger('slideInOut', [
      state('in', style({ width: '260px' })),
      state('out', style({ width: '0px' })),
      transition('in <=> out', animate('300ms ease-in-out'))
    ])
  ]
})
export class DocumentQueryComponent implements OnInit {

  @ViewChild('modalPromptListComponent') modalPromptListComponent?: PromptListComponent;
  @ViewChild('appMenu') chatHistoryComponent!: ChatHistoryComponent;
  @ViewChild('gptEnabledButton') gptEnabledButton!: any;
  @ViewChild('appChat') chatComponent?: ChatComponent;
  @ViewChild('dropdownComponent') dropdownComponent?: any;
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent;

  chats$: Observable<Chat[]> = this.chatService.getChats("ask_my_docs");

  chatHistoryState: string = 'out';
  faPaperPlane = faPaperPlane;
  faSlidersH = faSlidersH;
  response: string = '';
  promptText: string = '';
  filesList: any[] = [];
  multiSelectedValSelectKey: any[] = [];
  showAlert: boolean = false;
  showNoDocumentsNotification: boolean = false;
  showTipNoteNotification: boolean = false;
  uploaderIcon = faFolderOpen;
  url?: string;
  settings: PromptSettings = new PromptSettings(3, "PROFESSIONAL", false, "gpt-4-o");
  messages: Message[] = [];
  chat?: Chat;
  userData$: Observable<UserData>;
  includeWebSearch = false;
  activeView = "chat";
  showPromptSavedAlert = false;
  alertText = "We encountered an unexpected error. Please try again.";
  promptSavedText = 'Prompt Saved';
  textTrimmed = false;

  constructor(
    private filesService: FilesService,
    public oidcSecurityService: OidcSecurityService,
    private chatService: ChatService,
    private kmdModalService: KmdModalService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private modelFactory: ModelFactory) {

    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    );

    const navigation = this.router.getCurrentNavigation();
    if (this.hasFiles(navigation?.extras) != undefined) {
      this.multiSelectedValSelectKey = navigation?.extras.state?.['selectedFiles'];
    }
  }

  ngOnInit(): void {
    this.loadFiles();
    this.route.params.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.chatService.getChat(id).subscribe(
          {
            next: value => {
              this.chat = value
              this.settings = this.chat!.promptSettings;
            }
          }
        )
        this.chatService.getChatMessages(id)
          .subscribe(
            (newMessages) => {
              this.setMessages(newMessages);
            },
            (error) => {
              let errors = [400, 401, 403, 404, 500, 503]
              if (errors.includes(error.status)) {
                this.router.navigate(['/error'], { state: { status: error.status }, replaceUrl: true })
              } else {
                this.router.navigate(['/error'], { state: { status: 500 }, replaceUrl: true })
              }
            }
          )
      }
    });
  }

  hasFiles(extras?: NavigationExtras): FileUpload[] | undefined {
    const state = extras?.state as {
      selectedFiles: any[]
    };
    return state?.selectedFiles
  }

  loadFiles() {
    this.filesService.list().subscribe({
      next: (promptList) => {
        this.filesList = this.formatFiles(promptList.filter((file) => file.status === 'PROCESSED')).sort((a, b) => a.name.localeCompare(b.name));
        this.cdRef.detectChanges();
        if (this.filesList.length == 0) {
          this.showNoDocumentsNotification = true;
        } else {
          if (this.multiSelectedValSelectKey.length > 0) {
            this.dropdownComponent?.selectByKey(this.multiSelectedValSelectKey.map(o => o.id), 'id');
          }
          this.showTipNoteNotification = true;
        }
      },
      error: () => {
        this.alertText = 'The files could not be obtained. There was an issue contacting the server.';
        this.showAlert = true;
      }
    });
  }

  sendPrompt() {
    if (this.isTextExceeded())
      return;
    this.kmdModalService.open('loadingResponseModal');
    let selectedFilesIDs = this.multiSelectedValSelectKey.map((file) => file.id);
    let author = new Author("USER");
    let promptValue = this.promptText;
    const parent = this.messages[this.messages.length - 1];

    let prompt = new Prompt(promptValue, this.settings, parent?.id);
    let message = new Message(promptValue, author, new Date(), this.settings, parent?.id);
    prompt.files = selectedFilesIDs;
    if (this.chat?.id == undefined) {
      prompt.gptEnabled = this.gptEnabledButton.nativeElement.selected
      this.chatService.promptChat(prompt).subscribe({
        next: (chatAndPrompt) => {
          message.id = chatAndPrompt.prompt.id;
          message.fileReferences = chatAndPrompt.prompt.fileReferences;
          this.chat = chatAndPrompt.chat;
          this.chats$ = this.chatService.getChats("ask_my_docs");

          this.messages = [message, chatAndPrompt.prompt.answer!];
          this.chatComponent?.setMessages(this.messages)
          this.kmdModalService.close('loadingResponseModal');
          this.router.navigate(['/document-query/' + this.chat?.id], { state: { selectedFiles: this.multiSelectedValSelectKey } })

          if (this.chatHistoryState == 'out') {
            this.toggleChatHistoryState()
          }
        },
        error: (e) => {
          this.handlePromptError(e);
          this.promptText = promptValue;
        }
      });
    } else {
      this.chatService.prompt(this.chat.id, prompt).subscribe({
        next: (prompt) => {
          message.id = prompt.id
          message.parentId = prompt.parentId
          message.fileReferences = prompt.fileReferences
          this.addMessages([message, prompt.answer!]);
          this.kmdModalService.close('loadingResponseModal');
        }, error: (e) => {
          this.handlePromptError(e);
          this.promptText = promptValue;
        }
      });
    }
    this.promptText = "";
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPromptChange(event: Event) {
    if (this.textTrimmed && this.promptText.length < this.maxInputCharLimit()) {
      this.textTrimmed = false;
    }
  }

  onPaste(event: ClipboardEvent) {
    const text = event.clipboardData?.getData('text/plain');
    if (text) {
      this.textTrimmed = (this.promptText.length + text.length) > this.maxInputCharLimit();
    } else {
      this.textTrimmed = false;
    }
  }

  isTextTrimmed() {
    return this.textTrimmed && this.promptText.length == this.maxInputCharLimit();
  }

  isTextExceeded() {
    return this.promptText.length > this.maxInputCharLimit();
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handlePromptError(error: HttpErrorResponse) {
    this.alertText = 'Sorry, your prompt couldn\'t be processed. Please try again later.';
    this.showAlert = true;
    this.kmdModalService.close('loadingResponseModal');
  }

  onEnterKey(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    keyboardEvent.preventDefault();
    if (this.isSendEnabled()) {
      this.sendPrompt();
    }
  }

  isSendEnabled() {
    return this.promptText.trim().length > 0 && this.multiSelectedValSelectKey.length > 0 && !this.isTextExceeded();
  }

  moveToMyDocs() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/uploader']));
  }

  navigateToAskMyDocs() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/document-query']);
    });
  }

  showPromptList() {
    this.activeView = "prompt-list";
  }

  showChat(event?: any) {
    event?.preventDefault();
    this.activeView = "chat";
  }

  promptSelected(prompt: Prompt) {
    this.promptText = prompt.text;
    this.settings = prompt.promptSettings;
    this.selectFile(prompt.fileReferences!);
    this.activeView = "chat";
  }

  selectFile(file: FileReference[]) {
    if (file && file.length > 0) {
      const selected = file.map((file) => ({ id: file.id!, name: file.title }));
      const fileExists = selected.some(selected =>
        this.filesList.some(file => file.id === selected.id && file.name === selected.name)
      );
      if (fileExists) {
        this.multiSelectedValSelectKey = selected;
      }
    }
  }

  toggleChatHistoryState() {
    this.chatHistoryState = this.chatHistoryState === 'in' ? 'out' : 'in';
  }

  isChatHistoryVisible() {
    return this.chatHistoryState == "in";
  }

  toggleByDirective() {
    if (this.isChatHistoryVisible()) {
      this.toggleChatHistoryState();
    }
  }

  addMessages(newMessages: Message[]) {
    this.cdRef.detectChanges();
    this.messages = [...this.messages, ...newMessages];
    newMessages.forEach( m => {
      this.chatComponent?.addMessage(m);
    })
    this.cdRef.detectChanges();
  }

  setMessages(newMessages: Message[]) {
    this.messages = newMessages;
    this.chatComponent?.setMessages(newMessages);
    this.cdRef.detectChanges();
  }

  executeQuickAction(quickAction: string) {
    switch (quickAction) {
      case 'summarize':
        this.promptText = 'Explain the contents of the document to me like I am 25 years old.';
        break;
      case 'keyTerms':
        this.promptText = 'I\'m studying this document in preparation for an exam. Begin your response by outputting the title of the document using markdown. I want you to generate key terms from the document. Each key term and definition will use specific terms from that document and then show the definition of that term. Create 10 key terms and definitions. Place the response in a two column table, the first column is the key term and the second column is the definition.';
        break;
      case 'questions':
        this.promptText = 'I need to study this document in preparation for an exam. Step 1, explain the contents of the document to me like I am 25 years old. Step 2, create 10 questions and answers from your explanation that reflect the top 10 learning points from the document.';
        break;
      case 'analogies':
        this.promptText = 'I\'m studying this document in preparation for an exam. Explain the contents of the document to me like I am 25 years old and then create 5 analogies that represent the top 5 key points from your explanation.';
        break;
      case 'report':
        this.promptText = 'Explain the contents of the document to me like I am 25 years old. Use your explanation to write a Report. Based only on your explanation, write a 1000 word Report. Begin by creating an appropriate title and using markdown to display the Title, followed by the body and then conclude with a Summary.';
        break;
    }

    if (this.isSendEnabled()) {
      this.sendPrompt();
    }
  }

  handleGPT(enable: boolean) {
    if (this.chat) {
      this.chat!.gptEnabled = enable;
      this.chatService.update(this.chat!).subscribe(
        {
          error: () => {
            this.chat!.gptEnabled = !enable
          }
        }
      )
    }
  }

  isChatViewInactive() {
    return this.activeView != "chat";
  }

  handlePromptSaved() {
    this.showPromptSavedAlert = true;
  }

  handleResponseSelected(responseText: string) {
    this.promptText += responseText;
  }

  handleMessagesUpdated(updatedMessages: Message[]) {
    this.messages = updatedMessages;
  }

  private formatFiles(files: FileUpload[]): { id: string, name: string }[] {
    return files.map(file => ({ id: file.id!, name: file.name }));
  }

  openDrawer(): void {
    this.drawerComponent.openDrawer();
  }

  closeDrawer(): void {
    this.drawerComponent.dismissDrawer();
  }

  maxInputCharLimit() {
    const gptWordToTokenRatio: number = 3.5;
    const modelTokens = this.modelFactory.create(this.settings.model).TOKENS;
    return ((modelTokens / 8) * PromptTokenRatio.ASK_MY_DOCS) * gptWordToTokenRatio;
  }

  showQuickActions() {
    return this.multiSelectedValSelectKey.length === 1;
  }

  selectedChat(chat: Chat) {
    this.router.navigate(['/document-query/' + chat.id]);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deletedChat(chat: Chat) {
    this.router.navigate(['/document-query']);
  }

}
