<div class="data-table-container">
<div class="section" id="today" *ngIf="today.length">
  <div class="header"><h4>Today</h4></div>
  <div class="records">
    <app-record *ngFor="let record of today" [record]="record" (deletedChatEvent)="removeRecord(today, record)"></app-record>
  </div>
</div>
<div class="section" id="yesterday" *ngIf="yesterday.length">
  <div class="header"><h4>Yesterday</h4></div>
  <div class="records">
    <app-record *ngFor="let record of yesterday" [record]="record" (deletedChatEvent)="removeRecord(yesterday, record)"></app-record>
  </div>
</div>
<div class="section" id="last-week" *ngIf="lastWeek.length">
  <div class="header"><h4>Last Week</h4></div>
  <div class="records">
    <app-record *ngFor="let record of lastWeek" [record]="record" (deletedChatEvent)="removeRecord(lastWeek, record)"></app-record>
  </div>
</div>
<div class="section" id="last-month" *ngIf="lastMonth.length">
  <div class="header"><h4>Last Month</h4></div>
  <div class="records">
    <app-record *ngFor="let record of lastMonth" [record]="record" (deletedChatEvent)="removeRecord(lastMonth, record)"></app-record>
  </div>
</div>
<div class="section" id="all" *ngIf="all.length">
  <div class="header"><h4>All</h4></div>
  <div class="records">
    <app-record *ngFor="let record of all" [record]="record" (deletedChatEvent)="removeRecord(all, record)"></app-record>
  </div>
</div>
</div>
