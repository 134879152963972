<kmd-modal id="{{id}}" class="kmd-modal-container" modalSize="large" headline="Add new users to repository">
    <div class="add-users-container">
        <p class="subtitle">Added users are available for staff review. Ensure selected users do not misuse the
            repository information.</p>
        <form [formGroup]="formGroup" class="form-container">
            <div id="role" class="form-item">
                <label class="form-item-title" for="role-dropdown">Select role to assign</label>
                <kmd-dropdown id="role-dropdown" [externalLabel]="true" formControlName="role" [options]="roleLabels"
                    (onChange)="onRoleChange($event)">
                </kmd-dropdown>
            </div>
            <div id="users" class="form-item">
                <div class="user-input-title-wrapper">
                    <label id="email-input-title" class="form-item-title">Enter emails of users to add</label>
                    <p *ngIf="emails.length" class="email-count-label">{{ emailCountLabel }}</p>
                </div>
                <div class="user-input-list-wrapper" (click)="focusOnEmailInput()"
                    [ngClass]="userInputWrapperClasses$ | async">
                    <ng-container *ngFor="let email of emails">
                        <app-chip [text]="email" [closeable]="true" (close)="onEmailChipClose(email)"
                            [type]="getChipTypeForEmail$(email) | async" />
                    </ng-container>
                    <input id="email-input" (keydown)="onEmailInputKeydown($event)" formControlName="typedEmail"
                        type="text" autocomplete="off" />
                    <span class="error-icon" [ngClass]="userInputErrorIconClasses$ | async"></span>
                </div>
                <div *ngIf="isFormError$ | async" class="error-messages-wrapper">
                    <p id="duplicate-emails-error" class="error-message"
                        [ngClass]="{ 'notice': isExpectedFormError$(supportedFormErrors.DUPLICATE_VALUES) | async }">
                        Duplicate emails are not allowed.
                    </p>
                    <p id="invalid-email-format-error" class="error-message"
                        [ngClass]="{ 'notice': isExpectedFormError$(supportedFormErrors.INVALID_EMAILS) | async }">
                        One or more emails have invalid format.
                    </p>
                    <p id="max-admin-email-error" class="error-message"
                        [ngClass]="{ 'warning': isExpectedFormError$(supportedFormErrors.MAX_LIMIT) | async }">
                        Only 5 admins are allowed per request.
                    </p>
                    <p id="invalid-company-emails-error" class="error-message"
                        [ngClass]="{ 'error': isExpectedFormError$(supportedFormErrors.NON_COMPANY_EMAILS) | async }">
                        These email addresses are not recognized. Please enter valid company email(s).
                    </p>
                    <p id="failed-email-validations-error" class="error-message"
                        [ngClass]="{ 'error': isExpectedFormError$(supportedFormErrors.UNABLE_TO_VALIDATE_EMAIL) | async }">
                        Unable to validate these email addresses. Please try again.
                    </p>
                </div>
            </div>
        </form>
        <div class="action-buttons-container">
            <core-button id="cancel-button" label="Cancel" variant="information"
                (click)="onCancelClick()"></core-button>
            <core-button id="confirm-button" label="Confirm actions" variant="primary" (click)="onConfirmActionsClick()"
                [disabled]="isConfirmActionsDisabled$ | async"></core-button>
        </div>
    </div>
</kmd-modal>