import { Inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { GeneratedFile } from "@shared/models/generated-file";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CodeExecutorService {

  constructor(@Inject('BASE_API_URL') private baseUrl: string, private http: HttpClient) {
  }

  getFile(fileID: string): Observable<GeneratedFile> {
    return this.http.get<GeneratedFile>(this.baseUrl + `/v1/code-executor/files/${fileID}`, this.getHeaders());
  }

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }
}
