import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    FileTranslationsManagerTranslate
} from "@shared/models/file-translations-manager/file-translations-manager-translate.model";
import {
    FileTranslationsManagerService
} from "@services/file-translations/file-manager/file-translations-manager.service";
import {
    FileTranslationsProcessingStatus
} from "@shared/constants/file-translations/file-translations-processing-status";
import { KmdModalService } from "gds-atom-components";
import { FileTranslateEvent } from '../file-translations-uploader/file-translations-uploader.component';

@Component({
    selector: 'app-file-translations-translate-container',
    templateUrl: './file-translations-translate-container.component.html',
    styleUrl: './file-translations-translate-container.component.css'
})
export class FileTranslationsTranslateContainerComponent{
    @Input() translateFiles: FileTranslationsManagerTranslate[] = [];
    @Output() translateFilesChange = new EventEmitter<FileTranslationsManagerTranslate[]>();
    @Output() fileEvents = new EventEmitter<FileTranslateEvent>();

    onFileTranslated(file: FileTranslationsManagerTranslate): void {
      this.fileEvents.emit({ type: 'translated', data: file });
    }

    onFileTranslateError(): void {
      this.fileEvents.emit({ type: 'error' });
    }

    onFileTranslateRemoved(): void {
      this.fileEvents.emit({ type: 'removed' });
    }

    onFileTranslating(): void {
      this.fileEvents.emit({ type: 'translating' });
    }

    readonly processingStatus = FileTranslationsProcessingStatus;

    removeFileId: string = "";

    constructor(
          private fileTranslationsManagerService: FileTranslationsManagerService,
          private kmdModalService: KmdModalService
      ) {}

    getMobileDisplayName(name: string): string {
        return this.fileTranslationsManagerService.getParseFileNameToDisplayName(name, 25);
    }

    getTranslatingFileStatus(data: FileTranslationsManagerTranslate): string {
        const isTranslatingInProgress = data.status === this.processingStatus.TRANSLATING;
        if (isTranslatingInProgress) {
            this.onFileTranslating()
            return this.processingStatus.TRANSLATING;
        }

        if (data.status === this.processingStatus.TRANSLATING_ERROR) {
            this.onFileTranslateError()
            return this.processingStatus.TRANSLATING_ERROR;
        }

        if (data.status === this.processingStatus.TRANSLATED) {
            this.emitAndRemoveFile(data);
            return this.processingStatus.TRANSLATED;
        }

        return this.processingStatus.TRANSLATING;
    }

    emitAndRemoveFile(data: FileTranslationsManagerTranslate): void {
        this.onFileTranslated(data);
        this.removeTranslatingFileData(data);
    }

    removeTranslatingFileData(data: FileTranslationsManagerTranslate): void {
      const index = this.translateFiles.findIndex((translatingFile) => translatingFile.file === data.file);

      if (index >= 0) {
        const updatedFiles = [...this.translateFiles];
        updatedFiles.splice(index, 1);
        if (data.cancel) {
          data.cancel();
        }
        this.translateFilesChange.emit(updatedFiles);
      }
      this.onFileTranslateRemoved();
    };

    showClearFileTranslateModal(data : FileTranslationsManagerTranslate): void {
      if (data.id && data.status == this.processingStatus.TRANSLATING) {
        this.removeFileId = data.id;
        this.openClearFileTranslateModal();
      } else {
        this.removeTranslatingFileData(data);
      }
    }

  removeTranslatingFile(): void {
    const translateManager = this.translateFiles.find((translateFile) => translateFile.id === this.removeFileId);
    const updatedFiles = this.translateFiles.filter(translateFile => translateFile.id !== this.removeFileId);
    if (translateManager && translateManager.cancel) {
      translateManager.cancel();
    }
    this.translateFilesChange.emit(updatedFiles);

    this.removeFileId = "";
    this.closeClearFileTranslateModal();
  }

    openClearFileTranslateModal(): void {
      this.kmdModalService.open('clear-file-translate-modal');
    }

    closeClearFileTranslateModal(): void {
      this.kmdModalService.close('clear-file-translate-modal');
    }

    isTranslatingStatusInProgress(status: string | undefined): boolean {
        return status === this.processingStatus.TRANSLATING;
    }

    getFileStatusClass(status: string): string {
        if(status === this.processingStatus.TRANSLATING) {
            return 'translating-status-icon';
        }
        if (status === this.processingStatus.TRANSLATING_ERROR) {
            return 'error-status-icon';
        }
        return 'translated-file-status-icon';
    }
}
