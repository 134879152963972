import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FileUpload } from '@shared/models/file-upload.model';
import { map, Observable } from 'rxjs';
import { Prompt } from 'src/app/shared/models/prompt.model';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(@Inject('BASE_API_URL') private baseUrl: string, private http: HttpClient) {
  }

  create(file: FileUpload): Observable<FileUpload> {
    return this.http.post<FileUpload>(this.baseUrl + '/v1/files', file, this.getHeaders());
  }

  delete(file: FileUpload): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `/v1/files/${file.id}`, this.getHeaders());
  }

  list(fileType: string = '', sortBy: string = "processed", sortDirection: string = "DESC", excludeStatus: string = ''): Observable<Array<FileUpload>> {
    const fileTypeQueryParam = fileType ? `&fileType=${fileType}` : '';
    const excludeStatusQueryParam = excludeStatus ? `&excludeStatus=${excludeStatus}` : '';
    return this.http.get<Array<FileUpload>>(this.baseUrl
      + `/v1/files?sortBy=${sortBy}&sortDirection=${sortDirection}${fileTypeQueryParam}${excludeStatusQueryParam}`,
      this.getHeaders()).pipe(
      map((files: FileUpload[]) => {
        return files.map(file => {
          file.updatedAt = new Date(file.updatedAt!);
          file.fileSize = file.fileSize!;
          return file;
        })
      })
    );
  }

  lazyList(fileType: string = '', offset: number = 0, sortBy: string = "processed", sortDirection: string = "DESC", searchTerm: string = ''): Observable<Array<FileUpload>> {
    const _fileType = fileType ? `&fileType=${fileType}` : '';
    const _searchTerm = searchTerm ? `&searchTerm=${searchTerm}` : '';
    const _offset = `?offset=${offset}`;
    const _sortBy = `&sortBy=${sortBy}`;
    const _sortDirection = `&sortDirection=${sortDirection}`;
    return this.http.get<Array<FileUpload>>(
      this.baseUrl + `/v1/files/page${_offset}${_sortBy}${_sortDirection}${_fileType}${_searchTerm}`,
      this.getHeaders()).pipe(
      map((files: FileUpload[]) => {
        return files.map(file => {
          file.updatedAt = new Date(file.updatedAt!)
          return file;
        })
      })
    );
  }

  get(id: string): Observable<FileUpload> {
    return this.http.get<FileUpload>(this.baseUrl + `/v1/files/${id}`, this.getHeaders());
  }

  prompt(prompt: string, fileUploads: string[]): Observable<Prompt> {
    return this.http.post<Prompt>(this.baseUrl + `/v1/files/prompt`, { prompt, fileUploads }, this.getHeaders());
  }

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }
}
