<div class="add-to-my-agents-container">
  <div class="header">
    <div class="header-wrapper">
      <div class="return-to-agents-wrapper" (click)="promptReturnConfirmation()">
        <img src="../assets/icons/arrows/arrowLeft.svg">
        <p>Agents</p>
      </div>
      <div class="title-wrapper">
        <h1>Add to My Agents</h1>
        <p>You can save a public agent then change the configuration below to meet your specific needs.</p>
      </div>
    </div>
    <kmd-alerts kmd-success id="success-alert" [(show)]="success" [icon]="true" [alertText]="'Changes applied.'"
                [floating]="true"></kmd-alerts>

    <kmd-alerts kmd-error id="error-alert" [(show)]="error" [alertText]="errorText" [dismissible]="true"
                [floating]="true"></kmd-alerts>
  </div>
  <div class="add-to-my-agents-form-container">
    <div class="form-title-wrapper">
      <h2 *ngIf="showAddToMyAgentForm">Configuration</h2>
      <button kmdPrimaryButton id="agent-example-button" *ngIf="showExampleButton" (click)="toggleAgentExample()">Show me an example</button>
    </div>
    <div class="divider"></div>
    <div id="agents-container">
    <div class="form-wrapper" *ngIf="showAddToMyAgentForm">
      <div id="add-to-my-agents-form">
        <app-agent-form #form [agent]="agent"
                      (onSubmitValidForm)="submitForm($event)"
                      (formStatusChanged)="formStatusChanged($event)"/>
      </div>
    </div>
    <div class="agent-example" *ngIf="showExampleAgent">
      <app-agent-example (closeAgentExample)="toggleAgentExample()"></app-agent-example>
    </div>
  </div>
</div>
  <div class="form-actions-container" *ngIf="showAddToMyAgentForm">
    <core-button id="save-and-chat-button" label="Save and Chat with Agent" type="button" variant="primary"
                 (click)="form.submit()"
                 disabled="{{ !validForm }}"/>
  </div>
</div>

<div class="confirmation-modal-wrapper">
  <kmd-modal [id]="modalId" modalSize="mini" headline="Confirm Return to Agent List">
    <div class="body-container">
      <p class="body">If you return to the agent list without saving, any changes you made will be lost. Do you
        wish to proceed?</p>
      <div class="buttons-container">
        <button id="info-button" (click)="returnToMyAgents()" kmdInfoButton>Discard and Exit</button>
        <button id='featured-button' (click)="closeReturnConfirmationModal()" kmdFeaturedButton>Continue Editing
        </button>
      </div>
    </div>
  </kmd-modal>
</div>
