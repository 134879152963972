<app-chat-wrapper (promptSubmit)="onPromptSubmit($event)">
  <div class="examples-scrollable-wrapper" [ngClass]="{'padding-bottom': !bannerEnabled}">
    <div class="example-prompt-container">
      <app-example-prompt (exampleSelectEvent)="setPrompt($event)" [banner]="true"
                          [showHeaderMessage]="true" [promptExamplesType]="'ASK_GENE'"
                          [userName]="(userName$ | async)!" [bannerEnabled]="bannerEnabled"/>
    </div>
  </div>
  @if (featureFlags.newsBannerMainChat) {
    <app-news-banner class="banner-modal" [headerTitle]="bannerModalTitle" [type]="'MAIN_CHAT'" (onCloseEvent)="onNewsBannerClosed()">
      <div>Use the paperclip to upload this new file type.
        <a target='_blank' class="a-announced-feature" [href]="learnMore">Learn More.</a>
      </div>
    </app-news-banner>
  } @else {
    <app-banner-modal-new *ngIf="bannerEnabled" (toggleBannerModal)="bannerEnabled = $event" class="banner-modal"
    [headerTitle]="bannerModalTitle" [banner]="bannerEnabled">
    <div>Use the paperclip to upload this new file type.
      <a target='_blank' class="a-announced-feature" [href]="learnMore">Learn More.</a>
    </div>
    </app-banner-modal-new>
  }
</app-chat-wrapper>
