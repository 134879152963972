import { formatDate } from "@angular/common";
import { FileUpload } from "../file-upload.model";

export type FileManagerDoc = {
    id?: string | undefined,
    name: string,
    displayName: string,
    miniDisplayName: string,
    shortDisplayName: string,
    size: string,
    date: string,
    isSelected: boolean
    status: string,
    isSelectDisabled: boolean
};

export function createFileManagerDoc(file: FileUpload, maxFileNameLength: number, parseFileNameToDisplayName: (name: string, length: number) => string, parseShortFileDisplayName: (name: string) => string, getReadableFileSize: (size: number) => string): FileManagerDoc {
  return {
    id: file.id!,
    name: file.name,
    displayName: parseFileNameToDisplayName(file.name, maxFileNameLength),
    miniDisplayName: parseFileNameToDisplayName(file.name, 30),
    shortDisplayName: parseShortFileDisplayName(file.name),
    size: getReadableFileSize(file.fileSize!),
    date: formatDate(file.updatedAt!, 'MMM-dd-yyyy', 'en-US'),
    isSelected: false,
    status: file.status!,
    isSelectDisabled: file.status === 'ERROR'
  }
};
