<kmd-popover #popOver id="actions-menu" popoverPosition="bottom" position="right">
  <div class="actions-container">
    <div *ngIf="editingEnabled" class="options chat-edit" (click)="editChat()">
      <img class="icon-img" ngSrc="assets/icons/edit/size=24px, style=mono.svg" alt="" height="24" width="24">
      Edit
    </div>
    <div class="options chat-delete" (click)="deleteChat()">
      <img class="icon-img" ngSrc="assets/icons/bin/size=24px, style=mono.svg" alt="" height="24" width="24">
      Delete
    </div>
  </div>
</kmd-popover>
