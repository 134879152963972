<div class="language-selector-container">
  <div class="language-selector-row">
    <div class="language-selector">
      <label for="sourceLanguage">Select source language
        <span class="select-language-span">*</span>
      </label>
      <div class="dropdown-container">
        <div
          class="selected-language"
          [class.disabled]="sourceLanguages.length === 0"
          (click)="toggleSourceDropdown()">
          <span *ngIf="selectedSourceLanguage; else sourceDefault">
            {{ selectedSourceLanguage.displayName }}
          </span>
          <ng-template #sourceDefault>
            <span class="">Select language</span>
          </ng-template>
          <i class="dropdown-arrow" [class.open]="isSourceDropdownOpen"></i>
        </div>

        <!-- Custom dropdown for desktop -->
        <div *ngIf="!isMobileView && isSourceDropdownOpen" class="custom-dropdown with-search" #sourceDropdown
          (keydown)="onKeyDown($event, 'source')">
          <!-- Search input for source languages -->
          <div class="dropdown-search-container">
            <div class="search-input-wrapper">
              <input type="text" class="dropdown-search-input" placeholder="Search languages..."
                [value]="sourceSearchText"
                (input)="onSourceSearchChange($event)" #sourceSearchInput>
              <button *ngIf="sourceSearchText" class="search-clear-button" (click)="clearSourceSearch()" type="button">
                ×
              </button>
            </div>
          </div>

          <!-- Source languages list -->
          <div class="options-grid">
            <div *ngFor="let language of filteredSourceLanguages" class="language-option"
              [class.selected]="selectedSourceLanguage?.code === language.code"
              (click)="selectSourceLanguage(language)" #sourceLanguageOption
              (keydown.enter)="selectSourceLanguage(language)" tabindex="0">
              <span title="{{ language.displayName }}" [innerHTML]="highlightMatch(language.displayName, sourceSearchText)"></span>
              <i *ngIf="selectedSourceLanguage?.code === language.code" class="check-icon">✓</i>
            </div>
          </div>

          <!-- No results message -->
          <div *ngIf="filteredSourceLanguages.length === 0" class="no-results-message">
            No languages found matching "{{ sourceSearchText }}"
          </div>
        </div>

        <!-- Native select for mobile -->
        <select
          id = "sourceLanguage"
          *ngIf="isMobileView"
          class="mobile-select"
          [disabled]="sourceLanguages.length === 0"
          (change)="onSourceSelectChange($event)">
          <option [selected]="!selectedSourceLanguage">Select language</option>
          <option
            *ngFor="let language of sourceLanguages"
            [value]="language.code"
            [selected]="selectedSourceLanguage?.code === language.code">
            {{ language.displayName }}
          </option>
        </select>
      </div>
    </div>

    <div class="language-selector">
      <label for="targetLanguage">Select target language
        <span class="select-language-span">*</span>
      </label>
      <div class="dropdown-container">
        <div class="selected-language"
          [class.disabled]="!selectedSourceLanguage || targetLanguages.length === 0"
          (click)="toggleTargetDropdown()">
          <span *ngIf="selectedTargetLanguage; else targetDefault">
            {{ selectedTargetLanguage.displayName }}
          </span>
          <ng-template #targetDefault>
            <span class="">Select language</span>
          </ng-template>
          <i class="dropdown-arrow" [class.open]="isTargetDropdownOpen"></i>
        </div>

        <!-- Custom dropdown for desktop -->
        <div *ngIf="!isMobileView && isTargetDropdownOpen" class="custom-dropdown with-search" #targetDropdown
             [ngStyle]="getTargetLanguageDropdownStyle()" (keydown)="onKeyDown($event, 'target')">
          <!-- Search input for target languages -->
          <div class="dropdown-search-container">
            <div class="search-input-wrapper">
              <input type="text" class="dropdown-search-input" placeholder="Search languages..."
                [value]="targetSearchText" (input)="onTargetSearchChange($event)" #targetSearchInput>
              <button *ngIf="targetSearchText" class="search-clear-button" (click)="clearTargetSearch()" type="button">
                ×
              </button>
            </div>
          </div>

          <!-- Target languages list -->
          <div class="options-grid" [ngStyle]="getGridStyle()">
            <div *ngFor="let language of filteredTargetLanguages" class="language-option"
              [class.selected]="selectedTargetLanguage?.code === language.code"
              (click)="selectTargetLanguage(language)" #targetLanguageOption
              (keydown.enter)="selectTargetLanguage(language)" tabindex="0">
              <span title="{{ language.displayName }}" [innerHTML]="highlightMatch(language.displayName, targetSearchText)"></span>
              <i *ngIf="selectedTargetLanguage?.code === language.code" class="check-icon">✓</i>
            </div>
          </div>

          <!-- No results message -->
          <div *ngIf="filteredTargetLanguages.length === 0" class="no-results-message">
            No languages found matching "{{ targetSearchText }}"
          </div>
        </div>

        <!-- Native select for mobile -->
        <select id="targetLanguage" *ngIf="isMobileView" class="mobile-select"
          [disabled]="!selectedSourceLanguage || targetLanguages.length === 0"
          (change)="onTargetSelectChange($event)">
          <option [selected]="!selectedTargetLanguage">Select language</option>
          <option *ngFor="let language of targetLanguages" [value]="language.code"
            [selected]="selectedTargetLanguage?.code === language.code">
            {{ language.displayName }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
