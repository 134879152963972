import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PagedChatMetaData } from "@shared/models/paged-chat-metadata.model";
import { ChatMetaData } from "@shared/models/chat-metadata.model";

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnChanges{
  @Input() records!: PagedChatMetaData;
  today: ChatMetaData[] = [];
  yesterday: ChatMetaData[] = [];
  lastWeek: ChatMetaData[] = [];
  lastMonth: ChatMetaData[] = [];
  all: ChatMetaData[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['records']) {
      this.today = [];
      this.yesterday = [];
      this.lastWeek = [];
      this.lastMonth = [];
      this.all = [];
      changes['records'].currentValue.content.forEach((record: ChatMetaData) => {
        const today = new Date();
        const yesterday = new Date(new Date().setDate(today.getDate() - 1));
        const lastWeek = new Date(new Date(new Date().setDate(today.getDate() - 7)).toDateString());
        const lastMonth = new Date(new Date(new Date().setMonth(today.getMonth() - 1)).toDateString());
        let updatedAt = record.updatedAt;
        if(updatedAt.toDateString() === today.toDateString()) {
          this.today.push(record);
        }
        else if (updatedAt.toDateString() === yesterday.toDateString()) {
          this.yesterday.push(record);
        }
        else if (updatedAt >= lastWeek) {
          this.lastWeek.push(record);
        }
        else if (updatedAt >= lastMonth) {
          this.lastMonth.push(record);
        }
        else {
          this.all.push(record);
        }
      });
    }
  }

  removeRecord(list: ChatMetaData[], record: ChatMetaData) {
    const index = list.indexOf(record);
    list.splice(index, 1);
  }
}
