import { Component, EventEmitter, Input, Output } from '@angular/core';
import { featureFlags } from "@app/environments/environment";
import { AskOurDocsV2Service } from '@app/core/services/ask-our-docs-v2/ask-our-docs-v2.service';
import { ChatHistoryTableData } from '@app/shared/models/chat-history-table-data';
import { WindowRef } from '@app/core/services/window/window-ref.service';

@Component({
  selector: 'app-ask-our-docs-v2-chat-history',
  templateUrl: './ask-our-docs-v2-chat-history.component.html',
  styleUrls: ['./ask-our-docs-v2-chat-history.component.css']
})
export class AskOurDocsV2ChatHistoryComponent {
  filteredTableData: ChatHistoryTableData[] = []; // filtered data will be stored here
  searchText: string = '';
  tableType: string = '';
  defaultTableIndex: number = 0;
  posX: number = 0;
  posY: number = 0;
  isLoadingTableData : boolean = false;
  conversationHistory: any = [];
  //for table
  columnHeaders: string[] = ['Date', 'Chat title'];
  tableData: ChatHistoryTableData[] = [];
  position: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright' = 'bottom';
  visible: boolean = false;
  selectedChatHistoryRow: any = null;
  @Input() internalIndexName : string ="";
  @Output() conversationChatHistory = new EventEmitter<any>;
  clickableChatHistory: boolean = featureFlags.clickableChatHistory;

  constructor(private askOurDocsV2Service : AskOurDocsV2Service){
  }

  tableDataRefresh(){
    this.isLoadingTableData = true;
    this.filteredTableData = [...this.tableData];
    this.askOurDocsV2Service.getChatHistory(this.internalIndexName).then(res => {
      this.tableData = res.map((item: any) => {
        let localDate;
        if (featureFlags.chatHistoryTime) {
          const utcDate = new Date(item.date);
          localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
        } else {
          localDate = item.date;
        }

        return {
          id: item.id,
          date: localDate,
          title: item.title,
          isEditing: false
        };
      });
      this.isLoadingTableData = false;
      // Updating filtered table data as well
      this.filteredTableData = [...this.tableData];
    });
  }

  async renameConversation(conversationId: string, newTitle: string) {
    try {
      this.isLoadingTableData = true;
      const response = await this.askOurDocsV2Service.renameConversation(conversationId, newTitle);
      this.tableDataRefresh();
      console.log('Chat renamed successfully', response);
    } catch (error) {
      console.error('Error renaming chat:', error);
    }
  }

  async deleteConversation(conversationId: string){
    try{
      this.isLoadingTableData = true;
      await this.askOurDocsV2Service.deleteConversation(conversationId)
      this.tableDataRefresh();
    } catch(error){
      console.error('Error deleting chat:', error);
    }
  }

  openChat(conversationId: string){
    this.askOurDocsV2Service.openChat(conversationId).then((response:any) => {
      this.conversationChatHistory.emit({"conversation": response.conversation, "id":conversationId});
    });
  }

  filterData() {
    if (!this.searchText) {
      this.filteredTableData = [...this.tableData];
      return;
    }
    this.filteredTableData = this.tableData.filter(item => {
      const searchStr = this.searchText.toLowerCase();
      return ((item.title && item.title.toLowerCase().includes(searchStr)));
    });
  }

  showPopover(event : any) {
    event.preventDefault();
    this.posX = event.currentTarget.offsetLeft + event.currentTarget.clientWidth / 2 - 20;
    this.posY = event.currentTarget.offsetTop + event.currentTarget.clientHeight / 2 + 10;
  }

  updateTitle(id: string, newTitle: string) {
    if (newTitle.trim()) {
        this.renameConversation(id, newTitle.trim());
    }
  }

  showDialog(data: any) {
    if(!data.isEditing) {
      this.visible = true;
      this.selectedChatHistoryRow = data;
    }
  }

  closeDialog() {
    this.visible = false;
  }

  isMobileScreen(): boolean {
    return 500 >= WindowRef.innerWidth;
  }

  onKeyUp(event: KeyboardEvent, id: string, value: string): void {
    if (event.keyCode === 13) {
      this.updateTitle(id, value);

      const data = this.filteredTableData?.find(item => item.id === id);
      if (data) {
        data.isEditing = false;
      }
    }
  }
}
