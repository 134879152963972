<app-agent-wizard [(agent)]="agent"
                  [ngClass]="activeView === 'wizard' ? 'show' : 'hide'" (toggleParentViewEvent)="toggleView()">

  <button id="review-agent-button"
          kmdPrimaryButton
          (click)="toggleView()" >Review Agent</button>
</app-agent-wizard>
<app-agent-creation #agentCreationComponent
                    [agent]="agent"
                    [ngClass]="activeView === 'form' ? 'show' : 'hide'">

  <a class="back-to-agents" (click)="toggleView()">
    <img src="../assets/icons/arrows/arrowLeft.svg"><span> Back to wizard</span>
  </a>
</app-agent-creation>
