import { Inject, Injectable } from "@angular/core";
import { AuthenticationProvider } from "@microsoft/microsoft-graph-client";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { firstValueFrom, map, mergeMap, Observable, shareReplay } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class MsGraphProviderService implements AuthenticationProvider {

  activeAccessToken: string | undefined;
  newAccessToken: Observable<string>

  constructor(
    @Inject('TOKEN_ENDPOINT') private tokenEndpoint: string,
    @Inject('AUTH_APP_ID') private appId: string,
    private oidcSecurityService: OidcSecurityService,
    private httpClient: HttpClient
  ) {
    this.newAccessToken = this.getNewAccessToken().pipe(
      shareReplay() // TODO: Missing test
    )
  }


  public async getAccessToken(): Promise<string> {
    if (this.activeAccessToken == undefined) {
      this.activeAccessToken = await firstValueFrom(this.newAccessToken)
    } else if (this.isTokenExpired(this.activeAccessToken)){
      this.newAccessToken = this.getNewAccessToken().pipe(
        shareReplay() // TODO: Missing test
      )
      this.activeAccessToken = await firstValueFrom(this.newAccessToken)
    }
    return this.activeAccessToken!;
  }

  private getNewAccessToken() {
    return this.oidcSecurityService.getRefreshToken().pipe(
      mergeMap(refresh => {
        let body = this.createRequestBody(refresh);
        let options = this.getHeaders();
        return this.httpClient.post<any>(this.tokenEndpoint, body.toString(), options)
      }),
      map(response => {
        return response.access_token;
      })
    );
  }

  public isTokenExpired(token: string) : boolean {
    let payload = token.split('.')[1]
    let decodedPayload = atob(payload)
    let jsonPayload = JSON.parse(decodedPayload)
    return jsonPayload.exp < new Date().getTime() / 1000;
  }

  private getHeaders() {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json, text/plain, */*",
      }
    };
  }

  private createRequestBody(refresh: string) {
    let body = new URLSearchParams();
    body.set('grant_type', 'refresh_token')
    body.set('client_id', this.appId)
    body.set('refresh_token', refresh)
    body.set('scope', 'https://graph.microsoft.com/.default')
    return body;
  }
}
