import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  Creativity,
  CreativityList,
  Model,
  ModelList,
  PromptSettings,
  Tone,
  Tones
} from "@shared/models/prompt-settings.model";

import { featureFlags } from 'src/app/environments/environment';
import { modelList, imageSize, imageQuality, imageStyle } from './prompt-settings.data';

@Component({
  selector: 'prompt-settings',
  templateUrl: './prompt-settings.component.html',
  styleUrls: ['./prompt-settings.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PromptSettingsComponent implements OnInit {
  @Input() promptSettings!: PromptSettings;
  @Input() includeWebSearch!: boolean;
  @Input() chatType!: string;

  creativityList: Creativity[] = [...CreativityList];
  toneList: Tone[] = [...Tones];
  imageSize!: { title: string, description: string, value: string }[];
  imageQuality!: { title: string, description: string, value: string }[];
  imageStyle!: { title: string, description: string, value: string }[];

  styleHelperText?: string;
  qualityHelperText?: string;
  sizeHelperText?: string;
  modelList!: ModelList;
  modelHelperText!: string;
  featureFlags = featureFlags;
  showGpt4oTip: boolean = false;

  ngOnInit() {
    this.imageSize = imageSize;
    this.imageQuality = imageQuality;
    this.imageStyle = imageStyle;
    this.modelList = modelList;

    this.sizeHelperText = this.getImageSizeHelperText(this.promptSettings.imageSize!);
    this.qualityHelperText = this.getImageQualityHelperText(this.promptSettings.imageQuality!);
    this.styleHelperText = this.getImageStyleHelperText(this.promptSettings.imageStyle!);
    this.modelHelperText = this.getModelHelperText(this.promptSettings.model);
  }

  onCreativitySelect(selectedCreativity: Creativity) {
    this.promptSettings.creativity = selectedCreativity;
  }

  onToneSelect(selectedTone: Tone) {
    this.promptSettings.tone = selectedTone;
  }

  onModelSelect(model: Model) {
    this.promptSettings.model = model;
    this.modelHelperText = this.getModelHelperText(model);
  }

  isImageChatType(): boolean {
    return this.chatType === 'image-creation';
  }

  isGPT4oSupportedChatType(): boolean {
    return ['ask-gene', 'ask-my-docs'].includes(this.chatType);
  }

  isSupportedModel(model: any): boolean {
    if (model.model !== 'gpt-4-o') return true;

    if (this.isGPT4oSupportedChatType()) {
      return featureFlags.enableGPT4o;
    }

    return false;
  }

  onImageSizeSelect(size: string) {
    this.promptSettings.imageSize = size;
    this.sizeHelperText = this.getImageSizeHelperText(size);
  }

  onImageQualitySelect(quality: string) {
    this.promptSettings.imageQuality = quality
    this.qualityHelperText = this.getImageQualityHelperText(quality);
  }

  onImageStyleSelect(style: string) {
    this.promptSettings.imageStyle = style
    this.styleHelperText = this.getImageStyleHelperText(style);
  }

  getModelHelperText(model: Model) {
    const modelData = this.modelList.find(m => m.model === model);
    return modelData?.description ?? '';
  }

  getImageSizeHelperText(size: string) {
    const imageSize = this.imageSize.find(s => s.value === size);
    return imageSize?.description ?? '';
  }

  getImageStyleHelperText(style: string) {
    const imageStyle = this.imageStyle.find(s => s.value === style);
    return imageStyle?.description ?? '';
  }

  getImageQualityHelperText(quality: string) {
    const imageQuality = this.imageQuality.find(s => s.value === quality);
    return imageQuality?.description ?? '';
  }
}
