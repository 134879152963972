import {
  Component, HostListener,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AgentsService } from '@services/agents/agents.service';
import { Agent } from '@shared/models/agent';
import { Router } from "@angular/router";
import { AgentFormComponent } from "@app/agents/agent-form/agent-form.component";

@Component({
  selector: 'app-agent-creation',
  templateUrl: './agent-creation.component.html',
  styleUrls: ['./agent-creation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AgentCreationComponent {

  @ViewChild('dropDownComponent') dropDownComponent: any;
  @ViewChild('agentForm') agentForm!: AgentFormComponent;
  @Input() agent?: Agent;

  showErrorAlert = false;
  unexpectedErrorText = "We encountered an unexpected error. Please try again.";
  invalidAgentText = "You already have an agent with this name. Please use a unique name for your agent.";
  instructionsTemplate = "PERSONA:\n" +
    "MISSION:\n" +
    "PERSONALITY:\n" +
    "RULES AND BEHAVIOR:\n" +
    "STEP-BY-STEP PROCESS:\n" +
    "IMPORTANT: Ensure all steps are completed before concluding."
  errorAlertText = this.unexpectedErrorText;
  showExampleButton = true
  showExampleAgent = false;
  showAgentCreationForm = true;
  windowWidth = window.innerWidth;
  showAgentCreateForm = true;
  validForm: boolean = false;

  constructor(
    private agentsService: AgentsService,
    private router: Router) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = (event.target as Window).innerWidth;
    this.showAgentCreationForm = !(this.windowWidth <= 992 && this.showExampleAgent);
  }
  isAgentSaved() {
    return this.agent !== undefined && this.agent.id != undefined;
  }

  saveAgent(agent: Agent) {
    this.agentsService.save(agent).subscribe({
      next: (agent) => {
        this.agent = agent;
        this.navigateToAgentChat();
      },
      error: (error) => {
        let conflictError = 409;
        this.errorAlertText = error.status === conflictError ? this.invalidAgentText : this.unexpectedErrorText;
        this.showErrorAlert = true;
      }
    });
  }

  formStatusChanged(valid: boolean) {
    this.validForm = valid
  }

  submit() {
    this.agentForm.submit();
  }

  navigateToAgentChat() {
    this.router.navigate([`/agents/${this.agent!.id}/chat`]);
  }
  toggleAgentExample() {
    if (this.showAgentCreationForm) {
      this.agent = this.agentForm.storeFormValues();
    }
    this.showExampleButton = !this.showExampleButton;
    this.showExampleAgent = !this.showExampleAgent;
    this.showAgentCreationForm = !(this.windowWidth <= 992 && this.showExampleAgent);
  }
}
