import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { KmdPopoverComponent } from "gds-atom-components";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: 'copy-response',
  templateUrl: './copy-response.component.html',
  styleUrls: ['./copy-response.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CopyResponseComponent {

  @ViewChild('popOver') popOver!: KmdPopoverComponent;
  @Output() responseSelectEvent = new EventEmitter<string>();

  faClipboard = faClipboard;
  faComment = faComment;
  responseToCopy!: string;


  constructor(private clipboard: ClipboardService) { }

  showPopover(event: MouseEvent, responseText: string) {
    event.preventDefault();
    this.responseToCopy = responseText;

    this.setPopoverPosition(event);

    this.popOver.toggle(event, event.currentTarget);
  }

  setPopoverPosition(event: MouseEvent) {
    if (event.currentTarget instanceof HTMLElement) {
      const { scrollTop, scrollLeft } = this.getScrollTopAndLeftFromParents(event.currentTarget);
      this.popOver.positionX = event.currentTarget.offsetLeft - scrollLeft + event.currentTarget.clientWidth / 2;
      this.popOver.positionY = event.currentTarget.offsetTop - scrollTop + event.currentTarget.clientHeight / 2 + 10;
    }
  }

  getScrollTopAndLeftFromParents(element: HTMLElement): { scrollTop: number, scrollLeft: number } {
    let parentElement = element.parentElement;
    let scrollTop = 0;
    let scrollLeft = 0;
    let counter = 0;
    while (parentElement && counter < 10) {
      scrollTop = parentElement.scrollTop;
      scrollLeft = parentElement.scrollLeft;
      if (scrollTop != 0 || scrollLeft != 0) {
        break;
      }
      parentElement = parentElement.parentElement;
      counter++;
    }
    return { scrollTop, scrollLeft };
  }

  hidePopover(event: MouseEvent) {
    event.preventDefault();
    this.popOver.hide();
  }

  copyToClipboard() {
    this.clipboard.copyFromContent(this.responseToCopy);
    this.hidePopover(new MouseEvent('click'));
  }

  copyToPromptInput() {
    this.responseSelectEvent.emit(this.responseToCopy);
    this.hidePopover(new MouseEvent('click'));
  }
}
