import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PromptExample } from "@shared/models/prompt-example.model";
import { PromptService } from "@services/prompt/prompt.service";
import { featureFlags } from '@app/environments/environment';
import { ChipTypeConstant } from "@shared/constants/chip/chip-type";

@Component({
  selector: 'app-example-prompt',
  templateUrl: './example-prompt.component.html',
  styleUrls: ['./example-prompt.component.css']
})
export class ExamplePromptComponent implements OnInit {

  @Input() banner: boolean = true;
  @Input() showHeaderMessage: boolean = true;
  @Input() promptExamplesType: string = 'ASK_GENE';
  @Input() userName: string = '';
  @Input() bannerEnabled: boolean = true;
  @Output() exampleSelectEvent = new EventEmitter<PromptExample>();

  examplePrompts: PromptExample[] = [];
  selectedChip: any = '';
  titleText = featureFlags.uxRevamp ? 'Enter a prompt in the chat box below or try one of our sample prompts.'
    : 'Try with one of our sample prompts or start using the text box below';

  protected readonly featureFlags = featureFlags;
  protected readonly ChipTypeConstant = ChipTypeConstant;

  constructor(private promptService: PromptService) {
  }

  ngOnInit(): void {
    this.getExamples();
    this.userName = this.getUserName(this.userName);
  }

  getUserName(name: string): string {
    return name ? ` ${name.split(' ')[1]},` : ',';
  }

  getExamplePromptClass() {
    const bannerClass = this.banner ? 'with-banner' : 'with-no-banner';
    const revampClass = featureFlags.uxRevamp ? 'revamp-width' : 'width';
    return `example-prompt example-prompt-${bannerClass} example-prompt-${revampClass}`;
  }

  exampleSelected(examplePrompt: PromptExample) {
    this.selectedChip = examplePrompt;
    this.exampleSelectEvent.emit(examplePrompt);
    setTimeout(() => {
      this.selectedChip = null;
    }, 100);
  }

  getExamples() {
    this.promptService.promptExamples(this.promptExamplesType).subscribe(examples => {
      examples.sort(() => Math.random() - 0.5);
      this.examplePrompts = examples.slice(0, 4);
    })
  }
}
