import { LogLevel } from "angular-auth-oidc-client";
import EnvFeatureFlags from "@app/feature-flags/env.FeatureFlags.json";
import { Environment } from "@environments/environment.model";
import { FeatureFlags } from "@environments/feature-flags.model";

export const environment: Environment = {
  apiUrl: 'https://gene-ai-api.tfcdev.thermofisher.net/api/dpe-gene-ai',
  askOurDataApiUrl: 'https://gene-ai-solutions-ask-our-data.tfcdev.thermofisher.net/api/gene-ai-solutions-ask-our-data',
  authAppId: '48bd3841-c7fc-4dd2-b973-080d1d7e1b47',
  resourcesUrl: 'https://geneai.tfcdev.thermofisher.com/resources/',
  betaLink: 'https://geneai.tfcstage.thermofisher.com',
  apjAlbIframeUrl: 'https://geneaiapac.thermofisher.com/ai/',
  askOurDocsIframeUrl: 'https://askourdocs-webapp-temp-eus2-01.azurewebsites.net',
  askOurDocsIframeUrlV2: 'https://askourdocs-api.geneai.tfcdev.thermofisher.com',
  authLogLevel: LogLevel.Debug,
  aodSuperAdminGroupId: 'dcb24beb-2cbf-4976-84ce-0269625aae97',
  svabaseUrl: 'https://decp-eks-tfc-dev2-platform.tfcdev.thermofisher.net', // sva plus rag api
  svanotificationsUrl: 'https://sva-api.geneai-dev.tfcdev.thermofisher.com/nm/nmsvc',
  svasessionUrl: 'https://api.geneai-dev.tfcdev.thermofisher.com/platformheader',
  svaApiUrl: 'https://decp-eks-tfc-dev2-platform.tfcdev.thermofisher.net', //shared chat, feedback
  ccdBaseUrl: 'https://ccd-api.geneai-dev.tfcdev.thermofisher.com',
  ccdRoles: 'ccd_all_data,ccd_no_cro,ccd_no_cdmo',
  ccdPowerBiReportID: "a5307e06-4646-4c5f-adfe-85e66668aa54",
  ccdPowerBiHostName: "https://app.powerbi.com",
  ccdContactInfoAgentId: "3e9eb5de-83c9-4ca6-888f-7ecc3f8a04fb",
  ccdMeetingInsightsAgentId: "bc100734-aa48-460d-a298-b0da8c079bde",
  translationsApiUrl: "https://gene-ai-translations.tfcdev.thermofisher.net/api/dpe-gene-ai-translations",
  aodUrl: 'ask-our-docs',
  aodV2Url: 'ask-our-docs-v2',
};

export const featureFlags: FeatureFlags = {
  imageCreation: true,
  askOurDocsAdminList: false,
  recommendedAgents: true,
  sva2: true,
  maintenanceMode: typeof EnvFeatureFlags.maintenanceMode === 'boolean' ? EnvFeatureFlags.maintenanceMode : false,
  askOurDocsV2: true,
  codeInterpreter: true,
  ccd: true,
  ccdAdditionalQuestionTypes: true,
  enableDatabricks: true,
  agentSharing: true,
  agentSharingCancelModal: true,
  documentTab: true,
  documentTranslationNotification: true,
  savePrompt: true,
  promptLibrary: true,
  imagePrompt: true,
  askOurDataSamplePrompts: true,
  aodAdvanceAnalytics: false,
  shouldShowAodV2InsteadOfAod: false,
  promptSuggestions: false,
  askOurDataNewChatHeader: true,
  chatHistoryTime: true,
  aodDataChatHistory: true,
  promptRandomization: true,
  newChat: true,
  clickableChatHistory: true,
  hyperlinkInSubtitle: true,
  toggleRLS: true,
  chatHistoryRegenerateFix: true,
  agentChatNew: sessionStorage.getItem('agentChatNew') !== 'false',
  askOurDataCodeInterpreter: true,
  enableGssChat: true,
  enableMssqlSchema: true,
  enableChatHistory: true,
  powerPointFileProcessing: true,
  newsBannerMainChat: sessionStorage.getItem('newsBannerMainChat') !== 'false',
  askOurDataPlotlyCodeInterpreter:false,
  imageUploader: true,
  enableChatHistorySave: true,
  markdownWrapperRefactor: true,
  enableClaude: sessionStorage.getItem('enableClaude') === 'true',
};
