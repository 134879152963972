import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TermsAndConditions } from '../../../shared/models/terms-and-conditions.model'

@Injectable({
  providedIn: 'root'
})
export class TermsandconditionsService {


  constructor(@Inject('BASE_API_URL') private baseUrl: string, private http: HttpClient) { }

  termsandconditions(): Observable<TermsAndConditions> {
    return this.http.get<TermsAndConditions>(this.baseUrl + `/v1/termsandconditions`, this.getHeaders());
  }

  accept(): Observable<void> {
    return this.http.post<void>(this.baseUrl + `/v1/termsandconditions/accept`, {}, this.getHeaders());
  }

  getText(textUrl: string): Observable<string> {
    return this.http.get(textUrl, { responseType: 'text' });
  }

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }

}
