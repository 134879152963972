<div class="container-sign-in">
  <div class="container-left">
    <img class="image" id="headerIconId" src="assets/images/gene-ai-icon-slim.svg" alt="ask gene logo">
  </div>
  <div class="container-right">
    <img class="gene-ai-logo" alt="GeneAI logo with URL" src="assets/images/gene-ai-logo-with-url.svg">
    <span class="body">Reimagine your work, gain insights and drive value.</span>
    <core-button id="signin" (click)="authorize()" class="app-component-chat-submit-btn button" label="Sign In"
                 type="button" variant="primary" size="regular" fullspace></core-button>
  </div>
</div>