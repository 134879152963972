export class TermsAndConditions {
  textURL: string
  state: EnumTermsAndConditionsState

  constructor(textURL: string, state: EnumTermsAndConditionsState) {
    this.textURL = textURL
    this.state = state
  }
}

export enum EnumTermsAndConditionsState {
  accepted = 'ACCEPTED',
  pending = 'PENDING'
}
