import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('displayInOut', [
      state('in', style({ display: 'flex' })),
      state('out', style({ display: 'none' })),
      transition('in <=> out', animate('0ms ease-in-out'))
    ]),
    trigger('slideInOut', [
      state('in', style({ width: '500px' })),
      state('out', style({ width: '0px' })),
      transition('in <=> out', animate('300ms ease-in-out'))
    ])
  ]
})
export class DrawerComponent {
  @Input() position: 'left' | 'right' = 'right';
  @Output() onStateChange = new EventEmitter<'in' | 'out'>();

  drawerState: string = 'out';
  contentState: string = 'out'

  openDrawer(): void {
    this.drawerState = 'in';
    setTimeout(() => {
      this.contentState = 'in';
      this.onStateChange.emit(this.contentState as 'in');
    }, 0);
  }

  dismissDrawer(): void {
    this.contentState = 'out';
    setTimeout(() => {
      this.drawerState = 'out';
      this.onStateChange.emit(this.contentState as 'out');
    }, 350);
  }
}
