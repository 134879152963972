<div class="translated-documents-container">
  <div class="translated-documents-title">
    Translated documents
  </div>
  <div class="translated-documents-disclaimer">
      Document translations older than 30 days will automatically be removed from history.
  </div>
    <div class="translated-documents-list-container">
      <div class="search-bar-and-download-button-container">
        <div id="search-bar-container">
          <input type="text" class="search-bar-translated-documents" formControlName="search" kmdFormInput id="search-bar-translated-documents"
                 placeholder="Search translations" autocomplete="off" (keyup)="getSearchValue($event)" />
        </div>
        <div class="primary-download-button-container" (click)="downloadSelected()">
          <button id="Download" kmdFeaturedButton [disabled]="selectedFiles.length == 0">
            <img class="download-button-image" src="assets/icons/image_download/download-mono.svg" alt="icons/actions/download">
            Download ({{selectedFiles.length}})
          </button>
        </div>
      </div>
      <div class="translated-documents-list-wrapper">
        <div #filesTable class="file-table-wrapper" [ngClass]="{ 'loading': isLoading }" (scroll)="onScroll($event)">
          <table>
            <ng-container *ngIf="isLoading">
              <div class="loading-spinner">
                <kmd-loading-wheel></kmd-loading-wheel>
              </div>
            </ng-container>
            <ng-container *ngIf="!isLoading">
              <ng-container *ngIf="hasFiles; else noResults">
                <thead>
                <tr class="data-headers">
                  <th class="row-selector">
                  </th>
                  <th class="file-name">
                    <div class="file-table-head" (click)="sortFiles('name')">
                      <label>File name</label>
                      <i></i>
                    </div>
                  </th>
                  <th class="date-translated">
                    <div class="file-table-head" (click)="sortFiles('translated')">
                      <label>Date translated</label>
                      <i></i>
                    </div>
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
               <ng-container *ngFor="let file of TranslatedDocumentsList">
                  <tr class="data-row" [ngClass]="{ 'selected': file.isSelected }">
                    <td class="row-selector">
                      <core-checkbox label="" [disabled]="isDisabledRow(file)" [checked]="file.isSelected"
                                     (onClick)="onRowCheckboxSelection(file)"/>
                    </td>
                    <td class="file-name">
                      <p> {{ getDisplayName(file) }} </p>
                    </td>
                    <td class="file-date">
                      <p>{{ file.updatedAt | date:'MMM d, y' }}</p>
                    </td>
                    <td class="download-and-delete-button-container">
                      <button class="download-button-container" (click)="downloadFile(file)"> <img class="download-button-image" src="assets/icons/image_download/download-mono-blue.svg" alt="icons/actions/download"> </button>
                      <button class="delete-button-container" (click)="openDeleteModal()"> <img class="delete-button-icon"  src="app/sva2/assets/images/icons/delete-blue.svg" alt="icons/actions/delete"> </button>
                    </td>
                  </tr>
                  <kmd-modal [id]="'delete-item-modal'" modalSize="mini" headline="Delete file">
                    <div id="text">
                      You're about to delete this translated file, this action is permanent and you won't be able to recover the file. Do you want
                      to delete it?
                    </div>
                    <div class="modal-buttons-container">
                      <button id="btnCancelDeleteItemId" (click)="closeDeleteModal()" kmdSecondaryButton>Cancel</button>
                      <button id="btnDeleteItemId" (click)="deleteFile(file)" kmdFeaturedButton>Delete</button>
                    </div>
                  </kmd-modal>
                </ng-container>
                <tr>
                  <td colspan="4" class="lazy-loading-spinner" *ngIf="isLazyLoading">
                    <div class="loading-spinner">
                      <kmd-loading-wheel></kmd-loading-wheel>
                    </div>
                </tr>
                </tbody>
              </ng-container>
              <ng-template #noResults>
                <div id="no-results-container">
                  <div class="no-results-image">
                    <img src="assets/icons/no-results/no-results-icon.svg" alt="">
                  </div>
                  <div class="no-results-title">
                    No results found.
                  </div>
                  <div class="no-results-text">
                    Refine your search or translate a file.
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </table>
        </div>
      </div>
      <kmd-alerts kmd-error *ngIf="alertType === 'error'" [(show)]="showAlert" [alertText]="alertText" [floating]="true"></kmd-alerts>
    </div>
</div>
