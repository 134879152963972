<div class="content" [ngClass]="{'blur-style' : isChatHistoryVisible()}">
  <div class="gray">
    <div class="toolbar-content max-width" *ngIf="activeView !== 'prompt-list' else askGeneSavedPrompts">
      <button kmdPrimaryButton (click)="showPromptList()">
        <i class="ic ic-saved-prompts"></i>
        Saved Prompts
      </button>
      <button kmdPrimaryButton id="chat-history-btn" class="btn-view-prompts header-btn trigger-toggle-nav-menu"
              (click)="toggleChatHistoryState()">
        <i class="ic ic-chat-history"></i>
        Chat History
      </button>
      <button kmdFeaturedButton id="new-chat" (click)="moveToAskGene()">
        <img class="ic ic-new-chat" src="assets/icons/plus/plus-16px-mono.svg">
        New Chat
      </button>
    </div>
    <ng-template #askGeneSavedPrompts>
      <div class="saved-prompts-top-menu-container max-width">
        <div id="back-to-chat-wrapper" (click)="showChat($event)">
          <img src="../assets/icons/arrows/arrowLeft.svg">
          <p>Back to chat</p>
        </div>
        <div id="saved-prompts-title-wrapper">
          <h1>Saved Prompts</h1>
        </div>
      </div>
    </ng-template>

  </div>
  <div class="tabs max-width" *ngIf="activeView === 'prompt-list'">
    <kmd-tabs theme="red">
      <kmd-tab-pane title="Private Prompts" id="private-prompts-pane">
        <ng-template kmdTabPaneContent>
          <div id="prompt-list" class="tab">
            <app-paged-prompts *featureFlag="'savedPromptsPaginated'" (promptSelectEvent)="promptSelected($event)"
                               [categoriesType]="'ASK_GENE'"></app-paged-prompts>
            <prompt-list *featureFlag="'savedPromptsAccordion'" class="scrollable" [type]="'ask_gene'"
                         [visibility]="'private'" #modalPromptListComponent
                         (promptSelectEvent)="promptSelected($event)"/>
          </div>
        </ng-template>
      </kmd-tab-pane>

      <kmd-tab-pane *featureFlag="'promptSharing'" title="Public Prompts">
        <ng-template kmdTabPaneContent>
          <div id="public-prompt-list" class="tab">
            <app-public-paged-prompts (promptSelectEvent)="promptSelected($event)"/>
          </div>
        </ng-template>
      </kmd-tab-pane>

    </kmd-tabs>
  </div>
  <div id="chat-wrapper" class="tabs max-width">
    <div class="chat-overview-container" [hidden]="isChatViewInactive()" #chatWrapper>
      <div class="banner-modal-container">
        <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
        <app-banner-modal *ngIf="chat?.id == undefined && bannerEnabled" [banner]="bannerEnabled"
                          (toggleBannerModal)="bannerEnabled = $event"/>
      </div>
      <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
      <div class="example-prompt-container" *ngIf="chat?.id == undefined">
        <app-example-prompt (exampleSelectEvent)="setExampleCurrentPrompt($event)" [banner]="bannerEnabled"
                            [showHeaderMessage]="promptExampleHeader" [promptExamplesType]="promptExamplesType"
                            [userName]="userName"/>
      </div>
      <div class="chat-list" *ngIf="chat?.id">
        <div class="table">
          <app-chat [username]="(userData$ | async)?.name || 'NA'" [chatType]="'ask_gene'" [chatId]="chat?.id || ''"
                    [generatingResponse]="generatingResponse"
                    [streamingResponse]="streamingResponse"
                    [(lastUserMessage)]="lastUserMessage"
                    [(lastSystemMessage)]="lastSystemMessage"
                    (responseSelectEvent)="handleResponseSelected($event)" (promptSavedEvent)="handlePromptSaved()"
                    #appChat/>
          <button #regenerateButton *ngIf="displayRegenerateButton()" class="regenerate-button" kmdInfoButton
                  kmdBlockButton
                  (click)="regenerateResponse()">
            <img src="assets/icons/regenerate/regenerate-response-icon.svg"/>
            Regenerate Response
          </button>
          <button id="stopGenerateButton"  *ngIf="displayStopGenerateButton()"
                  class="stop-generate-button" kmdInfoButton kmdBlockButton
                  (click)="stopGenerateResponse()">
            <img src="assets/icons/stop/stop-bold.svg"/>
            Stop Response
          </button>
        </div>
      </div>
    </div>
    <div id="chat-container" [hidden]="isChatViewInactive()">
      <app-prompt-input #promptInputComponent [username]="(userData$ | async)?.name || 'NA'"
                        [chatId]="chat?.id || undefined" [promptExample]="(randomExample$ | async ) || undefined"
                        (promptSentEvent)="onSubmit($event)" [promptSettings]="promptSettings"
                        [disabled]="isSubmitDisabled" (openDrawerEvent)="openDrawer()"/>
    </div>
  </div>

  <!-- modal elements -->
  <app-drawer appClickOutsideDrawer (appClickOutsideDrawer)="closeDrawer()" [position]="'right'">
    <span title>Prompt Settings</span>
    <prompt-settings [promptSettings]="promptSettings" [includeWebSearch]="includeWebSearch" [chatType]="'ask-gene'"/>
  </app-drawer>

  <kmd-alerts kmd-success [(show)]="showPromptSavedAlert" [floating]="true" [alertText]="promptSavedText"></kmd-alerts>

  <kmd-alerts kmd-error [(show)]="showGeneralAlert" [alertText]="alertText" [dismissible]="true"></kmd-alerts>

  <kmd-alerts id="invalidPromptAlert" kmd-warning [(show)]="showInvalidPrompt"
              [alertText]="invalidPromptText"></kmd-alerts>

  <kmd-modal *ngIf="!featureFlags.streamingGPT;" class="loading-modal" [id]="'loadingResponseModal'" modalSize="small"
             [closeOnClickingOutside]="false">
    <div>Generating response</div>
    <kmd-loading-wheel></kmd-loading-wheel>
  </kmd-modal>
</div>

<chat-history appToggleChatHistory (ToggleChatHistoryEvent)="toggleByDirective()" [@slideInOut]="chatHistoryState"
              [selectedChat]="chat" (selectedChatEvent)="selectedChat($event)" (deletedChatEvent)="deletedChat()"
              #appMenu>
  <i class="kmd-icon-close-mono trigger-toggle-nav-menu" (click)="toggleChatHistoryState()"></i>
</chat-history>
