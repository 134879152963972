import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { addAllowShareToAgents, Agent } from "@shared/models/agent";
import { AgentsService } from "@services/agents/agents.service";
import { KmdModalService, KmdPopoverComponent } from "gds-atom-components";
import { AgentCardComponent } from "./agent-card/agent-card.component";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChatHistoryComponent } from "@app/chathistory/chathistory.component";
import { Chat } from "@shared/models/chat.model";
import { ActivatedRoute, Router } from "@angular/router";
import { AgentChatDecorator } from "../agent-chat/agent-chat.component";
import { map, Observable } from "rxjs";
import { AgentChatsService } from "@services/agentchats/agentchats.service";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { AgentChatHistory } from "@services/chathistory/agent-chat-history";
import { HttpErrorResponse } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserData } from '@app/shared/models/user-data.model';
import { AgentListPagedComponent } from "@app/agents/agent-list-paged/agent-list-paged.component";
import { featureFlags } from '@app/environments/environment';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: 'ChatHistoryService', useClass: AgentChatHistory },
    { provide: 'chatHistoryType', useValue: 'AGENT' }
  ],
  animations: [
    trigger('slideInOut', [
      state('in', style({ width: '260px' })),
      state('out', style({ width: '0px' })),
      transition('in <=> out', animate('300ms ease-in-out'))
    ])
  ]
})
export class AgentListComponent implements OnInit, AfterViewInit {

  @ViewChild('agentCard') agentCard!: AgentCardComponent;
  @ViewChild('scrollDiv') scrollDiv!: ElementRef;
  @ViewChild('appMenu') chatHistoryComponent: ChatHistoryComponent | undefined;
  @ViewChild("popOver") popOver!: KmdPopoverComponent;

  @ViewChild('pagedPrivateAgents') pagedPrivateAgents!: AgentListPagedComponent;
  @ViewChild('pagedPublicAgents') pagedPublicAgents!: AgentListPagedComponent;

  isAgentsLoaded: boolean = false;
  isUserApprover: boolean = false;
  agentToDelete?: Agent;

  showErrorAlert: boolean = false;
  showSuccessAlert: boolean = false;
  alertText: string = '';

  agents: Agent[] = [];
  filteredAgents: Agent[] = [];
  chatHistoryState: 'in' | 'out' = 'out';
  posY: number = 0;
  posX: number = 0;

  myAgentsTabSelected: boolean = true;
  publicAgentTabSelected: boolean = false;
  publicOverviewAgent: Agent | null = null;
  overviewAgent: Agent | null = null;
  userData$!: Observable<UserData>;

  chats$ = this.agentChatService.getAgentChats().pipe(
    map(chats => {
      return chats.map(chat => {
        return new AgentChatDecorator(chat.chat, chat.agentId!)
      })
    })
  );

  tabChangeEvents = {
    MY_AGENTS: 'my-agents',
    PAGE_MY_AGENTS: 'my-agents-paginated',
    PUBLIC_AGENTS: 'public-agents',
    PAGE_PUBLIC_AGENTS: 'public-agents-paginated'
  };

  protected readonly faEllipsis = faEllipsis;

  constructor(
    private agentsService: AgentsService,
    private kmdModalService: KmdModalService,
    private router: Router,
    private route: ActivatedRoute,
    private agentChatService: AgentChatsService,
    private oidcSecurityService: OidcSecurityService) {
  }

  ngOnInit(): void {
    this.userData$ = this.oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    )
    this.route.queryParams.subscribe(params => {
      this.tabSelectedInit(params['tab']);
    });

    this.checkForApproverRole();
  }

  ngAfterViewInit() {
    this.loadChatHistory();
  }

  checkForApproverRole(): void {
    this.route.data
      .pipe(map(data => data['oidcRoles']))
      .subscribe((roles) => {
        if (!roles) return;

        this.isUserApprover = roles.includes("agent-request-approver")
      });
  };

  hasPublicTabSelected(tabName: string): boolean {
    if (featureFlags.paginatedAgents) {
      return tabName === this.tabChangeEvents.PAGE_PUBLIC_AGENTS;
    }

    return tabName === this.tabChangeEvents.PUBLIC_AGENTS;
  }

  tabSelectedInit(tabName: string) {
    if (this.hasPublicTabSelected(tabName)) {
      this.myAgentsTabSelected = false;
      this.publicAgentTabSelected = true;
    }
  }

  handleTabChange(event: string): void {
    switch (event) {
      case this.tabChangeEvents.MY_AGENTS:
        this.fetchAgents();
        break;
      case this.tabChangeEvents.PUBLIC_AGENTS:
        this.fetchPublicAgents();
        break;
      case this.tabChangeEvents.PAGE_MY_AGENTS:
        if (this.pagedPrivateAgents) {
          this.pagedPrivateAgents.emptySearchText();
          this.pagedPrivateAgents.reloadAgents();
        }
        break;
      case this.tabChangeEvents.PAGE_PUBLIC_AGENTS:
        if (this.pagedPublicAgents) {
          this.pagedPublicAgents.emptySearchText();
          this.pagedPublicAgents.reloadAgents();
        }
        break;
    }
  };

  fetchAgents(): void {
    this.isAgentsLoaded = false;
    this.agentsService.agents().subscribe(agents => {
      agents = addAllowShareToAgents(agents);
      this.agents = agents;
      this.filteredAgents = agents;
      this.isAgentsLoaded = true;
    });
  };

  fetchPublicAgents(): void {
    this.isAgentsLoaded = false;
    this.agentsService.publicAgents().subscribe(agents => {
      this.agents = agents;
      this.filteredAgents = agents;
      this.isAgentsLoaded = true;
    });
  };

  goToCreateNewAgent() {
    this.router.navigate(["/agents/builder"])
  }

  onFilteredAgents(filteredAgents: Agent[]) {
    this.filteredAgents = filteredAgents;
  }

  onDeleteAgent(event: Agent, modalId: string) {
    this.agentToDelete = event;
    this.kmdModalService.open(modalId);
  }

  closeModal(modalId: string) {
    this.agentToDelete = undefined;
    this.kmdModalService.close(modalId);
  }

  deleteAgent() {
    if (!this.agentToDelete?.id) return;
    this.agentsService.delete(this.agentToDelete.id).subscribe({
      next: () => {
        this.loadChatHistory();
        this.fetchAgents();
        this.agents = [...this.agents.filter(agent => agent.id !== this.agentToDelete?.id)];
        this.filteredAgents = [...this.filteredAgents.filter(agent => agent.id !== this.agentToDelete?.id)];
        this.alertText = "Agent deleted correctly.";
        this.closeModal("delete-agent-modal");
        this.showSuccessAlert = true;
      },
      error: () => {
        this.alertText = "The agent could not be deleted. There was an issue contacting the server.";
        this.showErrorAlert = true;
      }
    });
  }

  deletePublicAgent() {
    if( !this.agentToDelete?.id ) return;
    this.agentsService.deletePublicAgent(this.agentToDelete.id).subscribe(
      {
        next: () => {
          this.showSuccessAlert = true;
          this.alertText = 'Agent successfully deleted.';
          this.closeModal("delete-public-agent-modal");
          this.fetchPublicAgents();
          this.loadChatHistory();
        },
        error: () => {
          this.showErrorAlert = true;
          this.alertText = 'Agent could not be deleted, please try again.';
        },
      }
    )
  }

  onRecommendAgent(event: Agent) {
    const action = event.isRecommended ?
      this.agentsService.deleteAgentRecommendation(event.id!) :
      this.agentsService.recommendAgent(event.id!);


    action.subscribe({
      next: () => {
        this.fetchPublicAgents();
      },
      error: () => {
        this.alertText = `The agent could not be ${event.isRecommended ? 'unrecommended' : 'recommended'}. please try again.`;
        this.showErrorAlert = true;
      }
    });
  }

  loadChatHistory() {
    if(this.chatHistoryComponent) {
      this.chatHistoryComponent.loadChatHistory();
    }
  }

  toggleChatHistoryState() {
    this.chatHistoryState = this.chatHistoryState === 'in' ? 'out' : 'in';
  }

  isChatVisible() {
    return this.chatHistoryState === 'in';
  }

  toggleByDirective() {
    if (this.isChatVisible()) {
      this.toggleChatHistoryState();
    }
  }

  selectedChat(chat: Chat) {
    let internalChat = chat as AgentChatDecorator
    this.router.navigate(["/agents/" + internalChat.agentID + "/chat/" + internalChat.id])
  }

  showEllipsisMenu(event: MouseEvent) {
    event.preventDefault();
    if (event.currentTarget instanceof HTMLElement) {
      this.posX = (event.currentTarget.offsetLeft + event.currentTarget.clientWidth / 2) - 175;
      this.posY = (event.currentTarget.offsetTop + event.currentTarget.clientHeight / 2) + 8;
      this.popOver.show(event, event.currentTarget);
    }
  }

  toggleChatHistoryFromMenu() {
    this.toggleChatHistoryState();
    this.popOver.hide();
  }

  isPublicAgent(agent: Agent): boolean {
    return agent.isPublic! === true;
  };

  showPublicAgentOverviewModal(agent: Agent): void {
    this.publicOverviewAgent = agent;
    this.kmdModalService.open("public-agent-overview");
  };

  showAgentOverviewModal(agent: Agent): void {
    this.overviewAgent = agent;
    this.kmdModalService.open("view-agent-modal");
  };

  publishAgent(agent: Agent): void {
    this.kmdModalService.close("public-agent-overview");
    this.agentsService.publishAgent(agent.id!)
      .subscribe({
        next: () => {
          this.alertText = "Your agent is now available under Public Agents";
          this.showSuccessAlert = true;
          this.fetchAgents();
        },
        error: (err: HttpErrorResponse) => {
          this.alertText = this.errorMessage(err);
          this.showErrorAlert = true;
        }
      });
  };

  private errorMessage(err: HttpErrorResponse) {
    if (err.status === 400) {
      return "There's already a public agent with this name. Please rename your agent and try again."
    } else {
      return "The agent could not be published. There was an issue contacting the server."
    }
  }

  protected readonly featureFlags = featureFlags;
}
