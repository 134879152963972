<app-modal [id]="confirmationModalId"
           [headline]="headline">
  <p class="confirm-text">
    You can only select one type of files at a time. This will discard all current {{ currentFileType | titlecase }}
    selections.
    Are you sure you want to continue?<br>
    Any uploads will not be lost.
  </p>
  <div class="action-controls">
    <core-button label="Cancel" type="button" variant="information"
                 size="regular" noradius="none" arialabel="Cancel"
                 (click)="cancel()"/>
    <core-button label="Continue" type="button" variant="primary"
                 size="regular" noradius="none" arialabel="Continue"
                 (click)="confirm()"/>
  </div>
</app-modal>
