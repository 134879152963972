import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChatHistoryUpdateService } from '@app/core/chat-history-update/chat-history-update.service';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ChatService } from '@app/core/services/chat/chat.service';
import { ChatHistoryRecordActions } from '@app/shared/models/actions/chat-history-record-actions';
import { ChatMetaData } from '@app/shared/models/chat-metadata.model';
import { PagedChatMetaData } from '@app/shared/models/paged-chat-metadata.model';
import { sortDirection } from '@app/shared/models/sort-direction.model';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  fromEvent,
  map,
  merge, mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';

@Component({
  selector: 'app-chat-history-mobile',
  templateUrl: './chat-history-mobile.component.html',
  styleUrls: ['./chat-history-mobile.component.css']
})
export class ChatHistoryMobileComponent implements OnInit, OnDestroy, AfterViewInit {
  drawerEvent: ChatHistoryRecordActions | undefined;
  searchFormGroup = new FormGroup({
    search: new FormControl('', {nonNullable: true})
  });
  readonly RESULTS_PER_PAGE = 20;
  readonly DEBOUNCE_TIME = 500;
  loaded = false;
  allChats$: Observable<PagedChatMetaData> = of();
  all: ChatMetaData[] = [];
  isLoading: boolean = false;
  defaultSortDirection: sortDirection = 'DESC';
  lastRecord = false;
  toggledDrawer = false;
  userHasChats: boolean = false;
  selectedRecord?: ChatMetaData;
  private destroy$ = new Subject<void>();
  private offset: number = 0;

  private scrollEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private chatService: ChatService,
    private alertService: AlertService,
    private chatHistoryUpdateService: ChatHistoryUpdateService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.loaded = true;
    this.allChats$ = this.createChatObservable();
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    if(this.el.nativeElement.querySelector('.records')) {
      fromEvent<WheelEvent>(this.el.nativeElement.querySelector('.records'), 'scroll')
        .pipe(
          tap((event: any) => event.preventDefault()),
          map((event: any) => ({
            scrollTop: event.target.scrollTop,
            clientHeight: event.target.clientHeight,
            scrollHeight: event.target.scrollHeight,
            dataset: event.target.dataset
          }))
        )
        .subscribe(this.onScroll.bind(this));
    }
  }

  createChatObservable() {
    let initialChats$ = this.getPagedChats(this.offset,
      this.searchFormGroup.controls.search.value, this.defaultSortDirection);

    let searchObservable = this.searchFormGroup.controls.search.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(this.DEBOUNCE_TIME),
      switchMap(() => {
        this.all = [];
        this.offset = 0;
        return this.getPagedChats(this.offset, this.searchFormGroup.controls.search.value, this.defaultSortDirection);
      })
    );

    let updatesObservable = this.chatHistoryUpdateService.updates().pipe(
      filter((update: string | undefined) => !update),
      switchMap(() => {
        this.all= [];
        this.offset = 0;
        return this.getPagedChats(this.offset, this.searchFormGroup.controls.search.value, this.defaultSortDirection).pipe(
          map(newChats => ({...newChats, content: [...newChats.content]}))
        )
      })
    );

    let scrollObservable = this.scrollEvent.pipe(
      mergeMap(() =>
        this.updatePagedChats()));

    return merge(initialChats$, searchObservable, updatesObservable, scrollObservable).pipe(
      tap((pages) => {
        this.lastRecord = this.isSecondLastRecord(pages);
        this.all = this.all.concat(pages.content);
        if (!this.searchFormGroup.controls.search.value) this.userHasChats = pages.totalElements > 0;
      }),
      takeUntil(this.destroy$)
    );
  }

  private updatePagedChats(){
    return this.getPagedChats(this.offset, this.searchFormGroup.controls.search.value, this.defaultSortDirection);
  }

  onScroll(event: any): void {
    if (event.target) {
      const {scrollTop, clientHeight, scrollHeight, dataset} = event.target;
      const isScrollingDown = scrollTop > (parseFloat(dataset.prevScrollTop) || 0);
      if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 1 && !this.isLoading) {
        if (!this.lastRecord) {
          this.offset++;
          this.scrollEvent.emit();
          this.afterActions();
        }
      }
    }
  }

  sortRecords(): void {
    this.defaultSortDirection = this.defaultSortDirection === 'DESC' ? 'ASC' : 'DESC';
    this.offset = 0;
    this.all = [];
    this.allChats$ = this.createChatObservable();
  }

  isSecondLastRecord(allChats: PagedChatMetaData): boolean {
    return allChats.number >= allChats.totalPages - 1;
  }

  toggleDrawer(): void {
    this.toggledDrawer = !this.toggledDrawer;
  }

  drawerAction(event: ChatHistoryRecordActions): void {
    this.drawerEvent = event;
  }

  openDrawer(record: ChatMetaData): void {
    if (this.selectedRecord && this.selectedRecord.id === record.id) {
      this.toggleDrawer();
    } else {
      this.selectedRecord = record;
      if (!this.toggledDrawer) {
        this.toggleDrawer();
      }
    }
  }

  getChatHistoryRecordActions(record: ChatMetaData): ChatHistoryRecordActions | undefined {
    if (record === this.selectedRecord) {
      return this.drawerEvent;
    }

    return undefined;
  }

  getSelectedRecord(record: ChatMetaData): ChatMetaData | undefined {
    if (record === this.selectedRecord) {
      return record;
    }
    return undefined;
  }

  afterActions(): void {
    this.selectedRecord = undefined;
    this.drawerEvent = undefined;
    this.toggledDrawer = false;
  }

  @HostListener('document:click', ['$event'])
  hideDrawer(event: any) {
    if (!this.isClickInsideDrawer(event.target)) {
      this.afterActions();
    }

  }

  isClickInsideDrawer(target: HTMLElement): boolean {
    return target.classList.contains('records') || !!target.closest('.records');
  }

  private getPagedChats(offset: number, filter: string = '', sortDirection?: sortDirection): Observable<PagedChatMetaData> {
    let observableOfEmptyPagedChats: Observable<PagedChatMetaData> = of({
      content: [], totalPages: 0,
      numberOfElements: 0, empty: true, first: true, totalElements: 0, last: true, size: 0, number: 0
    });

    this.isLoading = true;

    const currentOffset = this.offset;

    return this.chatService.pagedChats(offset, this.RESULTS_PER_PAGE, filter, sortDirection).pipe(
      map(pagedChats => {
        return pagedChats;
      }),
      catchError(() => {
        this.isLoading = false;
        this.offset = currentOffset;
        this.alertService.showError('We encountered an unexpected error. Please try again.');
        return observableOfEmptyPagedChats;
      }),
      finalize(() => {
        this.isLoading = false;
      })
    );
  };

}
