import { Component, ViewEncapsulation } from "@angular/core";
import { KmdModalService } from "gds-atom-components";
import { ClipboardService } from "ngx-clipboard";
import { TranslationService } from "src/app/core/services/translation/translation.service";
import { ModelFactory } from "src/app/shared/models/gpt-models/model-factory";
import { PromptTokenRatio } from "@app/prompt/prompttokenratio/prompt-token-ratio";

@Component({
  selector: "app-translations",
  templateUrl: "./translations.component.html",
  styleUrls: ["./translations.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class TranslationsComponent {
  languageList = [
    "Chinese",
    "Dutch",
    "English",
    "French",
    "German",
    "Italian",
    "Japanese",
    "Korean",
    "Portuguese",
    "Spanish",
  ];

  inputText = "";
  response = "";
  selectedLanguage = "";
  dropdownLabel = "Language to select";
  showErrorAlert = false;
  errorAlertText = "We encountered an unexpected error. Please try again.";
  isCopied = false;
  alertCopyText = "Prompt successfully added to clipboard."
  readonly TRANSLATIONS_MODEL = "gpt-4";

  constructor(
    private clipboard: ClipboardService,
    private kmdModalService: KmdModalService,
    private translationService: TranslationService,
    private modelFactory: ModelFactory
  ) {}

  onLanguageChange(event: string) {
    this.selectedLanguage = event;
  }
  isCopyEnabled() {
    return this.response.length > 0;
  }

  copyResponse() {
    if (this.isCopyEnabled()){
      this.clipboard.copyFromContent(this.response);
      this.isCopied = true;
    }
  }
  translate() {
    this.kmdModalService.open("loadingResponseModal");
    this.translationService
      .translate(this.inputText, this.selectedLanguage)
      .subscribe({
        next: (translation) => {
          this.response = translation.translatedText;
          this.kmdModalService.close("loadingResponseModal");
        },
        error: () => {
          this.showErrorAlert = true;
          this.kmdModalService.close("loadingResponseModal");
        },
      });
  }

  isTranslateEnabled() {
    return this.inputText.length > 0 && this.selectedLanguage.length > 0;
  }

  maxInputCharLimit(): number {
    const gptWordToTokenRatio: number = 3.5;
    const modelTokens = this.modelFactory.create(this.TRANSLATIONS_MODEL).TOKENS;
    return ((modelTokens / 8) * PromptTokenRatio.TRANSLATIONS) * gptWordToTokenRatio;
  }

  isClearTextEnabled(): boolean {
    return this.inputText.length > 0 || this.response.length > 0;
  }

  clearText (): void {
    this.inputText = "";
    this.response = "";
  }
}
