<div class="context-menu" *ngIf="isMenuOpen" [@slide]="getAnimationState()">
    <div class="file-list-wrapper">
        <div class="file" [ngClass]="getProcessingStatus(file.id!)" *ngFor="let file of files">
            <i class="status-icon"></i>
            <p>{{file.miniDisplayName}}</p>
            <i class="delete" (click)="unselectFile(file)"></i>
        </div>
    </div>
    <div class="add-more-files-btn" (click)="addMoreFiles()">
        <i></i>
        <p>Add more files...</p>
    </div>
</div>
