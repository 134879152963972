import { Injectable } from '@angular/core';
import { FileTranslationsUploadOptions } from '@app/shared/constants/file-translations/file-translations-upload-options';
import { isScannedPdf } from "@services/file-translations/file-translation-validation/scanned-pdf/detect-scanned-pdf";

@Injectable({
  providedIn: 'root'
})
export class FileTranslationValidationService {
  private readonly acceptedFileTypes = FileTranslationsUploadOptions['TRANSLATIONS'].acceptedFileTypes;
  private readonly maxFileSizesByType = FileTranslationsUploadOptions['TRANSLATIONS'].maxFileSizesByType;

  isValidFileType(fileType: string): boolean {
    return this.acceptedFileTypes.includes(fileType);
  }

  getFileType(file: File): string {
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex <= 0) {
      return '';
    }
    return fileName.slice(lastDotIndex + 1).toLowerCase();
  }

  isMaxFileSizeExceeded(fileSize: number, fileType: string): boolean {
    const typeSizeLimit = this.maxFileSizesByType[fileType];
    return fileSize >= typeSizeLimit;
  }

  async isFilePasswordProtected(file: File, type: string): Promise<boolean> {
    try {
      const reader = new FileReader();
      const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });

      const blob = new Blob([arrayBuffer], { type });
      const text = await blob.text();
      return text.includes("Encrypt");
    } catch (error) {
      console.error('Error reading file:', error);
      return false;
    }
  }

  async isScannedPdfFile(file: File): Promise<boolean> {
    return await isScannedPdf(file);
  }
}
