import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SpreadsheetAnalysisUsage } from "@app/shared/models/file-manager/spreadsheet-analysis-usage.model";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SpreadsheetsService {

    private readonly headers = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    constructor(
        @Inject('BASE_API_URL') private baseUrl: string,
        private http: HttpClient
    ) { };

    acknowledgeUsage(request: SpreadsheetAnalysisUsage): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/v1/spreadsheet-analysis-usage/acknowledge`, request, this.headers);
    };

    getUsageAcknowledgement(): Observable<SpreadsheetAnalysisUsage> {
        return this.http.get<SpreadsheetAnalysisUsage>(`${this.baseUrl}/v1/spreadsheet-analysis-usage`, this.headers);
    };
}