import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DrawerComponent } from "@app/drawer/drawer.component";
import { Creativity, CreativityList, ModelList, PromptSettings } from "@shared/models/prompt-settings.model";
import { modelList } from "../prompt-settings/prompt-settings.data";

@Component({
  selector: 'app-prompt-settings-new',
  templateUrl: './prompt-settings-new.component.html',
  styleUrls: ['./prompt-settings-new.component.css']
})
export class PromptSettingsNewComponent {
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent;

  @Output() savedPromptsSelectedEvent = new EventEmitter<void>();
  @Input() settings?: PromptSettings;

  creativityOptions: Creativity[] = [...CreativityList];
  modelOptions: ModelList = [...modelList];

  drawerState: 'in' | 'out' = 'out';

  get selectedModelDescription() {
    return this.modelOptions.find(option => this.isModelSelected(option))?.description;
  }

  show() {
    this.drawerComponent.openDrawer();
  };

  isCreativitySelected(creativity: number) {
    return this.settings?.creativity === creativity;
  };

  onCreativitySelected(creativity: any) {
    if (this.isCreativitySelected(creativity)) {
      return;
    }

    this.settings!.creativity = creativity;
  }

  isModelSelected(option: any) {
    return this.settings?.model === option.model;
  }

  onModelSelected(option: any) {
    if (this.isModelSelected(option)) {
      return;
    }

    this.settings!.model = option.model;
  }

  onDrawerStageChange(state: any) {
    this.drawerState = state;
  }

  onSavedPromptsSelected() {
    this.savedPromptsSelectedEvent.emit();
    this.drawerComponent.dismissDrawer();
  }
};
