import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DefaultCategories, Prompt } from "@shared/models/prompt.model";
import { PromptService } from "@services/prompt/prompt.service";
import { Model } from "@shared/models/prompt-settings.model";

@Component({
  selector: 'app-form-prompt',
  templateUrl: './form-prompt.component.html',
  styleUrls: ['./form-prompt.component.css']
})
export class FormPromptComponent implements OnChanges {

  @Input() prompt!: Prompt;
  @Input() readOnly: boolean = false;
  @Input() documentList?: string[] = [];
  @Output() formAcceptEvent = new EventEmitter<Prompt>();

  categories: string[] = [];
  alertText: string = '';
  showAlert: boolean = false;

  ALL_PROMPT_CATEGORIES = "";

  constructor(private promptService: PromptService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['prompt'] && changes['prompt'].currentValue) {
      if (!this.prompt.title)
        this.prompt.title = this.prompt.text.substring(0, 50);

      if (!this.prompt.category) {
        this.prompt.category = '';
      }

      if (this.prompt.fileReferences) {
        this.prompt.fileReferences?.sort((a, b) => a.title.localeCompare(b.title));
      }
      this.fetchPromptCategories();
    }
  }

  fetchPromptCategories() {
    this.promptService.promptCategories(this.ALL_PROMPT_CATEGORIES).subscribe({
      next: (categoryList) => {
        this.categories = [...new Set([...DefaultCategories, ...categoryList].sort())];
      },
      error: () => {
        this.alertText = "The categories could not be obtained. There was an issue contacting the server.";
        this.showAlert = true;
      }
    });
  }

  onSave() {
    this.formAcceptEvent.emit(this.prompt);
  }

  isSaveButtonEnabled(): boolean {
    if (this.prompt.title && this.prompt.category)
      return this.prompt.title.trim().length > 0 && this.prompt.category.trim().length > 0 && this.prompt.text.trim().length > 0;
    else
      return false;
  }

  getWebSearchLabelText(webSearch: boolean): string {
    return webSearch ? "ON" : "OFF";
  }

  getModelLabelText(model: Model): string {
    const modelLabels = {
      "gpt-4-o": "GPT 4o 128K"
    };
    return modelLabels[model as keyof typeof modelLabels];
  }

  getDocumentTitles() {
    return this.prompt.fileReferences?.map(fileRef => fileRef.title || "Missing File").join(', ');
  }
}
