import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ChipType, ChipTypeConstant } from "@app/shared/constants/chip/chip-type";

type ChipBoxComponentConfig = {
  max: number;
  showCount: boolean;
}

export type Chip = {
  value: string;
  type: ChipType;
  closeable: boolean;
}

@Component({
  selector: 'app-chip-box',
  templateUrl: './chip-box.component.html',
  styleUrl: './chip-box.component.css'
})
export class ChipBoxComponent implements OnChanges {
  @Input() chips: Chip[] = [];
  @Input() defaultChips: Chip[] = [];
  @Input() loading: boolean = false;
  @Input() config: ChipBoxComponentConfig = {
    max: Infinity,
    showCount: false
  };
  @Input() set disabled(value: boolean) {
    this.disableBox = value;
  }

  @Output() add = new EventEmitter<string>();
  @Output() remove = new EventEmitter<number>();
  @Output() backspace = new EventEmitter<void>();

  @ViewChild('boxInput') input: ElementRef | undefined;

  readonly form = new FormGroup({
    inputControl: new FormControl({ value: '', disabled: false })
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chips'] && !changes['chips'].firstChange) {
      this.disableIfChipsLimitReached();
    }
  }

  get constantChipType() {
    return ChipTypeConstant.DEFAULT;
  }

  get chipsCount(): number {
    return this.chips.length + this.defaultChips.length;
  }

  get isBoxDisabled(): boolean {
    return this.chipsCount >= this.config.max
      || this.inputControl.disabled;
  }

  get inputControl(): FormControl {
    return this.form.controls['inputControl'] as FormControl;
  }

  set disableBox(value: boolean) {
    if (value) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }

  disableIfChipsLimitReached(): void {
    this.disableBox = this.chipsCount >= this.config.max;
  }

  focusOnInput() {
    if (this.input) {
      this.input.nativeElement.focus();
    }
  }

  onInputKeyDown($event: KeyboardEvent): void {
    if ($event.key === 'Backspace' && this.isChipDeletionAllowed()) {
      this.backspace.emit();
    }

    if (($event.key === 'Enter' || ($event.key === 'Tab') || ($event.key === ' ')) && this.inputControl.value !== '') {
      this.add.emit(this.inputControl.value);
      this.inputControl.patchValue('');
    }
  }

  onChipRemove(index: number): void {
    this.remove.emit(index);
  }

  /**
   * Rules:
   * - Allows to delete a chip only if the input field is empty.
   * - Allows to delete a chip there are non-default chips to delete.
   */
  isChipDeletionAllowed(): boolean {
    return this.inputControl.value === ''
      && this.chips.length > 0;
  }
}
