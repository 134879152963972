<div class="drawer-component">
  <div [@displayInOut]="drawerState" class="overlay" (click)="dismissDrawer()"></div>
  <div [@slideInOut]="contentState" class="drawer drawer-{{ position }}">
    <div class="drawer-header">
      <h3 class="drawer-title">
        <ng-content select="[title]"></ng-content>
      </h3>
      <i class="kmd-icon-close-mono" (click)="dismissDrawer()"></i>
    </div>

    <ng-content></ng-content>

  </div>
</div>
