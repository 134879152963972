import { Injectable } from '@angular/core';
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})

export class BannerService {

  bannerVersioned: number = 10;

  constructor(private cookieService: CookieService) {
  }

  getBannerVersion(): number {
    let bannerVersion = this.cookieService.get('bannerVersion');
    if (bannerVersion === "") {
      return 0;
    }
    return Number(bannerVersion);
  }

  isBannerEnabled(): boolean {
    let bannerEnabled = this.cookieService.get('bannerEnabled');
    let bannerVersion: boolean;
    bannerVersion = this.getBannerVersion() < this.bannerVersioned;

    if (bannerEnabled === "" || bannerVersion ) {
      return true;
    }
    return bannerEnabled === 'true';
  }

  isBannerReset(): boolean {
    let bannerReset = this.cookieService.get('bannerReset');
    if (bannerReset === "") {
      return false;
    }
    return bannerReset === 'true';
  }

  enableBanner(): void {
    this.cookieService.set('bannerEnabled', 'true');
    this.cookieService.set('bannerVersion', String(this.bannerVersioned));
  }

  disableBanner(): void {
    this.cookieService.set('bannerEnabled', 'false');
    this.cookieService.set('bannerVersion', String(this.bannerVersioned));
  }

  resetBanner(): void {
    this.enableBanner()
  }

}
