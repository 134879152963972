import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { KmdModalService } from 'gds-atom-components';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent {

  @Input() headline!: string;
  @Input() description!: string;
  @Output() confirmDeleteEvent = new EventEmitter<boolean>();
  @Output() cancelDeleteEvent = new EventEmitter<boolean>();
  modalId = 'deleteModal';

  constructor(private modalService: KmdModalService, private cdRef: ChangeDetectorRef) {}

  open(modalId: string) {
    this.modalId = modalId;
    this.cdRef.detectChanges();
    this.modalService.open(modalId);
  }

  confirmDelete() {
    this.confirmDeleteEvent.emit(true);
  }

  close() {
    this.cancelDeleteEvent.emit(true);
    this.modalService.close(this.modalId);
  }
}
