import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BannerService } from "@services/banner/banner.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-banner-modal-new',
  templateUrl: './banner-modal-new.component.html',
  styleUrls: ['./banner-modal-new.component.css']
})
export class BannerModalNewComponent implements OnInit {
  @Input() headerTitle: string = 'Header tittle';
  @Input() banner: boolean = true;
  @Output() toggleBannerModal = new EventEmitter<boolean>();

  constructor(protected bannerService: BannerService,
              protected router : Router) {
  }

  ngOnInit() {
    this.banner = this.bannerService.isBannerEnabled();
    this.toggleBannerModal.emit(this.banner);
  }

  isChecked(event: any): void {
    if (event.checked) {
      this.bannerService.disableBanner() }
    else {
      this.bannerService.enableBanner();
    }
  }

  closeBannerModal(): void {
    this.banner = false;
    this.toggleBannerModal.emit(this.banner);
  }
}
