import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-iframe-component',
  templateUrl: './iframe-apj.component.html',
  styleUrls: ['./iframe-apj.component.css'],
})
export class IframeApjComponent {
  url:any = "";
  constructor(
    private sanitizer: DomSanitizer
  ) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.apjAlbIframeUrl);
  }

}
