import { Observable } from 'rxjs'
import { PromptExample } from 'src/app/shared/models/prompt-example.model'
import { Prompt } from '@shared/models/prompt.model'
import { PromptSearch } from "@shared/models/prompt-search.model";
import { PagedPrompts } from "@shared/models/pagedprompts.model";
import { SharedPromptCategory } from "@shared/models/shared-prompt-category.model";

export abstract class PromptService {
  abstract save(prompt: Prompt): Observable<Prompt>

  abstract update(prompt: Prompt): Observable<void>

  abstract delete(promptId: string): Observable<void>

  abstract prompts(type?: string, visibility?: string): Observable<Array<Prompt>>

  abstract promptCategories(type?: string): Observable<Array<string>>

  abstract getPublicPromptCategories(): Observable<Array<string>>

  abstract getPublicPromptCategories(): Observable<Array<string>>

  abstract promptExamples(type: string): Observable<Array<PromptExample>>

  abstract sharePrompt(promptId: string, sharedPromptCategory: SharedPromptCategory): Observable<void>

  abstract getPageablePrompts(promptSearch: PromptSearch, type?: string): Observable<PagedPrompts>;

  abstract deletePublicPrompt(publicPromptId: string): Observable<void>;

  abstract getPageablePublicPrompts(promptSearch: PromptSearch): Observable<PagedPrompts>;
}
