import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { Message } from "@shared/models/message.model";
import { ModalSavePromptComponent } from "@app/prompt/modalsaveprompt/modal-save-prompt.component";
import { ChatMessage } from "@app/chat/chat-message";
import { FileManagerService, FileNameFormatOption } from "@app/core/services/file-manager/file-manager.service";
import { FileManagerDoc } from "@app/shared/models/file-manager/file-manager-doc.model";
import { ChipTypeConstant } from "@shared/constants/chip/chip-type";
import { FileReference } from '@shared/models/file-reference.model';
import { FileUploadOptions } from "@app/shared/constants/file-manager/file-upload-options";
import { NavBarScreenResolutionSettings } from "@shared/constants/navbar/screen-resolutions";
import { UserMessageTypeConstant } from "@app/shared/constants/user-message/user-message-type";
import { WindowRef } from "@app/core/services/window/window-ref.service";
import { featureFlags } from "@app/environments/environment";

export interface ExtendedFileReference extends FileReference {
  displayTitle: string;
  displayTitleNoExtension: string;
}

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.css']
})
export class UserMessageComponent implements OnInit {

  @ViewChild('savePromptComponent') modalSavePromptComponent?: ModalSavePromptComponent;

  @Input() chatMessage!: ChatMessage;
  @Output() openSavePromptModal = new EventEmitter();
  @Output() promptSavedEvent = new EventEmitter();
  @Input() set type(value: any) {
    this._type = value;
  };

  protected readonly featureFlags = featureFlags;

  get type(): any {
    return this._type;
  };

  private _type: any = UserMessageTypeConstant.DEFAULT;

  fileReferences: ExtendedFileReference[] = [];
  missingFiles: ExtendedFileReference[] = [];
  displayMore: boolean = false;

  protected readonly ChipTypeConstant = ChipTypeConstant;

  constructor(
    private clipboard: ClipboardService,
    private fileManagerService: FileManagerService,
  ) {};

  @HostListener('window:resize', ['$event'])
  hideFileListPopover() {
    this.displayMore = false;
  }

  ngOnInit(): void {
    this.initializeFiles();
  }

  initializeFiles(): void {
    this.fileReferences = this.chatMessage.message.fileReferences?.length
      ? this.chatMessage.message.fileReferences.map(file => {
        if (!file.title) {
          const fileManagerFile = [...this.fileManagerService.selectedFiles$.value, ...this.fileManagerService.fixedFiles$.value]
            .find(f => f.id === file.id);
          if (fileManagerFile) {
            file.title = fileManagerFile.name;
          }
        }
        return {
          ...file,
          displayTitle: this.fileManagerService.getParseFileNameToDisplayName(file.title, 30),
          displayTitleNoExtension: this.fileManagerService.getParseFileNameToDisplayName(file.title, 30, FileNameFormatOption.WITHOUT_EXTENSION),
        };
      })
      : [...this.fileManagerService.selectedFiles$.value, ...this.fileManagerService.fixedFiles$.value].map(this.convertToFileReference);

    this.missingFiles = this.fileReferences.filter(file => file.title.length === 0);
    this.fileReferences = this.fileReferences.filter(file => file.title.length > 0);
  }

  convertToFileReference = (file: FileManagerDoc): ExtendedFileReference => {
    const id = file.id;
    const title = file.name;
    const displayTitle = file.miniDisplayName;
    const displayTitleNoExtension = this.fileManagerService.getParseFileNameToDisplayName(file.name, 30, FileNameFormatOption.WITHOUT_EXTENSION);
    return { id, title, displayTitle, displayTitleNoExtension } as ExtendedFileReference;
  }

  copyPromptToClipboard(prompt: string) {
    this.clipboard.copyFromContent(prompt);
  };

  openSaveModal(message: Message): void {
    this.openSavePromptModal.emit(message);
  };

  toggleDisplayMore() {
    this.displayMore = !this.displayMore;
  }

  getFileType(file: ExtendedFileReference): { kind: string, icon: string } {
    const fileExtension = file.title.slice(file.title.lastIndexOf('.'));

    return FileUploadOptions['SPREADSHEETS'].extensions.includes(fileExtension!)
      ? ChipTypeConstant.ANALYZE
      : ChipTypeConstant.DOCUMENT;
  }

  fileReferencesSlice(): ExtendedFileReference[] {
    if (this.isMobileScreen()) {
      return [];
    }

    return this.fileReferences.slice(0, 2);
  }

  fileReferencesSliceMore(): ExtendedFileReference[] {
    if (this.isMobileScreen()) {
      return this.fileReferences;
    }

    return this.fileReferences.slice(2);
  }

  isMobileScreen(): boolean {
    return NavBarScreenResolutionSettings.mobile.size >= WindowRef.innerWidth;
  }

  fileReferencesMoreThan(limit: number): boolean {
    return this.fileReferences.length > limit;
  }
}
