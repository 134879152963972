import { AfterContentInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalService } from '@app/core/services/modal/modal.service';

/**
 * A customizable confirm dialog component that provides a default confirm behavior
 * when no custom content is projected. If custom content (e.g., buttons) is projected,
 * the default actions are suppressed, allowing for fully customized dialog behavior.
 *
 * This component is designed to maintain a consistent design standard for decision dialogs
 * while offering flexibility.
 *
 * @example
 * // Default confirm dialog
 * <app-confirm-dialog message="Are you sure?"></app-confirm-dialog>
 *
 * @example
 * // Custom dialog with projected content
 * // Closing the dialog needs to be handled manually
 * <app-confirm-dialog>
 *   <button (click)="customAction()">Custom Action</button>
 *   <button (click)="closeDialog()">Cancel</button>
 * </app-confirm-dialog>
 */
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.css'
})
export class ConfirmDialogComponent implements AfterContentInit {
  @Input() title: string = '';
  @Input() message: string = '';
  @Output() confirm = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();

  @ViewChild('modal') modal!: ElementRef;
  @ViewChild('contentContainer') contentContainer!: ElementRef;

  readonly modalId = 'ConfirmDialogComponent';
  readonly modalConfig = {
    closeable: false
  };

  hasProjectedContent = false;

  constructor(
    private modalService: ModalService
  ) { }

  ngAfterContentInit() {
    this.checkProjectedContent();
  }

  /**
   * Opens the dialog.
   *
   * Checks for projected content in case is not detected in the AfterContentInit lifecycle phase.
   * e.g. when the dialog is created with ngIf.
   */
  open() {
    this.modalService.open(this.modalId);
    this.checkProjectedContent();
  }

  private checkProjectedContent() {
    this.hasProjectedContent = this.contentContainer?.nativeElement.hasChildNodes();
  }

  close() {
    this.modalService.close(this.modalId);
  }

  onConfirm() {
    this.confirm.emit();
    this.modalService.close(this.modalId);
  }

  onDismiss() {
    this.dismiss.emit();
    this.modalService.close(this.modalId);
  }
}
