<div class="share-container">
  <p class="modal-description">
    When made public, your prompt is shared with all colleagues on the 'Public Prompts' tab,
    where it can't be edited or deleted.
  </p>

  <div class="dropdowns-container">
    <label kmdFormLabel><b>Select category for prompt</b><em class="dropdowns-star"> *</em></label>
    <kmd-dropdown [options]="options" optionLabelKey="app" [externalLabel]="true"
      [(ngModel)]="selectedValue"></kmd-dropdown>
  </div>

  <div id="share-complies-guidelines">
    By making my prompt public, I confirm that my prompt complies with the
    <a [routerLink]="[guidelinesUrl]">Gene.AI Guidelines.</a>
  </div>

  <div class="share-button-group">
    <button kmdInfoButton type="button" class="cancel-share-button" (click)="close()">Cancel</button>
    <button kmdFeaturedButton type="button" class="share-button" [disabled]="selectedValue === '' "
      (click)="share()">Share</button>
  </div>

</div>