<div class="prompt-alerts">
  <kmd-alerts kmd-error [floating]="true" [show]="showusePromptWhenNotSavedAlert" [icon]="true">{{ usePromptWhenNotSavedErrorMessage }}</kmd-alerts>
  <kmd-alerts kmd-success [floating]="true" [show]="showPromptUpdateSuccessAlert" [icon]="true">{{ updatePromptSuccessMessage }}</kmd-alerts>
  <kmd-alerts kmd-error [floating]="true" [show]="showPromptUpdateErrorAlert" [icon]="true">{{ updatePromptErrorMessage }}</kmd-alerts>
  <kmd-alerts kmd-success [floating]="true" [show]="showPromptDeleteSuccessAlert" [icon]="true">{{ deletePromptSuccessMessage }}</kmd-alerts>
  <kmd-alerts kmd-error [floating]="true" [show]="showPromptDeleteErrorAlert" [icon]="true">{{ deletePromptErrorMessage }}</kmd-alerts>
</div>

<div *ngIf="!isDetailModalOpen" class="search-container">
  <div class="search-wrapper">
    <input class="search-input" *ngIf="!isMobileScreen()" type="text" [(ngModel)]="searchText" (keyup)="filterData()" placeholder="Search text input">
    <input class="search-input" *ngIf="isMobileScreen()" type="text" [(ngModel)]="searchText" (keyup)="filterData()" placeholder="Search a prompt by their category or name">
  </div>
</div>
<div class="saved-prompts-card" *ngIf="!isDetailModalOpen">
  <kmd-tabs theme="dark" #tabsComponent (tabChange)="setCurrentTab($event)">
    <kmd-tab-pane class="tab-pane" id="private" title="Private prompts">
      <ng-template kmdTabPaneContent class="saved-prompts-container">
        <div class="empty-state" *ngIf="!savedPromptsLoading && savedPromptsPrivateSearched.length === 0">
          <p>No private prompts found</p>
        </div>
        <div class="loader-container" *ngIf="savedPromptsLoading">
          <kmd-loading-wheel></kmd-loading-wheel>
        </div>
        <div class="prompt-boxes-container">
          <ng-container class="prompt-container" *ngFor="let item of savedPromptsPrivateSearched">
            <saved-prompt-card (click)="showSavedPromptDetailModal(item)">
              <div class="saved-prompt-container">
                <div class="saved-prompt">
                  <div class="prompt-info">
                    <div class="prompt-title-wrapper">
                      <h3>{{item.promptTitle}}</h3>
                      <img class="bin-icon" src="../../../assets/icons/bin/aod-bin.svg" (click)="deleteConfirmation(item); $event.stopPropagation()" alt="">
                    </div>
                    <div class="prompt-description">
                      <p>{{item.promptDescription}}</p>
                    </div>
                  </div>
                  <div class="controls">
                    <button kmdPrimaryButton (click)="useCurrentPromptInNewChat(item.promptDescription); $event.stopPropagation()">New chat</button>
                    <button kmdPrimaryButton (click)="useCurrentPrompt(item.promptDescription); $event.stopPropagation()">Use prompt</button>
                  </div>
                </div>
              </div>
            </saved-prompt-card>
          </ng-container>
        </div>
      </ng-template>
    </kmd-tab-pane>
    <kmd-tab-pane id="public" title="Public prompts">
      <ng-template kmdTabPaneContent>
        <div class="empty-state"
          *ngIf="!isPublicSavedPromptsEnabled || (!savedPromptsLoading && savedPromptsPublicSearched.length === 0)">
          <p>No public prompts found</p>
        </div>
        <div class="loader-container" *ngIf="savedPromptsLoading && isPublicSavedPromptsEnabled">
          <kmd-loading-wheel></kmd-loading-wheel>
        </div>
        <div class="prompt-boxes-container" *ngIf="isPublicSavedPromptsEnabled">
          <ng-container class="prompt-container" *ngFor="let item of savedPromptsPublicSearched">
            <saved-prompt-card (click)="showSavedPromptDetailModal(item)">
              <div class="saved-prompt-container">
                <div class="saved-prompt">
                  <div class="prompt-info">
                    <div class="prompt-title-wrapper">
                      <h3>{{item.promptTitle}}</h3>
                    </div>
                    <div class="prompt-description">
                      <p>{{item.promptDescription}}</p>
                    </div>
                  </div>
                  <div class="controls">
                    <button kmdPrimaryButton (click)="useCurrentPromptInNewChat(item.promptDescription); $event.stopPropagation()">New chat</button>
                    <button kmdPrimaryButton (click)="useCurrentPrompt(item.promptDescription); $event.stopPropagation()">Use prompt</button>
                  </div>
                </div>
              </div>
            </saved-prompt-card>
          </ng-container>
        </div>
      </ng-template>
    </kmd-tab-pane>
  </kmd-tabs>
</div>

<div class="saved-prompt-detail-container" *ngIf="isDetailModalOpen">
  <div class="saved-prompt-detail">
    <div class="header">
      <button class="back-button" (click)="openRecentTab()">
        <img *ngIf="!isMobileScreen()" src="../assets/icons/arrows/arrowLeft.svg" alt="Back">
      </button>
    </div>
    <div class="prompt-info">
      <div class="prompt-title-wrapper">
        <label for="prompt-title"><b>Title</b></label>
        <input *ngIf="currentTab === 'private'" id="prompt-title" maxlength="50" placeholder="Enter prompt title" type="text" [value]="currentPrompt.promptTitle" [(ngModel)]="promptTitleEditable">
        <input *ngIf="currentTab === 'public'" id="prompt-title" maxlength="50" placeholder="Enter prompt title" type="text" [value]="currentPrompt.promptTitle" readonly>
        <div class="character-count">
          <span *ngIf="currentTab === 'private'">{{promptTitleEditable ? promptTitleEditable.length : 0}}/50</span>
          <span *ngIf="currentTab === 'public'">{{currentPrompt.promptTitle ? currentPrompt.promptTitle.length : 0}}/50</span>
        </div>
      </div>
      <div class="prompt-description-wrapper">
        <label for="prompt-description"><b>Prompt</b></label>
        <textarea *ngIf="currentTab === 'private'" id="prompt-description" maxlength="5000" placeholder="Enter prompt description" [value]="currentPrompt.promptDescription" [(ngModel)]="promptDescriptionEditable"></textarea>
        <textarea *ngIf="currentTab === 'public'" id="prompt-description" maxlength="5000" placeholder="Enter prompt description" [value]="currentPrompt.promptDescription" readonly></textarea>
        <div class="character-count">
          <span *ngIf="currentTab === 'private'">{{promptDescriptionEditable ? promptDescriptionEditable.length : 0}}/5000</span>
          <span *ngIf="currentTab === 'public'">{{currentPrompt.promptDescription ? currentPrompt.promptDescription.length : 0}}/5000</span>
        </div>
      </div>
    </div>
    <div *ngIf="!isMobileScreen()" class="controls">
      <div class="left-controls">
        <button *ngIf="currentTab === 'private'" kmdLinkButton (click)="deleteConfirmation(currentPrompt)">Delete prompt</button>
        <button *ngIf="currentTab === 'public'" kmdLinkButton (click)="useCurrentPromptInNewChat(currentPrompt.promptDescription)">New chat</button>
      </div>
      <div class="right-controls">
        <button *ngIf="currentTab === 'private'" kmdInfoButton (click)="updatePrivatePrompt(currentPrompt, promptTitleEditable, promptDescriptionEditable)">Save changes</button>
        <button *ngIf="currentTab === 'public'" kmdInfoButton (click)="openRecentTab()">Cancel</button>
        <button *ngIf="currentTab === 'private'" kmdFeaturedButton (click)="useCurrentPromptInPromptDetailModal(currentPrompt.promptDescription,promptDescriptionEditable)">Use prompt</button>
        <button *ngIf="currentTab === 'public'" kmdFeaturedButton (click)="useCurrentPrompt(currentPrompt.promptDescription)">Use prompt</button>
      </div>
    </div>
    <div *ngIf="isMobileScreen()" class="controls">
      <button *ngIf="currentTab === 'private'" kmdFeaturedButton (click)="useCurrentPromptInPromptDetailModal(currentPrompt.promptDescription,promptDescriptionEditable)">Use prompt</button>
      <button *ngIf="currentTab === 'public'" kmdFeaturedButton (click)="useCurrentPrompt(currentPrompt.promptDescription)">Use prompt</button>
      <button *ngIf="currentTab === 'private'" kmdInfoButton (click)="updatePrivatePrompt(currentPrompt, promptTitleEditable, promptDescriptionEditable)">Save changes</button>
      <button *ngIf="currentTab === 'public'" kmdInfoButton (click)="openRecentTab()">Cancel</button>
      <button *ngIf="currentTab === 'private'" kmdLinkButton (click)="deleteConfirmation(currentPrompt)">Delete prompt</button>
      <button *ngIf="currentTab === 'public'" kmdLinkButton (click)="useCurrentPromptInNewChat(currentPrompt.promptDescription)">New chat</button>
    </div>
  </div>
</div>

<kmd-modal [id]="'delete-private-prompt'" modalSize="mini" headline="Delete private prompt" (clickedOutside)="closeDeleteConfirmationModal()">
  <div class="delete-private-prompt-contents">
    <div id="text">
      Are you sure you want to delete this private prompt? This action can't be undone.
    </div>
    <div class="controls">
      <button id="cancel-delete-private-prompt" (click)="closeDeleteConfirmationModal()" kmdInfoButton>Cancel</button>
      <button id="delete-agent" (click)="deletePrivatePrompt(currentDeletePrompt)" kmdLinkButton>Delete</button>
    </div>
  </div>
</kmd-modal>