import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { featureFlags } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FeatureFlagGuard implements CanActivate {

  constructor(
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const featureFlag = route.data?.['featureFlag'];

    if (featureFlag && featureFlags[featureFlag as keyof typeof featureFlags]) {
      return true;
    } else {
      return this.router.navigate(['/error'], { state: { status: 404 } });
    }
  }
}
