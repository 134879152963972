import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
@Component({
  selector: 'app-login',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent {
  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  authorize() {
    this.oidcSecurityService.authorize()
  }

}
