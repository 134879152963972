import { Prompt } from "./prompt.model";
import { PromptSettings } from "./prompt-settings.model";
import { Message } from "./message.model";

export class SocialMediaPrompt extends Prompt {
    platforms: string[];
    answers: Message[] = [];

    public constructor(text: string, promptSettings: PromptSettings, parentId? : string, platforms?: string[]){
        super(text, promptSettings, parentId)
        this.platforms = platforms || [];
    }
}