import { Component, ViewEncapsulation, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserData } from 'src/app/shared/models/user-data.model';
import { Author, Message } from 'src/app/shared/models/message.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { ChatComponent } from '../../chat/chat.component';
import { PromptSettings } from 'src/app/shared/models/prompt-settings.model';
import { DrawerComponent } from '../../drawer/drawer.component';
import { KmdModalService } from 'gds-atom-components';
import { Chat } from 'src/app/shared/models/chat.model';
import { ChatService } from 'src/app/core/services/chat/chat.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Prompt } from 'src/app/shared/models/prompt.model';
import { SocialMediaPrompt } from 'src/app/shared/models/social-media-prompt.model';
import { SocialMediaService } from "../../core/services/social-media/social-media.service";
import { PromptInputComponent } from "../../shared/components/prompt-input/prompt-input.component";

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SocialMediaComponent implements OnInit {

  @ViewChild('appChat') chatComponent?: ChatComponent;
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent;
  @ViewChild('promptInputComponent') promptInputComponent!: PromptInputComponent;

  lastSystemMessage: Message | undefined

  userData$: Observable<UserData>;
  selectedChatId!: string;
  chat?: Chat;
  promptSettings: PromptSettings = new PromptSettings(3, "PROFESSIONAL", false, "gpt-4-o");
  isLoading: boolean = false;
  showGeneralAlert = false;
  showInvalidPrompt = false;
  alertText = "We encountered an unexpected error. Please try again.";
  invalidPromptText = 'Sorry, your prompt couldn\'t be processed. Please rephrase or shorten your prompt.<br>Make sure to review and comply with the Gene.AI Use Guidelines';
  activeView = "chat";
  includeWebSearch = false;
  platformList: string[] = [];
  selectedPlatforms: string[] = [];

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private cdRef: ChangeDetectorRef,
    private kmdModalService: KmdModalService,
    private chatService: ChatService,
    private socialMediaService: SocialMediaService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    );
  }

  hasAPrompt(extras?: NavigationExtras): Prompt | undefined {
    const state = extras?.state as { prompt: Prompt };
    return state?.prompt
  }

  ngOnInit() {
    const navigation = this.router.getCurrentNavigation();
    let prompt = this.hasAPrompt(navigation?.extras)
    if (prompt) {
      this.promptInputComponent?.setPrompt(prompt.title ? prompt.text : '');
      this.promptSettings = prompt.promptSettings;
    }

    this.getPlatforms();
    this.route.params.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.selectedChatId = id;
        this.cdRef.detectChanges();

        this.showChat();
        let chat = new Chat("New chat", new Date())
        chat.id = id
        this.chat = chat;
        this.chatService
          .getChatMessages(id)
          .subscribe((newMessages) => {
              this.setMessages(newMessages);
              if (newMessages.length != 0)
                this.promptSettings = newMessages[newMessages.length - 1].promptSettings
              this.isLoading = false;
            }
            , (error) => {
              let errors = [400, 401, 403, 404, 500, 503]
              if (errors.includes(error.status)) {
                this.router.navigate(['/error'], {state: {status: error.status}, replaceUrl: true})
              } else {
                this.router.navigate(['/error'], {state: {status: 500}, replaceUrl: true})
              }
            }
          )
      }
    });
  }

  getPlatforms() {
    this.socialMediaService.getPlatforms().subscribe({
      next: (platforms) => {
        this.platformList = platforms.sort();
      }
    });
  }

  toggleSelection(platform: string) {
    const index = this.selectedPlatforms.indexOf(platform);
    if (index === -1) {
      this.selectedPlatforms.push(platform);
    } else {
      this.selectedPlatforms.splice(index, 1);
    }
  }

  handleResponseSelected(responseText: string) {
    this.promptInputComponent.setAppendPrompt(responseText);
  }

  openDrawer(): void {
    this.drawerComponent.openDrawer();
  }

  onSubmit(inputPrompt: string) {
    if (inputPrompt.trim() == "") return;

    let promptValue = inputPrompt;
    let author = new Author("USER")

    this.kmdModalService.open('loadingResponseModal');
    let prompt = new SocialMediaPrompt(promptValue, this.promptSettings, this.lastSystemMessage?.id);
    prompt.platforms = this.selectedPlatforms;
    let message = new Message(promptValue, author, new Date(), this.promptSettings, this.lastSystemMessage?.id);

    if (this.chat?.id == undefined) {
      this.chatService.socialMediaChat(prompt).subscribe({
        next: (chatAndPrompt) => {
          this.chat = chatAndPrompt.chat;

          message.id = chatAndPrompt.socialMediaPrompt.id
          this.addMessages([message, ...chatAndPrompt.socialMediaPrompt.answers]);
          this.kmdModalService.close('loadingResponseModal');
          this.isLoading = false;
          this.router.navigate(['/tools/marketing-ads/' + this.chat?.id],
            {state: {prompt: chatAndPrompt.socialMediaPrompt}})
        }, error: (err: HttpErrorResponse) => {
          this.handlePromptError(err);
          this.promptInputComponent.setPrompt(promptValue);
        }
      });
    } else {
      this.chatService.socialMediaPrompt(this.chat.id, prompt).subscribe({
        next: (prompt) => {
          message.id = prompt.id
          message.parentId = prompt.parentId

          this.addMessages([message, ...prompt.answers]);
          this.kmdModalService.close('loadingResponseModal');
          this.isLoading = false;
        }, error: (err: HttpErrorResponse) => {
          this.handlePromptError(err);
          this.promptInputComponent.setPrompt(promptValue);
        }
      });
    }
    this.selectedPlatforms = [];
  }

  handlePromptError(error: HttpErrorResponse) {
    this.kmdModalService.close('loadingResponseModal');
    this.showGeneralAlert = error.status == 500;
    this.showInvalidPrompt = error.status == 400;
  }

  addMessages(newMessages: Message[]) {
    this.selectedChatId = "";
    this.cdRef.detectChanges();
    newMessages.forEach(m => {
      this.chatComponent?.addMessage(m)
    })
    this.cdRef.detectChanges();
  }

  setMessages(newMessages: Message[]) {
    this.chatComponent?.setMessages(newMessages)
    this.cdRef.detectChanges();
  }

  showChat(event?: any) {
    event?.preventDefault()
    this.activeView = "chat"
  }

  closeDrawer(): void {
    this.drawerComponent.dismissDrawer();
  }

  newSocialMediaChat() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate(['/tools/marketing-ads']));
  }

}
