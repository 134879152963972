import { Observable, of } from 'rxjs';
import { GuardChecker } from './guard-checker-interface';
import { AskOurDocsRoleGuard } from "../guards/ask-our-docs-role.guard";
import { featureFlags } from "@environments/environment";

export class AskOurDocsRoleGuardChecker implements GuardChecker {
  check(guard: AskOurDocsRoleGuard): Observable<boolean> {
    if(featureFlags.askOurDocsPage) return of(true);
    return guard.hasAskOurDocsRoles()
  }
}
