import { Component, Input } from '@angular/core';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-circle-with-initials',
  template: `
    <div [className]="user === 'AskGene' ? 'circle ask-gen-circle' : 'circle'">
      <span id="user-circle-initials" *ngIf="user === 'User'" class="initials">{{ getInitials() }}</span>
      <fa-icon *ngIf="user === 'AskGene'" [icon]="faCommentDots"></fa-icon>
    </div>
  `,
  styleUrls: ['./circle-with-initials.component.css']
})
export class CircleWithInitialsComponent {

  @Input() user!: string;
  @Input() username!: string;

  faCommentDots = faCommentDots;

  getInitials() {
    if (this.username == undefined || this.username == "")
      return "N/A";

    let splitString = this.username.split(',');
    if (splitString.length == 1)
      splitString = this.username.split(' ');

    let initials = (splitString[1].trim().charAt(0) + splitString[0].trim().charAt(0));
    if (initials == "")
      return "N/A";

    return initials.toUpperCase();
  }
}
