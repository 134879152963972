import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ChatService } from '@app/core/services/chat/chat.service';
import { PagedChatMetaData } from '@app/shared/models/paged-chat-metadata.model';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  merge,
  Observable,
  of, Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { KmdPaginationComponent } from "gds-atom-components";
import { NavigationCacheService } from '@app/core/services/navigation-cache/navigation-cache.service';
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";

@Component({
  selector: 'app-chat-history-new',
  templateUrl: './chat-history-new.component.html',
  styleUrls: ['./chat-history-new.component.css']
})
export class ChatHistoryNewComponent implements OnInit, OnDestroy {
  @ViewChild('pagination') pagination!: KmdPaginationComponent;
  readonly RESULTS_PER_PAGE = 25;
  readonly DEBOUNCE_TIME = 500;
  loaded = false;

  pageFormControl = new FormControl(0, {nonNullable: true})
  searchFormGroup = new FormGroup({
    search: new FormControl('', {nonNullable: true})
  });
  allChats$: Observable<PagedChatMetaData> = of();
  private destroy$ = new Subject<void>();

  constructor(
    private chatService: ChatService,
    private alertService: AlertService,
    private navigationCacheService: NavigationCacheService,
    private chatHistoryUpdateService: ChatHistoryUpdateService
  ) {
    this.navigationCacheService.clearData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.loaded = true;
    let initialChats$ = this.getPagedChats(0, this.RESULTS_PER_PAGE);

    let paginatedChats$ = this.pageFormControl.valueChanges.pipe(
      switchMap((page) => this.getPagedChats(page, this.RESULTS_PER_PAGE, this.searchFormGroup.controls.search.value))
    );

    let searchObservable = this.searchFormGroup.controls.search.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(this.DEBOUNCE_TIME),
      tap(() => this.loaded = false),
      switchMap(() => {
        this.reloadAgentsToFirstPage();
        return EMPTY;
      })
    );

    let updatesObservable = this.chatHistoryUpdateService.updates().pipe(
      filter((update: string | undefined) => !update),
      switchMap(() => this.getPagedChats(this.pageFormControl.value, this.RESULTS_PER_PAGE,
        this.searchFormGroup.controls.search.value).pipe(
        map(newChats => ({...newChats, content: [...newChats.content]}))
      ))
    );

    this.allChats$ = merge(initialChats$, paginatedChats$, searchObservable, updatesObservable).pipe(
      takeUntil(this.destroy$)
    );
  }

  onPageChange(event: any) {
    this.pageFormControl.patchValue(event.currentPage - 1);
  }

  private reloadAgentsToFirstPage() {
    if (this.pagination) {
      this.pagination.selectPage(1);
    } else {
      this.pageFormControl.patchValue(0);
    }
    return EMPTY;
  }

  private getPagedChats(page: number, resultsPerPage: number, filter: string = ''): Observable<PagedChatMetaData> {
    let observableOfEmptyPagedChats: Observable<PagedChatMetaData> = of({
      content: [], totalPages: 0,
      numberOfElements: 0, empty: true, first: true, totalElements: 0, last: true, size: 0, number: 0
    });

    return this.chatService.pagedChats(page, resultsPerPage, filter).pipe(
      tap((chats) => {
        if (!filter) this.loaded = chats.content.length === 0;
      }),
      map(pagedChats => {
        return pagedChats;
      }),
      catchError(() => {
        this.alertService.showError('We encountered an unexpected error. Please try again.');
        return observableOfEmptyPagedChats;
      })
    );
  };
}
