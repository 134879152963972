import { LogLevel } from "angular-auth-oidc-client";
import EnvFeatureFlags from "@app/feature-flags/env.FeatureFlags.json";

export const environment = {
  apiUrl: 'https://gene-ai-api.tfcdev.thermofisher.net/api/dpe-gene-ai',
  askOurDataApiUrl: 'https://gene-ai-solutions-ask-our-data.tfcdev.thermofisher.net/api/gene-ai-solutions-ask-our-data',
  authAppId: '48bd3841-c7fc-4dd2-b973-080d1d7e1b47',
  resourcesUrl: 'https://geneai.tfcdev.thermofisher.com/resources/',
  betaLink: 'https://geneai.tfcstage.thermofisher.com',
  deleteFileUploadFlag: true,
  webSearchFlag: true,
  apjAlbIframeUrl: 'https://geneaiapac.thermofisher.com/ai/',
  askOurDocsIframeUrl: 'https://askourdocs-webapp-temp-eus2-01.azurewebsites.net',
  authLogLevel: LogLevel.Debug,
  aodSuperAdminGroupId: 'dcb24beb-2cbf-4976-84ce-0269625aae97',
  svabaseUrl: 'https://sva-api.geneai.tfcdev.thermofisher.com',
  svanotificationsUrl: 'https://sva-api.geneai.tfcdev.thermofisher.com/nm/nmsvc',
  svasessionUrl: 'https://sva-api.geneai.tfcdev.thermofisher.com/platformheader'
};

export const featureFlags = {
  capabilities: true,
  promptSharing: true,
  languageTranslation: true,
  socialMedia: true,
  askOurDocs: true,
  savedPromptsAccordion: false,
  savedPromptsPaginated: true,
  agents: true,
  imageCreation: true,
  agentSharing: true,
  askMyDocsTool: true,
  askOurDocsTool: true,
  askOurDocsPage: true,
  askOurDocsUserManagementPage: true,
  askOurDocsAdminList: false,
  agentRating: true,
  paginatedAgents: true,
  nonPaginatedAgents: false,
  recommendedAgents: true,
  enableGPT4o: true,
  streamingGPT: true,
  streamingRegenerated: true,
  askOurData: true,
  editPublicAgent: true,
  agentStreaming: true,
  sva2: true,
  uxRevamp: true,
  maintenanceMode: typeof EnvFeatureFlags.maintenanceMode === 'boolean' ? EnvFeatureFlags.maintenanceMode : false,
  askMyCsv: true,
  enableAoDataChart: true,
  regenerateResponse: true,
  sqlCuration: true,
  chatSummary: true,
  fileManagerSearchBar: true,
  codeInterpreter: true,
  conversationChat: true,
  customTooltip: true
};

export const askOurDocsRoles = {
  IndexPrefix: "dpe_geneai_index_"
}

export const askOurDataRole = {
  prefix: "dpe_geneai_aodata_"
}

export const sva2Roles = {
  Index: "dpe_geneai_index_Services_Virtual_Assistant"
}
