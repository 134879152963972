<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M22 4.12001L19.88 2L12 9.88L4.12 2L2 4.12001L9.88 12L2 19.88L4.12 22L12 14.12L19.88 22L22 19.88L14.12 12L22 4.12001Z"
    [attr.fill]="color"
  />
</svg>