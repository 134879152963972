<div #scrollDiv class="content-agents-list" [ngClass]="{'blur-style' : isChatVisible()}">
  <div class="agent-list">
    <div class="agents-page-bar">
      <kmd-alerts kmd-error [(show)]="showErrorAlert" [alertText]="alertText"></kmd-alerts>
      <kmd-alerts kmd-success [(show)]="showSuccessAlert" [alertText]="alertText" [icon]="true"
                  [floating]="true"></kmd-alerts>
      <div class="agent-list-toolbar-content">
        <div class="agent-title-section">
          <div class="agents-list-title">
            <h1>Agents</h1>
            <core-badge class="beta-badge" variant="standard" size="regular" color="green" label="Beta"/>
          </div>
          <div class="toolbar-buttons">
            <button *ngIf="!featureFlags.uxRevamp" kmdPrimaryButton id="agent-chat-history" (click)="toggleChatHistoryState()"
                    class="btn-view-prompts header-btn trigger-toggle-nav-menu">
              <i class="ic ic-chat-history"></i>
              Agents Chat History
            </button>
            <button kmdFeaturedButton id="new-agent-top" class="agent-btn" (click)="goToCreateNewAgent()">
              <img class="icon-agents icon-sm-new-agent" src="../../../assets/icons/plus/plus-16px-mono.svg">
              Create New Agent
            </button>
            <fa-icon *ngIf="!featureFlags.uxRevamp" class="ellipsis-btn" [icon]="faEllipsis" (click)="showEllipsisMenu($event)"></fa-icon>
          </div>
        </div>
        <div class="agent-description-section">
          <span>
            <p>Agents are digital assistants that can enhance your work efficiency by executing specific tasks or
              responding to your questions.</p>
            <p>Check out our <a href="/guidelines" target="_blank">Agent Sharing Guidelines</a> for best practices.</p>
          </span>
        </div>
      </div>
    </div>
    <div class="agents-list-section">
      <core-tabs-container id="agents-tab-container" position="columns">
        <core-tabs id="agents-tabs" variant="default">
          <core-tabs-item (tabSelected)="handleTabChange(tabChangeEvents.MY_AGENTS)"
                          label="My Agents"
                          variant="default"
                          [selected]="myAgentsTabSelected"
                          targetid="my-agents-tab"
                          *featureFlag="'nonPaginatedAgents'"/>
          <core-tabs-item label="My Agents"
                          id="my-agents-tab-item"
                          variant="default"
                          targetid="my-agents-paginated-tab"
                          [selected]="myAgentsTabSelected"
                          (tabSelected)="handleTabChange(tabChangeEvents.PAGE_MY_AGENTS)"
                          *featureFlag="'paginatedAgents'"/>
          <core-tabs-item (tabSelected)="handleTabChange(tabChangeEvents.PUBLIC_AGENTS)"
                          label="Public Agents"
                          [selected]="publicAgentTabSelected"
                          variant="default"
                          targetid="public-agents-tab"
                          *featureFlag="['agentSharing','nonPaginatedAgents']"/>
          <core-tabs-item label="Public Agents"
                          id="public-agents-tab-item"
                          variant="default"
                          targetid="public-agents-paginated-tab"
                          [selected]="publicAgentTabSelected"
                          (tabSelected)="handleTabChange(tabChangeEvents.PAGE_PUBLIC_AGENTS)"
                          *featureFlag="['agentSharing','paginatedAgents']"/>
        </core-tabs>
        <core-tabs-content>
          <div id="my-agents-tab">
            <ng-container *ngTemplateOutlet="myAgentsTabView" />
          </div>
          <div id="my-agents-paginated-tab">
            <ng-container *ngTemplateOutlet="myAgentsPagedTabView"/>
          </div>
          <div id="public-agents-tab" *featureFlag="'agentSharing'">
            <ng-container *ngTemplateOutlet="publicAgentsTabView"/>
          </div>
          <div id="public-agents-paginated-tab" *featureFlag="'agentSharing'">
            <ng-container *ngTemplateOutlet="publicAgentsPagedTabView"/>
          </div>
        </core-tabs-content>
      </core-tabs-container>
    </div>
  </div>
</div>

<kmd-modal [id]="'delete-agent-modal'" modalSize="mini" headline="Delete agent">
  <div id="text">
    Are you sure you want to delete this agent? This action can't be undone, and previous chats with this agent will
    also be deleted.
  </div>
  <div class="modal-buttons-container">
    <button (click)="closeModal('delete-agent-modal')" kmdSecondaryButton>Cancel</button>
    <button (click)="deleteAgent()" kmdFeaturedButton>Delete</button>
  </div>
</kmd-modal>

<kmd-modal [id]="'delete-public-agent-modal'" modalSize="mini" headline="Delete Public Agent">
  <div id="public-text">
    Are you sure you want to delete this agent? This action can't be undone, and previous chats will be deleted.
  </div>
  <div class="modal-buttons-container">
    <button (click)="closeModal('delete-public-agent-modal')" kmdSecondaryButton>Cancel</button>
    <button (click)="deletePublicAgent()" kmdFeaturedButton>Delete</button>
  </div>
</kmd-modal>

<kmd-modal [id]="'view-agent-modal'" modalSize="large" headline="Agent Overview">
  <app-agent-overview [agent]="overviewAgent!"></app-agent-overview>
</kmd-modal>

<kmd-modal [id]="'public-agent-overview'" modalSize="large" headline="Agent Overview" subheading="test">
  <app-agent-public-overview [agent]="publicOverviewAgent!" (onMakeAgentPublicEvent)="publishAgent($event)"
                             (onCancelEvent)="closeModal($event)"/>
</kmd-modal>

<kmd-popover #popOver [positionX]="posX" [positionY]="posY" id="actions-menu">
  <ul class="agent-menu-ul">
    <li class="agent-menu-li" (click)="toggleChatHistoryFromMenu()">Chat History
    </li>
  </ul>
</kmd-popover>

<chat-history *ngIf="!featureFlags.uxRevamp" appToggleChatHistory (ToggleChatHistoryEvent)="toggleByDirective()" [@slideInOut]="chatHistoryState"
              #appMenu (selectedChatEvent)="selectedChat($event)">
  <i class="kmd-icon-close-mono trigger-toggle-nav-menu" (click)="toggleChatHistoryState()"></i>
</chat-history>

<ng-template #myAgentsTabView>
  <div class="agents-list-content">
    <div *ngIf="agents.length > 0 && isAgentsLoaded; else noAgentsView" class="with-agents-view">
      <app-agent-list-toolbar [agents]="agents" [type]="'myAgent'" (onFilteredAgentsEvent)="onFilteredAgents($event)"/>
      <div class="agent-list-filtered">
        <app-agent-card #agentCard *ngFor="let agent of filteredAgents"
                        [agent]="agent"
                        [scrollDiv]="scrollDiv"
                        [type]="'myAgent'"
                        (deleteAgentEvent)="onDeleteAgent($event, 'delete-agent-modal')"
                        (onShareAgentEvent)="showPublicAgentOverviewModal(agent)"
                        [username]="(userData$ | async)?.name">
          <core-badge share-status-badge id="share-status-badge" *ngIf="isPublicAgent(agent)" variant="standard"
                      size="regular" color="blue" label="Public"/>
        </app-agent-card>
      </div>
      <div *ngIf="filteredAgents.length === 0; then noAgentsView"></div>
    </div>
  </div>
</ng-template>

<ng-template #myAgentsPagedTabView>
  <app-agent-list-paged #pagedPrivateAgents
                        [type]="'private'"
                        [userData$]="userData$"
                        [isUserApprover]="isUserApprover"
                        [scrollDiv]="scrollDiv"
                        *featureFlag="'paginatedAgents'"/>
</ng-template>

<ng-template #publicAgentsPagedTabView>
  <app-agent-list-paged #pagedPublicAgents
                        [type]="'public'"
                        [userData$]="userData$"
                        [isUserApprover]="isUserApprover"
                        [scrollDiv]="scrollDiv"
                        *featureFlag="'paginatedAgents'"/>
</ng-template>

<ng-template #myAgentsTabView>
</ng-template>

<ng-template #publicAgentsTabView>
  <div class="agents-list-content">
    <div *ngIf="agents.length > 0 && isAgentsLoaded; else noPublicAgentsView" class="with-agents-view">
      <app-agent-list-toolbar [agents]="agents"
                              [type]="'publicAgent'"
                              (onFilteredAgentsEvent)="onFilteredAgents($event)"/>
      <div class="agent-list-filtered">
        <app-agent-card #agentCard *ngFor="let agent of filteredAgents"
                        [agent]="agent"
                        [scrollDiv]="scrollDiv"
                        [type]="'publicAgent'"
                        (onViewAgentEvent)="showAgentOverviewModal(agent)"
                        [isUserApprover]="isUserApprover"
                        [displayRating]="true"
                        (deleteAgentEvent)="onDeleteAgent($event, 'delete-public-agent-modal')"
                        (onRecommendAgentEvent)="onRecommendAgent(agent)">
        </app-agent-card>
      </div>
      <div *ngIf="filteredAgents.length === 0; then noPublicAgentsView"></div>
    </div>
  </div>
</ng-template>

<ng-template #noPublicAgentsView>
  <ng-container *ngIf="isAgentsLoaded">
    <div id="no-public-agents-label-wrapper">
      <h2>No public agents at this time</h2>
    </div>
  </ng-container>
</ng-template>

<ng-template #noAgentsView>
  <ng-container *ngIf="filteredAgents.length === 0 && isAgentsLoaded">
    <div class="no-agents-view">
      <h3 class="no-agent-header">What Can Agents Do For You</h3>
      <div class="agents-capabilities">
        <div class="agent-capability">
          <img src="../../../assets/images/agents-robot.svg">
          <span>Agents allow you to create a specific role, called a persona, by adding instructions and knowledge that
            is specific for your needs.</span>
        </div>
        <div class="agent-capability">
          <img src="../../../assets/images/agents-rocket.svg">
          <span>Agents can assist you with tasks or answers queries on a wide range of topics through the chat
            interface.</span>
        </div>
        <div class="agent-capability">
          <img src="../../../assets/images/agents-message.svg">
          <span>Agents understand your questions through their preset instructions and deliver personalized
            responses.</span>
        </div>
      </div>
      <button kmdFeaturedButton id="new-agent-bottom" class="agent-btn" (click)="goToCreateNewAgent()">
        <img class="icon-agents icon-sm-new-agent" src="../../../assets/icons/plus/plus-16px-mono.svg">
        Create new Agent
      </button>
    </div>
  </ng-container>
</ng-template>
