import { HttpErrorResponse } from "@angular/common/http";

export class ApiError extends HttpErrorResponse {
  public detail?: any;

  constructor(
    responseError: HttpErrorResponse,
  ) {
    super({
      error: responseError.error,
      headers: responseError.headers,
      status: responseError.status,
      statusText: responseError.statusText,
      url: responseError.url || undefined,
    });

    this.detail = responseError.error?.detail;
  }
}
