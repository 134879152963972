<div class="content-translations">
  <div class="title">
    <div class="toolbar-content-translations">
      <kmd-alerts kmd-error [(show)]="showErrorAlert" [alertText]="errorAlertText" [dismissible]="true"></kmd-alerts>
      <kmd-alerts kmd-success [(show)]="isCopied" [icon]="true" [alertText]="alertCopyText" [floating]="true" id="copy-success-alert"></kmd-alerts>
      <h1>Language translation</h1>
    </div>
  </div>

  <div class="container">
    <div class="disclaimer">
      <span>The Gene.AI Translation capability is to be used for internal and informal content only.
        For formal, external-facing material, please follow the approved channels listed
        <a href="https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Education%20%26%20resources/Using-Gene.AI-for-language-translation.aspx" target="_blank">here</a>.</span>
    </div>
    <div class="container-texts">
      <div class="input-text">
        <div class="label">
          <span>Enter text to translate</span>
          <p>*</p>
        </div>
        <textarea id="inputText" [(ngModel)]="inputText" maxlength="{{maxInputCharLimit()}}"></textarea>
        <div class="bottom-container">
          <countdown-label id="translationsCountdownLabel" [maxlength]=maxInputCharLimit() [value]="inputText" />
          <span id="clearText" (click)="clearText()" [ngClass]="isClearTextEnabled()  ? 'clear-enabled' : 'clear-disabled'">
            <img src="assets/icons/close/size=16px,%20style=mono.svg"
                 id="clearTextIcon"><span>Clear</span>
        </span>
        </div>
      </div>
      <div class="output-text">
        <div class="controls">
          <kmd-dropdown [options]="languageList" optionLabelKey="name"
                        [inlineLabel]="dropdownLabel" (onChange)="onLanguageChange($event.selectedOption)"></kmd-dropdown>
          <button id="translate" kmdFeaturedButton (click)="translate()"
                  [disabled]="!isTranslateEnabled()">
            Translate
          </button>
        </div>
        <div>
          <textarea id="response" [(ngModel)]="response" readonly></textarea>
        </div>
        <div class="copy-response-container">
        <span class="copy-button" (click)="copyResponse()">
          <div [ngClass]="isCopyEnabled()  ? 'copy-enabled' : 'copy-disabled'">
            <img src="assets/icons/copy/Size=24px,%20Style=Mono.svg" class="gene-icon"
                 id="copy-response-icon"><span>Copy</span>
          </div>
        </span>
        </div>
      </div>
    </div>
  </div>
  <kmd-modal class="loading-modal" [id]="'loadingResponseModal'" modalSize="small" [closeOnClickingOutside]="false">
    <div>Generating response</div>
    <kmd-loading-wheel></kmd-loading-wheel>
  </kmd-modal>
</div>
