import { Component, ElementRef, HostListener, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavBarService } from '@app/core/services/navbar/navbar.service';
import {
  NavBarScreenResolutionSetting,
  NavBarScreenResolutionSettings
} from '@app/shared/constants/navbar/screen-resolutions';
import { MenuOption } from '@app/shared/models/navbar/menu-option';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css']
})
export class SubMenuComponent implements OnInit {

  @Input() isMenuCollapsed!: boolean;
  @Input() submenuOptions: MenuOption[] = [];
  @ViewChild('contextPopOver') contextPopOver!: ElementRef;
  popoverPosition = { top: 0, left: 3000 };
  currentScreenResolutionSetting: NavBarScreenResolutionSetting = NavBarScreenResolutionSettings.mobile;
  private resizeSubscription: Subscription = new Subscription;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    protected navBarService: NavBarService
  ) {
  }

  ngOnInit(): void {
    this.navBarService.currentScreenResolutionSetting$.subscribe(resolution => {
      this.currentScreenResolutionSetting = resolution;
    });
    this.resizeSubscription = this.navBarService.resizeTriggered$.subscribe(() => {
      this.hidePopover();
    });
  }

  showPopover(event: MouseEvent): void {
    event.stopPropagation();

    if (this.popoverPosition.top != 0) {
      this.hidePopover();
    } else {
      this.setPopoverPosition(event);
    }
  }

  onSubMenuOptionClick(event$: MouseEvent, subOption: any): void {
    if (window.innerWidth <= NavBarScreenResolutionSettings.mobile.size) {
      this.navBarService.setMobileResolution();
    }
    this.navigateTo(subOption);
    this.hidePopover();
  }

  setPopoverPosition(event: MouseEvent) {

    if (event.currentTarget instanceof HTMLElement) {

      let navBarContainer = document.querySelector('.nav-menu-wrapper .nav-menu');
      let navBarWidth = navBarContainer?.clientWidth || 0;
      const contextPopOverElement = this.contextPopOver.nativeElement;

      const currentTarget = event.currentTarget;
      const boundingRect = currentTarget.getBoundingClientRect();
      const isMobile = this.isMobileResolution();

       if (isMobile) {
        this.navBarService.setTabletResolution();
          setTimeout(() => {
          const newCurrentTarget = document.getElementById(currentTarget.id);
          const newBoundingRect = newCurrentTarget!.getBoundingClientRect();
          navBarContainer = document.querySelector('.nav-menu-wrapper .nav-menu');
          navBarWidth = navBarContainer!.clientWidth || 0;
          this.popoverPosition = {
            left: navBarWidth,
            top: (newBoundingRect!.top - contextPopOverElement.clientHeight) + newBoundingRect!.height  || 0
          };
          return;
         });
      }

      this.popoverPosition = {
        left: navBarWidth,
        top: (boundingRect.top - contextPopOverElement.clientHeight) + boundingRect.height
      };
    }
  }

  private isMobileResolution(): boolean {
    return (
      this.currentScreenResolutionSetting === NavBarScreenResolutionSettings.mobile ||
      window.innerWidth <= NavBarScreenResolutionSettings.mobile.size
    );
  }

  navigateTo(subMenuOption: MenuOption) {
    if (!subMenuOption.anchor) return;

    if (subMenuOption.anchor.type === 'href') {
      window.open(subMenuOption.anchor.value, '_blank');
      return;
    }

    if (!this.router.url.includes(subMenuOption.anchor.value)) {
      this.ngZone.run(() => {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate([subMenuOption.anchor?.value]));
      });
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (!(event.target as HTMLElement).closest('#context-pop-over')) {
      this.hidePopover();
    }
  }

  hidePopover(): void {
    this.popoverPosition = { top: 0, left: 3000 };
  }
}
