import { Inject, Injectable } from '@angular/core';
import { PromptService } from './prompt.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Prompt } from '@shared/models/prompt.model'
import { PromptExample } from 'src/app/shared/models/prompt-example.model';
import { PromptSearch } from "@shared/models/prompt-search.model";
import { PagedPrompts } from "@shared/models/pagedprompts.model";
import { SharedPromptCategory } from "@shared/models/shared-prompt-category.model";

@Injectable({
  providedIn: 'root'
})
export class PromptGeneApiService implements PromptService {

  constructor(@Inject('BASE_API_URL') private baseUrl: string, private http: HttpClient) {
  }

  save(prompt: Prompt): Observable<Prompt> {
    return this.http.post<Prompt>(this.baseUrl + '/v1/prompts', prompt, this.getHeaders())
  }

  prompts(type?: string, visibility?: string): Observable<Array<Prompt>> {
    const options = {
      params: {},
      headers: {
        'Content-type': 'application/json'
      }
    };
    if (type) {
      options.params = {
        type: type!
      }
    } else if (visibility) {
      options.params = {
        visibility: visibility!
      }
    }
    return this.http.get<Array<Prompt>>(this.baseUrl + '/v1/prompts', options)
  }

  promptCategories(type?: string): Observable<Array<string>> {
    const options = {
      params: {},
      headers: {
        'Content-type': 'application/json'
      }
    }
    if (type) {
      options.params = {type: type}
    }
    return this.http.get<Array<string>>(this.baseUrl + '/v1/prompts/categories', options)
  }

  getPublicPromptCategories(): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.baseUrl + '/public/v1/prompts/categories', this.getHeaders());
  }

  delete(promptId: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `/v1/prompts/${ promptId }`, this.getHeaders());
  }

  update(prompt: Prompt): Observable<void> {
    return this.http.put<void>(this.baseUrl + `/v1/prompts`, prompt, this.getHeaders());
  }

  promptExamples(type: string): Observable<Array<PromptExample>> {
    return this.http.get<Array<PromptExample>>(this.baseUrl + `/v1/prompts/examples?type=${ type }`, this.getHeaders());
  }

  sharePrompt(promptId: string, sharedPromptCategory: SharedPromptCategory): Observable<void> {
    return this.http.post<void>(this.baseUrl + `/v1/prompts/${ promptId }/share`, sharedPromptCategory, this.getHeaders())
  }

  getPageablePrompts(promptSearch: PromptSearch, type?: string): Observable<PagedPrompts> {
    const options = {
      params: {},
      headers: {
        'Content-type': 'application/json'
      }
    };
    if(type)
      options.params = {
        type: type
      }
    return this.http.post<PagedPrompts>(this.baseUrl + `/v1/prompts/page`, promptSearch, options);
  }

  getPageablePublicPrompts(promptSearch: PromptSearch): Observable<PagedPrompts> {
    return this.http.post<PagedPrompts>(this.baseUrl + `/public/v1/prompts`, promptSearch, this.getHeaders());
  }

  deletePublicPrompt(publicPromptId: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `/public/v1/prompts/${publicPromptId}`, this.getHeaders());
  }

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }
}
