import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FileTranslationsManagerUpload
} from "@shared/models/file-translations-manager/file-translations-manager-upload.model";
import {
  FileTranslationsManagerService
} from "@services/file-translations/file-manager/file-translations-manager.service";
import { FileTranslationsUploadStatus } from "@shared/constants/file-translations/file-translations-upload-status";
import {
  FileTranslationsProcessingStatus
} from "@shared/constants/file-translations/file-translations-processing-status";
import { KmdModalService } from "gds-atom-components";
import {
  FileUploadEvent
} from "@app/tools/translations/file-translations-manager/file-translations-uploader/file-translations-uploader.component";

@Component({
  selector: 'app-file-translations-upload-container',
  templateUrl: './file-translations-upload-container.component.html',
  styleUrl: './file-translations-upload-container.component.css'
})
export class FileTranslationsUploadContainerComponent {
  @Input() uploadFiles: FileTranslationsManagerUpload[] = [];
  @Output() uploadFilesChange = new EventEmitter<FileTranslationsManagerUpload[]>();
  @Output() fileEvents = new EventEmitter<FileUploadEvent>();

  onFileUploaded(file: FileTranslationsManagerUpload): void {
    this.fileEvents.emit({ type: 'uploaded', data: file });
  }

  onFileUploadError(): void {
    this.fileEvents.emit({ type: 'error' });
  }

  onFileUploadRemoved(): void {
    this.fileEvents.emit({ type: 'removed' });
  }

  onFileUploading(): void {
    this.fileEvents.emit({ type: 'uploading' });
  }

  readonly uploadStatus = FileTranslationsUploadStatus;
  readonly processingStatus = FileTranslationsProcessingStatus;

  removeFileId: string = "";

  constructor(
    private fileTranslationsManagerService: FileTranslationsManagerService,
    private kmdModalService: KmdModalService
  ) {}

  getMobileDisplayName(name: string): string {
    return this.fileTranslationsManagerService.getParseFileNameToDisplayName(name, 25);
  }

  getUploadFileStatus(data: FileTranslationsManagerUpload): string {
    //PreUpload validation catches start
    if(data.status === this.processingStatus.FILE_SIZE_EXCEEDED){
      return this.processingStatus.FILE_SIZE_EXCEEDED;
    }
    if(data.status === this.processingStatus.LANGUAGE_ERROR){
      return this.processingStatus.LANGUAGE_ERROR;
    }
    if(data.status === this.processingStatus.UNSUPPORTED_LANGUAGE){
      return this.processingStatus.UNSUPPORTED_LANGUAGE;
    }
    if(data.status === this.processingStatus.PASSWORD_PROTECTED){
      return this.processingStatus.PASSWORD_PROTECTED;
    }

    //PreUpload validation end

    const isUploadInProgress = data.status === FileTranslationsUploadStatus.IN_PROGRESS;
    if (isUploadInProgress) {
      this.onFileUploading();
      return this.processingStatus.UPLOAD_IN_PROGRESS;
    }

    const isUploadError = data.status;

    if (isUploadError === this.uploadStatus.ERROR) {
      this.onFileUploadError()
      return this.processingStatus.UPLOAD_ERROR;
    }

    if (isUploadError === this.uploadStatus.FILE_SIZE_EXCEEDED) {
      return this.processingStatus.FILE_SIZE_EXCEEDED;
    }

    if (isUploadError === this.uploadStatus.PASSWORD_PROTECTED) {
      return this.processingStatus.PASSWORD_PROTECTED;
    }

    this.emitAndRemoveFile(data);
    return this.processingStatus.UPLOAD_IN_PROGRESS;
  };

  emitAndRemoveFile(data: FileTranslationsManagerUpload): void {
    this.onFileUploaded(data)
    this.removeFileData(data);
  }

  removeFileData(data: FileTranslationsManagerUpload): void {
    const index = this.uploadFiles.findIndex((uploadFile) => uploadFile.file === data.file);

    if (index >= 0) {
      const updatedFiles = [...this.uploadFiles];
      updatedFiles.splice(index, 1);
      if (data.cancel) {
        data.cancel();
      }
      this.uploadFilesChange.emit(updatedFiles);
    }

    this.onFileUploadRemoved();
  };

  showClearFileUploadModal(data : FileTranslationsManagerUpload): void {
    if (data.id && data.status == this.uploadStatus.IN_PROGRESS) {
      this.removeFileId = data.id;
      this.openClearFileUploadModal();
    } else {
      this.removeFileData(data);
    }
  }

  removeFile(): void {
    const uploadManager = this.uploadFiles.find((uploadFile) => uploadFile.id === this.removeFileId);
    const updatedFiles = this.uploadFiles.filter(uploadFile => uploadFile.id !== this.removeFileId);
    if (uploadManager && uploadManager.cancel) {
      uploadManager.cancel();
    }
    this.uploadFilesChange.emit(updatedFiles);

    this.removeFileId = "";
    this.closeClearFileUploadModal();
  }

  openClearFileUploadModal(): void {
    this.kmdModalService.open('clear-file-upload-modal');
  }

  closeClearFileUploadModal(): void {
    this.kmdModalService.close('clear-file-upload-modal');
  }

  isUploadProgressDisplayed(data: FileTranslationsManagerUpload): boolean {
    return data.status === this.uploadStatus.IN_PROGRESS;
  };

  getReadableFileSize(size: number): string {
    return this.fileTranslationsManagerService.getReadableFileSize(size);
  }

  isUploadStatusInProgress(status: string | undefined): boolean {
    return status === this.uploadStatus.IN_PROGRESS;
  }

  isStatusErrorFileSizeExceeded(status: string): boolean {
    return status === this.uploadStatus.FILE_SIZE_EXCEEDED;
  }

  isStatusErrorPasswordProtected(status: string): boolean {
    return status === this.uploadStatus.PASSWORD_PROTECTED;
  }

  getFileStatusClass(status: string): string {
    if (this.isStatusErrorFileSizeExceeded(status)) {
      return 'file-size-exceeded-status-icon';
    }
    if (this.isStatusErrorPasswordProtected(status)) {
      return 'password-protected-status-icon';
    }
    return 'processed-file-status-icon';
  }
}
