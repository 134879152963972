<div [@slideInOut]="menuState" class="nav-menu">
  <ul class="nav-menu-ul">
    <ng-container *ngFor="let option of menu">
      <ng-container *ngIf="shouldDisplayOption(option) | async">
        <div (click)="isExternalLink($event)">
          <app-menu-option [menuOption]="option" (subMenuExpandedEvent)="toggleExpanded($event)"></app-menu-option>
        </div>
      </ng-container>
    </ng-container>
  </ul>
</div>