import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type ModalState = 'open' | 'closed' | 'hidden';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: { [key: string]: Subject<ModalState> } = {};

  registerModal(name: string) {
    if (!this.modals[name]) {
      this.modals[name] = new Subject<ModalState>();
    }
  }

  open(name: string) {
    if (this.modals[name]) {
      this.modals[name].next('open');
    }
  }

  close(name: string) {
    if (this.modals[name]) {
      this.modals[name].next('closed');
    }
  }

  show(name: string) {
    this.open(name);
  }

  hide(name: string) {
    if (this.modals[name]) {
      this.modals[name].next('hidden');
    }
  }

  getModalState(name: string) {
    return this.modals[name]?.asObservable();
  }
}
