
<core-modal #chatHistoryModal class="chat-history-modal" closeicon="true" header="History" bgclose="isMobileScreen()" 
[width]="widthVal" >
<div class="mt-3" >
  <div class="search-container mb-2">
    <div class="search-wrapper library-icomoon">
      <i class="icon-search-mono data-search"></i>
      <input kmdFormInput type="text" [(ngModel)]="searchText" (keyup)="filterData()" placeholder="Search text input" class="ps-4 search-input" />
      <i *ngIf="searchText" class="icon-close-bold srch-clear" (click)="clearSearch()"></i>
    </div>
  </div>
  <div class="table-container">
    <div class="alert-container">
      <kmd-alerts *ngIf="errorMessage" kmd-error [floating]="true" [show]="!!errorMessage" [icon]="true">{{errorMessage}}</kmd-alerts>
      <kmd-alerts *ngIf="successMessage" kmd-success [floating]="true" [show]="!!successMessage" [icon]="true">{{successMessage}}</kmd-alerts>
    </div>
    <div class="zero-state-container margin-auto my-4" *ngIf="emptyHistory">
      <div class="zero-state-header d-flex justify-content-center">Start your chat history</div>
      <div class="zero-state-sub-header d-flex justify-content-center">Here you will see all your chats</div>
    </div>
    <div *ngIf="!emptyHistory" [ngClass]="{'table-div': !isMobileScreen()}" class="history-table">
      <p-table [value]="filteredList" editMode="row" > 
        <ng-template pTemplate="header">
          <tr *ngIf="!isMobileScreen()" style="border-bottom: 1px solid #92929E;">
            <th style="width:20%">Date</th>
            <th style="width:75%">Title</th>
            <th style="width:5%">&nbsp;</th>
          </tr>
          <tr *ngIf="isMobileScreen()" style="border-bottom: 1px solid #92929E;">
            <th>By date</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-history let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="history" [ngClass]="{'highlight-tr': isEditing(history)}" >
              <td *ngIf="!isMobileScreen()" (click)="rowClick(history)">
                  {{getDate(history.timestamp) | date:'dd MMM yy HH:mm'}} 
              </td>
              <td (click)="rowClick(history)" title="{{history.chat_title}}" >
                  <ng-template #input>
                      <input kmdFormInput class="edit-input" type="text" [(ngModel)]="history.chat_title"
                      (keyup)="updateTitle($event, history);" maxlength="120"
                      (blur)="resetTitle(history);" autofocus />
                  </ng-template>
                  <div *ngIf="!isEditing(history) else input " >{{ (history.chat_title.length > 120) ?
                    (history.chat_title | slice:0:120) + '...' : (history.chat_title) }}</div>
                  <div *ngIf="isMobileScreen()"> {{getDate(history.timestamp) | date:'dd MMM yy HH:mm'}} </div>
              </td>
              
              <td *ngIf="!isMobileScreen()" class="library-icomoon flex items-center justify-center gap-2 menu-link"  style="margin: 10px"
                (click)="showPopover(history, $event); optionPopOver.toggle($event); "><i class="icon-options-mono" >&nbsp;</i>
              </td>
              
              <kmd-popover #optionPopOver [position]="'right'" [positionX]="posX" [positionY]="posY">
                <div class="popover-item menu-link library-icomoon" (click)="optionPopOver.hide(); renameChat();" ><i class="icon-edit-mono me-2"></i>Rename</div>
                <div class="popover-item menu-link library-icomoon" (click)="optionPopOver.hide(); deleteChat();" ><i class="icon-trash-mono me-2"></i>Delete</div>
                <div class="popover-item menu-link library-icomoon" (click)="optionPopOver.hide(); openChat();"><i class="icon-open-chat-mono me-2"></i>Open chat</div>
              </kmd-popover>
            </tr>
        </ng-template>
    </p-table>
    </div>
  </div>

  <div *ngIf="isMobileScreen() && isOptionOpened" class="buttons-container">
    <button kmdFeaturedButton kmdBlockButton class="mb-2" class="open-chat" (click)="openChat(); isOptionOpened = false;" > Open chat </button>
    <button kmdInfoButton kmdBlockButton class="mb-2" class="rename-title" (click)="renameChat(); isOptionOpened = false;" > Rename </button>
    <button kmdLinkButton kmdBlockButton class="mb-2" class="delete-chat" (click)="deleteChat(); isOptionOpened = false;" > Delete chat</button>
  </div>

</div>
</core-modal>

<kmd-modal [id]="'deletedConfirmation'" modalSize="small" headline="Delete chat?" [closeOnClickingOutside]="false">
  <div>Are you sure you want to delete this chat? <br />
      This action cannot be undone</div>
  <div style="float: right; margin-top: 40px;">
    <button kmdInfoButton type="button" class="me-3 modal-close-btn" (click)="closeModal('deletedConfirmation')" >Cancel</button>
    <button kmdFeaturedButton type="button" id="modal-confirm-btn" (click)="deleteAccepted()">Delete</button>
  </div>
</kmd-modal>