<div class="agent-creation-toolbar">
  <div class="toolbar-content-right">
    <kmd-alerts kmd-error [(show)]="showErrorAlert" [alertText]="errorAlertText" [dismissible]="true"
                [floating]="true"></kmd-alerts>
  </div>
  <div class="agent-creation-toolbar-content">
    <ng-content></ng-content>
    <h1>New Agent</h1>
    <p>Please provide answers for the following. Once complete you can chat with your agent. You can return to modify
      this information at any time. </p>
  </div>
</div>

<div class="agent-parent-div">
  <div class="agent-creation-form" *ngIf="showAgentCreationForm">
    <div id="agent-creation-form-title-bar">
      <h4 *ngIf="showAgentCreationForm">Configuration Form</h4>
      <button kmdPrimaryButton id="agent-example-button" *ngIf="showExampleButton" (click)="toggleAgentExample()">Show
        me an example
      </button>
    </div>

    <app-agent-form
      #agentForm
      [hidden]="!showAgentCreateForm"
      [agent]="agent"
      [instructionsTemplate]="instructionsTemplate"
      (formStatusChanged)="formStatusChanged($event)"
      (onSubmitValidForm)="saveAgent($event)"
      (askOurDocsEnabled)="updateAodStatus($event)"
      (askMyDocsEnabled)="setAskMyDocsState($event)" />

    <button *featureFlag="'agentSharing'" id="agent-creation-save-and-share-button" kmdSecondaryButton
            [disabled]="!validForm || isAodEnabled" (click)="openAgentSharingModal()">
      <i [ngClass]="validForm ? 'share-agent-ic' : 'save-agent-ic-disabled'"></i>
      <span>Save and Share</span>
    </button>

    <button id="agent-creation-save-button" kmdFeaturedButton [disabled]="!validForm" (click)="submit()">
      <i [ngClass]="validForm  ? 'save-agent-ic' : 'save-agent-ic-disabled'"></i>
      <ng-container *featureFlag="'agentSharing' ; else saveButtonText">
        <span>Save and Chat</span>
      </ng-container>
      <ng-template #saveButtonText>
        <span>Publish and Chat</span>
      </ng-template>
    </button>
  </div>

  <div class="agent-creation-example agent-creation-form" [hidden]="!showExampleAgent">
    <app-agent-example (closeAgentExample)="toggleAgentExample()"></app-agent-example>
  </div>
</div>

<app-agent-sharing-modal
  #agentSharingModal
  [sharingDetails]="sharingDetails"
  (onSharedAgent)="navigateToSharedWithMeAgents()"
  (onPubliclySharedAgent)="navigateToMyAgents()" />
