import { Injectable } from '@angular/core';
import { OidcSecurityService, UserDataResult } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { askOurDocsRoles } from "@environments/environment";
import {MenuOption} from "@shared/models/navbar/menu-option";

@Injectable({
  providedIn: 'root'
})
export class RoleIndexService {

  constructor(
    private oidcIdentityService: OidcSecurityService
  ) {
  }

  roleIndexes() {
    return this.oidcIdentityService.userData$.pipe(
      map(userData => this.getRoleMenus(userData))
    );
  }

  getRoleMenus(userData: UserDataResult) {
    let roles = userData.userData['roles'];
    let askOurDocsRoles = this.getAskOurDocsRoles(roles);
    return askOurDocsRoles.map(indexName => {
        let menuOption : MenuOption = {
          id: indexName,
          label: this.getAskOurDocMenuText(indexName),
          anchor: {type: 'routerLink', value: this.getAskOurDocMenuUrlPath(indexName)}
        }
        return menuOption;
    });
  }

  getAskOurDocsRoles(roles: string[]) {
    return roles?.filter(role => role.includes(askOurDocsRoles.IndexPrefix)) || [];
  }

  getAskOurDocMenuText(indexName: string) {
    return indexName.replace(askOurDocsRoles.IndexPrefix, "").replaceAll("_"," ");
  }

  getAskOurDocMenuUrlPath(indexName: string) {
    return "/ask-our-docs/" + indexName;
  }
}
