import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { featureFlags } from '@environments/environment';
import { BetaGuard } from "../core/guards/beta.guard";
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleIndexService } from "@services/roleIndex/role-index.service";
import { AskOurDocsRoleGuard } from "../core/guards/ask-our-docs-role.guard";
import { AskOurDataRoleGuard } from "../core/guards/ask-our-data-role.guard";
import { SVA2RoleGuard } from "../core/guards/sva2-role.guard";
import { GuardCheckerService } from "../core/guard-checkers/guard-checker-service";
import { DallEGuard } from "../core/guards/dall-e.guard";
import { Router, NavigationEnd } from '@angular/router';
import {links} from '@shared/constants/about-us/about-us.links';
import {MenuOption} from "@shared/models/navbar/menu-option";

@Component({
  selector: 'nav-menu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ width: '260px' })),
      state('out', style({ width: '0px' })),
      transition('in <=> out', animate('300ms ease-in-out'))
    ])
  ]
})

export class NavMenuComponent implements OnInit {

  currentRoute: string = '';

  CapabilitiesFlag: boolean = featureFlags.capabilities;

  activeMenu: string = '';

  geneAiSubmenu: MenuOption[] = [
    {
      id: 'guidelines',
      label: 'Gene.AI Guidelines',
      anchor: {
        type: 'href',
        value: links.guidelines
      }
    },
    {
      id: 'training',
      label: 'Training Videos',
      anchor: {
        type: 'href',
        value: links.videoTraining
      }
    },
    {
      id: 'faqs',
      label: 'FAQs',
      anchor: {
        type: 'href',
        value: links.faqs
      }
    },
    {
      id: 'tips',
      label: 'Tips & Tricks Series',
      anchor: {
        type: 'href',
        value: links.tipsAndTricks
      }
    },
    {
      id: 'viva-engage',
      label: 'Viva Engage Community',
      anchor: {
        type: 'href',
        value: links.vivaEngage
      }
    },
  ];

  subCommunityPreviewsOptions: MenuOption[] = [
    {
      id: 'apac',
      label: 'APAC Community App',
      anchor: { type: 'routerLink', value: 'iframe-apj' },
    }
  ];

  generativeToolsSubOptions: MenuOption[] = [
    {
      id: 'marketing-ads',
      label: 'Marketing Ads',
      anchor: { type: 'routerLink', value: 'tools/marketing-ads' },
      featureFlag: [featureFlags.socialMedia],
    },
    {
      id: 'image-creation',
      label: 'Image Generator',
      anchor: { type: 'routerLink', value: 'image-creation' },
      isBetaFeature: true,
      guard: [this.dallEGuard],
      featureFlag: [featureFlags.imageCreation]
    },
    {
      id: 'translation',
      label: 'Language Translation',
      anchor: { type: 'routerLink', value: 'tools/translations' },
      featureFlag: [featureFlags.languageTranslation],
    }

  ];

  askOurDocsSubOptions: MenuOption[] = [];

  menu: MenuOption[] = [
    {
      id: 'home',
      label: 'Home',
      anchor: { type: 'href', value: '/ask-gene' },
      icon: { type: 'src', value: '../assets/icons/chat/chat-mono.svg' },
    },
    {
      id: 'agents',
      label: 'Agents',
      anchor: { type: 'routerLink', value: 'agents' },
      icon: { type: 'src', value: '../assets/icons/agents/robot-mono.svg' },
      isBetaFeature: true,
      featureFlag: [featureFlags.agents]
    },
    {
      id: 'generative-tools',
      icon: { type: 'src', value: '../assets/icons/support/support-mono.svg' },
      label: 'Generative Tools',
      featureFlag: [featureFlags.languageTranslation, featureFlags.imageCreation,
      featureFlags.socialMedia],
      submenu: { subMenuList: this.generativeToolsSubOptions }
    },
    {
      id: 'my-documents',
      label: 'My Documents',
      anchor: { type: 'routerLink', value: 'uploader' },
      icon: { type: 'src', value: "../assets/icons/folder/folder-closed-mono.svg" }
    },
    {
      id: 'document-query',
      label: 'Ask My Docs',
      anchor: { type: 'routerLink', value: 'document-query' },
      icon: { type: 'src', value: "../assets/icons/document/document-mono.svg" },
    },
    {
      id: 'ask-our-docs',
      label: 'Ask Our Docs',
      anchor: featureFlags.askOurDocsPage? { type: 'routerLink', value: 'ask-our-docs' } : undefined,
      icon: { type: 'src', value: '../assets/icons/globe/global-mono.svg' },
      submenu: { subMenuList: this.askOurDocsSubOptions },
      guard: [this.askOurDocsRoleGuard],
      featureFlag: [featureFlags.askOurDocs]
    },
    {
      id: 'ask-our-data',
      label: 'Ask Our Data',
      icon: { type: 'src', value: '../assets/icons/ask-our-data/aodata_icon.svg' },
      guard: [this.askOurDataRoleGuard],
      isBetaFeature: true,
      anchor: { type: 'routerLink', value: 'ask-our-data' },
      featureFlag: [featureFlags.askOurData]
    },
    {
      id: 'capabilities',
      label: 'Preview Capabilities',
      icon: { type: 'src', value: '../assets/icons/stop/stop-mono.svg' },
      anchor: { type: 'routerLink', value: 'capabilities' },
      featureFlag: [this.CapabilitiesFlag]
    },
    {
      id: 'about-geneai',
      label: 'About Gene.AI',
      anchor: { type: 'routerLink', value: 'about' },
      icon: { type: 'src', value: "../assets/icons/info/info-mono.svg" },
      submenu: { subMenuList: this.geneAiSubmenu }
    },
    {
      id: 'guidelines',
      label: 'Gene.AI Guidelines',
      anchor: { type: 'routerLink', value: 'guidelines' },
      icon: { type: 'src', value: '../assets/icons/documentation/documentation-mono.svg' }
    },
    {
      id: 'iframe-endpoint',
      label: 'Community Apps',
      icon: { type: 'src', value: "../assets/icons/info/info-mono.svg" },
      submenu: { subMenuList: this.subCommunityPreviewsOptions },
      guard: [this.betaGuard]
    },
    {
      id: 'sign-out',
      label: 'Sign out',
      anchor: { type: 'routerLink', value: 'sign-out' },
      icon: { type: 'src', value: "../assets/icons/union/union-mono.svg" },
      mobileOnly: true
    }
  ];

  menuState: string = 'in';
  routerSubscription: Subscription | undefined;
  currentOption: MenuOption | null | undefined;

  constructor(
    private betaGuard: BetaGuard,
    private dallEGuard: DallEGuard,
    private askOurDocsRoleGuard: AskOurDocsRoleGuard,
    private askOurDataRoleGuard: AskOurDataRoleGuard,
    private sva2RoleGuard: SVA2RoleGuard,
    private roleIndexService: RoleIndexService,
    private guardCheckerService: GuardCheckerService,
    private router: Router
  ) {
    this.defaultCollapse();
    this.setAskOurDocsSubOptions();
  }

  ngOnInit(): void {
    this.addSva2Option();
    this.routerSubscription = this.router.events.subscribe(event => {
      this.setStyleToActiveMenuItem(event);
    });
  }

  setStyleToActiveMenuItem(event: any) {
    if (event instanceof NavigationEnd) {
      this.currentRoute = event.urlAfterRedirects;
      this.currentOption = this.findCurrentOption(this.menu, this.currentRoute.substring(1));
      if (this.currentOption) {
        const pastActiveOptionElement = document.getElementById(this.activeMenu);
        if (pastActiveOptionElement) {
          pastActiveOptionElement.classList.remove('active');
        }
        this.activeMenu = this.currentOption.id;
        const activeOptionElement = document.getElementById(this.currentOption.id);
        activeOptionElement?.classList.add('active');
      }
    }
  }

  findCurrentOption(menu: MenuOption[], route: string): MenuOption | null {
    for (const option of menu) {
      if (option.anchor && option.anchor.value === route) {
        return option;
      } else if (option.submenu) {
        const subOption = this.findCurrentOption(option.submenu.subMenuList, route);
        if (subOption) {
          return option;
        }
      }
    }
    return null;
  }

  isExternalLink(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const childTarget = this.findChildElementTarget(target);
    if (childTarget) {
      const parentTarget = childTarget.parentElement?.parentElement?.firstElementChild?.id;
      if (parentTarget == 'ask-our-docs') {
        const pastActiveOptionElement = document.getElementById(this.activeMenu);
        if (pastActiveOptionElement) {
          pastActiveOptionElement.classList.remove('active');
        }
        this.activeMenu = 'ask-our-docs';
        const activeOptionElement = document.getElementById(this.activeMenu);
        activeOptionElement?.classList.add('active');
      }
    }
  }

  findChildElementTarget(element: HTMLElement): HTMLElement | null {
    let currentElement: HTMLElement | null = element;

    while (currentElement && currentElement.tagName.toLowerCase() !== 'li') {
      currentElement = currentElement.parentElement;
    }

    return currentElement;
  }

  setAskOurDocsSubOptions() {
    if(featureFlags.askOurDocsPage) return;
    this.roleIndexService.roleIndexes().subscribe((options) => {
      this.askOurDocsSubOptions = options;
      this.menu.forEach((menuOption) => {
        if (menuOption.id === 'ask-our-docs') {
          menuOption.submenu = { subMenuList: options.sort((a, b) => a.label.localeCompare(b.label)) };
        }
      });
    });
  }

  toggle() {
    this.menuState = this.menuState === 'in' ? 'out' : 'in';

    if (this.menuState === 'out') {
      this.defaultCollapse();
    }
  }

  toggleExpanded(id: string) {
    for (const menuOption of this.menu) {
      if (menuOption.submenu && menuOption.id !== id) {
        menuOption.submenu.isExpanded = false;
      }
    }
  }

  defaultCollapse() {
    for (const menuOption of this.menu) {
      if (menuOption.submenu) {
        menuOption.submenu.isExpanded = false;
      }
    }
  }

  shouldDisplayOption(option: MenuOption): Observable<boolean> {
    if (option.featureFlag?.every(flag => flag === false)) {
      return of(false);
    }
    return this.guardCheckerService.areAllOptionGuardsPassed(option);
  }

  shouldDisplaySva2(): Observable<boolean> {
    return this.sva2RoleGuard.hasSVA2Roles().pipe(
      map(hasRole => {
        return hasRole;
      })
    );
  }

  addSva2Option() {
    this.shouldDisplaySva2().subscribe((shouldDisplay) => {
      if (shouldDisplay) {
        // Check if the 'sva2-menu-item' is already in the menu
        const sva2MenuItemExists = this.menu.some(menuItem => menuItem.id === 'sva2-menu-item');

        if (!sva2MenuItemExists) {
          this.menu.splice(7, 0, {
            id: 'sva2-menu-item',
            label: 'SVA2',
            anchor: { type: 'routerLink', value: 'sva2' },
            icon: { type: 'src', value: '../assets/icons/ask-our-docs/ask-our-docs-icon.svg' },
            guard: [this.sva2RoleGuard],
            featureFlag: [featureFlags.sva2]
          });
        }
      }
    });
  }
}
