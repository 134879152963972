import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { PromptSettings } from "@shared/models/prompt-settings.model";
import { PromptSettingsNewComponent } from "../prompt-settings-new/prompt-settings-new.component";
import { PromptInputNewComponent } from "../prompt-input-new/prompt-input-new.component";
import { PromptSubmitEvent } from "@shared/models/prompt-submit-event";
import { ChatQAService } from "@app/core/services/chat-qa/chat-qa.service";
import { Prompt } from "@app/shared/models/prompt.model";

@Component({
  selector: "app-chat-wrapper",
  templateUrl: "./chat-wrapper.component.html",
  styleUrls: ["./chat-wrapper.component.css"]
})
export class ChatWrapperComponent {
  @ViewChild(PromptSettingsNewComponent) promptSettingsComponent!: PromptSettingsNewComponent;
  @ViewChild(PromptInputNewComponent) promptInputNewComponent!: PromptInputNewComponent;

  @Input() contentPosition: "middle" | "bottom" | "top" = "middle";
  @Input() isStreamingResponse!: boolean;
  @Input() savedPromptsSelectedEvent!: void;
  @Output() onPromptSubmit = new EventEmitter<PromptSubmitEvent>();
  @Output() stopStreaming = new EventEmitter<any>();

  promptSettings: PromptSettings;
  savedPrompts = false;

  constructor(
    private chatQAService: ChatQAService,
    private cdRef: ChangeDetectorRef
  ) {
    this.promptSettings = this.chatQAService.promptSettings;
  }

  openPromptSettings(): void {
    this.promptSettingsComponent.show();
  };

  setPromptText(text: string): void {
    this.promptInputNewComponent.setPromptInInput(text);
  };

  promptSelected(prompt: Prompt): void {
    this.savedPrompts = false;
    this.contentPosition = "bottom";
    this.cdRef.detectChanges();
    this.promptInputNewComponent.setPromptInInput(prompt.text);
  }

  showChat(): void {
    this.savedPrompts = false;
    this.cdRef.detectChanges();
  }

  showSavedPrompts(): void {
    this.savedPrompts = true;
    this.contentPosition = "middle";
    this.cdRef.detectChanges();
  }
}
