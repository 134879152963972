
export type NavBarScreenResolution = 'desktop' | 'tablet' | 'mobile';

export type NavBarScreenResolutionSetting = {
    size: number;
    collapseOnViewChange: boolean;
    hiddenOnCollapse: boolean;
    isCollapsedByDefault: boolean;
}

export const NavBarScreenResolutionSettings: {
    [key in NavBarScreenResolution]: NavBarScreenResolutionSetting
} = {
    desktop: {
        size: 1024,
        isCollapsedByDefault: false,
        hiddenOnCollapse: false,
        collapseOnViewChange: false
    },
    tablet: {
        size: 768,
        isCollapsedByDefault: true,
        hiddenOnCollapse: false,
        collapseOnViewChange: true
    },
    mobile: {
        size: 430,
        isCollapsedByDefault: true,
        hiddenOnCollapse: true,
        collapseOnViewChange: true
    }
};

export function getScreenResolution(): NavBarScreenResolution {
    if (window.innerWidth <= NavBarScreenResolutionSettings.mobile.size)
        return 'mobile';
    if (window.innerWidth <= NavBarScreenResolutionSettings.tablet.size)
        return 'tablet';
    return 'desktop';
}

export function isMobileResolution(): boolean {
  return getScreenResolution() === 'mobile';
}

export function isTabletResolution(): boolean {
  return getScreenResolution() === 'tablet';
}

export function isDesktopResolution(): boolean {
  return getScreenResolution() === 'desktop';
}
