
export class WindowRef {
  private static get nativeWindow(): Window {
    return window;
  }

  static get innerWidth(): number {
    return this.nativeWindow.innerWidth;
  }
}
