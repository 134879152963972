<div class="form-prompt container" *ngIf="prompt">
  <div class="title-container">
    <b>Title <span class="required-mark">*</span></b>
    <input kmdFormInput id="prompt-title-input" maxlength="50" [(ngModel)]="prompt.title" [disabled]="readOnly"/>
    <countdown-label [maxlength]=50 [value]="prompt.title"/>
  </div>

  <div class="prompt-container">
    <b>Prompt</b>
    <textarea kmdFormTextarea name="prompt-text-area" id="prompt-txt-area" cols="30" rows="4" [(ngModel)]="prompt.text"
              maxlength="5000" [disabled]="readOnly"></textarea>
    <countdown-label [maxlength]=5000 [value]="prompt.text"/>
  </div>

  <div class="category-container">
    <div class="category-item">
      <b>Category <span class="required-mark">*</span></b>
      <input-select-box [(value)]="prompt.category!" [options]="categories" [readOnly]="readOnly"></input-select-box>
    </div>

  </div>
  <div class="prompt-settings-container">
    <div class="prompt-settings-item">
      <b>Model: </b>
      <label class="prompt-settings-label">
        {{getModelLabelText(prompt.promptSettings.model)}}
      </label>
    </div>

    <div class="prompt-settings-item">
      <b>Web Search: </b>
      <label class="prompt-settings-label">
        {{getWebSearchLabelText(prompt.promptSettings.webSearch)}}
      </label>
    </div>

    <div class="prompt-settings-item">
      <b>Creativity: </b>
      <label class="prompt-settings-label">{{prompt.promptSettings.creativity}}</label>
    </div>

    <div class="prompt-settings-item">
      <b>Tone: </b>
      <label class="prompt-settings-label">{{prompt.promptSettings.tone | titlecase}}</label>
    </div>
  </div>

  <ng-container *ngIf="prompt.fileReferences && prompt.fileReferences!.length > 0">
    <div class="documents-container">
      <b>Documents: </b>
      <label class="document-label" kmdTooltip="{{ getDocumentTitles() }}" tooltipPlacement="top">
        <ng-container *ngFor="let item of prompt.fileReferences; let last = last">
          <span [ngStyle]="{ 'font-style': item.title ? 'normal' : 'italic' }">
            {{ (item.title || "Missing File") + ( !last ? ", " : '')}}
          </span>
        </ng-container>
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="!readOnly">
    <div class="save-container">
      <button kmdFeaturedButton type="button" id="save" (click)="onSave()" [disabled]="!isSaveButtonEnabled()">Save
      </button>
    </div>
  </ng-container>
</div>
