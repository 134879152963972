import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ModelFactory } from "../../models/gpt-models/model-factory";
import { PromptSettings } from "../../models/prompt-settings.model";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { PromptExample } from "../../models/prompt-example.model";
import { DallE3model } from "../../models/gpt-models/dalle3model.model";
import { Model } from "../../models/gpt-models/model.model";

@Component({
  selector: "app-prompt-input",
  templateUrl: "./prompt-input.component.html",
  styleUrls: ["./prompt-input.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class PromptInputComponent {
  @ViewChild("textArea") textArea?: ElementRef;

  @Input() username!: string;
  @Input() promptSettings!: PromptSettings;
  @Input() chatId?: string;
  @Input() promptExample?: PromptExample;
  @Input() showPromptSettingsButton?: boolean = true;
  @Input() disabled?: boolean = false;
  @Input() showCharacterCounter?: boolean = true;
  @Output() openDrawerEvent = new EventEmitter<void>();
  @Output() promptSentEvent = new EventEmitter<string>();

  prompt: string = "";
  textTrimmed = false;
  faPaperPlane = faPaperPlane;
  faSlidersH = faSlidersH;

  constructor(private modelFactory: ModelFactory, private ngZone: NgZone) {
  }

  onSubmit() {
    if (!this.disabled && this.prompt.trim() != "" && !this.isTextExceeded()) {
      this.promptSentEvent.emit(this.prompt);
      this.prompt = "";
    }
  }

  isTextExceeded() {
    return this.prompt.length > this.maxInputCharLimit();
  }

  maxInputCharLimit() {
    const model = this.modelFactory.create(this.promptSettings.model);
    return this.getCharLimitBasedOnModel(model);
  }

  private getCharLimitBasedOnModel(model: Model | DallE3model) {
    if (model instanceof DallE3model && this.promptSettings.imageUseEnhancement) {
      return model.MAX_NUMBER_OF_CHARACTERS_PROMPT_ENHANCEMENT;
    }
    return model.MAX_NUMBER_OF_CHARACTERS;
  }

  onEnterKey(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    keyboardEvent.preventDefault();
    this.onSubmit();
  }

  onPromptChange() {
    if (this.textTrimmed && this.prompt.length < this.maxInputCharLimit()) {
      this.textTrimmed = false;
    }
  }

  onPaste(event: ClipboardEvent) {
    const text = event.clipboardData?.getData("text/plain");
    if (text) {
      this.textTrimmed = this.prompt.length + text.length > this.maxInputCharLimit();
    } else {
      this.textTrimmed = false;
    }
  }

  resizeTextarea(): void {
    const textarea = document.querySelector("textarea") as HTMLTextAreaElement;
    if (textarea == undefined) return;

    if (textarea.scrollHeight < 230) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    } else {
      textarea.style.height = "230px";
    }
  }

  submitState() {
    let state = "";
    
    if (this.isTextExceeded() || this.disabled) {
      state += "submit-inactive";
    }

    return state;
  }

  openDrawer(): void {
    this.openDrawerEvent.emit();
  }

  isTextTrimmed() {
    return this.textTrimmed && this.prompt.length == this.maxInputCharLimit();
  }

  setExampleCurrentPrompt(example: PromptExample) {
    if (this.prompt.trim() != "") {
      this.prompt = "";
    }
    this.prompt = example.text;
    this.textArea?.nativeElement.focus();

    // TODO: Fix this with ngZone or find a better way to do it
    // Find ngZone mocking solution
    // Consult our PO about the regenerate button positioning
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.resizeTextarea();
      });
    });
  }

  setAppendPrompt(text: string) {
    this.prompt += text;
    this.textArea?.nativeElement.focus();
  }

  setPrompt(text: string) {
    this.prompt = text;
  }

  setPromptAndFocus(text: string) {
    this.setPrompt(text);
    this.textArea?.nativeElement.focus();
  }
}
