import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {

  errorCode: string = '';
  title: string = '';
  description: string = '';
  buttonText: string = '';
  secondaryButtonText: string = '';

  constructor(private router: Router, private location: Location) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras) {
      const state = navigation.extras.state as { status: string };
      if (state && state.status !== null) {
        this.loadError(state.status + '');
      } else {
        this.loadError('404');
      }
    } else {
      this.loadError('500');
    }
  }

  loadError(errorCode: string) {

    const errorList: any[] = [{
      errorCode: '400',
      title: "Sorry, we couldn't understand your request.",
      description: "We were unable to understand the request sent by your browser. Please try again or contact support for assistance.",
      buttonText: "Go Back",
      secondaryButtonText: 'Go to Homepage'
    },
    {
      errorCode: '401',
      title: "Sorry, you don't have permission to access this page.",
      description: "We have recognized that you do not have the necessary credentials to access the requested resource. Please log in or contact support for assistance.",
      buttonText: "Go Back",
      secondaryButtonText: 'Go to Homepage'
    },
    {
      errorCode: '403',
      title: "Oops! You don't have access to view this page.",
      description: "The server understands your request, but won't allow access. Please log in or contact support for assistance.",
      buttonText: "Go Back",
      secondaryButtonText: 'Go to Homepage'
    },
    {
      errorCode: '404',
      title: "Oops! The page you're looking for cannot be found.",
      description: "We were unable to find the page you are looking for. Please check the URL and try again.",
      buttonText: "Go Back",
      secondaryButtonText: 'Go to Homepage'
    },
    {
      errorCode: '500',
      title: "Oops! Something went wrong on our end.",
      description: "We encountered an unexpected error that prevented us from fulfilling your request. We're working to fix it and will be back up soon.",
      buttonText: "Go Back",
      secondaryButtonText: 'Go to Homepage'
    },
    {
      errorCode: '503',
      title: "Sorry, the service you're trying to access is currently unavailable.",
      description: "We are unable to handle your request at the moment. Please try again later or contact support for assistance.",
      buttonText: "Go Back",
      secondaryButtonText: 'Go to Homepage'
    }];

    const error = errorList.find(item => item.errorCode === errorCode);
    if (error) {
      this.errorCode = error.errorCode;
      this.title = error.title;
      this.description = error.description;
      this.buttonText = error.buttonText;
      this.secondaryButtonText = error.secondaryButtonText;
    }
  }

  goBack(): void {
    this.location.back();
  }

  goHome(): void {
    this.router.navigate(['/ask-gene']);
  }
}
