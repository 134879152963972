import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Group } from "@app/shared/models/azure/groups/group.model";
import { AlertService } from "@services/alert/alert.service";
import { FilesService } from "@services/files/files.service";
import { ModalService } from "@services/modal/modal.service";
import { Agent } from "@shared/models/agent";
import { FileUpload } from "@shared/models/file-upload.model";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { catchError, map, tap, throwError } from "rxjs";

@Component({
    selector: 'app-agent-sharing-confirmation-modal',
    templateUrl: './agent-sharing-confirmation-modal.component.html',
    styleUrls: ['./agent-sharing-confirmation-modal.component.css']
})
export class AgentSharingConfirmationModalComponent implements OnInit {
    @Input() sharingDetails?: { agent: Agent, selectedFiles: FileUpload[] };
    @Input() group?: Group;
    @Input() admins?: string[]
    @Input() isPublicShare: boolean = false;
    @Input() forceDisable: boolean = false;
    @Input() readOnly: boolean = false;
    @Input() membersCount?: number;

    @Output() initialized = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();
    @Output() close = new EventEmitter<void>();
    @Output() editAgentClick = new EventEmitter<void>();
    @Output() shareAgentClick = new EventEmitter<void>();
    @Output() sharePublicAgentClick = new EventEmitter<void>();

    @ViewChild('modalContent') modalContent?: ElementRef;

    readonly id = 'AgentSharingConfirmationModalComponent';
    readonly headline = 'Agent Overview';
    readonly subHeadline = 'Ensure compliance with Agent guidelines; violating them may result in deletion of your agent.';
    readonly MODAL_PADDING = 150;

    creationGuidelinesCompliance = false;
    creationDataClassificationPolicyCompiliance = false;
    modalHeight: number = 0;
    windowHeight: number = 0;
    agentOwner: string = '';
    fileNameSortState: 'asc' | 'desc' = 'asc';

    constructor(
        private modalService: ModalService,
        private filesService: FilesService,
        private alertService: AlertService,
        private oidcIdentityService: OidcSecurityService
    ) { }

    ngOnInit(): void {
        this.fetchAgentOwnerName();
        this.initialized.emit();
    };

    get isShareButtonDisabled(): boolean {
        if (this.forceDisable) {
            return true;
        }

        if (!this.isPublicShare) {
            return !(this.creationGuidelinesCompliance && this.creationDataClassificationPolicyCompiliance);
        }

        return false;
    };

    get conversationStarter(): string {
        return this.sharingDetails?.agent?.conversationStarters?.join(', ').trim() || 'N/A';
    }

    get adminsLabel(): string {
        if (!this.admins || this.admins.length === 0) {
            return 'N/A';
        }

        return this.admins.join(', ') || 'N/A';
    }

    fetchAgentOwnerName(): void {
        this.oidcIdentityService.userData$.pipe(
            map((info) => info.userData?.['name']),
            tap((name) => this.agentOwner = name)
        ).subscribe();
    };

    openModal(): void {
        this.modalService.open(this.id);
    };

    /**
     * Closes the modal component.
     *
     * @param {object} [options] - Configuration object.
     * @param {boolean} [options.emit] - Determines whether the 'close' event should be emitted.
    */
    closeModal(options: { emit: boolean }): void {
        this.modalService.close(this.id);
        if (options.emit) {
            this.close.emit();
        }
    };

    onModalClosed() {
        this.close.emit();
    }

    hideModal() {
        this.modalService.hide(this.id);
    }

    shareAgent(): void {
        if (this.isPublicShare) {
            return this.sharePublicAgentClick.emit();
        }

        return this.shareAgentClick.emit();
    };

    onBackClick(): void {
        this.back.emit();
    }

    onEditAgentClick(): void {
        this.editAgentClick.emit();
        this.hideModal();
    };

    toggleCreationGuidelinesCompliance(): void {
        this.creationGuidelinesCompliance = !this.creationGuidelinesCompliance;
    };

    toggleDataClassificationPolicyCompliance(): void {
        this.creationDataClassificationPolicyCompiliance = !this.creationDataClassificationPolicyCompiliance;
    }

    showFile(fileId: string): void {
        this.filesService.get(fileId).pipe(
            catchError((error) => {
                this.alertService.showError('An error occurred while trying to open the file. Please try again later.');
                return throwError(() => error)
            }),
            tap((file: FileUpload) => window.open(file.url, '_blank'))
        ).subscribe();
    };

    sortByFileName(): void {
        this.fileNameSortState = this.fileNameSortState === 'asc' ? 'desc' : 'asc';
        this.sharingDetails?.selectedFiles.sort((a, b) => {
            if (this.fileNameSortState === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
    }
};
