<div class="uploader-wrapper" [ngClass]="wrapperClasses">
  <div class="clear-files-container">
    <div class="clear-files" (click)="showClearFilesModal()" *ngIf="showClearFilesButton()">
      <img src="assets/icons/close/size=24px, style=bold.svg" alt="close" class="close-icon">
      Clear file(s)
    </div>
  </div>
  <div class="upload-panel" [ngClass]="{'disabled': disableUploader()}" appDragAndDrop
       (fileDropped)="handleFileUpload($event)" (click)="openFileDialog()">
    <div class="icon-wrapper">
      <i></i>
    </div>
    <div class="upload-instructions">
      <h6>Drag file here or <a>browse</a> to upload</h6>
      <p class="upload-types">
        {{ uploadType.filesText }}<br>
        {{ uploadType.filesInstructions }}
      </p>
      <input type="file" #fileInput (change)="onFileChange($event)" [accept]="uploadType.acceptedFileTypes"
             multiple>
    </div>
    <div class="upload-instructions mobile">
      <h6><a (click)="openFileDialog()">Select a file</a></h6>
      <p class="upload-types">
        {{ uploadType.filesText }}<br>
        {{ uploadType.mobileFileInstructions ? uploadType.mobileFileInstructions : [0] }}
      </p>
      <input type="file" #fileInput (change)="onFileChange($event)" [accept]="uploadType.acceptedFileTypes"
             multiple>
    </div>
  </div>
  <div class="upload-translate-files-container" #filesContainer>
    <app-file-translations-upload-container
      [@fileListAnimation]="uploadFiles.length"
      [(uploadFiles)]="uploadFiles"
      (fileEvents)="handleUploadEvents($event)"/>

    <app-file-translations-translate-container
      [@fileListAnimation]="translateFiles.length"
      [(translateFiles)]="translateFiles"
      (fileEvents)="handleTranslateEvents($event)"/>
  </div>
</div>
<kmd-modal [id]="'clear-files-modal'" modalSize="mini" headline="Do you want to stop processing?">
  <div id="text-acknowledge">
    <p>You're about to stop the processing. This action cannot be undone.</p>
  </div>
  <b>
    Do you want to proceed?
  </b>
  <div class="modal-buttons-container">
    <button id="stay-btn" (click)="closeClearFilesModal()" kmdInfoButton>No</button>
    <button id='switch-btn' (click)="clearAllFiles()" kmdFeaturedButton>Yes</button>
  </div>
</kmd-modal>


