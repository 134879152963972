<div class="file-management-controls">
  <ng-container *ngIf="!selectedFiles.length">
    <div appTooltip text="Add files" class="attach-icon" (click)="openFileManager(fileManagerModalId)">
      <img ngSrc="assets/icons/attachment/attachment.svg" alt="" height="24" width="24">
      Add
    </div>
  </ng-container>
  <ng-container *ngIf="selectedFiles.length">
    <div class="file-count-container">
      <div (click)="openSelectedFilesList()">
        <i *ngIf="isTooltipEnabled()" appTooltip [text]="tooltipText" [ngClass]="getSelectedFilesStatus()"></i>
        <ng-template [ngIf]="!isTooltipEnabled()">
          <app-tooltip [text]="tooltipText" [isVisible]="showTooltip && isFileIconHovered"></app-tooltip>
          <i [ngClass]="getSelectedFilesStatus()" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()"></i>
        </ng-template>
        <label class="file-count-label">
          <ng-container *ngIf="selectedFiles.length === 1; else fileCount">
            {{ selectedFiles[0].shortDisplayName }}
          </ng-container>
          <ng-template #fileCount>
            {{ selectedFiles.length }} files
          </ng-template>
        </label>
      </div>
      <app-file-manager-selected-files #fileManagerSelectedFiles [files]="selectedFiles"
                                       (onAddMoreFiles)="openFileManager(fileManagerModalId)"
                                       (onFileUnselected)="removeSelectedFile($event)"/>
    </div>
  </ng-container>
</div>
<div *ngIf="showModal(); else fileManagerDrawer" class="file-manager-modal-wrapper">
  <app-modal [id]="fileManagerModalId"
             [headline]="fileManagerModalHeadline"
             [subHeadline]="fileManagerModalSubHeadline"
             (onModalClose)="resetContent()">
    <app-file-manager-modal (onModalClose)="closeFileManager()"/>
  </app-modal>
</div>
<ng-template #fileManagerDrawer>
  <div class="file-manager-drawer-wrapper" [ngClass]="{'expanded block-user-initials': showFileManager}">
    <app-file-manager-drawer [fileManagerDrawerToggled]="showFileManager" (hideFMDrawer)="closeFileManager()">
      <app-file-manager-modal (onModalClose)="closeFileManager()"/>
    </app-file-manager-drawer>
  </div>
  <div class="fm-drawer-overlay" *ngIf="showFileManager"></div>
</ng-template>
