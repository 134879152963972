import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { ButtonModule, InputFieldsModule, LoadingIndicatorsModule,
  DropdownsModule, AlertsModule, ModalsModule, CheckboxModule, ChipsModule, PopoverModule} from "gds-atom-components";
import { AskOurDataService } from "@services/ask-our-data/ask-our-data.service";
import { CreateChartComponent } from './chat-with-db/create-chart/create-chart.component';
import { SamplePromptsComponent } from './chat-with-db/sample-prompts/sample-prompts.component';
import { AodChatHistoryComponent } from './chat-with-db/chat-history/aod-chat-history.component';
import { TableModule } from "primeng/table";

@NgModule({
  declarations: [CreateChartComponent, SamplePromptsComponent, AodChatHistoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    InputFieldsModule,
    LoadingIndicatorsModule,
    DropdownsModule,
    ButtonModule,
    AlertsModule,
    ModalsModule,
    CheckboxModule,
    ChipsModule,
    TableModule,
    PopoverModule
  ],
  exports: [CreateChartComponent, SamplePromptsComponent, AodChatHistoryComponent],
  providers: [AskOurDataService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AskOurDataSharedModule { }
