export class FileTranslationsUpload {
  id?: string | undefined
  name: string
  sourceLanguage: string;
  targetLanguage: string;
  fileSize: number;
  fileType?: string;
  status?: string | undefined
  url?: string | undefined
  updatedAt?: Date | undefined
  progress?: number;

  constructor(id: string | undefined, name: string, sourceLanguage: string, targetLanguage: string, fileSize: number, status?: string, url?: string, updatedAt?: Date) {
    this.id = id
    this.name = name
    this.fileSize = fileSize
    this.sourceLanguage = sourceLanguage
    this.targetLanguage = targetLanguage
    this.status = status
    this.url = url
    this.updatedAt = updatedAt
  }
}
