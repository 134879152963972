import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from "@angular/core";
import { AlertService } from "@app/core/services/alert/alert.service";
import { AskOurDocsV2Service } from '@app/core/services/ask-our-docs-v2/ask-our-docs-v2.service';
import { KmdTabsComponent, KmdModalService } from 'gds-atom-components';
import * as _ from 'lodash';
import { featureFlags } from "@app/environments/environment";
import { WindowRef } from "@app/core/services/window/window-ref.service";

@Component({
  selector: "app-saved-prompts-aod",
  templateUrl: "./saved-prompts.component.html",
  styleUrls: ["./saved-prompts.component.css"],
})
export class AskOurDocsV2SavedPromptsComponent implements OnInit {
  @ViewChild('tabsComponent') tabsComponent!: KmdTabsComponent;

  @Input() indexName: string = "";
  @Input() repoId: string = "";
  @Input() isDetailModalOpen: boolean = false;
  @Input() isPublicSavedPromptsEnabled: boolean = false;
  @Output() promptText = new EventEmitter<string>();
  @Output() promptTextInNewChat = new EventEmitter<string>();
  @Output() isDetailModalOpenInSavedPrompts = new EventEmitter<boolean>();
  @Output() mobileSavedPromptsClosed = new EventEmitter<boolean>();

  currentDeletePrompt = ''
  promptTitle: string = '';
  promptDescription: string = '';
  savedPromptsLoading: boolean = true;
  savedPromptsPrivate: Array<{ promptId: string, promptTitle: string, promptDescription: string }> = [];
  savedPromptsPrivateSearched: Array<{ promptId: string, promptTitle: string, promptDescription: string }> = [];
  savedPromptsPublic: Array<{ promptId: string, promptTitle: string, promptDescription: string }> = [];
  savedPromptsPublicSearched: Array<{ promptId: string, promptTitle: string, promptDescription: string }> = [];
  currentTab: 'private' | 'public' = 'private';
  currentPrompt: { promptTitle: string, promptDescription: string } = { promptTitle: '', promptDescription: '' };
  // isDetailModalOpen: boolean = false;
  searchText: string = '';
  promptDescriptionEditable: string = '';
  promptTitleEditable: string = '';
  showusePromptWhenNotSavedAlert: boolean = false;
  showPromptUpdateSuccessAlert: boolean = false;
  showPromptUpdateErrorAlert: boolean = false;
  showPromptDeleteSuccessAlert: boolean = false;
  showPromptDeleteErrorAlert: boolean = false;
  usePromptWhenNotSavedErrorMessage: string = 'Cannot use prompt until changes saved.';
  updatePromptSuccessMessage: string = 'Prompt updated successfully';
  updatePromptErrorMessage: string = 'Prompt update unsuccessful.';
  deletePromptSuccessMessage: string = 'Prompt deleted successfully.';
  deletePromptErrorMessage: string = 'Prompt deletion unsuccessful.';

  constructor(
    private askOurDocsV2Service: AskOurDocsV2Service,
    private kmdModalService: KmdModalService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.currentTab = 'private';
    if(featureFlags.savePrompt){
      (window as any).savedPromptsComponent = this;
    }
  }

  isMobileScreen(): boolean {
    return (500 >= WindowRef.innerWidth);
  }

  onSavedPromptModalClosed() {
    this.searchText = '';
    this.currentTab = 'private';
  }

  deleteConfirmation(prompt: any) {
    this.currentDeletePrompt = prompt;
    this.kmdModalService.open('delete-private-prompt');
  }

  closeDeleteConfirmationModal(): void {
    this.kmdModalService.close('delete-private-prompt');
  }

  getSavedPromptsPrivate() {
    this.searchText = '';
    this.savedPromptsLoading = true;
    this.savedPromptsPrivateSearched = [];
    this.askOurDocsV2Service.getSavedPromptsPrivate(
      this.repoId,
    ).then(response => {
      this.savedPromptsLoading = false;
      const { savedPrompts } = response;
      this.savedPromptsPrivate = savedPrompts;
      this.savedPromptsPrivateSearched = _.cloneDeep(this.savedPromptsPrivate);
    }).catch(e => {
      console.log(e);
    });
  }

  getSavedPromptsPublic() {
    this.searchText = '';
    this.savedPromptsLoading = true;
    this.savedPromptsPublicSearched = [];
    this.askOurDocsV2Service.getSavedPromptsPublic(
      this.repoId,
    ).then(response => {
      this.savedPromptsLoading = false;
      const { savedPrompts } = response;
      this.savedPromptsPublic = savedPrompts.map((prompt: { shortPrompt: string, longPrompt: string }) => ({
        promptId: "",
        promptTitle: prompt.shortPrompt,
        promptDescription: prompt.longPrompt
      }));
      this.savedPromptsPublicSearched = _.cloneDeep(this.savedPromptsPublic);
    }).catch(e => {
      console.log(e);
    });
  }

  updatePrivatePrompt(prompt: any, newTitle: string, newDescription: string) {
    if(newTitle.length === 0 || newDescription.length === 0) {
      this.alertService.showError('Title and Prompt cannot be empty.');
      return;
    }
    prompt.promptTitle = newTitle;
    prompt.promptDescription = newDescription;
    this.askOurDocsV2Service.updatePrivatePrompt(
      this.repoId,
      prompt
    ).then(response => {
      console.log(response);
      if(this.isMobileScreen()) {
        this.showPromptUpdateSuccessAlert = true;
        setTimeout(() => {
          this.showPromptUpdateSuccessAlert = false;
          }, 3000);
      }
      else {
        this.alertService.showSuccess(this.updatePromptSuccessMessage);
      }
    }).catch(e => {
      console.log(e);
      if(this.isMobileScreen()) {
        this.showPromptUpdateErrorAlert = true;
        setTimeout(() => {
          this.showPromptUpdateErrorAlert = false;
          }, 3000);
      }
      else {
        this.alertService.showError(this.updatePromptErrorMessage);
      }
    });
    // this.closeSavedPromptsModal();
    this.closeSavedPromptDetailModal();
  }

  deletePrivatePrompt(prompt: any) {
    this.askOurDocsV2Service.deletePrivatePrompt(
      this.repoId,
      prompt
    ).then(response => {
      console.log(response);
      if(this.isMobileScreen()) {
        this.showPromptDeleteSuccessAlert = true;
        setTimeout(() => {
          this.showPromptDeleteSuccessAlert = false;
          }, 3000);
      }
      else {
        this.alertService.showSuccess(this.deletePromptSuccessMessage);
      }
    }).catch(e => {
      console.log(e);
      if(this.isMobileScreen()) {
        this.showPromptDeleteErrorAlert = true;
        setTimeout(() => {
          this.showPromptDeleteErrorAlert = false;
          }, 3000);
      }
      else {
        this.alertService.showError(this.deletePromptErrorMessage);
      }
    });
    this.closeDeleteConfirmationModal();
    this.closeSavedPromptDetailModal();
  }

  setCurrentTab(currentTabEvent: any) {
    this.currentTab = currentTabEvent.nextId;
    this.searchText = '';
    if (this.currentTab === 'private') {
      this.getSavedPromptsPrivate();
    } else {
      this.getSavedPromptsPublic();
    }
  }

  showSavedPromptDetailModal(prompt: { promptId: string, promptTitle: string, promptDescription: string }) {
    this.currentPrompt = prompt;
    if (this.currentTab === 'private') {
      this.promptTitleEditable = prompt.promptTitle;
      this.promptDescriptionEditable = prompt.promptDescription;
    }
    this.isDetailModalOpen = true;
    this.isDetailModalOpenInSavedPrompts.emit(true);
  }

  closeSavedPromptDetailModal() {
    this.isDetailModalOpen = false;
    this.isDetailModalOpenInSavedPrompts.emit(false);
    setTimeout(() => {
    this.getSavedPromptsPrivate();
    }, 500);
  }

  showSavedPromptsModal() {
    this.closeSavedPromptDetailModal()
    this.kmdModalService.open('saved-prompts-modal');
  }

  closeSavedPromptsModal() {
    this.searchText = '';
    this.currentTab = 'private';
    this.kmdModalService.close('saved-prompts-modal');
  }

  useCurrentPrompt(prompt: string) {
    this.closeSavedPromptDetailModal();
    this.promptText.emit(prompt);
    this.closeSavedPromptsModal();
    this.mobileSavedPromptsClosed.emit(true);
  }

  useCurrentPromptInPromptDetailModal(oldPrompt: string, newPrompt: string) {
    if (oldPrompt === newPrompt) {
      this.closeSavedPromptDetailModal();
      this.promptText.emit(oldPrompt);
      this.closeSavedPromptsModal();
      this.mobileSavedPromptsClosed.emit(true);
    }
    else {
      if(this.isMobileScreen()) {
        this.showusePromptWhenNotSavedAlert = true;
        setTimeout(() => {
          this.showusePromptWhenNotSavedAlert = false;
          }, 3000);
      }
      else {
        this.alertService.showError(this.usePromptWhenNotSavedErrorMessage);
      }
    }
  }

  useCurrentPromptInNewChat(prompt: string) {
    this.closeSavedPromptDetailModal();
    this.promptTextInNewChat.emit(prompt);
    this.closeSavedPromptsModal();
    this.mobileSavedPromptsClosed.emit(true);
  }

  filterData() {
    const searchTextLower = this.searchText.toLowerCase();
    if (this.currentTab === 'public') {
      this.savedPromptsPublicSearched = this.savedPromptsPublic.filter((item) => {
        return item.promptTitle.toLowerCase().includes(searchTextLower) || item.promptDescription.toLowerCase().includes(searchTextLower);
      });
    } else if (this.currentTab === 'private') {
      this.savedPromptsPrivateSearched = this.savedPromptsPrivate.filter((item) => {
        return item.promptTitle.toLowerCase().includes(searchTextLower) || item.promptDescription.toLowerCase().includes(searchTextLower);
      });
    }
  }

  openRecentTab() {
    this.closeSavedPromptDetailModal();
    if (this.currentTab === 'private') {
      this.getSavedPromptsPrivate();
    } else {
      this.getSavedPromptsPublic();
    }
    setTimeout(() => {
      this.tabsComponent.select(this.currentTab);
    }, 5);
  }
}
