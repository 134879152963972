import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { MsGraphProviderService } from '@services/ms-graph-provider/ms-graph-provider.service';
import { PromptGeneApiService } from '@services/prompt/prompt-gene-api.service';
import { PromptService } from '@services/prompt/prompt.service';
import { ModelFactory } from "@shared/models/gpt-models/model-factory";
import { AuthModule } from 'angular-auth-oidc-client';
import { BasehttpInterceptor } from './interceptors/basehttp.interceptor';

@NgModule({
  declarations: [],
  providers: [
    { provide: PromptService, useExisting: PromptGeneApiService },
    { provide: ModelFactory },
    { provide: 'BASE_API_URL', useValue: environment.apiUrl },
    { provide: 'ASK_DATA_BASE_API_URL', useValue: environment.askOurDataApiUrl },
    { provide: 'ASK_DOCS_BASE_API_URL', useValue: environment.askOurDocsIframeUrlV2 },    
    { provide: 'RESOURCES_URL', useValue: environment.resourcesUrl },
    { provide: HTTP_INTERCEPTORS, useClass: BasehttpInterceptor, multi: true },
    { provide: 'AUTH_PROVIDER', useClass: MsGraphProviderService },
    { provide: 'AOD_SUPER_ADMIN_ID', useValue: environment.aodSuperAdminGroupId },
    { provide: 'AUTH_APP_ID', useValue: environment.authAppId },
    { provide: 'TOKEN_ENDPOINT', useValue: 'https://login.microsoftonline.com/b67d722d-aa8a-4777-a169-ebeb7a6a3b67/oauth2/v2.0/token' },
    { provide: 'TRANSLATIONS_API_URL', useValue: environment.translationsApiUrl }
  ],
  imports: [AuthModule.forRoot({
    config: {
      authority: 'https://login.microsoftonline.com/b67d722d-aa8a-4777-a169-ebeb7a6a3b67/v2.0',
      authWellknownEndpointUrl: 'https://login.microsoftonline.com/b67d722d-aa8a-4777-a169-ebeb7a6a3b67/v2.0',
      redirectUrl: window.location.origin,
      postLoginRoute: "/ask-gene",
      unauthorizedRoute: "/signin",
      forbiddenRoute: "/error",
      postLogoutRedirectUri: window.location.origin,
      clientId: environment.authAppId,
      scope: 'openid profile email offline_access ' + environment.authAppId + "/.default",
      responseType: 'code',
      silentRenew: true,
      ignoreNonceAfterRefresh: true,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      issValidationOff: true,
      autoUserInfo: false,
      useRefreshToken: true,
      renewUserInfoAfterTokenRenew: true,
      logLevel: environment.authLogLevel,
    }
  }), CommonModule]
})
export class CoreModule { }
