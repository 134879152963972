import { Component, Input } from '@angular/core';
import { FileManagerUpload } from "@shared/models/file-manager/file-manager-upload.model";

@Component({
  selector: 'app-uploaded-files-banner-manager',
  templateUrl: './uploaded-files-banner-manager.component.html',
  styleUrl: './uploaded-files-banner-manager.component.css'
})
export class UploadedFilesBannerManagerComponent {

  @Input() uploadedFiles: FileManagerUpload[] = [];

}
