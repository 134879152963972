<div class="context-menu"
     [@slide]="getAnimationState()"
     [ngClass]="{'disabled': !isMenuOpen}">
  <ul>
    <li *ngFor="let item of items"
        (click)="onItemClick(item)"
        [ngClass]="{'selected-item': item === selectedItem}">
      <p>{{ item.label }}</p>
      <div class="menu-item-description">{{ item.description }}</div>
    </li>
  </ul>
</div>
