import { FileReference } from "./file-reference.model";
import { Author, Message } from "./message.model";
import { PromptSettings } from "./prompt-settings.model";

export class Prompt {
  id?: string;
  title?: string;
  text: string;
  promptSettings: PromptSettings;
  author?: Author;
  answer?: Message;
  category?: string;
  files?: string[];
  parentId: string | undefined;
  gptEnabled: boolean | undefined;
  fileReferences?: FileReference[];
  chatId?: string;
  isPublic?: boolean;

  constructor(text: string, promptSettings: PromptSettings);
  constructor(text: string, promptSettings: PromptSettings, parentId?: string | undefined);
  constructor(text: string, promptSettings: PromptSettings, parentId?: string | undefined, gptEnabled?: boolean | undefined);
  constructor(text: string, promptSettings: PromptSettings, parentId?: string | undefined, files?: string[] | undefined);

  public constructor(...args: any[]) {
    this.text = args[0]
    this.promptSettings = args[1]
    this.parentId = args[2]
    if (Array.isArray(args[3])) {
      this.files = args[3]
    } else {
      this.gptEnabled = args[3]
    }
  }
}

export const DefaultCategories = [
  "Marketing", "Quality", "Coding", "Design", "R&D", "Sales", "Customer Service", "Human Resources", "PPI"] as const;
