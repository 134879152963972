<div [ngClass]="getExamplePromptClass()">
  <img id="gene-ai-logo" src="../../../assets/images/GeneAI-Logo.svg" alt="">
  <div *ngIf="showHeaderMessage" class="header-wrapper">
    <h2 class="greeting">Hello <span id="username">{{ userName }}</span> how can I assist you today?</h2>
    <span class="title">
      {{ firstPart }}
      <a [href]="hyperLinkPart" class="hyperlink" target="_blank" rel="noopener noreferrer">
        {{ highlightedPart }}
      </a>
      {{ lastPart }}
    </span>

    <ng-content *ngIf="repoDetails.subtitle">
      <span class="title">
        {{ subtitleFirstPart }}
        <a [href]="subtitleHyperLinkPart" class="hyperlink" target="_blank" rel="noopener noreferrer">
          {{ subtitleHighlightedPart }}
        </a>
        {{ subtitleLastPart }}
      </span>
    </ng-content>

    <p class="disclaimer">
      Inaccuracies may occur in Gene.AI, make sure to cross-verify critical information.
      Please <a [routerLink]="['/guidelines']">consult our guidelines</a>
      for additional information.
    </p>
  </div>
  <div class="example-wrapper" *ngIf="repoDetails.enable_tile !== false">
        <ng-container *ngIf="repoDetails.tile_prompts; else noUxRevampChips">
            <div *ngFor="let prompt of repoDetails.tile_prompts" class="example">
            <app-chip [text]="prompt.shortPrompt" [closeable]="false"
                      [type]="ChipTypeConstant.EXAMPLE_PROMPT"
                      (click)="exampleSelected(prompt)"/>
            </div>
        </ng-container>

        <ng-template #noUxRevampChips>
          <div *ngFor="let prompt of repoDetails.sample_questions" class="example">
            <kmd-chips class="category-badge" chipsType="choice" [selected]="selectedChip === prompt"
                       (clicked)="exampleSelected(prompt)" chipsText={{prompt}}></kmd-chips>
          </div>
        </ng-template>
  </div>
</div>
