import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: any, args?: any): any {
        if (isNaN(value)) return '0';
        if (value === null) return '0';
        if (value === 0) return '0';

        let abs = Math.abs(value);
        const rounder = Math.pow(10, 1);
        const isNegative = value < 0;

        let key = '';

        const powers = [
            { key: 'M', value: Math.pow(10, 6) },
            { key: 'K', value: 1000 }
        ];

        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;
            reduced = Math.round(reduced * rounder) / rounder;

            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key
                break;
            }
        }

        return (isNegative ? '-' : '') + abs + key;
    }
}
