import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AskOurDataService } from '@app/core/services/ask-our-data/ask-our-data.service';
import { ActivatedRoute } from '@angular/router';
import { SamplePrompt } from '@shared/models/ask-our-data.model';
import { Messages } from '@app/shared/constants/ask-our-data-constants';

@Component({
  selector: 'app-sample-prompts',
  templateUrl: './sample-prompts.component.html',
  styleUrl: './sample-prompts.component.css'
})
export class SamplePromptsComponent implements OnInit{
  dbname: string= '';
  dbId: string = '';
  @Input() userName: string = '';
  @Output() samplePromptSelectEvent = new EventEmitter<SamplePrompt>();
  @Input() showHeaderMessage: boolean = true;
  @Output() samplePromptsEvent = new EventEmitter<SamplePrompt[]>();
  samplePrompts: SamplePrompt[] = [];
  selectedChip: any = '';
  samplePromptTitleText = 'Enter a prompt in the chat box below or try one of our sample prompts.';
  normalTitleText = 'Enter a prompt in the chat box below';
  showSamplePrompts: boolean = true;
  showTitle: boolean = false;

  constructor(
      private askOurDataService: AskOurDataService,
      private route: ActivatedRoute,
  ){ }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.dbId = params['id'];
      this.dbname = params['name'];
    });
    this.userName = this.getUserName(this.userName);
    this.getPrompts();
  }

  getUserName(name: string): string {
    return name ? ` ${name.split(' ')[1]},` : ',';
  }

  samplePromptSelected(samplePrompt: SamplePrompt) {
    this.selectedChip = samplePrompt;
    this.samplePromptSelectEvent.emit(samplePrompt);
    setTimeout(() => {
      this.selectedChip = null;
    }, 100);
  }
  
  getPrompts() {
    this.askOurDataService.getSamplePrompts(Number(this.dbId), Messages.NUMBER_OF_SAMPLE_PROMPTS).subscribe({
      next: (response) => {
        this.showTitle = true;
        if(response.sample_prompts.length === 0) {
          this.showSamplePrompts = false;
        }
        this.samplePrompts = response.sample_prompts.map((prompt: SamplePrompt) => ({
          question: prompt.question?.trim().replace(/\s+/g, ' '),
          sql: prompt.sql?.trim()
        }));
        this.samplePromptsEvent.emit(this.samplePrompts);
      },
      error: () => {
        this.showTitle = true;
        this.showSamplePrompts = false;
        this.samplePromptsEvent.emit([]);
      }
    });
  }
}