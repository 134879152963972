import { formatDate } from "@angular/common";
import { FileUpload } from "../file-upload.model";
import { getFileExtension, isFileTypeUnsupported } from "./file-manager-doc";

export type FileManagerDoc = {
  id?: string | undefined,
  name: string,
  displayName: string,
  miniDisplayName: string,
  shortDisplayName: string,
  size: string,
  extension: string,
  date: string,
  isSelected: boolean
  status: string,
  isSelectDisabled: boolean,
  isUnsupported: boolean
};

export function isFileManagerDocImageType(file: FileManagerDoc): boolean {
  const lowerCaseName = file.name.toLowerCase();
  return lowerCaseName.endsWith('.jpg') ||
    lowerCaseName.endsWith('.jpeg') ||
    lowerCaseName.endsWith('.png') ||
    lowerCaseName.endsWith('.webp');
}

export function createFileManagerDoc(file: FileUpload, maxFileNameLength: number, parseFileNameToDisplayName: (name: string, length: number) => string, parseShortFileDisplayName: (name: string) => string, getReadableFileSize: (size: number) => string): FileManagerDoc {
  const isUnsupported = isFileTypeUnsupported(file);

  return {
    id: file.id!,
    name: file.name,
    displayName: parseFileNameToDisplayName(file.name, maxFileNameLength),
    miniDisplayName: parseFileNameToDisplayName(file.name, 30),
    shortDisplayName: parseShortFileDisplayName(file.name),
    size: getReadableFileSize(file.fileSize!),
    extension: getFileExtension(file.name),
    date: formatDate(file.updatedAt!, 'MMM-dd-yyyy', 'en-US'),
    isSelected: false,
    status: file.status!,
    isSelectDisabled: file.status === 'ERROR' || isUnsupported,
    isUnsupported: isUnsupported
  };
};
