import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideUpDownAnimation = trigger('slide', [
  state('openUpToDown', style({
    transform: 'translateY(0)',
    opacity: 1
  })),
  state('closedUpToDown', style({
    transform: 'translateY(-100%)',
    opacity: 0
  })),
  state('openDownToUp', style({
    transform: 'translateY(0)',
    opacity: 1
  })),
  state('closedDownToUp', style({
    transform: 'translateY(100%)',
    opacity: 0
  })),
  transition('closedUpToDown => openUpToDown', [
    animate('300ms ease-out')
  ]),
  transition('openUpToDown => closedUpToDown', [
    animate('300ms ease-in')
  ]),
  transition('closedDownToUp => openDownToUp', [
    animate('300ms ease-out')
  ]),
  transition('openDownToUp => closedDownToUp', [
    animate('300ms ease-in')
  ]),
]);
