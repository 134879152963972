import { featureFlags } from "@app/environments/environment";

export type FileType = 'text' | 'spreadsheets' | 'image';

export type FileUploadType = {
  fileType: FileType;
  acceptedFileTypes: string;
  unsupportedFileTypes: string[];
  extensions: string[];
  filesInstructions: string;
  mobileFileInstructions?: string[];
  filesText: string;
  maxFiles: number;
};

const includePptx = featureFlags.powerPointFileProcessing;

export const FileUploadOptions: {
  [key: string]: FileUploadType;
} = {
  TEXT: {
    fileType: 'text',
    acceptedFileTypes: `.txt, .docx, .pdf${includePptx ? ', .pptx' : ''}`,
    unsupportedFileTypes: [],
    extensions: [
      '.txt',
      '.docx',
      '.doc',
      '.pdf',
      ...(includePptx ? ['.pptx'] : [])
    ],
    filesInstructions: `Max 5 files at a time | Max ~ 1000 pages/file${includePptx ? ' <br>PPT file(s): 10 MB with images and tables' : ''}`,
    mobileFileInstructions: [
      'Max 5 files at a time',
      'Max ~ 1000 pages/file',
      ...(includePptx ? ["PPT file(s): 10 MB with images and tables"] : [])
    ],
    filesText: `Text file type(s): .txt, .docx, .pdf${includePptx ? ', .pptx' : ''}`,
    maxFiles: 5
  },
  SPREADSHEETS: {
    fileType: 'spreadsheets',
    acceptedFileTypes: '.csv, .xlsx',
    unsupportedFileTypes: ['.xls'],
    extensions: [
      '.csv',
      '.xlsx',
      '.xls'
    ],
    filesInstructions: 'Data file type: .xlsx Only one file at a time | Max 15 MB',
    mobileFileInstructions: [
      'Data file type: .xlsx Only one file at a time | Max 15 MB'
    ],
    filesText: 'Data file type: .csv Only one file at a time | Max 80 MB',
    maxFiles: 1
  },
  IMAGE: {
    fileType: 'image',
    acceptedFileTypes: '.jpg, .jpeg, .png, .webp',
    unsupportedFileTypes: [],
    extensions: [
      '.jpg',
      '.jpeg',
      '.png',
      '.webp'
    ],
    filesInstructions: 'Max 5 files at a time | Max 5 MB/file',
    mobileFileInstructions: [],
    filesText: 'Image file type(s): .jpg, .jpeg, .png, .webp',
    maxFiles: 5
  }
};

export const FileTypes: { [key: string]: any } = {
  TEXT: {
    TXT: 'TXT' as const,
    DOCX: 'DOCX' as const,
    PDF: 'PDF' as const,
    DOC: 'DOC' as const,
    PPTX: 'PPTX' as const
  },
  SPREADSHEETS: {
    XLSX: 'XLSX' as const,
    XLS: 'XLS' as const,
    CSV: 'CSV' as const
  },
  IMAGE: {
    JPG: 'JPG' as const,
    JPEG: 'JPEG' as const,
    PNG: 'PNG' as const,
    WEBP: 'WEBP' as const
  }
};


