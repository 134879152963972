<div class="aod-user-manage-container">
  <div class="aod-user-manage-header">
    <a class="back-to-aod" [routerLink]="aodUrl">
      <img src="../../../assets/icons/arrows/arrowLeft.svg"><span>Ask Our Docs</span>
    </a>
    <h1 id="repoName" (click)="navigateAndOpenNewChat()">{{ repoName }}</h1>
  </div>
  <!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
  <div class="loading-wheel-wrapper" *ngIf="!(allUsers$ | async)">
    <kmd-loading-wheel></kmd-loading-wheel>
  </div>
  <div class="aod-user-manage-content" *ngIf="(allUsers$ | async) as page">
    <!-- <div class="tabs-container"> -->
      <p-tabView [(activeIndex)]="activeIndex">
          <p-tabPanel header="Configuration">
            <div class="configuration">
              <h2>Configuration Settings</h2>
            </div>
            <div class=" mb-5 form-container">
              <form [formGroup]="configForm" (ngSubmit)="openModal('confirmation-modal-save-changes')">
                <!-- <div class="form-section">
                    <label for="dropdowns-container">Select your Repository</label>
                    <kmd-dropdown class="dropdowns-container" formControlName="selectedRepo" ngDefaultControl [options]="repoOptions" optionLabelKey="app" [externalLabel]="true"></kmd-dropdown>
                  </div> -->
                <div class="form-section">
                  <label class="required-label">System instructions</label>
                  <div class="input-group">
                    <textarea class="form-input" placeholder="Input text value" formControlName="system_message" kmdFormTextarea rows="3"></textarea>
                    <div>
                      <div *ngIf="configForm.get('system_message')?.touched && configForm.get('system_message')?.invalid"
                        class="error-message">System prompt is required</div>
                    </div>
                  </div>
                </div>
  
                <div class="form-section form-section-about-repo">
                  <label>About repository</label>
                  <div class="input-group">
                    <textarea class="form-input" placeholder="Input text value" formControlName="usecase_description" kmdFormTextarea rows="3"></textarea>
                    <div>
                    </div>
                  </div>
                </div>
                <div class="toggle-button">
                  <div class="toggle-button-form-section">
                    <label for="small-toggle" class="mr-2">Citation Download</label>
                    <kmd-toggle ngDefaultControl formControlName="enable_citation_download" class="kmd-toggle-button"></kmd-toggle>
                  </div>
                  <div class="ftoggle-button-form-section">
                    <label for="small-toggle" class="mr-2">Show repository link</label>
                    <kmd-toggle ngDefaultControl formControlName="enable_repository_link" class="kmd-toggle-button"></kmd-toggle>
                  </div>
                </div>
  
                <div class="welcome-message-form-section">
                  <div>
                    <label class="welcome-text">Welcome message</label>
                  </div>
                  <div class="form-selection">
                    <label class="required-label required-label-bold">Title</label>
                    <div class="input-group">
                      <input class="form-input form-input-title" [value]="title_value" placeholder="Input text value" kmdFormInput
                        readonly />
                    </div>
                  </div>
                  <div class="form-selection">
                    <label class="required-label required-label-bold text-spacing">Subtitle</label>
                    <div class="container-for-modal">
                      <div class="subtitle-input-group">
                        <div class="input-group">
                          <div *ngIf="hyperlinkInSubtitle; else noHyperlink">
                            <angular-editor class="subtitle-angular-editor" formControlName="subtitle" (mouseup)="checkSelection()" [config]="config"/>
                            <div class="subtitle-support-text">To add a link, select desired word then click the button on the right.</div>
                            <div>
                              <div *ngIf="configForm.get('subtitle')?.touched && configForm.get('subtitle')?.invalid" class="error-message">
                                This is a required field
                              </div>
                            </div>
                          </div>
                          <ng-template #noHyperlink>
                            <input class="form-input" placeholder="Input text value" formControlName="subtitle" kmdFormInput />
                            <div>
                              <div *ngIf="configForm.get('subtitle')?.touched && configForm.get('subtitle')?.invalid" class="error-message">
                                This is a required field
                              </div>
                            </div>
                          </ng-template>
                        </div>
                        <button class="paper-clip-button" (click)="openLinkModal($event)" [disabled]="!isButtonEnabled" *ngIf="hyperlinkInSubtitle">
                          <svg width="24" height="24" viewBox="0 0 24 24" transform="rotate(45)" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.6989 8.55411C9.16933 8.02843 9.16933 7.17325 9.69629 6.64629L15.0743 1.26829C15.5998 0.742764 16.453 0.742764 16.9785 1.26829L20.7315 5.02129C21.257 5.54681 21.257 6.39998 20.7314 6.92557L15.3551 12.3009C14.8296 12.8303 13.9736 12.8309 13.4474 12.3026L12.2047 11.06L11.0598 12.2049L12.3059 13.4511C12.828 13.977 12.828 14.8252 12.3033 15.3537L6.92269 20.7343C6.39677 21.2564 5.54862 21.2564 5.0227 20.7343L1.26548 16.9771C0.74343 16.4512 0.74343 15.603 1.26809 15.0745L6.6487 9.69388C7.17462 9.17183 8.02277 9.17183 8.5513 9.69649L9.64554 10.7907L10.7905 9.64574L9.6989 8.55411ZM13.619 9.64575L14.6951 8.5696L13.2809 7.15539L12.2047 8.23153L11.5721 7.5989L16.0264 3.14461L18.8551 5.97335L14.4004 10.4272L13.619 9.64575ZM8.23133 12.2049L7.5987 11.5723L3.1439 16.0271L5.97269 18.8559L10.4275 14.4011L9.64554 13.6192L8.4851 14.7796L7.07089 13.3654L8.23133 12.2049Z" fill="#54545C"/>
                          </svg>  
                        </button>
                        <div class="modal-link" *ngIf="isLinkModalVisible">
                          <h5 class="modal-heading">Insert Link</h5>
                          <form class="modal-link-form" [formGroup]="modalForm" (ngSubmit)="insertLink()">
                            <div class="modal-input-group">
                              <label for="selectedText" class="required-label">Text</label>
                              <input id="selectedText" type="text" class="modal-input" formControlName="selectedText" readonly/>
                            </div>
                            <div class="modal-input-group">
                              <label for="linkAddress" class="required-label">Address</label>
                              <input id="linkAddress" placeholder="Enter link address" type="text" class="modal-input" formControlName="linkAddress" />
                              <div *ngIf="modalForm.get('linkAddress')?.touched && modalForm.get('linkAddress')?.invalid" class="error-message error-message-link">
                                Enter a valid url
                              </div>
                            </div>
                            <div class="modal-buttons">
                              <button class="modal-cancel-button" kmdInfoButton (click)="closeLinkModal($event)">Cancel</button>
                              <button class="modal-unlink-button" kmdFeaturedButton (click)="unlink()" *ngIf="isLinkSelected">Unlink</button>
                              <button class="modal-insert-button" type="submit" kmdFeaturedButton [disabled]="modalForm.invalid">Insert</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="form-section" *ngIf="promptSuggestions">
                  <div class="prompt-toggle-container">
                    <label for="small-toggle" class="mr-2 prompt-suggestions-label">Prompt suggestions</label>
                    <kmd-toggle id="enable_prompt" formControlName="enable_tile" ngDefaultControl
                      (change)="togglePrompt()" class="kmd-toggle-button"></kmd-toggle>
                  </div>
                  <div formArrayName="tile_prompts">
                    <div class="prompt-row" *ngFor="let row of tilePrompts.controls; let i = index" [formGroupName]="i">
                      <div class="input-group">
                        <div class="title-header-container">
                          <label kmdFormLabel class="required-label">Title</label>
                          <small class="text-muted ml-2">
                            {{row.get('shortPrompt')?.value ? row.get('shortPrompt')?.value.toString().length : 0}}/50
                          </small>
                        </div>
                        <input class="input-group-inline form-input" placeholder="Input text value"
                          formControlName="shortPrompt" kmdFormInput maxlength="50"/>
                        <div *ngIf="row.get('shortPrompt')?.hasError('duplicate')" class="error-message">Duplicate short
                          prompts are not allowed</div>
                        <div *ngIf="row.get('shortPrompt')?.touched && row.get('shortPrompt')?.invalid"
                          class="error-message">Short prompt is required</div>
                        <div *ngIf="row.get('shortPrompt')?.hasError('tooLong')" class="note-message">Character limit
                          reached. Exceeding this limit may cause the prompt to overflow outside the tile on mobile
                          screens</div>
                      </div>
                      <div class="button-container library-icomoon mb=1">
                        <button kmdFeaturedButton type="button" size="mini" (click)="moveUp(tilePrompts, i)"
                          [disabled]="i==0"><i class="icon-up-arrow"></i></button>
                        <button kmdFeaturedButton type="button" size="mini" (click)="moveDown(tilePrompts, i)"
                          [disabled]="i === tilePrompts.controls.length - 1"><i class="icon-down-arrow"></i></button>
                        <button kmdFeaturedButton type="button" size="mini" (click)="removePrompt(i)"><i
                            class="icon-delete"></i></button>
                      </div>
                      <div class="input-group">
                        <div class="title-header-container">
                          <label kmdFormLabel class="required-label">Prompt</label>
                        </div>
                        <input type="text" class="form-input" placeholder="Input text value" minlength="1"
                          formControlName="longPrompt" kmdFormInput/>
                        <div *ngIf="row.get('longPrompt')?.touched && row.get('longPrompt')?.invalid"
                          class="error-message">Long prompt is required</div>
                      </div>
                    </div>
                    <div class="save-prompt-button-container">
                      <button kmdSecondaryButton type="button" size="mini" (click)="addPrompts()">Add Prompt</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="fixed-footer">
              <div class="cancel">
                <button kmdLinkButton (click)="cancelConfigForm()">Cancel</button>
              </div>
              <div class="submit">
                <button [disabled]="!isConfigFormReadyToSave()"
                        kmdFeaturedButton
                        type="submit"
                        (click)="openModal('confirmation-modal-save-changes')">Save
                </button>
              </div>
            </div>  
          </p-tabPanel>
          <p-tabPanel header="User access">
            <div class="user-access">
              <h2>User Access</h2>
            </div>
            <div class="aod-user-manage-toolbar" [formGroup]="formGroup">
              <div class="toolbar-section left">
                <div class="aod-user-manage-search">
                  <input id="search-input" class="search-term" type="text" formControlName="search" kmdFormInput
                    placeholder="Search by User Email" autocomplete="off" />
                  <i class="kmd-icon-close-mono kmd-close-black-icon search-close" *ngIf="!isSearchEmpty()"
                    (click)="clearSearch()"></i>
                </div>
                <div class="aod-user-manage-role-filter">
                  <kmd-dropdown id="role-dropdown" formControlName="role" [options]="roleFilters" inlineLabel="Role"
                    (onChange)="onRoleDropdownChange($event)"></kmd-dropdown>
                </div>
              </div>
              <div class="toolbar-section right">
                <div id="assign-role" class="toolbar-control" [class.aod-btn-disabled]="selectedUsers.size === 0">
                  <div id="assign-role-btn" (click)="toggleAssignRoles()">
                    <label>Assign role</label>
                    <img
                      src="{{ showRoles ? '../../../assets/icons/caret/caretUp-11px-mono-blue.svg' : '../../../assets/icons/caret/caretDown-11px-mono-blue.svg' }}">
                  </div>
                  <div id="assign-aod-roles" [hidden]="!showRoles">
                    <core-contextmenu>
                      <core-contextmenu-item height='42px' label='User' variant='default' id="aod-user-role"
                        (click)="revokeAdminAccess()" />
                      <core-contextmenu-item height='42px' label='Admin' variant='default' id="aod-admin-role"
                        (click)="grantAdminAccess()" />
                    </core-contextmenu>
                  </div>
                </div>
                <div id="remove-users-btn" class="toolbar-control" (click)="openRemoveUsersModal()"
                  [class.aod-btn-disabled]="selectedUsers.size === 0">
                  <img src="../../../assets/icons/bin/aod-bin.svg">
                  <label>Remove</label>
                </div>
                <div id="add-users-btn" class="toolbar-control" (click)="openAddUsersModal()">
                  <img src="../../../assets/icons/plus/plus-24px-mono.svg">
                  <label>Add users</label>
                </div>
              </div>
            </div>
            <div class="aod-user-manage-results-counter" *ngIf="!isSearchEmpty()">
              <span class="aod-results-counter-span">
                {{
                isSearchEmpty() ? page.totalElements + ' results' : page.totalElements + ' results for "' +
                formGroup.controls.search.value + '"'
                }}
              </span>
            </div>
            <div class="aod-user-selected-bar" *ngIf="selectedUsers.size > 0">
              <span>{{ getSelectionCounterLabel(selectedUsers.size) }}</span>
              <a class="aod-clear-selection" (click)="clearSelection()">
                <img src="../assets/icons/close/size=16px, style=mono.svg"> Clear selection
              </a>
            </div>
            <div class="aod-user-manage-table">
              <div class="aod-table-wrapper">
                <table *ngIf="page.content.length > 0; else noUserView">
                  <thead>
                    <tr class="table-head-tr">
                      <th class="head-checkbox">
                        <input #headCheckbox class="head-item table-th-checkbox" type="checkbox"
                          (click)="toggleCheckboxes($event,true)" />
                      </th>
                      <th class="head-user-email">
                        <div class="head-item" (click)="sortByEmail()">
                          <label>User email</label>
                          <div class="sorting-action-icon">
                            <i class="icon-caret-up user-email-up"></i>
                            <i class="icon-caret-down user-email-down"></i>
                          </div>
                        </div>
                      </th>
                      <th class="head-role">
                        <div class="head-item" (click)="sortByRole()"
                          [ngClass]="activeRoleFilter !== ALL_ROLES_FILTER ? 'disabled-role-sort' : ''">
                          <label>Role</label>
                          <div class="sorting-action-icon">
                            <i class="icon-caret-up role-up"></i>
                            <i class="icon-caret-down role-down"></i>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table-body-tr" *ngFor="let user of page.content"
                      [ngClass]="{'tr-checked': selectedUsers.has(user.id)}">
                      <td class="body-checkbox">
                        <input id="user-checkbox" class="body-item table-td-checkbox" type="checkbox" [value]="user.id"
                          [checked]="selectedUsers.has(user.id)" (click)="toggleCheckboxes($event,false)" />
                      </td>
                      <td class="body-user-email">
                        <div class="body-item">
                          {{ user.email }}
                        </div>
                      </td>
                      <td class="body-role">
                        <div class="body-item">
                          {{ user.role }}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="aod-user-manage-pagination" *ngIf="page.content.length > 0">
                  <kmd-pagination #pagination [totalResults]="page.totalElements" (onPageChange)="onPageChange($event)"
                                  [resultsPerPage]="RESULTS_PER_PAGE" [resultsPerPageOptions]="[20, 100, 200]">
                  </kmd-pagination>
                </div>
              </div>
              <div class="aod-no-search-results" *ngIf="page.content.length === 0 && !isSearchEmpty()">
                <img src="../../../assets/images/search.svg">
                <p class="no-matches">No matches for "{{ formGroup.controls.search.value }}"</p>
                <p class="advice">Try using different terms</p>
              </div>
          </p-tabPanel>
          <p-tabPanel header="Feedback form">
            <div class="feedback-form">
              <!-- Calling admin feedback form component -->
              <app-admin-landing-feedback-form [indexName]="indexName" (redirectionToCustomizableFeedback)="navigateToFeedback()"></app-admin-landing-feedback-form>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Prompt library" *ngIf="featureFlags.promptLibrary">
            <div class="prompt-library">
              <app-admin-prompt-library #promptLibrary (cancelFormEvent)="cancelAndDontSaveChanges()" [indexName]="indexName" *ngIf="true"></app-admin-prompt-library>
            </div>
          </p-tabPanel>
      </p-tabView>

    <!-- </div> -->
  </div>
  <ng-template #noUserView>
    <div class="aod-user-manage-no-user-view" *ngIf="isSearchEmpty()">
      <h2 class="aod-user-manage-no-user-content">There's no assigned admins or users</h2>
    </div>
  </ng-template>
</div>

<app-ask-our-docs-user-management-add-users id="addUsersModal" (onSubmit)="addUsers($event)" #addUsersModal />
<app-ask-our-docs-user-management-remove-users [id]="groupId" [users]="selectedUsers" [admins]="repoAdmins"
  #removeUsersModal (afterRemoveUsers)="reloadTable()" />

<kmd-modal id="revokeSelfAdminModal" headline="Change your role" modalSize="mini">
  <p class="modal-message">
    You cannot change your own role from the repository. Please ask another admin to change your role.
  </p>
  <div class="aod-buttons-container">
    <button class="cancel-button" kmdFeaturedButton (click)="closeModal('revokeSelfAdminModal')">Close</button>
  </div>
</kmd-modal>

<!-- User navigation alert -->
<kmd-modal [id]="'confirmation-modal-unsaved-changes-nav'" class="confirmation-modal" modalSize="mini"
  headline="Unsaved changes" (clickedOutside)=" closeModal('confirmation-modal-unsaved-changes-nav')">
  <div>
    <span class="text">
      You're about to leave this place. Any unsaved changes will be lost
    </span>
  </div>
  <div>
    <span class="text-two">
      Would you like to save the changes before leaving?
    </span>
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-button-model" (click)=" closeModal('confirmation-modal-unsaved-changes-nav')"
      kmdPrimaryButton>Don't save</button>
    <button [disabled]="!isConfigFormReadyToSaveNav()" id="confirm-button-model" (click)="saveUpdatedForms()" kmdFeaturedButton>Save changes</button>
  </div>
</kmd-modal>

<!-- When user clicks on cancel button -->
<kmd-modal [id]="'confirmation-modal-unsaved-changes'" class="confirmation-modal" modalSize="mini"
  headline="Unsaved changes" (clickedOutside)=" closeModal('confirmation-modal-unsaved-changes')">
  <div>
    <span class="text">
      Are you sure you want to cancel changes to the configuration form?
    </span>
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-button-model" (click)="cancelAndDontSaveChanges()"  kmdPrimaryButton>Don't save</button>
    <button [disabled]="!isConfigFormReadyToSave()" id="confirm-button-model" (click)="cancelAndSaveChanges()" kmdFeaturedButton>Save changes</button>
  </div>
</kmd-modal>


<!-- When clicked on Save -->
<kmd-modal [id]="'confirmation-modal-save-changes'" class="confirmation-modal" modalSize="mini" headline="Save changes"
  (clickedOutside)=" closeModal('confirmation-modal-save-changes')">
  <div>
    <span class="text">
      Are you sure you want to save changes to the configuration form?
    </span>
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-button-model" (click)="closeModal('confirmation-modal-save-changes')"
      kmdPrimaryButton>Cancel</button>
    <button id="confirm-button-model" kmdFeaturedButton (click)="submitForm()">Confirm</button>
  </div>
</kmd-modal>

<div class="alert-container">
  <kmd-alerts kmd-success [show]="true" *ngIf="alertType ==='success'" [icon]="true"
    [dismissible]="true">{{ alertMessage }}</kmd-alerts>
  <kmd-alerts kmd-error [show]="true" *ngIf="alertType ==='error'" [icon]="true">{{ alertMessage
    }}</kmd-alerts>
  <kmd-alerts kmd-warning [show]="true" *ngIf="alertType ==='warning'" [icon]="true">{{ alertMessage
    }}</kmd-alerts>
</div>