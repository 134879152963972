import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AlertService } from "@app/core/services/alert/alert.service";
import { AskOurDocsV2Service } from '@app/core/services/ask-our-docs-v2/ask-our-docs-v2.service';
import { KmdTabsComponent, KmdModalService } from 'gds-atom-components';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: "app-save-prompt-aod",
  templateUrl: "./save-prompt.component.html",
  styleUrls: ["./save-prompt.component.css"],
})
export class AskOurDocsV2SavePromptComponent {
  @ViewChild('tabsComponent') tabsComponent!: KmdTabsComponent;

  @Input() internalIndexName: string = "";
  @Input() repoId: string = "";
  @Input() prompt: string = '';
  @Output() promptTextInNewChat = new EventEmitter<string>();


  currentSavePrompt = ''
  promptTitle: string = '';
  promptDescription: string = '';


  constructor(
    private askOurDocsV2Service: AskOurDocsV2Service,
    private kmdModalService: KmdModalService,
    private alertService: AlertService
  ) { }

  setCurrentPrompt(prompt: string) {
    this.promptTitle = prompt.slice(0, 50);
    this.promptDescription = prompt;
  }

  closeSaveCurrentPromptModal() {
    this.kmdModalService.close('save-current-prompt-modal');
  }

  savePrivatePrompt() {
    this.askOurDocsV2Service.savePrompt(
      uuidv4(),
      this.repoId,
      'private',
      this.promptTitle,
      this.promptDescription
    ).then((response) => {
      if (response.message) {
        this.alertService.showSuccess('Prompt saved successfully.');
      }
      this.closeSaveCurrentPromptModal();
    }).catch(e => {
      console.log(e);
      this.alertService.showError('Prompt save unsuccesful.');
    });
  }

}