export type Agent = {
  id?: string;
  azureGroupId?: string;
  name: string;
  description: string;
  instructions: string;
  category: Category;
  createdBy?: string;
  capabilities?: string[],
  conversationStarters?: string[],
  isPublic?: boolean;
  allowShare?: boolean;
  created?: Date;
  aodRepositories?: string[];
  files?: string[];
  rating?: {
    likes: number;
    likedByUser: boolean;
  }
  isRecommended?: boolean;
  publishedByUser?: boolean;
  updated?: Date;
  admins?: AgentAdmin[];
  agentType?: string;
}

export type AgentAdmin = {
  name: string;
  email: string;
  lastname: string;
  role: string;
}

export const Categories = [
  'Commercial', 'Communications', 'Corporate Development', 'Customer Care', 'Finance',
  'Human Resources', 'IT', 'Legal', 'Marketing','PPI', 'Procurement', 'Quality', 'R&D', 'Supply Chain', 'Other'] as const;
export type Category = typeof Categories[number] | '';

export const AgentCategoryFilters = ["All agents", ...Categories] as const;

export type AgentCategoryFilter = typeof AgentCategoryFilters[number];
export type AgentType = 'public' | 'private'| 'shared';

export function addAllowShareToAgents(agents: Agent[]): Agent[] {
  return agents.map(agent => {
    agent.allowShare = false;
    // TODO: It should not have any capability to allow sharing
    const isWebSearch = agent.capabilities?.indexOf("WEBSEARCH") !== -1 && agent.capabilities?.length === 1;
    if (isWebSearch || agent.capabilities?.length === 0) {
      agent.allowShare = true;
    }
    return agent;
  });
}

export const SortOptions: string[] = ['A-Z', 'Newest to oldest', 'Oldest to newest', 'Most popular'];

export const SortOptionsByType = {
  public: ['A-Z', 'Newest to oldest', 'Oldest to newest', 'Most popular'],
  private: ['A-Z', 'Newest to oldest', 'Oldest to newest'],
  shared: ['A-Z', 'Newest to oldest', 'Oldest to newest']
};

export type SortOption = typeof SortOptions[number];
type direction = 'ASC' | 'DESC';
export type SortOptionValue = { field: string, direction: direction };
export const SortOptionValues: Record<SortOption, SortOptionValue> = { 'A-Z': { field: 'name', direction: 'ASC' },
                                                            'Newest to oldest': { field: 'created_at', direction: 'DESC' },
                                                            'Oldest to newest': { field: 'created_at', direction: 'ASC' },
                                                            'Most popular': { field: 'most_popular', direction: 'DESC' }};

