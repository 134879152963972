import {
  Component, EventEmitter, Inject, Input, OnChanges, Output,
  ViewEncapsulation
} from '@angular/core';
import { Chat } from '../shared/models/chat.model';
import { faMessage, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { KmdModalService } from 'gds-atom-components';
import { catchError, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ChatHistoryService } from "@services/chathistory/chat-history.service";
import { featureFlags } from '@app/environments/environment';

@Component({
  selector: 'chat-history',
  templateUrl: './chathistory.component.html',
  styleUrls: ['./chathistory.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatHistoryComponent implements OnChanges {

  @Input() selectedChat?: Chat;
  @Output() selectedChatEvent = new EventEmitter<Chat>();
  @Output() deletedChatEvent = new EventEmitter<Chat>();

  sortedChats!: Observable<Chat[]>;

  showAlert = false;
  alertText = "";
  alertType = "success";

  faTrashCan = faTrashCan;
  faMessage = faMessage;

  inputValue: string = '';
  editing: boolean = false;
  protected readonly featureFlags = featureFlags;


  constructor(
    @Inject('ChatHistoryService') private chatHistoryService: ChatHistoryService,
    @Inject('chatHistoryType') private chatHistoryType: string,
    private kmdModalService: KmdModalService) { }

  ngOnChanges(): void {
    this.loadChatHistory()
  }

  loadChatHistory() {
    this.sortedChats = this.chatHistoryService.getChats(this.chatHistoryType).pipe(
      map(chats => this.sortChatsByDate(chats)),
      catchError(() => {
        this.showAlertMessage('error', 'Unable to load chat history. There was an error contacting the server.');
        throw new Error('Error loading chats');
      })
    )
  }

  private sortChatsByDate(res: Array<Chat>) {
    return res.sort((chatOne: Chat, chatTwo: Chat) => {
      return chatTwo.created.getTime() - chatOne.created.getTime()
    });
  }

  isMenuItemSelected(menuItem: string | undefined): boolean {
    return menuItem == this.selectedChat?.id;
  }

  isEditing(menuItem: string | undefined): boolean {
    return menuItem == this.selectedChat?.id && this.editing;
  }

  startEditing(value: string) {
    this.editing = true;
    this.inputValue = value;

    setTimeout(() => {
      const inputTextArea = document.querySelector('.menu .li-selected .textarea-title') as HTMLTextAreaElement;
      if (inputTextArea) {
        inputTextArea.focus();
        this.autoSizeTextArea();
      }
    }, 200);
  }

  stopEditing(chat: Chat | undefined) {
    this.editing = false;
    if (chat != undefined) {
      let tmpChat: string = chat.title;
      chat.title = this.inputValue.trim();
      this.chatHistoryService.update(chat).subscribe({
        next: () => {
          this.showAlertMessage('success', 'Chat title Saved.');
        },
        error: () => {
          chat.title = tmpChat;
          this.showAlertMessage('error', 'The chat title cannot be saved. There was a problem contacting the server.');
        }
      });
    }
  }

  showAlertMessage(type: string, text: string) {
    this.alertType = type;
    this.alertText = text;
    this.showAlert = true;
  }

  autoSizeTextArea() {
    const textArea = document.querySelector('.textarea-title') as HTMLTextAreaElement;
    if (textArea) {
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    }
  }

  onKeyDown(event: KeyboardEvent, chat: Chat | undefined) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.stopEditing(chat);
    }

    if (event.key === 'Escape')
      this.stopEditing(undefined);
  }

  openDeleteModal() {
    this.kmdModalService.open('delete-chat-modal');
  }

  closeDeleteModal() {
    this.kmdModalService.close('delete-chat-modal');
  }

  deleteChat(chat: Chat) {
    this.closeDeleteModal();
    this.chatHistoryService.delete(chat).subscribe({
      next: () => {
        this.deletedChatEvent.emit(chat)
      },
      error: () => {
        this.showAlertMessage('error', 'The chat cannot be deleted. There was a problem contacting the server.');
      }
    });
  }

  selectChat(chat: Chat) {
    this.selectedChatEvent.emit(chat);
  }

}
