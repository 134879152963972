import { Injectable } from "@angular/core";
import { AgentsService } from "../services/agents/agents.service";
import { Observable, catchError, of } from "rxjs";
import { Agent } from "@app/shared/models/agent";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

@Injectable()
export class AgentResolver {

    constructor(
        private agentsService: AgentsService,
        private router: Router
    ) { }

    resolve(
        activatedRoute: ActivatedRouteSnapshot
    ): Observable<Agent> {
        return this.agentsService
            .agent(activatedRoute.params['agentId'])
            .pipe(
                catchError(() => {
                    this.router.navigate(['/error']);
                    return of({} as Agent);
                })
            );
    };
};
