import {
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { TooltipComponent } from "@shared/components/tooltip/tooltip.component";
import { isMobileResolution } from "@shared/constants/navbar/screen-resolutions";
import { Positioning } from "@shared/models/positioning";

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnChanges, OnDestroy {
  @Input() text: string = '';
  @Input() position: Positioning = 'top';
  @Input() visible: boolean = true;

  private componentRef: ComponentRef<TooltipComponent> | null = null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef
  ) { }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!isMobileResolution() && this.text && this.componentRef === null && this.visible) {
      this.componentRef = this.viewContainerRef.createComponent(TooltipComponent, {
        injector: this.injector
      });
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      this.renderer.appendChild(document.body, domElem);

      this.componentRef.instance.text = this.text;
      this.componentRef.instance.isVisible = true;
      this.componentRef.instance.position = this.position;
      this.componentRef.instance.setCoordinates(this.el.nativeElement.getBoundingClientRect());
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.clearTooltip();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] && !changes['visible'].firstChange && !this.visible) {
      this.clearTooltip();
    }
  }

  ngOnDestroy(): void {
    this.clearTooltip();
  }

  private clearTooltip() {
    if (this.componentRef !== null) {
      this.renderer.removeChild(document.body, this.componentRef.location.nativeElement);
      this.viewContainerRef.detach();
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
