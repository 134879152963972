export const FileTranslationsProcessingStatus = {
  //Upload Processing
  UPLOAD_IN_PROGRESS: 'upload-in-progress' as const,
  UPLOAD_ERROR: 'upload-error' as const,
  PROCESSING: 'processing' as const,

  //Validation Processing
  FILE_SIZE_EXCEEDED: 'file-size-exceeded' as const,
  LANGUAGE_ERROR: 'language-error' as const,
  UNSUPPORTED_LANGUAGE: 'unsupported-language' as const,
  PASSWORD_PROTECTED: 'password-protected' as const,

  //Translating Processing
  TRANSLATING: 'translating' as const,
  TRANSLATING_ERROR: 'translating-error' as const,
  TRANSLATED: 'translated' as const,
};
