import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Agent } from "@app/shared/models/agent";
import { KmdModalService } from "gds-atom-components";

@Component({
  selector: 'app-agent-overview',
  templateUrl: './agent-overview.component.html',
  styleUrls: ['./agent-overview.component.css']
})
export class AgentOverviewComponent {

  @Input() agent?: Agent
  @Input() modalName?: string = 'view-agent-modal';

  constructor(
    private kmdModalService: KmdModalService,
    private router: Router
  ) {
  }

  closeShareAgentModal() {
    this.kmdModalService.close(this.modalName!);
  }

  startChat() {
    this.router.navigate([`/agents/${ this.agent?.id }/chat`]);
  }

  addToMyAgents() {
    this.router.navigate([`/agents/public/${this.agent?.id}/save`]);
  }

}
