import { Chat } from "src/app/shared/models/chat.model";
import { Prompt } from "src/app/shared/models/prompt.model";

export class ChatAndPrompt {
  chat: Chat;
  prompt: Prompt;

  constructor(chat: Chat, prompt: Prompt) {
    this.chat = chat;
    this.prompt = prompt;
  }
}
