import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { LanguageManagerService } from "@services/file-translations/language-manager/language-manager.service";
import { WindowRef } from "@app/core/services/window/window-ref.service";

interface Language {
  code: string;
  displayName: string;
}

@Component({
  selector: 'app-file-translations-language-dropdown',
  templateUrl: './file-translations-language-dropdown.component.html',
  styleUrl: './file-translations-language-dropdown.component.css'
})
export class FileTranslationsLanguageDropdownComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() onSourceLanguageSelected = new EventEmitter<Language>();
  @Output() onTargetLanguageSelected = new EventEmitter<Language>();

  @ViewChild('sourceDropdown') sourceDropdownRef!: ElementRef;
  @ViewChild('targetDropdown') targetDropdownRef!: ElementRef;

  @ViewChildren('sourceLanguageOption') sourceLanguageOptionRefs!: QueryList<ElementRef>;
  @ViewChildren('targetLanguageOption') targetLanguageOptionRefs!: QueryList<ElementRef>;

  // Reference to search input fields
  @ViewChild('sourceSearchInput') sourceSearchInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('targetSearchInput') targetSearchInputRef!: ElementRef<HTMLInputElement>;

  sourceLanguages: Language[] = [];
  targetLanguages: Language[] = [];

  selectedSourceLanguage: Language | null = null;
  selectedTargetLanguage: Language | null = null;

  isSourceDropdownOpen = false;
  isTargetDropdownOpen = false;

  isMobileView = false;

  //auto scroll properties
  private sourceScrollDebounceTimeout: any = null;
  private targetScrollDebounceTimeout: any = null;

  // Search-related properties
  sourceSearchText: string = '';
  targetSearchText: string = '';

  // Filtered lists based on search
  filteredSourceLanguages: any[] = [];
  filteredTargetLanguages: any[] = [];

  constructor(private languageManagerService: LanguageManagerService) {}

  ngOnInit(): void {
    this.checkScreenSize();
    this.loadSourceLanguages();

    // Initialize filtered lists
    this.filteredSourceLanguages = [...this.sourceLanguages];
    this.filteredTargetLanguages = [...this.targetLanguages];

    this.selectedSourceLanguage = null;
    this.selectedTargetLanguage = null;
  }

  ngOnDestroy(): void {
    if (this.sourceScrollDebounceTimeout) {
      clearTimeout(this.sourceScrollDebounceTimeout);
    }

    if (this.targetScrollDebounceTimeout) {
      clearTimeout(this.targetScrollDebounceTimeout);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();

    // Adjust scroll position for open dropdowns
    if (this.isSourceDropdownOpen) {
      this.scrollToSelectedSourceLanguage();
    }

    if (this.isTargetDropdownOpen) {
      this.scrollToSelectedTargetLanguage();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Close dropdowns when clicking outside
    if (!targetElement.closest('.language-selector-container')) {
      this.isSourceDropdownOpen = false;
      this.isTargetDropdownOpen = false;
    }
  }

  ngAfterViewInit() {
    // For source language options
    this.sourceLanguageOptionRefs?.changes
      .subscribe(() => {
        if (this.isSourceDropdownOpen && this.selectedSourceLanguage) {
          this.scrollToSelectedSourceLanguage();
        }
      });

    // For target language options
    this.targetLanguageOptionRefs?.changes
      .subscribe(() => {
        if (this.isTargetDropdownOpen && this.selectedTargetLanguage) {
          this.scrollToSelectedTargetLanguage();
        }
      });
  }

  checkScreenSize() {
    this.isMobileView = WindowRef.innerWidth < 768;

    // Close dropdowns when switching to mobile view
    if (this.isMobileView) {
      this.isSourceDropdownOpen = false;
      this.isTargetDropdownOpen = false;
    }
  }

  loadSourceLanguages() {
    this.languageManagerService.getSourceLanguages()
      .subscribe({
        next: (languages) => {
          this.sourceLanguages = languages;
          this.filteredSourceLanguages = [...languages];
        },
        error: (error) => {
          console.error('Error loading source languages:', error);
        }
      });
  }

  loadTargetLanguages(sourceCode: string): void {
    this.languageManagerService.getTargetLanguages(sourceCode)
      .subscribe({
        next: (languages) => {
          this.targetLanguages = languages;
          this.filteredTargetLanguages = [...languages];
        },
        error: (error) => {
          console.error('Error loading target languages:', error);
        }
      });
  }

  selectTargetLanguage(language: Language) {
    this.selectedTargetLanguage = language;
    this.isTargetDropdownOpen = false;
    this.onTargetLanguageSelected.emit(language);
  }

  onSourceSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedIndex = selectElement.selectedIndex;

    if (selectedIndex > 0) { // Skip the first "Select language" option
      this.selectSourceLanguage(this.sourceLanguages[selectedIndex - 1]);
    }
  }

  onTargetSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedIndex = selectElement.selectedIndex;

    if (selectedIndex > 0) { // Skip the first "Select language" option
      this.selectTargetLanguage(this.targetLanguages[selectedIndex - 1]);
    }
  }

  getGridStyle(): any {
    const count = this.targetLanguages?.length || 0;

    if (count <= 2) {
      return { 'grid-template-columns': 'repeat(auto-fill, minmax(200px, 1fr))' };
    } else if (count <= 5) {
      return { 'grid-template-columns': 'repeat(auto-fill, minmax(180px, 1fr))' };
    } else {
      return { 'grid-template-columns': 'repeat(auto-fill, minmax(150px, 1fr))' };
    }
  }

  getTargetLanguageDropdownStyle(): any {
    const count = this.targetLanguages?.length || 0;
    if (count <= 5) {
      return {  'transform': 'translateX(0%)', 'width':'100%'};
    }else {
      return {'transform': 'translateX(-51%)', 'width': 'calc(200% + 16px)'};
    }
  }

  scrollToSelected(dropdownRef: ElementRef | null, selected: any, optionsList: any[], optionRefs: QueryList<ElementRef>) {
    if (!dropdownRef || !selected) return;

    const dropdownElement = dropdownRef.nativeElement;
    const selectedElement = optionRefs.find((item, index) => {
      return optionsList[index].code === selected.code;
    });

    if (selectedElement) {
      const element = selectedElement.nativeElement;
      const dropdownHeight = dropdownElement.offsetHeight;
      const elementHeight = element.offsetHeight;
      const elementTop = element.offsetTop;
      const scrollPosition = elementTop - (dropdownHeight / 2) + (elementHeight / 2);

      // Smooth scroll
      dropdownElement.scrollTo({
        top: Math.max(0, scrollPosition),
        behavior: 'smooth'
      });
    }
  }

  scrollToSelectedSourceLanguage() {
    if (this.sourceScrollDebounceTimeout) {
      clearTimeout(this.sourceScrollDebounceTimeout);
    }
    // Debounce the scroll operation
    this.sourceScrollDebounceTimeout = setTimeout(() => {
      this.scrollToSelected(
        this.sourceDropdownRef,
        this.selectedSourceLanguage,
        this.sourceLanguages,
        this.sourceLanguageOptionRefs
      );
    }, 50);
  }

  scrollToSelectedTargetLanguage() {
    if (this.targetScrollDebounceTimeout) {
      clearTimeout(this.targetScrollDebounceTimeout);
    }
    // Debounce the scroll operation
    this.targetScrollDebounceTimeout = setTimeout(() => {
      this.scrollToSelected(
        this.targetDropdownRef,
        this.selectedTargetLanguage,
        this.targetLanguages,
        this.targetLanguageOptionRefs
      );
    }, 50);
  }

  filterSourceLanguages(): void {
    const searchText = this.sourceSearchText.toLowerCase().trim();

    if (!searchText) {
      this.filteredSourceLanguages = [...this.sourceLanguages];
    } else {
      this.filteredSourceLanguages = this.sourceLanguages.filter(language =>
        language.displayName.toLowerCase().includes(searchText)
      );
    }
  }

  filterTargetLanguages(): void {
    const searchText = this.targetSearchText.toLowerCase().trim();

    if (!searchText) {
      this.filteredTargetLanguages = [...this.targetLanguages];
    } else {
      this.filteredTargetLanguages = this.targetLanguages.filter(language =>
        language.displayName.toLowerCase().includes(searchText)
      );
    }
  }

  onSourceSearchChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.sourceSearchText = input.value;
    this.filterSourceLanguages();
  }

  onTargetSearchChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.targetSearchText = input.value;
    this.filterTargetLanguages();
  }

  clearSourceSearch(): void {
    this.sourceSearchText = '';
    this.filterSourceLanguages();
    if (this.sourceSearchInputRef) {
      this.sourceSearchInputRef.nativeElement.focus();
    }
  }

  clearTargetSearch(): void {
    this.targetSearchText = '';
    this.filterTargetLanguages();
    if (this.targetSearchInputRef) {
      this.targetSearchInputRef.nativeElement.focus();
    }
  }

  toggleSourceDropdown(): void {
    this.isSourceDropdownOpen = !this.isSourceDropdownOpen;

    if (this.isSourceDropdownOpen) {
      this.isTargetDropdownOpen = false;

      this.sourceSearchText = '';
      this.filterSourceLanguages();

      setTimeout(() => {
        if (this.sourceSearchInputRef) {
          this.sourceSearchInputRef.nativeElement.focus();
        }

        this.scrollToSelectedSourceLanguage();
      }, 50);
    }
  }

  toggleTargetDropdown(): void {
    if (this.selectedSourceLanguage) {
      this.isTargetDropdownOpen = !this.isTargetDropdownOpen;

      if (this.isTargetDropdownOpen) {
        this.isSourceDropdownOpen = false;

        this.targetSearchText = '';
        this.filterTargetLanguages();

        setTimeout(() => {
          if (this.targetSearchInputRef) {
            this.targetSearchInputRef.nativeElement.focus();
          }

          this.scrollToSelectedTargetLanguage();
        }, 50);
      }
    }
  }

  onKeyDown(event: KeyboardEvent, dropdownType: 'source' | 'target'): void {
    const isSource = dropdownType === 'source';
    const filteredList = isSource ? this.filteredSourceLanguages : this.filteredTargetLanguages;
    const languageOptionRefs = isSource ? this.sourceLanguageOptionRefs : this.targetLanguageOptionRefs;

    // Close dropdown on escape
    if (event.key === 'Escape') {
      if (isSource) {
        this.isSourceDropdownOpen = false;
      } else {
        this.isTargetDropdownOpen = false;
      }
      return;
    }

    // If search input is focused, handle differently
    if (document.activeElement?.tagName === 'INPUT') {
      // On Enter in search field, select first result if available
      if (event.key === 'Enter' && filteredList.length > 0) {
        if (isSource) {
          this.selectSourceLanguage(filteredList[0]);
        } else {
          this.selectTargetLanguage(filteredList[0]);
        }
        event.preventDefault();
      }

      // On ArrowDown, focus first option
      if (event.key === 'ArrowDown' && filteredList.length > 0 && languageOptionRefs.first) {
        languageOptionRefs.first.nativeElement.focus();
        event.preventDefault();
      }

      return;
    }

    // If an option is focused
    if (document.activeElement?.classList.contains('language-option')) {
      const focusedElement = document.activeElement;

      if (event.key === 'Enter') {
        const currentIndex = Array.from(languageOptionRefs).findIndex(
          ref => ref.nativeElement === focusedElement
        );

        if (currentIndex >= 0) {
          if (isSource) {
            this.selectSourceLanguage(filteredList[currentIndex]);
          } else {
            this.selectTargetLanguage(filteredList[currentIndex]);
          }
        }
        event.preventDefault();
        return;
      }

      // Arrow key navigation
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        const options = Array.from(languageOptionRefs.toArray());
        const currentIndex = options.findIndex(ref => ref.nativeElement === focusedElement);

        let nextIndex;
        if (event.key === 'ArrowDown') {
          nextIndex = (currentIndex + 1) % options.length;
        } else {
          nextIndex = (currentIndex - 1 + options.length) % options.length;
        }

        if (options[nextIndex]) {
          options[nextIndex].nativeElement.focus();
        }

        event.preventDefault();
        return;
      }
    }

    // If nothing is focused yet, focus the search input
    const searchInput = isSource ? this.sourceSearchInputRef : this.targetSearchInputRef;
    if (searchInput) {
      searchInput.nativeElement.focus();
    }
  }

  highlightMatch(text: string, searchText: string): string {
    if (!searchText || !text) return text;

    const searchLower = searchText.toLowerCase().trim();
    if (!searchLower) return text;

    const index = text.toLowerCase().indexOf(searchLower);
    if (index === -1) return text;

    const before = text.substring(0, index);
    const match = text.substring(index, index + searchLower.length);
    const after = text.substring(index + searchLower.length);

    return `${before}<span class="highlight-match">${match}</span>${after}`;
  }

  selectSourceLanguage(language: Language): void {
    this.selectedSourceLanguage = language;
    this.selectedTargetLanguage = null;
    this.isSourceDropdownOpen = false;

    this.loadTargetLanguages(language.code);

    this.targetSearchText = '';
    this.onTargetLanguageSelected.emit();
    this.onSourceLanguageSelected.emit(language);
  }
}
