<svg
  *ngIf="shape === 'circle'"
  id="circular-alert-icon"
  class="alert-icon"
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 14 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7 0C3.13 0 0 3.13 0 7C0 10.87 3.13 14 7 14C10.87 14 14 10.87 14 7C14 3.13 10.87 0 7 0ZM8 12H6V10H8V12ZM8 8H6V2H8V8Z"
    [attr.fill]="color"
  />
</svg>
<svg
  *ngIf="shape === 'triangle'"
  id="triangular-alert-icon"
  class="alert-icon"
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 16 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.59 2.5H7.42L1 13.5L1.59999 14.5H14.4L15 13.5L8.59 2.5ZM9.00999 13H7.00999V11H9.00999V13ZM9.00999 10H7.00999V5.5H9.00999V10Z"
    [attr.fill]="color"
  />
</svg>
