<div class="title-container">
    <h2>Prompt library</h2>
    <!-- commenting this line for now -->
    <!-- <span class="toggle-small-text description-text">Description of prompt library</span> -->
</div>

<div class="toggle-container">
    <div class="label-container">
        <label class="switch">
            <span class="toggle-one">Prompt suggestions on new chat</span>
        </label>
        <kmd-toggle (ngModelChange)="hasUnsavedChanges()" [(ngModel)]="enableTile" name="enableTile"></kmd-toggle>
    </div>
    <label class="switch dropdown-prompt-switch">
        <span class="dropdown-prompt-suggestions">Show prompt suggestion  <span class="mandatory-field-asterisk">*</span></span>
    </label>
    <div class="dropdown-prompt-suggestions">
        <kmd-dropdown [options]="promptSuggestionsNumberArray" optionLabelKey="title" (ngModelChange)="hasUnsavedChanges()" [(ngModel)]="promptSuggestionsNumber"></kmd-dropdown>
    </div>
    <div class="label-container">
        <label class="switch">
            <span class="toggle-small-text">Randomize suggestions every new chat</span>
        </label>
        <kmd-toggle (ngModelChange)="hasUnsavedChanges()" [(ngModel)]="randomizeSuggestions" name="randomizeSuggestions"></kmd-toggle>  
    </div>
    <div class="label-container">
        <label class="switch">
            <span class="toggle-small-text">Show suggestions as saved prompts</span>
        </label>
        <kmd-toggle (ngModelChange)="hasUnsavedChanges()" [(ngModel)]="suggestionsAsSavedPrompts" name="suggestionsAsSavedPrompts"></kmd-toggle>
    </div>
</div>

<div>
    <h2>Prompt list</h2>
    <!-- commenting this line for now -->
    <!-- <span class="description-text">Add specific instructions to include...</span> -->
</div>

<div class="search-container">
    <div class="search-input-wrapper">
        <input type="text" placeholder="Search prompt" class="form-control search-input" [(ngModel)]="searchText"
            (ngModelChange)="filterPrompts(searchText)" name="searchText">
        <button class="add-prompt-btn" (click)="addNewPrompt()">
            <svg class="plus-bold" id="plus-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M22 10.5H13.5V2H10.5V10.5H2V13.5H10.5V22H13.5V13.5H22V10.5Z" fill="#1B1B1D" />
            </svg>
            <span class="add-prompt-button-text">
                Add Prompt
            </span>
        </button>
    </div>
</div>

<div class="loader-container" *ngIf="isLoadingPromptSuggestions">
    <kmd-loading-wheel></kmd-loading-wheel>
</div>

<div cdkDropList *ngIf="paginatedTilePromptArray.length > 0" (cdkDropListDropped)="drop($event)">
    <div class="dynamic-content">
        <div class="card" *ngFor="let item of paginatedTilePromptArray; let i = index" cdkDrag>
            <div class="card-header" (click)="item.expanded = !item.expanded">
                <div class="title-cotainer">
                    <h5>
                        <svg id="drag-n-drop-bold.svg" width="16" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 19C9.5 17.896 8.604 17 7.5 17C6.396 17 5.5 17.896 5.5 19C5.5 20.104 6.396 21 7.5 21C8.604 21 9.5 20.104 9.5 19Z" fill="#1B1B1D"/>
                            <path d="M18.5 19C18.5 17.896 17.604 17 16.5 17C15.396 17 14.5 17.896 14.5 19C14.5 20.104 15.396 21 16.5 21C17.604 21 18.5 20.104 18.5 19Z" fill="#1B1B1D"/>
                            <path d="M16.5 10C17.604 10 18.5 10.896 18.5 12C18.5 13.104 17.604 14 16.5 14C15.396 14 14.5 13.104 14.5 12C14.5 10.896 15.396 10 16.5 10Z" fill="#1B1B1D"/>
                            <path d="M9.5 12C9.5 10.896 8.604 10 7.5 10C6.396 10 5.5 10.896 5.5 12C5.5 13.104 6.396 14 7.5 14C8.604 14 9.5 13.104 9.5 12Z" fill="#1B1B1D"/>
                            <path d="M16.5 3C17.604 3 18.5 3.896 18.5 5C18.5 6.104 17.604 7 16.5 7C15.396 7 14.5 6.104 14.5 5C14.5 3.896 15.396 3 16.5 3Z" fill="#1B1B1D"/>
                            <path d="M9.5 5C9.5 3.896 8.604 3 7.5 3C6.396 3 5.5 3.896 5.5 5C5.5 6.104 6.396 7 7.5 7C8.604 7 9.5 6.104 9.5 5Z" fill="#1B1B1D"/>
                        </svg>
                        <svg id="chevron-right-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg" [ngClass]="{'rotate-90': item.expanded}">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5.93994 4.12001L8.05994 2L18.0599 12L8.05994 22L5.93994 19.88L13.8199 12L5.93994 4.12001Z"
                                fill="#1B1B1D" />
                        </svg>
                        <!-- Prompt {{item.promptNumber}} -->
                        <span>
                            {{item.cardTitle}}
                        </span>
                    </h5>
                </div>
                <div class="button-group">
                    <div class="dropdowns-container">
                        <kmd-dropdown [disable]="searchText !== ''" [options]="pageNumbers" optionLabelKey="value" inlineLabel="Page {{currentPage}}" (onChange)="onPromptSuggestionPageChange($event, i)" (click)="onDropdownClick($event)"></kmd-dropdown>
                    </div>
                    <svg class="bin-mono" (click)="removeItem(item.promptNumber, $event)" id="bin-mono.svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.75 9H11.25V18H12.75V9Z" fill="#1B1B1D"/>
                        <path d="M15.75 9H14.25V18H15.75V9Z" fill="#1B1B1D"/>
                        <path d="M9.75 9H8.25V18H9.75V9Z" fill="#1B1B1D"/>
                        <path d="M21 5H15.5V3C15.5 2.45 15.05 2 14.5 2H9.5C8.95 2 8.5 2.45 8.5 3V5H3V7H4.18005L5.5 22H18.5L19.8201 7H21V5ZM10 3.5H14V5H10V3.5ZM16.67 20H7.32996L6.18005 7H17.8101L16.66 20H16.67Z" fill="#1B1B1D"/>
                    </svg>
                </div>
            </div>
            <div class="card-body" [ngClass]="{'expanded': item.expanded}">
                <div class="form-group">
                    <div class="title-header-container">
                        <label for="title-{{item.id}}">Title: <span class="mandatory-field-asterisk">*</span></label>
                        <small class="text-muted ml-2" style="float: right;">
                            {{item.shortPrompt ? item.shortPrompt.length : 0}}/50
                        </small>
                    </div>
                    <input placeholder="What does this prompt do?" type="text" class="form-control" id="title-{{item.id}}" [(ngModel)]="item.shortPrompt"
                        name="shortPrompt-{{item.id}}" maxlength="50" (keyup)="hasUnsavedChanges()">
                    <div *ngIf="item.shortPrompt && item.shortPrompt.length > 50" class="note-message">
                        Character limit reached. Exceeding this limit may cause the prompt to overflow outside the tile on mobile screens
                    </div>
                </div>


                <div class="form-group">
                    <div class="title-header-container">
                        <label for="prompt-{{item.id}}">Prompt: <span class="mandatory-field-asterisk">*</span></label>
                        <small class="text-muted ml-2" style="float: right;">
                            {{item.longPrompt ? item.longPrompt.length : 0}}/5000
                        </small>
                    </div>
                    <input placeholder="Prompt instructions" type="text" class="form-control" id="prompt-{{item.id}}" [(ngModel)]="item.longPrompt"
                        name="longPrompt-{{item.id}}" maxlength="5000" (keyup)="hasUnsavedChanges()">
                    <div *ngIf="item.longPrompt && item.longPrompt.length > 5000" class="note-message">
                        Character limit reached. Exceeding this limit may cause the prompt to overflow outside the tile on mobile screens
                    </div>
                </div>

            </div>
        </div>
    </div>
    <kmd-pagination style="margin: 10px" #pagination (onPageChange)="onPageChange($event)"
        (onResultsPerPageChange)="onResultsPerPageChange($event)" [resultsPerPageOptions]="[10, 15, 20, 25]"
        [resultsPerPage]="resultsPerPage" [totalResults]="  displayedTitlePromptArray.length">
    </kmd-pagination>
</div>
<div *ngIf="paginatedTilePromptArray.length === 0 && isLoadingPromptSuggestions === false">
    <div class="empty-message-container">
        <p>No prompts</p>
        <p>Saving prompts can save you time and ensure accurate responses.</p>
    </div>
</div>
<div class="fixed-footer">
    <div class="cancel">
        <button kmdLinkButton (click)="checkForUnsavedChangesAndCancel()">Cancel</button>
      </div>
    <div class="submit">
        <button kmdFeaturedButton [disabled]="!saveButtonEnabled" type="submit"
            (click)="openModal('confirmation-modal-save-changes-prompt-library')">Save</button>
    </div>
</div>

<!-- When user clicks on cancel button -->
<kmd-modal [id]="'confirmation-modal-unsaved-changes-prompt-library'" class="confirmation-modal" modalSize="mini"
  headline="Unsaved changes" (clickedOutside)=" closeModal('confirmation-modal-unsaved-changes-prompt-library')">
  <div>
    <span class="text">
      Are you sure you want to cancel changes to the configuration form?
    </span>
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-button-model" (click)="cancelAndDontSaveChanges()" kmdPrimaryButton>Don't save</button>
    <button [disabled]="!saveButtonEnabled" id="confirm-button-model" (click)="savePromptLibraryAndNavigate()" kmdFeaturedButton>Save changes</button>
  </div>
</kmd-modal>

<!-- When clicked on Save -->
<kmd-modal [id]="'confirmation-modal-save-changes-prompt-library'" class="confirmation-modal" modalSize="mini" headline="Save changes"
  (clickedOutside)=" closeModal('confirmation-modal-save-changes-prompt-library')">
  <div>
    <span class="text">
      Are you sure you want to save changes to the configuration form?
    </span>
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-button-model" (click)="closeModal('confirmation-modal-save-changes-prompt-library')"
      kmdPrimaryButton>Cancel</button>
    <button id="confirm-button-model" kmdFeaturedButton (click)="savePromptLibrary()">Confirm</button>
  </div>
</kmd-modal>