<div class="external-loading-overlay">
  <div class="loading-spinner">
    <kmd-loading-wheel></kmd-loading-wheel>
  </div>
</div>

<div id="agent-list-paged-wrapper" *ngIf="(agents$ | async) as page">

  <div class="loading-overlay" [class.show]="isLoading">
    <div class="loading-spinner">
      <kmd-loading-wheel></kmd-loading-wheel>
    </div>
  </div>

    <div [formGroup]="formGroup" class="filter-options" *ngIf="page && type !== 'shared'; else sharedAgentsFilter">
      <h3>Search Agents</h3>
      <div class="toolbar">
        <input formControlName="search" type="text" kmdFormInput id="agent-search-input" placeholder="Enter Agent name"
          autocomplete="off" class="agent-search-input-{{type}}" />
        <div class="filters">
          <kmd-dropdown formControlName="category" id="category-dropdown" [options]="categories"></kmd-dropdown>
          <kmd-dropdown formControlName="sorting" id="sort-dropdown" [options]="sortOptions"></kmd-dropdown>
        </div>
      </div>

      <span id="agent-count" class="agents-count"
        *ngIf="page.content.length > 0 || formGroup.controls.category.value !== 'All agents'">{{
        page.totalElements }} agents</span>
    </div>

    <ng-template #sharedAgentsFilter>

      <app-agent-filters id="sharedAgentFilters" [agentType]="'shared'"
        (searchByAgentNameChange)="onFilterChange('agentName',$event)"
        (categoryChange)="onFilterChange('agentCategory',$event)"
        (sortByChange)="onFilterChange('agentSort',$event)"></app-agent-filters>

      <span id="agent-count" class="agents-count"
        *ngIf="page.content.length > 0 || formGroup.controls.category.value !== 'All agents'">{{
        page.totalElements }} agents</span>
    </ng-template>
    <div *ngIf="page">
      <div id="paginated-agents-wrapper" class="agents" *ngIf="page.content.length > 0; else noAgentsView">
        <app-agent-card #agentCard *ngFor="let agent of page.content; let last = last " [agent]="agent" [isSmallList]="isSmallAgentList"
          [type]="type" [scrollDiv]="scrollDiv" [displayRating]="type == 'public'" [loggedInUser]="(userData$ | async)!"
          [isUserApprover]="isUserApprover" (onViewAgentEvent)="viewAgent($event)" [isLastInList]="last"
          (deleteAgentEvent)="confirmDeleteAgent($event)" (onShareAgentEvent)="showPagePublicAgentOverviewModal(agent)">

          <core-badge share-status-badge id="share-status-badge" *ngIf="agent.isPublic && type==='private'"
            variant="standard" size="regular" color="blue" label="Public" />

        </app-agent-card>
      </div>

      <kmd-pagination #pagination [totalResults]="page.totalElements" (onPageChange)="onPageChange($event)">
      </kmd-pagination>
    </div>

    <ng-template #noAgentsView>
      <ng-container *ngIf="page && page.content.length === 0">
        <div class="no-agents-view">
          <h3 class="no-agent-header">{{ type === 'shared' ? 'Group shared agents' : 'What Can Agents Do For You' }}</h3>
          <div class="agents-capabilities">
            <div class="agent-capability">
              <img src="../../../assets/images/agents-robot.svg">
              <span>Agents allow you to create a specific role, called a persona, by adding instructions and knowledge that
                is specific for your needs.</span>
            </div>
            <div class="agent-capability">
              <img src="../../../assets/images/agents-rocket.svg">
              <span>Agents can assist you with tasks or answer queries on a wide range of topics through the chat
                interface.</span>
            </div>
            <div class="agent-capability">
              <img src="../../../assets/images/agents-message.svg">
              <span>Agents understand your questions through their preset instructions and deliver personalized
                responses.</span>
            </div>
          </div>
          <button kmdFeaturedButton id="new-agent-bottom" class="agent-btn" (click)="goToCreateNewAgent()">
            <img class="icon-agents icon-sm-new-agent" src="../../../assets/icons/plus/plus-16px-mono.svg">
            Create new Agent
          </button>
        </div>
      </ng-container>
    </ng-template>
</div>

<kmd-modal [id]="'page-view-agent-modal'" modalSize="large" headline="Agent Overview"
  (crossIconClicked)="closeModal('page-view-agent-modal')" (clickedOutside)="closeModal('page-view-agent-modal')">
  <app-agent-overview [agent]="selectedAgent!" [modalName]="'page-view-agent-modal'"></app-agent-overview>
</kmd-modal>

<kmd-modal [id]="'page-delete-agent-modal'" modalSize="mini" headline="Delete agent"
  (clickedOutside)="closeModal('page-delete-agent-modal')">
  <div id="text">
    Are you sure you want to delete this agent? This action can't be undone, and previous chats with this agent will
    also be deleted.
  </div>
  <div class="modal-buttons-container">
    <button id="cancel-delete-agent" (click)="closeModal('page-delete-agent-modal')" kmdSecondaryButton>Cancel</button>
    <button id="delete-agent" (click)="deleteAgent()" kmdFeaturedButton>Delete</button>
  </div>
</kmd-modal>

<kmd-modal [id]="'page-delete-public-agent-modal'" modalSize="mini" headline="Delete Public Agent"
  (clickedOutside)="closeModal('page-delete-public-agent-modal')">
  <div id="public-text">
    Are you sure you want to delete this agent? This action can't be undone, and previous chats will be deleted.
  </div>
  <div class="modal-buttons-container">
    <button (click)="closeModal('page-delete-public-agent-modal')" kmdSecondaryButton>Cancel</button>
    <button id="delete-public-agent" (click)="deletePublicAgent()" kmdFeaturedButton>Delete</button>
  </div>
</kmd-modal>

<kmd-modal [id]="'page-public-agent-overview'" modalSize="large" headline="Agent Overview" subheading="test"
  (crossIconClicked)="closeModal('page-public-agent-overview')"
  (clickedOutside)="closeModal('page-public-agent-overview')">
  <app-agent-public-overview [agent]="selectedAgent!" [modalName]="'page-public-agent-overview'"
    [username]="type === 'private' ? (userData$ | async)?.name : ''"
    (onMakeAgentPublicEvent)="publishAgent(selectedAgent!)" (onCancelEvent)="closeModal($event)" />
</kmd-modal>

@if (showAgentSharingModal) {
  <app-agent-sharing-confirmation-modal
    #agentSharingOverviewModal
    [readOnly]="true"
    (initialized)="onAgentSharingModalInitialized()"
    (close)="onAgentSharingOverviewModalClosed()" />
}
