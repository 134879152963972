import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileTranslationsUpload } from "@shared/models/file-translations-upload.model";

@Injectable({
  providedIn: 'root'
})
export class TranslationsFileService {

  constructor(@Inject('TRANSLATIONS_API_URL') private translationsUrl: string, private http: HttpClient) {
  }

  create(file: FileTranslationsUpload): Observable<FileTranslationsUpload> {
    return this.http.post<FileTranslationsUpload>(this.translationsUrl + '/v1/files', file, this.getHeaders());
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(this.translationsUrl + `/v1/files/${id}`, this.getHeaders());
  }

  list(fileStatus: string = ''): Observable<Array<FileTranslationsUpload>> {
    const fileStatusQueryParam = fileStatus ? `?fileStatus=${fileStatus}` : '';
    return this.http.get<Array<FileTranslationsUpload>>(this.translationsUrl + `/v1/files${fileStatusQueryParam}`,
      this.getHeaders())
  }

  get(id: string): Observable<FileTranslationsUpload> {
    return this.http.get<FileTranslationsUpload>(this.translationsUrl + `/v1/files/${id}`, this.getHeaders());
  }

  updateStatus(id: string, status: string): Observable<FileTranslationsUpload> {
    return this.http.put<FileTranslationsUpload>(this.translationsUrl + `/v1/files/${id}/status`, { status }, this.getHeaders());
  }

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }
}
