import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { UnsavedChangesGuard } from "@app/core/guards/unsaved-changes-guard.service";
import { Chip } from "@app/shared/components/chip-box/chip-box.component";
import {
  AgentPublishError,
  AgentSaveError,
  AgentShareError,
  AgentUpdateError
} from "@app/core/services/agents/agent-errors";
import { Group } from "@app/shared/models/azure/groups/group.model";
import { AgentsService } from "@services/agents/agents.service";
import { AlertService } from "@services/alert/alert.service";
import { GroupMembershipService } from "@services/group-membership/group-membership.service";
import { ModalService } from "@services/modal/modal.service";
import { ChipTypeConstant } from "@shared/constants/chip/chip-type";
import { Agent, AgentAdmin } from "@shared/models/agent";
import { FileUpload } from "@shared/models/file-upload.model";
import { Member } from "@shared/models/group-membership/members/member.model";
import { catchError, debounceTime, filter, finalize, first, forkJoin, map, Observable, of, takeUntil, tap, throwError } from "rxjs";
import {
  AgentSharingConfirmationModalComponent
} from "../agent-sharing-confirmation-modal/agent-sharing-confirmation-modal.component";
import {
  memberInGroupValidator,
  resetAzureEmailCache,
  USER_NOT_IN_AZURE_VALIDATOR_KEY,
  USER_NOT_IN_GROUP_VALIDATOR_KEY,
  USER_UNEXPECTED_ERROR_VALIDATOR_KEY
} from "./member-in-group.validator";
import {
  AgentSharingGroupMemberListModalComponent
} from "../agent-sharing-group-member-list-modal/agent-sharing-group-member-list-modal.component";
import { DUPLICATE_MEMBER_VALIDATOR_KEY, uniqueMembersValidator } from "./unique-members.validator";
import {
  AgentSharingKeepPrivateConfirmationModalComponent
} from "../agent-sharing-keep-private-confirmation-modal/agent-sharing-keep-private-confirmation-modal.component";
import { SharingAgentService } from "@services/sharing-agent/sharing-agent.service";
import { invalidEmailFromListValidator } from "./invalid-email-from-list.validator";
import { ConfirmDialogComponent } from "@app/shared/components/confirm-dialog/confirm-dialog.component";
import { featureFlags } from "@app/environments/environment";
import { ShareOptionsService } from "@services/share-options/share-options.service";
import { AgentShareOption, AgentShareOptionId } from "@app/shared/models/agent-share-option.model";

@Component({
  selector: 'app-agent-sharing-modal',
  templateUrl: './agent-sharing-modal.component.html',
  styleUrls: ['./agent-sharing-modal.component.css']
})
export class AgentSharingModalComponent implements OnInit, OnDestroy {
  @Input() sharingDetails?: { agent: Agent, selectedFiles: FileUpload[] };

  @Output() init = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  @Output() onSharedAgent: EventEmitter<void> = new EventEmitter<void>();
  @Output() onUnsharedAgent: EventEmitter<void> = new EventEmitter<void>();
  @Output() onPubliclySharedAgent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('confirmDialog') confirmDialog!: ConfirmDialogComponent;
  @ViewChild('confirmationModal') confirmationModal?: AgentSharingConfirmationModalComponent;
  @ViewChild('modalContent') modalContent?: ElementRef;
  @ViewChild('memberListModal') memberListModal?: AgentSharingGroupMemberListModalComponent;
  @ViewChild('keepPrivateConfirmationModal') keepPrivateConfirmationModal?: AgentSharingKeepPrivateConfirmationModalComponent;

  readonly maxAllowedAdmins: number = 5;
  readonly MIN_ADMIN_COUNT: number = 2;
  readonly id = 'AgentSharingModalComponent';
  readonly headline = 'Share your agent';
  readonly subHeadline = 'Select how do you want to share your agent.';
  readonly SharedAgentType = 'SHARED';
  readonly PrivateAgentType = 'PRIVATE';
  readonly CHIPS_CONFIG = {
    max: this.maxAllowedAdmins,
    showCount: true
  };


  readonly KeepPersonalOptionId: AgentShareOptionId = 'keep-personal';
  readonly MicrosoftGroupOptionId: AgentShareOptionId = 'microsoft-group';
  readonly MakePublicOptionId: AgentShareOptionId = 'make-public';


  emailChips: Chip[] = [];
  defaultEmailChips: Chip[] = [];
  shareToOptions: AgentShareOption[] = [];
  selectedShareToOption!: AgentShareOption;
  isShareToMenuOpen: boolean = false;
  loadingGroups: boolean = false;
  loadingMembers: boolean = false;
  displayMicrosoftGroupForm: boolean = false;
  displayGroupList: boolean = false;
  isPublicAgentShareFlow: boolean = false;
  isRequestProcessing: boolean = false;
  userInformation: Member | null = null;
  groups: Group[] = [];
  formGroup!: FormGroup;
  filteredGroups: Group[] = [];
  groupMembers: Member[] = [];
  groupMemberCountLabel: string = '';
  validatingAdmins: boolean = false;
  isAnyAdminNotInGroup: boolean = false;
  isAnyAdminNotInAzure: boolean = false;
  isAnyAdminWithUnexpectedError: boolean = false;
  hasInvalidAzureEmail: boolean = false;

  isAgentOwnerMissing: boolean = false;
  isOwnerOutOfGroup: boolean = false;
  mailWithErrorOnAzure: string = '';

  cancelFetchGroupMemberRequest$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: ModalService,
    private groupMembershipService: GroupMembershipService,
    private alertService: AlertService,
    private agentsService: AgentsService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private unsavedChangesGuard: UnsavedChangesGuard,
    private sharingAgentService: SharingAgentService,
    private ShareOptionsService: ShareOptionsService
  ) { };

  ngOnInit(): void {
    this.setForm();
    this.setAdminStatusListener();
    this.initializeData();
    this.ShareOptionsService.resetOptions();

    this.ShareOptionsService.shareOptions$.subscribe(options => {
      this.shareToOptions = options;
    });
    this.selectedShareToOption = this.ShareOptionsService.getDefaultShareOption();
    if (this.sharingDetails?.agent?.id) {
      this.disableOptionsBasedOnAgentType();
    }
  };

  ngOnDestroy(): void {
    this.cancelFetchGroupMemberRequest$.emit();
    resetAzureEmailCache();
  };

  initializeData() {
    forkJoin([
      this.setUserInformation(),
    ])
    .pipe(finalize(() => this.init.emit()))
    .subscribe({
      error: () => {}
    });
  }

  setForm() {
    this.formGroup = new FormGroup({
      defaultAdmins: new FormArray<FormControl<Member>>([]),
      newAdmins: new FormArray<FormControl<Member>>([], [uniqueMembersValidator()]),
      group: new FormControl<Group | null>(null),
      typedGroupName: new FormControl(''),
      typedEmail: new FormControl({ value: '', disabled: true }),
    });
  }

  get isAgentUpdate(): boolean {
    return !!this.sharingDetails?.agent?.id;
  };

  get defaultAdmins(): FormArray {
    return this.formGroup.controls['defaultAdmins'] as FormArray;
  }

  get newAdmins(): FormArray {
    return this.formGroup.controls['newAdmins'] as FormArray;
  }

  get allAdmins(): AbstractControl<Member>[] {
    return [...this.defaultAdmins.controls, ...this.newAdmins.controls];
  }

  get adminsEmails(): string[] {
    return this.allAdmins.map(admin => admin.value.mail);
  }

  get adminsNames(): string[] {
    return this.allAdmins.map(admin => admin.value.displayName);
  }

  get isGroupSelected(): boolean {
    return this.selectedGroup !== null;
  };

  get selectedGroup(): Group | null {
    return this.formGroup.controls['group'].value || null;
  };

  get isFormSubmitDisabled(): boolean {
    if (this.selectedShareToOption.id !== this.MicrosoftGroupOptionId) {
      return this.isRequestProcessing;
    }

    if (this.selectedGroup === null
      || this.groupMembers.length === 0
      || this.allAdmins.length < this.MIN_ADMIN_COUNT
      || this.validatingAdmins
      || this.hasInvalidAdmins) {
      return true;
    }

    return false;
  };

  get hasInvalidAdmins(): boolean {
    return (this.newAdmins.errors !== null) || this.isAnyAdminNotInGroup || this.isAnyAdminNotInAzure || this.hasInvalidAzureEmail;
  };

  get agentOwner(): AgentAdmin | null {
    return this.sharingDetails?.agent.admins?.find(admin => admin.role === 'owner') || null;
  };

  get isUserAgentOwner(): boolean {
    const owner = this.agentOwner;

    if (!owner) {
      return false;
    }

    return owner.email === this.userInformation?.mail;
  }

  get isDropdownDisabled(): boolean {
    return this.loadingGroups;
  }

  get hasNoMatchingGroups(): boolean {
    return this.filteredGroups.length === 0;
  };

  get isChipBoxDisabled(): boolean {
    return this.selectedGroup === null
      || this.groupMembers.length === 0;
  }

  set defaultAdmin(member: Member) {
    this.defaultAdmins.clear();
    this.defaultAdmins.push(new FormControl<Member>(member));
    this.defaultEmailChips = [{
      value: member.mail,
      type: ChipTypeConstant.DEFAULT,
      closeable: false
    }];
  }

  //#region Modal Events

  openModal(): void {
    this.modalService.open(this.id);
  };

  showModal() {
    this.modalService.open(this.id);
  }

  hideModal() {
    this.modalService.hide(this.id);
  }

  closeModal() {
    this.modalService.close(this.id);
    this.close.emit();
  }

  closeAllModals(): void {
    this.keepPrivateConfirmationModal?.close();
    this.confirmationModal?.closeModal({ emit: false });
    this.closeModal();
  };

  onSharingModalClose() {
    this.hideModal();
  }

  showConfirmationModal() {
    this.hideModal();
    this.confirmationModal?.openModal();
  };

  onConfirmationModalBack() {
    this.confirmationModal?.hideModal();
    this.showModal();
  }

  onConfirmationModalClose() {
    if (featureFlags.agentSharingCancelModal) {
      this.confirmDialog.open();
    } else {
      this.showModal();
    }
  }

  dismissAgentSharingCancellation() {
    this.confirmDialog.close();
    this.confirmationModal?.openModal();
  }

  onBackToAgentForm() {
    this.confirmDialog.close();
    this.hideModal();
  }

  onBackToSharingSettings() {
    this.confirmDialog.close();
    this.showModal();
  }

  //#endregion Modal Events

  private disableOptionsBasedOnAgentType() {
    const agentType = this.sharingDetails?.agent?.agentType?.toLowerCase();
    switch (agentType) {
      case this.SharedAgentType.toLowerCase():
        this.ShareOptionsService.disableOption(this.MicrosoftGroupOptionId);
        break;
    }
  }

  onShareToDropdownClick(): void {
    if (!this.isDropdownDisabled) {
      this.isShareToMenuOpen = true;
    }
  }

  onShareToDropdownOutsideClick(): void {
    this.isShareToMenuOpen = false;
  };

  onSelectionChanged(selectedOption: { id: AgentShareOptionId }): void {
    this.cancelFetchGroupMemberRequest$.emit();
    this.selectedShareToOption = this.ShareOptionsService.getOption(selectedOption.id as AgentShareOptionId)!;

    this.isPublicAgentShareFlow = this.selectedShareToOption.id === this.MakePublicOptionId;

    if (this.selectedShareToOption.id !== this.MicrosoftGroupOptionId) {
      this.clearSelectedGroup();
      this.displayMicrosoftGroupForm = false;
      return;
    }

    if (this.groups.length) {
      this.displayMicrosoftGroupForm = true;
      return;
    }

    this.loadingGroups = true;
    this.groupMembershipService.fetchUserTeams().subscribe({
      next: (response) => {
        this.loadingGroups = false;
        this.groups = response.value;
        this.filteredGroups = [...this.groups];
        this.displayMicrosoftGroupForm = true;
      },
      error: () => {
        this.loadingGroups = false;
        this.alertService.showError('Failed to fetch groups. Please try again later.');
      }
    });

    this.formGroup.controls['typedGroupName'].valueChanges.subscribe({
      next: (value) => {
        if (value) {
          this.filteredGroups = this.groups.filter(group =>
            group.displayName.toLowerCase().includes(value.toLowerCase())
          );
        } else {
          this.filteredGroups = [...this.groups];
        }
      }
    });
  };

  onGroupNameInputFocus(): void {
    this.displayGroupList = true;
  };

  onGroupNameInputOutsideClick(): void {
    this.displayGroupList = false;
  };

  onGroupItemClick(group: Group): void {
    this.formGroup.controls['group'].patchValue(group);
    this.formGroup.controls['typedEmail'].enable();
    this.displayGroupList = false;
    this.fetchGroupMembers(group.id);
  }

  setUserInformation(): Observable<Member> {
    return this.groupMembershipService.getUserInformation()
      .pipe(
        tap(userInformation => {
          this.userInformation = userInformation;
          this.defaultAdmin = userInformation;
        }),
        catchError(error => {
          this.alertService.showError('Failed to fetch user information. Please try again later.');
          throw error;
        })
      );
  }

  fetchGroupMembers(groupId: string): void {
    this.loadingMembers = true;
    this.groupMembershipService.getGroupMembers(groupId)
      .pipe(
        first(),
        takeUntil(this.cancelFetchGroupMemberRequest$),
        tap((response) => {
          this.groupMembers = response.value;
          this.groupMemberCountLabel = (this.groupMembers.length > 1)
            ? `${this.groupMembers.length} members`
            : `${this.groupMembers.length} member`;
          this.checkIfOwnerIsOutOfTheGroup$()
        }),
        catchError(error => {
          this.alertService.showError('Failed to fetch group members. Please try again later.');
          return throwError(() => error);
        }),
        finalize(() => this.loadingMembers = false)
      ).subscribe();
  };

  clearSelectedGroup(): void {
    this.cancelFetchGroupMemberRequest$.emit();
    this.formGroup.controls['group'].patchValue(null);
    this.formGroup.controls['typedGroupName'].patchValue('');
    this.filteredGroups = [...this.groups];
    this.formGroup.controls['typedEmail'].disable();
    this.formGroup.controls['typedEmail'].patchValue('');
    this.newAdmins.clear();
    this.groupMembers = [];
    this.groupMemberCountLabel = '';
  };

  setAdminStatusListener() {
    this.newAdmins.statusChanges.pipe(
      filter(status => status !== 'PENDING'),
      debounceTime(200),
    ).subscribe(() => {
      this.validatingAdmins = false;
      this.isAnyAdminNotInGroup = this.newAdmins.controls.some(control => control.hasError(USER_NOT_IN_GROUP_VALIDATOR_KEY));
      this.isAnyAdminNotInAzure = this.newAdmins.controls.some(control => control.hasError(USER_NOT_IN_AZURE_VALIDATOR_KEY));
      this.isAnyAdminWithUnexpectedError = this.newAdmins.controls.some(control => control.hasError(USER_UNEXPECTED_ERROR_VALIDATOR_KEY));
      this.hasInvalidAzureEmail = this.newAdmins.controls.some(control => control.hasError('invalidEmailFromList'));
      this.setChips();
    });
  }

  addAdmin(admin: string) {
    const member = this.groupMembers.find(member => member.mail === admin);
    if (!member) {
      this.setInvalidAdmin(admin);
    } else {
      this.pushAdmin(member);
    }
  }

  setInvalidAdmin(email: string) {
    this.pushAdmin({ mail: email } as Member);
  }

  pushAdmin(member: Member) {
    this.validatingAdmins = true;
    const memberControl = new FormControl<Member>(member, null, memberInGroupValidator(this.groupMembers, this.groupMembershipService));
    this.newAdmins.push(memberControl);
  }

  popAdmin() {
    this.newAdmins.removeAt(this.newAdmins.length - 1);
  };

  removeAdminAt(index: number): void {
    this.newAdmins.removeAt(index);
  };

  getChipType(memberCtrl: AbstractControl<Member>): any {
    if (memberCtrl.hasError(DUPLICATE_MEMBER_VALIDATOR_KEY)) {
      return ChipTypeConstant.NOTICE;
    }

    if (
      memberCtrl.hasError(USER_NOT_IN_GROUP_VALIDATOR_KEY) ||
      memberCtrl.hasError(USER_NOT_IN_AZURE_VALIDATOR_KEY) ||
      memberCtrl.hasError(USER_UNEXPECTED_ERROR_VALIDATOR_KEY) ||
      memberCtrl.hasError('invalidEmailFromList')
    ) {
      return ChipTypeConstant.ERROR;
    }

    return ChipTypeConstant.DEFAULT;
  }

  setChips() {
    this.emailChips = this.newAdmins.controls.map((ctrl: AbstractControl) => {
      return {
        value: ctrl.value.mail,
        type: this.getChipType(ctrl),
        closeable: true
      };
    });
  }

  // Event Handlers

  onChipBoxAdd(email: string): void {
    this.addAdmin(email);
  }

  onChipBoxBackspace(): void {
    this.popAdmin();
  }

  onChipBoxRemove(index: number): void {
    this.removeAdminAt(index);
  }

  // API Events

  alertOnSaveOrUpdate(response: HttpErrorResponse): void {
    let alertMessage = 'Failed to save agent. Please try again later.';

    if (response.error?.detail === 'Agent with the same name already exists') {
      alertMessage = 'You already have an agent with this name. Please use a unique name for your agent.';
    }

    this.alertService.showError(alertMessage);
  };

  onSuccessfulSaveAndShare(): void {
    this.closeAllModals();
    this.isRequestProcessing = false;
    this.unsavedChangesGuard.setSkipGuard(true);
    this.onSharedAgent.emit();
    this.alertService.showSuccess("Agent is now shared.");
  };

  onSuccessfulSaveAndUnshare(): void {
    this.closeAllModals();
    this.isRequestProcessing = false;
    this.unsavedChangesGuard.setSkipGuard(true);
    this.onUnsharedAgent.emit();
    this.alertService.showSuccess("Agent is now private.");
  };

  onSuccessfulMakePublic(): void {
    this.closeAllModals();
    this.isRequestProcessing = false;
    this.unsavedChangesGuard.setSkipGuard(true);
    this.onPubliclySharedAgent.emit();
    this.alertService.showSuccess("Your agent is now available under Public Agents.");
  };

  onFailedSaveAndShare(): void {
    this.isRequestProcessing = false;
    // TODO: error handling for invalid email on azure on edit save and share flow
    if (this.mailWithErrorOnAzure !== '') {
      this.confirmationModal?.closeModal({ emit: false });
      this.openModal();
      const failedAdmin = this.newAdmins.controls.find(control => control.value.mail === this.mailWithErrorOnAzure);
      if (failedAdmin) {
        const controlErrors = failedAdmin.errors;
        failedAdmin.setErrors({ ...controlErrors, 'invalidEmailFromList': true });
      }
    } else {
      this.confirmationModal?.closeModal({ emit: false });
    }
  };

  // API Calls

  saveAgent() {
    if (this.isAgentUpdate) {
      return this.updateAgent();
    }

    this.agentsService.save(this.sharingDetails!.agent!).subscribe({
      next: () => {
        this.unsavedChangesGuard.setSkipGuard(true);
        this.router.navigate([`/agents`]);
      }
    });
  };

  updateAgent() {
    this.agentsService.update(this.sharingDetails!.agent!).subscribe({
      next: () => {
        this.unsavedChangesGuard.setSkipGuard(true);
        this.router.navigate([`/agents`]);
      }
    });
  };

  saveAndShare(): void {
    if (this.isAgentUpdate) {
      return this.updateAndShare();
    }

    const azureGroupEmail = this.selectedGroup!.mail;
    const adminEmails = this.allAdmins.map(admin => admin.value.mail);
    this.isRequestProcessing = true;

    this.agentsService.saveAndShare(this.sharingDetails!.agent!, azureGroupEmail, adminEmails)
      .pipe(
        catchError((error) => this.handleSaveErrors(error))
      ).subscribe({
        error: () => this.onFailedSaveAndShare(),
        complete: () => this.onSuccessfulSaveAndShare()
      });
  };

  updateAndShare(): void {
    const azureGroupEmail = this.selectedGroup!.mail;
    const adminEmails = this.allAdmins.map(admin => admin.value.mail);
    this.isRequestProcessing = true;

    this.agentsService.updateAndShare(this.sharingDetails!.agent!, azureGroupEmail, adminEmails)
      .pipe(
        catchError((error) => this.handleUpdateErrors(error))
      ).subscribe({
        error: () => this.onFailedSaveAndShare(),
        complete: () => this.onSuccessfulSaveAndShare()
      });
  };

  saveAndMakePublic(): void {
    if (this.isAgentUpdate) {
      return this.updateAndMakePublic();
    }

    this.isRequestProcessing = true;
    this.agentsService.saveAndPublish(this.sharingDetails!.agent!)
      .pipe(
        catchError((error) => this.handleSaveErrors(error))
      ).subscribe({
        error: () => this.onFailedSaveAndShare(),
        complete: () => this.onSuccessfulMakePublic()
      });
  };

  updateAndMakePublic(): void {
    this.isRequestProcessing = true;
    this.agentsService.updateAndPublish(this.sharingDetails!.agent!)
      .pipe(
        catchError((error) => this.handleUpdateErrors(error))
      ).subscribe({
        error: () => this.onFailedSaveAndShare(),
        complete: () => this.onSuccessfulMakePublic()
      });
  };

  updateAndUnshare(): void {
    this.isRequestProcessing = true;
    this.agentsService.updateAndUnshare(this.sharingDetails!.agent!)
      .pipe(
        catchError((error) => this.handleUpdateErrors(error))
      ).subscribe({
        error: () => this.onFailedSaveAndShare(),
        complete: () => this.onSuccessfulSaveAndUnshare()
      });
  };

  // Error Handling

  handleSaveErrors(error: any): Observable<never> {
    if (error instanceof AgentSaveError) {
      this.alertOnSaveOrUpdate(error);
    } else if (error instanceof AgentPublishError) {
      this.unsavedChangesGuard.setSkipGuard(true);
      this.alertService.showError('Failed to publish agent. Please try again later.');
      this.router.navigate([`/agents/${error.agentId}/edit`]);
    } else if (error instanceof AgentShareError) {
      this.handleFailedUserError(error);
    } else {
      this.alertService.showError('An unexpected error occurred. Please try again later.');
    }

    return throwError(() => error);
  }

  // TODO: Create specific error for user not found on azure
  private handleFailedUserError(error: AgentShareError) {
    let errorMessage = 'Failed to share agent. Please try again later.';

    if (error.failedUser) {
      this.mailWithErrorOnAzure = error.failedUser;
      this.sharingAgentService.setFailingAdmin(error.failedUser);
      const adminsEmails = this.allAdmins.map(admin => admin.value.mail);
      this.sharingAgentService.setSelectedGroupAdmins(
        adminsEmails
          .filter(adminEmail => adminEmail !== this.userInformation?.mail && adminEmail !== error.failedUser)
          .map(adminEmail => adminEmail)
      );
      this.sharingAgentService.setSharingGroup(this.selectedGroup!);
      errorMessage = `Co-admin assignment to this email ${this.mailWithErrorOnAzure} failed, please try another user.`;
    }

    this.alertService.showError(errorMessage);
    this.router.navigate([`/agents/${error.agentId}/edit`]);
    this.unsavedChangesGuard.setSkipGuard(true);
  }

  handleUpdateErrors(error: any): Observable<never> {
    if (error instanceof AgentUpdateError) {
      this.alertOnSaveOrUpdate(error);
    } else if (error instanceof AgentShareError) {
      if (error.failedUser) {
        this.mailWithErrorOnAzure = error.failedUser;
      }
      this.alertService.showError('Failed update share status of agent. Please try again later.');
    } else if (error instanceof AgentPublishError) {
      this.alertService.showError('Failed to publish agent. Please try again later.');
    } else {
      this.alertService.showError('An unexpected error occurred. Please try again later.');
    }

    return throwError(() => error);
  };

  onContinue(): void {
    if (this.selectedShareToOption.id === this.KeepPersonalOptionId) {
      return this.onKeepPersonalSubmit();
    }

    this.showConfirmationModal();
  };

  onKeepPersonalSubmit(): void {
    if (!this.isAgentUpdate || this.isPrivateAgent()) {
      return this.saveAgent();
    }

    if (this.isUserAgentOwner) {
      return this.keepPrivateConfirmationModal!.open();
    }

    this.isRequestProcessing = true;
    this.checkIfOwnerIsOutOfTheGroup$();
    this.checkIfOwnerStillInAzure$()
      .pipe(
        first(),
        finalize(() => this.isRequestProcessing = false)
      )
      .subscribe({
        next: (isInAzure: boolean) => {
          this.isAgentOwnerMissing = !isInAzure;
          this.keepPrivateConfirmationModal!.open();
        },
        error: () => {
          this.alertService.showError('Failed to check if owner is still in Azure. Please try again later.');
        }
      });
  };

  private isPrivateAgent() {
    return this.sharingDetails?.agent.agentType === "PRIVATE";
  }

  checkIfOwnerStillInAzure$(): Observable<boolean> {
    const owner = this.agentOwner;

    if (!owner) {
      return of(false);
    }

    return this.groupMembershipService.getUserByEmail(owner.email)
      .pipe(
        map(() => true),
        catchError((error) => {
          if (error.code === 'Request_ResourceNotFound') {
            return of(false);
          }

          return throwError(() => error);
        })
      );
  };

  showMemberListModal(): void {
    this.memberListModal?.openModal();
    this.checkIfOwnerIsOutOfTheGroup$();
  };

  checkIfOwnerIsOutOfTheGroup$(): void {
    if (!this.sharingDetails?.agent?.azureGroupId) {
      return;
    }

    this.groupMembershipService.getGroupMembers(this.sharingDetails!.agent!.azureGroupId).subscribe(groupMembers => {
      const owner = this.agentOwner;
      this.isOwnerOutOfGroup = !(!!owner && groupMembers.some((member: {
        mail: string;
      }) => member.mail === owner.email));
    });
  }

  addAdmins(mails: string[]): void {
    mails.forEach(mail => {

      const member = this.groupMembers.find(member => member.mail === mail);
      if (!member) {
        this.setInvalidAdmin(mail);
      } else {
        this.pushAdmin(member);
      }
    });
  }

  addFailedAdmin(failedMail: string): void {
    this.mailWithErrorOnAzure = failedMail;
    const failedMember = { mail: failedMail } as Member;
    const memberControl = new FormControl<Member>(
      failedMember,
      invalidEmailFromListValidator([failedMail]),
      memberInGroupValidator(this.groupMembers, this.groupMembershipService)
    );

    this.newAdmins.push(memberControl);
    this.cdRef.detectChanges();
  }
};
