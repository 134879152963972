<div class="prompt-bubble-container">
  <div *ngIf="displayMore" class="more-files-container">
    <div class="more-files-files-wrapper">
      <div class="file-container-list" *ngFor="let file of fileReferencesSliceMore()">
        <div class="file">
          <i [ngClass]="getFileType(file) === ChipTypeConstant.ANALYZE ? 'spreadsheets' : 'text'"></i>
          <p>{{ file.displayTitle }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="badges-container">
    <app-chip *ngFor="let file of fileReferencesSlice()" class="no-pointer" [text]="file.displayTitle"
              [type]="getFileType(file)"/>
    <app-chip *ngIf="missingFiles.length" class="no-pointer missing-files" [text]="'Missing file(s)'"
              [type]="ChipTypeConstant.WARNING"/>
    <app-chip *ngIf="fileReferencesMoreThan(2) && !isMobileScreen()" [text]="'More files'"
              [type]="getFileType(fileReferences[0])" (click)="toggleDisplayMore()"/>
    <app-chip *ngIf="fileReferencesMoreThan(0) && isMobileScreen()"
              [text]="fileReferences.length === 1 ? 'File' : 'Files'" [type]="getFileType(fileReferences[0])"
              (click)="toggleDisplayMore()"/>

  </div>
  <div class="prompt-bubble-wrapper">
    <div class="prompt-bubble">
      <markdown katex mermaid class="markdown-wrapper message user" [data]="chatMessage.message.text"></markdown>
    </div>
  </div>
  <div class="prompt-bubble-icons-container">
    <div class="prompt-bubble-icons-container">
      <i id="prompt-bubble-copy" appTooltip text="Copy prompt" position="bottom"
         (click)="copyPromptToClipboard(chatMessage.message.text)">
        <svg class="icon copy-icon" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M14 4.7069L11.2743 4.7069L11.2743 2L2 2L2 11.3103L4.73429 11.3103L4.73429 14L14 14L14 4.7069ZM3.71429 9.58621L3.71429 3.72414L9.56 3.72414L9.56 4.7069L4.73429 4.7069L4.73429 9.58621L3.71429 9.58621ZM6.41429 12.2759L6.41429 6.43103L12.2857 6.43103L12.2857 12.2759L6.41429 12.2759Z"
                fill="#70707A"/>
        </svg>
      </i>
      <i id="prompt-bubble-save" appTooltip text="Save prompt" position="bottom" (click)="openSaveModal(chatMessage.message)">
        <svg class="icon save-icon" width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11 2H2V14H14V5L11 2ZM4.5 4H8.48V7.5H4.5V4ZM4 12V9H9.98H12V12H4Z" fill="#70707A"/>
        </svg>
      </i>
    </div>
  </div>
</div>
