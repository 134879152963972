import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, map } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserData } from '@app/shared/models/user-data.model';
import { ChatAuthor, ChatMessage } from '@shared/models/ask-our-data.model';

import { PromptSettings } from '@shared/models/prompt-settings.model';
import { PromptInputComponent } from '@app/shared/components/prompt-input/prompt-input.component';
import { AskOurDataService } from '@app/core/services/ask-our-data/ask-our-data.service';
import { ClipboardService } from "ngx-clipboard";
import { Messages } from '@app/shared/constants/ask-our-data-constants';
import { PlotlyService }  from 'src/app/core/services/ask-our-data/plotly.service';
import { featureFlags } from '@app/environments/environment';
import { AlertService } from '@app/core/services/alert/alert.service';

@Component({
  selector: 'app-chat-with-db',
  templateUrl: './chat-with-db.component.html',
  styleUrls: ['./chat-with-db.component.css']
})
export class ChatWithDbComponent implements OnInit, OnDestroy{
  @ViewChild('promptInputComponent') promptInputComponent!: PromptInputComponent;
  userData$: Observable<UserData>;
  selectedChatId: string;
  promptSettings: PromptSettings;
  isLoading: boolean = false;
  generatingResponse: boolean = false;
  receivedResponse = false;
  lastSystemMessage?: ChatMessage;
  dbname = '';
  dbId = '';
  ischatEmpty = true;
  isDbConnectErr = false;
  private subscriptions = new Subscription();
  generateSqlFailed = false;
  generatingChart: boolean[] = [];

  messages: ChatMessage[] = [];
  visibleMessages: ChatMessage[] = [];
  showSavePrompt: boolean = false;
  showCopyPrompt: boolean = true;
  inputPrompt: string = '';
  generateSubscription: Subscription | undefined;
  runSqlSubscription: Subscription | undefined;
  sqlResponse = '';
  showErrorIcon: boolean[] = [];
  jsonResponse: any;
  resultObtanined: boolean[] = [];
  protected readonly featureFlags = featureFlags;
  graphData: any[] = [];
  openGraph: boolean[] = [];
  chartGenerated: boolean[] = [];
  sessionId = this.generateSessionId();
  chatId = 1;

  constructor(private router: Router,
    private askOurDataService: AskOurDataService,
    public oidcSecurityService: OidcSecurityService,
    private cdRef: ChangeDetectorRef,
    private clipboard: ClipboardService,
    private alertService: AlertService,
    private plotly: PlotlyService,
    private route: ActivatedRoute) {
    this.selectedChatId = '';
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    );
    this.promptSettings = new PromptSettings(0, 'PROFESSIONAL', false, 'gpt-4-o');
  }

  ngOnInit(): void {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe(params => {
        this.dbId = params['id'];
        this.dbname = params['name'];
      });
    }
  }

  generateSessionId() {
    const chars = '0123456789';
    let sessionId = '';
    for (let i = 0; i < 10; i++) {
      sessionId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return sessionId;
  }

  change(type: string, chatId: number) {
    this.plotly.restyle('plot_child_chat_' + chatId, type);
  }

  async onSubmit(inputPrompt: string) {
    this.chatId ++;
    this.resultObtanined[this.chatId] = false;
    this.showErrorIcon[this.chatId] = false;
    if(!this.featureFlags.conversationChat) {
      this.messages = [];
      this.sqlResponse = '';
    }
    this.isDbConnectErr = false;
    /* istanbul ignore else */
    if (inputPrompt.trim() == "") return;
    /* istanbul ignore else */
    if (this.isLoading) return;
    this.inputPrompt = inputPrompt;
    this.receivedResponse = false;
    this.ischatEmpty = false;
    this.generatingResponse = true;
    this.generateSqlFailed = false;
    let promptValue = (inputPrompt || '').trim();
    this.promptInputComponent.setPrompt('');
    let author = new ChatAuthor("USER");

    let message = new ChatMessage(promptValue , author, new Date().toISOString(), promptValue, this.lastSystemMessage?.id, this.chatId);
    message.id = 'chat_' + this.chatId;
    this.addMessage(message);
    this.generatingResponse = true;
    this.isLoading = true;

    let requestData = this.featureFlags.conversationChat ? {
      database_id: this.dbId,
      question: promptValue,
      session_id: this.sessionId
    } : {id: this.dbId, question: promptValue};
    let apiCall = this.featureFlags?.conversationChat ? this.askOurDataService.callConversationChat(requestData) : this.askOurDataService.generateQuery(requestData);
    this.generateSubscription = apiCall.subscribe({
      next: (response: any) => {
        let isNotNullResponse = this.featureFlags.conversationChat ? response.response : response;
        if (isNotNullResponse) {
          let msg = this.featureFlags.conversationChat ? response.response : response.text;
          let systemResponse = new ChatMessage(msg, new ChatAuthor("SYSTEM"), new Date().toISOString(), msg, response.id, this.chatId);
          systemResponse.id = 'child_chat_' + this.chatId;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.generatingResponse = true;
          this.cdRef.detectChanges();
          if(response.tool_used === 'generate_sql' || !this.featureFlags.conversationChat) {
            this.runSql(msg, systemResponse, promptValue);
          } else {
            this.generatingResponse = false;
            this.receivedResponse = true;
            this.isLoading = false;
            let systemResponse = new ChatMessage(response.response, new ChatAuthor("SYSTEM"), new Date().toISOString(), msg, '1', this.chatId);
            systemResponse.id = 'child_chat_' + this.chatId;
            systemResponse.parentId = 'chat_' + this.chatId;
            this.addMessage(systemResponse);
            this.cdRef.detectChanges();
          }
        } else {
          this.generatingResponse = false;
          this.receivedResponse = true;
          this.isLoading = false;
          this.showErrorIcon[this.chatId] = true;
          let generateErr =  Messages.GENERATE_ERROR;
          let systemResponse = new ChatMessage(generateErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), '', '1', this.chatId);
          systemResponse.id = 'child_chat_' + this.chatId;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.cdRef.detectChanges();
        }
      },
      error: (error) => {
        if(this.featureFlags.conversationChat && error.error?.error?.status_code === 204) {
          this.generatingResponse = false;
          this.receivedResponse = true;
          this.isLoading = false;
          this.showErrorIcon[this.chatId] = true;
          let generateErr =  Messages.GENERATE_ERROR;
          let systemResponse = new ChatMessage(generateErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), '', '1', this.chatId);
          systemResponse.id = 'child_chat_' + this.chatId;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.cdRef.detectChanges();
        } else {
          if (error.status === 503) {
            this.isDbConnectErr = true;
          } else {
            let errMsg = (!this.featureFlags.conversationChat) ? error.error?.error_message : error.error?.error?.error_message;
            let generateErr =  (errMsg?.match(/^SQL injection\.*/g) ? Messages.NO_ACTION_ALLOWED :  Messages.GENERATE_SQL_ERROR);
            
            this.showErrorIcon[this.chatId] = true;
            let systemResponse = new ChatMessage(generateErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), '', 'parentId', this.chatId);
            systemResponse.id = 'child_chat_' + this.chatId;
            systemResponse.parentId = 'chat_' + this.chatId;
            this.addMessage(systemResponse);
            this.generateSqlFailed = true;
            this.generatingResponse = false;
            this.isLoading = false;
          }
        }
        this.receivedResponse = true;
        this.cdRef.detectChanges();
      }
    });
    this.subscriptions.add(this.generateSubscription);
  }

  copyToClipboard(text:  string) {
    if(text) {
      this.clipboard.copyFromContent(text);
      this.alertService.showSuccess(Messages.COPIED_TO_CLIPBOARD);
    }
  }

  addMessage(message: ChatMessage) {
    let messageIndex = this.messages.findIndex(m => m.id === message.id);
    if (messageIndex === -1) {
      this.messages.push(message);
    } else {
      this.messages[messageIndex] = message;
    }
  }

  runSql(sql: string, systemResponse: ChatMessage, promptValue: string): void {
    this.isDbConnectErr = false;
    this.runSqlSubscription = this.askOurDataService.runQuery({ id: this.dbId, sql: sql, question: promptValue, generate_summary: true}).subscribe({
      next: (response) => {
        if(response) {
          this.generatingResponse = false;
          let jsonResponse = JSON.parse(response.df_json || "[]");
          if(this.featureFlags.enableAoDataChart) {
            this.resultObtanined[this.chatId] = true;
            this.chartGenerated[this.chatId] = false;
            this.getChart(sql, response.df_json, promptValue, this.chatId);
          }
          this.cdRef.detectChanges();
          this.sqlResponse = response.df_markdown;
          let headers = Object.keys(jsonResponse[0]);
          let responseMessage = `<div class="chat-response"><p>`+ systemResponse.text +`</p> <div class="chat-table"><table>`;
          responseMessage += `<tr>`;
          headers.forEach(header => {
            responseMessage += `<th>`+header+`</th>`;
          });
          responseMessage += `</tr>`;
          let firstFiveRecords = jsonResponse.slice(0, 5);
          firstFiveRecords.forEach((data: any) => {
            responseMessage += `<tr>`;
            headers.forEach(header => {
              responseMessage += `<td>`+data[header]+`</td>`;
            });
            responseMessage += `</tr>`;
          });
          responseMessage += `</table></div>`;
          if(jsonResponse.length > 5) {
            responseMessage += `<div class="table-bot-div library-icomoon"><a href="/ask-our-data/response" target="_blank" >View Full Table <i class="pl-2 icon-external-link-mono"></i></a></div>`;
          }
          responseMessage += `</div>`;
          localStorage.setItem('fullTableData', JSON.stringify({
            name:this.dbname,
            id: this.dbId,
            sql: sql,
            promptValue: promptValue
          }));
          let systemRunSqlResponse = new ChatMessage(responseMessage, new ChatAuthor("SYSTEM"), new Date().toISOString(), systemResponse.prompt, response.id, this.chatId, response.summary);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.addMessage(systemRunSqlResponse);
          this.isLoading = false;
          this.receivedResponse = true;
          this.cdRef.detectChanges();
        } else {
          this.generateSqlFailed = true;
          systemResponse.parentId = 'parentId';
          this.showErrorIcon[this.chatId] = true;
          let sqlErr =  `<p>` + sql + `</p><p>` + Messages.NO_RESPONSE + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.addMessage(systemRunSqlResponse);
          this.generatingResponse = false;
          this.isLoading = false;
          this.receivedResponse = true;
          this.cdRef.detectChanges();
        }
      },
      error: (error) => {
        if (error.status === 503) {
          this.isDbConnectErr = true;
        } else if (error.status === 400) {
          this.generateSqlFailed = true;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.showErrorIcon[this.chatId] = true;
          let injectionErr = (error.error?.error_message.match(/^SQL injection\.*/g) ? Messages.NO_ACTION_ALLOWED :  Messages.NO_RESPONSE);
          let sqlErr =  (error.error?.error_message.match(/^Syntax error\.*/g)) ? Messages.GENERATE_SQL_ERROR : injectionErr;
          let sqlErrMsg = `<p>` + sqlErr + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErrMsg, new ChatAuthor("SYSTEM"), new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemRunSqlResponse);
        } else {
          this.generateSqlFailed = true;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.showErrorIcon[this.chatId] = true;
          let sqlErr =  `<p>` + sql + `</p><p>` + Messages.NO_RESPONSE + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErr, new ChatAuthor("SYSTEM"), new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemRunSqlResponse);
        }
        this.isLoading = false;
        this.generatingResponse = false;
        this.receivedResponse = true;
        this.cdRef.detectChanges();
      }
    });
    this.subscriptions.add(this.runSqlSubscription);
  }

  viewGraph(id: number) {
    this.openGraph[id] = !this.openGraph[id];
    this.cdRef.detectChanges();
    this.generatingChart[id] = true;
    if(this.openGraph[id] && this.graphData[id]) {
      this.plotly.plotLine("", "plot_child_chat_"+ id, this.graphData[id]);
    } else {
    // show error message on graph display failure
    }
    this.generatingChart[id] = false;
  }

  download(graphData: any) {
    this.askOurDataService.downloadGraph(JSON.stringify(graphData)).subscribe({
      next: () => {
        this.alertService.showSuccess(Messages.DOWNLOADED_CHART);
      },
      error: () => {
        this.alertService.showSuccess(Messages.UNABLE_TO_DOWNLOAD_CHART);
      }
    });
  };

  getChart(sql: string, chatResponse: any, promptValue: string, chatId: number) {
    this.generatingChart[chatId] = true;
    let request = {
        "id" : this.dbId,
        "question" : promptValue,
        "sql" : sql,
        "df" : chatResponse
      };
    this.askOurDataService.generateChart(request).subscribe({
      next: (response) => {
        this.generatingChart[chatId] = false;
        if(response) {
          this.chartGenerated[chatId] = true;
          let graph = JSON.parse(response.fig);
          this.graphData[chatId] = graph.data;
        }
      },
      error: () => {
        this.generatingChart[chatId] = false;
        this.cdRef.detectChanges();
      }
    });
  }

  regenerate() {
    this.messages.pop();
    this.openGraph[this.chatId] = false;
    this.graphData[this.chatId] = '';
    this.showErrorIcon[this.chatId] = false;
    this.chatId--;
    this.onSubmit(this.inputPrompt);
  }

  toShowCopyPrompt(role: string, chatId: number): boolean {
    return  role === 'USER' || this.resultObtanined[chatId];
  }

  stopResponse() {
    this.isLoading = false;
    this.generatingResponse = false;
    this.generateSubscription?.unsubscribe();
    this.runSqlSubscription?.unsubscribe();
  }

  isSubmitDisabled(): boolean {
    return this.isLoading || this.generatingResponse || this.isDbConnectErr;
  }

  back() {
     this.router.navigate(['/ask-our-data']);
  }

  startNewChat() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate(['/ask-our-data/chat'],{ queryParams: { id: this.dbId, name: this.dbname }}));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

