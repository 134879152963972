<app-modal [id]="modalId" [headline]="'Spreadsheet analysis feature usage'">
  <div class="info-body">
    <p>
      We are excited to offer a BETA of advanced data analysis capabilities in Gene.AI. Prior to using this
      capability please be aware and acknowledge the following:
    </p>
    <ol>
      <li>
        Generative AI may encounter challenges with complex or poorly formatted data sets. In such cases, the
        generated
        responses might not fully capture the intended insights or could potentially misrepresent the data.
      </li>
      <li>Processing times may be longer for large data sets.</li>
      <li>
        <strong>Important:</strong> Always verify all outputs against your source data before using in any
        production or decision making context. We are
        continually evaluating and improving the quality and performance of this feature.
      </li>
      <li>
        Please refer to <a
          href="https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Our-Data.aspx?csf=1&web=1&e=szXWYn&OR=Teams-HL&CT=1727285922117&CID=6697aa55-d375-45a6-b921-16ccbcb8b260"
          target="_blank" rel="noopener noreferrer">iConnect</a> for prompting guidance and more. <i>Please note that you will
        leave Gene.AI in doing so.</i>
      </li>
    </ol>
    <div class="modal-check-container" [ngClass]="{ 'processing': processingRequest }">
      <input type="checkbox" id="acknowledgement" [(ngModel)]="isAcknowledged" [disabled]="processingRequest"/>
      <p for="acknowledgement" (click)="toggleAcknowledge()">
        I understand and acknowledge these limitations and agree to verify all outputs before use.
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <core-button id="continueButton" (click)="closeModal()" label="Cancel" type="button" variant="information"
      size="regular" noradius="none" arialabel="Cancel" [disabled]="processingRequest" />
    <ng-container *ngIf="!processingRequest; else processing">
      <core-button (click)="accept()" id="cancelButton" [disabled]="!isAcknowledged" label="Continue" type="button"
        variant="primary" size="regular" noradius="none" arialabel="Continue" />
    </ng-container>
    <ng-template #processing>
      <div class="processing-btn">
        <kmd-loading-wheel></kmd-loading-wheel>
      </div>
    </ng-template>
  </div>
</app-modal>