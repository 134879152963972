import { HttpErrorResponse } from "@angular/common/http";
import { ApiError } from "@app/core/utils/http/api-error";
import { Agent } from "@app/shared/models/agent";

export class AgentSaveError extends ApiError {
  constructor(
    error: HttpErrorResponse,
  ) {
    super(error);
  }
}

export class AgentUpdateError extends ApiError {
  constructor(
    error: HttpErrorResponse,
  ) {
    super(error);
  }
}

export class AgentShareError extends ApiError {
  agent: Agent;
  failedUser?: string;

  constructor(
    error: HttpErrorResponse,
    agent: Agent,
  ) {
    super(error);
    this.agent = agent;
    this.failedUser = this.extractFailedUser(error.error?.detail);
  }

  get agentId(): string {
    return this.agent.id!;
  }

  private extractFailedUser(detail?: string): string | undefined {
    const match = detail?.match(/User not found - (.+)/);
    return match ? match[1] : undefined;
  }
}

export class AgentPublishError extends ApiError {
  agent: Agent;

  constructor(
    error: HttpErrorResponse,
    agent: Agent,
  ) {
    super(error);
    this.agent = agent;
  }

  get agentId(): string {
    return this.agent.id!;
  }
}
