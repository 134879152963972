import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
declare let Plotly: any;
@Injectable({
  providedIn: 'root'
})
export class PlotlyService {
  constructor(private alertService: AlertService) { }
  plotLine(title: string, plotDiv: string, data: any){
    let layout = {
      title:title
    };
    Plotly.newPlot(plotDiv, data, layout, {
      responsive: true, 
      displaylogo: false,
      modeBarButtonsToRemove: ['toImage'],
      modeBarButtonsToAdd: [
        {
          name: 'Download chart',
          icon: Plotly.Icons.camera,
          click: () => {
            let date = new Date();
            let svgContent = document.querySelector('#' + plotDiv + ' .svg-container')?.innerHTML.trim();
            if(svgContent) {
              const svgData = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`;
              // Create an anchor element for download
              const downloadLink = document.createElement("a");
              downloadLink.href = svgData; // Set the base64 source
              downloadLink.download ='chart_'+ date.getTime() +'.svg'; // Set file name
              downloadLink.style.display = 'none';
            
        
              // Append the link, trigger click, and remove it
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink); 
              this.alertService.showSuccess('Chart downloaded successfully!');
            } else {
              this.alertService.showError('Unable to download chart');
            }
          }
        }
      ]

    });     
  }
  
  restyle(plotDiv: string, type: string){
    Plotly.restyle(plotDiv, 'type', type, {responsive: true, displaylogo: false, modeBarButtonsToRemove: ['toImage']});
  }
}
