import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CopyResponseComponent } from '../ask-gene/copy-response/copy-response.component';
import { ModalSavePromptComponent } from '../prompt/modalsaveprompt/modal-save-prompt.component';
import { Message, Source } from '@shared/models/message.model';
import { Prompt } from '@shared/models/prompt.model';
import { ChatMessage } from "@app/chat/chat-message";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css', './chat.component.markdown.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent implements OnChanges {

  @ViewChild('copyResponseComponent') copyResponseComponent?: CopyResponseComponent;
  @ViewChild('modalSavePromptComponent') modalSavePromptComponent?: ModalSavePromptComponent;

  @Input() username!: string;
  @Input() chatType!: string;
  @Input() chatId!: string;

  @Input() showSavePrompt: boolean = true;
  @Input() showCopyPrompt: boolean = true;

  @Output() responseSelectEvent = new EventEmitter<string>();
  @Output() promptSavedEvent = new EventEmitter();
  @Output() messagesBranchUpdated = new EventEmitter<Message[]>();

  @Input() generatingResponse: boolean = false;
  @Input() streamingResponse: boolean = false;

  allMessages: Map<string, ChatMessage> = new Map();
  visibleMessages: ChatMessage[] = [];

  @Input() lastSystemMessage?: Message
  @Output() lastSystemMessageChange = new EventEmitter<Message>();

  @Input() lastUserMessage?: Message
  @Output() lastUserMessageChange = new EventEmitter<Message>();


  selectedPrompt?: Prompt;

  accordions: AccordionItem[] = [];
  caretIcon = 'caretDown-11px-mono-blue';

  readonly FILE_REFERENCE = 'ASK_MY_DOCS';
  readonly REPOSITORY = 'ASK_OUR_DOCS';
  readonly WEB_SOURCE = 'WEB_SEARCH';

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['generatingResponse']) {
      if (this.generatingResponse && this.visibleMessages.length > 0 && this.visibleMessages[this.visibleMessages.length - 1].message.author.role === 'SYSTEM') {
        this.visibleMessages.pop();
      }
    }
  }

  displayStreamingAnimation(message: Message, isLatestMessage: boolean): boolean {
    return message?.author.role != 'USER'
      && isLatestMessage
      && this.streamingResponse;
  }

  setMessages(messages: Message[]) {
    this.allMessages = new Map()
    messages.forEach(m => this.addMessage(m));
  }

  addMessage(message: Message) {
    this.checkMessageSources(message);

    if (this.chatType === 'agents' && message.sources && message.sources.length > 0) {
      this.accordions.push({id: message.id!, visibility: false, icon: this.caretIcon});
    }

    let messageIndex = message.id ? this.allMessages.has(message.id) : false;
    if (!messageIndex) {
      let chatMessage = this.addNewMessage(message)
      this.branchTo(chatMessage);
    } else {
      this.updateExistingMessage(message);
    }
    this.cdRef.detectChanges();
  }

  private updateExistingMessage(message: Message) {
    let existingChatMessage = this.allMessages.get(message.id!);
    existingChatMessage!.message = message;
  }

  public trackMessage (index: number, item: ChatMessage) {
    return item.message.text;
  }

  private addNewMessage(message: Message): ChatMessage {
    let parent = message.parentId ? this.allMessages.get(message.parentId!) : undefined;

    let chatMessage = new ChatMessage(message, parent);
    this.allMessages.set(chatMessage.message.id!, chatMessage)
    return chatMessage;
  }

  branchTo(chatMessage: ChatMessage | undefined) {
    if (chatMessage == undefined) {
      return
    }

    let aux: ChatMessage[] = []
    let parent = chatMessage.parent;

    while (parent != undefined) {
      aux.unshift(parent);
      parent = parent.parent;
    }

    aux.push(chatMessage);

    let child = chatMessage.mostRecentChild();

    while (child != undefined) {
      aux.push(child);
      child = child.mostRecentChild();
    }

    this.visibleMessages = aux;

    this.setLastUserMessage();
    this.setLastSystemMessage();

    this.messagesBranchUpdated.emit(this.visibleMessages.map(value => {
      return value.message
    }))
  }

  private checkMessageSources(message: Message) {
    if (message.text.startsWith("Sorry,"))
      message.sources = [];
  }

  private setLastSystemMessage() {
    let systemMessages = this.visibleMessages.filter(m => m.message.author.role === 'SYSTEM');
    if (systemMessages.length > 0) {
      this.lastSystemMessageChange.emit(systemMessages[systemMessages.length - 1].message)
    }
  }

  private setLastUserMessage() {
    let userMessages = this.visibleMessages.filter(m => m.message.author.role === 'USER');
    if (userMessages.length > 0) {
      this.lastUserMessageChange.emit(userMessages[userMessages.length - 1].message)
    }
  }

  openModal(message: Message): void {
    let prompt = new Prompt(message.text, message.promptSettings);
    prompt.id = message.id;
    prompt.fileReferences = message.fileReferences;
    prompt.chatId = this.chatId;

    this.selectedPrompt = Object.assign({}, prompt);
    this.modalSavePromptComponent?.open();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  promptSaved(prompt: Prompt) {
    this.modalSavePromptComponent?.close();
    this.promptSavedEvent.emit();
  }

  openCopyResponse(event: MouseEvent, response: string) {
    this.copyResponseComponent?.showPopover(event, response);
  }

  responseSelected(responseText: string) {
    this.responseSelectEvent.emit(responseText);
  }

  getFormattedFileReferencesByMessage(message: Message) {
    let formattedReferences = "";
    if (message != undefined && message.fileReferences != undefined) {
      const titles: string[] = message.fileReferences!.slice(0, 3).map(ref => ref.title ? ref.title : "Missing File");
      if (message.fileReferences.length > 3) {
        titles.push("...");
      }
      formattedReferences = titles.join(", ");
    }
    return formattedReferences;
  }

  getAllFileReferencesByMessage(message: Message) {
    const titles: string[] = message.fileReferences!.map(ref => ref.title ? ref.title : "Missing File");
    return titles.join(", ");
  }

  isFileReferencesToolTipEnabled(message: Message) {
    return !(message.fileReferences && message.fileReferences.length > 3);
  }

  isValidMessage(message: Message) {
    return message.author.role === 'USER' && message.fileReferences && message.fileReferences?.length > 0 && !this.previousFileReferencesMatch(message);
  }

  private previousFileReferencesMatch(message: Message) {
    let system = message.parentId ? this.allMessages.get(message.parentId) : undefined;
    let parent = system?.parent?.message

    return JSON.stringify(parent?.fileReferences) === JSON.stringify(message?.fileReferences);
  }

  showFilePage(sourceUrl: string) {
    return sourceUrl.includes('file?page=') ? ' - Page ' + sourceUrl.split('file?page=')[1] : '';
  }

  getChatTableClass() {
    return this.chatType === "ask-gene" || this.chatType === "image-creation" ? "response-paginator-ask-gene" : "";
  }

  toggleAccordionIcon(messageId: string) {
    let index = this.accordions.findIndex(accordion => accordion.id === messageId);
    this.accordions[index].visibility = !this.accordions[index].visibility;
    this.accordions[index].icon = this.accordions[index].visibility ? "caretUp-11px-mono-blue" : "caretDown-11px-mono-blue";
  }

  filteredSources(sources: Source[] | undefined, type: string): Source[] | undefined {
    return sources?.filter(source => source.type === type);
  }

  decodeSourceTitle(title: string) {
    return decodeURIComponent(title);
  }

  getAccordionItemVisibility(messageId: string) {
    let index = this.accordions.findIndex(accordion => accordion.id === messageId);
    return this.accordions[index].visibility;
  }

  getAccordionItemIcon(messageId: string) {
    let index = this.accordions.findIndex(accordion => accordion.id === messageId);
    return this.accordions[index].icon;
  }
}

export type AccordionItem = {
  id: string;
  visibility: boolean;
  icon: string;
}

