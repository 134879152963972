<div id="agent-filters-container">
  <h3>Search Agents</h3>
  <form [formGroup]="filtersForm" id="agent-filters">
    <input
      kmdFormInput
      id="search-input"
      type="text"
      formControlName="searchByAgentName"
      placeholder="Enter Agent name"
      autocomplete="off" />
    <div class="filters">
      <kmd-dropdown formControlName="category" [options]="categories">
      </kmd-dropdown>
      <kmd-dropdown formControlName="sortBy" [options]="sortOptions">
      </kmd-dropdown>
    </div>
  </form>
</div>
