<div class="my-library-content">
  <h2 class="title" *ngIf="!isMobileResolution()">My library</h2>
  <core-tabs-container position="columns">
    <core-tabs variant="default" class="library-tabs">
      <core-tabs-item label="Chat history" variant="default" selected targetid="my-chat-history"></core-tabs-item>
    </core-tabs>

    <core-tabs-content>
      <div id="my-chat-history">
        <app-chat-history-new *ngIf="!isMobileResolution()"/>
        <app-chat-history-mobile *ngIf="isMobileResolution()"/>
      </div>
    </core-tabs-content>
  </core-tabs-container>
</div>
