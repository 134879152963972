import { AbstractControl, ValidationErrors } from "@angular/forms";

export class MaxLimitValidator {
    static validate(control: AbstractControl, maxLimit: number): null | ValidationErrors {
        const values = control.value as string[];

        if (values && Array.isArray(values)) {
            if (values.length > maxLimit) {
                return { maxLimit: values };
            }
        }

        return null;
    };
}