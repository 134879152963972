import {Component, Inject} from '@angular/core';
import {links} from '@shared/constants/about-us/about-us.links';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent {
  constructor(@Inject("RESOURCES_URL") private resourcesUrl: string) {
  }

  aboutUsVideo: string = this.resourcesUrl + "AboutUsPage/GeneAI-Short-Demo.mp4";
  links: any = links;
}
