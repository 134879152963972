import { Injectable } from '@angular/core';
import { AgentShareOption, AgentShareOptionId } from '@app/shared/models/agent-share-option.model';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShareOptionsService {
  private defaultShareOptions: AgentShareOption[] = [
    { id: 'select-one', label: 'Select one', hideOption: true },
    { id: 'keep-personal', label: 'Personal', disabled: false },
    { id: 'microsoft-group', label: 'Team (Microsoft Group)', disabled: false },
    { id: 'make-public', label: 'All company (Public)', disabled: false },
  ];

  private defaultShareOption: AgentShareOption = this.defaultShareOptions.find(option => option.id === 'keep-personal')!;
  private shareOptionsSubject = new BehaviorSubject<AgentShareOption[]>(this.defaultShareOptions);

  shareOptions$ = this.shareOptionsSubject.asObservable().pipe(distinctUntilChanged());

  disableOption(optionId: AgentShareOptionId): void {
    this.updateOption(optionId, { disabled: true });
  }

  enableOption(optionId: AgentShareOptionId): void {
    this.updateOption(optionId, { disabled: false });
  }

  resetOptions(): void {
    const resetOptions = [...this.defaultShareOptions];
    this.shareOptionsSubject.next(resetOptions);
  }

  getOption(optionId: AgentShareOptionId): AgentShareOption | undefined {
    return this.shareOptionsSubject.value.find(option => option.id === optionId);
  }

  getDefaultShareOption(): AgentShareOption {
    return this.defaultShareOption;
  }

  setDefaultShareOption(optionId: AgentShareOptionId): void {
    this.defaultShareOption = this.defaultShareOptions.find(option => option.id === optionId)!;
  }

  updateOption(optionId: AgentShareOptionId, changes: Partial<AgentShareOption>): void {
    const currentOptions = this.shareOptionsSubject.value;
    const newOptions = currentOptions.map(option =>
      option.id === optionId ? { ...option, ...changes } : option
    );

    if (!this.areOptionsEqual(currentOptions, newOptions)) {
      this.shareOptionsSubject.next(newOptions);
    }
  }

  private areOptionsEqual(current: AgentShareOption[], newOptions: AgentShareOption[]): boolean {
    return JSON.stringify(current) === JSON.stringify(newOptions);
  }
}
