import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ChipTypeConstant } from "@app/shared/constants/chip/chip-type";

@Component({
  selector: "app-chip",
  templateUrl: "./chip.component.html",
  styleUrls: ["./chip.component.css"],
})
export class ChipComponent {
  @Output() close = new EventEmitter<void>();
  @Input() closeable: boolean = false;
  @Input() text: string = "";
  @Input()
  set type(value: any) {
    if (this._type === ChipTypeConstant.ERROR && value === ChipTypeConstant.DEFAULT) {
      return;
    }

    this._type = value;
  };

  get type(): any {
    return this._type;
  };

  private _type: any = ChipTypeConstant.DEFAULT;

  onClose(): void {
    this.close.emit();
  };

  displayIconForChipType(): boolean {
    if (this._type.icon === "none") {
      return false;
    }

    return this._type?.kind !== 'default' && this._type?.kind !== 'example-prompt'
  }
}
