import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


export interface HasUnsavedChanges {
  hasUnsavedChanges(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<HasUnsavedChanges> {

  canDeactivate(component: HasUnsavedChanges,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                currentRoute: ActivatedRouteSnapshot,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                currentState: RouterStateSnapshot,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                nextState: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (component.hasUnsavedChanges()) {
      return confirm('You have unsaved changes. Are you sure you want to leave?');
    }
    return true;
  }
}
