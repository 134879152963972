import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { featureFlags } from "@environments/environment";

@Component({
    selector: 'app-file-manager-drawer',
    templateUrl: './file-manager-drawer.component.html',
    styleUrls: ['./file-manager-drawer.component.css']
})
export class FileManagerDrawerComponent implements OnChanges {
  @Input() fileManagerDrawerToggled?: boolean;

  @Output() hideFMDrawer = new EventEmitter<void>();

  protected readonly featureFlags = featureFlags;

  isExpanded: boolean | undefined = false;

  constructor() { }

  ngOnChanges(): void {
    this.isExpanded = this.fileManagerDrawerToggled;
  }
}
