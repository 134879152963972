import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { buildRoute, ChatMetaData } from "@shared/models/chat-metadata.model";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { ActionButtonsComponent } from "@app/my-library/shared/record/action-buttons/action-buttons.component";
import { DeleteModalComponent } from "@shared/components/delete-modal/delete-modal.component";
import { Chat } from "@shared/models/chat.model";
import { ChatService } from "@services/chat/chat.service";
import { AgentChatsService } from "@services/agentchats/agentchats.service";
import { AlertService } from "@services/alert/alert.service";
import { AgentChat } from "@shared/models/agent-chat";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.css'],
  providers: [DatePipe]

})
export class RecordComponent {
  @ViewChild('actionsMenu') actionsMenu?: ActionButtonsComponent;
  @ViewChild('deleteModal') deleteModal?: DeleteModalComponent;
  @ViewChild('titleTextarea') titleTextarea?: ElementRef;

  @Input() record?: ChatMetaData;
  @Output() deletedChatEvent = new EventEmitter<ChatMetaData>();

  editingEnabled = false;
  showEditAction = false;
  inputValue = '';
  chatData?: Chat;
  chatToDelete?: ChatMetaData;
  readonly AGENT_TYPE = 'AGENT';
  protected readonly faEllipsis = faEllipsis;

  constructor(protected chatService: ChatService,
              protected agentChatsService: AgentChatsService,
              protected alertService: AlertService,
              protected datePipe: DatePipe,
              protected router: Router,
              protected chatHistoryUpdateService: ChatHistoryUpdateService) {
  }

  onDeleteChat($event: ChatMetaData) {
    this.chatToDelete = $event;
    this.getChatData($event).pipe(
      tap({
        next: (chat) => {
          this.chatData = chat;
          this.chatToDelete = $event;
          this.deleteModal?.open($event.id);
        },
        error: () => {
          this.alertService.showError("Error retrieving chat data");
        }
      })
    ).subscribe();
  }

  onEditChat($event: ChatMetaData) {
    this.getChatData($event).pipe(
      tap({
        next: (chat) => {
          this.chatData = chat;
          this.chatToDelete = $event;
          this.editingEnabled = true;
          this.inputValue = $event.title;
          setTimeout(() => {
            this.titleTextarea?.nativeElement.focus();
            this.autoSizeTextArea()
          });
        },
        error: () => {
          this.alertService.showError("Error retrieving chat data");
        }
      })
    ).subscribe();
  }

  onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.stopEditing(this.record);
    }
    if ($event.key === 'Escape')
      this.stopEditing(undefined);
  }

  stopEditing(chatMetadata: ChatMetaData | undefined) {
    this.editingEnabled = false;
    if (this.record && chatMetadata != undefined) {
      let tmpTitle: string = this.record.title;
      chatMetadata.title = this.inputValue.trim();
      this.record.title = chatMetadata.title;
      if (this.chatData) {
        this.chatData.title = chatMetadata.title;
        this.editChat().subscribe({
          next: () => {
            this.chatHistoryUpdateService.notifyChatUpdated(undefined);
            this.alertService.showSuccess("Chat title has been saved");
          },
          error: () => {
            if (this.record) this.record.title = tmpTitle;
            this.alertService.showError("Error updating chat title");
          }
        });
      } else {
        this.alertService.showError("Chat data is not available");
      }
    }
  }

  getChatData(chatMetaData: ChatMetaData): Observable<Chat> {
    return this.chatService.getChat(chatMetaData.id);
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd MMM yy HH:mm') || '';
  }

  startChat() {
    if (this.record) {
      const route = buildRoute(this.record);
      this.router.navigate([route]);
    }
  }

  showActionMenu(event: MouseEvent) {
    const currentTargetBackup = event.currentTarget;
    if (this.record) {
      this.getChatData(this.record).pipe(
        tap({
          next: (chat) => {
            this.chatData = chat;
            this.showEditAction = this.isAgentChatConsistent(this.record!) || chat.type !== this.AGENT_TYPE;
          },
          error: () => {
            this.alertService.showError("Error retrieving chat data");
          },
          finalize: () => {
            const clonedEvent = { ...event, currentTarget: currentTargetBackup };
            this.actionsMenu?.showPopover(clonedEvent as MouseEvent, this.record!);
          }
        })
      ).subscribe();
    }
  }

  autoSizeTextArea() {
    const textArea = this.titleTextarea?.nativeElement;
    if (textArea) {
      textArea.style.overflow = 'hidden';
      textArea.style.height = 'auto';
      textArea.style.height = textArea.scrollHeight + 'px';
    }
  }


  doDeleteChat($event: ChatMetaData) {
    this.deleteChat($event.id).subscribe({
      next: () => {
        this.chatHistoryUpdateService.notifyChatUpdated(undefined);
        this.deleteSuccessProcess($event);
      },
      error: () => {
        this.deleteModal?.close();
        this.alertService.showError("Error deleting chat");
      }
    });
  }

  deleteSuccessProcess(chat: ChatMetaData) {
    this.deletedChatEvent.emit(chat);
    this.deleteModal?.close();
    this.alertService.showSuccess("Chat has been deleted");
  }

  private deleteChat(chatId: string) {
    if (this.chatData?.type === this.AGENT_TYPE) {
      let agentChat = {
        agentId: this.chatToDelete?.agentId!,
        chat: this.chatData
      } as AgentChat;
      return this.agentChatsService.deleteAgentChat(agentChat);
    } else {
      return this.chatService.delete(chatId);
    }
  }

  private editChat() {
    if (this.chatData?.type === this.AGENT_TYPE) {
      let agentChat = {
        agentId: this.chatToDelete?.agentId!,
        chat: this.chatData
      } as AgentChat;
      return this.agentChatsService.updateAgentChat(agentChat);
    } else {
      return this.chatService.update(this.chatData!);
    }
  }

  isAgentChatConsistent(chatMetaData: ChatMetaData): boolean {
    return this.chatData?.type === this.AGENT_TYPE && chatMetaData.agentId != null;
  }
}
