import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { AgentsService } from "@services/agents/agents.service";
import { ChatComponent } from "@app/chat/chat.component";
import { Author, Message } from "@shared/models/message.model";
import { PromptSettings } from "@shared/models/prompt-settings.model";
import { KmdModalService } from "gds-atom-components";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { UserData } from "@shared/models/user-data.model";
import { Agent } from "@shared/models/agent";
import { UuidService } from "@services/uuid.service";

@Component({
  selector: 'app-agent-wizard',
  templateUrl: './agent-wizard.component.html',
  styleUrls: ['./agent-wizard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AgentWizardComponent implements AfterViewInit {

  @Input() agent?: Agent
  @Output() agentChange = new EventEmitter<Agent>();
  @Output() toggleParentViewEvent = new EventEmitter<void>();

  lastSystemMessage: Message | undefined = undefined

  username$ = this.oidcSecurityService.userData$.pipe(
    map(result => {
      return new UserData(result.userData.name, result.userData.email)
    })
  )

  unusedPromptSettings = new PromptSettings(1, "CASUAL", false);

  showError: boolean = false;

  @ViewChild('chat') chatComponent?: ChatComponent;


  constructor(private agentService: AgentsService,
              private kmdModalService: KmdModalService,
              private oidcSecurityService: OidcSecurityService,
              private uuidService: UuidService) {
  }

  ngAfterViewInit() {
    let initialMessage = new Message("Welcome! Let's define the core concept of your AGENT. What type of AGENT are you interested in creating?",
      new Author("SYSTEM"), new Date(), this.unusedPromptSettings, undefined);
    initialMessage.id = this.uuidService.random();
    let messages = [initialMessage];


    messages.forEach(message => this.chatComponent?.addMessage(message));
  }

  submit(prompt: string) {
    let userPrompt = new Message(prompt, new Author("USER"), new Date(), this.unusedPromptSettings, this.lastSystemMessage?.id);
    userPrompt.id = this.uuidService.random();
    this.kmdModalService.open('loadingResponseModal');
    let chatContext = this.chatComponent?.visibleMessages.map(m => {
      return m.message
    }) || [];

    this.agentService.promptBuilder(userPrompt, chatContext)
      .subscribe({
        next: (response) => {
          /**
           * Currently the API returns a wrong parent ID for the message and thus we must
           * override the parent ID for the one that is in the message we have sent.
           */
          response.message.parentId = userPrompt.id;

          this.chatComponent?.addMessage(userPrompt);
          this.chatComponent?.addMessage(response.message);
          this.kmdModalService.close('loadingResponseModal');
          this.agentChange.emit(response.agent);
        },
        error: () => {
          this.showError = true;
          this.kmdModalService.close('loadingResponseModal');
        }
      });

  }

  toggleParentView() {
    this.toggleParentViewEvent.emit();
  }
}
