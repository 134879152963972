import { Component, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';
import { ChatService } from "@services/chat/chat.service";
import { filter, Observable, of, Subject, switchMap, takeUntil } from "rxjs";
import { PagedChatMetaData } from "@shared/models/paged-chat-metadata.model";
import { Router } from "@angular/router";
import { buildRoute, ChatMetaData } from "@shared/models/chat-metadata.model";
import { NavigationCacheService } from '@app/core/services/navigation-cache/navigation-cache.service';
import { MenuOptionNewComponent } from "@app/navbar-new/menu-option-new/menu-option-new.component";
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";
import { map } from "rxjs/operators";
import { NavBarService } from '@app/core/services/navbar/navbar.service';

@Component({
  selector: 'app-my-library-menu-option',
  templateUrl: './my-library-menu-option.component.html',
  styleUrls: ['./my-library-menu-option.component.css']
})
export class MyLibraryMenuOptionComponent extends MenuOptionNewComponent implements OnDestroy {
  @Output() override onNavigation = new EventEmitter<void>();
  chats$: Observable<PagedChatMetaData>;
  private latestChatId: string = '';
  private destroy$ = new Subject<void>();

  constructor(
    private chatService: ChatService,
    protected override router: Router,
    private navigationCacheService: NavigationCacheService,
    protected override zone: NgZone,
    private chatHistoryUpdateService: ChatHistoryUpdateService,
    protected override navBarService: NavBarService,
  ) {
    super(router, zone, navBarService);

    this.chats$ = this.getChats();

    this.chats$.subscribe(chats => {
      this.updateLatestChatId(chats);
    });

    this.chatHistoryUpdateService.updates().pipe(
      filter((update: string | undefined) => !update || update !== this.latestChat()),
      switchMap(() => this.getChats().pipe(
        map(newChats => ({ ...newChats, content: [...newChats.content] }))
      )),
      takeUntil(this.destroy$)
    ).subscribe(updatedChats => {
      this.chats$ = of(updatedChats);
      this.updateLatestChatId(updatedChats);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getChats(): Observable<PagedChatMetaData> {
    return this.chatService.pagedChats(0, 14);
  }

  latestChat(): string {
    return this.latestChatId;
  }

  handleSeeAllClick() {
    this.router.navigateByUrl('/my-library');
  }

  handleChatClick(chat: ChatMetaData) {
    this.navigationCacheService.clearData();
    const url = buildRoute(chat);
    this.router.navigateByUrl(url);
  }

  handleMenuClick() {
    this.onNavigation.emit();
  }

  private updateLatestChatId(chats: PagedChatMetaData) {
    if (chats && chats.content.length > 0) {
      this.latestChatId = chats.content[0].id;
    }
  }

  isActiveChat(chat: ChatMetaData): boolean {
    return this.getCurrentPath() === buildRoute(chat);
  }

  getCurrentPath(){
    return location.pathname;
  }
}
