export class PromptSearch {

  page: number
  itemsPerPage: number
  category: string

  constructor(page: number, itemsPerPage: number, category: string) {
    this.page = page
    this.itemsPerPage = itemsPerPage
    this.category = category
  }
}
