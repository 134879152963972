import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'file-manager-confirmation-modal',
  templateUrl: './file-manager-confirmation-modal.component.html',
  styleUrls: ['./file-manager-confirmation-modal.component.css']
})
export class FileManagerConfirmationModalComponent {
  @Input() currentFileType: string = '';
  @Input() newFileType: string = '';
  @Input() headline: string = '';
  @Output() onConfirm: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

  readonly confirmationModalId: string = "file-manager-confirmation-modal";

  confirm() {
    this.onConfirm.emit(this.newFileType);
  }

  cancel() {
    this.onCancel.emit();
  }
}
