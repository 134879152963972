import { Inject, Injectable } from "@angular/core";
import { map, Observable, } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { FileTranslation } from "@app/shared/models/file-translation.model";
import { parseFileNameToDisplayName } from "@services/file-manager/utils/file-utils";
import { FileNameFormatOption } from "@services/file-manager/file-manager.service";

@Injectable({
  providedIn: 'root'
})
export class TranslatedDocumentsListService {
  constructor(@Inject('TRANSLATIONS_API_URL') private translationsUrl: string, private http: HttpClient) {
  }

  get(id: string): Observable<FileTranslation> {
    return this.http.get<FileTranslation>(this.translationsUrl + `/v1/translation/files/${id}`, this.getHeaders());
  }

  getByFileUpload(fileUpload: string): Observable<FileTranslation> {
    return this.http.get<FileTranslation>(this.translationsUrl + `/v1/translation/files/${fileUpload}`, this.getHeaders());
  }

  lazyList(fileName: string = '', offset: number = 0, sortBy: string = "processed", sortDirection: string = "DESC"): Observable<Array<FileTranslation>> {
    const _fileName = `&fileName=${fileName}`;
    const _offset = `?offset=${offset}`;
    const _sortBy = `&sortBy=${sortBy}`;
    const _sortDirection = `&sortDirection=${sortDirection}`;
    return this.http.get<Array<FileTranslation>>(
      this.translationsUrl + `/v1/translation/files/page${_offset}${_sortBy}${_sortDirection}${_fileName}`,
      this.getHeaders()).pipe(
      map((files: FileTranslation[]) => {
        return files.map(file => {
          file.updatedAt = new Date(file.updatedAt!)
          return file;
        })
      })
    );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(this.translationsUrl + `/v1/translation/files/${id}`, this.getHeaders());
  }

  download(id: string): Observable<HttpResponse<Blob>> {
    return this.http.post(this.translationsUrl + `/v1/translation/files/download/${id}`, null, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  downloadMultiple(ids: string[]): Observable<HttpResponse<Blob>> {
    return this.http.post(this.translationsUrl + '/v1/translation/files/download-multiple', ids, {
      responseType: 'blob',
      observe: 'response',
      headers: {
        'Content-type': 'application/json'
      }
    });
  }
  

  downloadFile(id: string): Observable<HttpResponse<Blob>> {
    return this.http.post(this.translationsUrl + '/v1/translation/files/download/' + id, null, {
      responseType: 'blob',
      observe: 'response',
      headers: {
        'Content-type': 'application/json'
      }
    });
  }

  list(excludeStatuses: string[]): Observable<FileTranslation[]> {
    let params = new HttpParams();
    excludeStatuses.forEach(status => {
      params = params.append('excludeStatuses', status);
    });
    return this.http.get<FileTranslation[]>(this.translationsUrl + '/v1/translation/files/list', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    });
  }

  getParseFileNameToDisplayName(name: string, maxFileNameLength: number, formatOption: FileNameFormatOption = FileNameFormatOption.WITH_EXTENSION): string {
    return parseFileNameToDisplayName(name, maxFileNameLength, formatOption);
  }

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }
}
