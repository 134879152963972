import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { featureFlags } from '@app/environments/environment';
declare let Plotly: any;
@Injectable({
  providedIn: 'root'
})
export class PlotlyService {
  protected readonly featureFlags = featureFlags;
  copyInterval: any = {};
  callCount : any = {};
  constructor(private alertService: AlertService) { }


  drawSVGToCanvas(svg: any) {
    const { width, height } = svg.getBoundingClientRect();
    const serializer = new XMLSerializer();
    const copy = svg.cloneNode(true);
    const data = serializer.serializeToString(copy);
    const image = new Image();

    const svgData = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(data)}`;
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      image.addEventListener('load', () => {
        if (ctx) {
          ctx.drawImage(image, 0, 0, width, height);
        }
        resolve(canvas);
      }, { once: true });
      image.src = svgData;
    })
  }

  async convertSVGsToSingleImage(svgs: any, format = 'image/png') {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const drawSVGs = Array.from(svgs).map(svg => this.drawSVGToCanvas(svg))
    const renders = await Promise.all(drawSVGs) as (HTMLImageElement | HTMLCanvasElement | HTMLVideoElement)[];
    canvas.width = Math.max(...renders.map(render => render?.width || 0));
    canvas.height = Math.max(...renders.map(render => render?.height || 0));
    renders.forEach(render => {
      if (render instanceof HTMLImageElement || render instanceof HTMLCanvasElement || render instanceof HTMLVideoElement) {
    
        ctx?.drawImage(render, 0, 0, render.width, render.height);
    
      }
    });
    const source = canvas.toDataURL(format).replace(format, 'image/octet-stream');
    return source;
  }

  plotLine(title: string, plotDiv: string, data: any, Glayout: any){
    let layout = {
      title:title,
      showlegend: true,
      xaxis: {
        automargin: true,
      },
      yaxis: {
        automargin: true,
      }
    };
    if(Glayout){
      layout = Glayout;
      layout.title = title;
      if(!layout.xaxis) {
        layout.xaxis = {automargin: true};
      } else {
        layout.xaxis.automargin = true;
      }
      if(!layout.yaxis) {
        layout.yaxis = {automargin: true};
      } else {
        layout.yaxis.automargin = true;
      }
    }
    if(data && data[0]?.hovertemplate){
      delete data[0]?.hovertemplate;
    }

    let isGLData = this.checkIfItIsGLGraph(data);
    
    data = this.changeGlToNonGL(data);

    let modeBarButtonsToAdd = [
      {
        name: 'Download chart',
        icon: Plotly.Icons.camera,
        click: () => {
          let date = new Date();
          let svgContentEles = document.querySelectorAll('#' + plotDiv + ' .svg-container svg.main-svg'); 
          this.convertSVGsToSingleImage(svgContentEles).then(source => {
              const downloadLink = document.createElement("a");
              downloadLink.href = source; 
              downloadLink.download ='chart_'+ date.getTime() +'.png'; 
              downloadLink.style.display = 'none';
            
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink); 
              this.alertService.showSuccess('Chart downloaded successfully!');
          }, 
          () => {
            this.alertService.showError('Unable to download chart');
          });
        }
      }
    ];

    modeBarButtonsToAdd.push({
      name: 'Copy chart',
      icon: {
        width: 16,
        height: 16,
        path: 'M14 4.7069L11.2743 4.7069L11.2743 2L2 2L2 11.3103L4.73429 11.3103L4.73429 14L14 14L14 4.7069ZM3.71429 9.58621L3.71429 3.72414L9.56 3.72414L9.56 4.7069L4.73429 .7069L4.73429 9.58621L3.71429 9.58621ZM6.41429 12.2759L6.41429 6.43103L12.2857 6.43103L12.2857 12.2759L6.41429 12.2759Z'
      },
      click: () => {
        if(!this.callCount[plotDiv] || this.callCount[plotDiv] === 0 ) {
          let tempContainer = document.createElement('div');
          tempContainer.id = 'tempContainer_' + plotDiv;
          tempContainer.style.position = 'absolute';
          tempContainer.style.left = '-9999px';
          tempContainer.style.whiteSpace = 'pre-wrap';
          document.body.appendChild(tempContainer);
          let svgContentEle = document.querySelector('#' + plotDiv + ' .svg-container svg'); 
          if (svgContentEle) {
            let img1 = document.createElement('img');
            let svgContentEles = document.querySelectorAll('#' + plotDiv + ' .svg-container svg.main-svg'); 
            this.convertSvgToPngDataUri(svgContentEles, img1, tempContainer);
          }
  
          setTimeout(()=> { 
            let cssSelector = '#tempContainer_' + plotDiv + ' img';
            if(document.querySelectorAll(cssSelector).length > 0) {
              if(this.copyInterval[plotDiv]) {
                clearInterval(this.copyInterval[plotDiv]);
                this.copyInterval[plotDiv] = null;
              }
              this.copyFromTemp(tempContainer);
              this.callCount[plotDiv] = 0;
            } else {
              this.copyInterval[plotDiv] = setInterval(() => {
                this.callCount[plotDiv] = this.callCount[plotDiv] ? this.callCount[plotDiv] + 1 : 1;
                if(document.querySelectorAll(cssSelector).length > 0) {
                  this.copyFromTemp(tempContainer);
                  this.callCount[plotDiv] = 0;
                  clearInterval(this.copyInterval[plotDiv]);
                  this.copyInterval[plotDiv] = null;
                }
                if(this.callCount[plotDiv] > 10) {
                  clearInterval(this.copyInterval[plotDiv]);
                  this.copyInterval[plotDiv] = null;
                  this.alertService.showError('Unable to copy');
                }
              }, 300);
            }
          }, 800);
        }
      }
    });

    Plotly.newPlot(plotDiv, data, layout, {
      responsive: true, 
      displaylogo: false,
      modeBarButtonsToRemove: ['toImage'],
      modeBarButtonsToAdd: modeBarButtonsToAdd
    }); 
    
    if(isGLData) {
      Plotly.restyle(plotDiv, 'type', 'scatter', {responsive: true, showlegend: true , displaylogo: false, modeBarButtonsToRemove: ['toImage']});
    }
  }

  copyFromTemp(tempContainer: any) {
    const range = document.createRange();
    range.selectNodeContents(tempContainer);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      this.alertService.showError('Unable to copy');
      return;
    }
    try {
      document.execCommand('copy');
      this.alertService.showSuccess('Copied to clipboard');
    } catch (err) {
      console.error('Failed to copy content:', err);
      this.alertService.showError('Unable to copy');
    }

    selection?.removeAllRanges();
    document.body.removeChild(tempContainer);
  }


  convertSvgToPngDataUri(svgDataEles: any, image: any, tempContainer: any): any {
      this.convertSVGsToSingleImage(svgDataEles).then(source => {
      image.addEventListener('load', () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(image, 0, 0);
          const pngDataUri = canvas.toDataURL('image/png');
          image.src = pngDataUri;
          tempContainer.appendChild(image);
        } else {
          console.error('Failed to convert SVG to PNG');
        }
      })
      image.src = source;
    });
  }

  changeGlToNonGL(data: any) {
    if(data[0] && data[0].type) {
      switch(data[0].type.toLowerCase()){
        case 'scattergl': 
              data[0].type = 'scatter';
              break;
        case 'heatmapgl': 
              data[0].type = 'heatmap';
              break;
        case 'scatter3d': 
              data[0].type = 'scatter';
              break;
        case 'pointcloud': 
              data[0].type = 'scatter';
              break;
        case 'parcoords': 
              data[0].type = 'scatter';
              break;
        case 'surface': 
              data[0].type = 'heatmap';
              break;
        case 'mesh3d': 
              data[0].type = 'scatterternary';
              break;
        case 'line3d': 
              data[0].type = 'line';
              break;
        case 'cone': 
              data[0].type = 'scatterpolar';
              break;
        case 'streamtube': 
              data[0].type = 'scatterternary';
              break;
        case 'volume': 
              data[0].type = 'heatmap';
              break;
      }
    } 
    return data;
  }

  checkIfItIsGLGraph(data: any) {
    if(data[0] && data[0].type) {
      let webGlGraphs = ['scattergl', 'heatmapgl', 'scatter3d', 'pointcloud', 'parcoords', 'surface', 'mesh3d', 'line3d', 'cone', 'streamtube', 'volume'];
      return webGlGraphs.indexOf(data[0].type.toLowerCase()) !== -1;
    }
    return false;
  }
  
  restyle(plotDiv: string, type: string) {
    Plotly.restyle(plotDiv, 'type', type, {responsive: true, displaylogo: false, modeBarButtonsToRemove: ['toImage'], showlegend: true});
  }
}
