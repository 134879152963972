import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PromptService } from "@services/prompt/prompt.service";
import { PromptSearch } from "@shared/models/prompt-search.model";
import { PagedPrompts } from "@shared/models/pagedprompts.model";
import { Prompt } from "@shared/models/prompt.model";
import { KmdModalService, KmdPaginationComponent } from "gds-atom-components";
import { catchError, mergeMap, Observable } from "rxjs";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { Model } from "@shared/models/prompt-settings.model";

@Component({
  selector: 'app-public-paged-prompts',
  templateUrl: './public-paged-prompts.component.html',
  styleUrls: ['./public-paged-prompts.component.css', '../shared/styles/prompt-table.css'],
  encapsulation: ViewEncapsulation.None
})
export class PublicPagedPromptsComponent implements AfterViewInit {
  @ViewChild('pagination', { static: false }) pagination!: KmdPaginationComponent;
  @Output() promptSelectEvent = new EventEmitter<Prompt>();

  categories: string[] = [];
  selectedCategory!: string;
  showErrorAlert: boolean = false;
  showSuccessAlert: boolean = false;
  alertText: string = '';
  isLoading = true;
  emptyPublicPrompts: boolean = false;
  pagedPublicPrompts: PagedPrompts = {
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: undefined,
    size: 0,
    sort: undefined,
    totalPages: 0,
    totalElements: 0,
    content: []
  }
  promptTableHeaders: string[] = ["Title", "Model", "Web search", ""];
  selectedPrompt?: Prompt;
  canApprove$: Observable<boolean>;

  constructor(private promptService: PromptService, private cd: ChangeDetectorRef, private kmdModalService: KmdModalService, public oidcSecurityService: OidcSecurityService) {
    this.canApprove$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return result.userData.roles.includes('prompt-request-approver')
      })
    );
  }

  ngAfterViewInit(): void {
    let promptCategoriesObservable = this.promptService.getPublicPromptCategories();
    promptCategoriesObservable.pipe(
      mergeMap(categories => {

        if (categories.length === 0) {
          this.emptyPublicPrompts = true;
          this.isLoading = false;
          return [];
        }

        this.categories = categories.sort();
        this.selectedCategory = categories[0];
        let promptSearch = new PromptSearch(0, this.pagination.resultsPerPage, categories[0]);
        return this.promptService.getPageablePublicPrompts(promptSearch);
      }),
      catchError(() => {
        this.showErrorAlert = true;
        this.alertText = "Unable to load categories.";
        this.emptyPublicPrompts = true;
        return [];
      })
    ).subscribe({
      next: pagedPublicPrompts => {
        this.isLoading = false;
        this.pagedPublicPrompts = pagedPublicPrompts;
      }
    })

    this.cd.detectChanges();
  }

  getUserFriendlyModelName(model: Model) {
    if (model === "gpt-4-o") {
      return "GPT-4o";
    } else {
      return "";
    }
  }

  getUserFriendlyWebSearchConfig(webSearch: boolean): string {
    return webSearch ? "Yes" : "No";
  }

  openModal(prompt: Prompt, id: string) {
    this.kmdModalService.open(id);
    this.selectedPrompt = prompt;
  }

  closeModal(id: string) {
    this.kmdModalService.close(id);
  }

  onSelect(prompt: Prompt) {
    this.promptSelectEvent.emit(prompt);
  }

  onCategoryChange(event: any) {
    let category = event.selectedOption
    let promptSearch = new PromptSearch(0, this.pagination.resultsPerPage, category)
    this.updatePrompts(promptSearch);
  }

  onPageChange(event: any) {
    let promptSearch = new PromptSearch(event.currentPage - 1, event.resultsPerPage, this.selectedCategory)
    this.updatePrompts(promptSearch);
  }

  updatePrompts(promptSearch: PromptSearch) {
    this.isLoading = true;
    this.promptService.getPageablePublicPrompts(promptSearch).subscribe({
      next: value => {
        this.pagedPublicPrompts = value;
      },
      error: () => {
        this.showErrorAlert = true;
        this.alertText = "Unable to load prompts.";
        this.emptyPublicPrompts = true;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  getPublicPromptsByCategory(category: string) {
    this.isLoading = true;
    let promptSearch = new PromptSearch(0, 10, category);
    this.promptService.getPageablePublicPrompts(promptSearch).subscribe({
      next: publicPrompts => {
        this.isLoading = false;
        this.pagedPublicPrompts = publicPrompts;
      }
    })
  }

  deletePrompt(prompt: Prompt) {
    this.kmdModalService.close('delete-prompt-modal');
    this.promptService.deletePublicPrompt(prompt.id!).subscribe({
      next: () => {
        this.getPublicPromptsByCategory(this.selectedCategory);
        this.showSuccessAlert = true;
        this.alertText = "Prompt deleted";
      },
      error: () => {
        this.showErrorAlert = true;
        this.alertText = "The prompt could not be deleted. There was an issue contacting the server.";
      }
    })
  }

  onDelete(prompt: Prompt, id: string) {
    this.kmdModalService.open(id);
    this.selectedPrompt = prompt;
  }

}
