import { Injectable } from "@angular/core";
import { FileManagerService } from "../file-manager/file-manager.service";
import { NavigationStart, Router } from "@angular/router";
import { ChatQAService } from "../chat-qa/chat-qa.service";
import { filter } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NavigationCacheService {
    
    constructor(
        protected router: Router,
        private chatQAService: ChatQAService,
        private fileManagerService: FileManagerService
    ) {
        router.events.pipe(
            filter((event: any) => event instanceof NavigationStart),
            filter((event: NavigationStart) => !event.url.endsWith('/chat') && !event.url.includes('/ask-gene'))
        ).subscribe({
            next: () => this.clearData()
        });        
    }

    public clearData(): void {
        this.chatQAService.clearActiveChat();
        this.fileManagerService.reset();
    };
};