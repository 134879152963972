import { AbstractControl, ValidationErrors } from "@angular/forms";

export class NoDuplicatesValidator {
    static validate(control: AbstractControl): null | ValidationErrors {
        const values = control.value as string[];

        if (values && Array.isArray(values)) {
            const valueCounts = values
                .map(value => value.toLowerCase())
                .reduce((acc, value) => {
                    acc[value] = (acc[value] || 0) + 1;
                    return acc;
                }, {} as Record<string, number>);

            const duplicateValues = Object.keys(valueCounts)
                .filter(value => valueCounts[value] > 1);

            if (duplicateValues.length > 0) {
                return { duplicateValues: duplicateValues };
            }
        }

        return null;
    };
};
