import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BetaGuard } from "@app/core/guards/beta.guard";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { GuardCheckerService } from "@app/core/guard-checkers/guard-checker-service";
import { MenuOption } from "@shared/models/navbar/menu-option";

@Component({
  selector: 'app-menu-option',
  templateUrl: './menu-option.component.html',
  styleUrls: ['./menu-option.component.css']
})
export class MenuOptionComponent {
  @Input() menuOption!: MenuOption;
  @Input() isSubOption: boolean = false;
  @Output() subMenuExpandedEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private betaGuard: BetaGuard,
    private router: Router,
    private guardCheckerService: GuardCheckerService,
  ) {
  }

  toggleExpanded() {
    if (!this.menuOption.submenu) return;
    this.menuOption.submenu.isExpanded = !this.menuOption.submenu.isExpanded;
    this.subMenuExpandedEvent.emit(this.menuOption.id);
  }

  navigateTo(subMenuOption: MenuOption) {
    if (!subMenuOption.anchor) return;
    if (!this.router.url.includes(subMenuOption.anchor.value)) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([subMenuOption.anchor?.value]));
    }
  }

  shouldDisplaySubOption(option: MenuOption): Observable<boolean> {
    if (option.featureFlag?.every(flag => flag === false)) {
      return of(false);
    }
    return this.guardCheckerService.areAllOptionGuardsPassed(option);
  }
}
