import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { GuardCheckerService } from '@app/core/guard-checkers/guard-checker-service';
import { AskOurDocsRoleGuard } from '@app/core/guards/ask-our-docs-role.guard';
import { SVA2RoleGuard } from "@app/core/guards/sva2-role.guard";
import { Observable, of } from 'rxjs';
import { getMenuOptions } from '@shared/constants/navbar/menu-options';
import {
  NavBarScreenResolutionSetting,
  NavBarScreenResolutionSettings
} from '@shared/constants/navbar/screen-resolutions';
import { MenuOption } from "@shared/models/navbar/menu-option";
import { BetaGuard } from "@app/core/guards/beta.guard";
import { DallEGuard } from '@app/core/guards/dall-e.guard';
import { NavbarState } from "@shared/models/navbar/navbar-state";
import { ChatHistoryUpdateService } from '@app/core/chat-history-update/chat-history-update.service';
import { AskOurDataRoleGuard } from "@app/core/guards/ask-our-data-role.guard";
import { NavBarService } from '@app/core/services/navbar/navbar.service';

@Component({
  selector: 'navbar-new',
  templateUrl: './navbar-new.component.html',
  styleUrls: ['./navbar-new.component.css']
})
export class NavbarNewComponent implements OnInit {
  @Input() navMenuToggleId?: string = 'navMenuToggleButton';

  isCollapsed: boolean = true;
  isHidden: boolean = true;

  currentScreenResolutionSetting: NavBarScreenResolutionSetting = NavBarScreenResolutionSettings.mobile;
  menuOptions: MenuOption[];
  feedbackUrl: string = 'https://forms.office.com/Pages/ResponsePage.aspx?id=LXJ9toqqd0ehaevremo7Z9qQeGTbQTtBqZnILeRlahNURVlMSTRBMVBHQ1k4TUNTSjdUSEIzQjhXUy4u';
  aboutUrl: string = 'https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Gene.AI/Gene.AI.aspx';

  constructor(
    private router: Router,
    private askOurDocsRoleGuard: AskOurDocsRoleGuard,
    private sva2RoleGuard: SVA2RoleGuard,
    private guardCheckService: GuardCheckerService,
    private eRef: ElementRef,
    private betaGuard: BetaGuard,
    private dallEGuard: DallEGuard,
    private chatHistoryUpdateService: ChatHistoryUpdateService,
    private askOurDataRoleGuard: AskOurDataRoleGuard,
    private navBarService: NavBarService
  ) {
    this.menuOptions = getMenuOptions(this.askOurDocsRoleGuard, this.sva2RoleGuard, this.betaGuard, this.dallEGuard, this.askOurDataRoleGuard);
    this.navBarService.isCollapsed$.subscribe(isCollapsed => this.isCollapsed = isCollapsed);
    this.navBarService.isHidden$.subscribe(isHidden => this.isHidden = isHidden);
    this.navBarService.currentScreenResolutionSetting$.subscribe(setting => this.currentScreenResolutionSetting = setting);
  }


  ngOnInit(): void {
    this.navBarService.setScreenResolution(window.innerWidth);
  }

  isActiveRoute(route: string): boolean {
    const matchOptions: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'exact',
      matrixParams: 'exact',
      fragment: 'exact'
    };

    return this.router.isActive(route, matchOptions);
  };

  handleMenuClick(): void {
    this.navBarService.handleMenuClick();
  };

  toggleNavbarStatus(): void {
    this.navBarService.resetNavbarState();
  };

  changeRoute(route: string): void {
    this.router.navigate([route]);
  };

  showMenuOption$(option: MenuOption): Observable<boolean> {
    if (option.featureFlag?.every(flag => flag === false)) {
      return of(false);
    }

    return this.guardCheckService.areAllOptionGuardsPassed(option);
  };

  isMenuOptionTextVisible(): boolean {
    const [newChatText] = this.menuOptions;
    const menuItemLabel = newChatText.label;
    return !!Array.from(document.querySelectorAll('div')).find(el => el.textContent === menuItemLabel);
  }

  @HostListener('window:resize', ['$event'])
  setScreenResolution() {
    this.navBarService.setScreenResolution(window.innerWidth);
  }

  @HostListener('document:click', ['$event'])
  clickListener(event: any) {
    const isMenuClick = document.querySelector(`#${this.navMenuToggleId}`)!.contains(event.target);
    const isOutsideClick = !this.eRef.nativeElement.contains(event.target);
    const isMenuOptionTextVisible = this.isMenuOptionTextVisible();

    if (isMenuClick && !isMenuOptionTextVisible) {
      this.chatHistoryUpdateService.notifyChatUpdated(undefined);
    }

    this.navBarService.handleClick(isMenuClick, isOutsideClick, isMenuOptionTextVisible, window.innerWidth);
  };

  navBarStatus(): NavbarState {
    if (this.isHidden) {
      return 'hidden';
    } else if (this.isCollapsed) {
      return 'collapsed';
    } else {
      return 'open';
    }
  }

  isMobileScreen(): boolean {
    return NavBarScreenResolutionSettings.mobile.size >= window.innerWidth;
  }
}
