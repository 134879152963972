import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from "@services/modal/modal.service";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() showOverlay = true;
  @Input() closeOnOutsideClick = true;
  @Input() showCloseIcon = true;
  @Input() headline?: string;
  @Input() subHeadline?: string;
  @Output() onModalClose = new EventEmitter<void>();

  isVisible = false;
  private modalSubscription!: Subscription;

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
    this.modalService.registerModal(this.id);
    this.modalSubscription = this.modalService.getModalState(this.id)
      ?.subscribe(visible => this.isVisible = visible);
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  close() {
    this.onModalClose.emit();
    this.modalService.close(this.id);
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey() {
    this.close();
  }
}
