import { Message } from "./message.model";
import { PromptSettings } from "./prompt-settings.model";

export class Chat {
  id?: string;
  title: string;
  created: Date;
  messages: Array<Message> = [];
  gptEnabled: boolean = false;
  promptSettings: PromptSettings = new PromptSettings(3, 'PROFESSIONAL', false);
  type?: string;
  updatedAt?: Date;

  constructor(title: string, created: Date) {
    this.title = title
    this.created = created
  }

}
