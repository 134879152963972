<form [formGroup]="agentForm" class="form-container">
  <div class="form-item">
    Name <span class="required-mark">*</span>
    <input kmdFormInput formControlName="name"/>
    <span class="agent-field-name-error">Agent name is already in use, please pick a new name.</span>
  </div>
  <div class="form-item">
    Description <span class="required-mark">*</span>
    <span class="form-label"><br>Add a short description about what this Agent does</span>
    <input kmdFormInput formControlName="description"/>
  </div>
  <div class="form-item">
    Conversation Starter
    <span class="form-label"><br>Create an example to start the conversation with your agent</span>
    <ng-container formArrayName="conversationStarters">
      <input kmdFormInput
             formControlName="0"/>
    </ng-container>
  </div>
  <div class="form-item">
    Instructions <span class="required-mark">*</span>
    <span class="form-label"><br>What does this Agent do? How does it behave? What should it avoid doing?</span>
    <textarea kmdFormTextarea id="agent-instructions-area" formControlName="instructions" cols="30" rows="6"></textarea>
  </div>

  <div class="form-item">
    Select a Category <span class="required-mark">*</span>
    <span class="form-label"><br>Select the category that best fits your agents.</span>
    <kmd-dropdown
      id="agent-categories-dropdown"
      formControlName="category"
      optionLabelKey="agent"
      [options]="categories"
      [externalLabel]="true">
    </kmd-dropdown>
  </div>
  <div class="form-item">
    <div class="form-item-horizontal">
      <kmd-toggle
        id="web-search-enabled-toggle"
        [active]="webSearchEnabled()"
        (toggle)="switchToggle()">
      </kmd-toggle>
      <div class="websearch-toggle-labels">
        <div class="label">Web search</div>
        <span>Enable your agent to utilize web resources for responses.</span>
      </div>
    </div>
    <div class="disclaimer-container">
      <span class="disclaimer-span">*Upon creation of an agent, your <b>name</b> and the <b>agent ID</b> is collected and shared with <b>IT administrator</b> to help enforce <b>compliance</b> with the Gene.AI guidelines and for <b>quality assurance purposes</b>.</span>
    </div>
  </div>

  <div class="agent-additional-capabilities" *ngIf="!isAgentPublicAndPublishedByUser()">
    <ng-container *featureFlag="'agentChatNew'; else additionalTools">
      <div class="additional-capabilities-title">
        <h4 class="agent-capability-title">Additional capabilities</h4>
        <span class="additional-capability-optional-label">(Optional)</span>
      </div>
      <p class="additional-capabilities-disclaimer">
        Adding documents to your Agent will enable better quality of response including citations.
        <a href="https://thermofisher.sharepoint.com/:u:/r/sites/GenerativeAICenterofExcellence/SitePages/Agents.aspx" target="_blank">Learn more.</a>
      </p>
    </ng-container>
    <ng-template #additionalTools>
      <div class="additional-capabilities-title">
        <h4 class="agent-capability-title">Additional Tools</h4><span class="additional-capability-optional-label">(Optional)</span>
      </div>
      <p class="additional-capabilities-disclaimer">
        Empower your agent with additional tools and functionality to respond your queries,
        noting that once enabled, the agent cannot be shared. Be sure to <b>select at least one document
        or repository</b> when using the tools below. </p>
    </ng-template>

    <div class="form-item form-item-horizontal input-container">
      <div class="amd-labels">
        <input
          id="ask-my-docs-checkbox"
          class="amd-checkbox"
          type="checkbox"
          (click)="toggleAskMyDocs()"
          [checked]="isAskMyDocsEnabled()" />
        <ng-container *featureFlag="'agentChatNew'; else askMyDocsLabel">
          <label for="ask-my-docs-checkbox">Add files to your Agent's documents (max 10 files)</label>
        </ng-container>
        <ng-template #askMyDocsLabel>
          <label for="ask-my-docs-checkbox">Ask My Docs (Recommended maximum of 10)</label>
        </ng-template>
      </div>
      <div class="capability-dropdown-container" *ngIf="isAskMyDocsEnabled()">
        <ng-container *featureFlag="'agentSharing'; else textPdfLabel">
          <div class="capability-dropdown-section">
            Select documents<span class="required-mark">*</span>
          </div>
        </ng-container>
        <ng-template #textPdfLabel>
          <div class="capability-dropdown-section">
            Select documents (text/pdf)<span class="required-mark">*</span>
          </div>
        </ng-template>
        <kmd-multi-select-dropdown
          id="ask-my-docs-files-dropdown"
          class="multiselect-width"
          optionLabelKey="name"
          [options]="files"
          [(ngModel)]="selectedFiles"
          [ngModelOptions]="{standalone: true}"
          [externalLabel]="true"
          (onChange)="fileSelectionChange($event)">
        </kmd-multi-select-dropdown>
      </div>
    </div>
    <div class="form-item form-item-horizontal input-container" *ngIf="showAODTool">
      <div class="aod-labels">
        <input
          id="ask-our-docs-checkbox"
          class="aod-checkbox"
          type="checkbox"
          (click)="toggleAskOurDocs()"
          [checked]="isAskOurDocsEnabled()"
          [disabled]="isSharedTypeAgent()" />
        <ng-container *featureFlag="'agentChatNew'; else askOurDocsLabel">
          <label for="ask-our-docs-checkbox">Ask our Docs (only for personal agents)</label>
        </ng-container>
        <ng-template #askOurDocsLabel>
          <label for="ask-our-docs-checkbox">Ask Our Docs</label>
        </ng-template>
      </div>
      <div class="capability-dropdown-container" *ngIf="isAskOurDocsEnabled()">
        <div class="capability-dropdown-section">
          Select repository <span class="required-mark">*</span>
        </div>
        <kmd-multi-select-dropdown
          id="ask-our-docs-dropdown"
          class="multiselect-width"
          [options]="repositories"
          [(ngModel)]="selectedRepositories"
          [ngModelOptions]="{standalone: true}"
          (onChange)="repositorySelectionChange($event)"
          optionLabelKey="name"
          [externalLabel]="true">
        </kmd-multi-select-dropdown>
      </div>
    </div>

    <core-notification
      label='Note: '
      message='Using tools from your Agent can increase response time due to the extensive search required.'
      variant='inline'
      state='information'
      timer='0'
      nocloseicon
      nostateicon
    ></core-notification>
  </div>
</form>
