import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
import { catchError, switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { AskOurDocsV2Service } from '@app/core/services/ask-our-docs-v2/ask-our-docs-v2.service';

@Component({
  selector: "app-feedback-form",
  templateUrl: "./feedback-form.component.html",
  styleUrls: ["./feedback-form.component.css"],
})
export class FeedbackFormComponent implements OnInit {
  feedbackForm!: FormGroup;
  showForm: boolean = true;
  formSubmitted = false;
  showSuccessMessage = false;
  options: any[] = [];
  feedbackData: any = [];
  showTextarea: boolean = false;

  @Input() answerHelpful: boolean = false;
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() hideButtons: boolean = false;
  @Input() showMCQ: boolean = false;
  @Input() conversation: any = {};
  @Input() userEmail: string = '';
  @Input() indexName: string = '';
  @Input() currentIndex: number = 0;
  @Output() userFeedbackFormSubmitted: EventEmitter<any> = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private askOurDocsV2Service: AskOurDocsV2Service) { }


  ngOnInit(): void {
    this.askOurDocsV2Service.getFeedbackFormData(this.indexName).pipe(
      switchMap((data: any) => {
        if (data?.detail === "404: Feedback form data not found") {
          return this.askOurDocsV2Service.getDefaultFeedbackForm();
        } else {
          return of(data);
        }
      }),
      catchError(error => {
        // Check if the error response contains the expected data
        if (error.error && error.error.detail === "404: Feedback form data not found") {
          return this.askOurDocsV2Service.getDefaultFeedbackForm();
        }
        return throwError(error);
      })
    ).subscribe(
      (data: any) => {
        this.feedbackData = data.questions;
        this.feedbackData = this.feedbackData.sort((a: any, b: any) => a.questionOrder - b.questionOrder);
        this.feedbackData = this.feedbackData.map((question: any) => {
          if (question.questionType === 'Multiple choice' || question.questionType === 'Single choice') {
            return { ...question, 'isOtherOptionChecked': false };
          }
          return question;
        });
        this.initializeForm();
      },
      (error: any) => {
        console.error('Error occurred:', error);
      }
    );
  }

  initializeForm(): void {
    this.feedbackForm = this.fb.group({
      responses: this.fb.array([]),
    });

    this.feedbackData.forEach((question: any) => {
      let questionGroup: FormGroup = this.fb.group({
        questionId: [question.questionOrder],
        questionText: [question.questionText],
        questionType: [question.questionType],
        isRequired: [question.isRequired]
      });

      switch (question.questionType) {
        case 'Linear scale':
          questionGroup.addControl('startValue', this.fb.group({
            value: [question.startValue.value || null, question.isRequired ? Validators.required : null],
            text: [question.startValue.text || '']
          }));
          questionGroup.addControl('endValue', this.fb.group({
            value: [question.endValue.value || null, question.isRequired ? Validators.required : null],
            text: [question.endValue.text || '']
          }));
          questionGroup.addControl('answer', new FormControl(null, question.isRequired ? Validators.required : null));
          break;
        case 'Multiple choice':
          questionGroup.addControl('answers', this.fb.array(question.options.map(() => new FormControl(false))));
          if (question.otherOption) {
            questionGroup.addControl('isOtherOptionChecked', new FormControl(false));
            questionGroup.addControl('otherAnswerText', new FormControl(''));
          }
          break;
        case 'Long text':
          questionGroup.addControl('answer', new FormControl('', question.isRequired ? Validators.required : null));
          break;
        case 'Single choice':
          questionGroup.addControl('answer', new FormControl(null, question.isRequired ? Validators.required : null));
          break;
      }

      (this.feedbackForm.get('responses') as FormArray).push(questionGroup);
    });

  }

  get responses(): FormArray {
    return this.feedbackForm.get('responses') as FormArray;
  }

  generateRange(start: number, end: number): number[] {
    const range: number[] = [];
    if (start <= end) {
      for (let i = start; i <= end; i++) {
        range.push(i);
      }
    } else {
      for (let i = start; i >= end; i--) {
        range.push(i);
      }
    }
    return range;
  }

  onCheckboxChange(event: Event, option: string) {
    const checkbox = event.target as HTMLInputElement;
    if (option === "Other - I will explain") {
      this.showTextarea = checkbox.checked;
      if (!checkbox.checked) {
        this.feedbackForm.get('otherExplanation')?.setValue('');
      }
    }
  }

  otherOptionCheckboxChange(question: any) {
    const responsesArray = this.feedbackForm.get('responses') as FormArray;
    const responseGroup = responsesArray.at(question.questionOrder - 1) as FormGroup;
    const isOtherOptionChecked = responseGroup.get('isOtherOptionChecked') as FormControl;
  
    isOtherOptionChecked.setValue(!isOtherOptionChecked.value);
  
    if (!isOtherOptionChecked.value) {
      responseGroup.get('otherAnswerText')?.setValue('');
    }
  }

  onCancel(): void {
    this.feedbackForm.reset();
  }

  onCancelForm() {
    this.feedbackForm.reset();
    this.closeForm.emit(true);
  }

  resetForm(): void {
    this.feedbackForm.reset();
    this.formSubmitted = false;
    this.showForm = false;
  }

  setThumbValue(value: boolean): void {
    this.answerHelpful = value;
    this.showForm = true;
  }

  resetAdditionalOptions(): void {
    const optionsArray = this.feedbackForm.get("options") as FormArray;
    optionsArray.controls.forEach((control) => control.setValue(false));
    this.feedbackForm.patchValue({
      generalFeedback: "",
    });
  }

  get optionsArray(): FormArray {
    return this.feedbackForm.get("options") as FormArray;
  }

  showImprovementField(): boolean {
    const qualityOfResponse = this.feedbackForm.get("qualityOfResponse")?.value;
    const optionsArray = this.feedbackForm.get("options") as FormArray;
    return (
      qualityOfResponse >= 4 ||
      optionsArray.controls.some((control) => control.value)
    );
  }

  formatTimestamp(date: Date): string {
    const pad = (num: number) => num.toString().padStart(2, "0");
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}`;
  }

  isFormValid(): boolean {
    if (!this.feedbackForm.valid) {
      return false;
    }

    const responsesArray = this.feedbackForm.get('responses') as FormArray;
    for (let i = 0; i < responsesArray.length; i++) {
      const responseGroup = responsesArray.at(i) as FormGroup;
      if (responseGroup.get('isRequired')?.value) {
      const questionType = responseGroup.get('questionType')?.value;
      switch (questionType) {
        case 'Linear scale':
        if (!responseGroup.get('answer')?.value) {
          return false;
        }
        break;
        case 'Multiple choice':
        {
          const answersArray = responseGroup.get('answers') as FormArray;
          const isOtherOptionChecked = responseGroup.get('isOtherOptionChecked');
          const otherAnswerText = responseGroup.get('otherAnswerText');

          if (!answersArray.controls.some(control => control.value) && !isOtherOptionChecked?.value) {
            return false;
          }
          if (isOtherOptionChecked?.value && !otherAnswerText?.value) {
            return false;
          }
        }
        
        break;

        case 'Long text':
        if (!responseGroup.get('answer')?.value) {
          return false;
        }
        break;
        case 'Single choice':
        if (!responseGroup.get('answer')?.value) {
          return false;
        }
        break;
      }
      }
    }

    return true;
  }

  onSubmitFeedback(): void {
    if (this.feedbackForm.valid) {
      const formValue = this.feedbackForm.value;

      const responses = formValue.responses.map((response: any, i: number) => {
        if (response.questionType === 'Multiple choice') {
          const transformedAnswers = response.answers
            .map((checked: boolean, j: number) => checked ? this.feedbackData[i].options[j].optionText : null)
            .filter((value: string | null) => value !== null);

          if (this.showTextarea && response.otherAnswerText) {
            return { ...response, answers: transformedAnswers, otherAnswerText: response.otherAnswerText };
          } else {
            return { ...response, answers: transformedAnswers };
          }
        } else {
          return response;
        }
      });

      const submissionData = {
        answerHelpful: this.answerHelpful,
        timeStamp: this.formatTimestamp(new Date()),
        conversationMessages: this.conversation.messages.slice(0, this.currentIndex + 2),
        indexName: this.indexName,
        user: this.userEmail,
        responses,
      };

      this.formSubmitted = true;
      this.showSuccessMessage = true;
      this.showForm = false;
      this.onCancel();

      this.askOurDocsV2Service.saveFeedback(submissionData, this.conversation.id);
      this.userFeedbackFormSubmitted.emit(true)
    }
  }

  closeSuccessMessage() {
    this.showSuccessMessage = false;
    this.closeForm.emit(true);
  }

  setFormValues(): void {
    this.feedbackData.forEach((question: any, index: number) => {
      const responseControl = (this.feedbackForm.get('responses') as FormArray).at(index);
      if (question.questionType === 'Linear scale') {
        responseControl.get('startValue.value')?.setValue(question.startValue.value);
        responseControl.get('endValue.value')?.setValue(question.endValue.value);
      }
    });
  }

  onSingleChoiceOptionClick(question: any): void {
    question.isOtherOptionChecked = false;
  }
}
