import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Prompt } from '@shared/models/prompt.model';
import { PromptService } from '@services/prompt/prompt.service';
import { KmdModalService } from 'gds-atom-components';
import { SharedPromptCategory } from '@shared/models/shared-prompt-category.model';

@Component({
  selector: 'app-share-prompt',
  templateUrl: './share-prompt.component.html',
  styleUrls: ['./share-prompt.component.css']
})
export class SharePromptComponent {
  options: string[] = ["Coding", "Customer Service", "Design", "Marketing", "Other", "Productivity", "Quality", "R&D", "Human Resources", "PPI"].sort();

  selectedValue: string = "";

  @Input()
  prompt!: Prompt

  @Output() shareValuesChange = new EventEmitter<{ alertType: string, alertText: string }>();

  guidelinesUrl = '/guidelines';

  constructor(private promptService: PromptService,
              private kmdModalService: KmdModalService) {
  }

  share() {
    let sharedPromptCategory = new SharedPromptCategory(this.selectedValue);

    this.promptService.sharePrompt(this.prompt.id!, sharedPromptCategory).subscribe({
      next: () => {
        this.shareValuesChange.emit({
          alertType: "success",
          alertText: "Your prompt is now available under Public Prompts."
        });
        this.kmdModalService.close('share-prompt-modal');
      },
      error: ()=> {
        this.shareValuesChange.emit({
          alertType: "alert",
          alertText: "Your prompt can't be shared to Public Prompts. There was an issue contacting the server."
        });
        this.kmdModalService.close('share-prompt-modal');
      }
    });
  }

  close() {
    this.kmdModalService.close('share-prompt-modal');
  }
}
