import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChipTypeConstant } from '@app/shared/constants/chip/chip-type';

@Component({
  selector: 'app-file-type-button',
  templateUrl: './file-type-button.component.html',
  styleUrl: './file-type-button.component.css',
})
export class FileTypeButtonComponent {

  @Input() label: string = '';
  @Input() isSelected: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isBeta: boolean = false;
  @Output() fileTypeSelected = new EventEmitter<void>();
  betaBadgeDetails = ChipTypeConstant.BETA;
  
}
