import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component'
import { ErrorComponent } from './error/error.component'
import { SigninComponent } from './signin/signin.component'
import { SignOutComponent } from "src/app/sign-out/sign-out.component";
import { AskGeneComponent } from './ask-gene/ask-gene.component'
import { AboutUsComponent } from './about-us/about-us.component';
import { AutoLoginPartialRoutesGuard } from "angular-auth-oidc-client";
import { TermsAndConditionsGuard } from "./core/guards/terms-and-conditions.guard";
import { FeatureFlagGuard } from './core/guards/feature-flag.guard';
import { FileUploaderComponent } from "./file-uploader/file-uploader.component";
import { CapabilitiesComponent } from './capabilities/capabilities.component';
import { DocumentQueryComponent } from './document-query/document-query.component';
import { IframeApjComponent } from "./iframe-apj/iframe-apj.component";
import { TranslationsComponent } from './tools/translations/translations.component';
import { SVA2RoleGuard } from './core/guards/sva2-role.guard';
import { AskOurDocsChatComponent } from "./ask-our-docs/ask-our-docs-chat/ask-our-docs-chat.component";
import { BetaGuard } from "./core/guards/beta.guard";
import { AskOurDocsRoleGuard } from "./core/guards/ask-our-docs-role.guard";
import { AskOurDataRoleGuard } from "./core/guards/ask-our-data-role.guard";
import { ImageCreationComponent } from "./image-creation/image-creation.component";
import { SocialMediaComponent } from "./tools/social-media/social-media.component";
import { AgentListComponent } from "./agents/agent-list/agent-list.component";
import { AgentChatComponent } from "./agents/agent-chat/agent-chat.component";
import { DallEGuard } from "./core/guards/dall-e.guard";
import { AgentEditComponent } from "./agents/agent-edit/agent-edit.component";
import { AgentBuilderComponent } from "./agents/agent-builder/agent-builder.component";
import { UnsavedChangesGuard } from "./core/guards/unsaved-changes-guard.service";
import { OidcIdentityRoleResolver } from './core/resolvers/oidc-identity-role.resolver';
import { AddToMyAgentsComponent } from './agents/public-agents/add-to-my-agents/add-to-my-agents.component';
import { AgentResolver } from './core/resolvers/agent.resolver';
import { AskOurDocsPageComponent } from "@app/ask-our-docs/ask-our-docs-page/ask-our-docs-page.component";
import {
  AskOurDocsUserManagementPageComponent
} from "@app/ask-our-docs/ask-our-docs-user-management-page/ask-our-docs-user-management-page.component";
import { ChatWithDbComponent } from './ask-our-data/chat-with-db/chat-with-db.component';
import { AskOurDocsGroupAdminGuard } from "@app/core/guards/ask-our-docs-group-admin.guard";
import { featureFlags } from "@environments/environment";
import { AskGeneNewComponent } from "@app/ask-gene-new/ask-gene-new.component";
import { ChatNewComponent } from './chat-new/chat-new.component';
import { MyLibraryComponent } from "@app/my-library/my-library.component";
import { SessionGuard } from './core/guards/session.guard';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'signin' },
  {
    path: 'ask-gene',
    component: featureFlags.uxRevamp ? AskGeneNewComponent : AskGeneComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard]
  },
  { path: 'ask-gene/:id', redirectTo: 'chat/:id' },
  { path: 'chat', component: ChatNewComponent, canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard] },
  {
    path: 'chat/:id',
    component: ChatNewComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard]
  },
  { path: 'signin', component: SigninComponent, canActivate: [SessionGuard] },
  { path: 'sign-out', component: SignOutComponent },
  { path: 'guidelines', component: TermsandconditionsComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  { path: 'error', component: ErrorComponent },
  {
    path: 'uploader',
    component: FileUploaderComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard]
  },
  { path: 'about', component: AboutUsComponent, canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard] },
  {
    path: 'capabilities',
    component: CapabilitiesComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'capabilities' }
  },
  {
    path: 'document-query',
    component: DocumentQueryComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard]
  },
  {
    path: 'document-query/:id',
    component: DocumentQueryComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard]
  },
  {
    path: 'tools/translations',
    component: TranslationsComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'languageTranslation' }
  },
  {
    path: 'tools/marketing-ads',
    component: SocialMediaComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'socialMedia' }
  },
  {
    path: 'tools/marketing-ads/:id',
    component: SocialMediaComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'socialMedia' }
  },
  {
    path: 'agents',
    component: AgentListComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'agents' },
    resolve: { oidcRoles: OidcIdentityRoleResolver }
  },
  {
    path: 'agents/builder',
    component: AgentBuilderComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'agents' }
  },
  {
    path: 'agents/:agentId/chat',
    component: AgentChatComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'agents' }
  },
  {
    path: 'agents/:agentId/edit',
    component: AgentEditComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'agents' }
  },
  {
    path: 'agents/public/:agentId/save',
    component: AddToMyAgentsComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'agentSharing' },
    resolve: { agent: AgentResolver }
  },
  {
    path: 'agents/:agentId/chat/:chatId',
    component: AgentChatComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'agents' }
  },
  {
    path: 'iframe-apj',
    component: IframeApjComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, BetaGuard]
  },
  {
    path: 'ask-our-docs',
    component: AskOurDocsPageComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'askOurDocsPage' }
  },
  {
    path: 'ask-our-docs/:indexName',
    component: AskOurDocsChatComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, AskOurDocsRoleGuard]
  },
  {
    path: 'ask-our-docs/:indexName/admin/:groupId',
    component: AskOurDocsUserManagementPageComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, AskOurDocsRoleGuard, AskOurDocsGroupAdminGuard],
    data: { featureFlag: 'askOurDocsUserManagementPage' }
  },
  {
    path: 'sva2',
    loadChildren: () => import('src/app/sva2/app/user/user.module').then((m) => m.UserModule),
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard, SVA2RoleGuard],
    data: { featureFlag: 'sva2' },
  },
  {
    path: 'ask-our-data',
    loadChildren: () => import('./ask-our-data/ask-our-data.module').then((m) => m.AskOurDataModule),
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard, AskOurDataRoleGuard],
    data: { featureFlag: 'askOurData' }
  },
  {
    path: 'image-creation',
    component: ImageCreationComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, DallEGuard]
  },
  {
    path: 'image-creation/:id',
    component: ImageCreationComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, DallEGuard]
  },
  {
    path: 'ask-our-data/chat',
    component: ChatWithDbComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard, AskOurDataRoleGuard],
    data: { featureFlag: 'askOurData' }
  },
  {
    path: 'my-library',
    component: MyLibraryComponent,
    canActivate: [AutoLoginPartialRoutesGuard, TermsAndConditionsGuard, FeatureFlagGuard],
    data: { featureFlag: 'uxRevamp' }
  },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
