import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";
import { featureFlags } from "@app/environments/environment";
import { UserData } from '@shared/models/user-data.model';
import { map, Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  @Input() title!: string;
  @Input() id!: string;
  @Input() buttonLabel!: string;
  @Input() url!: string;
  @Input() repositoryGroup!: any;
  @Input() repositoryOwners!: string;
  @Input() isUserAdmin?: boolean = false;

  protected readonly featureFlags = featureFlags;
  userData$: Observable<UserData>;

  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService
  ) {
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    );
  }

  redirectToUrl() {
    this.router.navigate([this.url]);
  };

  redirectToManagment() {
    this.router.navigate(['/ask-our-docs/' + this.id + '/admin/' + this.repositoryGroup]);
  };

  hasRepositoryOwners() {
    return this.repositoryOwners !== '';
  }
}
