<div id="agent-rating-{{ agent?.id }}" class="agent-rating-wrapper">

    <div class="like-icon-container" (click)="showTooltip($event)" (mouseleave)="hideTooltip()">
      <img id="liked-agent-icon" *ngIf="likedByUser; else unlikedAgentIcon"
          src="../assets/icons/thumbs%20up/thumbsup-16px-bold.svg" class="agent-rating-icon">
      <ng-template #unlikedAgentIcon>
          <img id="unliked-agent-icon" src="../assets/icons/thumbs%20up/thumbsup-16px-gray-bold.svg"
              class="agent-rating-icon">
      </ng-template>
      <div class="tooltip" [class.show]="tooltipVisible" [style.left.px]="tooltipLeft" [style.top.px]="tooltipTop">
        You can choose to like the agent after chatting with it.
      </div>
    </div>

    <span class="agent-rating-text">
        {{(likes | shortNumber) + getLikeLabel()}}
    </span>

</div>
