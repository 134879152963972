import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { KmdModalService } from 'gds-atom-components';
import { PromptService } from 'src/app/core/services/prompt/prompt.service';
import { DefaultCategories, Prompt } from 'src/app/shared/models/prompt.model';
import { Model } from "@shared/models/prompt-settings.model";

@Component({
  selector: 'app-modal-edit-prompt',
  templateUrl: './modal-edit-prompt.component.html',
  styleUrls: ['./modal-edit-prompt.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalEditPromptComponent implements OnChanges {

  @Input() id: string = '';
  @Input() prompt!: Prompt;
  @Input() readOnly: boolean = false;
  @Input() documentList?: string[] = [];
  @Output() promptEditEvent = new EventEmitter<Prompt>();

  categories: string[] = [];
  alertText: string = '';
  showAlert: boolean = false;

  ALL_PROMPT_CATEGORIES = "";

  constructor(private promptService: PromptService, private kmdModalService: KmdModalService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['prompt'] && changes['prompt'].currentValue) {
      if (!this.prompt.title)
        this.prompt.title = this.prompt.text.substring(0, 50);

      if (!this.prompt.category) {
        this.prompt.category = '';
      }

      this.fetchPromptCategories();
    }
  }

  fetchPromptCategories() {
    this.promptService.promptCategories(this.ALL_PROMPT_CATEGORIES).subscribe({
      next: (categoryList) => {
        this.categories = [...new Set([...DefaultCategories, ...categoryList].sort())];
      },
      error: () => {
        this.alertText = "The categories could not be obtained. There was an issue contacting the server.";
        this.showAlert = true;
      }
    });
  }

  isSaveButtonEnabled(): boolean {
    if (this.prompt.title && this.prompt.category)
      return this.prompt.title.trim().length > 0 && this.prompt.category.trim().length > 0 && this.prompt.text.trim().length > 0;
    else
      return false;
  }

  getWebSearchLabelText(webSearch: boolean): string {
    return webSearch ? "ON" : "OFF";
  }

  getModelLabelText(model: Model): string {
    const modelLabels = {
      "gpt-4-o": "GPT 4o 128K"
    };
    return modelLabels[model as keyof typeof modelLabels];
  }

  open(): void {
    this.kmdModalService.open(this.id);
  }

  close(): void {
    this.kmdModalService.close(this.id);
  }

  onFormAccept(prompt: Prompt) {
    this.promptService.update(prompt).subscribe({
      next: () => {
        this.promptEditEvent.emit(prompt);
      },
      error: () => {
        this.showAlert = true;
      }
    });
  }
}
