<div class="banner-container">
  <div class="banner">
    <div class="banner-header">
      <span class="header-title">NEW: GPT-4o added to Agents!</span>
      <i class="kmd-icon-close-mono kmd-close-black-icon" (click)="closeBannerModal()"></i>
    </div>
    <div class="banner-content">
      GPT-4o (128K) is now set as the default LLM model for <a class="a-announced-feature" (click)="navigateToAnnouncedFeature()">Agents</a>. We will continue to evaluate GPT-4o as we extend it to other Gene.AI features.
    </div>
    <div class="banner-ask">
      <kmd-checkbox (onClick)="isChecked($event)"></kmd-checkbox>
      <span class="banner-ask-label">Don't show this again</span>
    </div>
  </div>
</div>
