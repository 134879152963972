<kmd-alerts kmd-error [(show)]="showAlert" [floating]="true" [alertText]="alertText"></kmd-alerts>

<div class="document-query-container" [ngClass]="{'blur-style' : isChatHistoryVisible()}">
  <div class="toolbar-document-query">
    <div class="toolbar-content-document-query" *ngIf="activeView !== 'prompt-list' else docQuerySavedPrompt">
      <button class="saved-prompts-btn" kmdPrimaryButton (click)="showPromptList()">
        <i class="doc-query-ic ic-saved-prompts"></i>
        <span>Saved Prompts</span>
      </button>

      <button id="chat-history-btn" class="trigger-toggle-nav-menu" kmdPrimaryButton (click)="toggleChatHistoryState()">
        <i class="doc-query-ic ic-chat-history"></i>
        <span>Chat History</span>
      </button>

      <button kmdFeaturedButton class="new-chat-btn" (click)="navigateToAskMyDocs()">
        <img class="doc-query-ic ic-new-chat" src="assets/icons/plus/plus-16px-mono.svg">
        <span>New Chat</span>
      </button>

      <button id="go-to-my-docs-btn" kmdPrimaryButton (click)="moveToMyDocs()">
        <fa-icon [icon]="uploaderIcon"></fa-icon>
        <span>My Docs</span>
      </button>

    </div>
    <ng-template #docQuerySavedPrompt>
      <div class="doc-query-saved-prompts-top-menu-container">
        <div>
          <a (click)="showChat($event)">
            <img src="../assets/icons/arrows/arrowLeft.svg">
            <span>Back to chat</span>
          </a>
        </div>
        <div><span>Saved Prompts</span></div>
      </div>
    </ng-template>
  </div>
  <div class="tabs max-width-tabs">
    <kmd-tabs theme="red" *ngIf="activeView === 'prompt-list'">
      <kmd-tab-pane title="Private Prompts">
        <ng-template kmdTabPaneContent>
          <div id="prompt-list" class="tab">
            <prompt-list class="scrollable" [type]="'ask_my_docs'" #modalPromptListComponent
              (promptSelectEvent)="promptSelected($event)" />
          </div>
        </ng-template>
      </kmd-tab-pane>
    </kmd-tabs>
    <div class="chat dq-container" [hidden]="isChatViewInactive()">
      <div class="prompt-side">
        <span class="title">Ask My Docs</span>
        <div class="document-selection-container">
          <core-notification *ngIf="showNoDocumentsNotification" class='no-document-notification' label='No documents: '
            message='Please upload a document first' variant='inline' state='error' timer='0' nostateicon nocloseicon>
            <a target (click)="moveToMyDocs()" slot='link'>Go to My Docs</a>
          </core-notification>
          <label kmdFormLabel><b>Select Document</b><span>*</span></label>
          <span class="unsupported-pdf-message">
            Scanned/Converted PDFs of images, presentations, or spreadsheets are unsupported.
          </span>
          <kmd-multi-select-dropdown #dropdownComponent [options]="filesList" optionLabelKey="name"
            [externalLabel]="true" [(ngModel)]="multiSelectedValSelectKey" ngDefaultControl>
          </kmd-multi-select-dropdown>
        </div>

        <core-notification *ngIf="showTipNoteNotification" class='document-query-tip-notification' label='Tip: '
          message='For optimal results, provide specific prompts using document-related keywords. When using multiple documents, avoid ambiguous prompts to summarize or compare.'
          variant='inline' state='information' timer='0' nostateicon nocloseicon>
        </core-notification>

        <textarea id="prompt" rows="1" [ngClass]="{'error-outline': isTextExceeded()}" (paste)="onPaste($event)"
          (keyup)="onPromptChange($event)" (keydown.enter)="onEnterKey($event)"
          placeholder="Type a prompt for your document here" [(ngModel)]="promptText"
          maxlength="{{maxInputCharLimit()}}">
        </textarea>

        <div class="character-counter">
          <div>
            <p id="text-trimmed" *ngIf="isTextTrimmed()" class="error-text">
              Text cut, characters limit reached on model
            </p>
            <p id="text-limit" *ngIf="isTextExceeded()" class="error-text">
              Character limit exceeded
            </p>
          </div>
          <countdown-label [maxlength]=maxInputCharLimit() [value]="promptText"></countdown-label>
        </div>
        <div class="dq-container-btns">
          <core-segmentedbuttons>
            <core-segmentedbuttons-item label='Docs Only' slot='segment'
              [attr.selected]="!chat?.gptEnabled ?  true : null"
              (click)="handleGPT(false)"></core-segmentedbuttons-item>
            <core-segmentedbuttons-item #gptEnabledButton label='Docs + GPT' slot='segment'
              [attr.selected]="chat?.gptEnabled ?  true : null" (click)="handleGPT(true)"></core-segmentedbuttons-item>
          </core-segmentedbuttons>
          <button id="sendPromptButton" kmdFeaturedButton (click)="sendPrompt()" [disabled]="!isSendEnabled()">
            <fa-icon class="request-input-icon" [icon]="faPaperPlane"></fa-icon>
            Send
          </button>
          <fa-icon class="btn-prompt-settings-dq exclude-from-drawer-directive" [icon]="faSlidersH"
            (click)="openDrawer()" kmdTooltip="Prompt Settings" tooltipPlacement="top"></fa-icon>
        </div>
        <div class="quick-actions" *ngIf="showQuickActions()">
          <div class="quick-actions-title">
            <span><b>Quick Actions</b></span> <span class="quick-actions-message"> For use with single document
              only.</span>
          </div>
          <div class="quick-actions-container">
            <core-button label="Summarize" kmdTooltip="Get a simplified explanation of the content"
              tooltipPlacement="top" type="button" variant="outline" size="small" id="summarize-btn"
              (click)="executeQuickAction('summarize')"></core-button>
            <core-button label="Key Terms" kmdTooltip="Generate 10 key terms and definitions based on the content"
              tooltipPlacement="top" type="button" variant="outline" size="small" id="key-terms-btn"
              (click)="executeQuickAction('keyTerms')"></core-button>
            <core-button label="Questions" kmdTooltip="Generate 10 questions and answers based on the content"
              tooltipPlacement="top" type="button" variant="outline" size="small" id="questions-btn"
              (click)="executeQuickAction('questions')"></core-button>
            <core-button label="Analogies" kmdTooltip="Explain the document's contents using 5 analogies"
              tooltipPlacement="top" type="button" variant="outline" size="small" id="analogies-btn"
              (click)="executeQuickAction('analogies')"></core-button>
            <core-button label="Report" kmdTooltip="Generate a 1000-word report summarizing the content"
              tooltipPlacement="top" type="button" variant="outline" size="small" id="report-btn"
              (click)="executeQuickAction('report')"></core-button>
          </div>
        </div>
      </div>

      <div class="response-side">
        <div class="response-container">
          <div class="table" #table [scrollTop]="table.scrollHeight">
            <app-chat [username]="(userData$ | async)?.name || 'NA'" [chatType]="'ask_my_docs'"
              [chatId]="chat?.id || ''" (responseSelectEvent)="handleResponseSelected($event)"
              (promptSavedEvent)="handlePromptSaved()" (messagesBranchUpdated)="handleMessagesUpdated($event)"
              #appChat></app-chat>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal elements -->
  <app-drawer appClickOutsideDrawer (appClickOutsideDrawer)="closeDrawer()" [position]="'right'">
    <span title>Prompt Settings</span>
    <prompt-settings [promptSettings]="settings" [includeWebSearch]="includeWebSearch" [chatType]="'ask-my-docs'" />
  </app-drawer>

  <kmd-alerts kmd-success [(show)]="showPromptSavedAlert" [floating]="true" [alertText]="promptSavedText"></kmd-alerts>

  <kmd-modal class="loading-modal" [id]="'loadingResponseModal'" modalSize="small" [closeOnClickingOutside]="false">
    <div>Generating response</div>
    <kmd-loading-wheel></kmd-loading-wheel>
  </kmd-modal>
</div>

<chat-history appToggleChatHistory (ToggleChatHistoryEvent)="toggleByDirective()" [@slideInOut]="chatHistoryState"
  [selectedChat]="chat" (selectedChatEvent)="selectedChat($event)" (deletedChatEvent)="deletedChat($event)" #appMenu>
  <i class="kmd-icon-close-mono trigger-toggle-nav-menu" (click)="toggleChatHistoryState()"></i>
</chat-history>