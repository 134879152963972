<div class="action-controls-wrapper" *ngIf="!hideControls">
    <ng-container *ngIf="isMobileViewport">
        <div class="mobile-action-buttons">
            <div id="add-button" class="custom-button" (click)="onAdd()">
                <label>Add ({{ selectedFileCount }})</label>
            </div>
            <div id="cancel-button" class="custom-button" (click)="onCancel()">
                <label>Cancel</label>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isMobileViewport">
        <div class="section left">
            <label class="clear" [ngClass]="{ 'disabled': selectedFileCount < 1 }" (click)="onClear()">Clear
                selection</label>
        </div>
        <div class="section right">
            <core-button label="Cancel" type="button" variant="information" size="regular" noradius="none"
                arialabel="Cancel" (click)="onCancel()" />
            <core-button label="Add ({{ selectedFileCount }})" type="button"
                variant="primary" size="regular" noradius="none" arialabel="Add ({{ selectedFileCount }})"
                (click)="onAdd()" />
        </div>
    </ng-container>
</div>
