import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ChatMetaData } from "@shared/models/chat-metadata.model";
import { KmdPopoverComponent } from "gds-atom-components";

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css']
})
export class ActionButtonsComponent {

  @ViewChild('popOver') popOver!: KmdPopoverComponent;
  @Output() deleteChatEvent = new EventEmitter<ChatMetaData>();
  @Output() editChatEvent = new EventEmitter<ChatMetaData>();
  @Input() editingEnabled = false;

  chatMetaData!: ChatMetaData;

  showPopover(event: MouseEvent, chat: ChatMetaData) {
    this.chatMetaData = chat;
    this.popOver.toggle(event, event.currentTarget);
  }

  hidePopover(event: MouseEvent) {
    event.preventDefault();
    this.popOver.hide();
  }

  editChat() {
    this.editChatEvent.emit(this.chatMetaData);
    this.hidePopover(new MouseEvent('click'));
  }

  deleteChat() {
    this.deleteChatEvent.emit(this.chatMetaData);
    this.hidePopover(new MouseEvent('click'));
  }

}
