import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { TermsandconditionsService } from "../services/termsandconditions/termsandconditions.service";
import { map } from "rxjs/operators";
import { EnumTermsAndConditionsState, TermsAndConditions } from "../../shared/models/terms-and-conditions.model";

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsGuard implements CanActivate {

  cachedCanActivate = false

  constructor(
    private termsAndConditions: TermsandconditionsService,
    private router: Router
  ) {
  }

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.cachedCanActivate) {
      return of(this.cachedCanActivate)
    } else {
      return this.termsAndConditions.termsandconditions().pipe(
        map((result: TermsAndConditions) => {

          this.cachedCanActivate = result.state == EnumTermsAndConditionsState.accepted

          if (!this.cachedCanActivate) {
            this.router.navigate(['/guidelines'])
          }

          return this.cachedCanActivate;
        }),
        catchError(err => {
          let errors = [400, 401, 403, 404, 500, 503]
          if (errors.includes(err.status)) {
            return this.router.navigate(['/error'], {state: {status: err.status}})
          } else {
            return this.router.navigate(['/error'], {state: {status: 500}})
          }
        })
      )
    }
  }

}
