import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function invalidEmailFromListValidator(emailList: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const email = control.value.mail.toLowerCase();
    const list = emailList.map(e => e.toLowerCase());

    if (list.includes(email)) {
      return { invalidEmailFromList: true };
    }

    return null;
  };
}
