import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appToggleChatHistory]'
})
export class ToggleChatHistoryDirective {

  @Output() ToggleChatHistoryEvent = new EventEmitter<void>();

  constructor() { }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    const isTriggerElement = targetElement.closest('.trigger-toggle-chat-history') !== null;

    if (isTriggerElement) {
      this.ToggleChatHistoryEvent.emit();
    }
  }
}
