import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslationsComponent } from '@app/tools/translations/translations.component';
import { ModalService } from '@services/modal/modal.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageLeaveGuard implements CanDeactivate<TranslationsComponent> {
  constructor(private modalService: ModalService) {}

  canDeactivate(component: TranslationsComponent): Observable<boolean> | boolean {
    if (component && component.showConfirmationModal()) {
      const subject = new Subject<boolean>();
      component.openConfirmationModal();

      component.onConfirmLeave.subscribe(() => {
        subject.next(true);
        subject.complete();
      });

      component.onCancelLeave.subscribe(() => {
        subject.next(false);
        subject.complete();
      });

      return subject.asObservable();
    }
    return true;
  }
}
