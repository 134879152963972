import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalService, ModalState } from "@services/modal/modal.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() headline?: string;
  @Input() subHeadline?: string;
  @Input() config = {
    closeable: true
  }
  @Output() close = new EventEmitter<void>();

  private modalSubscription!: Subscription;
  private state: ModalState = 'closed';

  constructor(
    private modalService: ModalService
  ) { }

  @HostListener('document:keydown.escape')
  onKeydownHandler() {
    if (this.isCloseable) {
      this.closeModal();
    }
  }

  ngOnInit() {
    this.modalService.registerModal(this.id);
    this.modalSubscription = this.modalService.getModalState(this.id)
      .subscribe(state => this.state = state);
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  onOverlayClick() {
    if (this.isCloseable) {
      this.closeModal();
    }
  }

  closeModal() {
    this.close.emit();
    this.modalService.close(this.id);
  }

  get isCloseable() {
    return this.config.closeable;
  }

  get isOpen() {
    return this.state !== 'closed';
  }

  get isHidden() {
    return this.state === 'hidden';
  }

  get modalCssClass() {
    return {
      'hidden': this.isHidden,
    }
  }
}
