import { Injectable } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class OidcIdentityRoleResolver {

    constructor(
        private oidcIdentityService: OidcSecurityService
    ) { }

    resolve(): Observable<string[]> {
        return this.oidcIdentityService
            .userData$
            .pipe(
                map((data) => data.userData.roles)
            );
    };
}