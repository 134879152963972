import { Component, Input } from '@angular/core';
import { Prompt } from "../../shared/models/prompt.model";
import { KmdModalService } from "gds-atom-components";

@Component({
  selector: 'app-modal-read-only-prompt',
  templateUrl: './modal-read-only-prompt.component.html'
})
export class ModalReadOnlyPromptComponent {
  @Input() prompt!: Prompt;

  constructor(private kmdModalService: KmdModalService) {
  }

  open(): void {
    this.kmdModalService.open("read-only-prompt");
  }

  close(): void {
    this.kmdModalService.close("read-only-prompt");
  }
}
