import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { BannerService } from "@services/banner/banner.service";
import { Observable } from "rxjs";
import { UserData } from "@shared/models/user-data.model";
import { map, tap } from "rxjs/operators";
import { UserContextMenuComponent } from "@app/user-context-menu/user-context-menu.component";
import { featureFlags } from '@environments/environment';
import { NavigationEnd, Router } from "@angular/router";
import { NavBarScreenResolutionSettings } from "@shared/constants/navbar/screen-resolutions";
import { WindowRef } from './core/services/window/window-ref.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild(UserContextMenuComponent) userContextMenuComponent?: UserContextMenuComponent;

  readonly navMenuToggleId = 'navMenuToggleButton';
  readonly feedbackUrl = 'https://forms.office.com/r/ZsgKj4pmWX';
  readonly tcUrl = 'guidelines';

  isLoading: boolean = false;
  showHeaderFooter: boolean = true;
  isAuthenticated: boolean = false;
  userData$: Observable<UserData>;
  userName: string = '';

  protected readonly featureFlags = featureFlags;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    public bannerService: BannerService,
    private router: Router,
    private location: Location
  ) {
    this.userData$ = this.oidcSecurityService.userData$.pipe(
      map((result) => new UserData(result.userData.name, result.userData.email)),
      tap((result) => {
        this.userName = result.name;
      })
    );
  }

  ngOnInit() {
    this.isLoading = true
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showHeaderFooter = !event.url.includes('/signin') && !event.url.includes('/ask-our-data/response');
      }
    });

    // TODO: Change this chain to flatMap
    this.oidcSecurityService.isAuthenticated$.subscribe(result => {
      this.isAuthenticated = result.isAuthenticated
    })
    this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
      const { isAuthenticated } = loginResponse;
      if(!isAuthenticated) {
        let path = this.location.path() || '';
        sessionStorage.setItem('path', path);
      }
      this.isAuthenticated = isAuthenticated
      this.isLoading = false;
    });

    this.checkBannerVersioning();
  }

  checkBannerVersioning() {
    if (this.featureFlags.newsBannerMainChat) {
      return;
    }

    if (this.bannerService.getBannerVersion() < this.bannerService.bannerVersioned) {
      this.bannerService.resetBanner()
    }
  }

  openContextMenu(event: MouseEvent) {
    this.userContextMenuComponent?.showPopover(event);
  }

  isMobileScreen(): boolean {
    return NavBarScreenResolutionSettings.mobile.size >= WindowRef.innerWidth;
  }
}
