import { Component, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { FileManagerDoc } from "@app/shared/models/file-manager/file-manager-doc.model";
import { FileManagerService } from "@app/core/services/file-manager/file-manager.service";
import { Subject } from "rxjs";
import { FileManagerSelectedFilesComponent } from "./selected-files/file-manager-selected-files.component";
import { FileProcessingStatus } from "@shared/constants/file-manager/file-processing-status";
import { ModalService } from "@services/modal/modal.service";
import { FileUploadOptions } from "@app/shared/constants/file-manager/file-upload-options";
import { featureFlags } from "@environments/environment";
import { FileManagerModalComponent } from "./modal/file-manager-modal.component";

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnDestroy {

  @ViewChild(FileManagerModalComponent) fileManagerModal!: FileManagerModalComponent;
  @ViewChild('fileManagerSelectedFiles') fileManagerSelectedFiles!: FileManagerSelectedFilesComponent;

  private destroy$ = new Subject<void>();

  readonly fileManagerModalId = "fileManagerModal";
  readonly fileManagerModalHeadline = "Add files";
  readonly fileManagerModalSubHeadline = featureFlags.askMyCsv ?
    "Enhance your prompt's results by adding files. You can select multiple text files or a single spreadsheet." :
    "Enhance your prompt's results by adding files. You can select multiple text files.";

  showTooltip: boolean = false;
  showFileManager: boolean = false;
  isFileIconHovered: boolean = false;

  constructor(
    private modalService: ModalService,
    private fileManagerService: FileManagerService
  ) {
  };

  @HostListener('window:resize', ['$event'])
  closeFileManager(): void {
    this.showFileManager = false;
    this.modalService.close(this.fileManagerModalId);
  }

  @HostListener('document:click', ['$event'])
  hideFileManager(event: any) {
    if (event.target.classList.contains('file-manager-drawer-wrapper')) {
      this.showFileManager = false;
      this.resetContent();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  };

  get selectedFiles() {
    return this.fileManagerService.selectedFiles$.value;
  }

  showModal(): boolean {
    return window.innerWidth > 768;
  }

  openFileManager(name: string) {
    if (this.showModal()) {
      this.modalService.open(name);
    } else {
      this.showFileManager = true;
    }

    this.fileManagerModal.initFileFetch();
  };

  openSelectedFilesList(): void {
    this.fileManagerSelectedFiles.toggleMenu();
  };

  removeSelectedFile(file: FileManagerDoc): void {
    const currentSelectedFiles = this.selectedFiles;
    const newSelectedFiles = currentSelectedFiles.filter((f) => f.name !== file.name);
    this.fileManagerService.setSelectedFiles(newSelectedFiles);
  };

  getSelectedFilesStatus(): { [key: string]: boolean } {
    const { processed, error } = this.fileManagerService.fileStatusIds;

    if (this.selectedFiles.length === 0) {
      return {};
    }

    const hasProcessingError = this.selectedFiles.some(file => error.includes(file.id!));
    const hasProcessing = this.selectedFiles.some(file => !processed.includes(file.id!) && !error.includes(file.id!));

    if (hasProcessingError) {
      return {
        [FileProcessingStatus.PROCESSING_ERROR]: true
      };
    }

    if (hasProcessing) {
      return {
        [FileProcessingStatus.PROCESSING]: true
      };
    }

    if (this.selectedFiles.length === 1) {
      const file = this.selectedFiles[0].name;
      const extension = file.slice(file.lastIndexOf('.'));

      if (FileUploadOptions['SPREADSHEETS'].extensions.includes(extension)) {
        return {
          [FileProcessingStatus.PROCESSED_SPREADSHEET]: true
        }
      }
    }

    return {
      [FileProcessingStatus.PROCESSED]: true
    };
  }

  get tooltipText(): string {
    const status = this.getSelectedFilesStatus();
    if (status[FileProcessingStatus.PROCESSING]) {
      this.showTooltip = true;
      return 'Processing your file(s) you will be able to send your prompt when it is done.';
    }

    this.showTooltip = false;
    return '';
  }

  onMouseEnter(): void {
    this.isFileIconHovered = true;
  }

  onMouseLeave(): void {
    this.isFileIconHovered = false;
  }

  resetContent() {
    this.fileManagerService.clearSelectedFiles();
    this.fileManagerModal.resetSearchTerm();
  }

  isTooltipEnabled(): boolean {
    return featureFlags.customTooltip;
  }
}
