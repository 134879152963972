import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { GroupMembershipService } from "@app/core/services/group-membership/group-membership.service";
import { Group } from '@app/shared/models/azure/groups/group.model';
import { FileUpload } from '@app/shared/models/file-upload.model';
import { featureFlags } from '@environments/environment';
import { AgentsService } from "@services/agents/agents.service";
import { AlertService } from "@services/alert/alert.service";
import {
  addAllowShareToAgents,
  Agent,
  AgentCategoryFilters,
  AgentType,
  SortOptions,
  SortOptionValues
} from "@shared/models/agent";
import { PagedAgents } from "@shared/models/paged-agents.model";
import { UserData } from "@shared/models/user-data.model";
import { KmdModalService, KmdPaginationComponent } from "gds-atom-components";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  forkJoin,
  map,
  merge,
  Observable,
  of,
  switchMap, tap
} from "rxjs";
import { AgentCardComponent } from '../agent-list/agent-card/agent-card.component';
import { AgentSharingConfirmationModalComponent } from "../agent-sharing/agent-sharing-confirmation-modal/agent-sharing-confirmation-modal.component";
import { ChatHistoryUpdateService } from '@app/core/chat-history-update/chat-history-update.service';

@Component({
  selector: 'app-agent-list-paged',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './agent-list-paged.component.html',
  styleUrls: ['./agent-list-paged.component.css']
})
export class AgentListPagedComponent implements OnInit, OnChanges {
  @ViewChild('agentSharingOverviewModal') agentSharingOverviewModal?: AgentSharingConfirmationModalComponent;
  @ViewChild('agentCard') agentCard!: AgentCardComponent;
  @ViewChild('pagination') pagination!: KmdPaginationComponent;

  @Input() type!: AgentType;
  @Input() userData$!: Observable<UserData>;
  @Input() scrollDiv!: HTMLDivElement;
  @Input() isUserApprover: boolean = false;
  @Input() overviewAgent!: Agent;

  protected readonly scroll = scroll;
  readonly RESULTS_PER_PAGE = 15;
  readonly DEBOUNCE_TIME = 500;
  readonly FAST_DEBOUNCE_TIME = 0;

  agents!: PagedAgents;
  selectedAgent?: Agent;
  agentToDelete?: Agent;
  categories = [...AgentCategoryFilters];
  sortOptions = [...SortOptions];
  isSmallAgentList: boolean = false;
  debounceTimeValue = this.DEBOUNCE_TIME;
  isLoading = false;
  private isFirstEmptySearch = true;

  formGroup = new FormGroup({
    search: new FormControl('', { nonNullable: true }),
    sorting: new FormControl('A-Z', { nonNullable: true }),
    category: new FormControl('All agents', { nonNullable: true })
  })

  pageFormControl = new FormControl(0, { nonNullable: true })
  agents$: Observable<PagedAgents> = of();
  showAgentSharingModal: boolean = false;

  constructor(
    private agentService: AgentsService,
    private groupMembershipService: GroupMembershipService,
    private kmdModalService: KmdModalService,
    private router: Router,
    private alertService: AlertService,
    private cdRef: ChangeDetectorRef,
    private chatHistoryUpdateService : ChatHistoryUpdateService
  ) { }

  ngOnInit() {
    if (this.type == 'private') {
      this.sortOptions.pop();
    }
  }

  ngOnChanges(): void {
    let option = SortOptionValues['A-Z'];
    if (this.type == 'public') {
      this.formGroup.controls.sorting.patchValue('Most popular');
      option = SortOptionValues['Most popular'];
      this.reloadAgentsToFirstPage();
    }

    let initialAgentPage =
      this.getAgents(0, this.RESULTS_PER_PAGE, '', option.field, option.direction, '');

    let searchObservable = this.formGroup.controls.search.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(this.debounceTimeValue),
      switchMap(value => {
        if (value === '' && this.isFirstEmptySearch) {
          this.isFirstEmptySearch = false;
          this.cdRef.detectChanges();
          return EMPTY;
        }
        this.setLoadingState(true);
        this.formGroup.controls.category.patchValue('All agents');
        return EMPTY;
      })
    );


    let categoryObservable = this.formGroup.controls.category.valueChanges.pipe(
      switchMap(() => {
        return this.reloadAgentsToFirstPage();
      })
    )

    let sortingObservable = this.formGroup.controls.sorting.valueChanges.pipe(
      switchMap(() => {
        return this.reloadAgentsToFirstPage();
      })
    );

    let pageObservable = this.pageFormControl.valueChanges.pipe(
      switchMap((partial) => {
        let option = SortOptionValues[this.formGroup.controls.sorting.value];
        let category = this.formGroup.controls.category.value === 'All agents' ? '' : this.formGroup.controls.category.value;
        return this.getAgents(partial, this.RESULTS_PER_PAGE, category, option.field, option.direction, this.formGroup.controls.search.value);
      })
    )

    this.agents$ = merge(initialAgentPage, searchObservable, categoryObservable, sortingObservable, pageObservable);
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const width = entry.contentRect.width;
        this.isSmallAgentList = width < 580;
      });
    });

    const element = document.querySelector("#public-agents-tab") as Element;
    if (!element) return;

    observer.observe(element);
    this.debounceTimeValue = this.DEBOUNCE_TIME;
  }

  private getAgents(
    page: number,
    resultsPerPage: number,
    category: string,
    sort: string,
    sortDirection: string,
    searchTerm?: string
  ): Observable<PagedAgents> {

    this.setLoadingState(true);

    const observableOfEmptyPagedAgents: Observable<PagedAgents> = of({
      content: [], totalPages: 0, numberOfElements: 0,
      empty: true, first: true, totalElements: 0, last: true, size: 0, number: 0
    });

    let agents$: Observable<PagedAgents>;

    switch (this.type) {
      case 'public':
        agents$ = this.agentService.getPagedPublicAgents(page, resultsPerPage, category, sort, sortDirection, searchTerm);
        break;
      case 'shared':
        agents$ = this.agentService.getPagedAgents(page, resultsPerPage, category, sort, sortDirection, searchTerm, this.type);
        break;
      case 'private':
        agents$ = this.agentService.getPagedAgents(page, resultsPerPage, category, sort, sortDirection, searchTerm)
          .pipe(map(agents => ({ ...agents, content: addAllowShareToAgents(agents.content) })));
        break;
      default:
        this.setLoadingState(false);
        return observableOfEmptyPagedAgents;
    }

    return agents$.pipe(
      map(agents => {
        this.setLoadingState(false);
        return agents;
      }),
      catchError(() => {
        this.alertService.showError("We encountered an unexpected error. Please try again.");
        this.setLoadingState(false);
        return observableOfEmptyPagedAgents;
      })
    );
  }

  private setLoadingState(state: boolean): void {
    if (!featureFlags.agentSharing) return;
    if (this.isLoading === state) return;
    this.isLoading = state;
    this.cdRef.detectChanges();
  }

  reloadAgents(): Observable<PagedAgents> {
    this.formGroup.controls.category.patchValue('All agents');
    return this.reloadAgentsToFirstPage();
  }

  viewAgent(agent: Agent) {
    this.selectedAgent = agent
    if (this.type === 'shared') {
      this.showAgentSharingModal = true;
    } else {
      this.kmdModalService.open('page-view-agent-modal');
    }
  }

  onAgentSharingModalInitialized() {
    this.loadAgentSharingOverviewModal(this.selectedAgent!);
  }

  onAgentSharingOverviewModalClosed(): void {
    this.showAgentSharingModal = false;
  }

  private loadAgentSharingOverviewModal(agent: Agent) {
    forkJoin([
      agent.files ? this.getFiles(agent) : of([]),
      this.groupMembershipService.getGroup(agent.azureGroupId!),
      this.getGroupMembersCount(agent.azureGroupId!)
    ]).pipe(
      tap(([files, group, members]) => this.setAgentSharingOverviewModalInputs(agent, files, group, members))
    ).subscribe({
      next: () => this.agentSharingOverviewModal!.openModal(),
      error: () => {
        this.alertService.showError("We encountered an unexpected error. Please try again.");
        this.showAgentSharingModal = false;
      }
    });
  }

  setAgentSharingOverviewModalInputs(agent: Agent, files: FileUpload[], group: Group, membersCount: number) {
    this.agentSharingOverviewModal!.sharingDetails = {
      agent: agent,
      selectedFiles: files
    };
    this.agentSharingOverviewModal!.group = group;
    this.agentSharingOverviewModal!.admins = agent.admins?.map((admin) => `${admin.name} ${admin.lastname}`);
    this.agentSharingOverviewModal!.membersCount = membersCount;
  }

  closeModal(modal: string) {
    this.kmdModalService.close(modal)
  }

  confirmDeleteAgent(agent: Agent) {
    this.agentToDelete = agent
    if (agent.isPublic) {
      this.kmdModalService.open('page-delete-public-agent-modal')
    } else {
      this.kmdModalService.open('page-delete-agent-modal')
    }
  }

  showPagePublicAgentOverviewModal(agent: Agent): void {
    this.selectedAgent = agent;
    this.kmdModalService.open("page-public-agent-overview");
  };

  deleteAgent() {
    if (!this.agentToDelete?.id) return;
    this.agentService.delete(this.agentToDelete.id)
      .subscribe({
        next: () => {
          this.alertService.showSuccess("Agent deleted correctly.")
          this.reloadAgents()
          this.chatHistoryUpdateService.notifyChatUpdated(undefined);
          this.kmdModalService.close('page-delete-agent-modal')
        },
        error: () => {
          this.alertService.showError("We encountered an unexpected error. Please try again.")
        }
      })
  }

  deletePublicAgent() {
    if (!this.agentToDelete?.id) return;
    let observer = {
      next: () => {
        this.alertService.showSuccess("Agent deleted correctly.");
        this.reloadAgents();
        this.chatHistoryUpdateService.notifyChatUpdated(undefined);
        this.kmdModalService.close("page-delete-public-agent-modal");
      },
      error: () => {
        this.alertService.showError("We encountered an unexpected error. Please try again.");
      },
    };
    if (this.agentToDelete!.publishedByUser) {
      this.agentService.deleteByUser(this.agentToDelete.id).subscribe(observer);
    } else {
      this.agentService.deletePublicAgent(this.agentToDelete.id).subscribe(observer);
    }
  }

  publishAgent(agent: Agent) {
    this.agentService.publishAgent(agent.id!).subscribe({
      next: () => {
        this.alertService.showSuccess("Your agent is now available under Public Agents.")
        this.kmdModalService.close('page-public-agent-overview')
        this.reloadAgentsToFirstPage();
      },
      error: () => {
        this.alertService.showError("We encountered an unexpected error. Please try again.")
        this.kmdModalService.close('page-public-agent-overview')
      }
    })
  }

  onPageChange(event: any) {
    this.pageFormControl.patchValue(event.currentPage - 1);
  }

  private reloadAgentsToFirstPage() {
    if (this.pagination) {
      this.pagination.selectPage(1);
    } else {
      this.pageFormControl.patchValue(0);
    }
    return EMPTY;
  }

  goToCreateNewAgent() {
    this.router.navigate(["/agents/builder"])
  }

  emptySearchText() {
    this.formGroup.controls.search.patchValue('');
  }

  onFilterChange(filterType: string, event: any) {
    this.debounceTimeValue = this.FAST_DEBOUNCE_TIME;
    switch (filterType) {
      case 'agentCategory':
        this.formGroup.controls.category.patchValue(event);
        break;
      case 'agentSort':
        this.formGroup.controls.sorting.patchValue(event);
        break;
      case 'agentName':
        this.formGroup.controls.search.patchValue(event);
        break;
    }
  }

  private getFiles(agent: Agent): Observable<FileUpload[]> {
    return this.agentService.files(agent.id!).pipe(
      catchError(() => of([]))
    );
  }

  private getGroupMembersCount(groupId: string): Observable<number> {
    return this.groupMembershipService.getGroupMembers(groupId).pipe(
      map((response) => response.value.length),
      catchError(() => {
        this.alertService.showError('Failed to fetch group members. Please try again later.');
        return of(0);
      })
    );
  }
}
