export class AskOurDataUtils {
    static generateSessionId(): string {
        const chars = '0123456789';
        let sessionId = '';
        for (let i = 0; i < 10; i++) {
            sessionId += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return sessionId;
    }

    static createJsonTableData(chatId: number, jsonResponse: any, systemResponse: any, promptValue: string, sql: string, dbname: string, dbId: string, sessionId: string, lZStringService: any): string {
        let headers = Object.keys(jsonResponse[0]);
        let responseMessage = `<div class="chat-response "><p class="copyable-${chatId}">` + systemResponse.text + `</p> <div class="chat-table copyable-${chatId}"><table>`;
        responseMessage += `<tr>`;
        headers.forEach((header: any) => {
            responseMessage += `<th>` + header + `</th>`;
        });
        responseMessage += `</tr>`;
        let firstFiveRecords = jsonResponse.slice(0, 5);
        firstFiveRecords.forEach((data: any) => {
            responseMessage += `<tr>`;
            headers.forEach((header: any) => {
                responseMessage += `<td>` + data[header] + `</td>`;
            });
            responseMessage += `</tr>`;
        });
        responseMessage += `</table></div>`;
        if (jsonResponse.length > 5) {
            responseMessage += `<div class="table-bot-div library-icomoon"><a href="/ask-our-data/response/${chatId}_${sessionId}" target="_blank" >View Full Table <i class="pl-2 icon-external-link-mono"></i></a></div>`;
            try {
                let ftItems = JSON.parse(localStorage.getItem('FullTable_items') || "[]");
                ftItems.push(chatId + '_' + sessionId);
                localStorage.setItem('FullTable_items', JSON.stringify(ftItems));
                localStorage.setItem('fullTableData_' + chatId + '_' + sessionId, lZStringService.compress(JSON.stringify({
                    name: dbname,
                    id: dbId,
                    sql: sql,
                    promptValue: promptValue,
                    session_id: sessionId,
                    chat_id: chatId
                })));
            } catch (error) {
                console.log(error);
            }
        }
        responseMessage += `</div>`;
        return responseMessage;
    }

    static scrollToElm(container: any, elm: any, duration: any, userScrolling: boolean): void {
        let pos = this.getRelativePos(elm, userScrolling);
        this.scrollTo(container, pos.top, duration / 1000);  // duration in seconds
    }

    static getRelativePos(elm: any, userScrolling: boolean): any {
        let pPos = elm?.parentNode?.parentNode?.getBoundingClientRect(), // parent pos
            cPos = elm?.getBoundingClientRect(), // target pos
            pos: any = {};
        if (pPos && cPos) {
            pos.right = cPos.right - pPos.right;
            pos.bottom = cPos.bottom - pPos.bottom;
            pos.left = cPos.left - pPos.left;
            pos.top = !userScrolling ? (cPos.top - pPos.top + elm.parentNode.scrollTop) : cPos.top;
        }
        return pos;
    }

    static scrollTo(element: any, to: any, duration: any, onDone?: () => void): void {
        let start = element.scrollTop,
            change = to - start,
            startTime = performance.now(), now, elapsed, t;

        let animateScroll = () => {
            now = performance.now();
            elapsed = (now - startTime) / 1000;
            t = (elapsed / duration);

            element.scrollTop = start + change * this.easeInOutQuad(t);

            if (t < 1)
                window.requestAnimationFrame(animateScroll);
            else if(onDone) {
                onDone();
            }
        };

        animateScroll();
    }

    static easeInOutQuad(t: any): number {
        return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    static async convertSvgToPngDataUri(svgDataEles: any, image: any, tempContainer: any, plotly: any): Promise<void> {
        await plotly.convertSVGsToSingleImage(svgDataEles).then((source: any) => {
            image.addEventListener('load', () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(image, 0, 0);
                    const pngDataUri = canvas.toDataURL('image/png');
                    image.src = pngDataUri;
                    tempContainer.appendChild(image);
                } else {
                    console.error('Failed to convert SVG to PNG');
                }
            })
            image.src = source;
        });
    }

    static removeLocalStorage(): void {
        let ftItems = JSON.parse(localStorage.getItem('FullTable_items') || "[]");
        ftItems.forEach((ftItem: any) => {
            localStorage.removeItem('fullTableData_' + ftItem);
        });
        localStorage.removeItem('FullTable_items');
    }
}