<div class="drawer" [ngClass]="{'expanded': isExpanded}" >
  <div class="buttons">
    <core-button label="Delete" variant="information" type="button" size="large" fullspace="true"
                 (click)="onDelete()"/>
    <core-button label="Rename" variant="information" type="button" size="large" fullspace="true"
                 (click)="onRename()"/>
    <core-button label="Open chat" variant="primary" type="button" size="large" fullspace="true"
                 (click)="onOpenChat()"/>
  </div>
</div>
