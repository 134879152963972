import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {

  constructor(private http: HttpClient) { }

  uploadFile(file: File, preSignedUrl: string): Observable<HttpEvent<any>> {
    return this.http.put<void>(preSignedUrl, file, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      reportProgress: true,
      observe: 'events'
    });
  }

}
