<div class="modal" *ngIf="isOpen" [ngClass]="modalCssClass">
  <div class="modal-overlay" (click)="onOverlayClick()"></div>
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">
        <h2>{{ headline }}</h2>
        <p *ngIf="subHeadline">{{ subHeadline }}</p>
      </div>
      <div class="modal-close" *ngIf="isCloseable">
        <i class="close-btn" (click)="closeModal()"></i>
      </div>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
