import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { environment, featureFlags } from "@app/environments/environment";
import { UserData } from '@shared/models/user-data.model';
import { map, Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  @Input() title!: string;
  @Input() id!: string;
  @Input() buttonLabel!: string;
  @Input() url!: string;
  @Input() repositoryGroup!: any;
  @Input() repositoryOwners!: string;
  @Input() isUserAdmin?: boolean = false;
  @Input() aodUrl?: string = '/' + environment.aodUrl + '/';
  protected readonly featureFlags = featureFlags;
  userData$: Observable<UserData>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public oidcSecurityService: OidcSecurityService
  ) {
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    );
  }

  redirectToUrl() {
    const navigationExtras = { 
      state: {
        id: this.id,
        repositoryGroup: this.repositoryGroup,
        repoName: this.title,
        isUserAdmin: this.isUserAdmin,
        repositoryName: this.title
      },
      relativeTo: this.route
    };
    this.router.navigate([this.url], navigationExtras);
  };

  redirectToManagment() {
    const navigationExtras = { 
      state: {
        id: this.id,
        repositoryGroup: this.repositoryGroup,
        repoName: this.title,
        isUserAdmin: this.isUserAdmin,
        repositoryName: this.title,
        isNavigationSourceListingPage: true
      },
      relativeTo: this.route
    };
    this.router.navigate([this.aodUrl + this.id + '/admin/' + this.repositoryGroup], navigationExtras);
  };

  hasRepositoryOwners() {
    return this.repositoryOwners !== '';
  }
}
