import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AskOurDataService } from '@app/core/services/ask-our-data/ask-our-data.service';
import { PlotlyService }  from 'src/app/core/services/ask-our-data/plotly.service';
import { AlertService } from '@app/core/services/alert/alert.service';
import { KmdModalService } from 'gds-atom-components';
import { ChartTypes } from '@shared/models/ask-our-data.model';

@Component({
  selector: 'app-create-chart',
  templateUrl: './create-chart.component.html',
  styleUrls: ['./create-chart.component.css']
})
export class CreateChartComponent implements OnChanges{

  @Input() jsonResponse: any = [];
  @Input() dbId: number | string = '';
  @Input() chatId: number | string = '';
  @Input() sql: string = '';
  @Input() promptValue: string = '';
  @Output() displayLoader = new EventEmitter<any>();
  @Output() displayChart = new EventEmitter<any>();
  chartType: any;
  chartTypes = [...ChartTypes];
  xaxis: string = '';
  yaxis: string = '';
  selectedColumns: any[] = [];
  headers: string[] = [];
  apiFailed = false;

  constructor(private router: Router,
    private askOurDataService: AskOurDataService,
    public oidcSecurityService: OidcSecurityService,
    private cdRef: ChangeDetectorRef,
    private alertService: AlertService,
    private plotly: PlotlyService,
    private route: ActivatedRoute,
    private kmdModalService: KmdModalService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['jsonResponse']) {
      this.headers = Object.keys((changes['jsonResponse'].currentValue && changes['jsonResponse'].currentValue[0]) || {});
    }
  }

  createChart() {
    switch(this.chartType?.value) {
      case 'Line chart':
      case 'Bar chart':
      case 'Pie chart':
      case 'Histogram':
        this.callAdvanceChartAPI();
        break;
      case 'Data forecasting':
      case 'Regression analysis':
        this.callAdvancePredictiveAPI();
        break;
      case 'Default':
        this.getChart();
        break;
    }
  }

  callAdvanceChartAPI() {
    this.apiFailed = false;
    this.displayLoader.emit(true);
    let columns = [this.xaxis, this.yaxis];
    let analytics_type = this.chartType?.value;
    let request = {
        id : this.dbId,
        question : this.promptValue,
        sql : this.sql,
        df : JSON.stringify(this.jsonResponse.map((ele: any) => {
          let obj: any = {};
          columns.forEach((column: string) => {
            obj[column] = ele[column];
          });
          return obj;
        })),
        analytics_type: analytics_type
      };
    this.askOurDataService.advancePlot(request).subscribe({
      next: (response) => {
        this.displayLoader.emit(false);
        if(response) {
          let graph = JSON.parse(response.fig);
          let chartData = {
            isPredictive: false,
            data: graph.data,
            layout: graph.layout,
            chatId: this.chatId
          }
          this.displayChart.emit(chartData);
          this.closeModal('createChart');
        } else {
          this.apiFailed = true;
        }
      },
      error: () => {
        this.apiFailed = true;
        this.displayLoader.emit(false);
      }
    });

  }

  callAdvancePredictiveAPI() {
    this.apiFailed = false;
    this.displayLoader.emit(true);
    let columns = this.selectedColumns.length > 0 ? this.selectedColumns : [];
    let analytics_type = this.chartType?.value;
    let request = {
        id : this.dbId,
        question : this.promptValue,
        sql : this.sql,
        df : JSON.stringify(this.jsonResponse.map((ele: any) => {
          let obj: any = {};
          columns.forEach((column: string) => {
            obj[column] = ele[column];
          });
          return obj;
        })),
        analytics_type: analytics_type
      };
    this.askOurDataService.advancePrediction(request).subscribe({
      next: (response) => {
        this.displayLoader.emit(false);
        if(response) {
          let chartData = {
            isPredictive: true,
            data: {},
            layout: {},
            chatId: this.chatId,
            predictiveText: response?.prediction?.analysis_summary || response?.prediction
          }
          this.displayChart.emit(chartData);
          this.closeModal('createChart');
        } else {
          this.apiFailed = true;
        }
      },
      error: () => {
        this.apiFailed = true;
        this.displayLoader.emit(false);
      }
    });
  }

  getChart() {
    this.displayLoader.emit(true);
    let request = {
        "id" : this.dbId,
        "question" : this.promptValue,
        "sql" : this.sql,
        "df" : JSON.stringify(this.jsonResponse)
      };
    this.askOurDataService.generateChart(request).subscribe({
      next: (response) => {
        this.displayLoader.emit(false);
        if(response) {
          let graph = JSON.parse(response.fig);

          let chartData = {
            isPredictive: false,
            data: graph.data,
            layout: graph.layout,
            chatId: this.chatId,
            predictiveText: ''
          }
          this.displayChart.emit(chartData);
          this.closeModal('createChart');
        } else {
          this.apiFailed = true;
        }
      },
      error: () => {
        this.apiFailed = true;
      }
    });
  }

  clearData() {
    this.xaxis = '';
    this.yaxis = '';
    this.chartType = null;
    this.selectedColumns = [];
    this.apiFailed = false;
  }

  closeModal(modalType: string) {
    this.clearData();
    this.kmdModalService.close(modalType);
  }

  displayAxis() {
    let chartValue = this.chartType?.value;
    return (chartValue === 'Line chart' || chartValue === 'Bar chart' || chartValue === 'Histogram');
  }

  displayValues() {
    let chartValue = this.chartType?.value;
    return chartValue === 'Pie chart' || chartValue === 'Data forecasting' || chartValue === 'Regression analysis';
  }

  isValid() {
    let isAxis = this.displayAxis() ? this.xaxis && this.yaxis : true;
    let isPieValues = this.displayValues() ? this.selectedColumns.length > 0 : true;
    return this.chartType?.value && isAxis && isPieValues;
  }

}