import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { IsActiveMatchOptions, Router } from "@angular/router";
import { NavBarService } from '@app/core/services/navbar/navbar.service';
import { WindowRef } from '@app/core/services/window/window-ref.service';
import { NavBarScreenResolutionSettings } from '@app/shared/constants/navbar/screen-resolutions';
import { MenuOption } from "@shared/models/navbar/menu-option";
import { NavbarState } from "@shared/models/navbar/navbar-state";

@Component({
  selector: 'app-menu-option-new[menuOption]',
  templateUrl: './menu-option-new.component.html',
  styleUrls: ['./menu-option-new.component.css']
})
export class MenuOptionNewComponent {

  @Input() menuOption?: MenuOption = undefined;
  @Input() state: NavbarState = 'open';
  @Output() onNavigation = new EventEmitter<void>();
  @Output() onSubMenuClick = new EventEmitter<{ menuOption: MenuOption, event: MouseEvent }>();


  constructor(
    protected router: Router,
    protected zone: NgZone,
    protected navBarService: NavBarService
  ) {
  }

  handleClick($event?: MouseEvent): void {
    if (this.menuOption?.anchor?.type === 'routerLink') {
      if (WindowRef.innerWidth <= NavBarScreenResolutionSettings.mobile.size) {
        this.navBarService.setMobileResolution();
      }
      let path = this.menuOption.anchor.value;
      this.zone.run(() => {
        this.router.navigate([path]);
      });
      this.onNavigation.emit();
    } else if (this.hasNoEmptySubmenu()) {
      this.onSubMenuClick.emit({ menuOption: this.menuOption!, event: $event! });
    }
  }

  isActiveRoute() {
    if (this.menuOption && this.menuOption.anchor && this.menuOption.anchor.type === 'routerLink') {
      const matchOptions: IsActiveMatchOptions = {
        paths: 'subset',
        queryParams: 'exact',
        matrixParams: 'exact',
        fragment: 'exact'
      };
      return this.router.isActive(this.menuOption.anchor.value, matchOptions);
    }
    return false;
  }

  isHidden(): boolean {
    return this.state === 'hidden';
  }

  isCollapsed(): boolean {
    return this.state === 'collapsed';
  }

  hasNoEmptySubmenu(): boolean {
    return !!(this.menuOption) && !!(this.menuOption.submenu) && this.menuOption.submenu.subMenuList.length > 0;
  }

  showCaret(): boolean {

    return (this.state === 'open');
  }

  getTooltipText(): string {
    return this.isCollapsed() ? this.menuOption?.label! : '';
  }

}
