import { Component, Input, OnInit } from '@angular/core';
import { BannerModalComponent } from "@shared/components/banner-modal/banner-modal.component";
import { BannerService } from "@services/banner/banner.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-banner-modal-new',
  templateUrl: './banner-modal-new.component.html',
  styleUrls: ['./banner-modal-new.component.css']
})
export class BannerModalNewComponent extends BannerModalComponent implements OnInit {
  @Input() headerTitle: string = 'Header tittle';

  constructor(protected override bannerService: BannerService,
              protected override router : Router) {
    super(bannerService, router);
  }
}

