<div class="chat-history-mobile-container">
  <div class="table">
    <div *ngIf="(allChats$ | async) as page">
      <input [hidden]="!userHasChats" type="text" class="search-term" kmdFormInput id="chat-search-input" placeholder="Search chat title"
      [formControl]="searchFormGroup.controls.search" autocomplete="off" />
      <ng-container *ngIf="loaded && !userHasChats; else chatsView">
        <app-chat-history-zero-state id="zero-state-view"></app-chat-history-zero-state>
      </ng-container>

      <ng-template #chatsView>
        <div *ngIf="all.length > 0; else noSearchResults">
          <div class="header" >
            <h4>By date</h4>
            <div class="sort-records" (click)="sortRecords()">
              <i></i>
            </div>
          </div>
          <div class="records" (scroll)="onScroll($event)">
            <app-record-mobile  *ngFor="let record of all" [record]="record"  [selectedRecord] = "getSelectedRecord(record)"
                              [actionEvent]="getChatHistoryRecordActions(record)" (selectedEvent)="openDrawer(record)"
                              (deletedChatEvent)="afterActions()" (editedChatEvent)="afterActions()"
            />
          </div>
        </div>
        <div class="loading-spinner" *ngIf="isLoading">
          <kmd-loading-wheel></kmd-loading-wheel>
        </div>
      </ng-template>

      <ng-template #noSearchResults>
        <ng-container *ngIf="searchFormGroup.controls.search.value && all.length === 0">
          <div class="no-chats-view">
            <div class="empty-search-state-img"></div>
            <div class="matches-text">No matches for "{{this.searchFormGroup.controls.search.value}}"</div> <br>
            <div class="matches-sub-text">Try using different terms</div>
          </div>
        </ng-container>
      </ng-template>
  </div>
    <div>
      <app-chat-history-drawer class="drawer" [drawerToggled]="toggledDrawer" (actionEvent)="drawerAction($event)"/>
    </div>
  </div>
</div>
