import { PromptSettings } from "./prompt-settings.model";
import { Page } from "./page.model";
import { FileReference } from "./file-reference.model";

export class Message {
  id?: string;
  text: string;
  author: Author;
  created: Date | string;
  promptSettings: PromptSettings;
  parentId: string | undefined;
  children?: number;
  sources?: Source[];
  pages?: Page[];
  fileReferences?: FileReference[];
  imageRevisedPrompt?: string;
  imageUrl?: string;
  generatedFilesIds?: string[];

  constructor(message: string, author: Author, created: Date | string, promptSettings: PromptSettings);
  constructor(message: string, author: Author, created: Date | string, promptSettings: PromptSettings, parentId: string | undefined);

  public constructor(...args: any[]) {
    this.text = args[0];
    this.author = args[1];
    this.created = args[2];
    this.promptSettings = args[3];
    this.parentId = args[4];
  };
}

export class Author {
  role: string

  constructor(role: string) {
    this.role = role
  }
}

export type Source = {
  title: string;
  url: string;
  type: 'ASK_MY_DOCS' | 'ASK_OUR_DOCS' | 'WEB_SEARCH';
}
