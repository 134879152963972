import { Component, EventEmitter, Input, OnChanges, Output, ViewChild, ViewEncapsulation, } from "@angular/core";
import { Router } from "@angular/router";
import { featureFlags } from "@app/environments/environment";
import { UserData } from "@app/shared/models/user-data.model";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Agent, AgentType } from "@shared/models/agent";
import { KmdModalService, KmdPopoverComponent, KmdTooltipComponent } from "gds-atom-components";

@Component({
  selector: "app-agent-card",
  templateUrl: "./agent-card.component.html",
  styleUrls: ["./agent-card.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AgentCardComponent implements OnChanges {
  @Input() agent!: Agent;
  @Input() type!: AgentType;
  @Input() scrollDiv!: HTMLDivElement;
  @Input() isUserApprover: boolean = false;
  @Input() loggedInUser!: UserData;
  @Input() displayRating: boolean = false;
  @Input() isSmallList: boolean = false;
  @Input() isLastInList: boolean = false;

  @Output() deleteAgentEvent = new EventEmitter<Agent>();
  @Output() onViewAgentEvent = new EventEmitter<Agent>();
  @Output() onShareAgentEvent = new EventEmitter<Agent>();
  @Output() onRecommendAgentEvent = new EventEmitter<Agent>();

  @ViewChild("popOver") popOver!: KmdPopoverComponent;
  @ViewChild("toolTip") toolTip!: KmdTooltipComponent;

  protected readonly featureFlags = featureFlags;

  faEllipsis = faEllipsis;
  isSmallCard: boolean = false;

  constructor(
    private router: Router,
    private kmdModalService: KmdModalService,
  ) {
  }

  ngOnChanges(): void {
    this.isSmallCard = this.isSmallList;
  }

  get adminsString() {
    if (!this.agent.admins || this.agent.admins.length === 0) return '';
    const adminsNames = this.agent.admins.map(admin => `${admin.name} ${admin.lastname}`);
    const shouldAddEllipsis = adminsNames.length > 3;
    return shouldAddEllipsis
      ? adminsNames.slice(0, 3).join(', ') + '...'
      : adminsNames.join(', ');
  }

  get shareAgentOptionClass() {
    return {
      'share-agent-disabled': !this.agent.allowShare
    };
  }

  get showRating(): boolean {
    if (!this.displayRating) return false;

    if (!this.agent.rating) return false;

    return this.agent.rating.likes > 0;
  }

  get isRecommended(): boolean {
    return this.type == "public" && this.agent.isRecommended!;
  }

  get isLoggedInUserAdmin(): boolean {
    if (!this.agent.admins || this.agent.admins.length === 0) return false;

    return this.agent.admins.map(admin => admin.email).includes(this.loggedInUser.email);
  }

  get isPrivateAgent(): boolean {
    return this.type === "private";
  }

  startChat() {
    this.router.navigate([`/agents/${this.agent?.id}/chat`]);
  }

  openDeleteAgentDialog() {
    this.deleteAgentEvent.emit(this.agent);
    this.popOver.hide();
  }

  navigateToEditAgent() {
    this.router.navigate([`/agents/${this.agent.id}/edit`]);
  }

  showActionsMenu(event: MouseEvent) {
    this.popOver.toggle(event);
  }

  openShareAgentDialog(): void {
    if (this.agent.allowShare) {
      this.onShareAgentEvent.emit(this.agent);
      this.popOver.hide();
    }
  }

  isAgentShareable(): boolean {
    if (featureFlags.agentSharing) {
      return (this.agent?.allowShare === true || this.agent?.isPublic === true || !this.agent?.capabilities?.includes("ASK_OUR_DOCS"));
    } else {
      return this.agent?.allowShare === true || this.agent?.isPublic === true;
    }
  }

  isPublicAgent(): boolean {
    return this.type === "public";
  }

  isSharedAgent(): boolean {
    return this.type === "shared";
  }

  addToMyAgents() {
    this.router.navigate([`/agents/public/${this.agent?.id}/save`]);
  }

  viewAgent() {
    this.onViewAgentEvent.emit(this.agent);
    this.popOver.hide();
  }

  getCreatedBy(): string {
    return this.agent.createdBy!;
  }

  showPublicCreatedBy(): boolean {
    return this.isPublicAgent()
      && this.agent.createdBy !== null
      && this.agent.createdBy !== 'Unknown';
  }

  closeModal(modalId: string) {
    this.kmdModalService.close(modalId);
  }

  isUserAbleToRecommend(): boolean {
    return this.isUserApprover && !this.isRecommended;
  }

  isUserAbleToRemoveRecommendation(): boolean {
    return this.isUserApprover && this.isRecommended;
  }

  recommendAgent() {
    this.onRecommendAgentEvent.emit(this.agent);
    this.popOver.hide();
  }

  removeRecommendedAgent() {
    this.onRecommendAgentEvent.emit(this.agent);
    this.popOver.hide();
  }
}
