<div class="prompt-settings-wrapper">
  <app-drawer appClickOutsideDrawer [position]="'right'" (onStateChange)="onDrawerStageChange($event)"
              [ngClass]="{'out': drawerState === 'out' }">
    <span title class="settings-title">Prompt Settings</span>
    <div class="settings-container">
      <div class="setting" id="model-settings">
        <h3 class="title">Model</h3>
        <div id="model-options">
          <div class="model-options-wrapper">
            <div class="model-chips-container">
              <ng-container *ngFor="let option of modelOptions">
                <ng-container *featureFlag="'enableClaude'; else noBadge">
                  <ng-container *ngIf="option.title === 'Claude 3.5'">
                    <div class="model-item option-wrapper" (click)="onModelSelected(option)" [ngClass]="{'selected': isModelSelected(option)}">
                      <div class="claude-model-wrapper">
                        <app-chip class="models-chips" [text]="option.title"></app-chip>
                        <core-badge
                          class="beta-badge"
                          variant="standard"
                          size="regular"
                          color="green"
                          label="Beta">
                        </core-badge>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="option.title !== 'Claude 3.5'">
                    <div class="model-item option-wrapper" (click)="onModelSelected(option)" [ngClass]="{'selected': isModelSelected(option)}">
                      <app-chip class="models-chips" [text]="option.title"></app-chip>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noBadge>
                  <ng-container *ngIf="option.title !== 'Claude 3.5'">
                    <div class="model-item option-wrapper" (click)="onModelSelected(option)" [ngClass]="{'selected': isModelSelected(option)}">
                      <app-chip class="models-chips" [text]="option.title"></app-chip>
                    </div>
                  </ng-container>
                </ng-template>
              
              </ng-container>
              
              
            </div>
            <div class="selected-model-description">
              <p>{{ selectedModelDescription }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="setting" id="web-results-settings">
        <h3 class="title">Web Results</h3>
        <div id="web-results-toggle">
          <kmd-toggle [(ngModel)]="settings!.webSearch"></kmd-toggle>
          <label>Web Source Integrated</label>
        </div>
      </div>
      <div class="setting" id="saved-prompts-settings">
        <h3 class="title">Use saved prompts</h3>
        <p class="subtitle">Process of using a saved prompt</p>
        <core-button label="See saved prompts" type="button" variant="secondary" size="regular"
                     (click)="onSavedPromptsSelected()">
          Save
        </core-button>
      </div>
      <div class="setting" id="creativity-settings">
        <h3 class="title">Creativity</h3>
        <p class="subtitle">Set the response style from least variable (0) to most imaginative (5)</p>
        <div id="creativity-options">
          <div class="low-creativity-icon">🤖</div>
          <div class="creativity-options-wrapper">
            <div class="creativity-chips-container">
              <ng-container *ngFor="let option of creativityOptions;">
                <div class="creativity-item option-wrapper" [ngClass]="{'selected': isCreativitySelected(option)}">
                  <div class="chip-option-wrapper" (click)="onCreativitySelected(option)">
                    <app-chip [text]="option.toString()"></app-chip>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="creativity-descriptor-container">
              <span>Low</span>
              <span>High</span>
            </div>
          </div>
          <div class="creativity-item high-creativity-icon">🧑‍🎨</div>
        </div>
      </div>
    </div>
  </app-drawer>
</div>
