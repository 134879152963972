<form [formGroup]="form">
  <div id="chips-wrapper" class="field" (click)="focusOnInput()" [ngClass]="{'disabled': isBoxDisabled}">
    <ng-container *ngFor="let dChip of defaultChips; let i = index">
      <app-chip [text]="dChip.value" [type]="constantChipType" [closeable]="false" />
    </ng-container>
    <ng-container *ngFor="let chip of chips; let i = index">
      <app-chip [text]="chip.value" [type]="chip.type" [closeable]="chip.closeable" (close)="onChipRemove(i)" />
    </ng-container>
    @if (loading) {
      <kmd-loading-wheel></kmd-loading-wheel>
    }
    <input appNoSpaces #boxInput id="chips-input" (keydown)="onInputKeyDown($event)" formControlName="inputControl" type="text" autocomplete="off" />
  </div>
  <div *ngIf="config.showCount" id="chips-count">{{chipsCount}}/{{config.max}}</div>
</form>
