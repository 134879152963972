import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContextMenuItem } from '@app/shared/components/context-menu/context-menu';
import { ContextMenuComponent } from "@app/shared/components/context-menu/context-menu.component";
import { FileManagerService } from "@services/file-manager/file-manager.service";
import { FileManagerDoc } from "@shared/models/file-manager/file-manager-doc.model";
import { ModelFactory } from "@shared/models/gpt-models/model-factory";
import { Model as ModelSchema } from '@shared/models/gpt-models/model.model';
import { Model, modelIntegration, PromptSettings } from '@shared/models/prompt-settings.model';
import { PromptSubmitEvent } from '@shared/models/prompt-submit-event';
import { iif, mergeMap, of, take, tap } from 'rxjs';
import { modelList } from '../prompt-settings/prompt-settings.data';
import { defaultPromptInputConfig, failSafeMenuModel, getSubmitButtonStates, PromptInputConfig, SubmitActions, SubmitButtonState, SubmitButtonStates, SubmitButtonStatus } from './prompt-input-new';

@Component({
  selector: 'prompt-input-new',
  templateUrl: './prompt-input-new.component.html',
  styleUrls: ['./prompt-input-new.component.css']
})
export class PromptInputNewComponent implements OnInit, OnChanges {
  @ViewChild('modelMenu') modelMenu!: ContextMenuComponent;
  @ViewChild('promptTextarea') promptTextarea!: ElementRef;

  @Input() promptSettings!: PromptSettings;
  @Input() isStreamingResponse: boolean = false;
  @Input() config: PromptInputConfig = defaultPromptInputConfig;

  @Output() promptSubmit = new EventEmitter<PromptSubmitEvent>();
  @Output() promptSettingsClick = new EventEmitter();
  @Output() stopStreaming = new EventEmitter<any>();
  @Output() unsupportedFileFound = new EventEmitter<string>();

  private readonly submitActions: SubmitActions = {
    'inactive': () => {},
    'active': () => this.onPromptSubmit(),
    'stop': () => this.onStopStreaming()
  };
  private isMenuOpen = false;

  menuModels: ContextMenuItem[] = [];
  prompt: string = '';
  textTrimmed: boolean = false;
  // TODO: Change for an appropiate input. Might need collaboration with AOD team since they're using this property.
  responseGeneratedFlag: boolean = false; // for keeping the send button disabled until the response generation is completed
  selectedMenuModel!: ContextMenuItem;
  selectedModelSchema!: ModelSchema;
  selectedFileType: string = '';;
  submitButtonState!: SubmitButtonState;
  submitButtonStates: SubmitButtonStates = getSubmitButtonStates(this.submitActions);

  constructor(
    private modelFactory: ModelFactory,
    private fileManagerService: FileManagerService
  ) { }

  @HostListener('input')
  onInputChange() {
    this.trimPromptInput();
  }

  ngOnInit() {
    this.loadMenuModels();
    this.checkForUnsupportedFiles();
    this.updateSubmitButtonState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isStreamingResponse']) {
      this.updateSubmitButtonState();
    }

    this.fileManagerService.selectedFiles$
      .pipe(
        this.handleSelectedFiles()
      ).subscribe({
        next: () => this.loadMenuModels()
      });
  }

  //#region Model Menu

  loadMenuModels() {
    if (!this.selectedFileType) {
      this.setDefaultFileType();
    }

    this.menuModels = this.buildMenuModels();
    this.setSelectedMenuModel();
  }

  private buildMenuModels() {
    return modelList
      .filter(model => model.integrations.includes(this.selectedFileType as modelIntegration))
      .map(model => ({
        id: model.model,
        label: this.getModelLabel(model.model),
        description: model.description
      }));
  }

  private getModelLabel(model: Model) {
    switch (model) {
      case 'gpt-4-o':
        return 'GPT-4o';
      case 'claude-3-5-sonnet':
        return 'Claude 3.5';
      default:
        console.error(`Unhandled model ${model} found. Setting as Unknown.`);
        return 'Unknown';
    }
  }

  private setSelectedMenuModel() {
    this.selectedMenuModel = this.menuModels.find(item => item.id === this.promptSettings.model)
      || failSafeMenuModel;
    this.selectedModelSchema = this.modelFactory.create(this.promptSettings.model);
  }

  setModel(item: ContextMenuItem) {
    this.promptSettings.model = item.id as Model;
    this.setSelectedMenuModel();
    this.updateSubmitButtonState();
  }

  toggleModelMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.modelMenu.toggleMenu();
  }

  setModelMenuState(isOpen: boolean) {
    this.isMenuOpen = isOpen;
  }

  closeModelMenu() {
    this.isMenuOpen = false;
    this.modelMenu.closeMenu();
  }

  get modelMenuImageSrc() {
    return this.isMenuOpen
      ? '../../../../assets/icons/caret/caretUp-11px-mono-black.svg'
      : '../../../../assets/icons/caret/caretDown-11px-mono-black.svg'
  }

  get showTooltip() {
    return !this.isMenuOpen;
  }

  //#endregion Model Menu

  //#region Files

  private setSelectedFileType$() {
    return this.fileManagerService.selectedFileType$.pipe(
      tap(selectedFileType => this.selectedFileType = selectedFileType.fileType)
    );
  }

  private setDefaultFileType() {
    this.selectedFileType = 'text';
  }

  private handleSelectedFiles() {
    return mergeMap((files: FileManagerDoc[]) => iif(
      () => files.length > 0,
      this.setSelectedFileType$(),
      this.handleFixedFiles()
    ));
  }

  private handleFixedFiles() {
    return this.fileManagerService.fixedFiles$.pipe(
      mergeMap((files) => iif(
        () => files.length > 0,
        this.setSelectedFileType$(),
        this.setDefaultFileType$()
      ))
    );
  }

  private setDefaultFileType$() {
    return of(null).pipe(
      tap(() => this.setDefaultFileType())
    );
  }

  checkForUnsupportedFiles(): void {
    this.fileManagerService.isUnsupportedFileSelected$
      .pipe(take(1))
      .subscribe({
        next: (response) => response.forEach(data => {
          if (data.isUnsupported) {
            this.fileManagerService.removePreSelectedFile(data.file);
            this.unsupportedFileFound.emit(data.file.extension);
          }
        })
      });
  };

  private removeFilesWithError() {
    const { error } = this.fileManagerService.fileStatusIds;
    this.fileManagerService.selectedFiles$.next(
      this.selectedFiles.filter(file => !error.includes(file.id!))
    );
    this.fileManagerService.selectedFiles$.next(this.selectedFiles);
  }

  private isProcessingFiles(): boolean {
    const { processed, error } = this.fileManagerService.fileStatusIds;
    if (this.selectedFiles.length === 0) {
      return false;
    }

    return this.selectedFiles.some(file => !processed.includes(file.id!) && !error.includes(file.id!));
  }

  private getFiles() {
    return this.fileManagerService.getMixedSelectedFiles()
      .map(file => file.id)
      .filter((id): id is string => id !== undefined);
  }

  get selectedFiles() {
    return this.fileManagerService.selectedFiles$.value;
  }

  //#endregion Files

  //#region Prompt

  setPromptInInput(prompt: string) {
    this.prompt = prompt;
    this.promptTextarea.nativeElement.focus();
    this.onInputChange();
    this.updateSubmitButtonState();
  }

  onPromptSettingsClick() {
    this.promptSettingsClick.emit();
  }

  onPromptKeydownEnter(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.submitButtonState.onClick();
    }
  }

  onPromptSubmit(): void {
    this.removeFilesWithError();
    this.promptSubmit.emit({
      prompt: this.prompt,
      settings: this.promptSettings,
      files: this.getFiles()
    });
  }

  private trimPromptInput() {
    if (this.isPromptLengthExceeded) {
      this.trimTextToModelLimit();
      this.textTrimmed = true;
    } else {
      this.textTrimmed = false;
    }
  }

  private trimTextToModelLimit() {
    this.prompt = this.prompt.trim().substring(0, this.maxInputCharLimit);
  }

  get isPromptLengthExceeded() {
    return (this.prompt.length > this.maxInputCharLimit);
  }

  get isTextTrimmed() {
    return this.textTrimmed && this.prompt.length == this.maxInputCharLimit;
  }

  get maxInputCharLimit() {
    return this.selectedModelSchema.MAX_NUMBER_OF_CHARACTERS;
  }

  get promptWrapperCss() {
    return {
      'populated': this.prompt.length > 0,
      'error': this.isPromptLengthExceeded
    }
  }

  //#endregion Prompt

  //#region Submit Button

  get submitButtonStatus(): SubmitButtonStatus {
    if (this.isStreamingResponse) {
      return 'stop';
    } else if (
      this.prompt.trim() === ''
      || this.isPromptLengthExceeded
      || this.isProcessingFiles()
      || this.responseGeneratedFlag
    ) {
      return 'inactive';
    } else {
      return 'active';
    }
  }

  updateSubmitButtonState(): void {
    switch (this.submitButtonStatus) {
      case 'inactive':
        this.disableSendButton();
        break;
      case 'active':
        this.enableSendButton();
        break;
      case 'stop':
        this.setStopStreamingButton();
        break;
      default:
        console.error("Invalid status.");
        break;
    }
  }

  private enableSendButton() {
    this.submitButtonState = this.submitButtonStates.get('active')!;
  }

  private disableSendButton() {
    this.submitButtonState = this.submitButtonStates.get('inactive')!;
  }

  private setStopStreamingButton() {
    this.submitButtonState = this.submitButtonStates.get('stop')!;
  }

  //#endregion Submit Button

  showCounter() {
    return (this.selectedModelSchema.MAX_NUMBER_OF_CHARACTERS - this.prompt.length <= 1000);
  }

  private onStopStreaming() {
    this.stopStreaming.emit();
  }
}
