import { InjectionToken } from '@angular/core';

export interface Language {
  code: string;
  displayName: string;
}

export interface LanguageCombinations {
  sourceLanguages: Language[];
  targetCombinations: { [sourceCode: string]: Language[] };
}

export const LANGUAGE_DATA: LanguageCombinations = {
  sourceLanguages: [
    {
      "code": "af",
      "displayName": "Afrikaans"
    },
    {
      "code": "sq",
      "displayName": "Albanian"
    },
    {
      "code": "am",
      "displayName": "Amharic"
    },
    {
      "code": "ar",
      "displayName": "Arabic"
    },
    {
      "code": "hy",
      "displayName": "Armenian"
    },
    {
      "code": "as",
      "displayName": "Assamese"
    },
    {
      "code": "az-Cyrl-AZ",
      "displayName": "Azerbaijani (Cyrillic)"
    },
    {
      "code": "az-Latn-AZ",
      "displayName": "Azerbaijani (Latin)"
    },
    {
      "code": "be",
      "displayName": "Belarusian"
    },
    {
      "code": "bn",
      "displayName": "Bengali"
    },
    {
      "code": "bs",
      "displayName": "Bosnian"
    },
    {
      "code": "bg",
      "displayName": "Bulgarian"
    },
    {
      "code": "my",
      "displayName": "Burmese"
    },
    {
      "code": "ca",
      "displayName": "Catalan"
    },
    {
      "code": "zh-Hans",
      "displayName": "Chinese (Simplified)"
    },
    {
      "code": "zh-Hant",
      "displayName": "Chinese (Traditional)"
    },
    {
      "code": "hr",
      "displayName": "Croatian"
    },
    {
      "code": "cs",
      "displayName": "Czech"
    },
    {
      "code": "da",
      "displayName": "Danish"
    },
    {
      "code": "prs",
      "displayName": "Dari"
    },
    {
      "code": "nl",
      "displayName": "Dutch"
    },
    {
      "code": "en",
      "displayName": "English"
    },
    {
      "code": "et",
      "displayName": "Estonian"
    },
    {
      "code": "fj",
      "displayName": "Fijian"
    },
    {
      "code": "fil",
      "displayName": "Filipino"
    },
    {
      "code": "fi",
      "displayName": "Finnish"
    },
    {
      "code": "fr",
      "displayName": "French"
    },
    {
      "code": "fr-CA",
      "displayName": "French (Canada)"
    },
    {
      "code": "gl",
      "displayName": "Galician"
    },
    {
      "code": "ka",
      "displayName": "Georgian"
    },
    {
      "code": "de",
      "displayName": "German"
    },
    {
      "code": "el",
      "displayName": "Greek"
    },
    {
      "code": "gu",
      "displayName": "Gujarati"
    },
    {
      "code": "ht",
      "displayName": "Haitian Creole"
    },
    {
      "code": "ha",
      "displayName": "Hausa"
    },
    {
      "code": "he",
      "displayName": "Hebrew"
    },
    {
      "code": "hi",
      "displayName": "Hindi"
    },
    {
      "code": "hmn",
      "displayName": "Hmong"
    },
    {
      "code": "hu",
      "displayName": "Hungarian"
    },
    {
      "code": "is",
      "displayName": "Icelandic"
    },
    {
      "code": "ig",
      "displayName": "Igbo"
    },
    {
      "code": "id",
      "displayName": "Indonesian"
    },
    {
      "code": "ga",
      "displayName": "Irish"
    },
    {
      "code": "it",
      "displayName": "Italian"
    },
    {
      "code": "ja",
      "displayName": "Japanese"
    },
    {
      "code": "kn",
      "displayName": "Kannada"
    },
    {
      "code": "kk",
      "displayName": "Kazakh"
    },
    {
      "code": "km",
      "displayName": "Khmer"
    },
    {
      "code": "rw",
      "displayName": "Kinyarwanda"
    },
    {
      "code": "ko",
      "displayName": "Korean"
    },
    {
      "code": "ky",
      "displayName": "Kyrgyz"
    },
    {
      "code": "lo",
      "displayName": "Lao"
    },
    {
      "code": "lv",
      "displayName": "Latvian"
    },
    {
      "code": "lt",
      "displayName": "Lithuanian"
    },
    {
      "code": "lb",
      "displayName": "Luxembourgish"
    },
    {
      "code": "mk-MK",
      "displayName": "Macedonian"
    },
    {
      "code": "mg",
      "displayName": "Malagasy"
    },
    {
      "code": "ms",
      "displayName": "Malay"
    },
    {
      "code": "ml",
      "displayName": "Malayalam"
    },
    {
      "code": "mt",
      "displayName": "Maltese"
    },
    {
      "code": "mi",
      "displayName": "Maori"
    },
    {
      "code": "mr",
      "displayName": "Marathi"
    },
    {
      "code": "mn",
      "displayName": "Mongolian"
    },
    {
      "code": "ne",
      "displayName": "Nepali"
    },
    {
      "code": "no",
      "displayName": "Norwegian"
    },
    {
      "code": "or",
      "displayName": "Odia"
    },
    {
      "code": "ps",
      "displayName": "Pashto"
    },
    {
      "code": "fa",
      "displayName": "Persian"
    },
    {
      "code": "pl",
      "displayName": "Polish"
    },
    {
      "code": "pt-BR",
      "displayName": "Portuguese (Brazil)"
    },
    {
      "code": "pt-PT",
      "displayName": "Portuguese (Portugal)"
    },
    {
      "code": "pa",
      "displayName": "Punjabi"
    },
    {
      "code": "ro",
      "displayName": "Romanian"
    },
    {
      "code": "ru",
      "displayName": "Russian"
    },
    {
      "code": "sr",
      "displayName": "Serbian"
    },
    {
      "code": "st",
      "displayName": "Sesotho"
    },
    {
      "code": "sn",
      "displayName": "Shona"
    },
    {
      "code": "si",
      "displayName": "Sinhala"
    },
    {
      "code": "sk",
      "displayName": "Slovak"
    },
    {
      "code": "sl",
      "displayName": "Slovenian"
    },
    {
      "code": "so",
      "displayName": "Somali"
    },
    {
      "code": "es-419",
      "displayName": "Spanish (Latin America)"
    },
    {
      "code": "es-ES",
      "displayName": "Spanish (Spain)"
    },
    {
      "code": "sw",
      "displayName": "Swahili"
    },
    {
      "code": "sv",
      "displayName": "Swedish"
    },
    {
      "code": "tl",
      "displayName": "Tagalog"
    },
    {
      "code": "tg",
      "displayName": "Tajik"
    },
    {
      "code": "ta",
      "displayName": "Tamil"
    },
    {
      "code": "tt",
      "displayName": "Tatar"
    },
    {
      "code": "te",
      "displayName": "Telugu"
    },
    {
      "code": "th",
      "displayName": "Thai"
    },
    {
      "code": "bo",
      "displayName": "Tibetan"
    },
    {
      "code": "ti",
      "displayName": "Tigrinya"
    },
    {
      "code": "tr",
      "displayName": "Turkish"
    },
    {
      "code": "tk",
      "displayName": "Turkmen"
    },
    {
      "code": "uk",
      "displayName": "Ukrainian"
    },
    {
      "code": "ur",
      "displayName": "Urdu"
    },
    {
      "code": "vi",
      "displayName": "Vietnamese"
    },
    {
      "code": "cy",
      "displayName": "Welsh"
    },
    {
      "code": "xh",
      "displayName": "Xhosa"
    },
    {
      "code": "yo",
      "displayName": "Yoruba"
    },
    {
      "code": "zu",
      "displayName": "Zulu"
    }
  ],
  targetCombinations: {
    "af": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "sq": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "am": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ar": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "hy": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "as": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "az-Cyrl-AZ": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "az-Latn-AZ": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "be": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "bn": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "bs": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "bg": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "my": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ca": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "zh-Hans": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "zh-Hant": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "hr": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "cs": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "da": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "prs": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "nl": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "en": [
      {
        "code": "af-ZA",
        "displayName": "Afrikaans (South Africa)"
      },
      {
        "code": "sq-AL",
        "displayName": "Albanian (Albania)"
      },
      {
        "code": "am-ET",
        "displayName": "Amharic (Ethiopia)"
      },
      {
        "code": "ar-EG",
        "displayName": "Arabic (Egypt)"
      },
      {
        "code": "ar-IL",
        "displayName": "Arabic (Israel)"
      },
      {
        "code": "ar-JO",
        "displayName": "Arabic (Jordan)"
      },
      {
        "code": "ar-LB",
        "displayName": "Arabic (Lebanon)"
      },
      {
        "code": "ar-MA",
        "displayName": "Arabic (Morocco)"
      },
      {
        "code": "ar-TN",
        "displayName": "Arabic (Tunisia)"
      },
      {
        "code": "ar-AE",
        "displayName": "Arabic (United Arab Emirates)"
      },
      {
        "code": "hy-AM",
        "displayName": "Armenian (Armenia)"
      },
      {
        "code": "as-IN",
        "displayName": "Assamese (India)"
      },
      {
        "code": "az-Cyrl-AZ",
        "displayName": "Azerbaijani (Cyrillic"
      },
      {
        "code": "az-Latn-AZ",
        "displayName": "Azerbaijani (Latin"
      },
      {
        "code": "be-BY",
        "displayName": "Belarusian (Belarus)"
      },
      {
        "code": "bn-BD",
        "displayName": "Bengali (Bangladesh)"
      },
      {
        "code": "bn-IN",
        "displayName": "Bengali (India)"
      },
      {
        "code": "bs-Cyrl-BA",
        "displayName": "Bosnian (Cyrillic"
      },
      {
        "code": "bs-Latn-BA",
        "displayName": "Bosnian (Latin"
      },
      {
        "code": "bg-BG",
        "displayName": "Bulgarian (Bulgaria)"
      },
      {
        "code": "my-MM",
        "displayName": "Burmese (Myanmar)"
      },
      {
        "code": "ca-ES",
        "displayName": "Catalan (Spain)"
      },
      {
        "code": "zh-CN",
        "displayName": "Chinese (Simplified)"
      },
      {
        "code": "zh-SG",
        "displayName": "Chinese (Singapore)"
      },
      {
        "code": "zh-HK",
        "displayName": "Chinese (HongKong)"
      },
      {
        "code": "zh-MO",
        "displayName": "Chinese (Macao)"
      },
      {
        "code": "zh-TW",
        "displayName": "Chinese (Traditional)"
      },
      {
        "code": "hr-HR",
        "displayName": "Croatian (Croatia)"
      },
      {
        "code": "hr-BA",
        "displayName": "Croatian (Bosnia and Herzegovina)"
      },
      {
        "code": "cs-CZ",
        "displayName": "Czech (Czech Republic)"
      },
      {
        "code": "da-DK",
        "displayName": "Danish (Denmark)"
      },
      {
        "code": "prs-AF",
        "displayName": "Dari (Afghanistan)"
      },
      {
        "code": "nl-BE",
        "displayName": "Dutch (Belgium)"
      },
      {
        "code": "nl-NL",
        "displayName": "Dutch (Netherlands)"
      },
      {
        "code": "et-EE",
        "displayName": "Estonian (Estonia)"
      },
      {
        "code": "fj",
        "displayName": "Fijian"
      },
      {
        "code": "fil-PH",
        "displayName": "Filipino (Philippines)"
      },
      {
        "code": "fi-FI",
        "displayName": "Finnish (Finland)"
      },
      {
        "code": "fr-BE",
        "displayName": "French (Belgium)"
      },
      {
        "code": "fr-CA",
        "displayName": "French (Canada)"
      },
      {
        "code": "fr-FR",
        "displayName": "French (France)"
      },
      {
        "code": "fr-CH",
        "displayName": "French (Switzerland)"
      },
      {
        "code": "gl-ES",
        "displayName": "Galician (Spain)"
      },
      {
        "code": "ka-GE",
        "displayName": "Georgian (Georgia)"
      },
      {
        "code": "de-AT",
        "displayName": "German (Austria)"
      },
      {
        "code": "de-BE",
        "displayName": "German (Belgium)"
      },
      {
        "code": "de-DE",
        "displayName": "German (Germany)"
      },
      {
        "code": "de-CH",
        "displayName": "German (Switzerland)"
      },
      {
        "code": "el-GR",
        "displayName": "Greek (Greece)"
      },
      {
        "code": "gu-IN",
        "displayName": "Gujarati (India)"
      },
      {
        "code": "ht",
        "displayName": "Haitian Creole"
      },
      {
        "code": "ha-Latn-NG",
        "displayName": "Hausa (Latin"
      },
      {
        "code": "he-IL",
        "displayName": "Hebrew (Israel)"
      },
      {
        "code": "hi-IN",
        "displayName": "Hindi (India)"
      },
      {
        "code": "hmn",
        "displayName": "Hmong"
      },
      {
        "code": "hu-HU",
        "displayName": "Hungarian (Hungary)"
      },
      {
        "code": "is-IS",
        "displayName": "Icelandic (Iceland)"
      },
      {
        "code": "ig-NG",
        "displayName": "Igbo (Nigeria)"
      },
      {
        "code": "id-ID",
        "displayName": "Indonesian (Indonesia)"
      },
      {
        "code": "ga",
        "displayName": "Irish"
      },
      {
        "code": "xh-ZA",
        "displayName": "Xhosa (South Africa)"
      },
      {
        "code": "it-IT",
        "displayName": "Italian (Italy)"
      },
      {
        "code": "it-CH",
        "displayName": "Italian (Switzerland)"
      },
      {
        "code": "ja-JP",
        "displayName": "Japanese (Japan)"
      },
      {
        "code": "kn-IN",
        "displayName": "Kannada (India)"
      },
      {
        "code": "kk-KZ",
        "displayName": "Kazakh (Kazakhstan)"
      },
      {
        "code": "km-KH",
        "displayName": "Khmer (Cambodia)"
      },
      {
        "code": "rw-RW",
        "displayName": "Kinyarwanda (Rwanda)"
      },
      {
        "code": "ko-KR",
        "displayName": "Korean (South Korea)"
      },
      {
        "code": "ky-KG",
        "displayName": "Kyrgyz (Kyrgyzstan)"
      },
      {
        "code": "lo-LA",
        "displayName": "Lao (Laos)"
      },
      {
        "code": "lv-LV",
        "displayName": "Latvian (Latvia)"
      },
      {
        "code": "lt-LT",
        "displayName": "Lithuanian (Lithuania)"
      },
      {
        "code": "lb-LU",
        "displayName": "Luxembourgish (Luxembourg)"
      },
      {
        "code": "mk-MK",
        "displayName": "Macedonian (North Macedonia)"
      },
      {
        "code": "mg",
        "displayName": "Malagasy"
      },
      {
        "code": "ms-MY",
        "displayName": "Malay (Malaysia)"
      },
      {
        "code": "ml-IN",
        "displayName": "Malayalam (India)"
      },
      {
        "code": "mt-MT",
        "displayName": "Maltese (Malta)"
      },
      {
        "code": "mi-NZ",
        "displayName": "Maori (New Zealand)"
      },
      {
        "code": "mr-IN",
        "displayName": "Marathi (India)"
      },
      {
        "code": "mn-MN",
        "displayName": "Mongolian (Mongolia)"
      },
      {
        "code": "ne-NP",
        "displayName": "Nepali (Nepal)"
      },
      {
        "code": "nb",
        "displayName": "Norwegian (BokmÃ¥l)"
      },
      {
        "code": "or-IN",
        "displayName": "Odia (India)"
      },
      {
        "code": "ps-AF",
        "displayName": "Pashto (Afghanistan)"
      },
      {
        "code": "fa",
        "displayName": "Persian"
      },
      {
        "code": "pl-PL",
        "displayName": "Polish (Poland)"
      },
      {
        "code": "pt-BR",
        "displayName": "Portuguese (Brazil)"
      },
      {
        "code": "pt-PT",
        "displayName": "Portuguese (Portugal)"
      },
      {
        "code": "pa-IN",
        "displayName": "Punjabi (India)"
      },
      {
        "code": "ro-MD",
        "displayName": "Romanian (Moldova)"
      },
      {
        "code": "ro-RO",
        "displayName": "Romanian (Romania)"
      },
      {
        "code": "ru-GE",
        "displayName": "Russian (Georgia)"
      },
      {
        "code": "ru-IL",
        "displayName": "Russian (Israel)"
      },
      {
        "code": "ru-LV",
        "displayName": "Russian (Latvia)"
      },
      {
        "code": "ru-LT",
        "displayName": "Russian (Lithuania)"
      },
      {
        "code": "ru-MD",
        "displayName": "Russian (Moldova)"
      },
      {
        "code": "ru-RU",
        "displayName": "Russian (Russia)"
      },
      {
        "code": "ru-UA",
        "displayName": "Russian (Ukraine)"
      },
      {
        "code": "sr-Cyrl-RS",
        "displayName": "Serbian (Cyrillic"
      },
      {
        "code": "sr-Latn",
        "displayName": "Serbian (Latin)"
      },
      {
        "code": "st-ZA",
        "displayName": "Sesotho (South Africa)"
      },
      {
        "code": "sn",
        "displayName": "Shona"
      },
      {
        "code": "si-LK",
        "displayName": "Sinhala (Sri Lanka)"
      },
      {
        "code": "sk-SK",
        "displayName": "Slovak (Slovakia)"
      },
      {
        "code": "sl-SI",
        "displayName": "Slovenian (Slovenia)"
      },
      {
        "code": "so",
        "displayName": "Somali"
      },
      {
        "code": "es-AR",
        "displayName": "Spanish (Argentina)"
      },
      {
        "code": "es-CL",
        "displayName": "Spanish (Chile)"
      },
      {
        "code": "es-CO",
        "displayName": "Spanish (Colombia)"
      },
      {
        "code": "es-CR",
        "displayName": "Spanish (Costa Rica)"
      },
      {
        "code": "es-DO",
        "displayName": "Spanish (Dominican Republic)"
      },
      {
        "code": "es-EC",
        "displayName": "Spanish (Ecuador)"
      },
      {
        "code": "es-SV",
        "displayName": "Spanish (El Salvador)"
      },
      {
        "code": "es-GT",
        "displayName": "Spanish (Guatemala)"
      },
      {
        "code": "es-HN",
        "displayName": "Spanish (Honduras)"
      },
      {
        "code": "es-419",
        "displayName": "Spanish (Latin America)"
      },
      {
        "code": "es-MX",
        "displayName": "Spanish (Mexico)"
      },
      {
        "code": "es-NI",
        "displayName": "Spanish (Nicaragua)"
      },
      {
        "code": "es-PA",
        "displayName": "Spanish (Panama)"
      },
      {
        "code": "es-PE",
        "displayName": "Spanish (Peru)"
      },
      {
        "code": "es-PR",
        "displayName": "Spanish (Puerto Rico)"
      },
      {
        "code": "es-ES",
        "displayName": "Spanish (Spain)"
      },
      {
        "code": "es-US",
        "displayName": "Spanish (United States)"
      },
      {
        "code": "sw-KE",
        "displayName": "Swahili (Kenya)"
      },
      {
        "code": "sv-FI",
        "displayName": "Swedish (Finland)"
      },
      {
        "code": "sv-SE",
        "displayName": "Swedish (Sweden)"
      },
      {
        "code": "tl-PH",
        "displayName": "Tagalog (Philippines)"
      },
      {
        "code": "tg-Cyrl-TJ",
        "displayName": "Tajik (Cyrillic"
      },
      {
        "code": "ta-IN",
        "displayName": "Tamil (India)"
      },
      {
        "code": "ta-LK",
        "displayName": "Tamil (Sri Lanka)"
      },
      {
        "code": "tt-RU",
        "displayName": "Tatar (Russia)"
      },
      {
        "code": "te-IN",
        "displayName": "Telugu (India)"
      },
      {
        "code": "th-TH",
        "displayName": "Thai (Thailand)"
      },
      {
        "code": "bo-CN",
        "displayName": "Tibetan (China)"
      },
      {
        "code": "ti",
        "displayName": "Tigrinya"
      },
      {
        "code": "tr-TR",
        "displayName": "Turkish (Turkey)"
      },
      {
        "code": "tk-TM",
        "displayName": "Turkmen (Turkmenistan)"
      },
      {
        "code": "uk-UA",
        "displayName": "Ukrainian (Ukraine)"
      },
      {
        "code": "ur",
        "displayName": "Urdu"
      },
      {
        "code": "vi-VN",
        "displayName": "Vietnamese (Vietnam)"
      },
      {
        "code": "cy",
        "displayName": "Welsh"
      },
      {
        "code": "yo-NG",
        "displayName": "Yoruba (Nigeria)"
      },
      {
        "code": "zu-ZA",
        "displayName": "Zulu (South Africa)"
      }
    ],
    "et": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "fj": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "fil": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "fi": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "fr": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      },
      {
        "code": "de-DE",
        "displayName": "German (Germany)"
      }
    ],
    "fr-CA": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "gl": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ka": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "de": [
      {
        "code": "en",
        "displayName": "English"
      },
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      },
      {
        "code": "fr-FR",
        "displayName": "French (France)"
      },
      {
        "code": "it-IT",
        "displayName": "Italian (Italy)"
      }
    ],
    "el": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "gu": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ht": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ha": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "he": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "hi": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "hmn": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "hu": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "is": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ig": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "id": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ga": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "xh": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "it": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      },
      {
        "code": "de-DE",
        "displayName": "German (Germany)"
      }
    ],
    "ja": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "kn": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "kk": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "km": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "rw": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ko": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ky": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "lo": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "lv": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "lt": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "lb": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "mk-MK": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "mg": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ms": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ml": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "mt": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "mi": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "mr": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "mn": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ne": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "no": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "or": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ps": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "fa": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "pl": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "pt-BR": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "pt-PT": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      },
      {
        "code": "de-DE",
        "displayName": "German (Germany)"
      }
    ],
    "pa": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ro": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ru": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "sr": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "st": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "sn": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "si": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "sk": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "sl": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "so": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "es-419": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "es-ES": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "sw": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "sv": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "tl": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "tg": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ta": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "tt": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "te": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "th": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "bo": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ti": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "tr": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "tk": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "uk": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "ur": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "vi": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "cy": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "yo": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ],
    "zu": [
      {
        "code": "en-GB",
        "displayName": "English (United Kingdom)"
      },
      {
        "code": "en-US",
        "displayName": "English (United States)"
      }
    ]
  }
};

export const LANGUAGE_COMBINATIONS = new InjectionToken<LanguageCombinations>('LANGUAGE_COMBINATIONS', {
  factory: () => LANGUAGE_DATA
});
