import { HttpErrorResponse } from "@angular/common/http";
import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentsService } from "@services/agents/agents.service";
import { AlertService } from '@services/alert/alert.service';
import { Agent } from "@shared/models/agent";
import { FileUpload } from '@shared/models/file-upload.model';
import { KmdModalService } from "gds-atom-components";
import { mergeMap, Observer } from "rxjs";
import { map } from "rxjs/operators";
import { AgentFormComponent } from "../agent-form/agent-form.component";
import { AgentSharingModalComponent } from '../agent-sharing/agent-sharing-modal/agent-sharing-modal.component';
import { SharingAgentService } from "@services/sharing-agent/sharing-agent.service";
import { ShareOptionsService } from "@services/share-options/share-options.service";
import { AgentShareOption, AgentShareOptionId } from "@app/shared/models/agent-share-option.model";
import { WindowRef } from "@app/core/services/window/window-ref.service";


@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AgentEditComponent implements OnInit, OnDestroy {
  @ViewChild('agentForm') agentForm!: AgentFormComponent;
  @ViewChild('agentSharingModal') agentSharingModal!: AgentSharingModalComponent;

  validForm: boolean = false;
  isAodEnabled: boolean = false;
  isAmdValid: boolean = true;
  isSaveAndShareValid: boolean = false;
  agent?: Agent;
  showExampleButton = true
  showExampleAgent = false;
  showAgentEditForm = true;
  sharingAgent = false;
  sharingDetails: { agent: Agent, selectedFiles: FileUpload[] } | undefined;
  windowWidth = WindowRef.innerWidth;

  private msGroupOption: AgentShareOption = { id: 'microsoft-group', label: 'Team (Microsoft Group)'}
  readonly makePublicOptionID: AgentShareOptionId = 'make-public';
  readonly msGroupOptionID: AgentShareOptionId = 'microsoft-group';


  constructor(
    private activatedRoute: ActivatedRoute,
    private agentsService: AgentsService,
    private modalService: KmdModalService,
    private alertService: AlertService,
    private router: Router,
    private sharingAgentService: SharingAgentService,
    private shareOptionsService: ShareOptionsService
  ) { };

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = (event.target as Window).innerWidth;
    this.showAgentEditForm = !(this.windowWidth <= 1200 && this.showExampleAgent);
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      map(params => params['agentId']),
      mergeMap(agentId => this.agentsService.agent(agentId))
    ).subscribe({
      next: (agent: Agent) => this.setAgentData(agent),
      error: () => {
        this.router.navigate(['/error']);
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }

  setAgentData(agent: Agent) {
    this.agent = agent;
    this.sharingDetails = {
      agent: agent,
      selectedFiles: []
    };

    if (this.sharingAgentService.getFailingAdminMail()
        && this.sharingAgentService.getSharingGroup()) {
      this.saveAndShareAgent(agent);
      this.agentSharingModal.onShareToDropdownClick();
      this.agentSharingModal.onSelectionChanged(this.msGroupOption);
      this.agentSharingModal.onShareToDropdownOutsideClick();
      this.agentSharingModal.onGroupNameInputFocus();
      this.agentSharingModal.onGroupItemClick(this.sharingAgentService.getSharingGroup());

      if (this.sharingAgentService.getSelectedGroupAdminsMails().length > 0) {
        this.agentSharingModal.addAdmins(this.sharingAgentService.getSelectedGroupAdminsMails());
      }

      this.agentSharingModal.addFailedAdmin(this.sharingAgentService.getFailingAdminMail());
      this.sharingAgentService.clearAll();
    }
  }

  formStatusChanged(valid: boolean) {
    this.validForm = valid
  }

  publishAndChat() {
    this.agentForm.submit();
  }

  submitAndShareAgent() {
    this.sharingDetails = {
      agent: this.agentForm.agent!,
      selectedFiles: this.agentForm.selectedFiles
    };
    this.sharingAgent = true;
    this.publishAndChat();
  }

  saveAgent(agent: Agent) {
    if (this.sharingAgent) {
      this.sharingAgent = false;
      return this.saveAndShareAgent(agent);
    }

    let update$ = (agent.publishedByUser)
      ? this.agentsService.updatePublicAgent(agent)
      : this.agentsService.update(agent);

    update$.subscribe(this.publishAndChatResponseHandler);
  }

  saveAndShareAgent(agent: Agent): void {
    this.sharingDetails = {
      agent: agent,
      selectedFiles: this.agentForm.selectedFiles
    };

    if (agent.azureGroupId && agent.azureGroupId !== '') {
      this.shareOptionsService.updateOption(this.msGroupOptionID, { disabled: true, hideOption: true });
      this.shareOptionsService.updateOption(this.makePublicOptionID, { disabled: true, hideOption: true });
    }

    this.openAgentSharingModal();
  };

  setAskMyDocsState(enabled: boolean) {
    if (enabled) {
      this.shareOptionsService.disableOption(this.makePublicOptionID);
    }
    else {
      this.shareOptionsService.enableOption(this.makePublicOptionID);
    }
  }

  openAgentSharingModal() {
    this.agentSharingModal.openModal();
  }

  get publishAndChatResponseHandler(): Partial<Observer<Agent>> {
    return {
      next: (agent: Agent) => {
        this.agent = agent;
        this.alertService.showSuccess("Changes applied.")
        this.navigateToChatWithAgent();
      },
      error: (err: HttpErrorResponse) => {
        this.alertService.showError(this.errorMessage(err));
      }
    }
  };

  updateSaveAndShareStatus(valid: boolean) {
    this.isSaveAndShareValid = valid;
  }

  updateAodStatus(aodEnabled: boolean) {
    this.isAodEnabled = aodEnabled;
  }

  navigateToAgents() {
    this.modalService.open("cancel-edit-modal");
  }

  navigateToChatWithAgent() {
    this.router.navigate([`/agents/${this.agent?.id}/chat`])
  }

  discardChanges() {
    if (this.agent?.isPublic) {
      return this.router.navigate(['/agents'], { queryParams: { tab: 'public-agents' } });
    }

    if (this.agent!.agentType === 'SHARED') {
      return this.navigateToSharedWithMeAgents();
    }

    this.navigateToMyAgents();
  }

  continueEditing() {
    this.modalService.close("cancel-edit-modal");
  }

  toggleAgentExample() {
    this.showExampleButton = !this.showExampleButton;
    this.showExampleAgent = !this.showExampleAgent;
    this.showAgentEditForm = !(this.windowWidth <= 1200 && this.showExampleAgent);
  }

  navigateToSharedWithMeAgents() {
    this.router.navigate(['/agents'], { queryParams: { tab: 'shared-agents' }, replaceUrl: true });
  };

  navigateToMyAgents() {
    this.router.navigate(['/agents']);
  };

  private errorMessage(err: HttpErrorResponse) {
    if (err.status === 409) {
      return "You already have an agent with this name. Please use a unique name for your agent."
    } else {
      return "An error occurred while updating the agent. Please try again later."
    }
  }
}
