import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { featureFlags } from '@environments/environment';
import { Subject, Subscription, switchMap } from "rxjs";
import { KmdModalService } from "gds-atom-components";
import {
  FileTranslationsManagerService
} from "@services/file-translations/file-manager/file-translations-manager.service";
import { tap } from "rxjs/operators";
import {
  TranslatedDocumentsListService
} from "@services/file-translations/list-service/translated-documents-list.service";
import { FileTranslation } from "@app/shared/models/file-translation.model";
import { WindowRef } from "@app/core/services/window/window-ref.service";

@Component({
  selector: 'app-translated-documents-list',
  templateUrl: '../translated-documents-list/translated-documents-list.component.html',
  styleUrls: ['../translated-documents-list/translated-documents-list.component.css']
})
export class TranslatedDocumentsListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() refreshTableEvent = new EventEmitter();
  @Input() fileType: string = 'text';
  @Input() disableSelection: boolean = false;
  @Input() searchTerm: string = '';
  @ViewChild('filesTable') filesTable?: ElementRef;

  TranslatedDocumentsList: FileTranslation[] = [];
  sortBy: string = 'translated';
  sortDirection: string = 'DESC';

  isFirstLoad: boolean = true;
  isFetching: boolean = false;
  isLoading: boolean = false;
  isLazyLoading: boolean = false;
  isUserScrollingDown: boolean = false;
  shouldSelectFirstRow: boolean = false;
  protected readonly featureFlags = featureFlags;

  showAlert = false;
  alertText = "";
  alertType = "failure";

  private offset: number = 0;
  private fetchFilesSubject = new Subject<{
    sortBy: string;
    sortDirection: string;
    fileName: string;
  }>();

  private subscriptions: Subscription[] = [];

  constructor(
    private documentTranslationsFileManagerService: FileTranslationsManagerService,
    private kmdModalService: KmdModalService,
    private translatedDocumentsListService: TranslatedDocumentsListService,
  ) {}

  ngOnInit(): void {
    this.initTranslatedFileList();
    const refreshSubscription = this.refreshTableEvent.subscribe(() => {
      this.refreshTable(true);
    });
    this.subscriptions.push(refreshSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
    this.fetchFilesSubject.complete();
  }

  initTranslatedFileList(){
    this.setSelectedFilesFiltering();
    this.initializeFileFetchSubscription();
    this.initFileFetch();
  }

  ngOnChanges({searchTerm}: SimpleChanges): void {
    if (searchTerm && !searchTerm.firstChange) {
      this.refreshTable(false);
    }
  }

  initializeFileFetchSubscription(): void {
    const fetchSubscription = this.fetchFilesSubject.pipe(
      switchMap(({sortBy, sortDirection}) => {
        this.isFetching = true;
        this.isLoading = !this.isUserScrollingDown;
        this.isLazyLoading = this.isUserScrollingDown;

        const currentOffset = this.offset;
        const fileName = this.searchTerm;

        return this.documentTranslationsFileManagerService.getLazyFiles$(fileName, this.offset, sortBy, sortDirection)
          .pipe(
            tap({
              next: (files) => {
                if (files.length === 0) {
                  this.offset = currentOffset;
                } else {
                  this.addNewFiles(files);
                  this.restoreSelectedFiles();

                  if (this.shouldSelectFirstRow && this.TranslatedDocumentsList.length > 0) {
                    const firstFile = this.TranslatedDocumentsList[0];
                    if (!this.isDisabledRow(firstFile) && !firstFile.isSelected) {
                      setTimeout(() => {
                        this.onRowCheckboxSelection(firstFile);
                      });
                    }
                    this.shouldSelectFirstRow = false;
                  }

                  if (files.length === 20) {
                    this.offset += 20;
                  }
                }
              },
              error: () => {
                this.isLoading = false;
                this.isLazyLoading = false;
                this.offset = currentOffset;
                this.isFetching = false;
                this.shouldSelectFirstRow = false;
              },
              complete: () => {
                this.isLoading = false;
                this.isLazyLoading = false;
                this.isUserScrollingDown = false;
                this.isFetching = false;
              },
            })
          );
      })
    ).subscribe();

    this.subscriptions.push(fetchSubscription);
  }

  initFileFetch(): void {
    if (this.isFirstLoad) {
      this.fetchFiles();
      this.isFirstLoad = false;
    }
  }

  refreshTable(selectFirstRow: boolean): void {
    this.offset = 0;
    this.TranslatedDocumentsList = [];
    this.shouldSelectFirstRow = selectFirstRow;
    this.fetchFiles();
  }

  scrollToTop(): void {
    this.filesTable?.nativeElement.scrollTo(0, 0);
  }

  deleteFile(file: FileTranslation) {
    this.closeDeleteModal();
    this.translatedDocumentsListService.delete(file.id!).subscribe({
      next: () => {
        this.TranslatedDocumentsList = this.TranslatedDocumentsList.filter(f => f.id !== file.id);
        this.documentTranslationsFileManagerService.removeSelectedFile(file);
        this.refreshTable(false);
      },
      error: (err) => {
        this.showAlertMessage('error', 'The file cannot be deleted. There was a problem contacting the server.');
        console.error('Error deleting files: ' + err);
      }
    });
  }

  downloadSelected() {
    const fileIdsToDownload = this.selectedFiles.map(file => file.id!);
    if (fileIdsToDownload.length === 1) {
      this.downloadFile(this.selectedFiles[0]);
      return;
    }
    this.translatedDocumentsListService.downloadMultiple(fileIdsToDownload).subscribe({
      next: (response) => {
        this.triggerDownload(response.body!, 'downloaded_files.zip');
      },
      error: (err) => {
        this.showAlertMessage('error', 'One or more of the selected files cannot be downloaded. There was a problem contacting the server.');
        console.error('Error downloading selected files: ' + err);
      }
    });
    this.restoreSelectedFiles();
  }

  downloadFile(file: FileTranslation) {
    this.translatedDocumentsListService.downloadFile(file.id).subscribe({
      next: (response) => {
        this.triggerDownload(response.body!, file.translatedFilename ?? '');
      },
      error: (err) => {
        this.showAlertMessage('error', 'The selected file cannot be downloaded. There was a problem contacting the server.');
        console.error('Error downloading single file: ' + err);
      }
    });
  }

  triggerDownload(blob: Blob, fileName: string): void {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.setProperty('display', 'none');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  fetchFiles(
    sortBy: string = "translated",
    sortDirection: string = "DESC",
    fileName: string = ""
  ): void {
    this.fetchFilesSubject.next({ sortBy, sortDirection, fileName });
  }

  onScroll({target}: any): void {
    const {scrollTop, clientHeight, scrollHeight, dataset} = target;
    const isScrollingDown = scrollTop > (parseFloat(dataset.prevScrollTop) || 0);
    dataset.prevScrollTop = scrollTop;

    if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 1) {
      this.isUserScrollingDown = true;
      this.fetchFiles(this.sortBy, this.sortDirection);
    }
  }

  onRowCheckboxSelection(file: FileTranslation): void {
    if (this.isDisabledRow(file)) {
      return;
    }

    file.isSelected = !file.isSelected;
    if (file.isSelected) {
      this.documentTranslationsFileManagerService.addSelectedFile(file);
    } else {
      this.documentTranslationsFileManagerService.removeSelectedFile(file);
    }

    this.checkSelectedFileLimitAndDisableOrReEnableAccordingly();
  }

  checkSelectedFileLimitAndDisableOrReEnableAccordingly() {
    if (this.selectedFiles.length >= 5) {
      this.TranslatedDocumentsList.forEach(file => {
        if (!file.isSelected)
          file.isSelectDisabled = true;
      })
    } else {
      this.TranslatedDocumentsList.forEach(file => {
        if (file.isSelectDisabled)
          file.isSelectDisabled = false;
      })
    }
  }

  get hasFiles(): boolean {
    return this.TranslatedDocumentsList.length > 0;
  }

  clearSelectedFiles(): void {
    this.TranslatedDocumentsList.forEach(file => file.isSelected = false);
    this.documentTranslationsFileManagerService.clearSelectedFiles();
  }

  sortFiles(sortBy: string): void {
    this.offset = 0;
    this.TranslatedDocumentsList = [];
    this.clearSelectedFiles();
    this.toggleSortDirection(sortBy);
    this.sortBy = sortBy;
    this.fetchFiles(this.sortBy, this.sortDirection);
    this.scrollToTop();
  }

  getSearchValue(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement)?.value;
    this.searchTerm = searchTerm.trim().toLowerCase();
    this.TranslatedDocumentsList = [];
     this.offset = 0;
    this.filterDocumentsByName(this.searchTerm);
  }

  filterDocumentsByName(searchTerm: string): void {
    this.fetchFiles(this.sortBy, this.sortDirection, searchTerm);
    this.scrollToTop();
  }

  isDisabledRow(file: FileTranslation): boolean {
    return this.disableSelection || !!file.isSelectDisabled;
  }

  get selectedFiles(): FileTranslation[] {
    return this.TranslatedDocumentsList.filter(file => file.isSelected);
  }

  addFile(file: FileTranslation): void {
    this.addOrUpdateFile(file, 'start');
  }

  addNewFiles(files: FileTranslation[]): void {
    files.forEach((file) => {
      this.addOrUpdateFile(file, "end");
    });
  }

  addOrUpdateFile(file: FileTranslation, position: 'start' | 'end'): void {
    const existingFileIndex = this.TranslatedDocumentsList.findIndex(
      f => f.id === file.id || (f.name === file.name && f.updatedAt === file.updatedAt)
    );

    if (existingFileIndex !== -1) {
      this.TranslatedDocumentsList[existingFileIndex] = {...file};
      return;
    }

    if (position === 'start') {
      this.TranslatedDocumentsList.unshift({...file});
    } else {
      this.TranslatedDocumentsList.push({...file});
    }
  }

  private setSelectedFilesFiltering(): void {
    const subscription = this.documentTranslationsFileManagerService.selectedFiles$
      .subscribe({
        next: (files) => {
          this.TranslatedDocumentsList.forEach(file =>
            file.isSelected = files.some(f => f.name === file.name)
          );
        }
      });
    this.subscriptions.push(subscription);
  }

  toggleSortDirection(sortBy: string): void {
    if (this.sortBy === sortBy) {
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortDirection = 'ASC';
    }
  }

  restoreSelectedFiles(): void {
    this.TranslatedDocumentsList.forEach(file => {
      file.isSelected = this.documentTranslationsFileManagerService.selectedFiles$.value
        .some(f => f.name === file.name);
    });
  }

  get pageWidth(): number {
    return WindowRef.innerWidth;
  }

  getDisplayName(file: FileTranslation): string {
    if (this.pageWidth <= 815) {
      return this.translatedDocumentsListService.getParseFileNameToDisplayName(file.translatedFilename!, 38);
    }
    return file.translatedFilename!;
  }

  openDeleteModal(): void {
    this.kmdModalService.open('delete-item-modal');
  }

  closeDeleteModal(): void {
    this.kmdModalService.close('delete-item-modal');
  }

  showAlertMessage(type: string, text: string) {
    this.alertType = type;
    this.alertText = text;
    this.showAlert = true;
  }

}
