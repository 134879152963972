import { Component, Input } from "@angular/core";
import { Agent } from "@app/shared/models/agent";

@Component({
    selector: 'app-agent-rating',
    templateUrl: './agent-rating.component.html',
    styleUrls: ['./agent-rating.component.css']
})
export class AgentRatingComponent {
    @Input() agent?: Agent;
    @Input() displayLikeCountOnly: boolean = false;

    tooltipVisible = false;
    tooltipLeft = 0;
    tooltipTop = 0;

    constructor() { }

    getLikeLabel(): string {
        if (this.displayLikeCountOnly) {
            return "";
        }

        return ` ${(this.likes == 1) ? 'like' : 'likes'}`;
    }

    get likes(): number {
        return this.agent?.rating?.likes || 0;
    }

    get likedByUser(): boolean {
        return this.agent?.rating?.likedByUser || false;
    }

    showTooltip(event: MouseEvent) {
      this.tooltipVisible = true;
      const containerWidth = (event.target as HTMLElement).offsetWidth;
      const tooltipWidth = 450;

      this.tooltipLeft = (containerWidth - tooltipWidth) / 2 + 200;
      this.tooltipTop = -60;
    }

    hideTooltip() {
      this.tooltipVisible = false;
    }

}
