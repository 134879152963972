import { Component, OnInit } from '@angular/core';
import { TermsandconditionsService } from '../core/services/termsandconditions/termsandconditions.service';
import { EnumTermsAndConditionsState, TermsAndConditions } from '../shared/models/terms-and-conditions.model'
import { Router } from '@angular/router'

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.css']
})
export class TermsandconditionsComponent implements OnInit {

  termsAndConditions?: TermsAndConditions;
  text?: string;
  status?: boolean;
  constructor(
    private termsandconditionsService: TermsandconditionsService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.termsandconditionsService.termsandconditions().subscribe((termsandconditions) => {
      this.termsAndConditions = termsandconditions;
      this.status = termsandconditions.state === EnumTermsAndConditionsState.accepted ? true : false;
      this.termsandconditionsService.getText(this.termsAndConditions.textURL).subscribe((responseText) => {
        this.text = responseText;
      });
    });
  }

  onClickAccept() {
    this.termsandconditionsService.accept().subscribe(() => {
      this.router.navigate(['/ask-gene']);
    }, error => {
      this.router.navigate(['/error'], { state: { status: error.status } });
    });
  }

}
