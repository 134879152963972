import { Injectable } from '@angular/core';
import { NavBarScreenResolutionSetting, NavBarScreenResolutionSettings } from '@app/shared/constants/navbar/screen-resolutions';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {

  private isCollapsedSubject = new BehaviorSubject<boolean>(true);
  private isHiddenSubject = new BehaviorSubject<boolean>(true);
  private currentScreenResolutionSubject = new BehaviorSubject<NavBarScreenResolutionSetting>(NavBarScreenResolutionSettings.mobile);
  private resizeTriggeredSubject = new Subject<void>();

  isCollapsed$ = this.isCollapsedSubject.asObservable();
  isHidden$ = this.isHiddenSubject.asObservable();
  resizeTriggered$ = this.resizeTriggeredSubject.asObservable();
  currentScreenResolutionSetting$ = this.currentScreenResolutionSubject.asObservable();

  setScreenResolution(width: number): void {
    let newResolution: NavBarScreenResolutionSetting;
    this.resizeTriggeredSubject.next();

    if (width <= NavBarScreenResolutionSettings.mobile.size) {
      newResolution = NavBarScreenResolutionSettings.mobile;
    } else if (width <= NavBarScreenResolutionSettings.tablet.size) {
      newResolution = NavBarScreenResolutionSettings.tablet;
    } else {
      newResolution = NavBarScreenResolutionSettings.desktop;
    }

    if (newResolution !== this.currentScreenResolutionSubject.value) {
      this.currentScreenResolutionSubject.next(newResolution);
      this.isCollapsedSubject.next(newResolution.isCollapsedByDefault);
      this.isHiddenSubject.next(newResolution.hiddenOnCollapse);
    }
  }

  handleMenuClick(): void {
    const currentSetting = this.currentScreenResolutionSubject.value;

    if (currentSetting.collapseOnViewChange && !currentSetting.hiddenOnCollapse) {
      this.isCollapsedSubject.next(true);
      this.isHiddenSubject.next(false);
    } else if (currentSetting.hiddenOnCollapse) {
      this.isHiddenSubject.next(true);
      this.isCollapsedSubject.next(false);
    }
  }

  handleClick(
    isMenuClick: boolean,
    isOutsideClick: boolean,
    isMenuOptionTextVisible: boolean,
    width: number
  ): void {
    this.setScreenResolution(width);

    const hiddenOnCollapse = this.currentScreenResolutionSubject.value.hiddenOnCollapse;
    const currentScreenResolution = this.currentScreenResolutionSubject.value;

    if (isMenuClick) {
      if (hiddenOnCollapse && !this.isHiddenSubject.value) {
        this.isHiddenSubject.next(true);
        this.isCollapsedSubject.next(false);
      } else if (hiddenOnCollapse && this.isHiddenSubject.value) {
        this.isHiddenSubject.next(false);
        this.isCollapsedSubject.next(false);
      } else if (!hiddenOnCollapse) {
        this.isCollapsedSubject.next(!this.isCollapsedSubject.value);
        this.isHiddenSubject.next(false);
      }
    } else if (isOutsideClick && hiddenOnCollapse && !this.isHiddenSubject.value) {
      this.isHiddenSubject.next(true);
      this.isCollapsedSubject.next(false);
    } else if (isOutsideClick && !hiddenOnCollapse && currentScreenResolution === NavBarScreenResolutionSettings.tablet) {
      this.isHiddenSubject.next(false);
      this.isCollapsedSubject.next(true);
    }
  }
  resetNavbarState(): void {
    this.isHiddenSubject.next(false);
    this.isCollapsedSubject.next(false);
  }

  setMobileResolution(): void {
    this.updateResolutionState(NavBarScreenResolutionSettings.mobile);
  }

  setTabletResolution(): void {
    this.updateResolutionState(NavBarScreenResolutionSettings.tablet);
  }

  setDesktopResolution(): void {
    this.updateResolutionState(NavBarScreenResolutionSettings.desktop);
  }

  private updateResolutionState(newResolution: NavBarScreenResolutionSetting): void {
    if (newResolution !== this.currentScreenResolutionSubject.value) {
      this.currentScreenResolutionSubject.next(newResolution);
      this.isCollapsedSubject.next(newResolution.isCollapsedByDefault);
      this.isHiddenSubject.next(newResolution.hiddenOnCollapse);
    }
  }

  collapseMenu(): void {
    this.isCollapsedSubject.next(true);
  }
}
