import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from "@angular/core";
import { MarkdownImageRendererService } from "@app/core/services/markdown-image-renderer/markdown-image-renderer.service";
import mermaid from "mermaid";
import { MarkdownService } from "ngx-markdown";
import { Observable, Subject } from "rxjs";

@Component({
    selector: 'app-markdown-wrapper',
    templateUrl: './markdown-wrapper.component.html',
    styleUrls: [
        './markdown-wrapper.component.css',
        './custom/markdown.css',
    ]
})
export class MarkdownWrapperComponent implements OnDestroy, AfterViewInit {
    @Input() data: string = '';
    @Input() customClass: string = '';
    @Input() imageCache = new Map<string, Observable<string | null>>();

    private destroy$ = new Subject<void>();

    constructor(
        private cdr: ChangeDetectorRef,
        public markdownService: MarkdownService,
        private markdownImageRendererService: MarkdownImageRendererService
    ) {
        this.initializeMarkdownImageRenderer();
    };

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    };

    ngAfterViewInit(): void {
        this.onMarkdownReady();
    };

    initializeMarkdownImageRenderer(): void {
        this.markdownService.renderer.image = this.imageRenderer.bind(this);
    };

    imageRenderer(href: string | null, title: string | null, text: string | null): string {
        const safeHref = this.markdownImageRendererService.sanitizeUrl(href);

        if (!safeHref || !this.markdownImageRendererService.isSafeSource(safeHref)) {
            return '';
        }

        const uniqueId = this.markdownImageRendererService.generateUniqueId(safeHref);
        const placeholder = this.createPlaceholder(uniqueId, text ?? '');

        setTimeout(() => this.loadImage(safeHref, uniqueId, title, text), 0);

        return placeholder;
    };

    createPlaceholder(uniqueId: string, text: string): string {
        return `
          <div id="${uniqueId}" class="spinner-container">
            <div class="spinner"></div>
            <span class="loading-text">${text || ''} Loading...</span>
          </div>
        `;
    };

    onMarkdownReady(): void {
        this.cdr.detectChanges();
        this.renderMermaid();
    };

    renderMermaid(): void {
        setTimeout(() => {
            try {
                mermaid.run();
            } catch (error) {
                console.error(`Mermaid rendering error: ${error}`);
            }
        }, 100);
    };

    loadImage(safeHref: string, uniqueId: string, title: string | null, text: string | null): void {
        this.markdownImageRendererService.loadImage(safeHref, uniqueId, this.imageCache, this.destroy$).subscribe({
            next: dataUrl => this.handleImageLoadSuccess(dataUrl, uniqueId, title, text),
            error: () => this.updatePlaceholderWithError(uniqueId)
        });
    };

    handleImageLoadSuccess(dataUrl: string | null, uniqueId: string, title: string | null, text: string | null): void {
        if (!dataUrl) {
            return;
        }

        const dataType = this.markdownImageRendererService.getDataTypeFromDataUrl(dataUrl);

        if (dataType?.includes('image')) {
            this.insertImage(dataUrl, uniqueId, title ?? '', text ?? '');
        } else if (dataType?.includes('text')) {
            this.insertText(dataUrl, uniqueId);
        }

        this.cdr.detectChanges();
    };

    insertImage(dataUrl: string, uniqueId: string, title: string, text: string): void {
        const placeholder = document.getElementById(uniqueId);

        if (!placeholder) return;

        const imageAndDownloadButtonContainer = this.createImageContainer(dataUrl, title, text, uniqueId);
        this.replacePlaceholderWithImage(placeholder, imageAndDownloadButtonContainer, uniqueId);
    };

    createImageContainer(dataUrl: string, title: string, text: string, uniqueId: string): HTMLDivElement {
        const imageAndDownloadButtonContainer = document.createElement('div');
        imageAndDownloadButtonContainer.className = 'assistants-image-with-download-button-container';
        imageAndDownloadButtonContainer.style.cssText = "position: relative; display: inline-block;";

        const img = this.createImageElement(dataUrl, title, text);
        const link = this.createImageLink(img, dataUrl);
        const downloadButton = this.createDownloadButton(uniqueId, dataUrl);

        imageAndDownloadButtonContainer.appendChild(downloadButton);
        imageAndDownloadButtonContainer.appendChild(link);
        return imageAndDownloadButtonContainer;
    };

    createImageElement(dataUrl: string, title: string, text: string): HTMLImageElement {
        const img = document.createElement('img');
        img.src = dataUrl;
        img.alt = text || '';
        img.title = title || '';
        img.className = 'markdown-image';

        return img;
    };

    createImageLink(img: HTMLImageElement, dataUrl: string): HTMLAnchorElement {
        const blob = this.markdownImageRendererService.dataUrlToBlob(dataUrl);
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.target = '_blank';
        link.appendChild(img);

        return link;
    };

    createDownloadButton(uniqueId: string, dataUrl: string): HTMLButtonElement {
        const downloadContainer = document.createElement('button');
        downloadContainer.className = 'assistant-image-download';
        downloadContainer.onclick = () => this.downloadImage(uniqueId, dataUrl);

        const downloadIcon = document.createElement('i');
        downloadIcon.className = 'assistant-image-download-button';

        downloadContainer.appendChild(downloadIcon);

        return downloadContainer;
    };

    replacePlaceholderWithImage(placeholder: HTMLElement, imageContainer: HTMLElement, uniqueId: string): void {
        const img = imageContainer.querySelector('img') as HTMLImageElement;

        img.onload = () => {
            placeholder.parentNode?.replaceChild(imageContainer, placeholder);
            imageContainer.classList.toggle('visible');
        };

        img.onerror = () => {
            if (placeholder.parentNode?.contains(imageContainer)) {
                placeholder.parentNode?.removeChild(imageContainer);
            }

            this.updatePlaceholderWithError(uniqueId);
        };
    };

    downloadImage(title: string, dataUrl: string): void {
        const blob = this.markdownImageRendererService.dataUrlToBlob(dataUrl);

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = title;
        link.click();

        URL.revokeObjectURL(link.href);
    };

    insertText(dataUrl: string, uniqueId: string): void {
        const placeholder = document.getElementById(uniqueId);

        if (!placeholder) {
            return;
        }

        const div = document.createElement('div');
        div.innerHTML = atob(dataUrl.split(',')[1]);
        div.className = 'code-executor-source markdown-text';
        placeholder.parentNode?.replaceChild(div, placeholder);
    };

    updatePlaceholderWithError(uniqueId: string): void {
        const placeholder = document.getElementById(uniqueId);

        if (placeholder) {
            placeholder.innerHTML = '<div class="error-message">Failed to load image</div>';
        }
    };
};
