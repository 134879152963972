import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Agent } from "@app/shared/models/agent";

@Component({
  selector: 'app-agent-public-overview',
  templateUrl: './agent-public-overview.component.html',
  styleUrls: ['./agent-public-overview.component.css']
})
export class AgentPublicOverviewComponent {
  @Input() agent?: Agent;
  @Output() onMakeAgentPublicEvent = new EventEmitter<Agent>();
  @Output() onCancelEvent = new EventEmitter<string>();
  @Input() modalName?: string = 'public-agent-overview';
  @Input() username: string | undefined = '';

  constructor() {
  }

  getLabelValue(rawValue: string | undefined): string {
    return rawValue?.length ? rawValue : 'N/A';
  };

  getConversationStartersLabel(): string {
    const conversationStarters = this.agent?.conversationStarters?.join(', ');
    return this.getLabelValue(conversationStarters);
  };

  makeAgentPublic(): void {
    this.onMakeAgentPublicEvent.emit(this.agent);
  };

  onCancel(): void {
    this.onCancelEvent.emit(this.modalName);
  };
};
