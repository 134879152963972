export class PromptSettings {
  creativity: Creativity
  tone: Tone
  webSearch: boolean
  model: Model
  imageSize?: string
  imageQuality?: string
  imageStyle?: string
  imageUseEnhancement?: boolean;

  constructor(creativity: Creativity, tone: Tone, webSearch: boolean, model?: Model,
              imageSize?: string, imageQuality?: string, imageStyle?: string, imageUseEnhancement?: boolean) {
    this.creativity = creativity
    this.tone = tone
    this.webSearch = webSearch
    this.model = model ?? "gpt-4-o";
    this.imageSize = imageSize
    this.imageQuality = imageQuality
    this.imageStyle = imageStyle
    this.imageUseEnhancement = imageUseEnhancement
  }
}

export const Models = ['gpt-4-o', 'dall-e-3', 'claude-3-5-sonnet'] as const;
export type Model = typeof Models[number];

export type modelIntegration = 'text' | 'image' | 'spreadsheets';

export type ModelDetails = { model: Model, title: string, description: string, integrations: modelIntegration []};
export type ModelList = ModelDetails[];

export const Tones = ['PROFESSIONAL', 'FRIENDLY', 'PERSUASIVE', 'CASUAL'] as const;
export type Tone = typeof Tones[number];

export const CreativityList = [0, 1, 2, 3, 4, 5] as const;
export type Creativity = typeof CreativityList[number];
