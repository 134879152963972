import { CUSTOM_ELEMENTS_SCHEMA, NgModule, SecurityContext } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MarkdownModule } from "ngx-markdown";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "@shared/shared.module";
import { CoreModule } from "./core/core.module";
import { ClipboardModule } from "ngx-clipboard";
import { CookieService } from "ngx-cookie-service";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  AccordionsModule,
  AlertsModule,
  BadgesModule,
  ButtonModule,
  CheckboxModule,
  ChipsModule,
  DropdownsModule,
  InputFieldsModule,
  LoadingIndicatorsModule,
  ModalsModule,
  PaginationModule,
  PopoverModule,
  TabsModule,
  ToggleModule,
  TooltipModule
} from "gds-atom-components";

import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';

import { AppComponent } from './app.component';
import { SigninComponent } from './signin/signin.component';
import { LoadingComponent } from './loading/loading.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { CircleWithInitialsComponent } from "@shared/components/circle-with-initials/circle-with-initials.component";
import { ChatHistoryComponent } from './chathistory/chathistory.component';
import { PromptSettingsComponent } from '@shared/components/prompt-settings/prompt-settings.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { ErrorComponent } from './error/error.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { InputSelectBoxComponent } from '@shared/components/input-select-box/input-select-box.component';
import { CopyResponseComponent } from '@app/shared/components/copy-response/copy-response.component';
import { FeatureFlagDirective } from '@shared/directives/feature-flag/feature-flag.directive';
import { ModalEditPromptComponent } from './prompt/modaleditprompt/modal-edit-prompt.component';
import { FormPromptComponent } from './prompt/formprompt/form-prompt.component';
import { ModalSavePromptComponent } from './prompt/modalsaveprompt/modal-save-prompt.component';
import { CountdownLabelComponent } from '@shared/components/countdown-label/countdown-label.component';
import { ExamplePromptComponent } from '@shared/components/example-prompt/example-prompt.component';
import { SharePromptComponent } from './share-prompt/share-prompt.component';
import { ModalReadOnlyPromptComponent } from './prompt/modal-read-only-prompt/modal-read-only-prompt.component';
import { IframeApjComponent } from './iframe-apj/iframe-apj.component';
import { ChatComponent } from './chat/chat.component';
import { DrawerComponent } from './drawer/drawer.component';
import { DrawerComponent as ChatHistoryDrawerComponent } from './my-library/shared/drawer/drawer.component';
import { AskOurDocsChatComponent } from './ask-our-docs/ask-our-docs-chat/ask-our-docs-chat.component';
import { TranslationsComponent } from './tools/translations/translations.component';
import { ClickOutsideDrawerDirective } from '@shared/directives/click-outside-drawer/click-outside-drawer.directive';
import { ImageCreationComponent } from "./image-creation/image-creation.component";
import { SocialMediaComponent } from './tools/social-media/social-media.component';
import { PagedPromptsComponent } from './paged-prompts/paged-prompts.component';
import { ChatImageMessagesComponent } from "./chat/chat-image-messages/chat-image-messages.component";
import { AgentListComponent } from './agents/agent-list/agent-list.component';
import { AgentCreationComponent } from './agents/agent-creation/agent-creation.component';
import { SafePipe } from "./core/pipe/safe.pipe";
import { AgentCardComponent } from './agents/agent-list/agent-card/agent-card.component';
import { PublicPagedPromptsComponent } from './public-paged-prompts/public-paged-prompts.component';
import { PromptInputComponent } from '@shared/components/prompt-input/prompt-input.component';
import { AgentChatComponent } from './agents/agent-chat/agent-chat.component';
import { AgentEditComponent } from './agents/agent-edit/agent-edit.component';
import { AgentFormComponent } from './agents/agent-form/agent-form.component';
import { AgentBuilderComponent } from './agents/agent-builder/agent-builder.component';
import { AgentWizardComponent } from './agents/agent-wizard/agent-wizard.component';
import { ToggleNavMenuDirective } from '@shared/directives/toggle-nav-menu/toggle-nav-menu.directive';
import { ToggleChatHistoryDirective } from '@shared/directives/toggle-chat-history/toggle-chat-history.directive';
import {
  AgentConversationStarterComponent
} from './agents/agent-conversation-starter/agent-conversation-starter.component';
import { AgentListToolbarComponent } from './agents/agent-list/agent-list-toolbar/agent-list-toolbar.component';
import { AgentOverviewComponent } from './agents/agent-overview/agent-overview.component';
import { AgentPublicOverviewComponent } from './agents/agent-public-overview/agent-public-overview.component';
import { OidcIdentityRoleResolver } from './core/resolvers/oidc-identity-role.resolver';
import { AddToMyAgentsComponent } from './agents/public-agents/add-to-my-agents/add-to-my-agents.component';
import { AgentResolver } from './core/resolvers/agent.resolver';
import { FormatUsernamePipe } from '@shared/pipes/format-username/format-username.pipe';
import { AgentExampleComponent } from "@shared/components/agent-example/agent-example.component";
import { AgentRatingComponent } from './agents/agent-rating/agent-rating.component';
import { ShortNumberPipe } from './core/pipe/short-number.pipe';
import { AgentListPagedComponent } from './agents/agent-list-paged/agent-list-paged.component';
import { AgentRatingButtonComponent } from './agents/agent-rating-button/agent-rating-button.component';
import { AlertComponent } from './alert/alert/alert.component';
import { AgentRecommendationComponent } from './agents/agent-recommendation/agent-recommendation.component';
import { AskOurDocsPageComponent } from './ask-our-docs/ask-our-docs-page/ask-our-docs-page.component';
import { CardComponent } from "@shared/components/card/card.component";
import { ChatWithDbComponent } from './ask-our-data/chat-with-db/chat-with-db.component';
import {
  AskOurDocsUserManagementPageComponent
} from './ask-our-docs/ask-our-docs-user-management-page/ask-our-docs-user-management-page.component';
import {
  AskOurDocsUserManagementAddUsersComponent
} from './ask-our-docs/ask-our-docs-user-management-page/ask-our-docs-user-management-add-users/ask-our-docs-user-management-add-users.component';
import { ChipComponent } from '@shared/components/chip/chip.component';
import {
  AskOurDocsUserManagementRemoveUsersComponent
} from './ask-our-docs/ask-our-docs-user-management-page/ask-our-docs-user-management-remove-users/ask-our-docs-user-management-remove-users.component';
import { UserContextMenuComponent } from './user-context-menu/user-context-menu.component';
import { NavbarNewComponent } from './navbar-new/navbar-new.component';
import { AskGeneNewComponent } from './ask-gene-new/ask-gene-new.component';
import { PromptInputNewComponent } from '@shared/components/prompt-input-new/prompt-input-new.component';
import { PromptSettingsNewComponent } from '@shared/components/prompt-settings-new/prompt-settings-new.component';
import { ContextMenuComponent } from "@app/shared/components/context-menu/context-menu.component";
import { ClickOutsideDirective } from "@shared/directives/click-outside/click-outside.directive";
import { ChatNewComponent } from './chat-new/chat-new.component';
import { UserMessageComponent } from './chat-new/user-message/user-message.component';
import { AssistantMessageComponent } from './chat-new/assistant-message/assistant-message.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ChatWrapperComponent } from '@shared/components/chat-wrapper/chat-wrapper.component';
import { MyLibraryComponent } from './my-library/my-library.component';
import { ChatHistoryNewComponent } from './my-library/chat-history-new/chat-history-new.component';
import { DataTableComponent } from './my-library/shared/data-table/data-table.component';
import { RecordComponent } from './my-library/shared/record/record.component';
import { FileManagerComponent } from '@app/shared/components/file-manager/file-manager.component';
import { FileManagerModalComponent } from '@app/shared/components/file-manager/modal/file-manager-modal.component';
import { FileManagerDrawerComponent } from '@app/shared/components/file-manager/drawer/file-manager-drawer.component';
import {
  FileManagerSelectedFilesComponent
} from '@app/shared/components/file-manager/selected-files/file-manager-selected-files.component';
import { FileManagerMyDocsComponent } from '@app/shared/components/file-manager/my-docs/file-manager-my-docs.component';
import { FileManagerUploadComponent } from '@app/shared/components/file-manager/upload/file-manager-upload.component';
import { DragAndDropDirective } from '@shared/directives/drag-and-drop/drag-and-drop.directive';
import { MyLibraryMenuOptionComponent } from './navbar-new/my-library-menu-option/my-library-menu-option.component';
import {
  ChatHistoryZeroStateComponent
} from './my-library/chat-history-new/chat-history-zero-state/chat-history-zero-state.component';
import { ActionButtonsComponent } from './my-library/shared/record/action-buttons/action-buttons.component';
import { DeleteModalComponent } from "@shared/components/delete-modal/delete-modal.component";
import { SavedPromptsComponent } from './my-library/saved-prompts/saved-prompts.component';
import { MenuOptionNewComponent } from './navbar-new/menu-option-new/menu-option-new.component';
import { SubMenuOptionComponent } from './navbar-new/sub-menu-option/sub-menu-option.component';
import { AutosizeModule } from 'ngx-autosize';
import { SubMenuComponent } from './navbar-new/sub-menu-option/sub-menu/sub-menu.component';
import { ModalComponent } from "@shared/components/modal/modal.component";
import {
  FileManagerConfirmationModalComponent
} from "@shared/components/file-manager/modal/file-manager-confirmation-modal/file-manager-confirmation-modal.component";
import { FileManagerActionControlsComponent } from '@shared/components/file-manager/action-controls/file-manager-action-controls.component';
import { PlotlyService } from '@services/ask-our-data/plotly.service';
import { LZStringService } from '@services/ask-our-data/lz-string.service';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';
import { ChatHistoryMobileComponent } from './my-library/chat-history-new/mobile/chat-history-mobile/chat-history-mobile.component';
import { RecordMobileComponent } from './my-library/shared/record/mobile/record-mobile.component';
import { BannerModalNewComponent } from "@shared/components/banner-modal-new/banner-modal-new.component";
import { NewsBannerComponent } from "@shared/components/news-banner/news-banner.component";
import { FileManagerAcknowledgementModalComponent } from '@shared/components/file-manager/modal/file-manager-acknowledgement-modal/file-manager-acknowledgement-modal.component';
import { AskOurDocsChatPageComponent } from './ask-our-docs-v2/ask-our-docs-chat-page/ask-our-docs-chat-page.component';
import { PromptPageComponent } from './ask-our-docs-v2/prompt-page/prompt-page.component';
import { AskOurDocsPageNewComponent } from './ask-our-docs-v2/ask-our-docs-page-new/ask-our-docs-page-new.component';
import { AskOurDocsUserManagementPageNewComponent } from './ask-our-docs-v2/ask-our-docs-user-management-page-new/ask-our-docs-user-management-page-new.component';
import { AskOurDocsUserManagementRemoveUsersNewComponent } from './ask-our-docs-v2/ask-our-docs-user-management-page-new/ask-our-docs-user-management-remove-users-new/ask-our-docs-user-management-remove-users-new.component';
import { AskOurDocsUserManagementAddUsersNewComponent } from './ask-our-docs-v2/ask-our-docs-user-management-page-new/ask-our-docs-user-management-add-users-new/ask-our-docs-user-management-add-users-new.component';
import { FileUploaderComponent } from "@app/file-uploader/file-uploader.component";
import { gfmHeadingId } from "marked-gfm-heading-id";
import { AboutRepositoryComponent } from './ask-our-docs-v2/modals/about-repository/about-repository.component';
import { AskOurDocsV2ChatHistoryComponent } from './ask-our-docs-v2/modals/ask-our-docs-v2-chat-history/ask-our-docs-v2-chat-history.component';
import { AskOurDocsV2SavedPromptsComponent } from './ask-our-docs-v2/modals/saved-prompts/saved-prompts.component';
import {AskOurDocsV2SavePromptComponent} from './ask-our-docs-v2/modals/save-prompt/save-prompt.component';
import { FeedbackFormComponent } from './ask-our-docs-v2/modals/feedback-form/feedback-form.component';
import { AgentSharingModalComponent } from './agents/agent-sharing/agent-sharing-modal/agent-sharing-modal.component';
import { NoSpacesDirective } from "@shared/directives/no-spaces/no-spaces.directive";
import { AgentSharingConfirmationModalComponent } from './agents/agent-sharing/agent-sharing-confirmation-modal/agent-sharing-confirmation-modal.component';
import { DocumentTranslationsNotificationComponent } from "@app/tools/translations/document-translations-notification/document-translations-notification.component";
import { DocumentTranslationsMobileNotificationComponent } from "@app/tools/translations/document-translations-mobile-notification/document-translations-mobile-notification.component";
import { FeedbackComponent } from './ask-our-docs-v2/components/feedback/feedback.component';
import { AdminLandingFeedbackFormComponent } from './ask-our-docs-v2/components/admin-landing-feedback-form/admin-landing-feedback-form.component';
import { AgentSharingGroupMemberListModalComponent } from './agents/agent-sharing/agent-sharing-group-member-list-modal/agent-sharing-group-member-list-modal.component';
import { AgentFiltersComponent } from "./agents/agent-sharing/agent-filters/agent-filters.component";
import { TranslatedDocumentsListComponent } from "@app/tools/translations/translated-documents-list/translated-documents-list.component";
import { AdminPromptLibraryComponent } from './ask-our-docs-v2/components/admin-prompt-library/admin-prompt-library.component';
import { ExternalLinkIconComponent } from './shared/components/icons/external-link-icon/external-link-icon.component';
import { CaretIconComponent } from './shared/components/icons/caret-icon/caret-icon.component';
import { AlertIconComponent } from './shared/components/icons/alert-icon/alert-icon.component';
import { AgentSharingKeepPrivateConfirmationModalComponent } from './agents/agent-sharing/agent-sharing-keep-private-confirmation-modal/agent-sharing-keep-private-confirmation-modal.component';
import { ChipBoxComponent } from "./shared/components/chip-box/chip-box.component";
import { AskOurDataSharedModule } from '@app/ask-our-data/ask-our-data-shared.module';
import { WarningIconComponent } from './shared/components/icons/warning-icon/warning-icon.component';
import { NotificationCardComponent } from './shared/components/notification-card/notification-card.component';
import { CloseIconComponent } from './shared/components/icons/close-icon/close-icon.component';
import { AgentChatNewComponent } from './agents/agent-chat-new/agent-chat-new.component';
import { FileTypeButtonComponent } from './shared/components/file-manager/file-type-button/file-type-button.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import {
  FileTranslationsUploaderComponent
} from "@app/tools/translations/file-translations-manager/file-translations-uploader/file-translations-uploader.component";
import {
  FileTranslationsUploadContainerComponent
} from "@app/tools/translations/file-translations-manager/file-translations-upload-container/file-translations-upload-container.component";
import {
  FileTranslationsTranslateContainerComponent
} from "@app/tools/translations/file-translations-manager/file-translations-translate-container/file-translations-translate-container.component";
import { MarkdownWrapperComponent } from './shared/components/markdown-wrapper/markdown-wrapper.component';
import {
  FileManagerUploadBetaComponent
} from "@shared/components/file-manager/upload-beta/file-manager-upload-beta.component";
import {
  FileManagerMyDocsBetaComponent
} from "@shared/components/file-manager/my-docs-beta/file-manager-my-docs-beta.component";
import {
  UploadedFilesBannerManagerComponent
} from "@shared/components/file-manager/uploaded-files-banner-manager/uploaded-files-banner-manager.component";
import {
  FileManagerImagesComponent
} from "@shared/components/file-manager/file-manager-images/file-manager-images.component";
import {
  FileTranslationsLanguageDropdownComponent
} from "@app/tools/translations/file-translations-language-dropdown/file-translations-language-dropdown.component";

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignOutComponent,
    CircleWithInitialsComponent,
    PromptSettingsComponent,
    TermsandconditionsComponent,
    ErrorComponent,
    AboutUsComponent,
    InputSelectBoxComponent,
    CopyResponseComponent,
    FeatureFlagDirective,
    ModalSavePromptComponent,
    ModalEditPromptComponent,
    FormPromptComponent,
    CountdownLabelComponent,
    ExamplePromptComponent,
    SharePromptComponent,
    ModalReadOnlyPromptComponent,
    SharePromptComponent,
    FileUploaderComponent,
    IframeApjComponent,
    ChatComponent,
    BannerModalNewComponent,
    NewsBannerComponent,
    DrawerComponent,
    TranslationsComponent,
    ClickOutsideDrawerDirective,
    AskOurDocsChatComponent,
    SocialMediaComponent,
    PagedPromptsComponent,
    AgentListComponent,
    AgentCreationComponent,
    AgentCardComponent,
    PublicPagedPromptsComponent,
    PromptInputComponent,
    AgentChatComponent,
    ImageCreationComponent,
    ChatImageMessagesComponent,
    SafePipe,
    AgentEditComponent,
    AgentFormComponent,
    AgentBuilderComponent,
    AgentWizardComponent,
    ToggleNavMenuDirective,
    ToggleChatHistoryDirective,
    AgentConversationStarterComponent,
    AgentListToolbarComponent,
    AgentOverviewComponent,
    AgentPublicOverviewComponent,
    AddToMyAgentsComponent,
    FormatUsernamePipe,
    AgentExampleComponent,
    AgentRatingComponent,
    AgentRatingButtonComponent,
    ShortNumberPipe,
    AgentListPagedComponent,
    AlertComponent,
    AgentRecommendationComponent,
    AskOurDocsPageComponent,
    CardComponent,
    ChatWithDbComponent,
    AskOurDocsUserManagementPageComponent,
    AskOurDocsUserManagementAddUsersComponent,
    ChipComponent,
    AskOurDocsUserManagementRemoveUsersComponent,
    UserContextMenuComponent,
    NavbarNewComponent,
    AskGeneNewComponent,
    PromptInputNewComponent,
    PromptSettingsNewComponent,
    ContextMenuComponent,
    ClickOutsideDirective,
    ChatNewComponent,
    UserMessageComponent,
    AssistantMessageComponent,
    MaintenanceComponent,
    ChatWrapperComponent,
    FileManagerComponent,
    FileManagerModalComponent,
    FileManagerDrawerComponent,
    FileManagerMyDocsComponent,
    FileManagerUploadComponent,
    FileManagerSelectedFilesComponent,
    FileManagerActionControlsComponent,
    MyLibraryComponent,
    ChatHistoryNewComponent,
    DataTableComponent,
    RecordComponent,
    DragAndDropDirective,
    MyLibraryMenuOptionComponent,
    ChatHistoryZeroStateComponent,
    ActionButtonsComponent,
    DeleteModalComponent,
    SavedPromptsComponent,
    MenuOptionNewComponent,
    SubMenuOptionComponent,
    SubMenuComponent,
    ModalComponent,
    FileManagerConfirmationModalComponent,
    TooltipComponent,
    ChatHistoryMobileComponent,
    RecordMobileComponent,
    ChatHistoryDrawerComponent,
    FileManagerAcknowledgementModalComponent,
    AskOurDocsChatPageComponent,
    PromptPageComponent,
    AskOurDocsPageNewComponent,
    AskOurDocsUserManagementPageNewComponent,
    AskOurDocsUserManagementRemoveUsersNewComponent,
    AskOurDocsUserManagementAddUsersNewComponent,
    PromptInputNewComponent,
    CardComponent,
    FeatureFlagDirective,
    ContextMenuComponent,
    ChatWrapperComponent,
    ChipComponent,
    AboutRepositoryComponent,
    ChatHistoryComponent,
    AskOurDocsV2ChatHistoryComponent,
    AskOurDocsV2SavedPromptsComponent,
    AskOurDocsV2SavePromptComponent,
    FeedbackFormComponent,
    AgentSharingModalComponent,
    AgentSharingConfirmationModalComponent,
    AgentSharingGroupMemberListModalComponent,
    DocumentTranslationsNotificationComponent,
    DocumentTranslationsMobileNotificationComponent,
    NoSpacesDirective,
    FeedbackComponent,
    AdminLandingFeedbackFormComponent,
    AgentFiltersComponent,
    AdminPromptLibraryComponent,
    LoadingComponent,
    ExternalLinkIconComponent,
    WarningIconComponent,
    CaretIconComponent,
    AlertIconComponent,
    CloseIconComponent,
    TranslatedDocumentsListComponent,
    AdminPromptLibraryComponent,
    AgentSharingKeepPrivateConfirmationModalComponent,
    ChipBoxComponent,
    NotificationCardComponent,
    AgentChatNewComponent,
    FileTypeButtonComponent,
    ConfirmDialogComponent,
    FileTranslationsUploaderComponent,
    FileTranslationsUploadContainerComponent,
    FileTranslationsTranslateContainerComponent,
    MarkdownWrapperComponent,
    FileManagerUploadBetaComponent,
    FileManagerMyDocsBetaComponent,
    UploadedFilesBannerManagerComponent,
    FileManagerImagesComponent,
    FileTranslationsTranslateContainerComponent,
    FileTranslationsLanguageDropdownComponent
  ],

  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    FormsModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedExtensions: [gfmHeadingId()],
      sanitize: SecurityContext.NONE
    }),
    InputFieldsModule,
    ButtonModule,
    LoadingIndicatorsModule,
    FontAwesomeModule,
    AlertsModule,
    ModalsModule,
    TooltipModule,
    PopoverModule,
    ChipsModule,
    ClipboardModule,
    AccordionsModule,
    TabsModule,
    DropdownsModule,
    ToggleModule,
    BadgesModule,
    CheckboxModule,
    PaginationModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    AutosizeModule,
    AccordionModule,
    AngularEditorModule,
    DragDropModule,
    DialogModule,
    InputTextModule,
    AngularEditorModule,
    AskOurDataSharedModule,
    TabViewModule
  ],
  providers: [
    CookieService,
    OidcIdentityRoleResolver,
    AgentResolver,
    DatePipe,
    PlotlyService,
    provideHttpClient(withInterceptorsFromDi()),
    LZStringService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
