import { Model, ModelDetails, ModelList } from "@shared/models/prompt-settings.model";

export const imageSize = [
  { title: 'Square', description: '1024x1024', value: "1024x1024" },
  { title: 'Portrait', description: '1024x1792', value: "1024x1792" },
  { title: 'Landscape', description: '1792x1024', value: "1792x1024" }
];

export const imageQuality = [
  { title: 'High definition (HD)', description: 'Finer details, greater consistency, longer image creation.', value: "hd" },
  { title: 'Standard', description: 'Quick and simple image creation.', value: "standard" }
];

export const imageStyle = [
  { title: 'Natural', description: 'Real natural-looking style.', value: "natural" },
  { title: 'Vivid', description: 'Hyper-real and dramatic images.', value: "vivid" },
];

export const modelList: ModelList = [
  {
    model: "gpt-4-o",
    title: "GPT 4o 128K",
    description: "Comprehensive, high-performance, and fast for extensive and large-scale tasks.",
    integrations: ["text", "image", "spreadsheets"]
  },
  {
    model: "claude-3-5-sonnet",
    title: "Claude 3.5",
    description: "Improved performance in reasoning, coding, and safety.",
    integrations: ["text", "image", "spreadsheets"]
  }
];

export const modelMap = new Map<Model, ModelDetails>(
  modelList.map(model => [model.model, model])
);
