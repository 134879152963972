import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { EventTypes, OidcSecurityService, PublicEventsService } from "angular-auth-oidc-client";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "@environments/environment";
import { filter, mergeMap } from "rxjs";

@Component({
  selector: 'app-ask-our-docs-chat',
  templateUrl: './ask-our-docs-chat.component.html',
  styleUrls: ['./ask-our-docs-chat.component.css']
})
export class AskOurDocsChatComponent implements OnInit, OnDestroy, AfterViewInit{
  @ViewChild('myIframe') myIframe!: ElementRef;
  url!: string;
  safeUrl: any;
  targetOrigin: string = `${environment.askOurDocsIframeUrl}`;

  constructor(
    private oidcIdentityService: OidcSecurityService,
    private publicEventsService: PublicEventsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,

  ) {
  }

  async ngOnInit() {
    this.initializeIframe();
    this.initializeMessageListener();
  }


  ngAfterViewInit() {
    this.listenToSilentRenewEvents()
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.handleMessage);
  }

  initializeMessageListener() {
    window.addEventListener('message', this.handleMessage)
  }

  handleMessage = (event: MessageEvent) =>{
    if (event.origin !== this.targetOrigin) {
      return;
    }
    if(event.data.type === 'GET_TOKEN'){
      this.postMessageToIframe('SET_TOKEN');
    }
  }

  initializeIframe() {
    this.route.params.subscribe(params => {
      this.url = this.constructUrl(encodeURIComponent(params["indexName"]));
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    });
  }

  constructUrl(indexName: string): string {
    return `${environment.askOurDocsIframeUrl}/?indexName=${indexName}`;
  }

  postMessageToIframe(type: string) {
    this.oidcIdentityService.getIdToken().subscribe((idToken) => {
      let htmlElement = this.myIframe.nativeElement as HTMLIFrameElement;
      htmlElement.contentWindow?.postMessage({ type, token: idToken }, this.targetOrigin);
    });
  }

  postMessageToIframeWithRefreshedIdToken(idToken: string, type: string) {
    let htmlElement = this.myIframe.nativeElement as HTMLIFrameElement;
    htmlElement.contentWindow?.postMessage({ type, token: idToken }, this.targetOrigin);
  }


  listenToSilentRenewEvents() {
    this.publicEventsService.registerForEvents().pipe(
      filter(notification => notification.type === EventTypes.UserDataChanged),
      mergeMap(() => this.oidcIdentityService.getIdToken())
    ).subscribe(idToken => {
      this.postMessageToIframeWithRefreshedIdToken(idToken, 'SET_TOKEN');
    })

  }


}
