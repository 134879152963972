import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Chat } from "../shared/models/chat.model";
import { Author, Message } from "../shared/models/message.model";
import { Prompt } from '../shared/models/prompt.model';
import { UserData } from "../shared/models/user-data.model";
import { ChatService } from "../core/services/chat/chat.service";
import { KmdModalService } from 'gds-atom-components';
import { PromptSettings } from "../shared/models/prompt-settings.model";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { ChatComponent } from '../chat/chat.component';
import { DrawerComponent } from '../drawer/drawer.component';
import { PromptInputComponent } from "../shared/components/prompt-input/prompt-input.component";
import { PromptExample } from "../shared/models/prompt-example.model";
import { ChatHistoryUpdateService } from '@app/core/chat-history-update/chat-history-update.service';

@Component({
  selector: 'app-image-creation',
  templateUrl: './image-creation.component.html',
  styleUrls: ['./image-creation.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageCreationComponent implements OnInit {

  @ViewChild('chatList') chatList!: ElementRef;
  @ViewChild('textArea') textArea?: ElementRef;
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent;
  @ViewChild('appChat') chatComponent?: ChatComponent;
  @ViewChild('promptInputComponent') promptInputComponent!: PromptInputComponent;

  lastSystemMessage : Message | undefined

  userData$: Observable<UserData>;
  chat?: Chat;
  // messages: Message[] = [];
  showPromptSavedAlert = false;
  currentPrompt: string = '';
  isLoading: boolean = false;
  promptSettings: PromptSettings;
  promptSavedText = 'Prompt Saved';
  showInvalidPrompt = false;
  invalidPromptText = 'Sorry, your prompt couldn\'t be processed. Please rephrase or shorten your prompt.<br>Make sure to review and comply with the Gene.AI Use Guidelines';
  showTooManyRequests = false;
  tooManyRequestsText = 'Our image generation capacity has maxed out at the moment.<br>Please try again later, and thank you for your patience.';
  activeView = "chat";
  showGeneralAlert = false;
  alertText = "We encountered an unexpected error. Please try again.";
  selectedChatId!: string;
  bannerEnabled = false;
  promptExampleHeader = false;
  promptExamplesType = 'IMAGE_CREATION';

  constructor(
    private chatService: ChatService,
    private kmdModalService: KmdModalService,
    private route: ActivatedRoute,
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private chatHistoryUpdateService: ChatHistoryUpdateService
  ) {
    this.promptSettings = new PromptSettings(3, "PROFESSIONAL", false,
      "dall-e-3", "1024x1024", "hd", "natural", true);
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    );

    const navigation = this.router.getCurrentNavigation();
    let prompt = this.getPrompt(navigation?.extras);
    if (prompt) {
      this.currentPrompt = prompt.title ? prompt.text : '';
      this.promptSettings = prompt.promptSettings;
      setTimeout(() => {
        this.promptInputComponent.resizeTextarea();
      });
    }
  }

  getPrompt(extras?: NavigationExtras): Prompt | undefined {
    const state = extras?.state as { prompt: Prompt };
    return state?.prompt;
  }

  ngOnInit(): void {
    const navigation = this.router.getCurrentNavigation();
    const prompt = this.getPrompt(navigation?.extras);
    if (prompt) {
      this.promptInputComponent.setPrompt(prompt.title ? prompt.text : '');
      this.promptSettings = prompt.promptSettings;
      setTimeout(() => {
        this.promptInputComponent.resizeTextarea();
      });
    }
    this.route.params.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.selectedChatId = id;
        this.cdRef.detectChanges();
        this.showChat();
        let chat = new Chat("New chat", new Date());
        chat.id = id;
        this.chat = chat;
        this.chatService
          .getChatMessages(id)
          .subscribe((newMessages) => {
              this.setMessages(newMessages);
              if (newMessages.length != 0) {
                this.promptSettings = JSON.parse(JSON.stringify(newMessages[newMessages.length - 1].promptSettings));
              }
              this.isLoading = false;
            }
            , (error) => {
              this.isLoading = false;
              let errors = [400, 401, 403, 404, 500, 503]
              if (errors.includes(error.status)) {
                this.router.navigate(['/error'], { state: { status: error.status }, replaceUrl: true })
              } else {
                this.router.navigate(['/error'], { state: { status: 500 }, replaceUrl: true })
              }
            }
          );
      }
    });
  }

  async onSubmit(inputPrompt: string) {
    if (inputPrompt.trim() == "" || this.isLoading) return;

    let promptValue = inputPrompt.trim();
    this.promptInputComponent.setPrompt(promptValue);
    let author = new Author("USER")
    this.kmdModalService.open('loadingResponseModal');
    this.isLoading = true;
    let prompt = new Prompt(promptValue, this.promptSettings, this.lastSystemMessage?.id)
    let message = new Message(promptValue, author, new Date(), this.promptSettings, this.lastSystemMessage?.id);

    if (this.chat?.id == undefined) {
      this.chatService.promptImageChat(prompt).subscribe({
        next: (chatAndPrompt) => {
          this.chat = chatAndPrompt.chat;
          message.id = chatAndPrompt.prompt.id
          this.addMessages([message, chatAndPrompt.prompt.answer!]);
          this.kmdModalService.close('loadingResponseModal');
          this.isLoading = false;
          this.chatHistoryUpdateService.notifyChatUpdated(prompt.chatId);
          this.ngZone.run(() => {
            this.router.navigate(['/image-creation/' + this.chat?.id], {
              state: { prompt: chatAndPrompt.prompt }
            });
          });
        }, error: (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.handlePromptError(err);
          this.currentPrompt = promptValue;
        }
      });
    } else {
      this.chatService.promptImage(this.chat.id, prompt).subscribe({
        next: (prompt) => {
          message.id = prompt.id
          message.parentId = prompt.parentId
          this.addMessages([message, prompt.answer!]);
          this.kmdModalService.close('loadingResponseModal');
          this.isLoading = false;
        }, error: (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.handlePromptError(err);
          this.promptInputComponent.setPrompt(promptValue);
        }
      });
    }
  }

  handlePromptError(error: HttpErrorResponse) {
    this.kmdModalService.close('loadingResponseModal');
    this.showGeneralAlert = error.status == 500;
    this.showInvalidPrompt = error.status == 400;
    this.showTooManyRequests = error.status == 429;
  }

  setExampleCurrentPrompt(example: PromptExample) {
    this.promptInputComponent.setPromptAndFocus(example.text);
    // TODO: Fix this with ngZone or find a better way to do it
    // Find ngZone mocking solution
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.promptInputComponent.resizeTextarea();
      });
    });
  }

  moveToImageCreation() {
    this.ngZone.run(() => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/image-creation']));
    });
  }

  handlePromptSaved() {
    this.showPromptSavedAlert = true
  }

  showChat() {
    this.activeView = "chat"
  }

  isChatViewInactive(): boolean {
    return this.activeView != "chat";
  }

  handleResponseSelected(responseText: string) {
    this.promptInputComponent.setAppendPrompt(responseText);
  }

  setMessages(newMessages: Message[]) {
    // TODO: Add missing test
    newMessages.forEach(m => {
      this.chatComponent?.addMessage(m)
    })
    this.cdRef.detectChanges();
  }

  addMessages(newMessages: Message[]) {
    this.selectedChatId = "";
    this.cdRef.detectChanges();
    // TODO: Add missing test
    newMessages.forEach(m => {
      this.chatComponent?.addMessage(m)
    })
    this.cdRef.detectChanges();
  }

  openDrawer(): void {
    this.drawerComponent.openDrawer();
  }

  closeDrawer(): void {
    this.drawerComponent.dismissDrawer();
  }
}
