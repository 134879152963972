import {Chat} from "@shared/models/chat.model";
import {Observable} from "rxjs";
import {AgentChatsService} from "@services/agentchats/agentchats.service";
import {AgentChat} from "@shared/models/agent-chat";
import {AgentChatDecorator} from "@app/agents/agent-chat/agent-chat.component";
import {map} from "rxjs/operators";
import {ChatHistoryService} from "@services/chathistory/chat-history.service";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AgentChatHistory implements ChatHistoryService {
    constructor(private agentChatsService: AgentChatsService) { }

    delete(chatDecorator: AgentChatDecorator): Observable<void> {
        const chat = this.getChat(chatDecorator);
        const agentChat: AgentChat = { chat:chat, agentId: chatDecorator.agentID }
        return this.agentChatsService.deleteAgentChat(agentChat);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getChats(chatHistoryType: string): Observable<Chat[]> {
        return this.agentChatsService.getAgentChats().pipe( map(chats => {
            return chats.map(chat => {
                return new AgentChatDecorator(chat.chat, chat.agentId!)
            })
        }));
    }

    update(chatDecorator: AgentChatDecorator): Observable<void> {
        const chat = this.getChat(chatDecorator);
        const agentChat: AgentChat = { chat: chat as Chat, agentId: chatDecorator.agentID }
        return this.agentChatsService.updateAgentChat(agentChat);
    }

    private getChat(chatDecorator: AgentChatDecorator): Chat {
        const chat: Chat = new Chat(chatDecorator.title, chatDecorator.created)
        chat.id = chatDecorator.id
        return chat;
    }
}
