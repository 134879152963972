<li routerLinkActive="active" class="nav-menu-ul-li" [id]="menuOption.id" [ngClass]="{'external' : (menuOption.anchor?.type==='href' && isSubOption),
    'sub-option': isSubOption, 'show-in-mobile-only' : menuOption.mobileOnly}">
  <ng-container [ngSwitch]="menuOption.anchor?.type">
    <a *ngSwitchCase="'href'" [href]="menuOption.anchor?.value" [attr.target]="isSubOption ? '_blank' : null">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <a *ngSwitchCase="'routerLink'" [routerLink]="menuOption.anchor?.value">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <a *ngSwitchDefault (click)="toggleExpanded()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  </ng-container>
  <ng-template #content>
    <ng-container *ngIf="menuOption.icon">
      <fa-icon class="nav-menu-ul-li-icon" [icon]="menuOption.icon.value"
        *ngIf="menuOption.icon.type === 'fa'"></fa-icon>
      <img class="nav-menu-ul-li-icon" [src]="menuOption.icon.value" *ngIf="menuOption.icon.type=== 'src'">
    </ng-container>
    <span class="nav-menu-ul-li-text" [ngClass]="{'no-icon-span': !menuOption.icon}">{{ menuOption.label }}</span>
    <ng-container *ngIf="menuOption.isBetaFeature">
      <core-badge class="beta-badge" variant="standard" size="regular" color="green" label="BETA"></core-badge>
    </ng-container>
  </ng-template>
  <ng-container *ngIf="menuOption.submenu && menuOption.submenu.subMenuList.length > 0">
    <ng-container *ngIf="menuOption.submenu.type === 'komodo'">
      <button id="{{menuOption.id + '-collapse-btn'}}" (click)="toggleExpanded()" class="nav-menu-ul-li-expand">
        <img src="assets/icons/caret/caretRight.svg">
      </button>
    </ng-container>
    <ng-container *ngIf="!menuOption.submenu.type">
      <button id="{{menuOption.id + '-collapse-btn'}}" (click)="toggleExpanded()" class="nav-menu-ul-li-expand">
        <img src="assets/icons/caret/caretUp-11px-mono.svg" *ngIf="menuOption.submenu.isExpanded else expansion">
        <ng-template #expansion>
          <img src="assets/icons/caret/caretDown-11px-mono.svg">
        </ng-template>
      </button>
    </ng-container>
  </ng-container>
</li>
<ng-container *ngIf="menuOption.submenu?.type === 'komodo'; else classic">
  <core-contextmenu *ngIf="menuOption.submenu?.isExpanded" class="core-context-menu">
    <core-contextmenu-item (click)="navigateTo(subOption)" *ngFor="let subOption of menuOption.submenu?.subMenuList"
      [class]="menuOption.id" label='{{subOption.label}}' variant='default'>
    </core-contextmenu-item>
  </core-contextmenu>
</ng-container>
<ng-template #classic>
  <ng-container *ngIf="menuOption.submenu?.isExpanded">
    <ng-container *ngFor="let subOption of menuOption.submenu?.subMenuList">
      <ng-container *ngIf="shouldDisplaySubOption(subOption) | async">
        <app-menu-option [menuOption]="subOption" [isSubOption]="true"></app-menu-option>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
