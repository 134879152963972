<div class="banner-container" *ngIf="display">
  <div class="banner">
    <div class="banner-header">
      <span class="header-title">{{ headerTitle }}</span>
      <div class="close-icon-container">
        <app-close-icon class="close-icon" height="16px" width="16px" color="#54545C" (click)="close()" />
      </div>
    </div>
    <div class="banner-content">
      <ng-content></ng-content>
    </div>
    <div class="banner-ask">
      <core-checkbox class="checkbox" label="" [checked]="doNotShowAgain" [disabled]="disableDoNotShowAgain" (onClick)="onDoNotShowAgainChange()" />
      <span class="banner-ask-label">Don't show this again</span>
    </div>
  </div>
</div>