import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertService } from '@app/core/services/alert/alert.service';
import { AskOurDocsV2Service } from '@app/core/services/ask-our-docs-v2/ask-our-docs-v2.service';
import { UuidService } from '@app/core/services/uuid.service';
import { KmdModalService, KmdPaginationComponent } from 'gds-atom-components';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-admin-prompt-library',
  templateUrl: './admin-prompt-library.component.html',
  styleUrls: ['./admin-prompt-library.component.css']
})
export class AdminPromptLibraryComponent implements OnInit {

  @ViewChild('pagination', { static: false }) pagination!: KmdPaginationComponent;
  @Input() indexName = '';

  titlePromptArray: any = [];
  displayedTitlePromptArray: any = [];
  paginatedTilePromptArray: any = [];
  titlePromptArrayCopy: any = [];
  currentPage: number = 1;
  resultsPerPage: number = 10;
  totalPageCount: number = 1;
  startIndex: number = 0;
  endIndex: number = 10;
  searchText: string = '';
  arrayUpdated: boolean = false;
  enableTile: boolean = false;
  isLoadingPromptSuggestions = true;
  promptSuggestionsNumber: any = { value: '10', title: '10 prompts' };

  @Output() cancelFormEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  promptSuggestionsNumberArray: any[] = [
    { value: '1', title: '1 prompt' },
    { value: '2', title: '2 prompts' },
    { value: '3', title: '3 prompts' },
    { value: '4', title: '4 prompts' },
    { value: '5', title: '5 prompts' },
    { value: '6', title: '6 prompts' },
    { value: '7', title: '7 prompts' },
    { value: '8', title: '8 prompts' },
    { value: '9', title: '9 prompts' },
    { value: '10', title: '10 prompts' }
  ];

  randomizeSuggestions: boolean = false;
  suggestionsAsSavedPrompts: boolean = false;
  internalIndexName: string = '';
  saveButtonEnabled: boolean = false;
  togglesInitialState: any = {'enableTile': false, 'promptSuggestionsNumber': { value: '10', title: '10 prompts' }, 'randomizeSuggestions': false, 'suggestionsAsSavedPrompts': false};
  SUCCESS_MESSAGE_SAVE_PROMPT_LIBRARY: string = 'Prompt library saved successfully';
  FAILED_TO_SAVE_PROMPT_LIBRARY: string = 'Failed to save prompt library';

  pageNumbers: any[] = [];

  constructor(private askOurDocsV2Service: AskOurDocsV2Service,
    private uuidService: UuidService,
    private alertService: AlertService,
    private kmdModalService: KmdModalService) {
  }

  ngOnInit(): void {
    this.askOurDocsV2Service.getFileContent(this.indexName).pipe(take(1)).subscribe((response: any) => {
      this.isLoadingPromptSuggestions = false;
      this.titlePromptArray = response.tile_prompts || [];      
      this.enableTile = response?.enable_tile || false;
      const promptNumber = response?.prompt_suggestion_count.toString() || '10';
      this.promptSuggestionsNumber = { value: promptNumber, title: promptNumber === '1'? promptNumber + ' prompt' : promptNumber + ' prompts' };
      this.randomizeSuggestions = response?.randomize_suggestions || false;
      this.suggestionsAsSavedPrompts = response?.suggestions_as_saved_prompts || false;      
      this.internalIndexName = response.index_name_map;
      this.titlePromptArray = this.titlePromptArray.map((obj : any) => {
        return {...obj, "cardTitle" : obj.shortPrompt}
      })
      this.titlePromptArrayCopy = _.cloneDeep(this.titlePromptArray);
      this.displayedTitlePromptArray = this.titlePromptArray;
      this.displayedTitlePromptArray.forEach((item: any, index: number) => item.promptNumber = index + 1);
      this.titlePromptArray = this.displayedTitlePromptArray;
      this.togglesInitialState = { 'enableTile': this.enableTile, 'promptSuggestionsNumber': this.promptSuggestionsNumber, 'randomizeSuggestions': this.randomizeSuggestions, 'suggestionsAsSavedPrompts': this.suggestionsAsSavedPrompts };
      this.updatePaginatedArray();
      this.updatePageNumbersArray();
    }, (error : any)=>{
      console.log("Error", error)
      this.isLoadingPromptSuggestions = false;
    });
  }

  updatePageNumbersArray() {
    this.pageNumbers = Array.from({ length: this.totalPageCount }, (_, i) => ({ value: (i + 1).toString() }))
      .filter(page => parseInt(page.value) !== this.currentPage);
  }

  updatePaginatedArray() {
    this.startIndex = (this.currentPage - 1) * this.resultsPerPage;
    this.endIndex = this.startIndex + this.resultsPerPage;
    this.paginatedTilePromptArray = this.displayedTitlePromptArray.slice(this.startIndex, this.endIndex);
    this.totalPageCount = Math.ceil(this.displayedTitlePromptArray.length / this.resultsPerPage);
    this.updatePageNumbersArray();
    this.hasUnsavedChanges();
  }

  moveItemDown(index: number, event: MouseEvent) {
    event.stopPropagation();
    if (index < this.displayedTitlePromptArray.length - 1) {
      const temp = this.displayedTitlePromptArray[index];
      this.displayedTitlePromptArray[index] = this.displayedTitlePromptArray[index + 1];
      this.displayedTitlePromptArray[index + 1] = temp;
    }
    this.displayedTitlePromptArray.forEach((item: any, index: number) => item.promptNumber = index + 1);
    this.titlePromptArray = _.cloneDeep(this.displayedTitlePromptArray);
    this.updatePaginatedArray();
    this.hasUnsavedChanges();
  }

  moveItemUp(index: number, event: MouseEvent) {
    event.stopPropagation();
    if (index > 0) {
      const temp = this.displayedTitlePromptArray[index];
      this.displayedTitlePromptArray[index] = this.displayedTitlePromptArray[index - 1];
      this.displayedTitlePromptArray[index - 1] = temp;
    }
    this.displayedTitlePromptArray.forEach((item: any, index: number) => item.promptNumber = index + 1);
    this.titlePromptArray = _.cloneDeep(this.displayedTitlePromptArray);
    this.updatePaginatedArray();
    this.hasUnsavedChanges();
  }

  onPageChange(paginationEvent: any) {
    this.currentPage = paginationEvent.currentPage;
    this.resultsPerPage = paginationEvent.resultsPerPage;
    this.updatePaginatedArray();
    this.hasUnsavedChanges();
  }

  onResultsPerPageChange(paginationEvent: any) {
    this.currentPage = paginationEvent.currentPage;
    this.resultsPerPage = paginationEvent.resultsPerPage;
    this.updatePaginatedArray();
    this.hasUnsavedChanges();
  }

  filterPrompts(searchText: string) {
    if (searchText == '') {
      this.displayedTitlePromptArray = this.titlePromptArray;
    } else {
      this.pagination.selectPage(1);
      this.displayedTitlePromptArray = this.titlePromptArray.filter((item: any) => {
        return item.shortPrompt.toLowerCase().includes(searchText.toLowerCase() || item.longPrompt.toLowerCase().includes(searchText.toLowerCase()));
      });
    }
    this.startIndex = 0;
    this.endIndex = this.startIndex + this.resultsPerPage;
    this.paginatedTilePromptArray = this.displayedTitlePromptArray.slice(this.startIndex, this.endIndex);
    this.totalPageCount = Math.ceil(this.displayedTitlePromptArray.length / this.resultsPerPage);
  }

  removeItem(promptNumber: number, event: MouseEvent) {
    event.stopPropagation();
    if(this.searchText === '') {
      this.titlePromptArray.splice(promptNumber - 1, 1);
      this.titlePromptArray.forEach((item: any, index: number) => item.promptNumber = index + 1);
      this.displayedTitlePromptArray = this.titlePromptArray;
      this.updatePaginatedArray();
      this.hasUnsavedChanges();
      const isLastInPage = this.displayedTitlePromptArray.length % this.resultsPerPage === 0;
      if(isLastInPage) {
        this.pagination.selectPage(this.currentPage - 1);
      }
    }
  }

  addNewPrompt() {
    this.searchText = "";
    this.filterPrompts("");

    this.displayedTitlePromptArray.splice(this.resultsPerPage*(this.currentPage - 1), 0, {
      shortPrompt: "",
      longPrompt: "",
      expanded: true,
      cardTitle: "[prompt title]"
    });

    this.displayedTitlePromptArray.forEach((item: any, index: number) => item.promptNumber = index + 1);
    this.titlePromptArray = this.displayedTitlePromptArray;
    this.updatePaginatedArray();
    setTimeout(() => {
      this.hasUnsavedChanges();
      this.saveButtonEnabled = false;
    }, 700);
  }

  hasUnsavedChanges() {
    const stripExtraProps = (array: any) => array.map(({ shortPrompt, longPrompt }: any) => ({ shortPrompt, longPrompt }));
    const strippedTitlePromptArrayCopy = stripExtraProps(this.titlePromptArrayCopy);
    const strippedTitlePromptArray = stripExtraProps(this.titlePromptArray);

    setTimeout(() => {      
      const currentTogglesState = { 'enableTile': this.enableTile, 'promptSuggestionsNumber': this.promptSuggestionsNumber, 'randomizeSuggestions': this.randomizeSuggestions, 'suggestionsAsSavedPrompts': this.suggestionsAsSavedPrompts };
      this.saveButtonEnabled = !_.isEqual(strippedTitlePromptArray, strippedTitlePromptArrayCopy) || !_.isEqual(this.togglesInitialState, currentTogglesState);
      if (this.hasEmptyFields() || this.searchText !== '') {
        this.saveButtonEnabled = false;
      }
    }, 500);
  }

  hasEmptyFields() {
    for (const item of this.titlePromptArray) {
      if (item.shortPrompt == '' || item.longPrompt == '') {
        return true;
      }
    }
    return false;
  }

  savePromptLibrary() {
    this.closeModal('confirmation-modal-save-changes-prompt-library');
    const randomId = this.uuidService.random();
    const payload = {
      "tile_prompts": this.titlePromptArray.map((res: any) => {
        return {
          id: res.id ? res.id : randomId,
          userId: '',
          shortPrompt: res.shortPrompt,
          longPrompt: res.longPrompt,
          indexName: this.internalIndexName,
          category: 'public',
        }
      }),
      "enable_tile": this.enableTile || false,
      "prompt_suggestion_count": Number(this.promptSuggestionsNumber.value) || 10,
      "randomize_suggestions": this.randomizeSuggestions || false,
      "suggestions_as_saved_prompts": this.suggestionsAsSavedPrompts || false
    }
    const updatedindexName = this.indexName.replace(/ /g, '_');
    this.askOurDocsV2Service.saveFileContent(updatedindexName, payload).pipe(take(1)).subscribe(() => {
      this.alertService.showSuccess(this.SUCCESS_MESSAGE_SAVE_PROMPT_LIBRARY);
      this.saveButtonEnabled = false;
      this.ngOnInit();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
     (error) => {
      this.alertService.showError(this.FAILED_TO_SAVE_PROMPT_LIBRARY);
    });
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.displayedTitlePromptArray, 
      event.previousIndex + (this.currentPage - 1) * this.resultsPerPage,
      event.currentIndex + (this.currentPage - 1) * this.resultsPerPage
    );
    
    this.displayedTitlePromptArray.forEach((item: any, index: number) => 
      item.promptNumber = index + 1
    );
    
    this.titlePromptArray = _.cloneDeep(this.displayedTitlePromptArray);
    this.updatePaginatedArray();
    this.hasUnsavedChanges();
  }

  onDropdownClick(event : MouseEvent) {
    event.stopPropagation();
  }

  onPromptSuggestionPageChange(event: any , index : number) {
    const actualIndex = this.resultsPerPage*(this.currentPage-1) + index;
    const promptSuggestion = this.titlePromptArray[actualIndex];
    this.titlePromptArray.splice(actualIndex, 1);
    this.titlePromptArray.splice(this.resultsPerPage*(event.selectedOption.value-1), 0, promptSuggestion);
    this.updatePaginatedArray();
    this.hasUnsavedChanges();
  }

  cancelPromptLibraryForm() {
    this.cancelFormEvent.emit(true)
  }

  closeModal(modal: string) {
    this.kmdModalService.close(modal);
  }

  openModal(modal: string) {
    this.kmdModalService.open(modal);
  }

  cancelAndDontSaveChanges() {
    this.saveButtonEnabled = false;
    this.cancelPromptLibraryForm();
  }

  checkForUnsavedChangesAndCancel() {
    if (this.saveButtonEnabled || this.hasEmptyFields()) {
      this.openModal('confirmation-modal-unsaved-changes-prompt-library');
    } else {
      this.cancelPromptLibraryForm();
    }
  }

  canSavePromptLibrary() {
    return this.saveButtonEnabled && !this.hasEmptyFields() && !this.searchText;
  }

  async savePromptLibraryAndNavigate() {
    await this.savePromptLibrary();
    this.saveButtonEnabled = false;
    this.cancelPromptLibraryForm();
  }
}