<app-modal [id]="id" [headline]="headline">
    <div #modalContent class="member-list-content" [ngClass]="{'scrollable': scrollableModal}">
        <div class="top">
            <p>
                Please verify who you are sharing with. We will only show the first {{MEMBERS_TO_SHOW_COUNT}} group members, you can see the rest
                by following the see all members link.
            </p>
        </div>
        <div class="middle">
            <table>
                <thead>
                    <tr>
                        <th>
                            <p>Name</p>
                        </th>
                        <th>
                            <p>Email</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let member of membersToShow">
                        <td>
                            <p>{{member.displayName}}</p>
                        </td>
                        <td>
                            <p>{{member.mail}}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bottom">
            <ng-container *ngIf="groupMembers.length > MEMBERS_TO_SHOW_COUNT">
                <a href="https://myaccount.microsoft.com/groups/{{group!.id}}" target="_blank">See all {{ this.groupMembers.length }} members</a>
            </ng-container>
            <button kmdFeaturedButton id="back-btn" (click)="closeModal()">Back</button>
        </div>
    </div>
</app-modal>