import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatUsername'
})
export class FormatUsernamePipe implements PipeTransform {

  transform(value: string): string {
    const name = value.split(',');
    const firstName = name[1].split(' ').length > 1 ? name[1].split(' ')[1] : name[1];
    const lastName = name[0].split(' ').length > 1 ? name[0].split(' ')[0] : name[0];

    return `${firstName} ${lastName}`;
  }
}
