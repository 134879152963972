import { Component, Input } from '@angular/core';
import { Positioning } from "@shared/models/positioning";

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent {

  @Input() text: string = '';
  @Input() isVisible: boolean = false;
  @Input() position: Positioning = 'top';

  protected top: number = 0;
  protected left: number = 0;

  private readonly offset = 8;

  setCoordinates(domRect: DOMRect) {
    if (this.position === 'top') {
      this.left = this.findMiddle(domRect.left, domRect.right);
      this.top = domRect.top - this.offset;
    }
    else if (this.position === 'bottom') {
      this.left = this.findMiddle(domRect.left, domRect.right);
      this.top = domRect.bottom + this.offset;
    }
    else if (this.position === 'right') {
      this.left = domRect.right + this.offset;
      this.top = this.findMiddle(domRect.top, domRect.bottom);
    }
    else if (this.position === 'left') {
      this.left = domRect.left - this.offset;
      this.top = this.findMiddle(domRect.top, domRect.bottom);
    }
  }

  private findMiddle(firstCoordinate: number, secondCoordinate: number): number {
    return (secondCoordinate - firstCoordinate) / 2 + firstCoordinate;
  }

}
