<div class="ask-our-docs-container" *ngIf="repositories$ | async as repositories">
  <div class="ask-our-docs-toolbar">
    <div class="aod-toolbar-content">
      <div class="aod-toolbar-title">
        <h1>Ask Our Docs</h1>
      </div>
      <p class="aod-toolbar-description">Pick a repository available to you to start responding your queries.</p>
    </div>
  </div>
  <div class="loading-wheel-wrapper" *ngIf="isInfoLoading; else reposView">
    <kmd-loading-wheel></kmd-loading-wheel>
  </div>
  <ng-template #reposView>
    <div class="ask-our-docs-content" *ngIf="hasRepositories; else noReposView" [formGroup]="formGroup">
      <div class="toolbar">
        <div id="search-text-container">
          <input type="text" class="search-term" formControlName="search" kmdFormInput id="repository-search-input"
            placeholder="Search by name" autocomplete="off" />
        </div>
        <ng-container *featureFlag="'askOurDocsUserManagementPage'">
          <div id="filter-dropdown-container" *ngIf="isUserRepoAdmin">
            <kmd-dropdown [options]="filterByOptions" inlineLabel="Filter by:" ngDefaultControl
              [(ngModel)]="selectedFilter" [ngModelOptions]="{standalone: true}"
              (ngModelChange)="onFilterDropdownChange($event)"></kmd-dropdown>
          </div>
        </ng-container>
      </div>
      <span class="repos-count">
        {{
        repositories.totalElements === 0 ? '0 results for "' + formGroup.controls.search.value + '"' :
        repositories.totalElements === 1 ? '1 item' :
        repositories.totalElements + ' items'
        }}
      </span>
      <div class="no-search-results" [hidden]="!repositories.empty">
        <img src="../../../assets/images/search.svg">
        <p class="no-matches">No matches for "{{formGroup.controls.search.value}}"</p>
        <p class="advice">Try using different terms</p>
      </div>
      <div class="cards">
        <app-card *ngFor="let repository of repositories.content" [buttonLabel]="buttonLabel" [id]="repository.id"
          [title]="repository.name" [repositoryOwners]="fetchOwnersByRepository(repository.name)" [isUserAdmin]="repository.isAdmin"
          [repositoryGroup]="fetchGroupByRepository(repository.id)" [url]="getAODUrlPath(repository.id)"></app-card>
      </div>
      <kmd-pagination #pagination [totalResults]="repositories.totalElements" (onPageChange)="onPageChange($event)">
      </kmd-pagination>
    </div>
    <ng-template #noReposView>
      <div class="no-repos-view">
        <div class="no-repos-content">
          <h2 class="no-repos-header">You’re not assigned to any repository yet</h2>
          <p class="no-repos-description">Request access to available repositories to your manager or create your own
            repository and share it with your peers.</p>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
