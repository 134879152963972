<app-modal #modal [id]="modalId" [headline]="title" [config]="modalConfig">
  <div class="dialog-content">
    <div class="dialog-description">{{ message }}</div>
    <div #contentContainer class="dialog-buttons">
      <ng-content></ng-content>
    </div>
    @if (!hasProjectedContent) {
      <div class="dialog-buttons">
        <button kmdInfoButton (click)="onDismiss()">Cancel</button>
        <button kmdFeaturedButton (click)="onConfirm()">Accept</button>
      </div>
    }
  </div>
</app-modal>
