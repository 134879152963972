

<kmd-modal id='createChart' modalSize="small" headline="Create chart" [closeOnClickingOutside]="false" (modalClosed)="clearData()">
  <div *ngIf="apiFailed">
    <kmd-alerts kmd-error [show]="true" [icon]="true">Failed to create chart, please try again.</kmd-alerts>
  </div>
  <div class="my-2"><label kmdFormLabel [required]="true">Select chart type: </label>
    <kmd-dropdown id="chart-type-form" optionLabelKey="app" [options]="chartTypes" [(ngModel)]="chartType" [externalLabel]="true" > </kmd-dropdown>
  </div>
  <div *ngIf="displayAxis()" class="mt-3  d-flex justify-content-between">
    <div class="col-md-5 col-sm-5 pl-2"><label kmdFormLabel [required]="true">Select X axis: </label>
      <kmd-dropdown id="chart-xaxis-form"  [options]="headers" [(ngModel)]="xaxis" [externalLabel]="true" > </kmd-dropdown>
    </div>
    <div class="col-md-5 col-sm-5 pr-2"><label kmdFormLabel [required]="true">Select Y axis: </label>
      <kmd-dropdown id="chart-yaxis-form"  [options]="headers" [(ngModel)]="yaxis" [externalLabel]="true" > </kmd-dropdown>
    </div>
  </div>
  <div *ngIf="displayValues()" class="mt-3 display-columns d-flex justify-content-between">
    <div class=" pl-2"><label kmdFormLabel [required]="true">Select values: </label>
      <kmd-checkbox *ngFor="let header of headers" name="columnName" value="{{header}}" label="{{header}}" [(ngModel)]="selectedColumns" ></kmd-checkbox>
    </div>
  </div>
  <div class="float-end mt-5" >
      <button kmdInfoButton type="button" class="me-3" (click)="closeModal('createChart')" >Cancel</button>
      <button id="modalCreateChart" kmdFeaturedButton type="button" [disabled]="!isValid()" (click)="createChart()">Create chart</button>
  </div>
</kmd-modal>