<div class="record-container" [ngClass]="{'selected': selected}" (click)="onClick()">
  <ng-template [ngIf]="record">
    <div class="record-title-section">
      <span *ngIf="!editingEnabled">{{record.title}}</span>
      <textarea #titleTextarea *ngIf="editingEnabled" [(ngModel)]="inputValue" (blur)="stopEditing(undefined)"
                (keydown)="onKeyDown($event)" minlength="3" maxlength="120" rows="1"></textarea>
    </div>
    <div class="record-updated-section">{{ formatDate(record.updatedAt)}}</div>
  </ng-template>
</div>
<app-delete-modal #deleteModal  *ngIf="record" [headline]="'Delete Chat'"
                  [description]="'You\'re about to delete this chat, this action is permanent and you won\'t be able to recover it. Do you want to delete it?'"
                  (confirmDeleteEvent)="doDeleteChat(record)" (cancelDeleteEvent)="deleteCancelled()"></app-delete-modal>
