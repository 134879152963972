import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-file-manager-acknowledgement-modal',
  templateUrl: './file-manager-acknowledgement-modal.component.html',
  styleUrls: ['./file-manager-acknowledgement-modal.component.css']
})
export class FileManagerAcknowledgementModalComponent {
  @Output() onConfirm: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

  isAcknowledged: boolean = false;
  processingRequest: boolean = false;

  readonly modalId: string = "file-manager-acknowledgement-modal";

  closeModal() {
    this.isAcknowledged = false;
    this.onCancel.emit();
  };

  accept() {
    this.processingRequest = true;
    this.onConfirm.emit();
  };

  toggleAcknowledge(): void {
    if (this.processingRequest) {
      return;
    }

    this.isAcknowledged = !this.isAcknowledged;
  };
}
