import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appToggleNavMenu]'
})
export class ToggleNavMenuDirective {

  constructor() { }

  @Output() ToggleChatHistoryEvent = new EventEmitter<string>();


  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    const isTriggerElement = targetElement.closest('.trigger-toggle-nav-menu') !== null

    if (isTriggerElement) {
      const chatHistory = document.querySelector('[appToggleChatHistory]') as HTMLElement;
      if (chatHistory) {
        if (chatHistory.offsetWidth > 0) {
          this.ToggleChatHistoryEvent.emit('historyClosed');
        }
        else {
          this.ToggleChatHistoryEvent.emit('historyOpened');
        }
      }

    }
  }

}
