<div class="container-error-page">
  <span id="errorCodeId" class="error-code flex-item">ERROR: {{errorCode}}</span>
  <span id="titleId" class="title flex-item">{{title}}</span>
  <div class="container-left flex-item">
    <img class="logo" src="assets/images/ask-gene-logo.svg" alt="ask gene logo">
  </div>
  <div class="container-right flex-item">
    <span id="descriptionId" class="description" [innerHTML]="description"> </span>

    <div class="container-buttons">
      <button id="btnPrimaryId" kmdPrimaryButton (click)="goBack()">{{buttonText}}</button>
      <button id="btnSecondaryId" kmdFeaturedButton (click)="goHome()">{{secondaryButtonText}}</button>
    </div>

    <div class="container-assistence">
      <span>Need assistance?</span>
      <a href="mailto:GenAICOE@thermofisher.com">Contact us</a>
    </div>
  </div>
</div>