import { Component, NgZone, ViewChild } from '@angular/core';
import { MenuOptionNewComponent } from '../menu-option-new/menu-option-new.component';
import { Router } from '@angular/router';
import { MenuOption } from '@app/shared/models/navbar/menu-option';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { GuardCheckerService } from '@app/core/guard-checkers/guard-checker-service';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { NavBarService } from '@app/core/services/navbar/navbar.service';

@Component({
  selector: 'app-sub-menu-option',
  templateUrl: './sub-menu-option.component.html',
  styleUrls: ['./sub-menu-option.component.css']
})
export class SubMenuOptionComponent extends MenuOptionNewComponent {
  @ViewChild(SubMenuComponent) subMenu?: SubMenuComponent;

  constructor(
    protected override router: Router,
    protected override zone: NgZone,
    protected override navBarService: NavBarService,
    private guardCheckService: GuardCheckerService,
  ) {
    super(router, zone, navBarService);
  }


  handleMenuClick(event: { menuOption?: MenuOption, event?: MouseEvent }): void {
    if (event.menuOption?.submenu && event.menuOption?.submenu.subMenuList && event.menuOption?.submenu.subMenuList.length > 0) {
      const subMenuOptions$ = event.menuOption.submenu.subMenuList.map(option =>
        this.showSubMenuOption$(option).pipe(
          map(show => show ? option : null)
        )
      );

      combineLatest(subMenuOptions$).subscribe(filteredOptions => {
        event.menuOption!.submenu!.subMenuList = filteredOptions.filter(option => option !== null) as MenuOption[];
        this.subMenu?.showPopover(event.event!);
      });
    }
  }

  showSubMenuOption$(option: MenuOption): Observable<boolean> {
    if (option.featureFlag?.every(flag => flag === false)) {
      return of(false);
    }

    return this.guardCheckService.areAllOptionGuardsPassed(option);
  };
}
