import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  Output,
  SecurityContext, SimpleChanges
} from "@angular/core";
import mermaid from "mermaid";
import { ClipboardService } from "ngx-clipboard";
import { Source } from "@shared/models/message.model";
import { MarkdownService } from "ngx-markdown";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { MessageNode } from "@app/shared/models/message-node.model";
import { Observable, from, shareReplay, Subject, of } from 'rxjs';
import { switchMap, takeUntil, catchError, tap } from 'rxjs/operators';
import { CodeExecutorService } from "@services/code-executor/code-executor.service";
import { featureFlags } from "@environments/environment";
import { AssistantMessageTypeConstant } from '@app/shared/constants/assistant-message/assistant-message-type';
@Component({
  selector: 'app-assistant-message',
  templateUrl: './assistant-message.component.html',
  styleUrls: ['./assistant-message.component.css']
})
export class AssistantMessageComponent implements AfterViewInit, OnChanges {
  @Input() messageNode?: MessageNode | null = null;
  @Input() isGeneratingResponse: boolean = true;
  @Input() isMessageCompleted?: boolean = false;
  @Input() imageCache = new Map<string, Observable<string | null>>();
  @Input()
  set type(value: any) {
    this._type = value;
  };

  get type(): any {
    return this._type;
  };

  private _type: any = AssistantMessageTypeConstant.DEFAULT;

  @Output() regenerateResponse = new EventEmitter<string>();
  @Output() activeMessageChange = new EventEmitter<{ assistantMessageNode: MessageNode, nextSiblingIndex: number }>();

  showAllSources: boolean = false;
  filesUrls: string[] = [];

  private renderedImages = new Set<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private clipboard: ClipboardService,
    private cdr: ChangeDetectorRef,
    public markdownService: MarkdownService,
    private sanitizer: DomSanitizer,
    private codeExecutorService: CodeExecutorService,
    private http: HttpClient
  ) {
    this.initializeMarkdownImageRenderer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isMessageCompleted'] && this.isMessageCompleted && featureFlags.codeInterpreter) {
      this.fetchGeneratedFiles();
    }
  }

  public initializeMarkdownImageRenderer(): void {
    // @ts-ignore
    this.markdownService.renderer.image = (href: string | null, title: string | null, text: string | null) => {
      const safeHref = this.sanitizer.sanitize(SecurityContext.URL, href);
      if (!safeHref) return '';
      if (!this.isSafeSource(safeHref)) return '';
      const uniqueId = this.generateUniqueId(safeHref);
      const placeholder = this.createPlaceholder(uniqueId, text ?? '');
      setTimeout(() => this.loadImage(safeHref, uniqueId, title, text), 0);
      return placeholder;
    };
  }

  isSafeSource(url: string): boolean {
    return url.includes("thermofisher") || url.includes("gene-ai-code");
  }

  private fetchGeneratedFiles(): void {
    if (this.messageNode!.content!.message!.generatedFilesIds && this.filesUrls.length === 0) {
      this.messageNode!.content.message.generatedFilesIds.map(fileId =>
        this.codeExecutorService.getFile(fileId).pipe(
          tap(file => {
            let generated = "![ gene-ai_" + fileId + "](" + file.download_url + ")";
            this.filesUrls.push(generated);
          })
        )
      ).forEach(file$ => file$.subscribe());
    }
  }

  private loadImage(safeHref: string, uniqueId: string, title: string | null, text: string | null): void {
    if (this.renderedImages.has(uniqueId)) {
      return;
    }
    this.renderedImages.add(uniqueId);

    if (!this.imageCache.has(safeHref)) {
      const imageLoader$ = this.http.get(safeHref, {
        responseType: 'blob',
        observe: 'response'
      }).pipe(
        tap(response => {
          if (response.status !== 200) {
            throw new Error(`HTTP status ${response.status}`);
          }
        }),
        switchMap(response => from(this.blobToDataUrl(response.body!))),
        catchError(() => {
          this.renderedImages.delete(uniqueId);
          this.imageCache.delete(safeHref);
          this.updatePlaceholderWithError(uniqueId);
          return of(null);
        }),
        shareReplay(1),
        takeUntil(this.destroy$)
      );

      this.imageCache.set(safeHref, imageLoader$);
    }

    this.imageCache.get(safeHref)!.subscribe({
      next: (dataUrl) => {
        if (dataUrl) {
          let dataType = this.getDataTypeFromDataUrl(dataUrl);
          if (dataType && dataType.includes('image')) {
            this.insertImage(dataUrl, uniqueId, title ?? '', text ?? '');
          } else if (dataType && dataType.includes('text')) {
            this.insertText(dataUrl, uniqueId);
          }
          this.cdr.detectChanges();
        }
      },
      error: () => {
        this.updatePlaceholderWithError(uniqueId);
      }
    });
  }

  private getDataTypeFromDataUrl(dataUrl: string): string | null {
    const match = dataUrl.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-+]+)(;base64)?,/);
    return match?.[1] ?? null;
  }

  private updatePlaceholderWithError(uniqueId: string): void {
    const placeholder = document.getElementById(uniqueId);
    if (placeholder) {
      placeholder.innerHTML = '<div class="error-message">Failed to load image</div>';
    }
  }

  private blobToDataUrl(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        if (result) {
          resolve(result);
        } else {
          reject(new Error('FileReader returned null result'));
        }
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(blob);
    });
  }

  public generateUniqueId(url: string): string {
    return `img-${btoa(url).replace(/[^a-zA-Z0-9]/g, '')}`;
  }

  public insertImage(dataUrl: string, uniqueId: string, title: string, text: string): void {
    const placeholder = document.getElementById(uniqueId);
    if (placeholder) {
      // Create image / download button container
      const imageAndDownloadButtonContainer = document.createElement('div');
      imageAndDownloadButtonContainer.className = 'assistants-image-with-download-button-container';
      imageAndDownloadButtonContainer.style.cssText = "position: relative; display: inline-block;";

      // Create image
      const img = document.createElement('img');
      img.src = dataUrl;
      img.alt = text || '';
      img.title = title || '';
      img.className = 'markdown-image';

      const blob = this.dataURLToBlob(dataUrl);
      const blobUrl = URL.createObjectURL(blob);

      // Create clickable link
      const link = document.createElement('a');
      link.href = blobUrl;
      link.target = '_blank';
      link.appendChild(img);

      // Create download button container
      const downloadContainer = document.createElement('button');
      downloadContainer.className = "assistant-image-download";
      downloadContainer.onclick = () => {
        this.downloadImage(uniqueId, dataUrl);
      };

      // Create download button image
      const downloadIcon = document.createElement('i');
      downloadIcon.className = "assistant-image-download-button";
      downloadContainer.appendChild(downloadIcon);

      imageAndDownloadButtonContainer.appendChild(downloadContainer);
      imageAndDownloadButtonContainer.appendChild(link);

      img.onload = () => {
        placeholder.parentNode?.replaceChild(imageAndDownloadButtonContainer, placeholder);
        imageAndDownloadButtonContainer.classList.toggle('visible');
      };

      img.onerror = () => {
        if (placeholder.parentNode?.contains(imageAndDownloadButtonContainer)) {
          placeholder.parentNode?.removeChild(imageAndDownloadButtonContainer);
        }
        this.updatePlaceholderWithError(uniqueId);
      };
    }
  }

  public dataURLToBlob(dataUrl: string): Blob {
    const byteString = atob(dataUrl.split(",")[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  public insertText(dataUrl: string, uniqueId: string): void {
    const placeholder = document.getElementById(uniqueId);
    if (placeholder) {
      const div = document.createElement('div');
      div.innerHTML = atob(dataUrl.split(',')[1]);
      div.className = 'code-executor-source markdown-text';
      placeholder.parentNode?.replaceChild(div, placeholder);
    }
  }

  public createPlaceholder(uniqueId: string, text: string): string {
    return `
      <div id="${uniqueId}" class="spinner-container">
        <div class="spinner"></div>
        <span class="loading-text">${text || ''} Loading...</span>
      </div>`;
  }

  public downloadImage(title: string, dataUrl: string): any | null {
    const blob = this.dataURLToBlob(dataUrl);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = title;

    link.click();

    window.URL.revokeObjectURL(link.href);
  }

  get hasMultipleMessages(): boolean {
    if (!this.messageNode) {
      return false;
    }

    return this.messageNode.sibling.length > 0;
  }

  ngAfterViewInit(): void {
    this.onMarkdownReady();
  }

  onMarkdownReady(): void {
    this.renderedImages.clear();
    this.cdr.detectChanges();
    this.renderMermaid();
  }

  // TODO: Remove this method or implement OnDestroy interface because it currently is not being used
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private renderMermaid() {
    setTimeout(() => {
      try {
        mermaid.run();
      } catch (error) {
        console.error('Mermaid rendering failed:', error);
      }
    }, 100);
  }

  copyAssistantPrompt() {
    if (this.messageNode) {
      const text = this.messageNode.content.message!.text ? this.messageNode.content.message!.text : '';
      this.clipboard.copyFromContent(text);
    }
  };

  showReferences() {
    if (this.messageNode) {
      return this.messageNode.content.message!.sources?.filter(
        source => source.type === 'WEB_SEARCH' || source.type === 'ASK_MY_DOCS'
      ).length ?? 0;
    }

    return 0;
  }

  toggleReferenceDisplay() {
    this.showAllSources = !this.showAllSources;
  }

  caretDisplay() {
    return this.showAllSources ? 'caretUp' : 'caretDown';
  }

  getSourceDisplayTitle(source: Source) {
    if (source.type === 'ASK_MY_DOCS') {
      const pageNumber = RegExp(/page=(\d+)/).exec(source.url)?.[1];
      return `${source.title} - Page ${pageNumber}`;
    }
    return source.title;
  }

  getSourcesTitle(sources: Source[] | undefined) {
    if (sources && sources[0].type === 'ASK_MY_DOCS') {
      return "Ask My Docs file references:";
    }
    return "Web search sources:";
  }

  triggerRegenerateResponse() {
    this.regenerateResponse.emit(this.messageNode!.content.message.parentId);
  }

  showPreviousResponse() {
    if (this.isPreviousResponseAvailable) {
      const changeRequest = {
        assistantMessageNode: this.messageNode!,
        nextSiblingIndex: this.messageNode!.siblingIndex - 1
      };
      this.activeMessageChange.emit(changeRequest);
    }
  };

  showNextResponse() {
    if (this.isNextResponseAvailable) {
      const changeRequest = {
        assistantMessageNode: this.messageNode!,
        nextSiblingIndex: this.messageNode!.siblingIndex + 1
      };
      this.activeMessageChange.emit(changeRequest);
    }
  };

  get isPreviousResponseAvailable() {
    if (!this.messageNode) {
      return false;
    }

    return this.messageNode.siblingIndex > 0;
  }

  get isNextResponseAvailable() {
    if (!this.messageNode) {
      return false;
    }

    return this.messageNode.siblingIndex < this.messageNode.sibling.length;
  }

  protected readonly featureFlags = featureFlags;
}
