<ng-container *ngIf="getUserPrincipalNameList().length > 1; else singleUser">
  <kmd-modal id="remove-users-modal" headline="Remove users" modalSize="small">
    <p class="modal-message">Are you sure that you want to revoke access to selected users to repository?</p>
    <p id="emailsSelected">Emails selected ({{users.size}}):</p>
    <div id="userListContainer">
      <ul *ngFor="let userName of getUserPrincipalNameList()">
        {{userName}}
      </ul>
    </div>
    <div class="aod-buttons-container">
      <button class="cancel-button" (click)="closeModal()" kmdInfoButton>Cancel</button>
      <button class='confirm-button' [disabled]="isConfirmButtonDisabled" (click)="removeUsersFromGroup()"
        kmdFeaturedButton>Confirm</button>
    </div>
  </kmd-modal>
</ng-container>

<ng-template #singleUser>
  <kmd-modal id="remove-user-mini-modal" headline="Remove user" modalSize="mini">
    <p class="modal-message">Are you sure that you want to revoke access to <b>{{getUserPrincipalNameList()}}</b> to
      repository?</p>
    <div class="aod-buttons-container">
      <button class="cancel-button" (click)="closeModal()" kmdInfoButton>Cancel</button>
      <button class='confirm-button' (click)="removeUsersFromGroup()" kmdFeaturedButton>Confirm</button>
    </div>
  </kmd-modal>
</ng-template>

<kmd-modal [id]="removeSelfUserWarningModalId" headline="Removing yourself" modalSize="mini">
  <p class="modal-message">
    You cannot remove yourself from the repository. Please ask another admin to remove you.
  </p>
  <div class="aod-buttons-container">
    <button class='close-button' (click)="closeWarningModal()" kmdFeaturedButton>Close</button>
  </div>
</kmd-modal>
