export const UserMessageTypeConstant = {
  DEFAULT: {
    copyIcon: true,
    saveIcon: true,
    fileIcon: true,
  },
  AGENT: {
    copyIcon: false,
    saveIcon: false,
    fileIcon: true,
  }
};
