<div id="agent-card-{{ agent!.id }}" class="agent-card-container" [ngClass]="type">
  <div class="agent-card">
    <div class="avatar">
      <img ngSrc="../../../../assets/images/agent-avatar.png" alt="agent logo" height="64" width="64" />
    </div>
    <div class="agent-info">
      <div class="agent-title-wrapper" [ngClass]="isSmallCard ? 'small-card' : ''">
        <div class="agent-name-wrapper">
          <h5 class="agent-name"><a (click)="startChat()">{{agent.name}}</a></h5>
        </div>
        <ng-container *featureFlag="'agentRating'">
          <div class="agent-details-wrapper">
            <ng-container *featureFlag="'recommendedAgents'">
              <app-agent-recommendation *ngIf="isRecommended"/>
            </ng-container>
            <app-agent-rating agent-rating *ngIf="showRating" [agent]="agent" [displayLikeCountOnly]="isSmallCard" />
          </div>
        </ng-container>
      </div>
      <p class="agent-description">{{ agent.description }}</p>
      <div class="agent-warning-info">
        <p class="agent-creator" *ngIf="username">Created by: {{username | formatUsername }}</p>
        <p class="agent-creator" *ngIf="showPublicCreatedBy()">Created by: {{getCreatedBy()}}</p>
        <p class="agent-warning" *ngIf="!isAgentShareable()"><img class="warning-img"
            src="../assets/icons/warning/warning.svg">Agent not shareable due to inclusion of unique tools</p>
      </div>
    </div>
    <div class="controls">
      <ng-content select="[share-status-badge]" />
      <core-button class="chat-button normal" id="chat-button" label="Chat with Agent" type="button" variant="outline"
        size="small" iconclass="icon-16-download-mono" (click)="startChat()">
      </core-button>
      <core-button id="chat-button" class="chat-button mobile" label="Chat" type="button" variant="outline" size="small"
        iconclass="icon-16-download-mono" (click)="startChat()">
      </core-button>
      <div class="ellipsis" (click)="showActionsMenu($event)">
        <fa-icon [icon]="faEllipsis"></fa-icon>
      </div>
    </div>
    <kmd-popover #popOver id="actions-menu" popoverPosition="bottom" position="left" [positionX]="posX"
      [positionY]="posY">
      <ul class="agent-menu-ul" *ngIf="!belongsToPublicAgentTab(); else publicAgentMenu">
        <li class="agent-menu-li" (click)="navigateToEditAgent()">Edit</li>
        <li class="agent-menu-li" (click)="openDeleteAgentDialog()">Delete</li>
        <ng-container *featureFlag="'agentSharing'">
          <li class="agent-menu-li" [ngClass]="getAgentMenu()" (click)="openShareAgentDialog()"
            *ngIf="agent.isPublic !== true">Share agent</li>
        </ng-container>
      </ul>
      <ng-template #publicAgentMenu>
        <ul class="agent-menu-ul">
          <li class="agent-menu-li" (click)="addToMyAgents()">Add to my agents</li>
          <li class="agent-menu-li" (click)="viewAgent()">View agent</li>
          <li class="agent-menu-li" (click)="navigateToEditAgent()" *ngIf="agent.publishedByUser && featureFlags.editPublicAgent">Edit Agent</li>
          <li class="agent-menu-li" (click)="openDeleteAgentDialog()" *ngIf="isUserApprover || agent.publishedByUser">Delete</li>
          <ng-container *featureFlag="'recommendedAgents'">
            <li class="agent-menu-li" (click)="recommendAgent()" *ngIf="isUserAbleToRecommend()">Recommend agent</li>
            <li class="agent-menu-li" (click)="removeRecommendedAgent()" *ngIf="isUserAbleToRemoveRecommendation()">Unrecommend agent</li>
          </ng-container>
        </ul>
      </ng-template>
    </kmd-popover>
  </div>
</div>
