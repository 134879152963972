import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { GroupMembershipService } from "@services/group-membership/group-membership.service";
import {
  catchError,
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap
} from "rxjs";
import { KmdModalService, KmdPaginationComponent } from "gds-atom-components";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PagedAodGroupUser } from "@shared/models/paged-aod-group-user.model";
import {
  AodGroupUser,
  GroupUserRoleOptions,
  GroupUserRoleType,
  GroupUserSortOptions
} from "@shared/models/aod-group-user";
import { askOurDocsRoles } from "@environments/environment";
import { AlertService } from "@services/alert/alert.service";
import {
  AskOurDocsUserManagementAddUsersComponent
} from "./ask-our-docs-user-management-add-users/ask-our-docs-user-management-add-users.component";
import {
  AskOurDocsUserManagementRemoveUsersComponent
} from "@app/ask-our-docs/ask-our-docs-user-management-page/ask-our-docs-user-management-remove-users/ask-our-docs-user-management-remove-users.component";
import { OidcSecurityService } from "angular-auth-oidc-client";


@Component({
  selector: "app-ask-our-docs-user-management-page",
  templateUrl: "./ask-our-docs-user-management-page.component.html",
  styleUrls: ["./ask-our-docs-user-management-page.component.css"],
})
export class AskOurDocsUserManagementPageComponent implements OnInit {

  @ViewChild('pagination') pagination!: KmdPaginationComponent;
  @ViewChild('headCheckbox') headCheckbox!: ElementRef;
  @ViewChild('addUsersModal') addUsersModal?: AskOurDocsUserManagementAddUsersComponent;
  @ViewChild('removeUsersModal') removeUsersModal?: AskOurDocsUserManagementRemoveUsersComponent;

  groupId!: string;
  indexName!: string;
  allUsersOriginal$!: Observable<any>;
  initialAllUsers$: Observable<PagedAodGroupUser> = of();
  allUsers$: Observable<PagedAodGroupUser> = of();
  repoName: string = "";

  selectedUsers: Map<string, { email: string, role: string }> = new Map();
  repoAdmins: Map<string, { email: string, role: string }> = new Map();
  usersToBeAdded: any[] = [];
  usersInError: any[] = [];
  usersInSuccess: any[] = [];
  userEmails: string[] = [];

  readonly DEBOUNCE_TIME = 200;
  RESULTS_PER_PAGE = 20;

  protected readonly roleFilters = Object.values(GroupUserRoleOptions);
  protected readonly sortingFilters = Object.values(GroupUserSortOptions);
  protected readonly ALL_ROLES_FILTER = GroupUserRoleOptions.ALL_ROLES;

  private activeSortBy = GroupUserSortOptions.ROLE_ADMIN_FIRST;

  USER_ADDITION_SUCCESS_MESSAGE = "Users access granted successfully.";
  USER_ADDITION_ERROR_MESSAGE = "Request failed, please try again.";

  formGroup = new FormGroup({
    search: new FormControl('', { nonNullable: true }),
    sorting: new FormControl(this.activeSortBy, { nonNullable: true }),
    role: new FormControl(this.ALL_ROLES_FILTER, { nonNullable: true })
  });

  pageFormControl = new FormControl(0, { nonNullable: true })
  showRoles: boolean = false;
  userId: string = "";
  isUserAdminSelfSelected: boolean = false;
  addAdminError: boolean = false;

  constructor(
    private groupMemberService: GroupMembershipService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private cdRef: ChangeDetectorRef,
    public oidcSecurityService: OidcSecurityService,
    private kmdModalService: KmdModalService,
    private elementRef: ElementRef
  ) {
    this.route.params.subscribe(params => {
      this.groupId = params["groupId"];
      this.indexName = params["indexName"];
    });

    this.oidcSecurityService.userData$.subscribe((result) => {
      this.userId = result.userData.oid;
    });
  }

  ngOnInit(): void {
    this.repoName = this.getAskOurDocRepoName(this.indexName);
    this.allUsersOriginal$ = this.getAodGroupUsers(this.groupId);

    this.initializeAllUsersStream();

    const search$ = this.formGroup.controls.search.valueChanges.pipe(
      debounceTime(this.DEBOUNCE_TIME),
      switchMap(() => {
        return this.reloadAllUsersToFirstPage();
      })
    );

    const sort$ = this.formGroup.controls.sorting.valueChanges.pipe(
      switchMap(() => {
        return this.reloadAllUsersToFirstPage();
      })
    );

    const roles$ = this.formGroup.controls.role.valueChanges.pipe(
      switchMap(() => {
        return this.reloadAllUsersToFirstPage();
      })
    );

    const pages$ = this.pageFormControl.valueChanges.pipe(
      switchMap((partial) => {
        return this.pagedAodGroupUser(this.formGroup.controls.role.value, this.formGroup.controls.sorting.value,
          this.formGroup.controls.search.value, partial, this.RESULTS_PER_PAGE);
      })
    )

    this.allUsers$ = merge(this.initialAllUsers$, search$, roles$, sort$, pages$);
  }

  initializeAllUsersStream() {
    this.initialAllUsers$ = this.pagedAodGroupUser(this.formGroup.controls.role.value,
      this.formGroup.controls.sorting.value, '', 0, this.RESULTS_PER_PAGE);
  }

  reloadTable() {
    this.formGroup.reset({
      search: '',
      sorting: this.activeSortBy,
      role: this.ALL_ROLES_FILTER
    });
    this.showRoles = false;
    this.selectedUsers.clear();
    this.pageFormControl.setValue(0);
    this.resetHeadCheckbox();

    this.allUsersOriginal$ = this.getAodGroupUsers(this.groupId);
    this.initializeAllUsersStream();

    this.cdRef.detectChanges();
  }

  onPageChange(event: any) {
    this.RESULTS_PER_PAGE = event.resultsPerPage;
    this.pageFormControl.patchValue(event.currentPage - 1);
    this.resetHeadCheckbox();
  }

  onRoleDropdownChange(event: any) {
    this.formGroup.controls.role.patchValue(event.selectedOption);
  }

  get activeRoleFilter() {
    return this.formGroup.controls.role.value;
  }

  clearSearch() {
    this.formGroup.controls.search.patchValue('');
  }

  isSearchEmpty() {
    return this.formGroup.controls.search.value === '';
  }

  pagedAodGroupUser(role: string, sort: string, searchTerm: string, page: number, itemsPerPage: number): Observable<PagedAodGroupUser> {
    const groupUsers$ = this.getSortedAodGroupUsers(sort);

    return groupUsers$.pipe(
      map(users => {
        const filteredUsers = this.search(users, searchTerm)
          .filter(user => {
            switch (role) {
              case GroupUserRoleOptions.ONLY_ADMINS:
                return user.role === GroupUserRoleType[0];
              case GroupUserRoleOptions.ONLY_USERS:
                return user.role === GroupUserRoleType[1];
              default:
                return true;
            }
          });

        const pagedGroupUsers = filteredUsers.slice(itemsPerPage * page, itemsPerPage * page + itemsPerPage);

        return {
          content: pagedGroupUsers,
          totalPages: filteredUsers.length / itemsPerPage,
          totalElements: filteredUsers.length,
          size: itemsPerPage,
          number: page,
          last: itemsPerPage * page + itemsPerPage == filteredUsers.length,
          numberOfElements: pagedGroupUsers.length,
          first: page === 0,
          empty: filteredUsers.length === 0
        };
      }),
      catchError(() => {
        this.alertService.showError("We encountered an unexpected error. Please try again.");
        return EMPTY;
      })
    );
  }

  sortByEmail() {
    this.activeSortBy = (this.activeSortBy === GroupUserSortOptions.USER_EMAIL_ASC)
      ? GroupUserSortOptions.USER_EMAIL_DESC
      : GroupUserSortOptions.USER_EMAIL_ASC;

    this.formGroup.controls.sorting.patchValue(this.activeSortBy);
  }

  sortByRole() {
    if (this.activeRoleFilter !== GroupUserRoleOptions.ALL_ROLES) {
      return;
    }

    this.activeSortBy = (this.activeSortBy === GroupUserSortOptions.ROLE_ADMIN_FIRST)
      ? GroupUserSortOptions.ROLE_USER_FIRST
      : GroupUserSortOptions.ROLE_ADMIN_FIRST;

    this.formGroup.controls.sorting.patchValue(this.activeSortBy);
  }

  search(users: AodGroupUser[], searchTerm: string) {
    const searchTerms = searchTerm.toLowerCase().split(' ');

    return users.filter(user =>
      searchTerms.every(term => user.email.toLowerCase().includes(term))
    );
  }

  toggleCheckboxes(event: Event, isHeadCheckbox: boolean) {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;
    const checkboxes = isHeadCheckbox
      ? document.querySelectorAll('input#user-checkbox') as NodeListOf<HTMLInputElement>
      : [target];

    const actions: { [key: string]: (checkbox: HTMLInputElement, trElement?: HTMLElement) => void } = {
      'true': (checkbox, trElement) => {
        const userId = checkbox.value;
        const userEmail = (trElement?.querySelector('.body-user-email') as HTMLElement)?.innerText;
        const userRole = (trElement?.querySelector('.body-role') as HTMLElement)?.innerText;

        if (userEmail && userRole) this.selectedUsers.set(userId, { email: userEmail, role: userRole });
        checkbox.checked = true;
        trElement?.classList.add('tr-checked');
      },
      'false': (checkbox, trElement) => {
        const userId = checkbox.value;
        this.selectedUsers.delete(userId);
        checkbox.checked = false;
        trElement?.classList.remove('tr-checked');
      }
    };

    checkboxes.forEach(checkbox => {
      const trElement = isHeadCheckbox ? checkbox.closest('tr') : this.findParentTr(checkbox);
      actions[isChecked.toString()](checkbox, trElement ?? undefined);
    });

    if (isHeadCheckbox) {
      document.querySelectorAll('tbody tr').forEach(tr => {
        tr.classList.toggle('tr-checked', isChecked);
      });
    }
    else {
      const totalElements = document.querySelectorAll('tbody tr').length;
      if(this.selectedUsers.size === totalElements) {
        this.headCheckbox.nativeElement.checked = true;
      } else {
        this.headCheckbox.nativeElement.checked = false;
      }
    }
  }


  findParentTr(element: HTMLElement): HTMLElement | null {
    let parent = element.parentElement;

    while (parent) {
      if (parent.tagName === 'TR') {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  }

  resetHeadCheckbox() {
    if (!this.headCheckbox) return;
    this.headCheckbox.nativeElement.checked = false;
    this.cdRef.detectChanges();
  }

  openAddUsersModal() {
    this.addUsersModal?.openModal();
  }

  openRemoveUsersModal() {
    this.removeUsersModal?.openModal();
  }

  private getAodGroupUsers(groupId: string): Observable<any> {
    return forkJoin({
      adminsResponse: this.groupMemberService.getGroupOwners(groupId),
      usersResponse: this.groupMemberService.getGroupMembers(groupId),
    }).pipe(
      map(({ adminsResponse, usersResponse }) => ({
        admins: adminsResponse.value,
        users: usersResponse.value,
      })),
      map(({ admins, users }) => {
        const adminsMapped = admins
          .map((admin: any) => ({
            id: admin.id,
            email: this.parseEmail(admin),
            role: GroupUserRoleType[0]
          }))
          .sort((a: any, b: any) => a.email.localeCompare(b.email));

        const usersMapped = users
          .filter((user: any) => !adminsMapped.some((admin: any) => admin.id === user.id))
          .map((user: any) => ({
            id: user.id,
            email: this.parseEmail(user),
            role: GroupUserRoleType[1]
          }))
          .sort((a: any, b: any) => a.email.localeCompare(b.email));

        this.repoAdmins.clear();
        adminsMapped.forEach((admin: { id: string; email: any; role: any; }) => {
          this.repoAdmins.set(admin.id, { email: admin.email, role: admin.role });
        });
        return { adminsMapped, usersMapped };
      }),
      catchError(() => {
        this.alertService.showError("We encountered an unexpected error. Please try again.");
        return EMPTY;
      })
    );
  }

  private parseEmail(user: any) {
    if (user.mail?.trim().length > 0) {
      return user.mail;
    }

    if (user.userPrincipalName?.trim().length > 0) {
      return user.userPrincipalName;
    }

    return "N/A";
  }

  private getSortedAodGroupUsers(sort: string): Observable<AodGroupUser[]> {
    return this.allUsersOriginal$.pipe(
      map(({ adminsMapped, usersMapped }) => {
        switch (sort) {
          case GroupUserSortOptions.USER_EMAIL_ASC:
            return usersMapped.concat(adminsMapped).sort((a: any, b: any) => a.email.localeCompare(b.email))
          case GroupUserSortOptions.USER_EMAIL_DESC:
            return usersMapped.concat(adminsMapped).sort((a: any, b: any) => b.email.localeCompare(a.email))
          case GroupUserSortOptions.ROLE_ADMIN_FIRST:
            return adminsMapped.concat(usersMapped);
          case GroupUserSortOptions.ROLE_USER_FIRST:
            return usersMapped.concat(adminsMapped);
          default:
            return adminsMapped.concat(usersMapped);
        }
      })
    );
  }

  private reloadAllUsersToFirstPage() {
    this.resetHeadCheckbox();

    if (this.pagination) {
      this.pagination.selectPage(1);
    } else {
      this.pageFormControl.patchValue(0);
    }

    return EMPTY;
  }

  private getAskOurDocRepoName(indexName: string) {
    return indexName.replace(askOurDocsRoles.IndexPrefix, "").replaceAll("_", " ");
  }

  clearSelection() {
    this.selectedUsers.clear();
    this.resetHeadCheckbox();
    document.querySelectorAll('tbody tr').forEach(tr => {
      tr.classList.toggle('tr-checked', false);
    });
  }

  getSelectionCounterLabel(selectedCount: number): string {
    return selectedCount > 1 ? `${ selectedCount } users selected` : '1 user selected';
  }

  addUsers(users: any) {
    this.usersToBeAdded = users.ids;

    if (users.role.includes("Admin")) {
      this.addOwnersToGroup(this.usersToBeAdded);
    }
    if (!this.addAdminError) {
      this.addMembersToGroup(this.usersToBeAdded);
    } else {
      this.addAdminError = false;
      this.removeOwnersFromGroup(this.usersToBeAdded);
    }

    this.reloadAllUsersToFirstPage();
  }

  removeOwnersFromGroup(users: any) {
    users.map((user: string) => {
      return this.groupMemberService.removeOwnerFromGroup(this.groupId, user).pipe(
        catchError(() => {
          return of(null);
        })
      );
    })
  }

  addMembersToGroup(userIDs: any) {
    const members = userIDs.map((user: string) => {
      return this.groupMemberService.addMemberToGroup(this.groupId, user).pipe(
        map(() => {
          return user
        }),
        catchError((error) => {
          if (error.message.includes("references already exist")) {
            return user;
          }

          return of(null);
        })
      );
    });

    this.handleUserAdditionResponses(members);
  }

  addOwnersToGroup(userIDs: any) {
    if (userIDs.length > 5) {
      this.addAdminError = true;
      this.alertService.showError("Cannot add more than 5 admins at a time.");
      return;
    }

    const owners = userIDs.map((user: string) => {
      return this.groupMemberService.addOwnerToGroup(this.groupId, user).pipe(
        map(() => user),
        catchError((error) => {
          if (error.message.includes("references already exist")) {
            return user;
          }
          this.addAdminError = true;

          return of(null);
        })
      );
    });

    this.handleUserAdditionResponses(owners);
  }

  handleUserAdditionResponses(users: any) {
    forkJoin(users).subscribe((results: any) => {
      results.forEach((result: string | null) => {
        if (result) {
          this.usersInSuccess.push(result);
        } else {
          this.usersInError.push(result);
        }
      });

      this.reloadTable();

      if (this.usersInSuccess.length === this.usersToBeAdded.length) {
        this.alertService.showSuccess(this.USER_ADDITION_SUCCESS_MESSAGE);
      }

      if (this.usersInError.length > 0 && this.usersInError.length < this.usersToBeAdded.length && !this.addAdminError) {
        this.alertService.showError(`Failed to add ${ this.usersInError.length } users. Please review and try again.`);
      }

      if (this.usersInError.length === this.usersToBeAdded.length || this.addAdminError) {
        this.alertService.showError(this.USER_ADDITION_ERROR_MESSAGE);
      }
    });
  }

  toggleAssignRoles() {
    this.showRoles = !this.showRoles;
  }

  grantAdminAccess(): void {
    let failedRequests = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const usersToAdminsObservables = Array.from(this.selectedUsers.entries()).map(([userId, { email, role }]) => {
      if (role === 'Admin') {
        return of(null);
      } else {
        return this.groupMemberService.addOwnerToGroup(this.groupId, userId).pipe(
          catchError(() => {
            failedRequests++;
            return of(null);
          })
        );
      }
    });

    from(usersToAdminsObservables).pipe(
      concatMap(observable => observable),
      catchError(() => {
        this.toggleAssignRoles();
        this.alertService.showError('Error occurred while assigning role, please try again later.');
        return of(null);
      })
    ).subscribe({
      complete: () => {
        if (failedRequests === 0) {
          this.toggleAssignRoles();
          this.reloadTable();
          this.alertService.showSuccess('Users role access changed successfully.');
        } else {
          this.toggleAssignRoles();
          this.alertService.showError('Error occurred while assigning role, please try again later.');
        }
        this.ngOnInit();
      }
    });
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    const isInsideComponent = this.elementRef.nativeElement.contains(targetElement);
    const isInsideAssignAodRoles = !!targetElement.closest('#assign-role');

    if (this.showRoles && this.selectedUsers.size === 0 || isInsideComponent && !isInsideAssignAodRoles) {
      this.showRoles = false;
    }
  }

  areAllAdminsSelected(): boolean {
    const adminIds = Array.from(this.repoAdmins.keys());
    const userIds = Array.from(this.selectedUsers.keys());

    return adminIds.every(id => userIds.includes(id));
  }

  revokeAdminAccess() {
    if (this.areAllAdminsSelected()) {
      this.toggleAssignRoles();
      this.alertService.showError('Unable to remove all admins. Please maintain at least one admin in the group.');
      return;
    }
    if (this.isAdminSelfSelected()) {
      this.kmdModalService.open('revokeSelfAdminModal');
      this.toggleAssignRoles();
      return;
    }
    this.adminsToUsers();
  }

  pushAdminToEnd() {
    const userAdmin = this.selectedUsers.get(this.userId);
    if (userAdmin) {
      this.selectedUsers.delete(this.userId);
      this.selectedUsers.set(this.userId, userAdmin);
    }
  }

  isAdminSelfSelected() {
    const selectedAdmins = new Map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Array.from(this.selectedUsers.entries()).filter(([userId, { role }]) => role === 'Admin')
    );
    this.isUserAdminSelfSelected = selectedAdmins.has(this.userId);
    return this.isUserAdminSelfSelected;

  }

  adminsToUsers() {
    from(Array.from(this.selectedUsers.entries())).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([userId, { role }]) => role === 'Admin'),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap(([userId, { email, role }]) =>
        this.groupMemberService.removeOwnerFromGroup(this.groupId, userId)
          .pipe(
            catchError(() => of(null))
          )
      )
    ).subscribe({
      error: () => {
        this.toggleAssignRoles();
        this.alertService.showError('We encountered an unexpected error. Please try again.');
      },
      complete: () => {
        this.toggleAssignRoles();
        this.reloadTable();
        this.alertService.showSuccess(`User role access changed successfully.`);
      }
    });
  }

  closeModal() {
    this.kmdModalService.close('revokeSelfAdminModal');
  }
}
