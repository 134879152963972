export const ChipTypeConstant = {
  DEFAULT: {
    kind: "default",
    icon: "none"
  },
  ERROR: {
    kind: "error",
    icon: "warning-red"
  },
  NOTICE: {
    kind: "notice",
    icon: "warning-blue"
  },
  EXAMPLE_PROMPT: {
    kind: "example-prompt",
    icon: "none"
  },
  DOCUMENT: {
    kind: "document",
    icon: "document"
  },
  ANALYZE: {
    kind: "analyze",
    icon: "analyze"
  },
  WARNING: {
    kind: "warning",
    icon: "warning"
  },
  "BETA": {
    kind: "beta",
    icon: "none"
  }
};
