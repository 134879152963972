<div class="saved-prompts-container">
  <div class="saved-prompts-top-menu-container">
    <div id="back-to-chat-wrapper" (click)="showChat($event)">
      <img src="../assets/icons/arrows/arrowLeft.svg">
      <p>Back to chat</p>
    </div>
    <div id="saved-prompts-title-wrapper">
      <h1>Saved Prompts</h1>
    </div>
  </div>

  <core-tabs-container position="columns">
    <core-tabs variant="default" class="prompts-tabs">
      <core-tabs-item label="Private Prompts" variant="default" selected targetid="private-prompts"></core-tabs-item>
      <core-tabs-item label="Public Prompts" variant="default" selected="false"
        targetid="public-prompts"></core-tabs-item>
    </core-tabs>

    <core-tabs-content>
      <div id="private-prompts">
        <app-paged-prompts (promptSelectEvent)="promptSelected($event)"></app-paged-prompts>
      </div>
      <div id="public-prompts">
        <app-public-paged-prompts (promptSelectEvent)="promptSelected($event)"></app-public-paged-prompts>
      </div>
    </core-tabs-content>
  </core-tabs-container>
</div>
