<div class="image-creation content">
  <div id="toolbar-wrapper" class="gray">
    <div class="toolbar-content-image max-width">
      <div class="toolbar-labels-wrapper">
        <h1>Image generator</h1>
        <p>Take advantage of create original content, check our <a href="/guidelines">Guidelines</a>
          to take advantage of Image generator.</p>
      </div>
      <button kmdFeaturedButton id="new-chat" (click)="moveToImageCreation()">
        <img class="ic ic-new-chat" src="assets/icons/plus/plus-16px-mono.svg">
        <label>New Chat</label>
      </button>
    </div>
  </div>
  <div class="tabs max-width">
    <div id="chat" [hidden]="isChatViewInactive()">
      <div id="chat-list">
        <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
        <div *ngIf="chat?.id == undefined; else messagesChat" class="image-creation-title-section">
          <h2 class="main-title">Let's create great images!</h2>
          <p>
            For better results with your image requests, Gene.AI will always refine your prompts.<br>We recommend
            using a single chat for creating and refining a single image.
          </p>
          <app-example-prompt (exampleSelectEvent)="setExampleCurrentPrompt($event)"
                              [banner]="bannerEnabled" [showHeaderMessage]="promptExampleHeader"
                              [promptExamplesType]="promptExamplesType">
          </app-example-prompt>
        </div>
        <ng-template #messagesChat>
          <div class="table" #table [scrollTop]="table.scrollHeight">
            <app-chat [username]="(userData$ | async)?.name || 'NA'" [chatType]="'image-creation'"
                      [(lastSystemMessage)]="lastSystemMessage"
                      [chatId]="selectedChatId"
                      (responseSelectEvent)="handleResponseSelected($event)" (promptSavedEvent)="handlePromptSaved()" #appChat/>
          </div>
        </ng-template>
      </div>

      <app-prompt-input #promptInputComponent [username]="(userData$ | async)?.name || 'NA'"
                        [chatId]="selectedChatId" [promptExample]="undefined"
                        (promptSentEvent)="onSubmit($event)" [promptSettings]="promptSettings"
                        (openDrawerEvent)="openDrawer()"/>
    </div>
  </div>

  <!-- modal elements -->
  <app-drawer appClickOutsideDrawer (appClickOutsideDrawer)="closeDrawer()" [position]="'right'">
    <span title>Prompt Settings</span>
    <prompt-settings [promptSettings]="promptSettings" [chatType]="'image-creation'"/>
  </app-drawer>

  <kmd-alerts kmd-success [(show)]="showPromptSavedAlert" [floating]="true" [alertText]="promptSavedText"></kmd-alerts>

  <kmd-alerts id="generalAlert" kmd-error [(show)]="showGeneralAlert" [alertText]="alertText"
              [dismissible]="true"></kmd-alerts>

  <kmd-alerts id="invalidPromptAlert" kmd-warning [(show)]="showInvalidPrompt"
              [alertText]="invalidPromptText"></kmd-alerts>

  <kmd-alerts id="tooManyRequestsAlert" kmd-warning [(show)]="showTooManyRequests"
              [alertText]="tooManyRequestsText"></kmd-alerts>

  <kmd-modal class="loading-modal" [id]="'loadingResponseModal'" modalSize="small" [closeOnClickingOutside]="false">
    <div>Generating response</div>
    <kmd-loading-wheel></kmd-loading-wheel>
  </kmd-modal>
</div>
