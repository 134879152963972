import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { NewsBanner } from "@shared/models/news-banner/news-banner.model";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NewsBannerService {

    constructor(
        @Inject('BASE_API_URL') private baseUrl: string,
        private http: HttpClient
    ) { };

    get(): Observable<Array<NewsBanner>> {
        return this.http.get<Array<NewsBanner>>(`${this.baseUrl}/v1/news-banner`);
    };

    optOut(body: NewsBanner): Observable<any> {
        return this.http.post(`${this.baseUrl}/v1/news-banner/opt-out`, body, { headers: { 'Content-type': 'application/json' } });
    };
};
