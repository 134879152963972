import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PromptService } from "@services/prompt/prompt.service";
import { lastValueFrom, Observable } from "rxjs";
import { Prompt } from "@shared/models/prompt.model";
import { PagedPrompts } from "@shared/models/pagedprompts.model";
import { PromptSearch } from "@shared/models/prompt-search.model";
import { KmdModalService, KmdPaginationComponent } from "gds-atom-components";
import { ModalEditPromptComponent } from "../prompt/modaleditprompt/modal-edit-prompt.component";
import { Model } from "@shared/models/prompt-settings.model";

@Component({
  selector: 'app-paged-prompts',
  templateUrl: './paged-prompts.component.html',
  styleUrls: ['./paged-prompts.component.css', '../shared/styles/prompt-table.css'],
  encapsulation: ViewEncapsulation.None
})
export class PagedPromptsComponent implements AfterViewInit {
  @ViewChild('pagination', { static: false }) pagination!: KmdPaginationComponent;
  @ViewChild('modalEditPromptComponent') modalEditPromptComponent?: ModalEditPromptComponent;
  @Output() promptSelectEvent = new EventEmitter<Prompt>();
  @Output() promptEditEvent = new EventEmitter<Prompt>();
  @Input() categoriesType?: string;

  promptTableHeaders: string[] = ["Title", "Model", "Web search", "Status", "Actions"];
  selectedCategory!: string;
  categories: string[] = [];
  isLoading = false;
  pagedPrompts: PagedPrompts = {
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: undefined,
    size: 0,
    sort: undefined,
    totalPages: 0,
    totalElements: 0,
    content: []
  }
  selectedPrompt?: Prompt;
  alertText: string = '';
  showSuccessAlert: boolean = false;
  showErrorAlert: boolean = false;

  constructor(private promptService: PromptService, private kmdModalService: KmdModalService, private cd: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.loadCategories().then(() => {
      if (this.categories.length > 0) {
        let promptSearch = new PromptSearch(0, this.pagination.resultsPerPage, this.selectedCategory)
        return this.updatePrompts(promptSearch);
      } else {
        return [];
      }
    });
    this.cd.detectChanges();
  }

  async loadCategories(): Promise<void> {
    try {
      const value = await lastValueFrom(this.promptService.promptCategories(this.categoriesType));
      this.categories = value.sort();
      if (!this.categories.includes(this.selectedCategory)) {
        this.selectedCategory = this.categories[0];
      }
    } catch {
      this.showErrorAlert = true;
      this.alertText = "Unable to load categories";
    }
  }

  getUserFriendlyModelName(model: Model) {
    if (model === "gpt-4-o") {
      return "GPT-4o";
    } else {
      return "";
    }
  }

  openModal(prompt: Prompt, id: string) {
    this.kmdModalService.open(id);
    this.selectedPrompt = prompt;
  }

  closeModal(id: string) {
    this.kmdModalService.close(id);
  }

  onSelect(prompt: Prompt) {
    this.promptSelectEvent.emit(prompt);
  }

  onDelete(prompt: Prompt) {
    this.promptService.delete(prompt.id!).subscribe({
      next: () => {
        let promptIndex = this.pagedPrompts.content.findIndex((p) => p.id === prompt.id);
        this.pagedPrompts.content.splice(promptIndex, 1);
        this.loadCategories().then(() => {
          this.reloadPromptsAfterDelete();
          this.alertText = 'Prompt deleted';
          this.showSuccessAlert = true;
          this.kmdModalService.close('delete-prompt-modal');
        });
      },
      error: () => {
        this.alertText = 'The prompt could not be deleted. There was an issue contacting the server.';
        this.showErrorAlert = true;
      }
    });
  }

  onEdit(prompt: Prompt) {
    /**
     * Since the prompt edit will modify the content of the prompt
     * we're creating a copy of the original prompt to prevent from
     * alter the original prompt attributes if the edit form doesn't complete
     */
    this.selectedPrompt = Object.assign({}, prompt);
    this.modalEditPromptComponent?.open();
  }

  promptSaved(prompt: Prompt) {
    this.modalEditPromptComponent?.close();
    this.alertText = 'Prompt updated';
    this.showSuccessAlert = true;
    let promptIndex = this.pagedPrompts.content.findIndex((p) => p.id === prompt.id);
    this.pagedPrompts.content[promptIndex] = prompt;
    this.loadCategories().then(() => {
      let promptSearch = new PromptSearch(0, this.pagination.resultsPerPage, this.selectedCategory)
      this.updatePrompts(promptSearch);
    });
  }

  onPageChange(event: any) {
    let promptSearch = new PromptSearch(event.currentPage - 1, event.resultsPerPage, this.selectedCategory)
    this.updatePrompts(promptSearch);
  }

  onCategoryChange(event: any) {
    let category = event.selectedOption
    let promptSearch = new PromptSearch(0, this.pagination.resultsPerPage, category)
    this.updatePrompts(promptSearch);
  }

  resetSelectedPrompt() {
    this.selectedPrompt = undefined;
  }

  onShareValuesChange(values: { alertType: string; alertText: string }) {
    this.alertText = values.alertText;
    if (values.alertType == "success") {
      this.showSuccessAlert = true;
      let promptSearch = new PromptSearch(0, this.pagination.resultsPerPage, this.selectedCategory);
      this.updatePrompts(promptSearch);
    } else if (values.alertType == "alert") {
      this.showErrorAlert = true;
    }
  }

  getPromptStatus(prompt: Prompt) {
    return prompt.isPublic ? "Public" : "Private";
  }

  updatePrompts(promptSearch: PromptSearch) {
    this.isLoading = true;
    this.getPageablePrompts(promptSearch).subscribe({
      next: value => {
        this.pagedPrompts = value;
      },
      complete: () => {
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  getPromptStatusStyling(prompt: Prompt) {
    return prompt.isPublic ? "public-chip" : "private-chip";
  }

  shareActionDisabled(prompt: Prompt) {
    if (prompt.isPublic) {
      return "action-disabled";
    }
    return "clickable";
  }

  private reloadPromptsAfterDelete() {
    if (this.pagedPrompts.content.length === 0) {
      let promptSearch = new PromptSearch(0, this.pagination.resultsPerPage, this.selectedCategory)
      this.updatePrompts(promptSearch);
    }
  }

  private getPageablePrompts(promptSearch: PromptSearch): Observable<PagedPrompts> {
    if(this.categoriesType)
      return this.promptService.getPageablePrompts(promptSearch, "ask_gene");
    else
      return this.promptService.getPageablePrompts(promptSearch);
  }
}
