<div class="prompt-settings-component">
  <div id="chat-ask-image" class="body" *ngIf="isImageChatType(); else chatAsk">
    <div class="image-size-container">
      <span class="text">Resolution</span>
      <div id="image-size-chip" class="chips">
        <kmd-chips class="chip-tone" *ngFor="let size of imageSize" chipsText={{size.title}} chipsType="choice"
          [selected]="promptSettings.imageSize === size.value" [disabled]="promptSettings.imageSize === size.value"
          (click)="onImageSizeSelect(size.value)" iconClass="kmd-icon-alert-mono"></kmd-chips>
      </div>
      <div class="helper-text">{{ sizeHelperText }}</div>
    </div>
    <div class="image-style-container">
      <span class="text">Image style</span>
      <div id="image-style-chip" class="chips">
        <kmd-chips class="chip-tone" *ngFor="let style of imageStyle" chipsText={{style.title}} chipsType="choice"
          [selected]="promptSettings.imageStyle === style.value" [disabled]="promptSettings.imageStyle === style.value"
          (click)="onImageStyleSelect(style.value)" iconClass="kmd-icon-alert-mono"></kmd-chips>
      </div>
      <div class="helper-text">{{ styleHelperText }}</div>
    </div>
    <div class="image-quality-container">
      <span class="text">Image Quality</span>
      <div id="image-quality-chip" class="chips">
        <kmd-chips class="chip-tone" *ngFor="let quality of imageQuality" chipsText={{quality.title}} chipsType="choice"
          [selected]="promptSettings.imageQuality === quality.value"
          [disabled]="promptSettings.imageQuality === quality.value" (click)="onImageQualitySelect(quality.value)"
          iconClass="kmd-icon-alert-mono"></kmd-chips>
      </div>
      <div class="helper-text">{{ qualityHelperText }}</div>
    </div>
  </div>
  <ng-template #chatAsk>
    <div id="chat-ask" class="body">
      <div class="model-settings-container">
        <div id="model-title-contianer">
          <span class="text">Model</span>
        </div>
        <div class="chips">
          <ng-container *ngFor="let model of modelList">
            <kmd-chips id="chip-{{model.model}}" *ngIf="isSupportedModel(model)" class="chip-model"
              chipsText={{model.title}} chipsType="choice" [selected]="promptSettings.model === model.model"
              [disabled]="promptSettings.model === model.model" (click)="onModelSelect(model.model)"
              iconClass="kmd-icon-alert-mono" />
          </ng-container>
        </div>
        <div class="helper-text">{{ modelHelperText }}</div>
      </div>
      <div class="creativity-container">
        <span class="text">Creativity</span>
        <div class="chips">
          <div class="icon icon-left">🤖</div>
          <kmd-chips class="chip creativity-chip" *ngFor="let creativity of creativityList" chipsText="{{creativity}}"
            chipsType="choice" [selected]="promptSettings.creativity === creativity"
            [disabled]="promptSettings.creativity === creativity" (click)="onCreativitySelect(creativity)"
            iconClass="kmd-icon-alert-mono"></kmd-chips>
          <div class="icon icon-right">🧑‍🎨</div>
        </div>
        <div class="low-high">
          <span class="low-high-left">Low</span>
          <span class="low-high-right">High</span>
        </div>
      </div>
      <div class="tone-container">
        <span class="text">Tone</span>
        <div class="chips">
          <kmd-chips class="chip-tone" *ngFor="let tone of toneList" chipsText={{tone.toLowerCase()}} chipsType="choice"
            [selected]="promptSettings.tone === tone" [disabled]="promptSettings.tone === tone"
            (click)="onToneSelect(tone)" iconClass="kmd-icon-alert-mono"></kmd-chips>
        </div>
      </div>
      <div class="toggle-container" *ngIf="includeWebSearch">
        <span class="text">Web Search</span>
        <div class="toggle-content">
          <kmd-toggle [(ngModel)]="this.promptSettings.webSearch"></kmd-toggle>
          <span class="span-websearch-status">{{ this.promptSettings.webSearch ? "ON" : "OFF" }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
