import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserData } from '@shared/models/user-data.model';
import { map, Observable, Observer, Subject, Subscription } from 'rxjs';
import { Author, Message } from '@shared/models/message.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { PromptInputComponent } from '@shared/components/prompt-input/prompt-input.component';
import { KmdModalService, KmdPopoverComponent } from 'gds-atom-components';
import { PromptSettings } from '@shared/models/prompt-settings.model';
import { Chat } from '@shared/models/chat.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatComponent } from '@app/chat/chat.component';
import { DrawerComponent } from '@app/drawer/drawer.component';
import { AgentsService } from '@services/agents/agents.service';
import { Agent } from '@app/shared/models/agent';
import { ActivatedRoute, Router } from "@angular/router";
import { ChatService } from "@services/chat/chat.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { AgentChatHistory } from "@services/chathistory/agent-chat-history";
import { featureFlags } from '@app/environments/environment';
import { AlertService } from "@services/alert/alert.service";
import { DatePipe, Location } from '@angular/common';
import { takeUntil } from "rxjs/operators";
import { UuidService } from "@services/uuid.service";
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";


@Component({
  selector: 'app-agent-chat',
  templateUrl: './agent-chat.component.html',
  styleUrls: ['./agent-chat.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: 'ChatHistoryService', useClass: AgentChatHistory},
    {provide: 'chatHistoryType', useValue: 'AGENT'}
  ],
  animations: [
    trigger('slideInOut', [
      state('in', style({width: '260px'})),
      state('out', style({width: '0px'})),
      transition('in <=> out', animate('300ms ease-in-out'))
    ])
  ]
})
export class AgentChatComponent implements OnInit, OnDestroy {
  @ViewChild('promptInputComponent') promptInputComponent!: PromptInputComponent;
  @ViewChild('appChat') chatComponent?: ChatComponent;
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent;
  @ViewChild("popOver") popOver!: KmdPopoverComponent;

  publicAgentDeletedMessage: string = "This public agent has been deleted along with all related chats. Sorry for the inconvenience";
  privateAgentDeletedMessage: string = "This agent has been deleted along with all related chats. Sorry for the inconvenience";
  chatHistoryState: string = 'out';
  userData$: Observable<UserData>;
  agent?: Agent;
  selectedChatId!: string;
  isLoading: boolean = false;

  generatingResponse = false;
  streamingResponse = false;

  lastUserMessage?: Message;
  lastSystemMessage?: Message;

  agentChatDecorator?: AgentChatDecorator;
  promptSettings: PromptSettings = new PromptSettings(3, "PROFESSIONAL", false, 'gpt-4-o');
  generalAlertText =  "We encountered an unexpected error. Please try again.";
  showInvalidPrompt = false;
  invalidPromptText = 'Sorry, your prompt couldn\'t be processed. Please rephrase or shorten your prompt.<br>Make sure to review and comply with the Gene.AI Use Guidelines';
  includeWebSearch = false;
  posY: number = 0;
  posX: number = 0;
  alertVisible = false;
  showRatingError = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  streamingSubscription$: Subscription | undefined;
  protected readonly faEllipsis = faEllipsis;
  protected readonly featureFlags = featureFlags;

  private agentRatingObserver: Partial<Observer<Agent>> = {
    next: (agent: Agent) => {
      this.agent = agent;
      this.cdRef.detectChanges();
    },
    error: () => this.showRatingError = true
  };

  constructor(
    private agentsService: AgentsService,
    private chatService: ChatService,
    private chatHistoryUpdateService: ChatHistoryUpdateService,
    public oidcSecurityService: OidcSecurityService,
    private kmdModalService: KmdModalService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private uuidService: UuidService
  ) {
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    );
  }
  get isSubmitDisabled() {
    return this.generatingResponse || this.streamingResponse;
  }

  get showRating(): boolean {
    return !!this.agent?.rating && this.agent.rating.likes > 0;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const agentId = params['agentId'];
      if (agentId) {
        this.agentsService.agent(agentId).subscribe(agent => {
            this.agent = agent;
          },
          error => {
            if (error.status == 404) {
              this.alertService.showError(this.publicAgentDeletedMessage)
              this.returnToPublicAgents();
            } else {
              this.handleErrorNavigation(error)
            }
          }
        );
      }
      this.selectedChatId = params['chatId'];
      if (this.selectedChatId) {
        this.chatService.getChat(this.selectedChatId).subscribe(chat => {
            this.agentChatDecorator = new AgentChatDecorator(chat, agentId);
          },
          error => this.handleErrorNavigation(error));
        this.chatService.getChatMessages(this.selectedChatId).subscribe(messages => {
            this.cdRef.detectChanges();
            this.chatComponent?.setMessages(messages);
            if (!featureFlags.uxRevamp && this.chatHistoryState == 'out') {
              this.toggleChatHistory()
            }
          },
          error => this.handleErrorNavigation(error));
      }
    });

    if (this.route.queryParams) {
      this.route.queryParams.subscribe(params => {
        const agentAdded = params['agentAdded'];
        if (agentAdded !== undefined && agentAdded !== null) {
          this.alertVisible = true;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // For stop the streaming when navigate to another page
    if (featureFlags.agentStreaming && this.streamingSubscription$ != undefined) {
      this.streamingSubscription$?.unsubscribe();
    }
  }

  returnToPublicAgents() {
    if (featureFlags.paginatedAgents)
      this.router.navigate(['/agents'], {queryParams: {tab: 'public-agents-paginated'}, replaceUrl: true});
    else
      this.router.navigate(['/agents'], {queryParams: {tab: 'public-agents'}, replaceUrl: true});
  }

  onSubmit(inputPrompt: string) {
    if (this.featureFlags.agentStreaming) {
      this.sendStreamingPrompt(inputPrompt);
    } else {
      this.sendPrompt(inputPrompt);
    }
  }

  sendPrompt(inputPrompt: string) {
    if (this.isLoading) return;

    this.cdRef.detectChanges()

    let promptValue = inputPrompt;
    this.promptInputComponent.setPrompt('');
    let author = new Author("USER");

    this.kmdModalService.open('loadingResponseModal');
    this.isLoading = true;

    let message = new Message(promptValue, author, new Date(), this.promptSettings, this.lastSystemMessage?.id);

    if (this.agentChatDecorator?.id == undefined) {
      this.agentsService.prompt(this.agent!, message).subscribe({
        next: (agentChat) => {
          this.agentChatDecorator = new AgentChatDecorator(agentChat.chat, agentChat.agentId);
          this.router.navigate(['/agents/' + this.agent!.id + '/chat/' + this.agentChatDecorator?.id])
        }, error: (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.handlePromptError(err);
          this.promptInputComponent.setPrompt(promptValue);
        }
      });
    } else {
      this.agentsService.promptToChat(this.agent!, this.agentChatDecorator, message).subscribe({
        next: (prompt) => {
          message.id = prompt.id
          message.parentId = prompt.parentId

          this.chatComponent?.addMessage(message);
          this.chatComponent?.addMessage(prompt.answer!);

          this.kmdModalService.close('loadingResponseModal');
          this.isLoading = false;
        }, error: (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.handlePromptError(err);
          this.promptInputComponent.setPrompt(promptValue);
        }
      });
    }

  }

  sendStreamingPrompt(inputPrompt: string) {
    if (inputPrompt.trim() == "") return;

    let promptValue = inputPrompt;
    let message = new Message(promptValue, new Author("USER"), new Date().toISOString(), Object.assign({}, this.promptSettings), this.lastSystemMessage?.id);
    message.id = this.uuidService.random();

    this.generatingResponse = true;

    if (this.agentChatDecorator?.id == undefined) {
      this.selectedChatId = 'unknown';
      this.cdRef.detectChanges();
      this.chatComponent?.addMessage(message);

      let isChatLoaded = false;
      this.streamingSubscription$ = this.agentsService.promptV2(this.agent!, message)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: value => {
            this.generatingResponse = false;
            this.streamingResponse = true;

            if (!isChatLoaded) {
              this.agentChatDecorator = new AgentChatDecorator(value.agentChat.chat, value.agentChat.agentId)
              this.selectedChatId = this.agentChatDecorator.id!;
              this.location.replaceState('/agents/' + this.agent!.id + '/chat/' + this.agentChatDecorator?.id);
              isChatLoaded = true;
            }
            this.promptInputComponent.resizeTextarea();
            this.chatComponent?.addMessage(value.prompt.answer!);
          },
          error: (error: HttpErrorResponse) => {
            this.finalizeStreamingResponse();
            this.errorStreamingHandler(error);
          },
          complete: () => {
            this.finalizeStreamingResponse();
          }
        });
    } else {
      this.chatComponent?.addMessage(message);
      this.streamingSubscription$ = this.agentsService.promptToChatV2(this.agent!, this.agentChatDecorator, message)
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: value => {
            this.generatingResponse = false;
            this.streamingResponse = true;
            this.promptInputComponent.resizeTextarea();
            this.chatComponent?.addMessage(value.answer!);
          },
          error: (error: HttpErrorResponse) => {
            this.finalizeStreamingResponse();
            this.errorStreamingHandler(error);
          },
          complete: () => {
            this.chatHistoryUpdateService.notifyChatUpdated(this.agentChatDecorator?.id);
            this.finalizeStreamingResponse();
          }
        });
    }
  }

  finalizeStreamingResponse() {
    this.streamingResponse = false;
    this.generatingResponse = false;
    this.cdRef.detectChanges();
  }

  errorStreamingHandler(error: HttpErrorResponse) {
    if (error.status == 404) {
      this.alertService.showError(this.agent?.isPublic ? this.publicAgentDeletedMessage : this.privateAgentDeletedMessage);
    } else if (error.status == 500) {
      this.alertService.showError(this.generalAlertText);
    } else {
      this.showInvalidPrompt = error.status == 400;
    }
  }

  navigateToAgentChat() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([`/agents/${this.agent?.id}/chat`]));
  }

  handleResponseSelected(responseText: string) {
    this.promptInputComponent.setAppendPrompt(responseText);
  }

  handlePromptError(error: HttpErrorResponse) {
    this.kmdModalService.close('loadingResponseModal');
    if (error.status == 404) {
      this.alertService.showError(this.agent?.isPublic ? this.publicAgentDeletedMessage : this.privateAgentDeletedMessage);
    } else if (error.status == 500) {
      this.alertService.showError(this.generalAlertText);
    } else {
      this.showInvalidPrompt = error.status == 400;
    }
  }

  selectedChat(chat: Chat) {
    let internalChat = chat as AgentChatDecorator
    this.router.navigate(["/agents/" + internalChat.agentID + "/chat/" + internalChat.id])
  }

  deletedChat(chat: Chat) {
    let internalChat = chat as AgentChatDecorator
    this.router.navigate(["/agents/" + internalChat.agentID + "/chat"])
  }

  toggleChatHistory() {
    this.chatHistoryState = this.isChatVisible() ? 'out' : 'in';
  }

  toggleChatHistoryFromMenu() {
    this.toggleChatHistory();
    this.popOver.hide();
  }

  closeChatHistory() {
    this.chatHistoryState = 'out';
  }

  toggleByDirective() {
    if (this.isChatVisible()) {
      this.toggleChatHistory();
    }
  }

  showEllipsisMenu(event: MouseEvent) {
    event.preventDefault();
    if (event.currentTarget instanceof HTMLElement) {
      this.posX = (event.currentTarget.offsetLeft + event.currentTarget.clientWidth / 2) - 175;
      this.posY = (event.currentTarget.offsetTop + event.currentTarget.clientHeight / 2) + 8;
      this.popOver.show(event, event.currentTarget);
    }
  }

  isChatVisible() {
    return this.chatHistoryState === 'in';
  }

  hasConversationStarters() {
    return this.agent?.conversationStarters?.length! > 0 && this.agent?.conversationStarters![0] !== "";
  }

  handleConversationStarter(conversationStarterText: string) {
    this.promptInputComponent.setPrompt(conversationStarterText);
  }

  backToAgents() {
    this.router.navigate(['/agents'], {queryParams: {tab: this.selectedTab()}});
  }

  selectedTab(): string {
    if (featureFlags.paginatedAgents) {
      return this.agent?.isPublic ? 'public-agents-paginated' : 'my-agents-paginated';
    }

    return this.agent?.isPublic ? 'public-agents' : 'my-agents';
  }

  onRatedEvent(liked: any) {
    if (liked) {
      this.likeAgent(this.agent?.id!);
    } else {
      this.unlikeAgent(this.agent?.id!);
    }
  }

  unlikeAgent(agentId: string): void {
    this.agentsService.unlikeAgent(agentId).subscribe(this.agentRatingObserver);
  };

  likeAgent(agentId: string): void {
    this.agentsService.likeAgent(agentId).subscribe(this.agentRatingObserver);
  };

  displayRating() {
    return this.isPublicAgent() && this.showRating;
  }

  isPublicAgent() {
    return this.agent?.isPublic;
  }

  getFormattedDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MMM/yyyy')!;
  }

  displayStopGenerateButton() {
    return this.streamingResponse;
  }

  stopGenerateResponse() {
    this.promptInputComponent.resizeTextarea();
    this.streamingSubscription$?.unsubscribe();
    this.destroy$.next(true);
  }

  private handleErrorNavigation(error: HttpErrorResponse) {
    let errors = [400, 401, 403, 404, 500, 503]
    if (errors.includes(error.status)) {
      this.router.navigate(['/error'], {state: {status: error.status}, replaceUrl: true})
    } else {
      this.router.navigate(['/error'], {state: {status: 500}, replaceUrl: true})
    }
  }
}

export class AgentChatDecorator extends Chat {
  agentID: string

  constructor(chat: Chat, agentID: string) {
    super(chat.title, chat.created);
    this.id = chat.id;
    this.gptEnabled = chat.gptEnabled;
    this.promptSettings = chat.promptSettings;
    this.agentID = agentID;
  }
}
