import { Model } from "./model.model";
import { Gpt4model } from "./gpt4model.model";
import { DallE3model } from "./dalle3model.model";
import { Gpt4omodel } from "./gpt4omodel.model";

export class ModelFactory {

  create(model: string): Model {
    switch (model) {
      case "gpt-4":
        return new Gpt4model();
      case "gpt-4-o":
        return new Gpt4omodel();
      case "dall-e-3":
        return new DallE3model();
      default:
        throw Error(`unsupported model type ${ model }`)
    }
  }
}
