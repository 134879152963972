import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { FileManagerService } from "@app/core/services/file-manager/file-manager.service";
import { FileProcessingStatus } from "@shared/constants/file-manager/file-processing-status";
import { slideUpDownAnimation } from "@shared/animations/slide-up-down.animation";
import { FileManagerDoc } from "@app/shared/models/file-manager/file-manager-doc.model";
import { FileUploadOptions } from "@app/shared/constants/file-manager/file-upload-options";

@Component({
    selector: 'app-file-manager-selected-files',
    templateUrl: './file-manager-selected-files.component.html',
    styleUrls: ['./file-manager-selected-files.component.css'],
    animations: [slideUpDownAnimation]
})
export class FileManagerSelectedFilesComponent {

    @Input() files: FileManagerDoc[] = [];
    @Input() slideDirection: 'up-to-down' | 'down-to-up' = 'up-to-down';

    @Output() onAddMoreFiles = new EventEmitter<void>();
    @Output() onFileUnselected = new EventEmitter<FileManagerDoc>();

    isMenuOpen = false;

    constructor(
        private elementRef: ElementRef,
        private fileManagerService: FileManagerService
    ) { }

    getAnimationState() {
        if (this.slideDirection === 'up-to-down') {
            return this.isMenuOpen ? 'openUpToDown' : 'closedUpToDown';
        } else {
            return this.isMenuOpen ? 'openDownToUp' : 'closedDownToUp';
        }
    };

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    };

    addMoreFiles(): void {
        this.toggleMenu();
        this.onAddMoreFiles.emit();
    };

    unselectFile(file: FileManagerDoc): void {
        this.onFileUnselected.emit(file);
    };

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement: any) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);

        const excludedElements = [
            '.file-management-controls',
            'i.delete'
        ]

        const isExcludedElement = excludedElements.some((selector) => {
            return targetElement.closest(selector) !== null;
        });

        if (!clickedInside && !isExcludedElement) {
            this.isMenuOpen = false;
        }
    };

    getProcessingStatus(id: string): string {
        const { processed, error } = this.fileManagerService.fileStatusIds;

        if (processed.includes(id)) {
          return (this.isProcessedFileSpreadsheet(id)) ? FileProcessingStatus.PROCESSED_SPREADSHEET : FileProcessingStatus.PROCESSED;
        }

        if (error.includes(id)) {
            return FileProcessingStatus.PROCESSING_ERROR;
        }

        return FileProcessingStatus.PROCESSING;
    };

    isProcessedFileSpreadsheet(id: string): boolean {
      const fileExtension = this.files.find(file => file.id === id)?.name?.split('.').pop();
        return(FileUploadOptions['SPREADSHEETS'].extensions.includes(`.${fileExtension}`)) ? true : false;
    }
}
