import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { FileManagerService } from "@app/core/services/file-manager/file-manager.service";
import { FileUploadOptions, FileUploadType } from "@app/shared/constants/file-manager/file-upload-options";
import { FileManagerUpload } from "@app/shared/models/file-manager/file-manager-upload.model";
import { FileManagerDoc } from "@shared/models/file-manager/file-manager-doc.model";
import { featureFlags } from '@app/environments/environment';
import { Subject, Subscription, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { WindowRef } from "@app/core/services/window/window-ref.service";

@Component({
  selector: 'app-file-manager-my-docs',
  templateUrl: './file-manager-my-docs.component.html',
  styleUrls: ['./file-manager-my-docs.component.css']
})
export class FileManagerMyDocsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fileType: string = 'text';
  @Input() disableSelection: boolean = false;
  @Input() searchTerm: string = '';
  @ViewChild('filesTable') filesTable?: ElementRef;

  myFiles: FileManagerDoc[] = [];
  allFiles: FileManagerDoc[] = [];
  fixedFiles: FileManagerDoc[] = [];
  sortBy: string = 'processed';
  sortDirection: string = 'DESC';

  isFirstLoad: boolean = true;
  isFetching: boolean = false;
  isLoading: boolean = false;
  isLazyLoading: boolean = false;
  isUserScrollingDown: boolean = false;
  protected readonly featureFlags = featureFlags;
  readonly excludeFileStatus = ['ERROR', 'UPLOAD_REQUESTED'];

  private offset: number = 0;
  firstTimeLoadingFiles = 1;
  private fetchFilesSubject = new Subject<{ sortBy: string, sortDirection: string }>();
  preSelectedFilesSubscription: Subscription | undefined;
  fixedFilesSubscription: Subscription | undefined;
  selectedFilesSubscription: Subscription | undefined;

  constructor(
    private fileManagerService: FileManagerService
  ) {
  };

  ngOnInit(): void {
    this.setFixedFiles();
    this.initializeFileFetchSubscription();
    this.subscribeToSelectedFiles();
  };

  ngOnChanges({ fileType, searchTerm }: SimpleChanges): void {
    if (fileType && !fileType.firstChange) {
      this.refreshTable();
      this.fileManagerService.clearPreSelectedFiles();
      this.scrollToTop();
    }
    if (searchTerm && !searchTerm.firstChange) {
      this.refreshTable();
    }
  };

  ngOnDestroy(): void {
    this.preSelectedFilesSubscription?.unsubscribe();
    this.fixedFilesSubscription?.unsubscribe();
    this.selectedFilesSubscription?.unsubscribe();
  }

  resetComponentFilesState() {
    this.firstTimeLoadingFiles = 1;
    this.setFixedFiles();
    this.moveSelectedFilesToPreselected();
  }

  private initializeFileFetchSubscription(): void {
    this.fetchFilesSubject.pipe(
      switchMap(({ sortBy, sortDirection }) => {
        this.isFetching = true;
        this.isLoading = !this.isUserScrollingDown;
        this.isLazyLoading = this.isUserScrollingDown;

        const currentOffset = this.offset;
        const selectedFileType = this.getSelectedFileType(this.fileType);

        return this.fileManagerService.getLazyFiles$(selectedFileType, this.offset, sortBy, sortDirection, this.searchTerm)
          .pipe(
            tap({
              next: (files) => {
                if (files.length === 0) {
                  this.offset = currentOffset;
                } else {
                  this.addNewFiles(files);
                  this.restorePreSelectedFiles();
                  this.moveSelectedFilesToPreselected();
                  this.subscribeToPreSelectedFiles();
                  if (files.length === 20) {
                    this.offset += 20;
                  }
                }
              },
              error: () => {
                this.isLoading = false;
                this.isLazyLoading = false;
                this.offset = currentOffset;
                this.isFetching = false;
              },
              complete: () => {
                this.isLoading = false;
                this.isLazyLoading = false;
                this.isUserScrollingDown = false;
                this.isFetching = false;
              }
            })
          );
      })
    ).subscribe();
  }

  initFileFetch(): void {
    if (this.isFirstLoad) {
      this.fetchFiles();
      this.isFirstLoad = false;
    }
  }

  refreshTable(): void {
    this.offset = 0;
    this.myFiles = [];
    this.fetchFiles();
  };

  private scrollToTop(): void {
    this.filesTable?.nativeElement.scrollTo(0, 0);
  };

  fetchFiles(sortBy: string = 'processed', sortDirection: string = 'DESC'): void {
    this.fetchFilesSubject.next({ sortBy, sortDirection });
  }

  fetchAllFiles(sortBy: string = 'processed', sortDirection: string = 'DESC'): void {
    if (this.isFetching) {
      return;
    }

    const excludeStatus = this.excludeFileStatus.join(',');
    const selectedFileType = this.getSelectedFileType(this.fileType);

    this.isFetching = true;
    this.isLoading = !this.isUserScrollingDown;
    this.isLazyLoading = this.isUserScrollingDown;

    this.fileManagerService.getFiles$(selectedFileType, sortBy, sortDirection, excludeStatus)
      .subscribe({
        next: (files) => {
          this.myFiles = files;
        },
        error: () => {
          this.isLoading = false;
          this.isLazyLoading = false;
          this.isFetching = false;
        },
        complete: () => {
          this.isLoading = false;
          this.isLazyLoading = false;
          this.isUserScrollingDown = false;
          this.isFetching = false;
          this.restorePreSelectedFiles();
        }
      });
  }

  onScroll({ target }: any): void {
    const { scrollTop, clientHeight, scrollHeight, dataset } = target;
    const isScrollingDown = scrollTop > (parseFloat(dataset.prevScrollTop) || 0);
    dataset.prevScrollTop = scrollTop;

    if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 1) {
      this.isUserScrollingDown = true;
      this.fetchFiles(this.sortBy, this.sortDirection);
    }
  }

  onRowCheckboxSelection(file: FileManagerDoc): void {
    if (this.isDisabledRow(file)) {
      return;
    }

    file.isSelected = !file.isSelected;
    if (file.isSelected) {
      this.fileManagerService.addPreSelectedFile(file);
    } else {
      this.fileManagerService.removePreSelectedFile(file);
    }
  };

  onAllRowsCheckboxSelection(): void {
    if (this.selectAllCheckBoxState === 'active') {
      this.clearSelectedFiles();
      return;
    }

    this.fetchAllFiles();
    this.myFiles
      .filter(file => !file.isSelectDisabled)
      .filter(file => !file.isSelected)
      .forEach(file => this.onRowCheckboxSelection(file));
  };

  get selectAllCheckBoxState(): any {
    if (this.myFiles.length === 0 || this.selectedFiles.length === 0) {
      return 'inactive';
    }

    const nonErrorFiles = this.myFiles.filter(file => !file.isSelectDisabled);
    if (this.selectedFiles.length === nonErrorFiles.length) {
      return 'active';
    }
    return 'indeterminate';
  }

  get hasFiles(): boolean {
    return this.myFiles.length > 0;
  };

  clearSelectedFiles(): void {
    this.myFiles.forEach(file => file.isSelected = false);
    this.fileManagerService.clearPreSelectedFiles();
  };

  sortFiles(sortBy: string): void {
    this.offset = 0;
    this.myFiles = [];
    this.clearSelectedFiles();
    this.toggleSortDirection(sortBy);
    this.sortBy = sortBy;
    this.fetchFiles(this.sortBy, this.sortDirection);
    this.scrollToTop();
  }

  isDisabledRow(file: FileManagerDoc): boolean {
    if (this.disableSelection) {
      return true;
    }

    if (file.isSelectDisabled) {
      return true;
    }

    if (this.fileType === 'spreadsheets') {
      return this.selectedFiles.length > 0 && !file.isSelected;
    }

    const fixFiles= this.fileManagerService.fixedFiles$.value;
    const isListedAsFixedFile = fixFiles.some(f => f.name === file.name);

    if (this.fileType === 'image' && !isListedAsFixedFile) {
      return (this.selectedFiles.length == 5) && !file.isSelected;
    }
    return false;
  };

  isSelectAllDisabled(): boolean {
    return this.disableSelection || this.fileType === 'spreadsheets' || this.fileType === 'image';
  };

  get selectedFiles(): FileManagerDoc[] {
    return this.myFiles.filter(file => file.isSelected);
  };

  getFileStatus(file: FileManagerDoc): { label: string, type: string } {
    if (file.isUnsupported) {
      return {
        label: 'Not Supported',
        type: 'notice'
      };
    }

    if (this.fileType === 'spreadsheets'|| this.fileType == 'image') {
      return {
        label: '',
        type: 'default'
      };
    }

    if (this.fileManagerService.fileStatusIds.processed.includes(file.id!)) {
      return {
        label: '',
        type: 'default'
      };
    }

    if (this.fileManagerService.fileStatusIds.error.includes(file.id!)) {
      return {
        label: 'Processing error',
        type: 'error'
      };
    }

    return {
      label: 'Processing',
      type: 'notice'
    };
  };

  addFile(upload: FileManagerUpload): void {
    const file = this.fileManagerService.convertUploadToFileManagerDoc(upload);
    this.addOrUpdateFile(file, 'start');
  };

  getDisplayName(file: FileManagerDoc): string {
    if (this.pageWidth <= 768) {
      return this.fileManagerService.getParseFileNameToDisplayName(file.name, 38);
    }
    return file.displayName;
  }

  private addNewFiles(files: FileManagerDoc[]): void {
    const now = new Date();
    const startOfDayNow = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

    files.forEach(file => {
      const fileDate = new Date(file.date);
      const startOfDayFileDate = new Date(fileDate.getFullYear(), fileDate.getMonth(), fileDate.getDate()).getTime();

      const differenceInDays = Math.floor((startOfDayNow - startOfDayFileDate) / (1000 * 60 * 60 * 24));

      if (this.fileManagerService.fileStatusIds.error.includes(file.id!) && differenceInDays > 0) {
        return;
      }

      this.addOrUpdateFile(file, 'end');
    });
  }


  private addOrUpdateFile(file: FileManagerDoc, position: 'start' | 'end'): void {
    const existingFile = this.myFiles.find(f => f.id === file.id);

    if (existingFile) {
      Object.assign(existingFile, file);
      return;
    }

    if (position === 'start') {
      this.myFiles.unshift(file)
    } else {
      this.myFiles.push(file);
    }
  };

  private subscribeToPreSelectedFiles(): void {
    this.preSelectedFilesSubscription = this.fileManagerService.preSelectedFiles$.subscribe(preSelectedFiles => {
      preSelectedFiles = this.fileManagerService.preSelectedFiles$.value;
      this.allFiles = this.fixedFiles.concat(preSelectedFiles);
      this.syncAllFiles();
      this.restoreSelectedFiles();
    });
  }

  private subscribeToSelectedFiles(): void {
    this.selectedFilesSubscription = this.fileManagerService.selectedFiles$.subscribe(selectedFiles => {
      this.clearSelectedFiles();
      selectedFiles.forEach(file => {
        this.fileManagerService.addPreSelectedFile(file);
      });
      this.syncAllFiles();
      this.restoreSelectedFiles();
    });
  }

  private restoreSelectedFiles(): void {
    this.myFiles.forEach(file => {
      file.isSelected = this.allFiles.some(f => f.name === file.name);
    });
  }

  private restorePreSelectedFiles(): void {
    this.myFiles.forEach(file => {
      file.isSelected = this.fileManagerService.preSelectedFiles$.value.some(f => f.name === file.name);
    });
  }

  private syncAllFiles() {
    this.allFiles.forEach(updatedFile => {
      const existingFile = this.myFiles.find(file => file.id === updatedFile.id);
      if (existingFile) {
        Object.assign(existingFile, updatedFile);
      }
    });
  }

  private moveSelectedFilesToPreselected() {
    if (this.firstTimeLoadingFiles == 1) {
      const selectedFiles = this.fileManagerService.selectedFiles$.value;
      selectedFiles.forEach(file => {
        this.fileManagerService.addPreSelectedFile(file);
      });
      this.firstTimeLoadingFiles++;
    }
  }

  private setFixedFiles(): void {
    this.fixedFilesSubscription = this.fileManagerService.fixedFiles$.subscribe((fixedFiles) => {
      this.fixedFiles = fixedFiles;
      this.clearSelectedFiles();
    });
  }

  private toggleSortDirection(sortBy: string) {
    if (this.sortBy === sortBy) {
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortDirection = 'ASC';
    }
  }

  private getSelectedFileType(fileType: string): FileUploadType {
    let selectedFileType: FileUploadType;
    switch (fileType) {
      case FileUploadOptions['SPREADSHEETS'].fileType:
        selectedFileType = FileUploadOptions['SPREADSHEETS'];
        break;
      case FileUploadOptions['TEXT'].fileType:
        selectedFileType = FileUploadOptions['TEXT'];
        break;
      case FileUploadOptions['IMAGE'].fileType:
        selectedFileType = FileUploadOptions['IMAGE'];
        break;
      default:
        selectedFileType = FileUploadOptions['TEXT'];
        break;
    }
    return selectedFileType;
  }

  get pageWidth(): number {
    return WindowRef.innerWidth;
  }
}
