import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FileManagerUpload } from "@shared/models/file-manager/file-manager-upload.model";
import { FileManagerService } from "@app/core/services/file-manager/file-manager.service";
import { FileUploadStatus } from "@shared/constants/file-manager/file-upload-status";
import { FileProcessingStatus } from "@shared/constants/file-manager/file-processing-status";
import { FileUploadOptions, FileUploadType } from "@app/shared/constants/file-manager/file-upload-options";
import { getReadableFileSize } from "@app/core/services/file-manager/utils/file-utils";

@Component({
  selector: 'app-file-manager-upload',
  templateUrl: './file-manager-upload.component.html',
  styleUrls: ['./file-manager-upload.component.css']
})
export class FileManagerUploadComponent {
  @Output() onCancel = new EventEmitter<void>();
  @Output() onUploadingInProgress = new EventEmitter<boolean>();
  @Output() onFileProcessed = new EventEmitter<FileManagerUpload>();
  @Input() uploadType: FileUploadType = FileUploadOptions['TEXT'];

  @ViewChild('fileInput') fileInput!: ElementRef;

  readonly uploadedFiles: FileManagerUpload[] = [];
  readonly uploadStatus = FileUploadStatus;
  readonly processingStatus = FileProcessingStatus;
  readonly fileUploadOptions = FileUploadOptions;

  constructor(
    private fileManagerService: FileManagerService,
  ) {
  }

  disableUploader() {
    if(this.uploadType === this.fileUploadOptions['SPREADSHEETS']&&
      this.fileManagerService.preSelectedFiles$ &&
      this.fileManagerService.preSelectedFiles$.value.length === 1){
      return true;
    }
    if(this.uploadType === this.fileUploadOptions['IMAGE']&&
      this.fileManagerService.preSelectedFiles$ &&
      this.fileManagerService.preSelectedFiles$.value.length === 5){
      return true;
    }
    return false;
  }

  openFileDialog(): void {
    this.fileInput.nativeElement.click();
  };

  onFileChange(event: any): void {
    const files: FileList = event.target.files;
    this.handleFileUpload(files);
  };

  async handleFileUpload(files: FileList): Promise<void> {
    const validation = this.fileManagerService.validateFileUploadBatch(files, this.uploadType.fileType);

    if (!validation.isValid()) {
      if (validation.maxFileSizeExceeded) {
        this.uploadedFiles.push({
          file: files.item(0)!,
          status: this.uploadStatus.FILE_SIZE_EXCEEDED,
        });
      }
    }

    const filesToUpload: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const isMaxFilesReached = i > this.uploadType.maxFiles - 1;

      if (isMaxFilesReached) {
        break;
      }

      const supportedFileTypes = this.fileManagerService.getFilesByType(this.uploadType.fileType);
      if (!supportedFileTypes.includes(files.item(i)!.type)) {
        continue;
      }

      const file = files.item(i)!;
      if (!this.fileManagerService.validFileName(file.name)) {
        continue;
      }

      if (await this.fileManagerService.isFilePasswordProtected(file)) {
        this.uploadedFiles.push({
          file: file,
          status: this.uploadStatus.PASSWORD_PROTECTED,
        });
        continue;
      }

      const isAlreadyInUploadList = this.uploadedFiles.some(
        (uploadedFile) => uploadedFile.file.name === file.name
      );

      if (isAlreadyInUploadList) continue;

      filesToUpload.push(file);
    }

    if (filesToUpload.length > 0) {
      this.fileManagerService.uploadFiles(filesToUpload, this.uploadType).forEach(
        (fileUpload) => {
          const upload = this.fileManagerService.getFileUploadStatus(fileUpload);
          this.uploadedFiles.push(upload);
        }
      );
    }
  };

  get uploadDetails(): { count: number, size: string } {
    const count = this.uploadedFiles.length;
    const size = this.uploadedFiles.reduce((acc, { file }) => acc + file.size, 0);
    return { count, size: `${this.getReadableFileSize(size)}` };
  };

  getMobileDisplayName(file: File): string {
    return this.fileManagerService.getParseFileNameToDisplayName(file.name, 25);
  }

  pauseFileUpload(data: FileManagerUpload): void {
    data.status = FileUploadStatus.PAUSED;
    data.cancel!();
  };

  retryFileUpload(data: FileManagerUpload): void {
    data.status = FileUploadStatus.IN_PROGRESS;
    data.progress = 0;
    data.fetch!();
  };

  getFileStatus(data: FileManagerUpload): string {
    const isUploadInProgress = data.status === FileUploadStatus.IN_PROGRESS;
    if (isUploadInProgress) {
      return this.processingStatus.UPLOAD_IN_PROGRESS;
    }

    const isUploadError = data.status;

    if (isUploadError === this.uploadStatus.ERROR) {
      return this.processingStatus.UPLOAD_ERROR;
    }

    if (isUploadError === this.uploadStatus.FILE_SIZE_EXCEEDED) {
      return this.processingStatus.FILE_SIZE_EXCEEDED;
    }

    if (isUploadError === this.uploadStatus.PASSWORD_PROTECTED) {

      return this.uploadStatus.PASSWORD_PROTECTED;
    }

    const {
      error: errorIds,
      processed: processedIds,
    } = this.fileManagerService.fileStatusIds;

    const isProcessingError = errorIds.includes(data.id!);
    if (isProcessingError) {
      this.emitAndRemoveFile(data);
      return this.processingStatus.PROCESSING_ERROR;
    }

    const isProcessed = processedIds.includes(data.id!);
    if (isProcessed) {
      this.emitAndRemoveFile(data);
      return this.processingStatus.PROCESSED;
    }

    this.emitAndRemoveFile(data);
    return this.processingStatus.PROCESSING;
  };

  emitAndRemoveFile(data: FileManagerUpload): void {
    this.onFileProcessed.emit(data);
    this.removeFile(data);
  }

  removeFile(data: FileManagerUpload): void {
    const index = this.uploadedFiles.findIndex((uploadedFile) => uploadedFile.file === data.file);

    if (data.status === this.uploadStatus.FILE_SIZE_EXCEEDED || data.status === this.uploadStatus.PASSWORD_PROTECTED) {
      this.onUploadingInProgress.emit(false);
    }

    if (index >= 0) {
      this.uploadedFiles.splice(index, 1);
      data.cancel!();
    }
  };

  get wrapperClasses(): { [key: string]: boolean } {
    return {
      'has-files': this.uploadedFiles.length > 0,
      'single-file': this.uploadedFiles.length === 1
    }
  };

  isRetryButtonDisplayed(data: FileManagerUpload): boolean {
    if (data.status === this.uploadStatus.ERROR) {
      return true;
    }

    if (data.status === this.uploadStatus.PAUSED) {
      return true;
    }

    if (data.status === this.uploadStatus.FILE_SIZE_EXCEEDED) {
      return false;
    }

    return this.getFileStatus(data) === this.processingStatus.PROCESSING_ERROR;
  };

  isUploadProgressDisplayed(data: FileManagerUpload): boolean {
    if (data.status === this.uploadStatus.COMPLETED) {
      this.onUploadingInProgress.emit(false);
      return false;
    }

    const inProgress = data.status !== this.uploadStatus.ERROR;
    this.onUploadingInProgress.emit(inProgress);
    return inProgress;
  };

  getReadableFileSize(size: number): string {
    return getReadableFileSize(size);
  }

  isStatusInProgress(status: string | undefined): boolean {
    return status === this.uploadStatus.IN_PROGRESS;
  }

  isStatusErrorFileSizeExceeded(status: string): boolean {
    return status === this.uploadStatus.FILE_SIZE_EXCEEDED;
  }

  isStatusErrorPasswordProtected(status: string): boolean {
    return status === this.uploadStatus.PASSWORD_PROTECTED;
  }

  getFileStatusClass(status: string): string {
    if (this.isStatusErrorFileSizeExceeded(status)) {
      return 'file-size-exceeded-status-icon';
    }
    if (this.isStatusErrorPasswordProtected(status)) {
      return 'password-protected-status-icon';
    }
    return 'processed-file-status-icon';
  }

}
