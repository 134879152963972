<div [ngClass]="getExamplePromptClass()">
  <ng-container *featureFlag="'uxRevamp'">
    <img id="gene-ai-logo" src="../../../assets/images/GeneAI-Logo.svg" alt="" height="271" [ngClass]="{'hide-icon': bannerEnabled}">
  </ng-container>
  <div *ngIf="showHeaderMessage" class="header-wrapper">
    <h2 class="greeting">Hello<span id="username">{{ userName }}</span> how can I assist you today?</h2>
    <p class="title">{{ titleText }}</p>
    <p class="disclaimer">
      Inaccuracies may occur in Gene.AI, make sure to cross-verify critical information.
      Please <a [routerLink]="['/guidelines']">consult our guidelines</a>
      for additional information.
    </p>
  </div>
  <div class="example-wrapper">
    <ng-container *ngFor="let example of examplePrompts">
      <div class="example">
        <ng-container *ngIf="featureFlags.uxRevamp; else noUxRevampChips">
          <app-chip [text]="example.title" [closeable]="false"
                    [type]="ChipTypeConstant.EXAMPLE_PROMPT"
                    (click)="exampleSelected(example)"/>
        </ng-container>

        <ng-template #noUxRevampChips>
          <kmd-chips class="category-badge" chipsType="choice" [selected]="selectedChip === example"
                     (clicked)="exampleSelected(example)" chipsText={{example.title}}></kmd-chips>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
