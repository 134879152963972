<div id="agent-example-container">
  <div id="example-title-container">
    <span id="example-title">
      Agent structure
    </span>
    <core-badge id="agent-example-badge" variant="standard"
                size="regular" color="blue" label="Example"/>
    <div class="close-button-container">
      <i id="example-close-button" class="kmd-icon-close-mono kmd-close-black-icon" (click)="closeExample()"></i>
    </div>
  </div>
  <div class="example-subtitle">
    <span>
      Name:
    </span>
    <p>
      {{ agent.name }}
    </p>
  </div>
  <div class="example-subtitle">
    <span>
      Description:
    </span>
    <p>
      {{ agent.description }}
    </p>
  </div>
  <div class="example-subtitle">
    <span>
      Conversation Starters:
    </span>
    <p>
      {{ agent.conversationStarters }}
    </p>
  </div>
  <div id="example-insts-container" class="example-subtitle">
    <span>
      Instructions:
    </span>
    <p class="example-insts" *ngFor="let instruction of trimmedInstructions()">
      {{ instruction }}
    </p>
  </div>
  <div class="example-subtitle">
    <span>
      Select a Category:
    </span>
    <p>
      {{ agent.category }}
    </p>
  </div>
</div>

