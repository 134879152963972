<app-chat-wrapper (onPromptSubmit)="onPromptSubmit($event)">
  <div class="examples-scrollable-wrapper" [ngClass]="{'padding-bottom': !bannerEnabled}">
    <div class="example-prompt-container">
      <app-example-prompt (exampleSelectEvent)="setPrompt($event)" [banner]="true"
                          [showHeaderMessage]="true" [promptExamplesType]="'ASK_GENE'"
                          [userName]="(userName$ | async)!" [bannerEnabled]="bannerEnabled"/>
    </div>
  </div>
  <app-banner-modal-new *ngIf="bannerEnabled" (toggleBannerModal)="bannerEnabled = $event" class="banner-modal"
                        [headerTitle]="bannerModalTitle" [banner]="bannerEnabled">
    <div>Click the paperclip icon below to upload and query spreadsheets, text, and PDF files with ease.</div>
    <span>Want tips? Watch how to </span>
    <a target='_blank' class="a-announced-feature" [href]="askMyDocsLink">Ask My Docs</a>
    <span> or </span>
    <a target="_blank" class="a-announced-feature" [href]="askMyCSVLink">Ask My Spreadsheet</a>
    <span>.</span>
  </app-banner-modal-new>
</app-chat-wrapper>
