import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { askOurDataRole } from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AskOurDataRoleGuard implements CanActivate {

  constructor(
    private oidcIdentityService: OidcSecurityService,
    private router: Router,
  ) {
  }

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAskOurDataSpecificRepoRole().pipe(map((hasRole) => {
      if (hasRole){
        return true;
      }else{
        return this.router.createUrlTree(['/error']);
      }
    }));
  }

  hasAskOurDataRoles() {
    return this.oidcIdentityService.userData$.pipe(
      map(userData => {
        let roles = userData?.userData?.['roles'];
        return roles?.some((role: string[]) => role.includes(askOurDataRole.prefix)) || false;
      })
    );
  }

  hasAskOurDataSpecificRepoRole(): Observable<boolean> {
    return this.oidcIdentityService.userData$.pipe(
      map(userData => {
        let roles = userData?.userData?.['roles'];
        return roles?.some((role: string[]) => role.includes(askOurDataRole.prefix)) || false;
      })
    );
  }

}

