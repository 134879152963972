<body>
<ng-container *ngIf="!featureFlags.maintenanceMode; else maintenanceTemplate">
  <ng-container *ngIf="showHeaderFooter">
    <div *featureFlag="'uxRevamp'; else oldHeaderWrapper" [ngClass]="{'header-wrapper ux-revamp-header' : isMobileScreen()}">
      <ng-container *ngIf="isMobileScreen()">
        <div class="left">
          <div [id]="navMenuToggleId" *ngIf="isAuthenticated"
               class="header-menu-icon trigger-toggle-chat-history">
            <img src="../assets/icons/hamburger/hamburger-24px-mono.svg">
          </div>
          <div class="header-logo">
            <img src="assets/images/gene-ai-header.svg" height="24" id="headerLogoId">
          </div>
        </div>
        <div class="right user-menu-wrapper" *ngIf="isAuthenticated">
          <div id="user-initials-menu-icon" (click)="openContextMenu($event)">
            <app-circle-with-initials [user]="'User'"
                                      [username]="(userData$ | async)?.name || 'NA'"/>
          </div>
          <app-user-context-menu/>
        </div>
      </ng-container>
      <div id="floating-user-menu" *ngIf="!isMobileScreen()">
        <div *ngIf="isAuthenticated">
          <div id="user-initials-menu-icon" (click)="openContextMenu($event)">
            <app-circle-with-initials [user]="'User'"
                                      [username]="(userData$ | async)?.name || 'NA'"/>
          </div>
          <app-user-context-menu/>
        </div>
      </div>
    </div>
    <ng-template #oldHeaderWrapper>
      <div class="header-wrapper">
        <div *ngIf="isAuthenticated" (click)="onMenuToggle()" class="header-menu-icon trigger-toggle-chat-history">
          <img src="../assets/icons/hamburger/hamburger-24px-mono.svg">
        </div>
        <div class="header-logo">
          <img src="assets/images/thermo-fisher-scientific.svg" height="24" id="headerLogoId">
        </div>
        <div class="header-title">
          <span id="headerTitleId">Ask Gene.AI</span>
        </div>
        <div class="header-icon">
          <img id="headerIconId" src="assets/images/ask-gene-logo.png" alt="ask gene logo" height="24">
        </div>
        <div class="header-sign-out" *ngIf="isAuthenticated" [routerLink]="['/sign-out']">
          <fa-icon [icon]="faSignOut"></fa-icon>
          <span>Sign Out</span>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <app-alert></app-alert>

  <div class="content-wrapper" [ngClass]="featureFlags.uxRevamp ? 'ux-revamp' : 'no-ux-revamp'">
    <ng-container *ngIf="isAuthenticated">
      <navbar-new *featureFlag="'uxRevamp'; else navMenu" [navMenuToggleId]="navMenuToggleId"></navbar-new>
    </ng-container>
    <ng-template #navMenu>
      <nav-menu appToggleNavMenu (ToggleChatHistoryEvent)="toggleByDirective($event)" *ngIf="isAuthenticated"
                #navMenuComponent></nav-menu>
    </ng-template>
    <div class="right-container">
      <router-outlet></router-outlet>
    </div>
  </div>

  <ng-container *ngIf="showHeaderFooter">
    <div *ngIf="!featureFlags.uxRevamp" class="footer-wrapper">
      <div class="footer-item">
        <span>
          © Copyright 2023-2024 Thermo Fisher Scientific Inc. All rights reserved.
        </span>
      </div>
      <div class="footer-links-wrapper">
        <div *ngIf="isAuthenticated" class="footer-item">
          <a id="tc-anchor" [routerLink]="[tcUrl]">
            <img src="../assets/icons/clipboard%20check/clipboardcheck-16px-bold.svg" class="footer-link-icon">
            <span class="tc-link-text">Gene.AI Guidelines</span>
          </a>
        </div>
        <div *ngIf="isAuthenticated" class="footer-item">
          <a id="feedback-anchor" target="_blank" href="{{feedbackUrl}}">
            <img src="../assets/icons/thumbs%20up/thumbsup-16px-bold.svg" class="footer-link-icon">
            <span class="feedback-link-text">Submit your feedback</span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #maintenanceTemplate>
  <app-maintenance></app-maintenance>
</ng-template>
</body>
