<div class="terms-and-conditions">
  <div class="shadow-container">
    <div class="header">
      <span class="title">Gene.AI Guidelines</span>
    </div>
    <div class="center">
      <div class="right">
        <div id="termsAndConditionsTextId" class="text" [innerHTML]="text"></div>
      </div>
    </div>
    <div class="bottom" *ngIf="!this.status">
      <button id="btnAcceptId" class="btn-accept" kmdFeaturedButton (click)="onClickAccept()">I
        Accept </button>
    </div>
  </div>
</div>
