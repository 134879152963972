import { Component, HostListener, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AgentFormComponent } from "@app/agents/agent-form/agent-form.component";
import { Agent } from "@app/shared/models/agent";
import { KmdModalService } from "gds-atom-components";
import { AgentsService } from "@services/agents/agents.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-add-to-my-agents',
  templateUrl: './add-to-my-agents.component.html',
  styleUrls: ['./add-to-my-agents.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddToMyAgentsComponent {
  @ViewChild('form') form!: AgentFormComponent;

  agent: Agent;
  success: boolean = false;
  error: boolean = false;
  errorText: string = "";
  modalId = 'return-confirmation-modal';
  validForm: boolean = true;
  showExampleButton = true
  showExampleAgent = false;
  showAddToMyAgentForm = true;
  windowWidth = window.innerWidth;

  constructor(
    private activatedRoute: ActivatedRoute,
    private kmdModalService: KmdModalService,
    private agentsService: AgentsService,
    private router: Router
  ) {
    this.agent = this.activatedRoute.snapshot.data['agent'];
    delete this.agent.id;
  };

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = (event.target as Window).innerWidth;
    this.showAddToMyAgentForm = !(this.windowWidth <= 1200 && this.showExampleAgent);
  }

  submitForm(agent: Agent) {
    this.agentsService.save(agent).subscribe({
      next: (addedAgent: Agent) => {
        this.success = true;
        this.agent = addedAgent;
        this.router.navigate([`/agents/${addedAgent.id}/chat`], {queryParams: {agentAdded: true}});
      },
      error: (err: HttpErrorResponse) => {
        this.error = true;
        this.errorText = this.errorMessage(err);
        this.markFirstInputAsInvalid();
      }
    })
  }

  promptReturnConfirmation() {
    this.kmdModalService.open(this.modalId);
  }

  closeReturnConfirmationModal() {
    this.kmdModalService.close(this.modalId)
  }

  returnToMyAgents() {
    this.kmdModalService.close(this.modalId);
    this.router.navigate(['/agents'], {queryParams: {tab: 'public-agents'}});
  }

  formStatusChanged(valid: boolean) {
    this.validForm = valid;
  }

  toggleAgentExample() {
    this.showExampleButton = !this.showExampleButton;
    this.showExampleAgent = !this.showExampleAgent;
    this.showAddToMyAgentForm = !(this.windowWidth <= 1200 && this.showExampleAgent);
  }

  private errorMessage(err: HttpErrorResponse) {
    if (err.status === 409) {
      return "You already have an agent with this name. Please use a unique name for your agent."
    } else {
      return "An error occurred while saving the agent. Please try again later."
    }
  }

  private markFirstInputAsInvalid(): void {
    if (this.form && this.form.agentForm) {
      const formControls = this.form.agentForm.controls;

      if (formControls) {
        const controlKeys = Object.keys(formControls);

        if (controlKeys.length > 0) {
          const firstControlName = controlKeys[1];
          const firstControl = this.form.agentForm.get(firstControlName);

          if (firstControl) {
            const firstControlElement = document.querySelector(`[formcontrolname="${firstControlName}"]`) as HTMLElement;

            if (firstControlElement) {
              firstControlElement.classList.add('agent-form-invalid-field');
              firstControlElement.focus();
            }
          }
        }
      }
    }
  }
}




