import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import {
  FileTranslationsManagerUpload
} from "@shared/models/file-translations-manager/file-translations-manager-upload.model";
import {
  FileTranslationsUploadOptions,
  FileTranslationsUploadType, FileTypes,
  FileUploadErrorMessages
} from '@app/shared/constants/file-translations/file-translations-upload-options';
import {
  FileTranslationsManagerService
} from "@services/file-translations/file-manager/file-translations-manager.service";
import {
  FileTranslationsManagerTranslate
} from "@shared/models/file-translations-manager/file-translations-manager-translate.model";
import {
  FileTranslationsProcessingStatus
} from "@shared/constants/file-translations/file-translations-processing-status";
import { KmdModalService } from "gds-atom-components";
import {
  FileTranslationValidationService
} from '@app/core/services/file-translations/file-translation-validation/file-translation-validation.service';
import { FileTranslationsUploadStatus } from "@shared/constants/file-translations/file-translations-upload-status";

export interface FileUploadEvent {
  type: 'uploaded' | 'error' | 'removed' | 'uploading';
  data?: FileTranslationsManagerUpload;
}

export interface FileTranslateEvent {
  type: 'translated' | 'error' | 'removed' | 'translating';
  data?: FileTranslationsManagerTranslate;
}

@Component({
  selector: 'app-file-translations-uploader',
  templateUrl: './file-translations-uploader.component.html',
  styleUrl: './file-translations-uploader.component.css',
  animations: [
    trigger('fileListAnimation', [
      transition('* => *', [
        query(':enter', [
          style({ transform: 'translateY(-20px)', opacity: 0 }),
          stagger('100ms', animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })))
        ], { optional: true }),
        query(':leave', [
          stagger('100ms', animate('300ms ease-in', style({ transform: 'translateX(100%)', opacity: 0 })))
        ], { optional: true })
      ])
    ])
  ]
})
export class FileTranslationsUploaderComponent implements OnInit, OnDestroy {

  @Output() onUploading = new EventEmitter<boolean>();
  @Output() onTranslating = new EventEmitter<boolean>();
  @Output() onFileUploadErrorAlert = new EventEmitter<{showError: boolean, errorMessage: string}>();
  @Output() onFileTranslatedRefreshTranslatedList = new EventEmitter<boolean>();

  @Input() targetLanguage: string = "";
  @Input() sourceLanguage: string = "";

  readonly uploadStatus = FileTranslationsUploadStatus;

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('filesContainer') filesContainer!: ElementRef;

  isUploading = false
  isTranslating = false
  readonly uploadType: FileTranslationsUploadType = FileTranslationsUploadOptions['TRANSLATIONS'];
  readonly fileTypes = FileTypes['TRANSLATIONS'];
  uploadFiles: FileTranslationsManagerUpload[] = [];
  translateFiles: FileTranslationsManagerTranslate[] = [];
  activeTranslatingCount = 0;
  activeUploadingCount = 0;

  constructor(
    private fileTranslationsManagerService: FileTranslationsManagerService,
    private fileTranslationsValidationService: FileTranslationValidationService,
    private kmdModalService: KmdModalService
  ) { }

  ngOnInit() {
    this.fileTranslationsManagerService.initUploadFiles(this.uploadFiles);
    this.fileTranslationsManagerService.initTranslateFiles(this.translateFiles,
        () => this.onTranslatingInProgress());
    this.resetSourceLanguage();
    this.resetTargetLanguage();
    this.activeTranslatingCount = this.getFilesInTranslationCount();
    this.activeUploadingCount = this.getFilesInUploadCount();
  }

  ngOnDestroy() {
    this.fileTranslationsManagerService.cleanupCancellations();
  }

  scrollFilesContainerToBottom(): void {
    this.filesContainer.nativeElement.scrollTop = this.filesContainer.nativeElement.scrollHeight;
  }

  scrollFilesContainerToTop(): void {
    this.filesContainer.nativeElement.scrollTop = 0;
  }

  openFileDialog(): void {
    this.fileInput.nativeElement.click();
  };

  onFileChange(event: any): void {
    const files: FileList = event.target.files;
    this.handleFileUpload(files);
  };

  handleUploadEvents(event: FileUploadEvent): void {
    switch (event.type) {
      case 'uploaded':
        if(event.data){
          this.onFileUploaded(event.data);
        }
        break;
      case 'error':
      case 'removed':
      case 'uploading':
        this.onUploadingInProgress();
        break;
    }
  }

  handleTranslateEvents(event: FileTranslateEvent): void {
    switch (event.type) {
      case 'translated':
        if(event.data){
          this.onFileTranslated(event.data);
        }
        break;
      case 'error':
      case 'removed':
      case 'translating':
        this.onTranslatingInProgress();
        break;
    }
  }

  async handleFileUpload(files: FileList): Promise<void> {
    const filesToUpload: File[] = [];
    // TODO: empty document detection (docx, txt, eml)/minimum character count (Pop up and redirect the user to TEXT translation)
    //  source language match checking

    for (let i = 0; i < files.length; i++) {
      if(await this.validateFile(files[i])){
        filesToUpload.push(files[i]);
      }
    }

    if (filesToUpload.length > 0) {
      this.fileTranslationsManagerService.uploadFiles(
        filesToUpload,
        this.uploadType,
        this.sourceLanguage,
        this.targetLanguage,
        (errorMessage?: string) => this.handleError(errorMessage)
      ).forEach((fileUpload) => {
        const upload = this.fileTranslationsManagerService.getFileUploadStatus(fileUpload);
        this.uploadFiles.push(upload);
        this.onUploadingInProgress();
      });
    }
  }

  async validateFile(file: File): Promise<boolean> {
    const fileUpload: FileTranslationsManagerUpload = {
      file: file
    };

    // Check File Type
    let fileType = this.fileTranslationsValidationService.getFileType(file);
    if (fileType === '' || !this.fileTranslationsValidationService.isValidFileType(fileType)) {
      this.handleError(FileUploadErrorMessages.FILE_UPLOAD_INVALID_FILE_TYPE);
      return false;
    }

    // Check file size (Max limit Based on fileType)
    if (this.fileTranslationsValidationService.isMaxFileSizeExceeded(file.size, fileType)) {
      fileUpload.status = FileTranslationsProcessingStatus.FILE_SIZE_EXCEEDED;
      this.uploadFiles.push(fileUpload);
      return false;
    }

    // Check if file is password protected
    if (await this.fileTranslationsValidationService.isFilePasswordProtected(file, file.type)) {
      fileUpload.status = FileTranslationsProcessingStatus.PASSWORD_PROTECTED;
      this.uploadFiles.push(fileUpload);
      return false;
    }

    // Check if pdfs are scanned
    if (fileType === this.fileTypes.PDF.toLowerCase()) {
      if (await this.fileTranslationsValidationService.isScannedPdfFile(file)) {
        this.handleError(FileUploadErrorMessages.FILE_UPLOAD_SCANNED_PDF);
        return false;
      }
    }

    // TODO Check source language

    return true;
  }

  handleError(errorMessage: string = FileUploadErrorMessages.FILE_UPLOAD_FAILED): void {
    this.onFileUploadErrorAlert.emit({
      showError: true,
      errorMessage: errorMessage
    });
  }

  disableUploader() {
    if(!(this.sourceLanguage && this.targetLanguage)){
        return true;
    }
    return  (this.activeTranslatingCount + this.activeUploadingCount) >= this.uploadType.maxFiles;
  }

  onFileUploaded(data: FileTranslationsManagerUpload): void {
    if (data && data.id) {
      const index = this.uploadFiles.indexOf(data);
      if (index !== -1) {
        this.uploadFiles.splice(index, 1);
      }
      const fileId = data.id;
      const translateData = this.fileTranslationsManagerService.getFileTranslateStatus(fileId);
      this.translateFiles.push(translateData);
      this.onUploadingInProgress();
      this.onTranslatingInProgress();
      this.scrollFilesContainerToBottom();
    }
  }

  onFileTranslated(data: FileTranslationsManagerTranslate): void {
    if (data && data.file) {
      const index = this.translateFiles.indexOf(data);
      if (index !== -1) {
        this.translateFiles.splice(index, 1);
      }
      this.onTranslatingInProgress();
      this.scrollFilesContainerToTop();
      this.onFileTranslatedRefreshTranslatedList.emit(true);
    }
  }

  getFilesInTranslationCount(): number {
    return this.translateFiles
        .filter(item => item.status === FileTranslationsProcessingStatus.TRANSLATING
            && item.file !== undefined)
        .length;
  }

  getFilesInUploadCount(): number {
    return this.uploadFiles.filter(item => item.status === FileTranslationsUploadStatus.IN_PROGRESS
            && item.file !== undefined)
        .length;
  }

  onUploadingInProgress() {
    this.activeUploadingCount = this.getFilesInUploadCount();
    this.isUploading = this.activeUploadingCount > 0
    this.onUploading.emit(this.isUploading);
  }

  onTranslatingInProgress() {
    this.activeTranslatingCount = this.getFilesInTranslationCount();
    this.isTranslating = this.activeTranslatingCount > 0
    this.onTranslating.emit(this.isTranslating);
  }

  showClearFilesButton(): boolean {
    return this.uploadFiles.length > 0 || this.translateFiles.length > 0;
  }

  openClearFilesModal(): void {
    this.kmdModalService.open('clear-files-modal');
  }

  closeClearFilesModal(): void {
    this.kmdModalService.close('clear-files-modal');
  }

  showClearFilesModal(): void {
    if (this.isUploading || this.isTranslating) {
      this.openClearFilesModal();
    } else {
      this.clearAllFiles();
    }
  }

  clearAllFiles(): void {
    // Handle upload files - create a copy to avoid modifying during iteration
    const uploadFilesCopy = [...this.uploadFiles];
    uploadFilesCopy.forEach(file => {
      if (file && file.cancel) {
        file.cancel();
      }
    });

    // Update the array
    this.uploadFiles = [];

    // Handle translate files - create a copy to avoid modifying during iteration
    const translateFilesCopy = [...this.translateFiles];
    translateFilesCopy.forEach(file => {
      if (file && file.cancel) {
        file.cancel();
      }
    });

    // Update the array
    this.translateFiles = [];

    this.onUploadingInProgress();
    this.onTranslatingInProgress();
    this.closeClearFilesModal();
  }

  get wrapperClasses(): { [key: string]: boolean } {
    return {
      'has-files': this.hasFiles(),
      'single-file': this.singleFile()
    }
  };

  hasFiles(){
      return (this.uploadFiles.length + this.translateFiles.length) > 0;
  }

  singleFile(){
      return (this.uploadFiles.length || this.translateFiles.length) === 1;
  }

  resetSourceLanguage(){
      this.sourceLanguage = "";
  }

  resetTargetLanguage(){
      this.targetLanguage = "";
  }

}
