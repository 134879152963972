<div class="uploader-wrapper" [ngClass]="wrapperClasses">
  <div class="upload-panel" [ngClass]="{'disabled': disableUploader()}" appDragAndDrop
       (fileDropped)="handleFileUpload($event)">
    <div class="icon-wrapper">
      <i></i>
    </div>
    <div class="upload-instructions">
      <h6>Drag a file here or <a (click)="openFileDialog()">browse</a> to upload</h6>
      <p class="upload-types">
        {{ uploadType.filesText }}<br>
        {{ uploadType.filesInstructions }}
      </p>
      <input type="file" #fileInput (change)="onFileChange($event)" [accept]="uploadType.acceptedFileTypes" multiple>
    </div>
    <div class="upload-instructions mobile">
      <h6><a (click)="openFileDialog()">Select a file</a></h6>
      <p class="upload-types">
        {{ uploadType.filesText }}<br>
        {{ uploadType.mobileFileInstructions![0] }}<br>
        {{ uploadType.mobileFileInstructions![1] }}
      </p>
      <input type="file" #fileInput (change)="onFileChange($event)" [accept]="uploadType.acceptedFileTypes" multiple>
    </div>
  </div>
  <div class="uploaded-files-container" *ngIf="uploadedFiles.length">
    <div class="file-upload-list">
      <ng-container *ngFor="let data of uploadedFiles">
        <div class="file-upload-summary" [ngClass]="getFileStatus(data)">
          <div class="file-upload-details-wrapper">
            <div class="file-upload-details">
              <ng-container *ngIf="!isStatusInProgress(data.status)">
                <div [class]="getFileStatusClass(data.status!)">
                  <i></i>
                </div>
              </ng-container>
              <div class="labels">
                <p class="file-title">{{ data.file.name }}</p>
                <p class="file-title mobile">{{ getMobileDisplayName(data.file) }}</p>
                <p class="file-size">{{ getReadableFileSize(data.file.size) }}</p>
              </div>
              <div class="cancel upload-control mobile" (click)="removeFile(data)">
                <i></i>
              </div>
            </div>
            <ng-container *ngIf="isUploadProgressDisplayed(data)">
              <div class="upload-progress">
                <div class="progress-bar" [style.width]="data.progress + '%'"></div>
              </div>
              <div class="upload-progress loading-wheel">
                <kmd-loading-wheel></kmd-loading-wheel>
              </div>
            </ng-container>
            <div class="file-upload-controls">
              <div class="file-status error upload-error" *ngIf="data.status === uploadStatus.ERROR">
                <label>Upload error</label>
              </div>
              <div class="file-status error password-protected" *ngIf="data.status === uploadStatus.PASSWORD_PROTECTED">
                <label>Password protected</label>
              </div>
              <div class="file-status error file-size-exceeded"
                   *ngIf="data.status === uploadStatus.FILE_SIZE_EXCEEDED">
                <label>File size exceeded</label>
              </div>
              <div class="pause upload-control" (click)="pauseFileUpload(data)"
                   *ngIf="data.status === uploadStatus.IN_PROGRESS">
                <i></i>
              </div>
              <div class="restart upload-control" (click)="retryFileUpload(data)" *ngIf="isRetryButtonDisplayed(data)">
                <i></i>
              </div>
              <div class="cancel upload-control" (click)="removeFile(data)">
                <i></i>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
