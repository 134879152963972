import { ModelList } from "@shared/models/prompt-settings.model";

export const imageSize = [
  { title: 'Square', description: '1024x1024', value: "1024x1024" },
  { title: 'Portrait', description: '1024x1792', value: "1024x1792" },
  { title: 'Landscape', description: '1792x1024', value: "1792x1024" }
];

export const imageQuality = [
  { title: 'High definition (HD)', description: 'Finer details, greater consistency, longer image creation.', value: "hd" },
  { title: 'Standard', description: 'Quick and simple image creation.', value: "standard" }
];

export const imageStyle = [
  { title: 'Natural', description: 'Real natural-looking style.', value: "natural" },
  { title: 'Vivid', description: 'Hyper-real and dramatic images.', value: "vivid" },
];

export const modelList: ModelList = [
  {
    model: "gpt-4",
    title: "GPT4 32K",
    description: "Advanced, detailed, and deep for complex queries."
  },
  {
    model: "gpt-4-o",
    title: "GPT 4o 128K",
    description: "Comprehensive, high-performance, and fast for extensive and large-scale tasks."
  }
];
