<div class="modal block-user-initials" *ngIf="isVisible">
  <div class="modal-overlay" *ngIf="showOverlay" (click)="closeOnOutsideClick ? close() : null"></div>
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">
        <h2>{{ headline }}</h2>
        <p>{{ subHeadline }}</p>
      </div>
      <i class="close-btn" (click)="close()"></i>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>