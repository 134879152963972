<div class="chat-history-new-container" *ngIf="(allChats$ | async) as page">
  <div *ngIf="loaded && page.content.length === 0; else chatsView">
    <app-chat-history-zero-state id="zero-state-view"></app-chat-history-zero-state>
  </div>
  <ng-template #noChatsView>
    <ng-container *ngIf="page.content.length === 0 && searchFormGroup.controls.search.value">
      <div class="no-chats-view">
        <div class="empty-search-state-img"></div>
        <div class="matches-text">No matches for "{{this.searchFormGroup.controls.search.value}}"</div> <br>
        <div class="matches-sub-text">Try using different terms</div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #chatsView>
    <ng-container>
      <input type="text" class="search-term" kmdFormInput id="chat-search-input" placeholder="Search chat title"
             [formControl]="searchFormGroup.controls.search" autocomplete="off" />
      <div class="chat-history-new-page">
        <app-data-table *ngIf="page.content.length > 0; else noChatsView" [records]="page"></app-data-table>
      </div>
      <kmd-pagination #pagination [totalResults]="page.totalElements" [resultsPerPage]="RESULTS_PER_PAGE"
        (onPageChange)="onPageChange($event)">
      </kmd-pagination>
    </ng-container>
  </ng-template>
</div>
