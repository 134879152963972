import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { PromptService } from '../core/services/prompt/prompt.service';
import { ModalReadOnlyPromptComponent } from '../prompt/modal-read-only-prompt/modal-read-only-prompt.component';
import { Prompt } from '../shared/models/prompt.model';
import { KmdModalService } from 'gds-atom-components';
import { Router } from '@angular/router';
@Component({
  selector: 'app-public-prompt-list',
  templateUrl: './public-prompt-list.component.html',
  styleUrls: ['./public-prompt-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PublicPromptListComponent {
  showError = false
  errorMessage = "Unable to load the list";
  publicPrompts$!: Observable<Array<Prompt>>
  @ViewChild('modalReadOnlyPrompt') modalReadOnlyPrompt!: ModalReadOnlyPromptComponent;
  @Output() promptSelectEvent = new EventEmitter<Prompt>();
  promptToUse?: Prompt

  selectedPrompt?: Prompt
  posX: number = 0;
  posY: number = 0;

  constructor(
    private promptService: PromptService,
    private kmdModalService: KmdModalService,
    private router: Router,
  ) {
    this.loadPublicPrompts();
  }

  private loadPublicPrompts() {
    this.publicPrompts$ = this.promptService.prompts(undefined,"public").pipe(
      catchError((err) => {
        this.showError = true
        return throwError(err)
      })
    )
  }

  viewPrompt(prompt: Prompt) {
    this.selectedPrompt = prompt
    this.modalReadOnlyPrompt.open()
  }

  showPopover(event: any, prompt: Prompt) {
    this.promptToUse = prompt
    event.preventDefault();
    const rect = event.currentTarget.getBoundingClientRect();
    this.posX = rect.left + window.scrollX + 10;
    this.posY = rect.top + window.scrollY + 25;
  }

  useOnActualChat() {
    this.kmdModalService.close('select-modal');
    this.promptSelectEvent.emit(this.promptToUse);
  }

  useOnNewChat() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/ask-gene'], { state: { prompt: this.promptToUse }, replaceUrl: true })
    );
  }
}
