import { Component, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PromptExample } from "@shared/models/prompt-example.model";
import { ChatWrapperComponent } from '@shared/components/chat-wrapper/chat-wrapper.component';
import { ChatQAService } from '@services/chat-qa/chat-qa.service';
import { PromptSubmitEvent } from '@shared/models/prompt-submit-event';
import { Router } from '@angular/router';
import { NavigationCacheService } from '@services/navigation-cache/navigation-cache.service';
import { featureFlags } from '@app/environments/environment';

@Component({
  selector: 'ask-gene-new',
  templateUrl: './ask-gene-new.component.html',
  styleUrls: ['./ask-gene-new.component.css']
})
export class AskGeneNewComponent {
  @ViewChild(ChatWrapperComponent) chatWrapperComponent!: ChatWrapperComponent;

  userName$: Observable<string> = of('');

  bannerEnabled: boolean = true;

  protected readonly featureFlags = featureFlags;

  readonly bannerModalTitle = 'Upload new file type: PowerPoint';
  readonly learnMore = 'https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Education%20&%20resources/Generative-AI-FAQs.aspx?csf=1&web=1&e=zxG4p6#ask-my-docs';

  constructor(
    public router: Router,
    private chatQAService: ChatQAService,
    private navigationCacheService: NavigationCacheService
  ) {
    this.userName$ = this.chatQAService.userName$;
    this.navigationCacheService.clearData();
  };

  setPrompt(prompt: PromptExample) {
    this.chatWrapperComponent.setPromptText(prompt.text);
  }

  onPromptSubmit(promptEvent: PromptSubmitEvent) {
    this.chatQAService.setNewActivePrompt(promptEvent);
    this.router.navigate([`/chat`]);
  }

  onNewsBannerClosed() {
    this.bannerEnabled = false;
  };
}
