<div class="agent-edit-toolbar">
  <div class="top-container">
    <a class="back-to-agents" (click)="navigateToAgents()">
      <img src="../assets/icons/arrows/arrowLeft.svg"><span>Agents</span>
    </a>
  </div>
  <div class="bottom-container">
    <h1>Edit "{{ agent?.name?.trim() }}"</h1>
  </div>
</div>

<div class="agent-edit-form">
  <div class="agent-parent-container">
    <div id="agent-edit-form-container">
      <div id="agent-edit-title-bar">
        <h4 *ngIf="showAgentEditForm">Configure Agent</h4>
        <button kmdPrimaryButton id="agent-example-button" *ngIf="showExampleButton" (click)="toggleAgentExample()">Show
          me an example
        </button>
      </div>
      <app-agent-form
        #agentForm
        [hidden]="!showAgentEditForm"
        [agent]="agent"
        (formStatusChanged)="formStatusChanged($event)"
        (onSubmitValidForm)="saveAgent($event)"
        (askOurDocsEnabled)="updateAodStatus($event)"
        (askMyDocsEnabled)="setAskMyDocsState($event)"
        (formSaveAndShareStatus)="updateSaveAndShareStatus($event)" />
    </div>
    <div class="agent-example" [hidden]="!showExampleAgent">
      <app-agent-example (closeAgentExample)="toggleAgentExample()"></app-agent-example>
    </div>
  </div>
  <div class="action-buttons">
    <ng-container *featureFlag="'agentSharing'">
      <ng-container *ngIf="!agent?.isPublic">
        <button id="save-and-share-button" kmdSecondaryButton
                [disabled]="!isSaveAndShareValid || isAodEnabled"
                (click)="submitAndShareAgent()">
          <span>Save and Share</span>
        </button>
      </ng-container>
    </ng-container>
    <button id="publish-and-chat-button" kmdFeaturedButton [disabled]="!validForm" (click)="publishAndChat()">
      <ng-container *featureFlag="'agentSharing' ; else saveButtonText">
        <span>Save and Chat</span>
      </ng-container>
      <ng-template #saveButtonText>
        <span>Publish and Chat</span>
      </ng-template>
    </button>
    <button class="cancel-btn" kmdPrimaryButton (click)="navigateToAgents()">
      <span>Cancel</span>
    </button>
  </div>
</div>

<kmd-modal [id]="'cancel-edit-modal'" id="cancel-edit-modal" modalSize="mini" headline="Warning">
  <div id="text">
    You'll lose any progress made in editing your agent.
  </div>
  <div class="modal-buttons-container">
    <button id="discard-changes-btn" (click)="discardChanges()" kmdInfoButton>Discard and exit</button>
    <button id='continue-editing-btn' (click)="continueEditing()" kmdFeaturedButton>Continue editing</button>
  </div>
</kmd-modal>

<app-agent-sharing-modal
  #agentSharingModal
  [sharingDetails]="sharingDetails"
  (onSharedAgent)="navigateToSharedWithMeAgents()"
  (onUnsharedAgent)="navigateToSharedWithMeAgents()"
  (onPubliclySharedAgent)="navigateToMyAgents()" />
