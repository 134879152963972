import { Observable, of } from 'rxjs';
import { GuardChecker } from './guard-checker-interface';
import { SVA2RoleGuard } from "../guards/sva2-role.guard";
import { featureFlags } from "@environments/environment";

export class SVA2RoleGuardChecker implements GuardChecker {
  check(guard: SVA2RoleGuard): Observable<boolean> {
    if(featureFlags.sva2) return of(true);
    return guard.hasSVA2Roles()
  }
}