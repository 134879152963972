import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KmdModalService } from 'gds-atom-components';
import { PromptService } from 'src/app/core/services/prompt/prompt.service';
import { Prompt } from 'src/app/shared/models/prompt.model';

@Component({
  selector: 'app-modal-save-prompt',
  templateUrl: './modal-save-prompt.component.html',
  styleUrls: ['./modal-save-prompt.component.css']
})
export class ModalSavePromptComponent {

  @Input() id: string = '';
  @Input() prompt!: Prompt;
  @Output() promptSaveEvent = new EventEmitter<Prompt>();
  showAlert: boolean = false;

  constructor(private promptService: PromptService, private kmdModalService: KmdModalService) { }

  open(): void {
    this.kmdModalService.open(this.id);
  }

  close(): void {
    this.kmdModalService.close(this.id);
  }

  onFormAccept(prompt: Prompt){
    this.promptService.save(prompt).subscribe({
      next: () => {
        this.promptSaveEvent.emit(prompt);
      },
      error: () => {
        this.showAlert = true;
      }
    });
  }
}
