import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-agent-conversation-starter',
  templateUrl: './agent-conversation-starter.component.html',
  styleUrls: ['./agent-conversation-starter.component.css']
})
export class AgentConversationStarterComponent {
  @Input() conversationStarters!: string[];
  @Output() conversationStarterEvent = new EventEmitter<string>();

  conversationStarterSelected() {
    this.conversationStarterEvent.emit(this.conversationStarters[0]);
  }
}
