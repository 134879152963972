import { HttpClient } from "@angular/common/http";
import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { catchError, from, Observable, of, shareReplay, Subject, switchMap, takeUntil, tap } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MarkdownImageRendererService {
    private renderedImages = new Set<string>();

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) { };

    sanitizeUrl(url: string | null): string | null {
        return this.sanitizer.sanitize(SecurityContext.URL, url);
    };

    isSafeSource(url: string): boolean {
        return url.includes('thermofisher') || url.includes("gene-ai-code");
    };

    generateUniqueId(url: string): string {
        return `img-${btoa(url).replace(/[^a-zA-Z0-9]/g, '')}`;
    };

    loadImage(safeHref: string, uniqueId: string, imageCache: Map<string, Observable<string | null>>, destroy$: Subject<void>): Observable<string | null> {
        if (this.renderedImages.has(uniqueId)) {
            return of(null);
        }

        this.renderedImages.add(uniqueId);

        if (!imageCache.has(safeHref)) {
            const imageLoader$ = this.http.get(safeHref, { responseType: 'blob', observe: 'response' }).pipe(
                tap(response => {
                    if (response.status !== 200) {
                        throw new Error(`HTTP status ${response.status}`);
                    }
                }),
                switchMap(response => from(this.blobToDataUrl(response.body!))),
                catchError(() => {
                    this.renderedImages.delete(uniqueId);
                    imageCache.delete(safeHref);
                    return of(null);
                }),
                shareReplay(1)
            );

            imageCache.set(safeHref, imageLoader$);
        }

        return imageCache.get(safeHref)!.pipe(
            takeUntil(destroy$)
        );
    };

    private blobToDataUrl(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = () => reject(reader.error);
            reader.readAsDataURL(blob);
        });
    };

    getDataTypeFromDataUrl(dataUrl: string): string | null {
        const match = dataUrl.match(/^data:([a-zA-Z0-9.+-]+\/[a-zA-Z0-9.+-]+)(;base64)?,/);
        return match?.[1] ?? null;
    };

    dataUrlToBlob(dataUrl: string): Blob {
        const [header, base64Data] = dataUrl.split(',');
        const mimeType = header.split(':')[1].split(';')[0];
        const binaryString = atob(base64Data);

        const buffer = new ArrayBuffer(binaryString.length);
        const byteArray = new Uint8Array(buffer);

        for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
        }

        return new Blob([byteArray], { type: mimeType });
    }
}