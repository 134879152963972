import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Language, LANGUAGE_COMBINATIONS, LanguageCombinations } from './language-combinations';

@Injectable({
  providedIn: 'root'
})
export class LanguageManagerService {

  constructor(
    @Inject(LANGUAGE_COMBINATIONS) private languageData: LanguageCombinations
  ) { }

  getSourceLanguages(): Observable<Language[]> {
    // Use the predefined language data instead of an API call
    return of(this.languageData.sourceLanguages);

    //  TODO revert to API call in the future:
    // return this.http.get<Language[]>(`${this.apiUrl}/source`)
    //   .pipe(
    //     catchError(this.handleError<Language[]>('getSourceLanguages', []))
    //   );
  }

  getTargetLanguages(sourceCode: string): Observable<Language[]> {
    // Use the predefined language data
    const targetLanguages = this.languageData.targetCombinations[sourceCode] || [];
    return of(targetLanguages);

    // TODO  revert to API call in the future:
    // return this.http.get<Language[]>(`${this.apiUrl}/target?source=${sourceCode}`)
    //   .pipe(
    //     catchError(this.handleError<Language[]>('getTargetLanguages', []))
    //   );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed:`, error);
      // Return empty result to keep app running
      return of(result as T);
    };
  }
}
