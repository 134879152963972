export type FileTranslationsUploadType = {
  acceptedFileTypes: string;
  unsupportedFileTypes: string[];
  extensions: string[];
  filesInstructions: string;
  mobileFileInstructions?: string[];
  filesText: string;
  maxFiles: number;
  maxFileSizesByType: {
    [key: string]: number;
  };
};

export const FileTranslationsUploadOptions: {
  [key: string]: FileTranslationsUploadType;
} = {
  TRANSLATIONS: {
    acceptedFileTypes: '.txt, .docx, .pdf, .eml',
    unsupportedFileTypes: [],
    extensions: [
      '.txt',
      '.docx',
      '.pdf',
      '.eml',
    ],
    filesInstructions: 'Max 5 files at a time | Max 300kb per file',
    mobileFileInstructions: [
      'Max 5 files at a time | Max 300kb per file',
    ],
    filesText: 'Text file type(s): .txt, .docx, .pdf, .eml',
    maxFiles: 5,
    maxFileSizesByType: {
      'txt': 307200,    // 300kb for text files
      'docx': 307200,   // 300kb for docx files
      'pdf': 307200,    // 300kb for pdf files
      'eml': 307200,    // 300kb for email files
    },
  },
};

export const FileTypes: { [key: string]: any } = {
  TRANSLATIONS: {
    TXT: 'TXT' as const,
    DOCX: 'DOCX' as const,
    PDF: 'PDF' as const,
    EML: 'EML' as const
  },
};

export const FileUploadErrorMessages = {
  FILE_UPLOAD_FAILED: 'Upload failed, Please try again!',
  FILE_UPLOAD_INVALID_FILE_NAME: 'File Upload Failed, You already have a translated file with the same name plus same target language',
  FILE_UPLOAD_INVALID_FILE_TYPE: 'Invalid File Type, Please only use the following types (.txt, .docx, .pdf,  .eml)',
  FILE_UPLOAD_SCANNED_PDF: 'Sorry, scanned pdfs currently cannot be translated.',
}


