import { Component, ElementRef, HostListener, Input, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavBarService } from '@app/core/services/navbar/navbar.service';
import { WindowRef } from '@app/core/services/window/window-ref.service';
import {
  NavBarScreenResolutionSetting,
  NavBarScreenResolutionSettings
} from '@app/shared/constants/navbar/screen-resolutions';
import { MenuOption } from '@app/shared/models/navbar/menu-option';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css']
})
export class SubMenuComponent implements OnInit {
  @Input() isMenuCollapsed!: boolean;
  @Input() submenuOptions: MenuOption[] = [];
  @ViewChild('contextPopOver') contextPopOver!: ElementRef;
  popoverPosition = { top: 0, left: 0 };
  currentScreenResolutionSetting: NavBarScreenResolutionSetting = NavBarScreenResolutionSettings.mobile;
  private resizeSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2,
    protected navBarService: NavBarService
  ) { }

  ngOnInit(): void {
    this.navBarService.currentScreenResolutionSetting$.subscribe((resolution) => {
      this.currentScreenResolutionSetting = resolution;
    });
    this.resizeSubscription = this.navBarService.resizeTriggered$.subscribe(() => {
      this.hidePopover();
    });
  }

  showPopover(event: MouseEvent): void {
    event.stopPropagation();
    if (this.isPopoverVisible) {
      this.hidePopover();
    } else {
      this.setPopoverPosition(event);
    }
  }

  onSubMenuOptionClick(event$: MouseEvent, subOption: any): void {
    if (WindowRef.innerWidth <= NavBarScreenResolutionSettings.mobile.size) {
      this.navBarService.setMobileResolution();
    }
    this.navigateTo(subOption);
    this.hidePopover();
  }

  setPopoverPosition(event: MouseEvent): void {
    if (event.currentTarget instanceof HTMLElement) {
      if (this.isMobileResolution()) {
        this.navBarService.setTabletResolution();
        this.calculateMobilePopoverPosition(event.currentTarget);
      } else {
        this.popoverPosition = this.calculatePopoverPosition(event);
      }
    }
  }

  private calculatePopoverPosition(event: MouseEvent): { top: number; left: number } {
    const navBarContainer = this.renderer.selectRootElement('.nav-menu-wrapper .nav-menu', true);
    const navBarWidth = navBarContainer.clientWidth || 0;
    const contextPopOverElement = this.contextPopOver.nativeElement;
    const currentTarget = event.currentTarget as HTMLElement;
    const boundingRect = currentTarget.getBoundingClientRect();

    return {
      left: navBarWidth,
      top: boundingRect.top - contextPopOverElement.clientHeight - boundingRect.height * 2,
    };
  }

  private calculateMobilePopoverPosition(currentTarget: HTMLElement): void {

    setTimeout(() => {
      const newCurrentTarget = document.getElementById(currentTarget.id);
      const newBoundingRect = newCurrentTarget!.getBoundingClientRect();
      const navBarContainer = this.renderer.selectRootElement('.nav-menu-wrapper .nav-menu', true);
      const navBarWidth = navBarContainer.clientWidth || 0;
      const contextPopOverElement = this.contextPopOver.nativeElement;

      this.popoverPosition = {
        left: navBarWidth,
        top: (newBoundingRect!.top - contextPopOverElement.clientHeight) + newBoundingRect!.height || 0,
      };

    }, 0);
  }

  private isMobileResolution(): boolean {
    return (
      this.currentScreenResolutionSetting === NavBarScreenResolutionSettings.mobile ||
      WindowRef.innerWidth <= NavBarScreenResolutionSettings.mobile.size
    );
  }

  navigateTo(subMenuOption: MenuOption) {
    if (!subMenuOption.anchor) return;

    if (subMenuOption.anchor.type === 'href') {
      window.open(subMenuOption.anchor.value, '_blank');
      return;
    }

    if (!this.router.url.includes(subMenuOption.anchor.value)) {
      this.ngZone.run(() => {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate([subMenuOption.anchor?.value]));
      });
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (!(event.target as HTMLElement).closest('#context-pop-over')) {
      this.hidePopover();
    }
  }

  hidePopover(): void {
    this.popoverPosition = { top: 0, left: 0 };
  }

  get isPopoverVisible(): boolean {
    return this.popoverPosition.top !== 0 || this.popoverPosition.left !== 0;
  }
}
