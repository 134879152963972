import {ChatHistoryService} from "@services/chathistory/chat-history.service";
import {ChatService} from "@services/chat/chat.service";
import {Chat} from "@shared/models/chat.model";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class ChatHistory implements ChatHistoryService {
    constructor(private chatService: ChatService) { }

    delete(chat: Chat): Observable<void> {
        return this.chatService.delete(chat.id!);
    }

    getChats(chatHistoryType: string): Observable<Chat[]> {
        return this.chatService.getChats(chatHistoryType);
    }

    update(chat: Chat): Observable<void> {
        return this.chatService.update(chat);
    }
}
