import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client'

@Injectable()
export class BasehttpInterceptor implements HttpInterceptor {

  constructor(@Inject('BASE_API_URL') private baseUrl: string,
              @Inject("TRANSLATIONS_API_URL")  private translationsUrl: string,
              @Inject('ASK_DATA_BASE_API_URL') private APIbaseUrl: string,
              @Inject('ASK_DOCS_BASE_API_URL') private APIAODocsBaseUrl: string,
              private oidcSecurityService: OidcSecurityService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!request.url.includes(this.baseUrl) && !request.url.includes(this.translationsUrl)
      && !request.url.includes(this.APIbaseUrl) && !request.url.includes("v1/get_file") && !request.url.includes(this.APIAODocsBaseUrl)) {
        return next.handle(request);
    }


    return this.oidcSecurityService.getAccessToken().pipe(
      map((idToken) => request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + idToken) })),
      flatMap((newRequest) => next.handle(newRequest))
    )
  }
}
