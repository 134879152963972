<div class="nav-menu-wrapper">
  <div class="nav-menu" [ngClass]="{ 'collapsed': this.isCollapsed, 'hidden': this.isHidden }">
    <div class="navbar-menu-top">
      <div class="hamburger-menu" *ngIf="!isMobileScreen()">
        <div [id]="navMenuToggleId" *ngIf="'navMenuToggleButton'" class="hamburger-icon">
          <img src="../assets/icons/hamburger/hamburger-24px-mono.svg" height="22">
        </div>
        <div class="header-logo">
          <img src="assets/images/gene-ai-header.svg" height="24" id="headerLogoId">
        </div>
      </div>
      <ng-container *ngFor="let menu of menuOptions">
        <ng-container [ngSwitch]="menu.customClass" *ngIf="(showMenuOption$(menu) | async)">
          <app-sub-menu-option *ngSwitchCase="'submenu'" [menuOption]="menu" [state]="navBarStatus()"
                               (onNavigation)="handleMenuClick()"/>
          <app-my-library-menu-option *ngSwitchCase="'my-library'" [menuOption]="menu" [state]="navBarStatus()"
                                      (onNavigation)="handleMenuClick()"/>
          <app-menu-option-new *ngSwitchDefault [menuOption]="menu" [state]="navBarStatus()"
                               (onNavigation)="handleMenuClick()"/>
        </ng-container>
      </ng-container>
    </div>
    <div class="navbar-menu-bottom">
      <div id="collapsedView">
        <a id="about-anchor" (click)="toggleNavbarStatus()">
          <img id="about-icon" src="../../assets/icons/question/question-24px-mono.svg" appTooltip text="About Gene.AI" position="right">
        </a>
      </div>
      <div id="expandedView">
        <div id="links-wrapper" class="navbar-menu-bottom-item">
          <div class="navbar-menu-bottom-link">
            <a id="about-anchor" target="_blank" href="{{aboutUrl}}">About Gene.AI</a>
          </div>
          <div class="navbar-menu-bottom-link">
            <a id="feedback-anchor" target="_blank" href="{{feedbackUrl}}">
              Submit your feedback
            </a>
          </div>
        </div>
        <div class="navbar-menu-bottom-item small-text">
          <img id="thermo-logo" src="../../assets/images/thermo-fisher-scientific.svg">
        </div>
        <div class="navbar-menu-bottom-item small-text">
          © Copyright 2023-2024<br>
          Thermo Fisher Scientific.<br>
          All rights reserved.
        </div>
      </div>
    </div>
  </div>
</div>
