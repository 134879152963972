import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild, ViewEncapsulation, } from "@angular/core";
import { Agent } from "@shared/models/agent";
import { Router } from "@angular/router";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { KmdModalService, KmdPopoverComponent, KmdTooltipComponent } from "gds-atom-components";
import { featureFlags } from "@app/environments/environment";

@Component({
  selector: "app-agent-card",
  templateUrl: "./agent-card.component.html",
  styleUrls: ["./agent-card.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AgentCardComponent implements AfterViewInit, OnChanges {
  @Input() agent!: Agent;
  @Input() type!: string;
  @Input() scrollDiv!: HTMLDivElement;
  @Input() isUserApprover: boolean = false;
  @Input() username: string | undefined = '';
  @Input() displayRating: boolean = false;
  @Input() isSmallList: boolean = false;

  @Output() deleteAgentEvent = new EventEmitter<Agent>();
  @Output() onViewAgentEvent = new EventEmitter<Agent>();
  @Output() onShareAgentEvent = new EventEmitter<Agent>();
  @Output() onRecommendAgentEvent = new EventEmitter<Agent>();

  @ViewChild("popOver") popOver!: KmdPopoverComponent;
  @ViewChild("toolTip") toolTip!: KmdTooltipComponent;

  faEllipsis = faEllipsis;
  posY: number = 0;
  posX: number = 0;
  isSmallCard: boolean = false;

  constructor(private router: Router, private kmdModalService: KmdModalService) {
  }

  ngAfterViewInit(): void {
    if (!featureFlags.paginatedAgents) {
      this.setCardContainerResizeObserver();
    }
  }

  ngOnChanges(): void {
    if (featureFlags.paginatedAgents) {
      this.isSmallCard = this.isSmallList;
    }
  }

  setCardContainerResizeObserver() {
    const observer = new ResizeObserver(
      entries => entries.forEach(entry => this.onCardContainerResize(entry))
    );

    const cardSelector = document.querySelector(`.agent-card-container.${this.type}#agent-card-${this.agent.id}`);

    if (!cardSelector) return;

    observer.observe(cardSelector);
  };

  onCardContainerResize(entry: any) {
    const smallCardSizeReference = 580;

    const isSmall = entry.contentRect.width <= smallCardSizeReference;

    if (isSmall === this.isSmallCard) return;

    this.isSmallCard = isSmall;
  };

  startChat() {
    this.router.navigate([`/agents/${this.agent?.id}/chat`]);
  }

  openDeleteAgentDialog() {
    this.deleteAgentEvent.emit(this.agent);
    this.popOver.hide();
  }

  navigateToEditAgent() {
    this.router.navigate([`/agents/${this.agent.id}/edit`]);
  }

  showActionsMenu(event: MouseEvent) {
    if (event.currentTarget instanceof HTMLElement) {
      this.posX = (event.currentTarget.offsetLeft - this.scrollDiv.scrollLeft + event.currentTarget.clientWidth / 2) - 175;
      this.posY = (event.currentTarget.offsetTop - this.scrollDiv.scrollTop + event.currentTarget.clientHeight / 2) + 8;
    }
    this.popOver.toggle(event);
  }

  openShareAgentDialog(): void {
    if (this.agent.allowShare) {
      this.onShareAgentEvent.emit(this.agent);
      this.popOver.hide();
    }
  }

  isAgentShareable(): boolean {
    return (this.agent?.allowShare === true || this.agent?.isPublic === true);
  }

  getAgentMenu(): string {
    return this.agent.allowShare !== true ? 'share-agent-disabled' : '';
  }

  belongsToPublicAgentTab(): boolean {
    return this.type === "publicAgent";
  }

  addToMyAgents() {
    this.router.navigate([`/agents/public/${this.agent?.id}/save`]);
  }

  viewAgent() {
    this.onViewAgentEvent.emit(this.agent);
    this.popOver.hide();
  }

  getCreatedBy(): string {
    return this.agent.createdBy!;
  }

  showPublicCreatedBy(): boolean {
    return this.belongsToPublicAgentTab()
      && !this.username
      && this.agent.createdBy !== null
      && this.agent.createdBy !== 'Unknown';
  }

  closeModal(modalId: string) {
    this.kmdModalService.close(modalId);
  }

  get showRating(): boolean {
    if (!this.displayRating) return false;

    if (!this.agent.rating) return false;

    return this.agent.rating.likes > 0;
  }

  get isRecommended(): boolean {
    return this.type == "publicAgent" && this.agent.isRecommended!;
  }

  isUserAbleToRecommend(): boolean {
    return this.isUserApprover && !this.isRecommended;
  }

  isUserAbleToRemoveRecommendation(): boolean {
    return this.isUserApprover && this.isRecommended;
  }

  recommendAgent() {
    this.onRecommendAgentEvent.emit(this.agent);
    this.popOver.hide();
  }

  removeRecommendedAgent() {
    this.onRecommendAgentEvent.emit(this.agent);
    this.popOver.hide();
  }

  protected readonly featureFlags = featureFlags;
}
