import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.css']
})
export class NotificationCardComponent {
    @Input() label: string = '';
    @Input() message: string = '';
    @Input() type: 'success' | 'error' | 'warning' | 'info' = 'info';
    @Input() closable = true;
    @Input() showIcon = true;
    @Input() color: string = '';

    show: boolean = true;

    close() {
        this.show = false;
    };
};
