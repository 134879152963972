<div id="input-component-wrapper" #inputComponentWrapper
     [ngClass]="{'populated': isInputPopulated, 'error': isLimitExceeded}">
  <textarea id="input-component-container" #inputComponentContainer (keyup)="checkInputAndToggleSubmitButton()"
            autosize [minRows]="1" [maxRows]="7"
            [(ngModel)]="prompt" (keydown.enter)="onInputKeydownEnter($event)"
            placeholder="Enter your prompt here">
  </textarea>
  <div id="input-component-controls-section">
    <div class="input-component-control custom">
      <app-file-manager/>
    </div>
    <div class="input-component-control" id="model-control">
      <div class="model-button-wrapper" (click)="toggleModelDropdown()" (clickOutside)="closeContextMenu()">
        <img src="../../../../assets/icons/model/model-icon.svg" alt="model-icon" width="20"/>
        <div id="select-model-btn" appTooltip text="Change model" position="top">
          <label>{{ selectedModel }}</label>
          <img src="{{ showModelDropdown
            ? '../../../../assets/icons/caret/caretUp-11px-mono-black.svg'
            : '../../../../assets/icons/caret/caretDown-11px-mono-black.svg'}}" width="8">
        </div>
      </div>
      <app-context-menu #modelContextMenu [ngClass]="{'closed': !showModelDropdown}" [items]="modelList"
                        [defaultSelectedItem]="selectedModel" [slideDirection]="'down-to-up'"
                        (itemClicked)="setSelectedModel($event)"
                        (isContextMenuOpen)="isContextMenuOpen($event)"/>
    </div>
    <div class="input-component-control" id="settings-control" (click)="onPromptSettingsButtonClick()" appTooltip text="Prompt settings" position="top">
      <img ngSrc="assets/icons/filter/filter-horizontal.svg" alt="" height="24" width="24">
    </div>
    <div class="input-component-send-control" [ngClass]="currentSubmitStatus!.cssClass"
         (click)="currentSubmitStatus!.onClick()" id="send-control">
      <img class="send-icon" id="send-icon" [src]="currentSubmitStatus!.url" alt="">
      <span>Stop response</span>
    </div>
  </div>
</div>

<div class="countdown-label-wrapper" *ngIf="showCounter()" [ngClass]="{'error' : isTextTrimmed || isLimitExceeded}">
  <p id="text-trimmed" *ngIf="isTextTrimmed" class="error">
    Text cut, characters limit reached on model
  </p>
  <p id="text-limit" *ngIf="isLimitExceeded" class="error">
    Character limit exceeded
  </p>
  <countdown-label [maxlength]="maxInputCharLimit()" [value]="prompt"
                   [ngStyle]="{'color': isLimitExceeded ? '#F00' : '#F56A00'}"/>
</div>

<div id="input-component-footer">
  <p>
    Inaccuracies may occur in Gene.AI, make sure to cross-verify critical information.
    Please <a [routerLink]="['/guidelines']">consult our guidelines</a>
    for additional information.
  </p>
</div>
