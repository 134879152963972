import { Message } from "@shared/models/message.model";

export class ChatMessage {

  parent: ChatMessage | undefined
  message: Message
  children: ChatMessage[] = []

  constructor(message: Message, parent: ChatMessage | undefined) {
    this.parent = parent
    this.message = message;
    this.parent?.addChild(this);
  }

  hasSiblings(): boolean {
    if (this.parent?.children) {
      return this.parent?.children.length > 1;
    } else {
      return false;
    }
  }

  leftSibling(): ChatMessage | undefined {
    let index = this.siblingIndex();
    if (index != undefined)
      return this.parent?.children[index - 1]
    else
      return undefined;
  }

  hasLeftSibling() {
    return this.leftSibling() != undefined;
  }

  rightSibling(): ChatMessage | undefined {
    let index = this.siblingIndex();
    if (index != undefined)
      return this.parent?.children[index + 1]
    else
      return undefined;
  }

  hasRightSibling() {
    return this.rightSibling() != undefined;
  }

  addChild(child: ChatMessage) {
    this.children.push(child)
  }

  mostRecentChild(): ChatMessage | undefined {
    if (this.children.length == 0) {
      return undefined;
    }

    return this.children.reduce((previousValue, currentValue) => {
      return currentValue.message.created > previousValue.message.created ? currentValue : previousValue;
    })
  }

  siblingIndex(): number | undefined {
    return this.parent?.children.indexOf(this)
  }

  siblingsCount(): number {
    return this.parent?.children.length ?? 0;
  }

}
