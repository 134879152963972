export type FileValidationResponseObject = {
  unsupportedFileType: boolean;
  maxFileSizeExceeded: boolean;
  passwordProtected: boolean;
};

export class FileValidationResponse {
  unsupportedFileType: boolean;
  maxFileSizeExceeded: boolean;

  constructor(fileValidationResponse?: Partial<FileValidationResponseObject>) {
    this.unsupportedFileType = fileValidationResponse?.unsupportedFileType ?? false;
    this.maxFileSizeExceeded = fileValidationResponse?.maxFileSizeExceeded ?? false;
  }

  isValid(): boolean {
    return !this.unsupportedFileType && !this.maxFileSizeExceeded;
  }
}
