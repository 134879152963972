<div class="samples">
    <img id="gene-ai-logo" src="../../../assets/images/GeneAI-Logo.svg" alt="" height="271">
    <div *ngIf="showHeaderMessage" class="header-wrapper">
      <h2 class="greeting">Hello<span id="username">{{ userName }}</span> how can I assist you today?</h2>
      <p *ngIf="showTitle" class="title">{{ showSamplePrompts ? samplePromptTitleText : normalTitleText }}</p>
    </div>
    <div class="chip-wrapper">
      <div
        *ngFor="let sample of samplePrompts"
        class="chip-container">
        <kmd-chips 
        [selected]="selectedChip === sample" 
        chipsText="{{ sample.question }}" 
        chipsType="choice" 
        [chipWidth]="sample.question.length > 25 ? '200px' : 'auto'" 
        [small]="true" 
        (click)="samplePromptSelected(sample)">
        </kmd-chips>
      </div>
    </div>
</div>