import { AgentChat } from "@shared/models/agent-chat";
import { Prompt } from "@shared/models/prompt.model";

export class AgentChatPrompt {
  agentChat: AgentChat;
  prompt: Prompt;

  constructor(agentChat: AgentChat, prompt: Prompt) {
    this.agentChat = agentChat;
    this.prompt = prompt;
  }
};
