<div class="context-menu" [@slide]="getAnimationState()" [ngClass]="containerClasses">
  <ul>
    <li *ngFor="let item of items" (click)="onItemClick(item)" [ngClass]="{'selected-item': item === selectedItem}">
      <div class="label-container">
        <img *ngIf="itemHasIcon(item)" [src]="item.imageUrl">
        <p>{{ item.label }}</p>
      </div>
      <div class="menu-item-description" [ngClass]="{ 'menu-item-with-icon': itemHasIcon(item) }">{{ item.description }}
      </div>
    </li>
  </ul>
</div>
