import { Component, EventEmitter, ViewEncapsulation, Output } from "@angular/core";
import { KmdModalService } from "gds-atom-components";
import { ClipboardService } from "ngx-clipboard";
import { TranslationService } from "src/app/core/services/translation/translation.service";
import { ModelFactory } from "src/app/shared/models/gpt-models/model-factory";
import { PromptTokenRatio } from "@app/prompt/prompttokenratio/prompt-token-ratio";
import { FileUploadOptions, FileUploadType } from "@app/shared/constants/file-manager/file-upload-options";
import { featureFlags } from '@app/environments/environment';
import { Language } from "@services/file-translations/language-manager/language-combinations";

@Component({
  selector: "app-translations",
  templateUrl: "./translations.component.html",
  styleUrls: ["./translations.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class TranslationsComponent {
  @Output() onModalClose = new EventEmitter<void>();
  @Output() onFileTypeSelected: EventEmitter<string> = new EventEmitter<string>();

  @Output() onConfirmLeave = new EventEmitter<void>();
  @Output() onCancelLeave = new EventEmitter<void>();

  refreshEventEmitter = new EventEmitter<void>();

  protected readonly fileUploadOptions = FileUploadOptions;
  protected readonly featureFlags = featureFlags;
  protected readonly document = document;

  readonly TRANSLATIONS_MODEL = "gpt-4-o";
  readonly DEFAULT_LANGUAGE_TEXT = "Select language";

  languageList = [
    "Chinese",
    "Dutch",
    "English",
    "French",
    "German",
    "Italian",
    "Japanese",
    "Korean",
    "Portuguese",
    "Spanish",
  ];

  translationContentType: 'Document' | 'Text' = "Text";
  fileType: string;
  uploadType: FileUploadType = this.fileUploadOptions['TEXT'];
  confirmationHeadline: string = 'Switch to Spreadsheets files?';
  isUploading = false;
  isTranslating = false;
  inputText = "";
  response = "";
  selectedLanguage = "";
  selectedTargetLanguage = "";
  selectedSourceLanguage = "";
  dropdownLabel = this.DEFAULT_LANGUAGE_TEXT;
  showErrorAlert = false;
  errorAlertText = "We encountered an unexpected error. Please try again.";
  isCopied = false;
  alertCopyText = "Prompt successfully added to clipboard."
  confirmationModalId = "confirm-leave-translations-modal"
  uploadInProgressHeadline = "Are you sure you want to stop uploading?";
  translationInProgressHeadline = "Do you want to stop processing?";

  constructor(
      private clipboard: ClipboardService,
      private kmdModalService: KmdModalService,
      private translationService: TranslationService,
      private modelFactory: ModelFactory,
  ) {
    this.fileType = "Text";
  }

  closeModal(): void {
    this.onModalClose.emit();
  };

  setTranslationContentType(type: 'Document' | 'Text') {
    this.translationContentType = type;
  }

  onUploading(isUploading: boolean) {
    this.isUploading = isUploading;
  }

  onTranslating(isTranslating: boolean) {
    this.isTranslating = isTranslating;
  }

  disableTab(){
    return this.isUploading;
  }

  onLanguageChange(event: string) {
    this.selectedLanguage = event;
  }

  sourceLanguageSelected(event: Language) {
    if (event && event.code) {
      this.selectedSourceLanguage = '';
      setTimeout(() => {
        this.selectedSourceLanguage = event.code;
      }, 0);
    } else {
      this.selectedSourceLanguage = '';
    }
  }

  targetLanguageSelected(event: Language) {
    if (event && event.code) {
      this.selectedTargetLanguage = '';
      setTimeout(() => {
        this.selectedTargetLanguage = event.code;
      }, 0);
    } else {
      this.selectedTargetLanguage = '';
    }
  }

  isCopyEnabled() {
    return this.response.length > 0;
  }

  copyResponse() {
    if (this.isCopyEnabled()){
      this.clipboard.copyFromContent(this.response);
      this.isCopied = true;
    }
  }

  translateText() {
    this.kmdModalService.open("loadingResponseModal");
    this.translationService
        .translate(this.inputText, this.selectedLanguage)
        .subscribe({
          next: (translation) => {
            this.response = translation.translatedText;
            this.kmdModalService.close("loadingResponseModal");
          },
          error: () => {
            this.showErrorAlert = true;
            this.kmdModalService.close("loadingResponseModal");
          },
        });
  }

  isTranslateTextEnabled() {
    return this.inputText.length > 0 && this.selectedLanguage.length > 0;
  }

  maxInputCharLimit(): number {
    const gptWordToTokenRatio: number = 3.5;
    const modelTokens = this.modelFactory.create(this.TRANSLATIONS_MODEL).TOKENS;
    return ((modelTokens / 8) * PromptTokenRatio.TRANSLATIONS) * gptWordToTokenRatio;
  }

  isClearTextEnabled(): boolean {
    return this.inputText.length > 0 || this.response.length > 0;
  }

  clearText (): void {
    this.inputText = "";
    this.response = "";
  }

  updateUploadType() {
    this.uploadType = this.fileType === FileUploadOptions['Text'].fileType
        ? this.fileUploadOptions['Text']
        : this.fileUploadOptions['Document'];
  }

  setConfirmationHeadline(fileType: string) {
    this.confirmationHeadline = `Switch to ${fileType === 'Text' ? 'Document' : 'Text'} files?`;
  }

  resetTextButton() {
    this.setFileType('Text');
  }

  setFileType(fileType: string) {
    this.fileType = fileType;
    this.onFileTypeSelected.emit(fileType);
    this.updateUploadType();
    this.setConfirmationHeadline(fileType);
  }

  handleCancelLeave() {
    this.kmdModalService.close(this.confirmationModalId);
    this.onCancelLeave.emit();
  }

  handleConfirmLeave() {
    this.kmdModalService.close(this.confirmationModalId);
    this.onConfirmLeave.emit();
  }

  openConfirmationModal(){
    this.kmdModalService.open(this.confirmationModalId);
  }

  showConfirmationModal() {
  return this.featureFlags.documentTab && (this.isUploading || this.isTranslating);
}

  getConfirmationModalHeadline(){
    if (this.isUploading){
      return this.uploadInProgressHeadline;
    }
      return this.translationInProgressHeadline;
  }

  onFileUploadErrorAlert(event: any) {
    this.showErrorAlert = event.showError;
    this.errorAlertText = event.errorMessage;
  }

  refreshTranslatedList (event  : boolean) {
    if (event) {
        this.refreshEventEmitter.emit();
    }
  }

}
