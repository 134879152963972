import { Observable, of } from 'rxjs';
import { GuardChecker } from './guard-checker-interface';
import { AskOurDataRoleGuard } from "../guards/ask-our-data-role.guard";
import { featureFlags } from "@environments/environment";

export class AskOurDataRoleGuardChecker implements GuardChecker {
  check(guard: AskOurDataRoleGuard): Observable<boolean> {
    if(featureFlags.askOurData) {
      return guard.hasAskOurDataRoles();
    } else {
      return of(false);
    }
  }
}
