import { Inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AgentChat } from "../../../shared/models/agent-chat";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AgentChatsService {

  constructor(@Inject('BASE_API_URL') private baseUrl: string, private http: HttpClient) {
  }

  getAgentChats(): Observable<AgentChat[]> {
    return this.http.get<AgentChat[]>(`${this.baseUrl}/v1/agents/chats`, this.getHeaders()).pipe(
      map(chats => {
        return chats.map(agentChat => {
          agentChat.chat.created = new Date(agentChat.chat.created)
          return agentChat
        })
      }
    ));
  }

  updateAgentChat(agentChat: AgentChat): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/v1/agents/${agentChat.agentId}/chats/${agentChat.chat.id}`,
      agentChat.chat, this.getHeaders());
  }

  deleteAgentChat(agentChat: AgentChat): Observable<any> {
    let url = agentChat.agentId == null
      ? `${this.baseUrl}/v1/agents/chats/${agentChat.chat.id}`
      : `${this.baseUrl}/v1/agents/${agentChat.agentId}/chats/${agentChat.chat.id}`;

    return this.http.delete(url, this.getHeaders());
  }

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }

}
