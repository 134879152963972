import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Agent } from "@shared/models/agent";
import { ModalService } from "@services/modal/modal.service";

@Component({
    selector: 'app-agent-sharing-keep-private-confirmation-modal',
    templateUrl: './agent-sharing-keep-private-confirmation-modal.component.html',
    styleUrls: ['./agent-sharing-keep-private-confirmation-modal.component.css']
})
export class AgentSharingKeepPrivateConfirmationModalComponent {
    @Input() agent?: Agent;
    @Input() isUserOwner: boolean = false;
    @Input() isOwnerMissing: boolean = false;
    @Input() isOwnerOutOfGroup: boolean = false;

    @Output() onAcceptEvent: EventEmitter<void> = new EventEmitter<void>();

    readonly id = 'agent-sharing-keep-private-confirmation-modal';

    constructor(
        private modalService: ModalService
    ) { }

    get owner(): string {
        return this.agent?.admins?.find(admin => admin.role === 'owner')?.email || 'N/A';
    };

    open() {
        this.modalService.open(this.id);
    };

    close() {
        this.modalService.close(this.id);
    };

    accept() {
        this.onAcceptEvent.emit();
    };
}
