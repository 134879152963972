<div class="translate-files-container" *ngIf="translateFiles.length" #translateFilesContainer>
    <div class="file-translate-list">
        <ng-container *ngFor="let data of translateFiles">
            <div class="file-translate-summary" [ngClass]="getTranslatingFileStatus(data)">
                <div class="file-translate-details-wrapper">
                    <div class="file-translate-details">
                        <ng-container *ngIf="isTranslatingStatusInProgress(data.status); else translatingOrErrorStatus">
                            <div class="translate-progress loading-wheel">
                                <kmd-loading-wheel></kmd-loading-wheel>
                            </div>
                        </ng-container>
                        <ng-template #translatingOrErrorStatus>
                            <div [class]="getFileStatusClass(data.status!)">
                                <i></i>
                            </div>
                        </ng-template>
                        <div class="labels">
                            <p class="file-title">{{ data.file!.name }}</p>
                            <p class="file-title mobile">{{ getMobileDisplayName(data.file!.name) }}</p>
                        </div>
                        <div class="cancel translate-control mobile" (click)="showClearFileTranslateModal(data)">
                            <i></i>
                        </div>
                    </div>
                    <div class="file-translate-controls">
                        <div class="file-status translating"
                             *ngIf="data.status === processingStatus.TRANSLATING">
                            <label>Translating</label>
                        </div>
                        <div class="file-status translated"
                             *ngIf="data.status === processingStatus.TRANSLATED">
                            <label>Translated</label>
                        </div>
                        <div class="file-status error translate-error"
                             *ngIf="data.status === processingStatus.TRANSLATING_ERROR">
                            <label>Translating error</label>
                        </div>
                        <div class="cancel translate-control" (click)="showClearFileTranslateModal(data)">
                            <i></i>

                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<kmd-modal [id]="'clear-file-translate-modal'" modalSize="mini" headline="Do you want to stop translating?">
  <div id="text-acknowledge">
    <p>You're about to stop the translating. This action cannot be undone.</p>
  </div>
  <b>
    Do you want to proceed?
  </b>
  <div class="modal-buttons-container">
    <button id="stay-btn" (click)="closeClearFileTranslateModal()" kmdInfoButton>No</button>
    <button id='switch-btn' (click)="removeTranslatingFile()" kmdFeaturedButton>Yes</button>
  </div>
</kmd-modal>
