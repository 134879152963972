import { Component, EventEmitter, Output } from '@angular/core';
import { Prompt } from '@app/shared/models/prompt.model';

@Component({
  selector: 'app-saved-prompts',
  templateUrl: './saved-prompts.component.html',
  styleUrls: ['./saved-prompts.component.css']
})
export class SavedPromptsComponent {
  @Output() promptSelectEvent = new EventEmitter<Prompt>();
  @Output() backToChatEvent = new EventEmitter<boolean>();

  promptSelected(event: any): void {
    this.promptSelectEvent.emit(event);
  }

  showChat(event: any): void {
    event?.preventDefault();
    this.backToChatEvent.emit(true);
  }
}
