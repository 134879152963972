import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from "@angular/core";
import { featureFlags } from "@app/environments/environment";
import { FileManagerMyDocsComponent } from "../my-docs/file-manager-my-docs.component";
import { ModalService } from "@services/modal/modal.service";
import {
  FileManagerConfirmationModalComponent
} from "@shared/components/file-manager/modal/file-manager-confirmation-modal/file-manager-confirmation-modal.component";
import { FileUploadOptions, FileUploadType } from "@app/shared/constants/file-manager/file-upload-options";
import { FileManagerUpload } from "@app/shared/models/file-manager/file-manager-upload.model";
import { FileManagerService } from "@app/core/services/file-manager/file-manager.service";
import { ChipTypeConstant } from "@app/shared/constants/chip/chip-type";
import { catchError, debounceTime, distinctUntilChanged, Subject, take, tap, throwError } from "rxjs";
import { AlertService } from "@app/core/services/alert/alert.service";
import {
  FileManagerAcknowledgementModalComponent
} from "./file-manager-acknowledgement-modal/file-manager-acknowledgement-modal.component";
import { WindowRef } from "@app/core/services/window/window-ref.service";
import { defaultFileManagerConfig, FileManagerConfig } from "../file-manager";
import { FileManagerMyDocsBetaComponent } from "../my-docs-beta/file-manager-my-docs-beta.component";

@Component({
  selector: 'app-file-manager-modal',
  templateUrl: './file-manager-modal.component.html',
  styleUrls: ['./file-manager-modal.component.css']
})
export class FileManagerModalComponent implements OnInit {
  @Output() onModalClose = new EventEmitter<void>();
  @Output() onFileTypeSelected: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('myDocsComponent') myDocsComponent!: FileManagerMyDocsComponent | FileManagerMyDocsBetaComponent;
  @ViewChild(FileManagerConfirmationModalComponent) confirmationModal!: FileManagerConfirmationModalComponent;
  @ViewChild(FileManagerAcknowledgementModalComponent) acknowledgementModal!: FileManagerAcknowledgementModalComponent;

  protected readonly featureFlags = featureFlags;
  protected readonly fileUploadOptions = FileUploadOptions;
  readonly DEBOUNCE_TIME = 300;
  protected fileManagerConfig: FileManagerConfig = defaultFileManagerConfig;

  selectedFileType: FileUploadType = FileUploadOptions['TEXT'];
  betaBadgeDetails = ChipTypeConstant.BETA;

  isUploading = false;
  isMobile: boolean = false;
  isTablet: boolean = false;

  searchTerm$ = new Subject<string>();
  searchTerm: string = '';
  search: string = '';
  targetFileType: string = '';

  constructor(
    private modalService: ModalService,
    private fileManagerService: FileManagerService,
    private alertService: AlertService,
  ) {
    this.initDocumentSearch();
    this.setIsMobileOrTablet();
  }

  ngOnInit() {
    this.fileManagerService.selectedFileType$
      .subscribe({
        next: (fileType) => {
          this.selectedFileType = fileType
        }
      });
      this.fileManagerConfig = this.fileManagerService.fileManagerConfig;
  }

  @HostListener('window:resize')
  onResize() {
    this.setIsMobileOrTablet();
  }

  private setIsMobileOrTablet() {
    this.isMobile = WindowRef.innerWidth <= 430;
    this.isTablet = WindowRef.innerWidth > 430 && WindowRef.innerWidth <= 768;
  }

  isMobileOrTablet(): boolean {
    return this.isMobile || this.isTablet;
  }

  initFileFetch(): void {
    const interval = setInterval(() => {
      if (this.myDocsComponent) {
        this.myDocsComponent.initFileFetch();
        clearInterval(interval);
      }
    }, 100);
  }

  closeModal(): void {
    this.onModalClose.emit();
    this.resetSearchTerm();
    this.myDocsComponent.resetComponentFilesState();
  };

  resetOffset(): void {
    this.myDocsComponent.refreshTable();
  }

  isFileTypeSelected(fileType: string) {
    return this.selectedFileType.fileType === fileType;
  }

  setSelectedFileType(fileUploadType: FileUploadType) {
    if (this.selectedFileType === fileUploadType || this.isChangingTabsDisabled()) {
      return;
    }

    if (fileUploadType.fileType === 'text' || fileUploadType.fileType === 'image') {
      return this.handleFileTypeSelection(fileUploadType);
    }

    this.fileManagerService.isSpreadsheetUsageAcknowledged()
      .pipe(
        take(1),
        catchError(() => {
          this.alertService.showError('Failed to fetch spreadsheet usage acknowledgement.');
          return throwError(() => {
          });
        }),
        tap((isUsageAcknowledged) => {
          if (isUsageAcknowledged) {
            return this.handleFileTypeSelection(fileUploadType);
          }

          this.modalService.open(this.acknowledgementModal.modalId);
        })
      )
      .subscribe();
  }

  handleFileTypeSelection(newFileUploadType: FileUploadType): void {
    if (this.myDocsComponent.selectedFiles.length > 0) {
      this.confirmationModal.currentFileType = this.selectedFileType.fileType;
      this.confirmationModal.newFileType = newFileUploadType.fileType;
      this.targetFileType = newFileUploadType.fileType;
      this.modalService.open(this.confirmationModal.confirmationModalId);
    } else {
      this.setFileType(newFileUploadType);
    }
  };

  setFileType(fileUploadType: FileUploadType) {
    this.onFileTypeSelected.emit(fileUploadType.fileType);
    this.fileManagerService.setSelectedFileType(fileUploadType);
    this.resetSearchTerm();
  }

  getConfirmationModalHeadline() {
    let fileType;
    switch (this.targetFileType) {
      case 'text':
        fileType = 'Text';
        break;
      case 'image':
        fileType = 'Image';
        break;
      case 'spreadsheets':
        fileType = 'Spreadsheets';
        break;
    }
    return  `Switch to ${fileType} files?`;
  }

  onConfirmConfirmationModal(switchToFileType: string) {
    this.setFileType(this.fileUploadOptions[switchToFileType.toUpperCase()]);
    this.closeConfirmationModal();
  }

  onCancelConfirmationModal() {
    this.closeConfirmationModal();
    if (this.selectedFileType.fileType === 'text') {
      this.resetTextButton();
    }
    if (this.selectedFileType.fileType === 'spreadsheets') {
      this.resetSpreadsheetsButton();
    }
    if (this.selectedFileType.fileType === 'image') {
      this.resetImagesButton();
    }
  }

  resetTextButton() {
    this.setFileType(this.fileUploadOptions['TEXT']);
  }

  resetSpreadsheetsButton() {
    this.setFileType(this.fileUploadOptions['SPREADSHEETS']);
  }

  resetImagesButton() {
    this.setFileType(this.fileUploadOptions['IMAGE']);
  }

  closeConfirmationModal() {
    this.modalService.close(this.confirmationModal.confirmationModalId);
  }

  onFileUploaded(event: FileManagerUpload) {
    this.myDocsComponent.addFile(event);
  }

  onUploadingInProgress(isUploading: boolean) {
    this.isUploading = isUploading;
  }

  closeAcknowledgementModal(): void {
    this.modalService.close(this.acknowledgementModal.modalId);
  }

  onAcknowledgementAccepted() {
    this.fileManagerService.acknowledgeSpreadsheetUsage()
      .subscribe({
        next: () => {
          this.closeAcknowledgementModal();
          this.handleFileTypeSelection(this.fileUploadOptions['SPREADSHEETS']);
        },
        error: () => {
          this.alertService.showError('Failed to acknowledge spreadsheet usage.');
          this.acknowledgementModal.processingRequest = false;
        }
      })
  }

  onSearchDocument(event: any) {
    this.searchTerm$.next(event.target.value);
  }

  initDocumentSearch() {
    this.searchTerm$.pipe(
      debounceTime(this.DEBOUNCE_TIME),
      distinctUntilChanged()
    ).subscribe(search => {
      this.searchTerm = search;
    });
  }

  resetSearchTerm() {
    this.searchTerm = '';
    this.search = '';
  }

  isChangingTabsDisabled(): boolean {
    return this.isUploading || (this.fileManagerService.fixedFiles$ && this.fileManagerService.fixedFiles$.value.length > 0);
  }
}
