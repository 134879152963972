import { createFileManagerDoc, FileManagerDoc } from "@app/shared/models/file-manager/file-manager-doc.model";
import { FileNameFormatOption } from "../file-manager.service";
import { FileUpload } from "@app/shared/models/file-upload.model";

export function fileUploadToFileManagerDoc(file: FileUpload, maxFileNameLength: number): FileManagerDoc {
  return createFileManagerDoc(
    file,
    maxFileNameLength,
    parseFileNameToDisplayName.bind(fileUploadToFileManagerDoc),
    parseShortFileDisplayName.bind(fileUploadToFileManagerDoc),
    getReadableFileSize.bind(fileUploadToFileManagerDoc)
  );
}

export function parseFileNameToDisplayName(name: string, maxFileNameLength: number, formatOption: FileNameFormatOption = FileNameFormatOption.WITH_EXTENSION): string {
  const fileNameParts = name.split('.');
  const extension = fileNameParts.length > 1 ? fileNameParts.pop() : '';
  const fileNameWithoutExtension = fileNameParts.join('.');

  if (formatOption === FileNameFormatOption.WITHOUT_EXTENSION) {
    name = fileNameWithoutExtension;
  }

  if (name.length <= maxFileNameLength) {
    return name;
  }

  const firstPart = name.substring(0, maxFileNameLength - 10);
  const last3Chars = fileNameWithoutExtension.substring(fileNameWithoutExtension.length - 3);

  return formatOption === FileNameFormatOption.WITH_EXTENSION
    ? `${firstPart}(...)${last3Chars}.${extension}`
    : `${firstPart}(...)${last3Chars}`;
};

export function parseShortFileDisplayName(name: string): string {
  const maxFileNameLength = 15;
  if (name.length <= maxFileNameLength) {
    return name;
  }

  const fileNameParts = name.split('.');
  const extension = fileNameParts[fileNameParts.length - 1];
  return `${fileNameParts[0].substring(0, maxFileNameLength - extension.length)}...${extension}`;
};

export function getReadableFileSize(size?: number): string {
  if (!size) {
    return "N/A"
  }

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  let index = 0;

  while (size! >= 1000 && index < units.length - 1) {
    size! /= 1000;
    index++;
  }

  return `${size!.toFixed(2)}${units[index]}`;
};

export function getFileExtension(fileName: string): string {
  let extension = fileName.lastIndexOf(".");
  return extension === -1 ? "" : fileName.substring(extension);
};

