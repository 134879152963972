<div class="agent-edit-toolbar">
  <div class="top-container">
    <a class="back-to-agents" routerLink="/agents">
      <img src="../assets/icons/arrows/arrowLeft.svg"><span>Agents</span>
    </a>
    <div class="action-buttons-top">
      <button id="chat-with-agent-button" class="chat-agent-btn" kmdPrimaryButton (click)="navigateToChatWithAgent()">
        <span>Chat with agent</span>
      </button>
      <button id="save-button" kmdFeaturedButton [disabled]="!validForm" (click)="submit()">
        <i [ngClass]="validForm  ? 'save-agent-ic' : 'save-agent-ic-disabled'"></i>
        <span>Save Agent</span>
      </button>
    </div>
  </div>
  <div class="bottom-container">
    <h1>Edit "{{ agent?.name?.trim() }}"</h1>
  </div>
  <kmd-alerts kmd-success
              id="success-alert"
              [(show)]="success"
              [icon]="true"
              [alertText]="'Changes applied.'"
              [floating]="true"></kmd-alerts>

  <kmd-alerts kmd-error id="error-alert"
              [(show)]="error"
              [alertText]="errorText"
              [dismissible]="true"
              [floating]="true"></kmd-alerts>
</div>

<div class="agent-edit-form">
  <div class="agent-parent-container">
    <div id="agent-edit-form-container">
      <div id="agent-edit-title-bar">
        <h4 *ngIf="showAgentEditForm">Configure Agent</h4>
        <button kmdPrimaryButton id="agent-example-button" *ngIf="showExampleButton" (click)="toggleAgentExample()">Show
          me an example
        </button>
      </div>
      <app-agent-form #agentForm [hidden]="!showAgentEditForm"
                      [agent]="agent"
                      (formStatusChanged)="formStatusChanged($event)"
                      (onSubmitValidForm)="saveAgent($event)"></app-agent-form>
    </div>

    <div class="agent-example" [hidden]="!showExampleAgent">
      <app-agent-example (closeAgentExample)="toggleAgentExample()"></app-agent-example>
    </div>
  </div>

  <div class="action-buttons-bottom">
    <button class="cancel-btn" kmdPrimaryButton (click)="navigateToAgents()">
      <span>Cancel</span>
    </button>
  </div>
  <div class="action-buttons-bottom mobile">
    <div class="top-section">
      <button id="save-button" kmdFeaturedButton [disabled]="!validForm" (click)="submit()">
        <i [ngClass]="validForm  ? 'save-agent-ic' : 'save-agent-ic-disabled'"></i>
        <span>Save Agent</span>
      </button>
      <button class="cancel-btn" kmdPrimaryButton (click)="navigateToAgents()">
        <span>Cancel</span>
      </button>
    </div>
    <div class="bottom-section">
      <button id="chat-with-agent-button" class="chat-agent-btn" kmdPrimaryButton (click)="navigateToChatWithAgent()">
        <span>Chat with agent</span>
      </button>
    </div>
  </div>
</div>

<kmd-modal [id]="'cancel-edit-modal'" id="cancel-edit-modal" modalSize="mini" headline="Warning">
  <div id="text">
    You'll lose any progress made in editing your agent.
  </div>
  <div class="modal-buttons-container">
    <button id="discard-changes-btn" (click)="discardChanges()" kmdInfoButton>Discard and exit</button>
    <button id='continue-editing-btn' (click)="continueEditing()" kmdFeaturedButton>Continue editing</button>
  </div>
</kmd-modal>


