<div class="card-container">
  <div class="card-title-section">
    <h3><a (click)="redirectToUrl()">{{ title }}</a></h3>

  </div>

  <ng-container *featureFlag="'askOurDocsUserManagementPage'">
    <button class="managment-settings-btn" *ngIf="isUserAdmin" (click)="redirectToManagment()">
      <img src="../../../../assets/icons/settings/settings.svg" alt="">
    </button>
  </ng-container>
  <core-button class="card-chat-button normal" label="{{ buttonLabel }}" type="button" variant="outline" size="small"
    iconclass="icon-16-download-mono" (click)="redirectToUrl()" />
  <div class="admins-section" *ngIf="featureFlags.askOurDocsAdminList && hasRepositoryOwners()">Admins:
    {{repositoryOwners}}</div>
</div>