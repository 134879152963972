<kmd-popover #popOver id="copy-response-popover" position='right' popoverPosition='bottom'>
  <div class="copy-response">
    <div class="clipboard" (click)="copyToClipboard()">
      <fa-icon [icon]="faClipboard"></fa-icon>
      <span class="text">Copy to Clipboard</span>
    </div>
    <div class="prompt-input" (click)="copyToPromptInput()">
      <fa-icon [icon]="faComment"></fa-icon>
      <span class="text">Copy to Prompt</span>
    </div>
  </div>
</kmd-popover>
