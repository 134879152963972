import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-caret-icon',
    templateUrl: './caret-icon.component.html',
    styleUrls: ['./caret-icon.component.css']
})
export class CaretIconComponent {
    @Input() width!: string;
    @Input() height!: string;
    @Input() color!: string;

    @Input() type!: 'dropdown' | 'sort';

    @Input() isOpen?: boolean = false;
};