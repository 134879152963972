<div class="alerts-container">
  <kmd-alerts kmd-success [(show)]="showSuccessAlert" [alertText]="alertText" [icon]="true"
              [floating]="true"></kmd-alerts>
  <kmd-alerts kmd-error [(show)]="showErrorAlert" [icon]="true" [floating]="true">{{alertText}}</kmd-alerts>
</div>

<div class="paged-public-prompts">
  <div class="loading-wheel" *ngIf="isLoading">
    <kmd-loading-wheel></kmd-loading-wheel>
  </div>
  <div class="empty-public-prompts-message" *ngIf="emptyPublicPrompts">
    <h2>There are no public prompts yet</h2>
    <span>Share valuable prompts to assist your peers in their day-to-day tasks.</span>
  </div>

  <div class="public-categories-dropdown" *ngIf="!isLoading && categories.length > 0">
    <label kmdFormLabel>Category</label>
    <kmd-dropdown [(ngModel)]="selectedCategory" [options]="categories" [externalLabel]="true"
                  (onChange)="onCategoryChange($event)"></kmd-dropdown>
  </div>

  <div class="public-prompts-table">
    <table class="prompt-list-table" *ngIf="!isLoading && categories.length > 0">
      <thead>
      <tr>
        <th *ngFor="let headerTitle of promptTableHeaders">{{ headerTitle }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let prompt of pagedPublicPrompts.content">
        <td>
              <span (click)="openModal(prompt, 'preview-prompt-modal')" class="prompt-title clickable">
                {{ prompt.title }}
              </span>
        </td>
        <td>{{ getUserFriendlyModelName(prompt.promptSettings.model) }}</td>
        <td>{{ getUserFriendlyWebSearchConfig(prompt.promptSettings.webSearch) }}</td>
        <td>
              <span class="preview-prompt-button clickable"
                    (click)="openModal(prompt, 'preview-prompt-modal')">
                <img src="assets/icons/view on/Size=24px, Style=Mono.svg" alt="">
              </span>
        </td>
        <td>
          <span class="clickable" (click)="onDelete(prompt,'delete-prompt-modal');" *ngIf="canApprove$ | async">
            <img src="assets/icons/bin/size=24px, style=mono.svg" alt="">
          </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="pagination">
    <kmd-pagination #pagination [totalResults]="pagedPublicPrompts.totalElements" [resultsPerPageOptions]="[15, 20, 25]"
                    (onPageChange)="onPageChange($event)"></kmd-pagination>
  </div>
</div>

<kmd-modal [id]="'preview-prompt-modal'" headline="Preview prompt" id="preview-prompt-modal">
  <div *ngIf="selectedPrompt">
    <div class="preview-prompt-text">
      {{ selectedPrompt.text }}
    </div>
    <div class="modal-buttons-container">
      <core-button label="Cancel" type="button" variant="information" size="medium"
                   (click)="closeModal('preview-prompt-modal')">
      </core-button>
      <core-button label="Use prompt" type="button" variant="primary" size="medium" (click)="onSelect(selectedPrompt)">
      </core-button>
    </div>
  </div>
</kmd-modal>

<kmd-modal [id]="'delete-prompt-modal'" modalSize="mini" headline="Delete prompt">
  <div>
    You're about to delete this prompt, this action is permanent and you won't be able to recover it. Do you want to
    delete it?
  </div>
  <div class="modal-buttons-container">
    <button (click)="closeModal('delete-prompt-modal')" kmdSecondaryButton>Cancel</button>
    <button (click)="deletePrompt(selectedPrompt!)" kmdFeaturedButton>Delete</button>
  </div>
</kmd-modal>

