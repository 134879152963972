<app-menu-option-new
  [menuOption]="menuOption"
  [state]="state"
  (onNavigation)="handleMenuClick()">
</app-menu-option-new>
<div [ngClass]="{ 'menu-chat-history': true, 'collapsed': state === 'collapsed' }">
  <div *ngIf="(chats$ | async) as chats">
    <div *ngFor="let chat of (chats.content ?? [])" (click)="handleChatClick(chat)"
         [ngClass]="{'active': isActiveChat(chat)}">
      <div class="chat">{{chat.title}}</div>
    </div>
    <div *ngIf="(chats.content?.length ?? 0) > 0" class="see-all-history" (click)="handleSeeAllClick()">
      <a>See all history</a>
    </div>
  </div>
</div>
