import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { AgentCategoryFilters, SortOptionsByType } from "@shared/models/agent";
import { debounceTime, distinctUntilChanged } from "rxjs";

@Component({
  selector: 'app-agent-filters',
  templateUrl: './agent-filters.component.html',
  styleUrl: './agent-filters.component.css'
})
export class AgentFiltersComponent implements OnInit {

  @Input() agentType!: 'public' | 'private' | 'shared';

  @Output() searchByAgentNameChange = new EventEmitter<string>();
  @Output() categoryChange = new EventEmitter<string>();
  @Output() sortByChange = new EventEmitter<string>();

  readonly SEARCH_DEBOUNCE_TIME_MS = 500;
  readonly DEFAULT_CATEGORY_INDEX = 0;
  readonly DEFAULT_SORT_INDEX = 0;

  categories = AgentCategoryFilters.slice();
  sortOptions: string[] = [];

  selectedCategory = this.categories[this.DEFAULT_CATEGORY_INDEX];
  selectedSortBy = '';

  filtersForm!: FormGroup;

  get searchByAgentName() {
    return this.filtersForm.get('searchByAgentName')!;
  }

  get category() {
    return this.filtersForm.get('category')!;
  }

  get sortBy() {
    return this.filtersForm.get('sortBy')!;
  }

  ngOnInit() {
    this.sortOptions = SortOptionsByType[this.agentType].slice();
    this.selectedSortBy = this.sortOptions[this.DEFAULT_SORT_INDEX];

    this.filtersForm = new FormGroup({
      searchByAgentName: new FormControl('', { nonNullable: true }),
      category: new FormControl(this.selectedCategory, { nonNullable: true }),
      sortBy: new FormControl(this.selectedSortBy, { nonNullable: true }),
    });

    this.searchByAgentName.valueChanges
      .pipe(
        debounceTime(this.SEARCH_DEBOUNCE_TIME_MS),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.resetCategory();
        this.searchByAgentNameChange.emit(value);
      });

    this.category.valueChanges.subscribe((value) => {
      this.categoryChange.emit(value);
    });

    this.sortBy.valueChanges.subscribe((value) => {
      this.sortByChange.emit(value);
    });
  };

  private resetCategory() {
    this.category.patchValue(this.categories[this.DEFAULT_CATEGORY_INDEX], { emitEvent: false });
  };
}
