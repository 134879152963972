import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ChatQAService } from "@app/core/services/chat-qa/chat-qa.service";
import { Prompt } from "@app/shared/models/prompt.model";
import { PromptSettings } from "@shared/models/prompt-settings.model";
import { PromptSubmitEvent } from "@shared/models/prompt-submit-event";
import { defaultPromptInputConfig, PromptInputConfig } from "../prompt-input-new/prompt-input-new";
import { PromptInputNewComponent } from "../prompt-input-new/prompt-input-new.component";
import { PromptSettingsNewComponent } from "../prompt-settings-new/prompt-settings-new.component";
import { FileManagerService } from '../../../core/services/file-manager/file-manager.service';

@Component({
  selector: "app-chat-wrapper",
  templateUrl: "./chat-wrapper.component.html",
  styleUrls: ["./chat-wrapper.component.css"]
})
export class ChatWrapperComponent {
  @ViewChild(PromptSettingsNewComponent) promptSettingsComponent!: PromptSettingsNewComponent;
  @ViewChild(PromptInputNewComponent) promptInputNewComponent!: PromptInputNewComponent;

  @Input() contentPosition: "middle" | "bottom" | "top" = "middle";
  @Input() isStreamingResponse!: boolean;
  @Input() savedPromptsSelectedEvent!: void;
  private _promptInputConfig: PromptInputConfig = defaultPromptInputConfig;

  @Input()
  get promptInputConfig(): PromptInputConfig {
    return this._promptInputConfig;
  }

  set promptInputConfig(value: PromptInputConfig) {
    this._promptInputConfig = { ...defaultPromptInputConfig, ...value };
    if (this.fileManagerService) {
      this.fileManagerService.fileManagerConfig = this._promptInputConfig.fileManagerConfig;
    }
  }

  @Output() promptSubmit = new EventEmitter<PromptSubmitEvent>();
  @Output() stopStreaming = new EventEmitter<any>();

  promptSettings: PromptSettings;
  savedPrompts = false;
  showXLSUnsupportedNotification = false;

  constructor(
    private chatQAService: ChatQAService,
    private cdRef: ChangeDetectorRef,
    private fileManagerService: FileManagerService
  ) {
    this.promptSettings = this.chatQAService.promptSettings;
    this.fileManagerService.fileManagerConfig = this.promptInputConfig.fileManagerConfig;
  }

  openPromptSettings(): void {
    this.promptSettingsComponent.show();
  };

  setPromptText(text: string): void {
    this.promptInputNewComponent.setPromptInInput(text);
  };

  promptSelected(prompt: Prompt): void {
    this.savedPrompts = false;
    this.contentPosition = "bottom";
    this.cdRef.detectChanges();
    this.promptInputNewComponent.setPromptInInput(prompt.text);
  }

  showChat(): void {
    this.savedPrompts = false;
    this.cdRef.detectChanges();
  }

  showSavedPrompts(): void {
    this.savedPrompts = true;
    this.contentPosition = "middle";
    this.cdRef.detectChanges();
  }

  handleUnsupportedFileEvent(extension: string): void {
    if (extension === ".xls") {
      this.showXLSUnsupportedNotification = true;
    }
  };
}
