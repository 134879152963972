import { Injectable } from "@angular/core";
import {
  BehaviorSubject, catchError, delay,
  forkJoin,
  map, mergeMap,
  Observable,
  of, Subject, takeUntil,
  tap, throwError,
} from "rxjs";
import {
  FileTypes,
  FileUploadOptions,
  FileUploadType
} from "@shared/constants/file-manager/file-upload-options";
import {
  TranslatedDocumentsListService
} from "@services/file-translations/list-service/translated-documents-list.service";
import { HttpEvent, HttpEventType, HttpResponse } from "@angular/common/http";
import { TranslationsFileService } from "@services/file-translations/file-service/translations.file.service";
import { S3UploadService } from "@services/files/s3-upload.service";
import { FileTranslation } from "@shared/models/file-translation.model";
import {
  FileTranslationsManagerUploadStatus
} from "@shared/models/file-translations-manager/file-translations-manager-upload-status.model";
import {
  FileTranslationsManagerUpload
} from "@shared/models/file-translations-manager/file-translations-manager-upload.model";
import {
  FileTranslationsUploadType,
  FileUploadErrorMessages
} from "@shared/constants/file-translations/file-translations-upload-options";
import { FileTranslationsUploadStatus } from "@shared/constants/file-translations/file-translations-upload-status";
import { FileTranslationsUpload } from "@shared/models/file-translations-upload.model";
import { getReadableFileSize, parseFileNameToDisplayName } from "@services/file-manager/utils/file-utils";
import { FileNameFormatOption } from "@services/file-manager/file-manager.service";
import {
  FileTranslationsProcessingStatus
} from "@shared/constants/file-translations/file-translations-processing-status";
import {
  FileTranslationsManagerTranslateStatus
} from "@shared/models/file-translations-manager/file-translations-manager-translate-status.model";
import {
  FileTranslationsManagerTranslate
} from "@shared/models/file-translations-manager/file-translations-manager-translate.model";
import {FileTranslationsTranslateStatus} from "@shared/constants/file-translations/file-translations-translate-status";
import { finalize } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FileTranslationsManagerService {

  allFiles$ = new BehaviorSubject<FileTranslation[]>([]);
  selectedFiles$ = new BehaviorSubject<FileTranslation[]>([]);

  readonly fileStatusIds = {
    translated: new Array<string>(),
    error: new Array<string>(),
    uploaded: new Array<string>(),
  };

  // Map to store cancellation subjects by fileId
  private fileUploadCancellations = new Map<string, Subject<boolean>>();

  constructor(
    private translatedDocumentsListService: TranslatedDocumentsListService,
    private translationsFileService: TranslationsFileService,
    private s3UploadService: S3UploadService,
  ) {
  }

  getLazyFiles$(fileName: string, offset: number = 0, sortBy: string = "translated", sortDirection: string = "DESC"): Observable<FileTranslation[]> {
    if (offset === 0) {
      this.allFiles$.next([]);
    }

    return this.translatedDocumentsListService.lazyList(fileName, offset, sortBy, sortDirection)
      .pipe(
        tap((files) => {
          if (offset === 0) {
            this.allFiles$.next(files);
          } else {
            this.allFiles$.next(this.allFiles$.value.concat(files));
          }
        })
      );
  };

  getFiles$(excludeStatuses: string[] = []): Observable<FileTranslation[]> {
    return this.translatedDocumentsListService.list(excludeStatuses).pipe(
      tap((files) => this.allFiles$.next(files))
    );
  }

  getFiles(fileIds: string[]): Observable<FileTranslation[]> {
    if (!fileIds.length) {
      return of([]);
    }

    const fileObservables = fileIds.map((id) => {
      return this.translatedDocumentsListService.get(id)
    });

    return forkJoin(fileObservables).pipe(
      map((files) => files.filter((file): file is FileTranslation => file !== null)),
      tap((files) => this.setSelectedFiles(files))
    );
  }

  setSelectedFiles(files: FileTranslation[]): void {
    files.forEach((file) => {
      if (!this.fileStatusIds.error.includes(file.id!)) {
        this.fileStatusIds.translated.push(file.id!);
      }
    });
    this.selectedFiles$.next(files);
  };

  addSelectedFile(file: FileTranslation): void {
    const selectedFiles = this.selectedFiles$.value;
    if (selectedFiles.some((f) => f.id === file.id)) {
      return;
    }
    selectedFiles.push(file);
    this.selectedFiles$.next(selectedFiles);
    this.updateAllFiles(file);
  };

  private updateAllFiles(file: FileTranslation) {
    const allFiles = this.allFiles$.value;
    if (allFiles.some((f) => f.id === file.id)) {
      const fileIndex = allFiles.findIndex((f) => f.id === file.id);
      allFiles[fileIndex].isSelected = true;
    } else {
      allFiles.unshift(file);
    }
    this.allFiles$.next(allFiles);
  }

  removeSelectedFile(file: FileTranslation): void {
    const files = this.selectedFiles$.value.filter((f) => f.id !== file.id);
    this.selectedFiles$.next(files);
  };

  clearSelectedFiles(): void {
    this.selectedFiles$.next([]);
  };

  getCategoryForFileType(fileType: string): FileUploadType {
    if (!fileType)
      throw new Error('Invalid file type');

    for (const category in FileTypes) {
      if (Object.values(FileTypes[category]).includes(fileType.toUpperCase())) {
        return FileUploadOptions[category];
      }
    }

    throw new Error(`File type ${fileType} not found`);
  }

  // Method to get or create a cancellation subject for a file
  private getCancellationSubject(fileId: string): Subject<boolean> {
    if (!this.fileUploadCancellations.has(fileId)) {
      this.fileUploadCancellations.set(fileId, new Subject<boolean>());
    }
    return this.fileUploadCancellations.get(fileId)!;
  }

  // Method to cancel an upload and clean up the subject
  cancelUpload(fileId: string): void {
    const subject = this.fileUploadCancellations.get(fileId);
    if (subject) {
      subject.next(true);
      subject.complete();
      this.fileUploadCancellations.delete(fileId);
    }
  }

  // Public method to clean up resources - can be called by components when they're destroyed
  public cleanupCancellations(): void {
    this.fileUploadCancellations.forEach((subject) => {
      subject.next(true);
      subject.complete();
    });
    this.fileUploadCancellations.clear();
  }

  uploadFiles(files: File[], uploadType: FileTranslationsUploadType, sourceLanguage: string,
              targetLanguage: string, handleError?: (message?: string) => void): FileTranslationsManagerUpload[] {

    const fileTranslationsUploads: FileTranslationsManagerUpload[] = [];
    for (let file of files) {
      const fileUpload = new FileTranslationsUpload(undefined, file.name, sourceLanguage, targetLanguage, file.size);
      let id: string;
      const upload = this.translationsFileService.create(fileUpload)
        .pipe(
          catchError(error => {
            // To handle the FileName validation.
            if (error.status === 400) {
              handleError!(FileUploadErrorMessages.FILE_UPLOAD_INVALID_FILE_NAME);
            }
            return throwError(() => error);
          }),
          tap((uploadedFile: FileTranslationsUpload) =>{
            id = uploadedFile.id!;
            this.getCancellationSubject(id);
          }),
          mergeMap((uploadedFile: FileTranslationsUpload) =>
            this.s3UploadService.uploadFile(file, uploadedFile.url!)
              .pipe(
                // Take until this file's upload is cancelled
                takeUntil(this.getCancellationSubject(id)),
                catchError(error => {
                  // Update the file status in the database to ERROR
                  return this.translationsFileService.updateStatus(id, 'ERROR')
                    .pipe(
                      map(() => {
                        // Return error response after updating the status
                        this.fileStatusIds.error.push(id);
                        return {
                          type: HttpEventType.Response,
                          body: { error: true, message: error.message || 'Upload failed' },
                          status: 500
                        } as HttpResponse<any>;
                      }),
                      catchError(updateError => {
                        // Even if the status update fails, still return error response
                        console.error("Failed to Update Status", updateError)
                        this.fileStatusIds.error.push(id);
                        return of({
                          type: HttpEventType.Response,
                          body: { error: true, message: error.message || 'Upload failed' },
                          status: 500
                        } as HttpResponse<any>);
                      })
                    );
                })
              )
          ),
          mergeMap((event: HttpEvent<any>) => this.mapProgress(id, event, uploadType))
        );
      fileTranslationsUploads.push({ file, upload });
    }
    return fileTranslationsUploads;
  };

  private mapProgress(id: string, event: HttpEvent<any>, uploadType: FileTranslationsUploadType): Observable<FileTranslationsManagerUploadStatus> {
    if (event.type === HttpEventType.Response) {
      const response = event as HttpResponse<any>;

      // Check if this is our error response
      if (response.body && response.body.error === true) {
        this.fileStatusIds.error.push(id);
        return of({
          id,
          status: FileTranslationsUploadStatus.ERROR,
          progress: 0
        });
      }

      // File is uploaded to S3, but we need to wait for processing to complete
      // Start simulating progress from 75% to 100% while waiting for server to confirm "UPLOADED" status
      return this.simulateProcessingProgress(id, uploadType);
    }

    if (event.type === HttpEventType.UploadProgress) {
      return of({
        id,
        status: FileTranslationsUploadStatus.IN_PROGRESS,
        progress: event.total
          ? Math.round(((100 * event.loaded) / event.total) - 25)
          : 0
      });
    }

    // For other HttpEventTypes, just return in-progress status
    return of({
      id,
      status: FileTranslationsUploadStatus.IN_PROGRESS,
      progress: 0
    });
  };

  private simulateProcessingProgress(id: string, uploadType: FileTranslationsUploadType): Observable<FileTranslationsManagerUploadStatus> {
    // Current progress starts at 75% (as upload was 75% of total progress)
    let currentProgress = 75;

    // Create a subject to control the simulation
    const progressSubject = new Subject<FileTranslationsManagerUploadStatus>();
    const cancellation$ = this.getCancellationSubject(id);

    // Function to increment progress up to 95% (reserving final 5% for confirmed UPLOADED status)
    const incrementProgress = () => {
      if (currentProgress < 95) {
        currentProgress = Math.min(currentProgress + 1, 95);
        progressSubject.next({
          id,
          status: FileTranslationsUploadStatus.IN_PROGRESS,
          progress: currentProgress
        });

        // Schedule next increment
        setTimeout(incrementProgress, 300);
      }
    };

    // Start progress simulation
    incrementProgress();

    // Poll for file status using the modern subscription syntax
    this.checkFileUploadStatus(id, uploadType).pipe(
        takeUntil(cancellation$),
        tap({
          next: (status) => {
            // Once we get final status, complete the subject with that status
            progressSubject.next(status);
            progressSubject.complete();
          },
          error: () => {
            // Handle errors
            progressSubject.next({
              id,
              status: FileTranslationsUploadStatus.ERROR,
              progress: 0
            });
            progressSubject.complete();
          }
        })
    ).subscribe();

    progressSubject.pipe(
      finalize(() => {
        if (!cancellation$.closed) {
          this.fileUploadCancellations.delete(id);
        }
      })
    );

    return progressSubject.asObservable().pipe(
      takeUntil(cancellation$)
    );
  }

  private checkFileUploadStatus(id: string, uploadType: FileTranslationsUploadType): Observable<FileTranslationsManagerUploadStatus> {
    const cancellation$ = this.getCancellationSubject(id);

    return this.translationsFileService.get(id).pipe(
      takeUntil(cancellation$),
      mergeMap((file: FileTranslationsUpload) => {
        if (file.status === 'UPLOADED') {
          // File is fully processed
          this.fileStatusIds.uploaded.push(id);
          return of({
            id,
            status: FileTranslationsUploadStatus.COMPLETED,
            progress: 100
          });
        } else if (file.status === 'ERROR') {
          // Processing failed
          this.fileStatusIds.error.push(id);
          return of({
            id,
            status: FileTranslationsUploadStatus.ERROR,
            progress: 0
          });
        } else {
          // Status is not final yet, wait and try again
          return of(null).pipe(
            delay(3000),
            mergeMap(() => this.checkFileUploadStatus(id, uploadType))
          );
        }
      }),
      catchError((err) => {
        console.error("Failed to check the file upload status", err)
        this.fileStatusIds.error.push(id);
        return of({
          id,
          status: FileTranslationsUploadStatus.ERROR,
          progress: 0
        });
      })
    );
  }

  getFileUploadStatus(data: FileTranslationsManagerUpload): FileTranslationsManagerUpload {
    const cancel$: Subject<boolean> = new Subject<boolean>();

    const upload: FileTranslationsManagerUpload = {
      file: data.file,
      status: FileTranslationsUploadStatus.IN_PROGRESS,
      progress: 0,
      cancel: () => {
        if(data.id){
          this.translationsFileService.delete(data.id).pipe(
            tap(() => {
              cancel$.next(true);
              this.cancelUpload(data.id!);
            }),
            catchError(error => {
              console.error('Error cancelling Uploading:', error);
              cancel$.next(true);
              this.cancelUpload(data.id!);
              return of(null);
            })
          ).subscribe();
        }else{
          cancel$.next(true);
        }
      },
      fetch: () => data.upload!.pipe(
        takeUntil(cancel$),
        catchError((err) => {
          upload.status = FileTranslationsUploadStatus.ERROR;
          upload.progress = 0;
          return throwError(() => err);
        }),
        tap((uploadStatus) => {
          upload.id = uploadStatus.id!;
          upload.status = uploadStatus.status;
          upload.progress = uploadStatus.progress;
        })
      ).subscribe()
    };
    upload.fetch!();
    return upload;
  };

  getFileTranslateStatus(fileUploadId: string): FileTranslationsManagerTranslate {
    const cancel$: Subject<boolean> = new Subject<boolean>();

    // Initialize with minimum required structure
    const translate: FileTranslationsManagerTranslate = {
      id: undefined,
      file: undefined,
      status: FileTranslationsProcessingStatus.TRANSLATING
    };

    // Get the actual FileTranslation from the backend
    this.translatedDocumentsListService.getByFileUpload(fileUploadId).pipe(
        tap((fileTranslation: FileTranslation) => {
          translate.file = fileTranslation;
          translate.id = fileTranslation.id

          translate.cancel = () => {
            this.translatedDocumentsListService.delete(fileTranslation.id).pipe(
                tap(() => {
                  cancel$.next(true);
                }),
                catchError(error => {
                  console.error('Error cancelling translation:', error);
                  cancel$.next(true);
                  return of(null);
                })
            ).subscribe();
          };

          translate.fetch = () => this.checkTranslationStatus(fileTranslation.id!).pipe(
              takeUntil(cancel$),
              catchError((err) => {
                translate.status = FileTranslationsProcessingStatus.TRANSLATING_ERROR;
                return throwError(() => err);
              }),
              tap((status) => {
                translate.id = fileTranslation.id;
                translate.status = status.status;
                if (status.file) {
                  translate.file = status.file;
                }
              })
          ).subscribe();

          // Start monitoring immediately
          translate.fetch();
        }),
        catchError((error) => {
          console.error('Error getting FileTranslation:', error);
          translate.status = FileTranslationsProcessingStatus.TRANSLATING_ERROR;
          return of(null);
        })
    ).subscribe();

    return translate;
  }

  private checkTranslationStatus(fileTranslationId: string): Observable<FileTranslationsManagerTranslateStatus> {
    return this.translatedDocumentsListService.get(fileTranslationId).pipe(
        mergeMap((file: FileTranslation) => {
          if (file.status === FileTranslationsTranslateStatus.TRANSLATED_UPLOADED) {
            return of({
              file,
              status: FileTranslationsProcessingStatus.TRANSLATED
            });
          } else if (file.status === FileTranslationsTranslateStatus.ERROR) {
            return of({
              file,
              status: FileTranslationsProcessingStatus.TRANSLATING_ERROR
            });
          } else {
            // Status is not final yet, wait and try again
            return of(null).pipe(
                delay(3000),
                mergeMap(() => this.checkTranslationStatus(fileTranslationId))
            );
          }
        }),
        catchError((err) => {
          console.error("Error checking translation status:", err);
          return of({
            file: undefined,
            status: FileTranslationsProcessingStatus.TRANSLATING_ERROR
          });
        })
    );
  }

  /**
   * Initialize translation files array with in-progress or error translations
   * @param translateFiles Array to populate with translation files
   * @param onUpdate Callback function to run after updating the array
   */
  initTranslateFiles(translateFiles: FileTranslationsManagerTranslate[], onUpdate?: () => void): void {
    const excludeStatus = FileTranslationsTranslateStatus.TRANSLATED_UPLOADED
    this.translatedDocumentsListService.list([excludeStatus]).pipe(
        tap(files => {
          // Add files to translateFiles array
          files.forEach(file => {
            if (file.id) {
              const translateData = this.getFileTranslateStatus(file.id);
              translateFiles.push(translateData);
            }
          });

          if (onUpdate) {
            onUpdate();
          }
        }),
        catchError(error => {
          console.error('Error fetching translation files Exclude status: %d and error: %d', excludeStatus, error);
          return of([]);
        })
    ).subscribe();
  }

  /**
   * Initialize upload files array with error uploads
   * @param uploadFiles Array to populate with upload files
   * @param onUpdate Callback function to run after updating the array
   */
  initUploadFiles(uploadFiles: FileTranslationsManagerUpload[], onUpdate?: () => void): void {
    this.translationsFileService.list("error").pipe(
        tap(errorFiles => {
          // Add files to uploadFiles array
          errorFiles.forEach(errorFile => {
            if (errorFile.id) {
              const file = new File([], errorFile.name || 'unknown');
              const uploadData: FileTranslationsManagerUpload = {
                file,
                id: errorFile.id,
                status: FileTranslationsUploadStatus.ERROR
              };
              const uploadStatus = of({
                id: errorFile.id,
                status: FileTranslationsUploadStatus.ERROR,
                progress: 0
              });
              uploadData.upload = uploadStatus;
              const upload = this.getFileUploadStatus(uploadData);
              uploadFiles.push(upload);
            }
          });

          if (onUpdate) {
            onUpdate();
          }
        }),
        catchError(error => {
          console.error('Error fetching File Upload Error status files:', error);
          return of([]);
        })
    ).subscribe();
  }

  getParseFileNameToDisplayName(name: string, maxFileNameLength: number, formatOption: FileNameFormatOption = FileNameFormatOption.WITH_EXTENSION): string {
    return parseFileNameToDisplayName(name, maxFileNameLength, formatOption);
  }

  getReadableFileSize(size: number): string {
    return getReadableFileSize(size);
  }
}
