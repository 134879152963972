import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ChatHistoryRecordActions } from '@app/shared/models/actions/chat-history-record-actions';
import { featureFlags } from "@environments/environment";

@Component({
  selector: 'app-chat-history-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css']
})
export class DrawerComponent implements OnChanges {
  @Output() actionEvent = new EventEmitter<ChatHistoryRecordActions>();
  @Input() drawerToggled?: boolean;
  @Input() renameEnabled = false;

  isExpanded: boolean | undefined = false;
  protected readonly featureFlags = featureFlags;

  constructor() {}

  ngOnChanges(): void {
    this.isExpanded = this.drawerToggled;
  }

  toggleDrawer() {
    this.isExpanded = !this.isExpanded;
  }

  onDelete(): void {
    this.actionEvent.emit('delete');
    this.toggleDrawer();
  }

  onRename(): void {
    this.actionEvent.emit('rename');
    this.toggleDrawer();
  }

  onOpenChat(): void {
    this.actionEvent.emit('open');
    this.toggleDrawer();
  }
}
