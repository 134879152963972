<div class="agent-filters">
  <h3>Search agents</h3>
  <div class="agent-filters-container">
    <input kmdFormInput id="agent-search-input" placeholder="Enter Agent name" (keyup)="getSearchValue($event)" autocomplete="off"/>
    <div class="dropdown-container">
      <kmd-dropdown [options]="AgentCategoryFilters" inlineLabel="Category"
                [(ngModel)]="selectedFilter" (ngModelChange)="onCategoryDropdownChange($event)"></kmd-dropdown>
      <kmd-dropdown [options]="SortOptions" inlineLabel="Sort contents by"
                  [(ngModel)]="selectedSortOption" (ngModelChange)="onSortDropdownChange($event)"></kmd-dropdown>
    </div>
  </div>
  <span class="agents-count">
    {{agentCountLabel}}
  </span>
</div>
