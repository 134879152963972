<div class="repository-history-container">
  <div class="repository-history-header">
    <h2>Repository History</h2>
  </div>
  <!-- <h2>Repository History</h2> -->
  <!-- Search box -->
  <div class="search-container">
    <div class="search-wrapper">
      <input type="text" [(ngModel)]="searchText" (keyup)="filterData()" placeholder="Search text input"
        class="search-input">
    </div>
  </div>
  <div class="table-container">
    <div class="loader-container" *ngIf="isLoadingTableData">
      <kmd-loading-wheel></kmd-loading-wheel>
    </div>
    <table *ngIf="!isLoadingTableData">
      <thead>
        <tr *ngIf="!isMobileScreen()">
          <td *ngFor="let header of columnHeaders">{{header}}</td>
          <!-- Intentionally kept this td tag as empty as it need to be -->
          <td></td>
        </tr>

        <tr *ngIf="isMobileScreen()">
          <td class="chat-history-row">
            By date
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filteredTableData">
          <td *ngIf="!isMobileScreen()">{{data.date | date:'MM/dd/yyyy, HH:mm'}}</td>
          <td *ngIf="!isMobileScreen()">
            <ng-container *ngIf="!data.isEditing; else editMode">
              <ng-container *ngIf="clickableChatHistory; else plainText">
                <a href="javascript:void(0)" (click)="openChat(data.id)">{{data.title}}</a>
              </ng-container>
              <ng-template #plainText>{{data.title}}</ng-template>
            </ng-container>
            <ng-template #editMode>
              <input #titleInput type="text" [value]="data.title"
              (keyup)="onKeyUp($event, data.id, titleInput.value)"
                (blur)="data.isEditing = false" autofocus>
            </ng-template>
          </td>
          <td class="chat-history-data" *ngIf="isMobileScreen()" (click)="showDialog(data)">
            <div class="chat-history-title-mobile">
              <ng-container *ngIf="!data.isEditing; else editMode">
                <ng-container *ngIf="!isMobileScreen() && clickableChatHistory; else plainText">
                  <a href="javascript:void(0)" (click)="openChat(data.id)">{{data.title}}</a>
                </ng-container>
                <ng-template #plainText>{{data.title}}</ng-template>
              </ng-container>
              <ng-template #editMode>
                <input #titleInput type="text" [value]="data.title"
                  (keyup)="onKeyUp($event, data.id, titleInput.value)"
                  (blur)="data.isEditing = false" autofocus>
              </ng-template>
            </div>
            <div class="chat-history-date-mobile">
              {{data.date | date:'dd-MMM-yyyy HH:mm'}}
            </div>
          </td>
          <!-- <td *ngIf="isMobileScreen()">{{data.date | date:'MM/dd/yyyy, h:mm'}}</td> -->
          <td *ngIf="!isMobileScreen()" class="chat-history-options" size="mini" kmdPrimaryButton style="margin: 10px"
            (click)="showPopover($event); popOver1.toggle($event)">...</td>

          <kmd-popover #popOver1 [position]="'right'" [positionX]="posX" [positionY]="posY">
            <div class="popover-item" (click)="data.isEditing = true; popOver1.hide()">Rename</div>
            <div class="popover-item" (click)="deleteConversation(data.id); popOver1.hide()">Delete</div>
            <div class="popover-item" (click)="openChat(data.id)">Open Chat</div>
          </kmd-popover>

        </tr>
        <div #popOver2 class="chat-history-options-mobile">
          <p-dialog [modal]="true" [(visible)]="visible" [position]="position" [dismissableMask]="true"
            [showHeader]="false" [style]="{ width: '100%', height: '13.5rem' }">
            <div class="buttons-container">
              <button kmdFeaturedButton kmdBlockButton (click)="openChat(selectedChatHistoryRow.id); closeDialog()">
                Open chat </button>
              <button kmdInfoButton kmdBlockButton (click)="selectedChatHistoryRow.isEditing = true; closeDialog()">
                Rename </button>
              <button kmdLinkButton kmdBlockButton
                (click)="deleteConversation(selectedChatHistoryRow.id); closeDialog()"> Delete chat</button>
            </div>
          </p-dialog>
        </div>

      </tbody>
    </table>
  </div>
</div>