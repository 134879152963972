import { Inject, Injectable } from "@angular/core";
import { AuthenticationProvider, Client } from "@microsoft/microsoft-graph-client";
import { from, map, mergeMap, Observable, of, shareReplay, throwError } from "rxjs";
import { AskOurDocsSubject } from "@shared/models/ask-our-docs-subject";

@Injectable({
  providedIn: 'root'
})
export class GroupMembershipService {

  client: Client;

  constructor(
    @Inject('AUTH_PROVIDER') authProvider: AuthenticationProvider
  ) {
    this.client = Client.initWithMiddleware({authProvider});
  }

  getGroupOwners(groupId: string): Observable<any> {
    return from(this.client.api(`/groups/${groupId}/owners`).get());
  }

  getGroupMembers(groupId: string): Observable<any> {
    return from(this.client.api(`/groups/${groupId}/members?$top=999`).get()).pipe(
      mergeMap((response: any) => {
        if (response['@odata.nextLink']) {
          const members: any[] = [];

          members.push(...response['value']);

          return this.getGroupMembersRecursive(response['@odata.nextLink'], members);
        }

        return of(response);
      }),
      shareReplay(1)
    );
  };

  private getGroupMembersRecursive(nextLink: string, members: any[]): Observable<any> {
    return from(this.client.api(nextLink).get()).pipe(
      mergeMap((response: any) => {
        members.push(...response['value']);

        if (response['@odata.nextLink']) {
          return this.getGroupMembersRecursive(response['@odata.nextLink'], members);
        }

        return of({...response, value: members});
      })
    );
  }

  getGroupByRepository(repositoryName: string): Observable<{ response: any, isOwner: boolean }> {
    const searchQuery = {
      $search: encodeURIComponent(`"displayName:${this.encodeRepoName(repositoryName)}"`)
    };

    const ownerOf$ = from(this.client.api(`/me/ownedObjects`)
      .header('ConsistencyLevel', 'eventual')
      .header('Prefer', 'legacySearch=true')
      .query(searchQuery)
      .get());

    return ownerOf$.pipe(
      mergeMap((response: any) => {
        if (response.value.length > 0) {
          return of({response, isOwner: true});
        }

        const memberOf$ = from(this.client.api(`/me/memberOf`)
          .header('ConsistencyLevel', 'eventual')
          .query(searchQuery)
          .get());

        return memberOf$.pipe(
          mergeMap((response: any) => of({response, isOwner: false}))
        );
      })
    );
  };

  getUserByEmail(email: string): Observable<AskOurDocsSubject> {
    return from(this.client.api(`/users`)
      .header('ConsistencyLevel', 'eventual')
      .query({$filter: `mail eq '${email}' or userPrincipalName eq '${email}'`})
      .get())
      .pipe(
        map(response => response.value[0]),
        mergeMap(response => 
          (!response)
            ? throwError(() => ({ code: 'Request_ResourceNotFound' }))
            : of({
              id: response.id,
              mail: response.mail,
              userPrincipalName: response.userPrincipalName
            })
        )
      );
  };

  getUsersByIds(userIds: string[]): Observable<{ value: { id: string }[] }> {
    return from(this.client.api(`/directoryObjects/getByIds`)
      .post({ids: userIds, types: ["user"]}));
  };

  addOwnerToGroup(groupId: string, ownerId: string): Observable<any> {
    return from(this.client.api(`/groups/${groupId}/owners/$ref`)
      .post({
        "@odata.id": `https://graph.microsoft.com/v1.0/users/${ownerId}`
      }));
  };

  addMemberToGroup(groupId: string, memberId: string): Observable<any> {
    return from(this.client.api(`/groups/${groupId}/members/$ref`)
      .post({
        "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${memberId}`
      }));
  };

  removeOwnerFromGroup(groupId: string, ownerId: string): Observable<any> {
    return from(this.client.api(`/groups/${groupId}/owners/${ownerId}/$ref`)
      .delete());
  };

  removeMemberFromGroup(groupId: string, memberId: string): Observable<any> {
    return from(this.client.api(`/groups/${groupId}/members/${memberId}/$ref`)
      .delete());
  };

  private encodeRepoName(repositoryName: string) {
    return repositoryName
      .replace(/[.]/g, '')
      .replace(/&/g, '%26')
      .replace(/ /g, '_');
  }
}
