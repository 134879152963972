export class FileUpload {
  id?: string | undefined
  name: string
  status?: string | undefined
  url?: string | undefined
  updatedAt?: Date | undefined
  progress?: number;
  fileSize?: number;
  fileType?: string;

  constructor(id: string | undefined, name: string, status: string | undefined, url: string | undefined, updatedAt?: Date | undefined, fileSize?: number) {
    this.id = id
    this.name = name
    this.status = status
    this.url = url
    this.updatedAt = updatedAt
    this.fileSize = fileSize
  }
}
