import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GroupMembershipService } from '@app/core/services/group-membership/group-membership.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { KmdModalService } from "gds-atom-components";
import { catchError, concat, concatMap, from, map, Observable, of, tap } from 'rxjs';
import { AlertService } from "@services/alert/alert.service";


@Component({
  selector: 'app-ask-our-docs-user-management-remove-users',
  templateUrl: './ask-our-docs-user-management-remove-users.component.html',
  styleUrls: ['./ask-our-docs-user-management-remove-users.component.css']
})
export class AskOurDocsUserManagementRemoveUsersComponent implements OnChanges {

  @Input() id: string = "";
  @Input() users: Map<string, { email: string, role: string }> = new Map();
  @Input() admins: Map<string, { email: string, role: string }> = new Map();
  @Output() afterRemoveUsers = new EventEmitter<void>();

  modalId = 'removeUsersModal';
  removeSelfUserWarningModalId = 'removeSelfUserWarningModal';


  isConfirmButtonDisabled: boolean = false;

  userId$: Observable<string>;
  verifyIfSelfDeletion$: Observable<any> = of({ isSelfDelete: false });

  constructor(
    private kmdModalService: KmdModalService,
    private groupMemberService: GroupMembershipService,
    public oidcSecurityService: OidcSecurityService,
    private alertService: AlertService
  ) {
    this.userId$ = this.oidcSecurityService.userData$.pipe(
      map(response => response.userData.oid)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['users']) {
      return;
    }

    this.verifyIfSelfDeletion$ = this.userId$.pipe(
      map(userId => ({ isSelfDelete: Array.from(this.users.keys()).includes(userId) }))
    );
  }

  openModal(): void {
    this.verifyIfSelfDeletion$.pipe(
      tap(response => {
        if (response.isSelfDelete) {
          this.kmdModalService.open(this.removeSelfUserWarningModalId);
          return;
        }

        this.isConfirmButtonDisabled = this.validateAdminsInUsers();
        this.kmdModalService.open(this.modalId);
      }))
      .subscribe();
  }

  closeModal(): void {
    this.kmdModalService.close(this.modalId);
  }

  closeWarningModal(): void {
    this.kmdModalService.close(this.removeSelfUserWarningModalId);
  }

  getUserPrincipalNameList(): string[] {
    this.modalId = this.users.size > 1 ? "remove-users-modal" : "remove-user-mini-modal";
    return Array.from(this.users.values()).map(user => user.email);
  }

  removeUsersFromGroup(): void {
    let failedRequests = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const removalObservables = Array.from(this.users.entries()).map(([userId, { email, role }]) => {
      if (role === 'Admin') {
        return concat(
          this.groupMemberService.removeMemberFromGroup(this.id, userId),
          this.groupMemberService.removeOwnerFromGroup(this.id, userId)
        ).pipe(
          catchError(() => {
            failedRequests++;
            return of(null);
          })
        );
      } else {
        return this.groupMemberService.removeMemberFromGroup(this.id, userId).pipe(
          catchError(() => {
            failedRequests++;
            return of(null);
          })
        );
      }
    });

    from(removalObservables).pipe(
      concatMap(observable => observable),
      catchError(() => {
        this.alertService.showError('Error occurred while removing users from group, please try again later.');
        return of(null);
      })
    ).subscribe({
      complete: () => {
        if (failedRequests === 0) {
          this.alertService.showSuccess('Users access removed successfully.');
        } else {
          this.alertService.showError(`Could not remove ${failedRequests} users. Please review and try again.`);
        }
        this.afterRemoveUsers.emit();
        this.closeModal();
      }
    });
  }


  validateAdminsInUsers(): boolean {
    const adminIds = Array.from(this.admins.keys());
    const userIds = Array.from(this.users.keys());

    return adminIds.every(id => userIds.includes(id));
  }

}
