import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';
import { PromptService } from '@services/prompt/prompt.service';
import { Prompt } from '@shared/models/prompt.model';
import { KmdAccordionComponent, KmdModalService } from 'gds-atom-components';
import { faEdit, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { ModalEditPromptComponent } from '../prompt/modaleditprompt/modal-edit-prompt.component';
import { SharePromptComponent } from '../share-prompt/share-prompt.component';

@Component({
  selector: 'prompt-list',
  templateUrl: './promptlist.component.html',
  styleUrls: ['./promptlist.component.css', "./promptlist-table.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class PromptListComponent implements OnInit {

  @Input() type!: string;
  @Input() visibility!: string;

  @ViewChild('categoriesView', { static: false }) categoriesView!: KmdAccordionComponent;
  @ViewChild('modalEditPromptComponent') modalEditPromptComponent?: ModalEditPromptComponent;
  @ViewChild('sharePromptComponent') sharePromptComponent?: SharePromptComponent;

  actualOpenCategory = -1;

  alertText: string = '';
  showAlert: boolean = false;
  showSuccess: boolean = false;
  editPrompt?: Prompt;
  promptToDelete?: Prompt
  promptToShare?: Prompt
  @Output() promptSelectEvent = new EventEmitter<Prompt>();

  prompts: Prompt[] = [];

  isLoading: boolean = true;
  showEmptyText: boolean = false;
  faEdit = faEdit;
  faTrashCan = faTrashCan;

  promptTableHeaders: string[] = ["Title", "Model", "Web search", "Status", "Actions"];

  constructor(private promptService: PromptService, private kmdModalService: KmdModalService) {
  }

  ngOnInit(): void {
    this.promptService.prompts(this.type, this.visibility).subscribe({
      next: (promptList) => {
        this.isLoading = false;
        this.prompts = promptList;
        this.showEmptyText = promptList.length == 0;

        /**
         * For some strange reason the accordion component behaves weird
         * when adding the tabs dynamically for that reason after loading the
         * prompts we are calling ngAfterContentInit to let the component
         * recalculate its initial state.
         */
        this.categoriesView.ngAfterContentInit();
      },
      error: () => {
        this.alertText = 'The prompts could not be obtained. There was an issue contacting the server.';
        this.showAlert = true;
        this.isLoading = false;
      }
    });
  }

  categories() {
    const categories = this.prompts.map((prompt) => prompt.category!);
    return [...new Set(categories)].sort();
  }

  promptsByCategory(category: string) {
    return this.prompts
      .filter(prompt => prompt.category === category)
      .sort((promptOne, promptTwo) => {
        if (promptOne.title! > promptTwo.title!) {
          return 1;
        }
        if (promptOne.title! < promptTwo.title!) {
          return -1;
        }
        return 0;
      });
  }

  onSelect(prompt: Prompt) {
    this.promptSelectEvent.emit(prompt);
  }

  onEdit(prompt: Prompt) {
    /**
     * Since the prompt edit will modify the content of the prompt
     * we're creating a copy of the original prompt to prevent from
     * alter the original prompt attributes if the edit form doesn't complete
     */
    this.editPrompt = Object.assign({}, prompt);
    this.modalEditPromptComponent?.open();
  }

  onDelete(prompt: Prompt, id: string) {
    this.kmdModalService.open(id);
    this.promptToDelete = prompt;
  }

  onShare(prompt: Prompt, id: string) {
    this.kmdModalService.open(id);
    this.promptToShare = prompt;
  }

  onShareValuesChange(values: {
    alertType: string,
    alertText: string
  }) {
    if (values.alertType == "success") {
      let promptIndex = this.prompts.findIndex((p) => p.id === this.promptToShare?.id);
      this.prompts.splice(promptIndex, 1);
      this.alertText = values.alertText;
      this.showSuccess = true
    } else if (values.alertType == "alert") {
      this.alertText = values.alertText;
      this.showAlert = true
    }
  }

  resetPromptToShare() {
    this.promptToShare = undefined
  }

  delete(prompt: Prompt) {
    this.promptService.delete(prompt.id!).subscribe({
      next : () => {
        let promptIndex = this.prompts.findIndex((p) => p.id === prompt.id);
        this.prompts.splice(promptIndex, 1);
        this.alertText = 'Prompt deleted';
        this.showSuccess = true
        this.kmdModalService.close('delete-prompt-modal')
      },
      error: () => {
        this.alertText = 'The prompt could not be deleted. There was an issue contacting the server.';
        this.showAlert = true
      }
    });
  }

  closeModal(modalId: string) {
    this.kmdModalService.close(modalId);
  }

  closePreviousSection(event: number[]) {
    if (this.actualOpenCategory != -1 && this.actualOpenCategory != event[0]) {
      this.categoriesView.closeTabs([this.actualOpenCategory])
    }
    this.actualOpenCategory = event[0]
  }

  promptSaved(prompt: Prompt) {
    this.modalEditPromptComponent?.close();
    this.alertText = 'Prompt updated';
    this.showSuccess = true
    let promptIndex = this.prompts.findIndex((p) => p.id === prompt.id);
    this.prompts[promptIndex] = prompt
  }
}
