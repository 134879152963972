import { Component, Input } from '@angular/core';

@Component({
  selector: 'countdown-label',
  templateUrl: './countdown-label.component.html',
  styleUrls: ['./countdown-label.component.css']
})
export class CountdownLabelComponent {

  @Input() value: string | undefined = '';
  @Input() maxlength: number = 50;

  constructor() {
  }

  get inputCharLimit(): number {
    if (!this.maxlength)
      return 0;

    if (this.value)
      return this.maxlength - this.value.length;
    else
      return this.maxlength;
  }
}
