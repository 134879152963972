<app-modal [id]="id"
  [headline]="headline"
  [subHeadline]="!isPublicShare ? subHeadline : undefined"
  (close)="onModalClosed()">
  <div #modalContent class="modal-body-content">
    <div class="top-section">
      <ng-container #publicAgentShareFormProperties *ngIf="isPublicShare">
        <div class="modal-row columns three-sections">
          <div class="modal-row rows">
            <div id="name-display" class="property-wrapper">
              <label>Name:</label>
              <p>{{ sharingDetails?.agent?.name }}</p>
            </div>
            <div class="property-wrapper">
              <label>Share to:</label>
              <p>Public</p>
            </div>
          </div>
          <div class="modal-row rows">
            <div class="property-wrapper">
              <label>Category:</label>
              <p>{{ sharingDetails?.agent?.category }}</p>
            </div>
          </div>
          <div class="modal-row rows"></div>
        </div>
      </ng-container>
      <ng-container #shareAgentFormProperties *ngIf="!isPublicShare">
        <div class="modal-row columns three-sections">
          <div class="modal-row rows">
            <div id="name-display" class="property-wrapper">
              <label>Name:</label>
              <p>{{ sharingDetails?.agent?.name }}</p>
            </div>
            <div class="property-wrapper">
              <label>Share to:</label>
              <p>Microsoft group</p>
            </div>
            <div class="property-wrapper">
              <label>Members</label>
              <p><a href="https://myaccount.microsoft.com/groups/{{group?.id}}" target="_blank">{{ membersCount }}
                members</a></p>
            </div>
          </div>
          <div class="modal-row rows">
            <div class="property-wrapper">
              <label>Category:</label>
              <p>{{ sharingDetails?.agent?.category }}</p>
            </div>
            <div class="property-wrapper">
              <label>Group name:</label>
              <p><a href="https://myaccount.microsoft.com/groups/{{group?.id}}"
                    target="_blank">{{ group?.displayName }}</a></p>
            </div>
            <div class="property-wrapper">
              <label>Admins:</label>
              <p>{{ adminsLabel }}</p>
            </div>
          </div>
          <div class="modal-row rows"></div>
        </div>
      </ng-container>
      <div class="modal-row">
        <div class="property-wrapper">
          <label>Description</label>
          <p>{{ sharingDetails?.agent?.description }}</p>
        </div>
      </div>
      <div class="modal-row">
        <div class="property-wrapper">
          <label>Conversation Starter:</label>
          <p>{{ conversationStarter }}</p>
        </div>
      </div>
      <div class="modal-row">
        <div id="instructions-display" class="property-wrapper">
          <label>Instructions</label>
          <p>{{ sharingDetails?.agent?.instructions }}</p>
        </div>
      </div>
      <div class="modal-row" *ngIf="sharingDetails?.selectedFiles?.length">
        <table>
          <thead>
          <tr>
            <th>
              <div class="table-header" (click)="sortByFileName()">
                <label>File name</label>
                <div class="sorting-action-icon">
                  <app-caret-icon width="9px" height="14px" color="#1B1B1D" type="sort"/>
                </div>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let file of sharingDetails?.selectedFiles">
            <td>
              <div class="file-reference" (click)="showFile(file.id!)">
                <span class="link">{{ file.name }}</span>
                <app-external-link-icon [width]="'16px'" [height]="'16px'" [color]="'#0071D0'"
                                        (click)="showFile(file.id!)"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-row rows" *ngIf="!readOnly">
        <ng-container #publicAgentShareFormConsent *ngIf="isPublicShare">
          <div id="public-sharing-disclaimer">
            <p>By making my Agent public, I confirm that my Agent complies with the <a href="/guidelines" target="_blank">Gene.AI
              Guidelines</a> and agree to print my name in association with my agent.</p>
          </div>
        </ng-container>
        <ng-container #shareAgentFormConsent *ngIf="!isPublicShare">
          <div id="public-sharing-disclaimer">
            <p>By sharing your agent you are agreeing to display your name and any other admins under the “Admin:”
              field.</p>
          </div>
          <div class="checkbox">
            <core-checkbox label="" (onClick)="toggleCreationGuidelinesCompliance()"
                           [checked]="creationGuidelinesCompliance"/>
            <label>I confirm I've read and comply with <a href="/guidelines" target="_blank"> Agent creation
              guidelines.</a></label>
          </div>
          <div class="checkbox">
            <core-checkbox label="" (onClick)="toggleDataClassificationPolicyCompliance()"
                           [checked]="creationDataClassificationPolicyCompiliance"/>
            <label>I understand that shared agents may not include any confidential information about customers,
              vendors, or business partners; personal data besides employee contact information; or any other
              information defined as “Restricted” by the<a
                href="https://thermofisher.sharepoint.com/sites/CIS/Policies/Data%20Classification%20%26%20Handling%20Policy.pdf"
                target="_blank"> Thermo Fisher Data Classification Policy.</a></label>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="bottom-section" *ngIf="!readOnly">
      <div class="left">
        <label><a (click)="onEditAgentClick()">Edit agent</a></label>
      </div>
      <div id="modal-action-buttons" class="right">
        <button kmdInfoButton (click)="onBackClick()">Back</button>
        <ng-container *featureFlag="'agentSharing'; else publishAgentButton">
          <button kmdFeaturedButton (click)="shareAgent()" [disabled]="isShareButtonDisabled">Confirm</button>
        </ng-container>
        <ng-template #publishAgentButton>
          <button kmdFeaturedButton (click)="shareAgent()" [disabled]="isShareButtonDisabled">Publish agent</button>
        </ng-template>
      </div>
    </div>
  </div>
</app-modal>
