<div class="file-manager-modal-container">
  <div id="file-manager-modal-file-actions" class="top-section">
    <div class="segmented-actions-wrapper">
      <div class="segmented-actions">
        @if(fileManagerConfig.fileUpload.text){
          <app-file-type-button id="text-button" label="Text" [isSelected]="isFileTypeSelected('text')"
            [isDisabled]="isChangingTabsDisabled()" (click)="setSelectedFileType(fileUploadOptions['TEXT'])" [isBeta]="false">
          </app-file-type-button>
        }

        @if(fileManagerConfig.fileUpload.spreadsheets){
          <app-file-type-button id="spreadsheets-button" label="Spreadsheets" [isSelected]="isFileTypeSelected('spreadsheets')"
            [isDisabled]="isChangingTabsDisabled()" (click)="setSelectedFileType(fileUploadOptions['SPREADSHEETS'])"
            [isBeta]="true">
          </app-file-type-button>
        }


        <ng-content *featureFlag="'imagePrompt'">
          @if(fileManagerConfig.fileUpload.images){
            <app-file-type-button id="image-button" label="Image" [isSelected]="isFileTypeSelected('image')"
              [isDisabled]="isChangingTabsDisabled()" (click)="setSelectedFileType(fileUploadOptions['IMAGE'])" [isBeta]="false">
            </app-file-type-button>
          }
        </ng-content>
      </div>
      <div id="search-bar-container" *ngIf="!isMobileOrTablet()">
        <img class="search-icon" src="../../../../../assets/icons/search/search.svg" alt="">
        <input type="text" class="search-term" kmdFormInput id="file-search-input"
          [placeholder]="isMobile ? 'Search' : 'Search documents'" autocomplete="off" (input)="onSearchDocument($event)"
          [(ngModel)]="search" />
      </div>
    </div>
    <div class="file-type-help-text" *ngIf="isFileTypeSelected('text')">
      Upload your documents for query. <a
        href="https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Ask%20My%20Docs.aspx?csf=1&web=1&e=dsIv1i&CID=58f1399c-026a-4ed8-9e10-6127d3ec23e2"
        target="_blank">Learn more</a>
    </div>
    <div class="file-type-help-text" *ngIf="isFileTypeSelected('image')">
      Upload your images for query.
    </div>
    <div class="file-type-help-text" *ngIf="isFileTypeSelected('spreadsheets')">
      Upload your spreadsheets for query. See <a
        href="https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Our-Data.aspx?csf=1&web=1&e=szXWYn&OR=Teams-HL&CT=1727285922117&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA4MTcwMDQyMSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&CID=6697aa55-d375-45a6-b921-16ccbcb8b260"
        target="_blank">formatting details and more.</a>
    </div>
  </div>
  <div class="middle-section">
    <div class="file-manager-modal-mobile-scrollable">
      <div id="file-manager-modal-upload">
        <ng-container *ngIf="!featureFlags.imageUploader">
          <app-file-manager-upload id="file-manager-modal-upload-original" [uploadType]="selectedFileType" (onCancel)="closeModal()"
          (onFileProcessed)="onFileUploaded($event)" (onUploadingInProgress)="onUploadingInProgress($event)" />
        </ng-container>
        <ng-container *ngIf="featureFlags.imageUploader">
          <app-file-manager-upload-beta id="file-manager-modal-upload-beta" [uploadType]="selectedFileType" (onCancel)="closeModal()"
                  (onFileProcessed)="onFileUploaded($event)" (onUploadingInProgress)="onUploadingInProgress($event)" />
        </ng-container>
      </div>
      <div id="xls-not-supported-notification" *ngIf="isFileTypeSelected('spreadsheets')">
        <app-notification-card
          message="Gene.AI no longer supports the .xls file type. Please save your files as .xlsx and re-upload to continue."
          type="warning"
          color="#E71316"
          [closable]="false"
        />
      </div>

      <div id="file-search-bar-container" *ngIf="isMobileOrTablet()">
        <img id="icon-mobile" src="../../../../../assets/icons/search/search.svg" alt="">
        <input type="text" kmdFormInput id="file-search-input-mobile"
          [placeholder]="isMobile ? '  Search' : 'Search documents'" autocomplete="off" (input)="onSearchDocument($event)"
          [(ngModel)]="search" />
      </div>

      <div id="file-manager-modal-mydocs">
          <ng-container *ngIf="!featureFlags.imageUploader">
            <app-file-manager-my-docs
              id="file-manager-my-docs"
              #myDocsComponent
              [fileType]="selectedFileType.fileType"
              [searchTerm]="searchTerm">
            </app-file-manager-my-docs>
          </ng-container>
          <ng-container *ngIf="featureFlags.imageUploader">
            <app-file-manager-my-docs-beta
              #myDocsComponent
              id="file-manager-my-docs-beta"
              [fileType]="selectedFileType.fileType"
              [searchTerm]="searchTerm">
            </app-file-manager-my-docs-beta>
          </ng-container>
      </div>
    </div>
  </div>
  <div class="bottom-section">
    <div id="file-manager-action-controls">
      <app-file-manager-action-controls (onClose)="closeModal()" />
    </div>
    <div id="confirmation-modal-wrapper" class="custom-modal">
      <file-manager-confirmation-modal [fileType]="selectedFileType.fileType" [headline]="getConfirmationModalHeadline()"
        (onConfirm)="onConfirmConfirmationModal($event)" (onCancel)="onCancelConfirmationModal()" />
    </div>
    <div id="acknowledgement-modal-wrapper" class="custom-modal">
      <app-file-manager-acknowledgement-modal (onCancel)="closeAcknowledgementModal()"
        (onConfirm)="onAcknowledgementAccepted()" />
    </div>
  </div>
</div>


