import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { GroupMembershipService } from '@services/group-membership/group-membership.service';
import { Member } from "@shared/models/group-membership/members/member.model";
import { catchError, map, Observable, of, switchMap } from 'rxjs';

export const USER_NOT_IN_GROUP_VALIDATOR_KEY = 'userNotInGroup';
export const USER_NOT_IN_AZURE_VALIDATOR_KEY = 'userNotInAzure';
export const USER_UNEXPECTED_ERROR_VALIDATOR_KEY = 'userUnexpectedError';

const azureEmailCache = new Map<string, boolean>();

export const resetAzureEmailCache = () => {
  azureEmailCache.clear();
};

const setControlError = (control: AbstractControl, errorKey: string, errorValue: any): void => {
  const existingErrors = control.errors || {};
  existingErrors[errorKey] = errorValue;
  control.setErrors(existingErrors);
};

export const memberInGroupValidator = (group: Member[], groupMembershipService: GroupMembershipService): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.value || !control.value.mail) {
      return of(null);
    }

    const memberEmail = control.value.mail.trim().toLowerCase();
    const groupEmails = group.map(member => member.mail.trim().toLowerCase());

    if (groupEmails.length === 0) {
      return of(null);
    }

    if (groupEmails.includes(memberEmail)) {
      control.setErrors(null);
      return of(null);
    }

    if (azureEmailCache.has(memberEmail)) {
      const isInAzure = azureEmailCache.get(memberEmail)!;
      const errorKey = isInAzure ? USER_NOT_IN_GROUP_VALIDATOR_KEY : USER_NOT_IN_AZURE_VALIDATOR_KEY;
      setControlError(control, errorKey, true);
      return of({ [errorKey]: true });
    }

    return groupMembershipService.getUserByEmail(memberEmail).pipe(
      map(() => true),
      catchError((error) => {
        if (error?.code === 'Request_ResourceNotFound') {
          return of(false);
        } else {
          console.error('Unexpected error during Azure validation:', error);
          return of(null);
        }
      }),
      switchMap((isInAzure: boolean | null) => {
        if (isInAzure === null) {
          setControlError(control, USER_UNEXPECTED_ERROR_VALIDATOR_KEY, true);
          return of({ [USER_UNEXPECTED_ERROR_VALIDATOR_KEY]: true });
        }

        azureEmailCache.set(memberEmail, isInAzure);
        const errorKey = isInAzure ? USER_NOT_IN_GROUP_VALIDATOR_KEY : USER_NOT_IN_AZURE_VALIDATOR_KEY;
        setControlError(control, errorKey, true);
        return of({ [errorKey]: true });
      })
    );
  };
}
