<kmd-alerts kmd-error [(show)]="showError"
            [alertText]="'Sorry, your prompt couldn\'t be processed. Please rephrase or shorten your prompt.<br>Make sure to review and comply with the Gene.AI Use Guidelines'"
            [dismissible]="true"></kmd-alerts>

<div class="agent-wizard-toolbar">
  <div class="agent-wizard-toolbar-content">
    <a routerLink="/agents">
      <img src="../assets/icons/arrows/arrowLeft.svg"> Agents</a>
    <h1>New Agent</h1>
    <p>Answer a few guided questions below to create your agent. Alternatively, some experienced users may prefer creating their agent through the <span (click)="toggleParentView()"> manual configuration form.</span></p>
  </div>
</div>

<div class="sub-header">
  <h2>Agent Builder Wizard</h2>
</div>

<div class="agent-wizard-content">
  <div class="agent-wizard-table" #table [scrollTop]="table.scrollHeight">
    <app-chat #chat
              [username]="(username$ | async)?.name || 'NA'"
              [(lastSystemMessage)]="lastSystemMessage"
              [showSavePrompt]="false"
              [showCopyPrompt]="false"
    ></app-chat>
  </div>
  <div class="agent-wizard-input">
    <app-prompt-input
      [username]="(username$ | async)?.name || 'NA'"
      [showPromptSettingsButton]="false"
      [showCharacterCounter]="false"
      [promptSettings]="unusedPromptSettings"
      (promptSentEvent)="submit($event)"></app-prompt-input>
    <ng-content></ng-content>
  </div>
</div>

<kmd-modal class="loading-modal" [id]="'loadingResponseModal'" modalSize="small" [closeOnClickingOutside]="false">
  <div>Generating response</div>
  <kmd-loading-wheel></kmd-loading-wheel>
</kmd-modal>

