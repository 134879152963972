import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  setObserver(element: any, callbackFn: (entry: any) => object): ResizeObserver {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        callbackFn(entry);
      }
    });

    observer.observe(element);

    return observer;
  };

  removeObserver(observer: ResizeObserver): void {
    observer.disconnect();
  }

  removeObservers(observers: ResizeObserver[]): void {
    observers.forEach(observer => this.removeObserver(observer));
  }
};
