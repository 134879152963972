<ng-container *ngIf="publicPrompts$ | async as publicPrompts; else loading">
  <div class="wrapper">
    <table *ngIf="publicPrompts.length !== 0">
      <thead>
        <tr>
          <td>Category</td>
          <td>Title</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let publicPrompt of publicPrompts">
          <td>{{ publicPrompt.category }}</td>
          <td>{{ publicPrompt.title }}</td>
          <td class="public-prompt-action-wrapper">
            <button kmdLinkButton (click)="viewPrompt(publicPrompt)">View</button>
            <div> | </div>
            <button kmdLinkButton (click)="showPopover($event,publicPrompt); popOver1.toggle($event)"
              kmdTooltip="Use this prompt on a chat" tooltipPlacement="right">Use</button>
            <kmd-popover #popOver1 [position]="'left'" popoverPosition="bottom" [positionX]="posX" [positionY]="posY">
              <div class="buttons-tooltip-container">
                <button class="tooltip-use-button" (click)="useOnActualChat()" kmdLinkButton><u>Use in current
                  chat</u></button>
                <hr>
                <button class="tooltip-use-button" (click)="useOnNewChat()" kmdLinkButton><u>Use in new chat</u></button>
              </div>
            </kmd-popover>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="expand">
    <span class="center" *ngIf="publicPrompts.length === 0">
      It appears there are no public prompts at the moment. Share your prompts publicly for others to benefit from!
    </span>
  </div>
</ng-container>
<ng-template #loading>
  <div *ngIf="!showError" class="expand">
    <kmd-loading-wheel class="center"></kmd-loading-wheel>
  </div>
  <div style="margin-top: 10px">
    <kmd-alerts id="loadError" kmd-error [show]="showError" [alertText]="errorMessage"></kmd-alerts>
  </div>
</ng-template>

<app-modal-read-only-prompt #modalReadOnlyPrompt [prompt]="selectedPrompt!">

</app-modal-read-only-prompt>
