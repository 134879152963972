<div class="notification-card-wrapper" *ngIf="show" [style.border-left-color]="color">
    <div class="notification-card-content">
        <div class="icon-wrapper" *ngIf="showIcon">
            @if (type === 'warning') {
            <app-warning-icon width="25px" height="25px" [color]="color" />
            }
        </div>
        <p>
            <span class="label" *ngIf="label.length">{{label}}</span>
            <span class="message" *ngIf="message.length">{{message}}</span>
        </p>
    </div>
    <div class="close-icon-wrapper" *ngIf="closable">
        <app-close-icon width="16px" height="16px" (click)="close()" />
    </div>
</div>