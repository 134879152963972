import { Injectable } from "@angular/core";
import { NewsBannerService } from "./news-banner.service";
import { NewsBanner, NewsBannerType } from "@shared/models/news-banner/news-banner.model";
import { map, Observable, take, tap } from "rxjs";
import { CookieService } from "ngx-cookie-service";

@Injectable({
    providedIn: 'root'
})
export class NewsBannerManager {

    private readonly VERSIONED_NEWS_BANNERS: NewsBanner[] = [
        { type: 'MAIN_CHAT', version: 10 },
        { type: 'AGENT_CHAT', version: 1 },
    ];

    private _userNewsBannerPreferences: NewsBanner[] | undefined;

    constructor(
        private cookieService: CookieService,
        private newsBannerService: NewsBannerService
    ) { };

    fetchUserNewsBannerPreferencesByType$(type: NewsBannerType): Observable<NewsBanner | undefined> {
        if (this._userNewsBannerPreferences) {
            return new Observable((observer) => {
                observer.next(this._userNewsBannerPreferences?.find((banner) => banner.type === type));
                observer.complete();
            });
        }

        return this.newsBannerService.get()
            .pipe(
                take(1),
                tap((newsBanners) => this._userNewsBannerPreferences = newsBanners),
                map((newsBanners) => newsBanners.find((banner) => banner.type === type)),
            );
    };

    isUserUsingBannerPreferencesCookie(): boolean {
        return this.cookieService.get('bannerEnabled') !== '';
    };

    shouldShowNewsBanner(type: NewsBannerType): boolean {
        const newsBanner = this.VERSIONED_NEWS_BANNERS.find((banner) => banner.type === type);
        if (!newsBanner) {
            return false;
        }

        const userNewsBanner = this._userNewsBannerPreferences?.find((banner) => banner.type === type);
        if (!userNewsBanner) {
            return true;
        }

        return userNewsBanner.version < newsBanner.version;
    };

    optOutFromNewsBanner(type: NewsBannerType): Observable<void> {
        const newsBanner = this.VERSIONED_NEWS_BANNERS.find((banner) => banner.type === type)!;
        return this.newsBannerService.optOut(newsBanner)
            .pipe(
                tap(() => this.updateUserNewsBannerPreferences(newsBanner))
            );
    };

    migrateMainChatCookiePreferencesToDatabase$(): Observable<void> {
        const bannerVersionCookie = this.cookieService.get('bannerVersion');

        const newsBanner: NewsBanner = {
            type: 'MAIN_CHAT',
            version: parseInt(bannerVersionCookie)
        };

        return this.newsBannerService.optOut(newsBanner)
            .pipe(
                tap(() => {
                    this.updateUserNewsBannerPreferences(newsBanner);
                    this.cookieService.delete('bannerEnabled');
                    this.cookieService.delete('bannerVersion');
                    this.cookieService.delete('bannerReset');
                })
            );
    };

    updateUserNewsBannerPreferences(newBanner: NewsBanner): void {
        const existingPreferences = this._userNewsBannerPreferences ?? [];

        const hasMatch = existingPreferences.some(banner => banner.type === newBanner.type);

        this._userNewsBannerPreferences = hasMatch
            ? existingPreferences.map(banner =>
                banner.type === newBanner.type
                    ? { ...banner, version: newBanner.version }
                    : banner
              )
            : [...existingPreferences, newBanner];
    };
}
