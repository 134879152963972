export type AodGroupUser = {
  id: string;
  email: string;
  role: string;
};

export const GroupUserSortOptions = {
  USER_EMAIL_ASC: 'User Email A-Z',
  USER_EMAIL_DESC: 'User Email Z-A',
  ROLE_ADMIN_FIRST: 'Role Admin First',
  ROLE_USER_FIRST: 'Role User First'
};

export const GroupUserRoleOptions = {
  ALL_ROLES: 'All Roles',
  ONLY_ADMINS: 'Only Admins',
  ONLY_USERS: 'Only Users'
};

export const GroupUserRoleType: string[] = ['Admin', 'User'];
