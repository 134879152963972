export interface FileManagerConfig {
  fileUpload: {
    text: boolean;
    spreadsheets: boolean;
    images: boolean;
  }
};

export const defaultFileManagerConfig: FileManagerConfig = {
  fileUpload: {
    text: true,
    spreadsheets: true,
    images: true
  }
};

export const agentFileManagerConfig: FileManagerConfig = {
  fileUpload: {
    text: true,
    spreadsheets: false,
    images: false
  }
};
