<div class="alert-wrap">
  <kmd-alerts kmd-error [(show)]="showAlert" [alertText]="alertText"></kmd-alerts>
  <kmd-alerts kmd-success [(show)]="showSuccess" [alertText]="alertText" [icon]="true" [floating]="true"></kmd-alerts>
</div>
<div class="center" *ngIf="isLoading">
  <kmd-loading-wheel></kmd-loading-wheel>
</div>

<kmd-accordion #categoriesView (onOpen)="closePreviousSection($event)" *ngIf="prompts.length !== 0">
  <kmd-accordion-tab *ngFor="let category of categories()" [headerText]="category">
    <ul>
      <li class="prompt-item" *ngFor="let prompt of promptsByCategory(category)">
        <span (click)="onSelect(prompt)" class="prompt-title clickable">{{prompt.title}}</span>
        <div class="action-buttons-container">
          <span class="clickable" id="sharing-feature" *featureFlag="'promptSharing'"
            (click)="onShare(prompt,'share-prompt-modal');">
            <img src="assets/icons/share/share-24px-mono.svg" alt="">
          </span>
          <span class="clickable" (click)="onEdit(prompt);">
            <fa-icon [icon]="faEdit" size="lg"></fa-icon>
          </span>
          <span class="clickable" (click)="onDelete(prompt,'delete-prompt-modal');">
            <fa-icon [icon]="faTrashCan" size="lg"></fa-icon>
          </span>
        </div>
      </li>
    </ul>
  </kmd-accordion-tab>
</kmd-accordion>

<span id="emptyTextId" class="empty-text" *ngIf="showEmptyText">No prompts saved...</span>

<kmd-modal [id]="'share-prompt-modal'" modalSize="medium" headline="Share Prompt" (modalClosed)="resetPromptToShare()">
  <app-share-prompt *ngIf="promptToShare" (shareValuesChange)="onShareValuesChange($event)"
    [prompt]="promptToShare"></app-share-prompt>
</kmd-modal>

<kmd-modal [id]="'delete-prompt-modal'" modalSize="mini" headline="Delete prompt">
  <div>
    You're about to delete this prompt, this action is permanent and you won't be able to recover it. Do you want to
    delete it?
  </div>
  <div class="modal-buttons-container">
    <button (click)="closeModal('delete-prompt-modal')" kmdSecondaryButton>Cancel</button>
    <button (click)="delete(promptToDelete!)" kmdFeaturedButton>Delete</button>
  </div>
</kmd-modal>

<app-modal-edit-prompt id="modalEditPromptId" #modalEditPromptComponent [prompt]="editPrompt!"
  (promptEditEvent)="promptSaved($event)" />
