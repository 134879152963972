<div class="agent-chat-toolbar">
  <div class="agent-chat-toolbar-content max-width-agent-chat">
    <div class="toolbar-buttons">
      <a id="backtoAgents" (click)="backToAgents()" class="back-to-agents">
        <img src="../assets/icons/arrows/arrowLeft.svg"> Back to Agents</a>

      <div class="agent-chat-toolbar-buttons">
        <button kmdFeaturedButton class="agent-btn mobile" (click)="navigateToAgentChat()">
          <img class="icon-new-agent" src="../../../assets/icons/plus/plus-16px-mono.svg">New Chat
        </button>
      </div>
    </div>
    <div class="agent-name-like-container">
      <div class="agent-information">
        <h1>{{ agent?.name }}</h1>
      </div>
      <button kmdFeaturedButton class="agent-btn desktop" (click)="navigateToAgentChat()">
        <img class="icon-new-agent" src="../../../assets/icons/plus/plus-16px-mono.svg">New Chat
      </button>
    </div>
    <p class="agent-description">{{ agent?.description }}</p>
  </div>
</div>
<app-chat-wrapper *ngIf="!loadingChat; else loading"
  [contentPosition]="'bottom'"
  [isStreamingResponse]="isStreamingResponse"
  [promptInputConfig]="promptInputConfig"
  (promptSubmit)="onPromptSubmit($event)"
  (stopStreaming)="stopStreaming()">
  <div class="empty-message-container" *ngIf="messages.length === 0 && !loadingChat">
    <p>
      Remember to check any Agent responses for accuracy.
      NOTE: Agents should <strong>not</strong> be relied upon for legal or
      scientific advice or be used for making decisions about people.
      For more guidelines relating to Agents,
      please see the <a href="/guidelines" target="_blank">Gene.AI Guidelines</a>.
    </p>
    <img id="agent-avatar-image" src="../../../../assets/images/agent-avatar.png" />
    <h2 id="agent-name">{{ agent?.name }}</h2>
    <p id="empty-message-agent-description" *ngIf="!hasConversationStarters(); else conversationStarters">
      {{ agent?.description }}
    </p>
    <ng-template #conversationStarters>
      <app-agent-conversation-starter [conversationStarters]="agent?.conversationStarters!"
        (conversationStarterEvent)="handleConversationStarter($event)">
      </app-agent-conversation-starter>
    </ng-template>
  </div>
  <div id="chat-container" #chatContainer [scrollTop]="shouldScrollToBottom? chatContainer.scrollHeight: getScrollTop()"
    (scroll)="onScroll($event)">
    <div class="messages-wrapper">
      <div class="message-bubble" *ngFor="let msg of messages">
        <ng-container *ngIf="msg.user">
          <app-user-message [chatMessage]="msg.user.content" [type]="userMessageTypeConstant.AGENT"/>
        </ng-container>
        <ng-container *ngIf="msg.assistant">
          <ng-container *ngIf="!msg.isGenerating; else generatingResponse">
            <ng-container *ngIf="msg.assistant | async as assistantMsg">
              <app-assistant-message [type]="AssistantMessageTypeConstant.AGENT" #assistantMessage [messageNode]="assistantMsg" [isGeneratingResponse]="false"
                [isMessageCompleted]="isMessageCompleted" [imageCache]="imageCache"
                (activeMessageChange)="onActiveAssistantMessageChange($event)" />
            </ng-container>
          </ng-container>
          <ng-template #generatingResponse>
            <app-assistant-message [type]="AssistantMessageTypeConstant.AGENT" #assistantMessage [isGeneratingResponse]="true" [imageCache]="imageCache" />
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <app-news-banner *ngIf="messages.length === 0" class="banner-modal" [headerTitle]="bannerModalTitle" [type]="'AGENT_CHAT'">
    <div>Click the paperclip icon below to upload and query text, PDF and powerpoint files with ease. </div>
    <span>
      Want tips? Visit our iConnect page to <a target="_blank" class="a-announced-feature" [href]="bannerLearnMoreUrl">learn more</a>.
    </span>
  </app-news-banner>
</app-chat-wrapper>
<ng-template #loading>
  <div class="loading-wheel-wrapper">
    <kmd-loading-wheel *ngIf="loadingChat"></kmd-loading-wheel>
  </div>
</ng-template>
