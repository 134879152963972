<app-modal [headline]="'Revoking Shared Access'" [id]="id">
    @if (isOwnerOutOfGroup) {
        <p id="missing-owner-in-group-disclaimer" class="disclaimer">
          This shared agent's owner is not part of the group anymore, this agent would be moved under your private agents, would
          you like to continue?
        </p>
    } @else if (isOwnerMissing) {
    <p id="missing-owner-disclaimer" class="disclaimer">
      This shared agent's owner does not exist in Azure anymore, this agent would be moved under your private agents, would
      you like to continue?
    </p>
    } @else if (isUserOwner) {
        <p id="owner-disclaimer" class="disclaimer">
            Changing this shared Agent to private will revoke access for all group members and admins.
            The Agent will only be accessible to you as the designated owner.
            All files linked to this Agent by non-owners will be removed and the Agent won't be able to use them.
            Do you want to proceed?
        </p>
    } @else {
        <p id="co-admin-disclaimer" class="disclaimer">
            Changing this shared Agent to private will revoke access for all group members and admins.
            The Agent will only be accessible to the designated owner ({{owner}}) as a private agent.
            All non-owner files linked to this Agent will be removed and the Agent won't be able to use them.
            Do you want to proceed?
        </p>
    }
    <div class="action-buttons">
        <button kmdInfoButton (click)="close()">Cancel</button>
        <button kmdFeaturedButton (click)="accept()">Accept</button>
    </div>
</app-modal>
