import { Location, DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChatMessage } from "@app/chat/chat-message";
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";
import { AgentsService } from "@app/core/services/agents/agents.service";
import { ChatService } from "@app/core/services/chat/chat.service";
import { FileManagerService } from "@app/core/services/file-manager/file-manager.service";
import { UuidService } from "@app/core/services/uuid.service";
import { agentFileManagerConfig } from "@app/shared/components/file-manager/file-manager";
import { PromptInputConfig } from "@app/shared/components/prompt-input-new/prompt-input-new";
import { AssistantMessageTypeConstant } from '@app/shared/constants/assistant-message/assistant-message-type';
import { UserMessageTypeConstant } from "@app/shared/constants/user-message/user-message-type";
import { Agent } from "@app/shared/models/agent";
import { Chat } from "@app/shared/models/chat.model";
import { FileManagerDoc } from "@app/shared/models/file-manager/file-manager-doc.model";
import { FileReference } from "@app/shared/models/file-reference.model";
import { MessageNode } from "@app/shared/models/message-node.model";
import { Author, Message } from "@app/shared/models/message.model";
import { PromptSettings } from "@app/shared/models/prompt-settings.model";
import { AlertService } from "@services/alert/alert.service";
import { ChatQAService } from "@services/chat-qa/chat-qa.service";
import { ChatWrapperComponent } from "@shared/components/chat-wrapper/chat-wrapper.component";
import { PromptSubmitEvent } from "@shared/models/prompt-submit-event";
import { Prompt } from "@shared/models/prompt.model";
import { catchError, EMPTY, lastValueFrom, map, Observable, of, Subject, switchMap, tap, throwError } from "rxjs";
import { concatMap, finalize, take, takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-agent-chat-new',
  templateUrl: './agent-chat-new.component.html',
  styleUrl: './agent-chat-new.component.css'
})
export class AgentChatNewComponent implements OnInit, OnDestroy {
  @ViewChild(ChatWrapperComponent) chatWrapperComponent!: ChatWrapperComponent;
  @ViewChild('chatContainer') chatContainer?: ElementRef;
  @ViewChildren('assistantMessage', { read: ElementRef }) assistantMessages?: QueryList<ElementRef>;

  @Output() promptSavedEvent = new EventEmitter();

  private chatId: string = '';
  private agentId: string = '';
  private promptSettings: PromptSettings = new PromptSettings(3, "PROFESSIONAL", false, 'gpt-4-o');

  protected readonly bannerModalTitle = 'NEW! Upload documents directly to the agent chat!';
  protected readonly bannerLearnMoreUrl = 'https://thermofisher.sharepoint.com/:u:/r/sites/GenerativeAICenterofExcellence/SitePages/Agents.aspx?csf=1&web=1&e=5NYwPh';

  promptInputConfig: PromptInputConfig = {
    features: {
      addFiles: true,
      changeModel: false,
    },
    display: {
      promptSettings: false,
    },
    fileManagerConfig: agentFileManagerConfig

  };
  chatId$?: Observable<string>;
  agent?: Agent;
  destroy$: Subject<boolean> = new Subject<boolean>();
  lastUserMessage?: Message;
  lastSystemMessage?: Message;
  agentChatDecorator?: AgentChatDecorator;
  userMessageTypeConstant = UserMessageTypeConstant;
  imageCache = new Map<string, Observable<string | null>>();
  loadingChat = true;
  isGeneratingResponse = false;
  isStreamingResponse: boolean = false;
  isUserScrolling = false;
  isMessageCompleted = false;
  messageTree: MessageNode[] = [];

  messages: {
    id: string,
    user?: MessageNode,
    assistant?: Observable<MessageNode | null>,
    error?: boolean,
    isGenerating: boolean
  }[] = [];

  protected shouldScrollToBottom: boolean = true;
  private scrollTop: number = 0;
  protected readonly AssistantMessageTypeConstant = AssistantMessageTypeConstant;

  constructor(
    public activatedRoute: ActivatedRoute,
    private chatQAService: ChatQAService,
    private agentsService: AgentsService,
    private router: Router,
    private location: Location,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private chatHistoryUpdateService: ChatHistoryUpdateService,
    private alertService: AlertService,
    private uuidService: UuidService,
    private chatService: ChatService,
    private datePipe: DatePipe,
    private fileManagerService: FileManagerService,
  ) { };

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        tap((params) => {
          this.agentId = params["agentId"];
          this.agentsService.agent(this.agentId).subscribe({
            next: (agent) => {
              this.agent = agent;
            },
            error: (error) => {
              if (error.status == 404) {
                this.returnToPublicAgents();
              } else {
                this.handleErrorNavigation(error)
              }
            }
          });
        }),
        map((params) => params["chatId"]),
        tap((chatId) => {
          this.chatId$ = of(chatId);
          this.chatId = chatId;
        })
      )
      .subscribe(() => {
        this.messageTree = [];
        this.messages = [];

        if (!this.chatQAService.activePrompt) {
          this.loadExistingChat();
        } else {
          this.displayNewMessage();
        }
      });
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onActiveAssistantMessageChange(changeRequest: { assistantMessageNode: MessageNode, nextSiblingIndex: number }): void {
    const newActiveBranch = this.chatQAService.shiftActiveBranch(this.messageTree, changeRequest.assistantMessageNode, changeRequest.nextSiblingIndex);

    this.messages = newActiveBranch.map((node) => {
      if (node.role === 'USER') {
        return {
          id: node.id,
          user: node,
          isGenerating: false
        };
      }

      return {
        id: node.id,
        assistant: of(node),
        isGenerating: false
      };
    });
  }

  loadExistingChat(): void {
    this.shouldScrollToBottom = true;
    this.loadingChat = true;
    this.chatId$?.pipe(
      take(1),
      switchMap((chatId) => {
        if (!chatId) {
          this.loadingChat = false;
          return EMPTY;
        }
        return this.chatQAService.getExistingMessages(chatId);
      }),
      catchError((error) => {
        this.router.navigate(['/error']);
        return throwError(() => error);
      }),
      tap(async (messages: MessageNode[]) => {
        this.messageTree = messages;
        const activeBranch = this.chatQAService.getActiveBranch(this.messageTree);

        const lastUserMessageNode = activeBranch.reverse().find(node => node.role === 'USER');

        if (lastUserMessageNode?.content?.message) {
          this.lastUserMessage = lastUserMessageNode.content.message;

          if (this.lastUserMessage.fileReferences?.length) {
            await this.setFilesInManager(this.lastUserMessage.fileReferences);
          } else {
            this.fileManagerService.clearSelectedAndSetType();
            this.fileManagerService.handleSelectedFiles([]);
          }
        }

        activeBranch.reverse();

        this.messages = activeBranch.map((node) => {
          if (node.role === 'USER') {
            return {
              id: node.id,
              user: node,
              isGenerating: false
            };
          }

          if (node.content?.message) {
            this.lastSystemMessage = node.content.message;
          }
          return {
            id: node.id,
            assistant: of(node),
            isGenerating: false
          };
        });

        this.isMessageCompleted = true;
        this.loadingChat = false;
        this.cdr.detectChanges();
      })
    ).subscribe();
  }

  getLastRoleUserMessage(): MessageNode | undefined {
    for (let i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages[i].user) {
        return this.messages[i].user;
      }
    }
    return undefined;
  }

  displayNewMessage(): void {
    this.shouldScrollToBottom = false;
    const activePrompt = this.chatQAService.activePrompt!;

    this.isGeneratingResponse = true;
    this.loadingChat = false;

    let previousParentId: string | null = null;
    let assistantChatMessage$: Observable<ChatMessage>;

    if (this.messages.length === 0) {
      assistantChatMessage$ = this.getChatAndAssistantMessage(activePrompt.prompt);
    } else {
      previousParentId = this.messages[this.messages.length - 1].id;
      assistantChatMessage$ = this.getAssistantMessage(activePrompt.prompt);
    }

    const parent: MessageNode = this.chatQAService.addMessageToTree(this.messageTree, activePrompt.message, previousParentId);
    const prompt = parent.content!.message;
    this.messages.push({ id: parent.id, user: parent, isGenerating: false });
    this.messages.push({ id: 'loading', assistant: of(), isGenerating: true });
    this.scrollToEnd();
    this.lastUserMessage = prompt;

    assistantChatMessage$.pipe(
      catchError((error) => {
        console.log(error);
        // TODO: Refactor this logic. Error handling should be separated from the message handling.
        return of(null);
      }),
      map((assistantMessage) => {
        if (!assistantMessage) {
          this.chatWrapperComponent.setPromptText(prompt.text);
          this.messages = this.messages.slice(0, this.messages.length - 2);
          return;
        }

        const node = this.chatQAService.addMessageToTree(this.messageTree, assistantMessage.message, parent.id);
        const index = this.messages.findIndex((message) => message.id === node.id);

        const message$ = of(node).pipe(
          catchError(() => of(null)),
          tap(() => this.cdr.detectChanges())
        );

        if (index !== -1) {
          this.messages[index].assistant = message$;
        } else {
          this.messages.pop();
          this.messages.push({ id: node.id, assistant: message$, isGenerating: false });

        }
        this.lastSystemMessage = node.content!.message;
      }),
    ).subscribe({
      next: () => {},
      error: (error) => console.error(error),
    });

    this.cdr.detectChanges();
    this.startAutoScrollToLastAssistantMessage();
  };

  async setFilesInManager(fileReferences: FileReference[]): Promise<void> {
      fileReferences = fileReferences.filter(fileReference => {
        const titleWithoutExtension = fileReference.title.split('.').slice(0, -1).join('.').trim();
        return titleWithoutExtension.length > 0;
      });
      if (fileReferences.length> 0) {
        const fileIds: string[] = fileReferences.map((fileReference) => fileReference.id);
        const fileExtension = `${fileReferences[0].title.split('.').pop()}`!;
        const fileDocs: FileManagerDoc[] = await lastValueFrom(this.fileManagerService.getFiles(fileIds));
        let fileType = this.fileManagerService.getCategoryForFileType(fileExtension);
        this.fileManagerService.setSelectedFileType(fileType);
        this.fileManagerService.handleSelectedFiles(fileDocs);
      }
    }

  getChatAndAssistantMessage(prompt: Prompt): Observable<ChatMessage> {
    let promptValue = prompt.text;
    let message = new Message(promptValue, new Author("USER"), new Date().toISOString(), Object.assign({}, this.promptSettings), this.lastSystemMessage?.id);
    message.id = this.uuidService.random();
    if (prompt.files && prompt.files.length > 0) {
      message.files = prompt.files;
    }
    return this.agentsService.promptV2(this.agent!, message)
      .pipe(
        catchError((error) => {
          this.isStreamingResponse = false;
          this.isGeneratingResponse = false;
          this.alertService.showError("An error occurred while creating the chat.");
          return throwError(() => error);
        }),
        takeUntil(this.destroy$),
        finalize(() => {
          this.isStreamingResponse = false;
          this.isGeneratingResponse = false;
          this.isMessageCompleted = true;
          this.destroy$.next(true);
          this.cdr.detectChanges();
        }),
        tap((response) => {
          if (!this.isGeneratingResponse) {
            return;
          }
          this.chatId$ = of(response.agentChat.chat.id!);
          this.chatId = response.agentChat.chat.id!;
          prompt.chatId = response.agentChat.chat.id;
          this.location.replaceState(`/agents/${this.agentId}/chat/${response.agentChat.chat.id}`);
          this.isGeneratingResponse = false;
          this.isStreamingResponse = true;
          this.lastSystemMessage = response.prompt.answer!;
        }),
        map((response) => new ChatMessage(response.prompt.answer!, undefined))
      );
  };

  getAssistantMessage(prompt: Prompt): Observable<ChatMessage> {
    let promptValue = prompt.text;
    let message = new Message(promptValue, new Author("USER"), new Date().toISOString(), Object.assign({}, this.promptSettings), this.lastSystemMessage?.id);
    message.id = this.uuidService.random();
    if (prompt.files && prompt.files.length > 0) {
      message.files = prompt.files;
    }

    return this.chatId$!.pipe(
      concatMap((chatId) => this.getChatAndSetAgentChatDecorator(chatId)),
      concatMap((chat) =>
        this.agentsService.promptToChatV2(this.agent!, chat!, message)
          .pipe(
            catchError((error) => {
              this.isStreamingResponse = false;
              this.isGeneratingResponse = false;
              this.alertService.showError("An error occurred while sending the chat.");
              return throwError(() => error);
            }),
            finalize(() => {
              this.isStreamingResponse = false;
              this.isGeneratingResponse = false;
              this.chatHistoryUpdateService.notifyChatUpdated(chat?.id!);
              this.cdr.detectChanges();
            })
          )
      ),
      takeUntil(this.destroy$),
      finalize(() => {
        this.isMessageCompleted = true;
        this.isStreamingResponse = false;
        this.cdr.detectChanges();
      }),
      tap((response) => {
        if (!this.isGeneratingResponse) {
          return;
        }

        this.isGeneratingResponse = false;
        this.isStreamingResponse = true;
        this.lastSystemMessage = response.answer!;
      }),
      map((response) => new ChatMessage(response.answer!, undefined))
    );
  }

  private getChatAndSetAgentChatDecorator(chatId: string): Observable<Chat | null> {
    return this.chatService.getChat(chatId).pipe(
      tap(chat => this.agentChatDecorator = new AgentChatDecorator(chat, this.agentId)),
      catchError((error) => {
        console.error(error);
        this.handleErrorNavigation(error);
        return of(null);
      })
    );
  }

  onPromptSubmit(event: PromptSubmitEvent): void {
    this.chatQAService.setNewActivePrompt(event);
    this.chatWrapperComponent.setPromptText("");
    this.displayNewMessage();
  };

  scrollToEnd(): void {
    if (!this.chatContainer) {
      return;
    }

    this.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    this.renderer.setProperty(this.chatContainer.nativeElement, 'scrollTop', this.scrollTop);
    this.isUserScrolling = false;
  }

  startAutoScrollToLastAssistantMessage(): void {
    const lastAssistantMessage = this.assistantMessages?.last?.nativeElement;

    if (lastAssistantMessage) {
      this.isUserScrolling = false;
      this.scrollToLastAssistantMessage();

      const scrollInterval = setInterval(() => {
        if (!this.isUserScrolling) {
          this.scrollToLastAssistantMessage();
        } else {
          clearInterval(scrollInterval);
        }
      }, 500);
    }
  }

  scrollToLastAssistantMessage(): void {
    const lastAssistantMessage = this.assistantMessages?.last?.nativeElement;

    if (lastAssistantMessage) {
      const offsetTop = lastAssistantMessage.offsetTop;
      const offsetMargin = 75;
      this.scrollTop = offsetTop - offsetMargin;
      this.isUserScrolling = false;

      if (this.chatContainer) {
        this.renderer.setProperty(this.chatContainer.nativeElement, 'scrollTop', this.scrollTop);
      }
    }
  }

  onScroll(event: any) {
    this.isUserScrolling = !(event.target!.offsetHeight + event.target!.scrollTop >= event.target!.scrollHeight);
    this.scrollTop = event.target.scrollTop;
  }

  stopStreaming() {
    this.isStreamingResponse = false;
    this.destroy$.next(true);
    this.cdr.detectChanges();
  }

  getScrollTop(): number {
    return this.scrollTop;
  }

  get showRating(): boolean {
    return !!this.agent?.rating && this.agent.rating.likes > 0;
  }

  backToAgents() {
    this.router.navigate(['/agents'], {queryParams: {tab: this.selectedTab()}});
  }

  navigateToAgentChat() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([`/agents/${this.agent?.id}/chat`]));
  }

  displayRating() {
    return this.isPublicAgent() && this.showRating;
  }

  getFormattedDate(date: Date): string {
    return this.datePipe.transform(date, 'dd/MMM/yyyy')!;
  }

  isPublicAgent() {
    return this.agent?.isPublic;
  }

  selectedTab(): string {
    return this.agent?.isPublic ? 'public-agents' : 'my-agents';
  }

  returnToPublicAgents() {
    this.router.navigate(['/agents'], { queryParams: { tab: 'public-agents' }, replaceUrl: true });
  }

  hasConversationStarters() {
    return this.agent?.conversationStarters?.length! > 0 && this.agent?.conversationStarters![0] !== "";
  }

  handleConversationStarter(conversationStarterText: string) {
    this.chatWrapperComponent.setPromptText(conversationStarterText);
  }

  private handleErrorNavigation(error: HttpErrorResponse) {
    let errors = [400, 401, 403, 404, 500, 503]
    if (errors.includes(error.status)) {
      this.router.navigate(['/error'], { state: { status: error.status }, replaceUrl: true })
    } else {
      this.router.navigate(['/error'], { state: { status: 500 }, replaceUrl: true })
    }
  }
}

export class AgentChatDecorator extends Chat {
  agentID: string

  constructor(chat: Chat, agentID: string) {
    super(chat.title, chat.created);
    this.id = chat.id;
    this.gptEnabled = chat.gptEnabled;
    this.promptSettings = chat.promptSettings;
    this.agentID = agentID;
  }
}
