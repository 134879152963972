import { FileUploadOptions, FileUploadType } from "@shared/constants/file-manager/file-upload-options";
import { FileUpload } from "../file-upload.model";

const getFileUploadType = (fileType: string): FileUploadType => {
  const key = Object.keys(FileUploadOptions).find(key => FileUploadOptions[key].extensions.includes(fileType.toLowerCase()));
  return FileUploadOptions[key!];
};

export const getFileExtension = (fileName: string): string => {
  return `.${fileName.split('.').pop()}`;
};

export const isFileTypeUnsupported = (file: FileUpload): boolean => {
  const fileExtension = getFileExtension(file.name);

  const uploadType = getFileUploadType(fileExtension);

  return (uploadType !== undefined)
    ? uploadType.unsupportedFileTypes.includes(fileExtension)
    : false;
};
