import { Component, ElementRef, ViewChild } from '@angular/core';
import { FileUpload } from "@shared/models/file-upload.model";
import { FilesService } from "@services/files/files.service";
import { S3UploadService } from "@services/files/s3-upload.service";
import { catchError, from, mergeMap, Observable, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { formatDate } from "@angular/common";
import { KmdModalService } from "gds-atom-components";
import { environment, featureFlags } from "@environments/environment";
import { Router } from '@angular/router';
import { AgentsService } from '@app/core/services/agents/agents.service';
import { FileUploadOptions } from "@shared/constants/file-manager/file-upload-options";

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent {

  @ViewChild('uploader') uploaderElement!: ElementRef;

  processedFiles!: Observable<Array<FileUpload>>;

  showError = false;
  showDeleteError = false;
  showDeleteSuccess = false;
  showEmptyMessage = false;
  canClearFiles$: Observable<boolean> = of(false);
  successfulUploadsCount: number = 0;
  totalFilesCount: number = 0;
  hasErrorUploading: boolean = false;
  errorMessage = "Unable to load the file list";
  deleteErrorMessage = "There was an error while deleting the file!";
  deleteSuccessMessage = "The file has been successfully deleted!";
  isFileLinkedToAgents: boolean = false;
  notificationMessage = "Scanned/Converted PDFs of images, presentations, or spreadsheets are currently not supported."
  newNotificationMessageLabel = "Note: Uploads are now available in main chat. ";
  newNotificationMessage = "Click the paperclip icon to upload up to five files in .txt, .docx, or .pdf formats, or one file in .csv or .xlsx format. " +
    "Presentations, images, and videos are not supported. For details, please visit our Gen AI FAQs"

  fileUploadToBeDeleted?: FileUpload;
  deleteFileUploadFlag: boolean = environment.deleteFileUploadFlag;

  protected readonly featureFlags = featureFlags;

  constructor(private filesService: FilesService,
    private s3UploadService: S3UploadService,
    private kmdModalService: KmdModalService,
    private router: Router,
    private agentsService: AgentsService) {
    this.getProcessedFiles();
  }

  processFiles() {
    this.uploaderElement.nativeElement.getAllFiles().then((data: any[]) => {
      this.totalFilesCount = data.length;
      this.successfulUploadsCount = 0;
      this.hasErrorUploading = false;

      data.forEach((file) => {
        this.uploadFile(file)
      })
    })
  }

  onFileRestarted(event: any) {
    this.uploadFile(event.detail)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFileRemoved(event: any) {
    this.uploaderElement.nativeElement.getAllFiles().then((data: any[]) => {
      this.totalFilesCount = data.length;
      if (this.totalFilesCount === 0) {
        this.canClearFiles$ = of(false);
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFileSelected(event: any) {
    this.processFiles();
  }

  deleteFileUpload() {
    this.kmdModalService.close('delete-file-upload')
    this.filesService.delete(this.fileUploadToBeDeleted!).subscribe({
      next: () => {
        this.getProcessedFiles();
        this.showDeleteSuccess = true;
      },
      error: () => {
        this.showDeleteError = true;
      }
    });
  }

  cancelDeleteFileUpload() {
    this.kmdModalService.close('delete-file-upload')
  }

  confirmDelete(fileUpload: FileUpload) {
    this.fileUploadToBeDeleted = fileUpload
    this.agentsService.getAgentsLinkedToFile(fileUpload).subscribe(
      {
        'next': (agents) => {
          this.isFileLinkedToAgents = agents.length > 0;
        },
        'error': () => {
          this.showDeleteError = true;
        }
      }
    );

    this.kmdModalService.open('delete-file-upload')
  }

  uploadFile(file: any) {
    let fileUpload = new FileUpload(undefined, file.name, undefined, undefined, undefined, file.size);

    from(this.uploaderElement.nativeElement.updateFileStatus(file.id, "in-progress")).pipe(
      mergeMap(() => {
        return this.filesService.create(fileUpload)
      }),
      mergeMap((fileUpload) => {
        return this.s3UploadService.uploadFile(file.fileReference, fileUpload.url!)
      }),
      mergeMap((event: HttpEvent<any>) => {
        let progress = this.mapProgress(event)
        return of(progress)
      })
    ).subscribe({
      next: (res) => {
        this.uploaderElement.nativeElement.updateFileProgress(file.id, res.progress)
        this.uploaderElement.nativeElement.updateFileStatus(file.id, res.status)
        if (res.status == "completed") {
          this.successfulUploadsCount++;
        }
      }
      ,
      error: () => {
        this.hasErrorUploading = true;
        this.canClearFiles$ = of(true);
        this.uploaderElement.nativeElement.updateFileStatus(file.id, "error")
      },
      complete: () => {
        if (this.successfulUploadsCount === this.totalFilesCount && !this.hasErrorUploading) {
          this.clearFiles();
        }
        this.getProcessedFiles();
      }
    });
  }

  mapProgress(event: HttpEvent<any>) {
    if (event.type === HttpEventType.Response) {
      return {
        status: 'completed',
        progress: 100
      }
    } else if (event.type == HttpEventType.UploadProgress) {
      return {
        status: 'in-progress',
        progress: event.total
          ? Math.round((100 * event.loaded) / event.total)
          : 0
      }
    } else {
      return {
        status: 'in-progress',
        progress: 0
      }
    }
  }

  getProcessedFiles() {
    this.processedFiles = this.filesService.list().pipe(
      map((processedFiles) => {
        return processedFiles
          .sort((fileOne: FileUpload, fileTwo: FileUpload) => {
            return fileTwo.updatedAt!.getTime() - fileOne.updatedAt!.getTime()
          })
      }),
      catchError((err) => {
        this.showError = true
        return throwError(err)
      })
    )
    if (this.processedFiles == null) {
      this.showEmptyMessage = true
    }
  }

  getDateForFile(file: FileUpload) {
    if (file.status != "PROCESSED") {
      return "---"
    }
    return formatDate(file.updatedAt!, 'MMM-dd-yyyy', 'en-US')
  }

  getFileStatus(status: string) {
    return (status == "UPLOAD_REQUESTED") ? "UPLOADING" : status
  }

  clearFiles() {
    this.uploaderElement.nativeElement.clearAllFiles()
    this.canClearFiles$ = of(false);
  }

  moveToAskMyDocs() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/document-query']));
  }

  isDeleteEnabled(file: FileUpload) {
    return file.status === 'PROCESSED'
      || file.status === 'ERROR'
      || file.status === 'UPLOAD_REQUESTED'
      || (file.status === 'UPLOADED' && this.isSpreadsheet(file.name));
  }

  private isSpreadsheet(fileName: string) {
    const extensions = FileUploadOptions['SPREADSHEETS'].extensions;
    const fileExtension = '.' + fileName.split('.').pop()?.toLowerCase();
    return extensions.includes(fileExtension);
  }
  goToFAQS(): void {
    window.open('https://thermofisher.sharepoint.com/:u:/r/sites/GenerativeAICenterofExcellence/SitePages/Education%20%26%20resources/Generative-AI-FAQs.aspx?csf=1&web=1&e=ageFFd', '_blank');
  }
}
