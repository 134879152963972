<div class="personal-docs">
  <ng-template #loading>
    <div style="margin-top: 10px">
      <kmd-alerts id="loadError" kmd-error [show]="showError" [alertText]="errorMessage"></kmd-alerts>
    </div>
  </ng-template>
  <div *ngIf="!featureFlags.uxRevamp" class="toolbar-file-uploader">
    <div class="toolbar-content-file-uploader">
      <button id="go-to-ask-my-docs-btn" kmdPrimaryButton (click)="moveToAskMyDocs()">
        <img class="nav-menu-ul-li-icon" src="../assets/icons/document%20query/document-24px-bold.svg">
        <span>Ask My Docs</span>
      </button>
    </div>
  </div>
  <div class="uploader-container">
    <span class="title">My Docs</span>
    <core-uploader id="uploader" *ngIf="!featureFlags.uxRevamp" #uploader
      hint="Accepted file types: .txt, .docx, .pdf | Max 5 files at a time | Max ~ 1000 pages/file" showicon
      variant="condense" (fileRemoved)="onFileRemoved($event)" (fileRestarted)="onFileRestarted($event)"
      (fileSelected)="onFileSelected($event)"
      acceptedformats="text/plain,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      maxfiles="5">
    </core-uploader>
    <div *ngIf="!featureFlags.uxRevamp && (canClearFiles$ | async)">
      <button kmdInfoButton id="clearBtn" (click)="clearFiles()" [disabled]="!(canClearFiles$ | async)!">
        Clear Files
      </button>

    </div>
    <core-notification class='notification-amd'
      label='{{featureFlags.uxRevamp ?  newNotificationMessageLabel : "Note:"}}'
      message='{{featureFlags.uxRevamp ?  newNotificationMessage : notificationMessage}}' variant='inline'
      state='information' timer='0' nocloseicon>
      <core-button *featureFlag="'uxRevamp'" (click)="goToFAQS()" label="Gen AI FAQs" [type]="'button'"
        variant="outline" size="regular" slot="button"></core-button>
    </core-notification>
    <span class="upload-message">Upload and process your documents so you can chat with them!</span>

  </div>
  <div class="uploaded-files-list">
    <span *ngIf="!featureFlags.uxRevamp" id="betaUsersRefresh" class="upload-message">BETA Users: Please manually
      refresh this page until the status of your files is
      PROCESSED</span>
    <table>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Status</th>
          <th>Date Processed</th>
          <th *ngIf="deleteFileUploadFlag">Delete File</th>
        </tr>
      </thead>
      <ng-container *ngIf="processedFiles | async as processedFiles; else loading">
        <tbody *ngIf="processedFiles.length > 0">
          <tr *ngFor="let processedFile of processedFiles">
            <td>{{ processedFile.name }}</td>
            <td>{{ getFileStatus(processedFile.status!) }}</td>
            <td>{{ getDateForFile(processedFile) }}</td>
            <td>
              <button kmdLinkButton (click)="confirmDelete(processedFile)" class="delete-file-button"
                [disabled]="!isDeleteEnabled(processedFile)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </ng-container>
      <td class="center" *ngIf="(processedFiles | async)?.length === 0" colspan="4">
        It seems like you don't have any documents available to query. Upload your documents to interact with them.
      </td>
    </table>
  </div>
  <kmd-modal [id]="'delete-file-upload'" modalSize="mini" headline="Warning!">
    <div>
      <b *ngIf="isFileLinkedToAgents; then fileLinkedToAgents else regularFile"></b>
    </div>
    <div class="modal-buttons-container">
      <button id="btnCancelDeleteFileUpload" (click)="cancelDeleteFileUpload()" kmdSecondaryButton>Cancel</button>
      <button id="btnDeleteFileUpload" (click)="deleteFileUpload()" kmdFeaturedButton>Delete</button>
    </div>
  </kmd-modal>
  <kmd-alerts [(show)]="showDeleteError" kmd-error [alertText]="deleteErrorMessage"></kmd-alerts>
  <kmd-alerts [(show)]="showDeleteSuccess" kmd-success [alertText]="deleteSuccessMessage"></kmd-alerts>

</div>

<ng-template #fileLinkedToAgents>
  <b id="fileLinkedToAgents">The file will be permanently deleted and removed from any linked Agents, do you want to
    proceed?</b>
</ng-template>
<ng-template #regularFile>
  <b id="regularFile">You're about to delete this file, this action is permanent and you won't be able to recover it. Do
    you want to
    delete it?</b>
</ng-template>