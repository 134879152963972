import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-history-zero-state',
  templateUrl: './chat-history-zero-state.component.html',
  styleUrls: ['./chat-history-zero-state.component.css']
})
export class ChatHistoryZeroStateComponent {

}
