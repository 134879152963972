import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-alert-icon',
    templateUrl: './alert-icon.component.html',
    styleUrls: ['./alert-icon.component.css']
})
export class AlertIconComponent {
    @Input() width!: string;
    @Input() height!: string;
    @Input() color!: string;
    @Input() shape: 'circle' | 'triangle' = 'triangle';
}