import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewsBannerType } from '@shared/models/news-banner/news-banner.model';
import { NewsBannerManager } from '@services/news-banner/news-banner-manager.service';
import { AlertService } from '@services/alert/alert.service';
import { catchError, finalize, tap, throwError } from 'rxjs';

@Component({
  selector: 'app-news-banner',
  templateUrl: './news-banner.component.html',
  styleUrls: ['./news-banner.component.css']
})
export class NewsBannerComponent implements OnInit {
  private static readonly MAIN_CHAT_TYPE = 'MAIN_CHAT';

  @Input() headerTitle: string = '';
  @Input() type!: NewsBannerType;

  @Output() onCloseEvent = new EventEmitter<void>();

  display: boolean = false;
  doNotShowAgain: boolean = false;
  disableDoNotShowAgain: boolean = false;

  constructor(
    private newsBannerManager: NewsBannerManager,
    private alertService: AlertService
  ) { };

  ngOnInit() {
    if (this.isMigrationNeeded()) {
      this.migrateMainChatCookiePreferencesToDatabase();
    } else {
      this.loadNewsBannerInfo();
    }
  };

  private isMigrationNeeded(): boolean {
    return this.isMainChatType() && this.newsBannerManager.isUserUsingBannerPreferencesCookie();
  };

  private isMainChatType(): boolean {
    return this.type === NewsBannerComponent.MAIN_CHAT_TYPE;
  }

  loadNewsBannerInfo(): void {
    this.newsBannerManager.fetchUserNewsBannerPreferencesByType$(this.type)
      .pipe(
        catchError(this.handleError('Failed to load user news banner preferences.')),
        tap(() => this.checkIfBannerShouldBeDisplayed())
      ).subscribe();
  }

  migrateMainChatCookiePreferencesToDatabase(): void {
    this.newsBannerManager.migrateMainChatCookiePreferencesToDatabase$()
      .pipe(
        catchError(this.handleError('Failed to migrate news banner cookie preferences to database.')),
        tap(() => this.checkIfBannerShouldBeDisplayed())
      ).subscribe();
  }

  private checkIfBannerShouldBeDisplayed(): void {
    this.display = this.newsBannerManager.shouldShowNewsBanner(this.type);

    if (!this.display) {
      this.onCloseEvent.emit();
    }
  }

  onDoNotShowAgainChange(): void {
    this.doNotShowAgain = !this.doNotShowAgain;
    this.requestNewsBannerOptOut();
  }

  private requestNewsBannerOptOut(): void {
    this.disableDoNotShowAgain = true;
    this.newsBannerManager.optOutFromNewsBanner(this.type)
      .pipe(
        catchError(this.handleError('Failed to opt out from news banner.', () => this.doNotShowAgain = false)),
        finalize(() => this.disableDoNotShowAgain = false)
      ).subscribe({
        next: () => this.close()
      });
  }

  private handleError(message: string, callback?: () => void) {
    return (error: any) => {
      this.alertService.showError(message);

      if (callback) callback();

      this.onCloseEvent.emit();
      return throwError(() => error);
    };
  }

  close(): void {
    this.display = false;
    this.onCloseEvent.emit();
  }
}
