<svg
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 16 17"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.59 2.5H7.42L1 13.5L1.59999 14.5H14.4L15 13.5L8.59 2.5ZM9.00999 13H7.00999V11H9.00999V13ZM9.00999 10H7.00999V5.5H9.00999V10Z"
    [attr.fill]="color"
  />
</svg>
