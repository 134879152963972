<div class="about-us">
  <div class="about-us-content">
    <h4>
      Welcome to Gene.AI!
    </h4>
    <br>
    <p>Gene.AI is our premier, company-approved tool that gives colleagues the ability to leverage the power of
      generative AI and <a
        [href]="links.aboutUs" target="_blank">large
        language model</a> (LLM) technology to generate text content in a manner similar to human
      language. You can use the tool to streamline everyday tasks and improve the way you work. Watch our quick video
      on
      how to use the tool and don’t forget to review the other resources and notes below for some helpful
      information.</p>
    <video class="video" controls>
      <source [src]="aboutUsVideo" type="video/mp4">
      Your browser does not support the video tag.
    </video>

    <h6><b>Resources for getting started</b></h6>

    <ul>
      <li>Understand our <a
        [href]="links.guidelines" target="_blank">
        guidelines for using Gene.AI</a>
      </li>
      <li>Read up on our <a
        [href]="links.faqs" target="_blank">
        Gene.AI FAQs</a>
      </li>
      <li>Watch our <a
        [href]="links.videoTraining" target="_blank">
        video training series</a> for demos of Gene.AI
      </li>
      <li>Check out our <a
        [href]="links.tipsAndTricks" target="_blank">
        Gen.AI Tips & Tricks</a> on Viva Engage for helpful prompting tips
      </li>
    </ul>

    <h6><b>Some important notes for using Gene.AI:</b></h6>

    <ul>
      <li><b>Gene.AI may be accessed only by Thermo Fisher employees</b> and is intended to support them in their daily
        work.
      </li>
      <li>We are currently leveraging the same GPT 3.5 Large Language Model from OpenAI that powers the public
        generative AI tool, ChatGPT.
      </li>
      <li>Like ChatGPT, Gene.AI <b>has no access to the internet or any real-time data</b>. It may provide wholly or
        partially false answers to factual questions.
      </li>
      <li>At this time, <b>internal company information is not known to the model</b>, so questions on such topics
        should not
        be expected to yield accurate results. Access to additional company information will be made available in future
        releases.
      </li>
      <li>Colleagues are responsible for <b>reviewing all outputs from Gene.AI and cross-checking it with reputable
        sources</b>. Always exercise reasonable and prudent judgement before relying on outputs from Gene.AI.
      </li>
    </ul>
    <p>Expect more features and updates soon!</p>

  </div>
</div>
