<div class="modal-prompt-edit">
  <kmd-modal *featureFlag="'savedPromptsAccordion'" id="{{id}}" modalSize="large" headline="Edit prompt">
    <app-form-prompt [prompt]="prompt!"
                     (formAcceptEvent)="onFormAccept($event)"/>
  </kmd-modal>

  <div class="paginated-prompts-modal" *featureFlag="'savedPromptsPaginated'">
    <kmd-modal *featureFlag="'savedPromptsPaginated'" id="{{id}}" modalSize="medium" headline="Edit prompt">
      <div class="form-prompt" *ngIf="prompt">
        <div class="category-container">
          <span class="field-title">Category <span class="required-mark">*</span></span>
          <input-select-box [(value)]="prompt.category!" [options]="categories"
                            [readOnly]="readOnly"></input-select-box>
        </div>

        <div class="title-container">
          <span class="field-title">Title <span class="required-mark">*</span></span>
          <input kmdFormInput id="prompt-title-input" maxlength="50" [(ngModel)]="prompt.title" [disabled]="readOnly"/>
          <countdown-label [maxlength]=50 [value]="prompt.title"/>
        </div>

        <div class="prompt-container">
          <span class="field-title">Prompt <span class="required-mark">*</span></span>
          <textarea kmdFormTextarea name="prompt-text-area" id="prompt-txt-area" cols="30" rows="4"
                    [(ngModel)]="prompt.text"
                    maxlength="5000" [disabled]="readOnly"></textarea>
          <countdown-label [maxlength]=5000 [value]="prompt.text"/>
        </div>

        <div class="prompt-settings-container">
          <div class="prompt-settings-item">
            <span class="field-title">Model: </span>
            <label class="prompt-settings-label">
              {{getModelLabelText(prompt.promptSettings.model)}}
            </label>
          </div>
          <div class="prompt-settings-item">
            <span class="field-title">Tone: </span>
            <label class="prompt-settings-label">{{prompt.promptSettings.tone | titlecase}}</label>
          </div>
          <div class="prompt-settings-item">
            <span class="field-title">Creativity: </span>
            <label class="prompt-settings-label">{{prompt.promptSettings.creativity}}</label>
          </div>
          <div class="prompt-settings-item">
            <span class="field-title">Web Search: </span>
            <label class="prompt-settings-label">
              {{getWebSearchLabelText(prompt.promptSettings.webSearch)}}
            </label>
          </div>
        </div>

        <div class="modal-buttons-container">
          <core-button label="Cancel" type="button" variant="information"
                       (click)="close()">
          </core-button>
          <core-button label="Save" type="button" variant="primary"
                       (click)="onFormAccept(prompt)"
                       disabled="{{ !isSaveButtonEnabled() }}">
          </core-button>
        </div>
      </div>
    </kmd-modal>
  </div>

  <kmd-alerts kmd-error [(show)]="showAlert"
              alertText="The prompt could not be saved. There was a problem contacting the server."
              [dismissible]="true"></kmd-alerts>
</div>

