import { Component, ViewChild } from '@angular/core';
import { Agent } from "../../shared/models/agent";
import { HasUnsavedChanges } from "../../core/guards/unsaved-changes-guard.service";
import { AgentCreationComponent } from "../agent-creation/agent-creation.component";

@Component({
  selector: 'app-agent-builder',
  templateUrl: './agent-builder.component.html',
  styleUrls: ['./agent-builder.component.css']
})
export class AgentBuilderComponent implements HasUnsavedChanges {

  agent?: Agent;

  activeView : 'wizard' | 'form' = 'wizard';

  @ViewChild('agentCreationComponent ') agentCreationComponent?: AgentCreationComponent;

  toggleView() {
    if (this.activeView === 'wizard') {
      this.activeView = 'form';
    } else {
      this.activeView = 'wizard';
    }
  }

  reviewAgentEnabled() {
    return this.agent &&
      this.agent.name.length > 0 &&
      this.agent.description.length > 0 &&
      this.agent.instructions.length > 0 &&
      this.agent.category.length > 0
  }

  hasUnsavedChanges(): boolean {
    return  !this.agentCreationComponent?.isAgentSaved()
  }

}
