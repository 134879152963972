<div class="alert-wrap">
  <kmd-alerts kmd-success [(show)]="showSuccessAlert" [alertText]="alertText" [icon]="true"
              [floating]="true"></kmd-alerts>
  <kmd-alerts kmd-error [(show)]="showErrorAlert" [alertText]="alertText" [icon]="true" [floating]="true"></kmd-alerts>
</div>

<div class="empty-message-container" *ngIf="categories.length <= 0">
  <p>You haven't saved any prompts yet</p>
  <p>Saving prompts can save you time and ensure accurate responses from Gene.AI.</p>
</div>
<div class="paged-prompt">
  <div class="categories-dropdowns" *ngIf="!isLoading && categories.length > 0">
    <label kmdFormLabel>Category</label>
    <kmd-dropdown [(ngModel)]="selectedCategory" [options]="categories" (onChange)="onCategoryChange($event)"
                  [externalLabel]="true"></kmd-dropdown>
  </div>

  <div class="center" *ngIf="isLoading">
    <kmd-loading-wheel></kmd-loading-wheel>
  </div>

  <div class="prompts-table" *ngIf="!isLoading && categories.length > 0">
    <table class="prompt-list-table">
      <thead>
      <tr>
        <th *ngFor="let header of promptTableHeaders">{{ header }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let prompt of pagedPrompts.content">
        <td>
            <span (click)="openModal(prompt, 'saved-prompt-modal')" class="prompt-title clickable">
              {{ prompt.title }}
            </span>
        </td>
        <td>{{ getUserFriendlyModelName(prompt.promptSettings.model) }}</td>
        <td>{{ prompt.promptSettings.webSearch === true ? 'Yes' : 'No' }}</td>
        <td>
            <span class="state-chip" [ngClass]="getPromptStatusStyling(prompt)">
              {{ getPromptStatus(prompt) }}
            </span>
        </td>
        <td>
          <div class="action-buttons">
              <span (click)="openModal(prompt,'share-prompt-modal');" [className]="shareActionDisabled(prompt)">
                <img src="assets/icons/share/Size=24px, Style=Mono.svg" alt="">
              </span>
            <span (click)="onEdit(prompt)">
                <img src="assets/icons/edit/size=24px, style=mono.svg" alt="">
              </span>
            <span (click)="openModal(prompt,'delete-prompt-modal')">
                <img src="assets/icons/bin/size=24px, style=mono.svg" alt="">
              </span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="pagination">
    <kmd-pagination #pagination
                    [totalResults]="pagedPrompts.totalElements"
                    [resultsPerPageOptions]="[15, 20, 25]"
                    (onPageChange)="onPageChange($event)"></kmd-pagination>
  </div>
</div>

<kmd-modal [id]="'saved-prompt-modal'" headline="Saved prompt" id="saved-prompt-modal"
           (modalClosed)="resetSelectedPrompt()">
  <div *ngIf="selectedPrompt">
    <div class="saved-prompt-text">
      {{ selectedPrompt.text }}
    </div>
    <div class="modal-buttons-container">
      <core-button label="Cancel" type="button" variant="information" size="medium"
                   (click)="closeModal('saved-prompt-modal')">
      </core-button>
      <core-button label="Use prompt" type="button" variant="primary" size="medium" (click)="onSelect(selectedPrompt)">
      </core-button>
    </div>
  </div>
</kmd-modal>

<kmd-modal [id]="'share-prompt-modal'" modalSize="small" headline="Share Prompt" (modalClosed)="resetSelectedPrompt()">
  <app-share-prompt *ngIf="selectedPrompt" [prompt]="selectedPrompt"
                    (shareValuesChange)="onShareValuesChange($event)"></app-share-prompt>
</kmd-modal>

<kmd-modal [id]="'delete-prompt-modal'" headline="Delete prompt" id="delete-prompt-modal"
           (modalClosed)="resetSelectedPrompt()">
  <div>
    You're about to delete this prompt, this action is permanent and you won't be able to recover it. Do you want to
    delete it?
  </div>
  <div class="modal-buttons-container">
    <core-button id="cancel" label="Cancel" type="button" variant="information" size="medium" fullspace="true"
                 (click)="closeModal('delete-prompt-modal')">
    </core-button>
    <core-button label="Delete prompt" type="button" variant="primary" size="medium" fullspace="true"
                 (click)="onDelete(selectedPrompt!)">
    </core-button>
  </div>
</kmd-modal>

<app-modal-edit-prompt id="modalEditPromptId" #modalEditPromptComponent [prompt]="selectedPrompt!"
                       (promptEditEvent)="promptSaved($event)"/>
