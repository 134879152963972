import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChatHistoryRecordActions } from '@app/shared/models/actions/chat-history-record-actions';
import { ChatMetaData } from '@app/shared/models/chat-metadata.model';
import { DatePipe } from "@angular/common";
import { RecordComponent } from "@app/my-library/shared/record/record.component";
import { ChatService } from "@services/chat/chat.service";
import { AgentChatsService } from "@services/agentchats/agentchats.service";
import { AlertService } from "@services/alert/alert.service";
import { Router } from "@angular/router";
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";

@Component({
  selector: 'app-record-mobile',
  templateUrl: './record-mobile.component.html',
  styleUrls: ['./record-mobile.component.css'],
  providers: [DatePipe]
})
export class RecordMobileComponent extends RecordComponent implements OnChanges {
  @Input() actionEvent?: ChatHistoryRecordActions;
  @Output() selectedEvent = new EventEmitter<ChatMetaData>();
  @Output() editedChatEvent = new EventEmitter<ChatMetaData>();

  selected: boolean = false;
  @Input() selectedRecord?: ChatMetaData;

  constructor(chatService: ChatService,
              agentChatsService: AgentChatsService,
              alertService: AlertService,
              datePipe: DatePipe,
              router: Router,
              chatHistoryUpdateService: ChatHistoryUpdateService) {
    super(chatService, agentChatsService, alertService, datePipe, router, chatHistoryUpdateService);
  }

  override formatDate(date: Date): string {
    return (this.datePipe.transform(date, 'dd-MMM-yyyy HH:mm') || '').toLowerCase();
  }

  onClick() {
    if (this.record) {
      if (this.selected) {
        this.selected = false;
        this.selectedEvent.emit(undefined);
      } else {
        this.selected = true;
        this.selectedEvent.emit(this.record);
      }
    }
  }

  deleteCancelled() {
    this.deletedChatEvent.emit(undefined);
  }

  toggleSelected() {
    this.selected = !!(this.record && this.selectedRecord)
      && this.record.id === this.selectedRecord.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['actionEvent']) {
      if(this.record) {
        if (changes['actionEvent'].currentValue === 'rename') {
          this.onEditChat(this.record);
          this.editedChatEvent.emit(this.record);
        }
        else if (changes['actionEvent'].currentValue === 'delete') {
          this.onDeleteChat(this.record);
        }
        else if (changes['actionEvent'].currentValue === 'open') {
          this.startChat();
        }
      }

      if(changes['actionEvent'].currentValue === undefined) {
        this.selected = false;
      }
    }

    if (changes['selectedRecord'] && this.record) {
      this.toggleSelected();
    }
  }
}
