<div class="banner-container">
  <div class="banner">
    <div class="banner-header">
      <span class="header-title">{{headerTitle}}</span>
      <div class="close-icon-container">
        <img src="../../../../assets/icons/close/size=16px, style=mono-black.svg" class="close-icon"  (click)="closeBannerModal()">
      </div>
    </div>
    <div class="banner-content">
      <ng-content></ng-content>
    </div>
    <div class="banner-ask">
      <kmd-checkbox class="checkbox" (onClick)="isChecked($event)"></kmd-checkbox>
      <span class="banner-ask-label">Don't show this again</span>
    </div>
  </div>
</div>
