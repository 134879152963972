<app-modal
  [id]="id"
  [headline]="headline"
  [subHeadline]="subHeadline"
  (close)="onSharingModalClose()">
  <div #modalContent class="modal-inner-content">
    <div class="top-modal-content">
      <div class="share-to-wrapper">
        <label class="input-title">
          Share to<label class="required">*</label>
        </label>
        <div class="custom-select-wrapper">
          <div class="share-to-input-wrapper">
            <div class="share-to-dropdown-selector"
                 (click)="onShareToDropdownClick()"
                 [ngClass]="{ 'disabled': isDropdownDisabled }"
                 (clickOutside)="onShareToDropdownOutsideClick()">
              <p>{{ selectedShareToOption.label }}</p>
              <app-caret-icon width="8px" height="4px" color="#1B1B1D" type="dropdown" [isOpen]="isShareToMenuOpen"/>
            </div>
            <div class="share-to-dropdown-options" *ngIf="isShareToMenuOpen">
              <ng-container *ngFor="let option of shareToOptions">
                <div [ngClass]="!option.disabled ? 'share-to-option' : 'disabled-option'"
                     *ngIf="!option.hideOption"
                     (click)="!option.disabled && onSelectionChanged(option)">
                  <p>{{ option.label}}</p>
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="loadingGroups" class="loading-groups-spinner">
            <kmd-loading-wheel></kmd-loading-wheel>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isPublicAgentShareFlow">
        <div class="agreement-container">
          <p class="agreement-text">
            By sharing your agent you are agreeing to display your name in the agent information.
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="displayMicrosoftGroupForm">
        <form id="group-selection-form" [formGroup]="formGroup">
          <div class="group-form-container">
            <div class="title-container">
              <label class="input-title">
                Add from your Microsoft group (only one group)<label class="required">*</label>
              </label>
            </div>
            <div class="group-input-wrapper">
              <div id="group-input" class="form-group-input-field" [ngClass]="{'error': hasNoMatchingGroups}">
                <ng-container *ngIf="!isGroupSelected">
                  <input type="text" id="group-name-input" (focus)="onGroupNameInputFocus()"
                         formControlName="typedGroupName" (clickOutside)="onGroupNameInputOutsideClick()"
                         autocomplete="off">
                  <app-alert-icon *ngIf="hasNoMatchingGroups" width="14px" height="14px" shape="circle"
                                  color="#E71316"/>
                </ng-container>
                <ng-container *ngIf="isGroupSelected">
                  <div class="group-details-wrapper">
                    <div class="group-details">
                      <p>{{ selectedGroup!.displayName }}</p>
                      <p *ngIf="groupMembers.length" id="group-member-count"
                         (click)="showMemberListModal()">{{ groupMemberCountLabel }}</p>
                    </div>
                    <i class="close-btn" (click)="clearSelectedGroup()"></i>
                  </div>
                </ng-container>
              </div>
              <div id="group-dropdown" *ngIf="filteredGroups.length && displayGroupList">
                <ng-container *ngFor="let group of filteredGroups">
                  <div class="group-item" (click)="onGroupItemClick(group)">
                    <p class="group-name">{{ group.displayName }}</p>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="hasNoMatchingGroups">
                <p id="invalid-group-name">Group name not found, please try a different name.</p>
              </div>
            </div>
            <p class="input-description">
              Enter your Microsoft group name or reference the <a
              href="https://myaccount.microsoft.com/groups/groups-i-belong-to" target="_blank">groups you are a member
              of</a>.
            </p>
          </div>
          <div class="group-form-container">
            <div class="title-container">
              <label class="input-title">
                Add co-admin emails (minimum of 2, maximum of {{ maxAllowedAdmins }})<label class="required">*</label>
              </label>
            </div>
            <app-chip-box
              [defaultChips]="defaultEmailChips"
              [chips]="emailChips"
              [disabled]="isChipBoxDisabled"
              [config]="CHIPS_CONFIG"
              [loading]="loadingMembers"
              (add)="onChipBoxAdd($event)"
              (backspace)="onChipBoxBackspace()"
              (remove)="onChipBoxRemove($event)">
            </app-chip-box>
            <div *ngIf="newAdmins.invalid" id="admins-validation-messages" class="error-messages">
              <p id="duplicate-emails-error" class="error-message notice" *ngIf="newAdmins.hasError('duplicateMember')">
                Duplicate emails are not allowed.
              </p>
              <p id="user-not-in-group-error" class="error-message error" *ngIf="isAnyAdminNotInGroup">
                The email entered is not part of the selected group.
              </p>
              <p id="user-not-in-azure-error" class="error-message error" *ngIf="isAnyAdminNotInAzure">
                These email addresses are not recognized. Please enter valid company email(s).
              </p>
              <p id="user-unexpected-validation-error" class="error-message error" *ngIf="isAnyAdminWithUnexpectedError">
                One or more emails could not be validated due to a system error. Please review and try again.
              </p>
              <p id="user-azure-error" class="error-message error" *ngIf="hasInvalidAzureEmail">
                Co-admin assignment to this email ({{mailWithErrorOnAzure}}) failed, please try another user.
              </p>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <div class="modal-buttons-container" [ngClass]="{'basic-modal-buttons': !displayMicrosoftGroupForm }">
      <ng-container *ngIf="displayMicrosoftGroupForm">
        <div class="find-group-help-link-wrapper">
          <a
            href="https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/SitePages/Education%20%26%20resources/Generative-AI-FAQs.aspx#learn-more-about-the-gene.ai-capabilities"
            target="_blank">
            Can't find your group?
          </a>
        </div>
      </ng-container>
      <div class="modal-buttons">
        <button id="discard-changes-btn" kmdInfoButton (click)="hideModal()">Cancel</button>
        <button id='continue-editing-btn' kmdFeaturedButton [disabled]="isFormSubmitDisabled" (click)="onContinue()">
          Continue
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-confirm-dialog
  #confirmDialog
  [title]="'Cancel agent sharing'"
  [message]="'You are about to cancel the agent sharing process, would you like to be taken back to the agent\'s configuration form or to the sharing settings?'">
  <button kmdInfoButton (click)="dismissAgentSharingCancellation()">Cancel</button>
  <button kmdInfoButton (click)="onBackToAgentForm()">Back to Agent form</button>
  <button kmdFeaturedButton (click)="onBackToSharingSettings()">Back to sharing settings</button>
</app-confirm-dialog>

<app-agent-sharing-confirmation-modal
  #confirmationModal
  [isPublicShare]="isPublicAgentShareFlow"
  [sharingDetails]="sharingDetails"
  [group]="selectedGroup!"
  [forceDisable]="isRequestProcessing"
  [admins]="adminsNames"
  [membersCount]="groupMembers.length"
  (back)="onConfirmationModalBack()"
  (close)="onConfirmationModalClose()"
  (shareAgentClick)="saveAndShare()"
  (sharePublicAgentClick)="saveAndMakePublic()" />

<app-agent-sharing-group-member-list-modal
  #memberListModal
  [group]="selectedGroup"
  [groupMembers]="groupMembers" />

<app-agent-sharing-keep-private-confirmation-modal
  #keepPrivateConfirmationModal
  [isUserOwner]="isUserAgentOwner"
  [isOwnerOutOfGroup]="isOwnerOutOfGroup"
  [isOwnerMissing]="isAgentOwnerMissing"
  [agent]="sharingDetails?.agent"
  (onAcceptEvent)="updateAndUnshare()" />
