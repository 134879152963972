import { Injectable } from '@angular/core';
import { Group } from "@app/shared/models/azure/groups/group.model";

@Injectable({
  providedIn: 'root'
})
export class SharingAgentService {

  private sharingGroup: Group = {} as Group;
  private failingAdminMail: string = '';
  private selectedGroupAdminsMails: string[] = [];

  constructor() { }

  setSharingGroup(group: Group): void {
    this.sharingGroup = group;
  }

  getSharingGroup(): Group {
    return this.sharingGroup;
  }

  setFailingAdmin(failingAdminMail: string): void {
    this.failingAdminMail = failingAdminMail;
  }

  getFailingAdminMail(): string {
    return this.failingAdminMail;
  }

  setSelectedGroupAdmins(admins: string[]): void {
    this.selectedGroupAdminsMails = admins;
  }

  getSelectedGroupAdminsMails(): string[] {
    return this.selectedGroupAdminsMails;
  }

  clearAll(): void {
    this.sharingGroup = {} as Group;
    this.failingAdminMail = '';
    this.selectedGroupAdminsMails = [];
  }
}
