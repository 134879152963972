import { Component } from '@angular/core';
import { AlertService } from "@services/alert/alert.service";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {

  successes: Alert[] = [];
  successes$ = this.alertService.successes();

  errors: Alert[] = [];
  errors$ = this.alertService.errors();
  private alertIdCounter = 0;

  constructor(private alertService: AlertService) {
    this.alertService.successes().subscribe(message => {
      this.successes.push({ id: this.alertIdCounter++, message });
    })

    this.alertService.errors().subscribe(message => {
      this.errors.push({ id: this.alertIdCounter++, message });
    });
  }

  successAlertClosed(alertId: number) {
    this.successes = this.successes.filter(alert => alert.id !== alertId);
  }

  errorAlertClosed(alertId: number) {
    this.errors = this.errors.filter(alert => alert.id !== alertId);
  }

}

interface Alert {
  id: number;
  message: string;
}
