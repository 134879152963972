<div class="content" >
  <ng-container *ngIf="!featureFlags.askOurDataNewChatHeader; else newChatHeader">
    <div class="header">
      <div class="title-section ms-0">
        <div  class=" library-icomoon back-txt" (click)="back()"><i class="icon-arrow-left-mono"></i> Ask Our Data</div>
        <div class="row">
          <div class="col-10 ps-0 ms-0"><h1 class="title">{{dbname || 'Database name'}}</h1></div>
          <div class="col-2 float-end library-icomoon"><button kmdFeaturedButton class="float-end" (click)="startNewChat()"> Start new chat</button></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #newChatHeader>
    <div class="new-header">
      <div class="db-name-container" (clickOutside)="closeDropdownMenu()">
        <img src="../../assets/icons/arrows/arrowLeft.svg" class="ms-3 back-arrow-bold-icon" (click)="back()">
        <span (click)="toggleDatabaseDropdown()" class="db-name">
          {{dbname || 'Database name'}}
          <img src="{{ showModelDropdown
            ? '../../../../assets/icons/caret/caretUp-11px-mono-blue.svg'
            : '../../../../assets/icons/caret/caretDown-11px-mono-blue.svg'}}" width="8">
        </span>
        <app-context-menu #ContextMenuComponent [ngClass]="{'closed': !showModelDropdown}" [items]="itemsList"
          [slideDirection]="'down-to-up'" (isContextMenuOpen)="isContextMenuOpen($event)"
          (itemClicked)="menuItemSelected($event)" />
      </div>
    </div>
  </ng-template>

  <div class="chat-wrapper">
    <div class="chat-overview-container" (onScroll)="scrollChat($event)">
      <div class="chat-mid-content p-5 pb-0" [ngClass]="{'empty-table': !isDbConnectErr && ischatEmpty && !generateSqlFailed}"> 
          <div class="response-side" *ngIf="!isDbConnectErr" >
            <div class="response-container pb-3" id="chat-main">
              <div class="table-chat" #table [ngClass]="{'error-table': generateSqlFailed}">
                <div class="chat-content-item my-3 pt-0" *ngFor="let message of messages" [ngClass]="{'white pe-3' : message.author.role === 'USER', 'ps-3' : message.author.role !== 'USER'}">
                  <div id="chat_response_{{message.author.role}}_{{message.chatId}}" ></div>
                  <app-circle-with-initials class="float-start me-2" [user]="message.author.role === 'USER' ? 'User' : 'AskGene'"
                  [username]="(userData$ | async)?.name || 'NA'"></app-circle-with-initials>
                  <div class="ps-3 pt-2 pb-3" [ngClass]="{ 'white': message.author.role === 'USER', 'gray': message.author.role !== 'USER', 'err-block': message.author.role !== 'USER' && showErrorIcon[message.chatId]}">
                    <markdown class="message-body markdown-text float-start" >
                      {{ message.text }}
                    </markdown>
                    <i *ngIf="!isConversationResponse[message.chatId] && (resultObtained[message.chatId] || sqlResultObtained[message.chatId]) && message.author.role !== 'USER'" id="copy-query-icon" (click)="copyToClipboard(message.sql, message.chatId, message.author.role)" class="gene-icon" 
                    [ngClass]="{ 'float-end': message.author.role !== 'USER'}"
                    kmdTooltip="Copy query" tooltipPlacement="left">
                      <svg class="icon copy-icon" width="16" height="16" viewBox="0 0 16 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14 4.7069L11.2743 4.7069L11.2743 2L2 2L2 11.3103L4.73429 11.3103L4.73429 14L14 14L14 4.7069ZM3.71429 9.58621L3.71429 3.72414L9.56 3.72414L9.56 4.7069L4.73429 4.7069L4.73429 9.58621L3.71429 9.58621ZM6.41429 12.2759L6.41429 6.43103L12.2857 6.43103L12.2857 12.2759L6.41429 12.2759Z"
                              fill="#70707A"/>
                      </svg>
                    </i>
                    
                    <div *ngIf="message.summary" class=" copyable-{{message.chatId}}" >
                      <b>Summary:</b>
                      <markdown class="message-body markdown-text float-start" >{{ message.summary }}</markdown>
                    </div>
                    <ng-template [ngIf]="message.author.role === 'USER' && showSavePrompt">
                      <img src="../../assets/icons/save/save-24px-mono.svg" class="gene-icon float-end" id="save-prompt"
                          kmdTooltip="Save Prompt" tooltipPlacement="right">
                    </ng-template>
                    <ng-template [ngIf]="message.author.role !== 'USER' && showErrorIcon[message.chatId]">
                      <span class="library-icomoon warn-icon"> <i class="icon-warn-mono"></i></span>
                    </ng-template>
                    <div *ngIf="showChartContent(message.author.role, message.chatId)" class="clear-both">
                      <div id="generating-chart " class="chat-content-item p-3 pt-0 chat-loader" *ngIf="generatingChart[message.chatId] && !chartAPICalled[message.chatId]" >
                        <kmd-loading-wheel class="float-start "></kmd-loading-wheel>
                        <p class="generate">Generating chart...</p>
                      </div>
                      <a *ngIf="!generatingChart[message.chatId] && chartGenerated[message.chatId]" (click)="viewGraph(message.chatId, message.sql, message.prompt)" class="chart-link icon-link" style="cursor: pointer;text-decoration: none;">
                        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" 
                            d="M11.9993 6.38989C15.8828 6.38989 19.4457 8.29665 21.6269 11.4284L22.0249 11.9999L21.6269 12.5714C19.4457 15.7031 15.8828 17.6099 11.9993 17.6099C8.11622 17.6099 4.55241 15.7026 2.37256 12.5712L1.97485 11.9999L2.37256 11.4286C4.55241 8.29715 8.11622 6.38989 11.9993 6.38989ZM11.9993 8.38989C9.02153 8.38989 6.27177 9.74117 4.44322 11.9999C5.59639 13.4243 7.11593 14.4879 8.82798 15.0792C8.3771 14.4443 8.11199 13.6682 8.11199 12.8303C8.11199 12.1143 8.30553 11.4435 8.64313 10.8674L10.164 12.3882C10.13 12.53 10.112 12.6781 10.112 12.8303C10.112 13.8729 10.9572 14.7183 11.999 14.7183C13.0426 14.7183 13.888 13.8731 13.888 12.8303C13.888 11.7875 13.0426 10.9423 11.999 10.9423C11.8588 10.9423 11.7223 10.9576 11.5908 10.9866L10.0626 9.45842C10.6328 9.1301 11.294 8.94229 11.999 8.94229C14.1471 8.94229 15.888 10.6828 15.888 12.8303C15.888 13.6682 15.623 14.4441 15.1722 15.0788C16.8837 14.4875 18.4027 13.4242 19.5562 11.9999C17.7266 9.74079 14.9775 8.38989 11.9993 8.38989Z" 
                            fill="currentColor"/>
                        </svg>
                        <span>{{ !featureFlags.aodAdvanceAnalytics ? 'Visualizations' : 'Create chart'}}</span>
                      </a>
                      <div *ngIf="!chartGenerated[message.chatId] && chartAPICalled[message.chatId]" class="chat-content-item pt-0" >Visualization cannot be generated for this data.</div>
                      <div *ngIf="(openGraph[message.chatId] && resultObtained[message.chatId]) || featureFlags.aodAdvanceAnalytics" class="graph-container mt-3">
                        <ng-container *ngIf="featureFlags.askOurDataCodeInterpreter && !featureFlags.askOurDataPlotlyCodeInterpreter; else plotlyChart">
                          <ng-template [ngIf]="graphData[message.chatId]">
                            <div class="chart-container">
                              <div class="chart-icons-container">
                                <button kmdInfoButton size="mini" class="copy-chart-button" (click)="copyCodeInterpreterChart(message.chatId)">
                                  <svg class="copy-chart-icon" id="copy-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.4464 5.51717L5.4464 15.9433H3L3 3L15.9727 3L15.9727 5.51717L5.4464 5.51717ZM21 7.0066L6.94254 7.0066L6.94254 21H21V7.0066Z" fill="#54545C"/>
                                  </svg>
                                  copy chart
                                </button>
                                <button kmdInfoButton size="mini" class="download-chart-button" (click)="downloadCodeInterpreterChart(message.chatId)">
                                  <svg class="download-chart-icon" id="export-file-bold.svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.16 12.24L16.17 12.25L18.18 14.25H10.5V16.25H18.18L16.16 18.26L17.58 19.68L20.59 16.67L22 15.25L17.58 10.83V10.82L16.16 12.24Z" fill="#54545C"/>
                                    <path d="M13.33 18.26L13.35 18.25H8.5V12.25H13.35L13.34 12.24L14.75 10.82L16.16 9.41L16.58 9H10V2H2V22H17V21.93L13.33 18.26Z" fill="#54545C"/>
                                    <path d="M12 2V7H17L12 2Z" fill="#54545C"/>
                                  </svg>
                                  Export chart (.png)
                                </button>
                              </div>
                              <img [src]="graphData[message.chatId]" alt="Generated Chart" class="chart-image">
                            </div>
                          </ng-template>
                        </ng-container>
                        <ng-template #plotlyChart>
                          <div id="plot_{{message.id}}" class="plot_{{message.chatId}}"></div>
                          <markdown *ngIf="featureFlags.aodAdvanceAnalytics && predictiveText[message.chatId]" class="markdown-text" id="text_{{message.id}}">{{getData(message.chatId)}}</markdown>
                          <div *ngIf="!featureFlags.aodAdvanceAnalytics" class="library-icomoon mt-2">
                            <i (click)="change('line', message.chatId)" title="line chart" class="chart-icon"><img src="assets/icons/ask-our-data/line-chart.svg"/></i>
                            <i (click)="change('bar', message.chatId)" title="bar chart" class="ms-2 icon-bar-chart"></i>
                            <i (click)="change('pie', message.chatId)" title="pie chart" class="ms-2 icon-pie-chart"></i>
                            <i (click)="change('histogram', message.chatId)" title="histogram chart" class="d-none ms-2 icon-histogram-chart"></i>
                          </div>
                          <div id="plot_end_{{message.chatId}}" ></div>
                        </ng-template>
                      </div>
                  </div>

                  <ng-template [ngIf]="toShowCopyPrompt(message.author.role, message.chatId)">
                    <i id="copy-response-icon" (click)="copyToClipboard(message.prompt, message.chatId, message.author.role, false)" class="gene-icon" 
                    [ngClass]="{'float-end': message.author.role === 'USER',  'float-start': message.author.role !== 'USER' }"
                    kmdTooltip="{{message.author.role !== 'USER' ? 'Copy response' : 'Copy'}}" tooltipPlacement="right">
                      <svg class="icon copy-icon" width="16" height="16" viewBox="0 0 16 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M14 4.7069L11.2743 4.7069L11.2743 2L2 2L2 11.3103L4.73429 11.3103L4.73429 14L14 14L14 4.7069ZM3.71429 9.58621L3.71429 3.72414L9.56 3.72414L9.56 4.7069L4.73429 4.7069L4.73429 9.58621L3.71429 9.58621ZM6.41429 12.2759L6.41429 6.43103L12.2857 6.43103L12.2857 12.2759L6.41429 12.2759Z"
                              fill="#70707A"/>
                      </svg>
                    </i>
                  </ng-template>
                  </div>
                </div> 
                <div id="generating-response " class=" chat-content-item px-3 pt-0 chat-loader" *ngIf="generatingResponse"  > 
                  <app-circle-with-initials class="float-start me-2" [user]="'AskGene'"></app-circle-with-initials>
                  <div class="chat-item gray py-3 ps-2 ">
                    <kmd-loading-wheel class="float-start"></kmd-loading-wheel>
                      <p class="generate">Generating response...</p>
                  </div>
                </div>
              </div>
            </div>
        </div>  
        <div *ngIf="!isDbConnectErr && ischatEmpty && !generateSqlFailed" class="empty-chat">
            <ng-container *ngIf="featureFlags.askOurDataSamplePrompts; else defaultText">
            <app-sample-prompts (samplePromptSelectEvent)="onChipClick($event)" 
            [userName]="(userData$ | async)?.name || 'NA'" 
            [showHeaderMessage]="true"
            (samplePromptsEvent)="onSamplePromptsReceived($event)">
            </app-sample-prompts>
            </ng-container>
            <ng-template #defaultText>
            Start chatting with database
            </ng-template>
        </div>
        <div *ngIf="isDbConnectErr" >
          <div class="row col-7 min-main-content mt-5 pt-5 error-content">
            <div class="col-12 mid-content">
                <div class="warning mb-3"></div>
                <p><b>Unable to connect to database.</b></p>
                <div class="mt-3">Please try again at a later time.</div>
            </div>
          </div>
        </div>
    </div>

  </div>
  <div class="chat-input-wrapper" >
    <div class="chat-input-btns-container">
      <div class="chat-response-btns">
          <button *ngIf="generatingResponse || (featureFlags.enableChatHistorySave && generatingChart[chatId]) || savingHistory" #regenerateButton class="regenerate-button" kmdInfoButton kmdBlockButton (click)="stopResponse(true)">
          <img src="assets/icons/stop/stop-bold.svg"/>
          Stop Response
          </button>
          <button *ngIf="receivedResponse" #regenerateButton class="regenerate-button" kmdInfoButton kmdBlockButton (click)="regenerate()">
          <img src="assets/icons/regenerate/regenerate-response-icon.svg"/>
          Regenerate Response
          </button>
      </div>
      <div *ngIf="featureFlags.askOurDataNewChatHeader" class="new-chat-button-container">
        <button kmdFeaturedButton class="new-chat-button" (click)="startNewChat()">
          <img src="assets/icons/plus/plus-16px-mono.svg" class="new-chat-plus-icon"/>
          New chat
        </button>
      </div>
    </div>
    <app-prompt-input #promptInputComponent class="prompt-input-padding-bottom"
    [username]="(userData$ | async)?.name || 'NA'" [chatId]="selectedChatId"
    [promptSettings]="promptSettings"
    [disabled]="isSubmitDisabled()" 
    (promptSentEvent)="featureFlags.askOurDataSamplePrompts ? handlePrompt($event) : onSubmit($event)"
    [showPromptSettingsButton]="false"></app-prompt-input>
    <p class="disclaimer ps-4 ms-4">GeneAI can make mistakes. Consider checking important information.</p>
  </div>

</div>
</div>

<kmd-modal [id]="'consentModal'" modalSize="small" headline="Attention: Review Carefully" [closeOnClickingOutside]="false" (crossIconClicked)="back()">
  <div class="consent-modal-body-content">
    <div *ngIf="consentAPIFailed" class="mb-3">
      <kmd-alerts kmd-error [show]="true" [icon]="true">Failed to fetch data usage acknowledgement</kmd-alerts>
    </div>
    <!-- <h4>Attention: Review Carefully</h4>  -->
    <span> Our "Ask Our Data" capability is designed to interact with databases efficiently. However, due to the size and complexity of the database:</span>
    <ul class="m-0">
      <li>Some results may take some time to process. </li>
      <li>While we strive for accuracy, some outputs might be incomplete or inaccurate.</li>
    </ul> 
    <strong>Please carefully review all responses before use.</strong>
    
    <p class="mt-3 mb-0 "><kmd-checkbox [binary]="true" name="consent" (onClick)="isChecked($event)" label="I understand and acknowledge these limitations and agree to verify all outputs before use."></kmd-checkbox></p></div>
  <div style="float: right; margin-top: 10px;">
      <button kmdFeaturedButton type="button" (click)="continue()" [disabled]="!consent" >Continue</button>
  </div>
</kmd-modal>

<div *ngIf="showLoader" class="page-overlay" >
  <kmd-loading-wheel></kmd-loading-wheel>
</div>

<app-create-chart *ngIf="featureFlags.aodAdvanceAnalytics" [jsonResponse]="jsonResponse" (displayLoader)="displayLoader($event)" (displayChart)="displayChart($event)"
 [chatId]="createChartChatId" [dbId]="dbId" [sql]="selectedSql" [promptValue]="selectedPromptValue" ></app-create-chart>
 <app-aod-chat-history *ngIf="featureFlags.aodDataChatHistory" #chatHistory (displayLoader)="displayLoader($event)" (displayChat)="displayChat($event)" ></app-aod-chat-history>