<div class="content-social-media">
  <div class="gray">
    <div id="toolbar-wrapper">
      <div class="toolbar-content">
        <div class="toolbar-top">
          <div id="toolbar-title-wrapper">
            <h1 class="social-media-title">Marketing Ads</h1>
            <button kmdFeaturedButton id="new-chat" class="chat-btn" (click)="newSocialMediaChat()">
              <img class="icon-sm icon-sm-new-chat" src="assets/icons/plus/plus-16px-mono.svg">
              New Chat
            </button>
          </div>
          <div id="toolbar-subtitle-wrapper">
            <p class="social-media-subtitle">
              Thermo Fisher Scientific's use of social media networks is subject to the guidance for approved social
              networks in our
              <a href="https://thermofisher.sharepoint.com/sites/CorporateMarketingSocialMedia/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCorporateMarketingSocialMedia%2FShared%20Documents%2FGeneral%2FRisk%20Management%2FPaid%20Media%20Brand%20Safety%2Epdf&parent=%2Fsites%2FCorporateMarketingSocialMedia%2FShared%20Documents%2FGeneral%2FRisk%20Management&p=true&ga=1&isSPOFile=1&OR=Teams%2DHL&CT=1710278758381&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI1MC8yNDAyMDIwNTUxNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
                target="_blank"> Brand Safety Guardrails</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tabs max-width">
    <div id="chat">
      <div id="chat-list">
        <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
        <div *ngIf="selectedChatId == undefined; else messagesChat" class="social-media-title-section">
          <h2 class="main-title">Generate engaging social posts in just a few clicks</h2>
          <span class="subtitle">Stay consistent, creative, and productive with Gene AI Marketing Ads.</span>
        </div>
        <ng-template #messagesChat>
          <div class="table" #table [scrollTop]="table.scrollHeight">
            <app-chat [username]="(userData$ | async)?.name || 'NA'" [chatType]="'ask_gene'" [chatId]="selectedChatId"
              [(lastSystemMessage)]="lastSystemMessage" (responseSelectEvent)="handleResponseSelected($event)"
              [showSavePrompt]=false #appChat></app-chat>
          </div>
        </ng-template>
      </div>
      <div class="submit-wrapper">
        <div class="social-media-input-wrapper">
          <div class="request-input-platforms">
            <span class="platform-title">Select platforms: </span>
            <button *ngFor="let platform of platformList; let i = index" class="platform-button" kmdPrimaryButton
              size="sm" [class.active]="selectedPlatforms.includes(platform)" (click)="toggleSelection(platform)">
              {{ platform }}
            </button>
          </div>
          <app-prompt-input #promptInputComponent [promptSettings]="promptSettings"
            [username]="(userData$ | async)?.name || 'NA'" (openDrawerEvent)="openDrawer()" [chatId]="selectedChatId"
            (promptSentEvent)="onSubmit($event)"></app-prompt-input>
        </div>
      </div>
    </div>
  </div>

  <kmd-modal class="loading-modal" [id]="'loadingResponseModal'" modalSize="small" [closeOnClickingOutside]="false">
    <div>Generating response</div>
    <kmd-loading-wheel></kmd-loading-wheel>
  </kmd-modal>
</div>


<!-- modal elements -->
<app-drawer appClickOutsideDrawer (appClickOutsideDrawer)="closeDrawer()" [position]="'right'">
  <span title>Prompt Settings</span>
  <prompt-settings [promptSettings]="promptSettings" [includeWebSearch]="includeWebSearch"
    [chatType]="'ask-gene'" />
</app-drawer>

<kmd-alerts kmd-error [(show)]="showGeneralAlert" [alertText]="alertText" [dismissible]="true"></kmd-alerts>

<kmd-alerts id="invalidPromptAlert" kmd-warning [(show)]="showInvalidPrompt"
  [alertText]="invalidPromptText"></kmd-alerts>
