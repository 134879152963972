import { Component, ElementRef, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AskOurDataService } from '@app/core/services/ask-our-data/ask-our-data.service';
import { KmdModalService } from 'gds-atom-components';
import { Messages } from '@app/shared/constants/ask-our-data-constants';
import { WindowRef } from '@app/core/services/window/window-ref.service';

@Component({
  selector: 'app-aod-chat-history',
  templateUrl: './aod-chat-history.component.html',
  styleUrls: ['./aod-chat-history.component.css']
})
export class AodChatHistoryComponent implements OnInit {

  @ViewChild('chatHistoryModal', { static: false })
  chatHistoryModal?: ElementRef;

  @Output() displayLoader = new EventEmitter<any>();
  @Output() displayChat = new EventEmitter<any>();
  databaseId: string = '';
  userId: string = '';
  historyList: any[] = [];
  searchText = '';
  widthVal = WindowRef.innerWidth - 500;
  posX = 0;
  posY = 0;
  filteredList: any[] = [];
  selectedChat: any;
  isOptionOpened = false;
  successMessage = '';
  errorMessage = '';
  isEditMode = false;
  emptyHistory = false;

  constructor( private askOurDataService: AskOurDataService,
    public oidcSecurityService: OidcSecurityService,
    private route: ActivatedRoute,
    private kmdModalService: KmdModalService) {
      this.oidcSecurityService.userData$.subscribe( result => {
          this.userId = result.userData.oid;
        });
  }

  ngOnInit(): void {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe(params => {
        this.databaseId = params['id'];
      });
    }
  }

  loadChatHistory() {
    this.displayLoader.emit(true);
    this.askOurDataService.getChatHistoryList(this.databaseId, this.userId).subscribe({
      next: (response) => {
        this.displayLoader.emit(false);
        this.emptyHistory = response.length === 0;
        this.historyList = [...response];
        this.filteredList = [...response];
      },
      error : () => {
        this.displayLoader.emit(false);
        this.errorMessage = Messages.UNABLE_TO_GET_CHAT_HISTORY;
      }
    });

  }


  openChatHistory() {
    if (this.chatHistoryModal) {
      this.chatHistoryModal.nativeElement.isopen = true;
      this.chatHistoryModal.nativeElement.bgclose = this.isMobileScreen();
      this.loadChatHistory();

      let footerElement: HTMLElement = document.querySelector('core-modal')?.shadowRoot?.querySelector('.footer') as HTMLElement;
      if(footerElement) {
        footerElement.style.height = '1px';
        footerElement.style.margin = '15px';
      }
      let sheetHandleElement: HTMLElement = document.querySelector('core-modal')?.shadowRoot?.querySelector('.sheetHandle') as HTMLElement;
      if(sheetHandleElement) {
        sheetHandleElement.style.display = this.isMobileScreen() ? 'block' : 'none';
      }
    }
  }

  filterData() {
    this.filteredList = [...this.historyList.filter(data => data.chat_title.includes(this.searchText))];
  }

  isMobileScreen(): boolean {
    return 500 >= WindowRef.innerWidth;
  }

  rowClick(history: any) {
    this.selectedChat = {...history};
    if(this.isMobileScreen() && !this.isEditMode) {
      this.isOptionOpened = !this.isOptionOpened;
    } else if(!this.isEditMode) {
      this.openChat();
    }
  }

  showPopover(history: any, event : any) {
    this.isEditMode = false;
    this.selectedChat = {...history};
    event.preventDefault();
    this.posX = event.currentTarget.offsetLeft + event.currentTarget.clientWidth / 2 - 10;
    this.posY = event.currentTarget.offsetTop + event.currentTarget.clientHeight / 2 + 10;
  }

  openChat() {
    this.displayLoader.emit(true);
    this.askOurDataService.getChatDetails(this.databaseId, this.userId, this.selectedChat.session_id).subscribe({
      next: (response) => {
        this.displayLoader.emit(false);
        this.displayChat.emit(response);
        if (this.chatHistoryModal) {
          this.chatHistoryModal.nativeElement.isopen = false;
        }
      },
      error: () => {
        this.displayLoader.emit(false);
        this.errorMessage = Messages.UNABLE_TO_OPEN_CHAT_DETAILS;
      }
    });
  }

  clearSearch() {
    this.searchText = '';
    this.filterData();
  }

  deleteChat() {
    this.kmdModalService.open('deletedConfirmation');
  }

  isEditing(history: any) {
    return this.isEditMode && this.selectedChat.session_id === history.session_id;
  }

  getDate(historyDate: any) {
    const utcDate = new Date(historyDate);
    return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  }

  renameChat() {
    this.isEditMode = true;
  }

  updateTitle(event: any, history: any) {
    if(event.keyCode === 13) {
      this.errorMessage = '';
      this.successMessage = '';
      this.displayLoader.emit(true);
      this.isEditMode = false;
      let requestData = {
        new_chat_title: history.chat_title,
        databaseId: this.databaseId,
        userId: this.userId,
        chatId: this.selectedChat.chat_id
      };
      this.askOurDataService.renameChatTitle(requestData).subscribe({
        next: () => {
          this.loadChatHistory();
          this.successMessage = Messages.CHAT_HISTORY_RENAMED;
        },
        error: () => {
          this.resetTitle(history);
          this.displayLoader.emit(false);
          this.errorMessage = Messages.UNABLE_RENAME_CHAT_TITLE;
        }
      })
    } else if(event.keyCode === 27) {
      this.resetTitle(history);
    }
  }

  resetTitle(history: any) {
    this.isEditMode = false;
    history.chat_title = this.selectedChat.chat_title;
  }

  closeModal(modalId: string) {
    this.kmdModalService.close(modalId);
  }

  deleteAccepted() {
    this.errorMessage = '';
    this.successMessage = '';
    this.displayLoader.emit(true);
    this.askOurDataService.deleteChat(this.databaseId, this.userId, this.selectedChat.session_id).subscribe({
      next: () => {
        this.displayLoader.emit(false);
        this.displayChat.emit([]);
        this.filteredList = this.filteredList.filter((data: any) => data.session_id !== this.selectedChat.session_id);
        this.historyList = this.historyList.filter((data: any) => data.session_id !== this.selectedChat.session_id);
        this.successMessage = Messages.CHAT_HISTORY_DELETED;
        this.kmdModalService.close('deletedConfirmation');
      },
      error: () => {
        this.displayLoader.emit(false);
        this.errorMessage = Messages.UNABLE_TO_DELETE_CHAT_HISTORY;
        this.kmdModalService.close('deletedConfirmation');
      }
    });
  }

}
