<div class="overview-agent-container">
  <div class="overview-agent-note">
    <p>The information below is read-only and cannot be manipulated. To edit it, add it to your agents.</p>
  </div>

  <div class="overview-agent-name-category-container">
    <div class="overview-agent-info">
      <b>Name: </b>
      <label>
        {{agent?.name}}
      </label>
    </div>

    <div class="overview-agent-info">
      <b>Category: </b>
      <label>
        {{agent?.category}}
      </label>
    </div>

    <div class="overview-agent-info">
      <b>Created By: </b>
      <label>
        {{agent?.createdBy}}
      </label>
    </div>
  </div>

  <div class="overview-agent-info">
    <b>Description: </b>
    <label class="overview-agent-description">
      {{agent?.description}}
    </label>
  </div>

  <div class="overview-agent-info">
    <b>Instructions: </b>
    <div class="overview-agent-instructions">
      {{agent?.instructions}}
    </div>
  </div>

  <div class="overview-agent-buttons-container">
    <core-button (click)="addToMyAgents()" label="Copy to Personal Agents" type="button" variant="information" size="medium"
      fullspace="true">
    </core-button>
    <core-button (click)="startChat()" label="Chat with Agent" type="button" variant="primary" size="medium"
      fullspace="true">
    </core-button>
  </div>
</div>