import { Injectable } from "@angular/core";
import { FilesService } from "../files/files.service";
import {
  BehaviorSubject,
  catchError,
  delay,
  first,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  takeUntil,
  tap,
  throwError
} from "rxjs";
import { formatDate } from "@angular/common";
import { FileUpload } from "@shared/models/file-upload.model";
import { FileManagerDoc, isFileManagerDocImageType } from "@shared/models/file-manager/file-manager-doc.model";
import { S3UploadService } from "../files/s3-upload.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { FileManagerUpload } from "@shared/models/file-manager/file-manager-upload.model";
import { FileManagerUploadStatus } from "@shared/models/file-manager/file-manager-upload-status.model";
import { FileUploadStatus } from "@shared/constants/file-manager/file-upload-status";
import {
  FileType,
  FileTypes,
  FileUploadOptions,
  FileUploadType
} from "@app/shared/constants/file-manager/file-upload-options";
import { SpreadsheetsService } from "../files/spreadsheets.service";
import { SpreadsheetAnalysisUsageConsent } from "@app/shared/constants/file-manager/spreadsheet-analysis-usage-consent";
import { FileValidationResponse } from "@shared/models/file-manager/file-validation-response.model";
import { fileUploadToFileManagerDoc, getReadableFileSize, parseFileNameToDisplayName, parseShortFileDisplayName } from "./utils/file-utils";
import { getDifferenceInHours } from "./utils/date-utils";
import { getFileExtension } from "@shared/models/file-manager/file-manager-doc";
import { defaultFileManagerConfig, FileManagerConfig } from "@app/shared/components/file-manager/file-manager";

export enum FileNameFormatOption {
  WITH_EXTENSION = 'WITH_EXTENSION',
  WITHOUT_EXTENSION = 'WITHOUT_EXTENSION'
}

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  private readonly CSV_MAX_FILE_SIZE = 80000000;
  private readonly EXCEL_MAX_FILE_SIZE = 15000000;
  private readonly IMAGE_MAX_FILE_SIZE = 5000000;
  private readonly POWERPOINT_MAX_FILE_SIZE = 10000000;
  private readonly supportedTextFileTypes = [
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ];
  private readonly PDF_UPLOAD_TYPE = 'pdf';
  private readonly DOCX_UPLOAD_TYPE = 'vnd.openxmlformats-officedocument.wordprocessingml.document';
  private readonly XLSX_UPLOAD_TYPE = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  private readonly PPTX_UPLOAD_TYPE = 'vnd.openxmlformats-officedocument.presentationml.presentation';

  private readonly supportedSpreadsheetsFileTypes = [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  private readonly supportedImageFileTypes = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp'
  ];

  private readonly PPTX_EXTENSION = '.pptx';
  private _fileManagerConfig: FileManagerConfig = defaultFileManagerConfig;

  selectedFileType$ = new BehaviorSubject<FileUploadType>(FileUploadOptions['TEXT']);
  allFiles$ = new BehaviorSubject<FileManagerDoc[]>([]);
  selectedFiles$ = new BehaviorSubject<FileManagerDoc[]>([]);
  preSelectedFiles$ = new BehaviorSubject<FileManagerDoc[]>([]);
  fixedFiles$ = new BehaviorSubject<FileManagerDoc[]>([]);
  uploadedFiles$ = new BehaviorSubject<FileManagerUpload[]>([]);

  readonly fileStatusIds = {
    processed: new Array<string>(),
    error: new Array<string>()
  };

  private _isSpreadsheetUsageAcknowledged: boolean | undefined;

  constructor(
    private filesService: FilesService,
    private spreadsheetsService: SpreadsheetsService,
    private s3UploadService: S3UploadService,
  ) {
    this.isSpreadsheetUsageAcknowledged()
      .pipe(
        first()
      ).subscribe({
        next: (isAcknowledged) => this._isSpreadsheetUsageAcknowledged = isAcknowledged
      });
  };

  setSelectedFileType(fileType: FileUploadType): void {
    this.selectedFileType$.next(fileType);
  }

  private hasBeenProcessingForMoreThanOneHour(file: FileUpload, fileManagerDoc: FileManagerDoc) {
    return fileManagerDoc.status === 'PROCESSING' && file.updatedAt && getDifferenceInHours(file.updatedAt) >= 1
  }

  get isUnsupportedFileSelected$(): Observable<{ file: FileManagerDoc, isUnsupported: boolean }[]> {
    return this.selectedFileType$.pipe(
      mergeMap((type) => this.selectedFiles$.pipe(
        map((files) => files.map((file) => ({
          file,
          isUnsupported: type.unsupportedFileTypes.includes(file.extension)
        })))
      ))
    );
  };

  getLazyFiles$(fileType: FileUploadType, offset: number = 0, sortBy: string = "processed",
                sortDirection: string = "DESC", searchTerm: string = ''): Observable<FileManagerDoc[]> {
    if (this.selectedFileType$.value !== fileType && offset === 0) {
      this.allFiles$.next([]);
    }

    this.setSelectedFileType(fileType);

    return this.filesService.lazyList(fileType.extensions.join(','), offset, sortBy, sortDirection, searchTerm)
      .pipe(
        map((files) => {
          return files.map((file) => {
            const parsedFile = fileUploadToFileManagerDoc(file, 60);

            if (parsedFile.status === 'ERROR') {
              this.addToFileStatusError(parsedFile);
            } else if (parsedFile.status === 'PROCESSED') {
              this.updateProcessedFileStatus(parsedFile);
            } else if (this.hasBeenProcessingForMoreThanOneHour(file, parsedFile)) {
              this.addToFileStatusError(parsedFile);
            } else {
              this.fetchProcessingStatus(parsedFile.id!, fileType);
            }

            return parsedFile;
          });
        }),
        tap((files) => {
          if (offset === 0) {
            this.allFiles$.next(files);
          } else {
            this.allFiles$.next(this.allFiles$.value.concat(files));
          }
        })
      );
  };

  getFiles$(fileType: FileUploadType, sortBy: string = "processed", sortDirection: string = "DESC", excludeStatus = ""): Observable<FileManagerDoc[]> {
    return this.filesService.list(
      fileType.extensions.join(','),
      sortBy,
      sortDirection,
      excludeStatus
    ).pipe(
      map((files) => {
        return files.map((file) => {
          const parsedFile = fileUploadToFileManagerDoc(file, 65);

          if (parsedFile.status === 'ERROR') {
            this.addToFileStatusError(parsedFile);
          } else if (parsedFile.status === 'PROCESSED') {
            this.updateProcessedFileStatus(parsedFile);
          } else {
            this.fetchProcessingStatus(parsedFile.id!, fileType);
          }

          return parsedFile;
        });
      }),
      tap((files) => this.allFiles$.next(files))
    );
  }

  getFiles(fileIds: string[]): Observable<FileManagerDoc[]> {
    if (!fileIds.length) {
      return of([]);
    }

    const fileObservables = fileIds.map((id) => {
      return this.filesService.get(id).pipe(
        map((file) => {
          const parsedFile = fileUploadToFileManagerDoc(file, 65);
          if (parsedFile.status === 'ERROR') {
            this.addToFileStatusError(parsedFile);
          } else if (parsedFile.status === 'PROCESSED') {
            this.updateProcessedFileStatus(parsedFile);
          } else {
            this.fetchProcessingStatus(parsedFile.id!, this.getCategoryForFileType(file.fileType!)!);
          }
          return parsedFile;
        }),
        catchError(() => {
          console.error(`Error fetching file with ID ${id}`);
          return of(null);
        })
      );
    });

    return forkJoin(fileObservables).pipe(
      map((files) => files.filter((file): file is FileManagerDoc => file !== null)),
      tap((files) => this.setSelectedFiles(files))
    );
  }

  getFilesByType(fileType: string) {
    switch (fileType) {
      case 'text':
        return this.supportedTextFileTypes;
      case 'spreadsheets':
        return this.supportedSpreadsheetsFileTypes;
      case 'image':
        return this.supportedImageFileTypes;
      default:
        return [];
    }
  };

  validateFileUploadBatch(files: FileList, fileType: FileType): FileValidationResponse {
    let validationResponse = this.getSupportedFilesValidationResponse(fileType, files);
    this.validateMaxFileSizeForSpreadsheets(fileType, files, validationResponse);
    this.validateTextFiles(fileType, files, validationResponse);
    return validationResponse;
  };

  validateTextFiles(fileType: string, files: FileList, validationResponse: FileValidationResponse): FileValidationResponse {
    if (fileType === 'text') {
      for (let i = 0; i < files.length; i++) {
        if (getFileExtension(files.item(i)!.name) === this.PPTX_EXTENSION && this.fileLimitExceeded(files.item(i)!)) {
          validationResponse.maxFileSizeExceeded = true;
          validationResponse.unsupportedFileType = false;
        }
      }
    }
    return validationResponse
  }

  private getSupportedFilesValidationResponse(fileType: string, files: FileList): FileValidationResponse {
    const supportedFileTypes = this.getFilesByType(fileType);
    const validationResponse = {
      isValid: true,
      unsupportedFileType: false,
      maxFileSizeExceeded: false,
      passwordProtected: false
    };

    for (let i = 0; i < files.length; i++) {
      if (!supportedFileTypes.includes(files.item(i)!.type)) {
        validationResponse.isValid = false;
        validationResponse.unsupportedFileType = true;
      }
    }
    return new FileValidationResponse(validationResponse);
  }

  private validateMaxFileSizeForSpreadsheets(fileType: string, files: FileList, validationResponse: FileValidationResponse) {
    if ((fileType === 'spreadsheets' || fileType === 'image') && this.fileLimitExceeded(files.item(0)!)) {
      validationResponse.unsupportedFileType = false;
      validationResponse.maxFileSizeExceeded = true;
    }
  }

  validFileName(fileName: string): boolean {
    if (!fileName) {
      return false;
    }
    const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    return nameWithoutExtension.trim().length > 0;
  }

  uploadFiles(files: File[], uploadType: FileUploadType): FileManagerUpload[] {
    const fileUploads: FileManagerUpload[] = [];

    for (let file of files) {
      const fileUpload = new FileUpload(undefined, file.name, undefined, undefined, undefined, file.size);

      let id: string;
      const upload = this.filesService.create(fileUpload)
        .pipe(
          tap((uploadedFile: FileUpload) => id = uploadedFile.id!),
          mergeMap((uploadedFile: FileUpload) => this.s3UploadService.uploadFile(file, uploadedFile.url!)),
          mergeMap((event: HttpEvent<any>) => this.mapProgress(id, event, uploadType))
        );

      fileUploads.push({ file, upload });
    }

    return fileUploads;
  };

  getMixedSelectedFiles(){
    return this.selectedFiles$.value.concat(this.fixedFiles$.value);
  }

  getFileUploadStatus(data: FileManagerUpload): FileManagerUpload {
    const cancel$: Subject<boolean> = new Subject<boolean>();

    const upload: FileManagerUpload = {
      file: data.file,
      status: FileUploadStatus.IN_PROGRESS,
      progress: 0,
      cancel: () => cancel$.next(true),
      fetch: () => data.upload!.pipe(
        takeUntil(cancel$),
        catchError((err) => {
          upload.status = FileUploadStatus.ERROR;
          upload.progress = 0;
          return throwError(() => err);
        }),
        tap((uploadStatus) => {
          upload.id = uploadStatus.id!;
          upload.status = uploadStatus.status;
          upload.progress = uploadStatus.progress;
        })
      ).subscribe()
    };
    upload.fetch!();
    return upload;
  };

  private mapProgress(id: string, event: HttpEvent<any>, uploadType: FileUploadType): Observable<FileManagerUploadStatus> {
    if (event.type === HttpEventType.Response) {
      return of({
        id,
        status: FileUploadStatus.COMPLETED,
        progress: 100
      });
    }

    if (event.type === HttpEventType.UploadProgress) {
      return of({
        id,
        status: FileUploadStatus.IN_PROGRESS,
        progress: event.total
          ? Math.round((100 * event.loaded) / event.total)
          : 0
      });
    }

    this.fetchProcessingStatus(id, uploadType);
    return of({
      id,
      status: FileUploadStatus.IN_PROGRESS,
      progress: 0
    });
  };

  private fetchProcessingStatus(id: string, uploadType: FileUploadType): void {
    if (uploadType === FileUploadOptions['SPREADSHEETS'] || uploadType === FileUploadOptions['IMAGE']) {
      this.fileStatusIds.processed.push(id);
      return;
    }

    this.filesService.get(id)
      .pipe(
        mergeMap((file) => of(file).pipe(
          delay(3000),
          catchError((err) => of({ ...err, status: 'ERROR' }))
        )),
        tap((file: FileUpload) => {
          switch (file.status) {
            case 'PROCESSED':
              this.fileStatusIds.processed.push(id);
              break;
            case 'ERROR':
              this.fileStatusIds.error.push(id);
              break;
            default:
              this.fetchProcessingStatus(id, uploadType);
              break;
          }
        })
      ).subscribe();
  };

  setSelectedFiles(files: FileManagerDoc[]): void {
    files.forEach((file) => {
      if (!this.fileStatusIds.error.includes(file.id!)) {
        this.fileStatusIds.processed.push(file.id!);
      }
    });
    this.selectedFiles$.next(files);
  };

  addPreSelectedFile(file: FileManagerDoc): void {
    const preSelectedFiles  = this.preSelectedFiles$.value
    if (preSelectedFiles.some((f) => f.id === file.id)) {
      return;
    }
    preSelectedFiles.push(file);
    this.preSelectedFiles$.next(preSelectedFiles);
    this.updateAllFiles(file);
  };

  private updateAllFiles(file: FileManagerDoc) {
    const allFiles = this.allFiles$.value;
    if (allFiles.some((f) => f.id === file.id)) {
      const fileIndex = allFiles.findIndex((f) => f.id === file.id);
      allFiles[fileIndex].isSelected = true;
    } else {
      allFiles.unshift(file);
    }
    this.allFiles$.next(allFiles);
  }

  removePreSelectedFile(file: FileManagerDoc): void {
    const files = this.preSelectedFiles$.value.filter((f) => f.id !== file.id);
    this.preSelectedFiles$.next(files);
  };

  reset(): void {
    this.clearPreSelectedFiles();
    this.clearFixedFiles();
    this.clearSelectedAndSetType();
  }

  clearSelectedAndSetType(){
    this.selectedFiles$.next([]);
    this.selectedFileType$.next(FileUploadOptions['TEXT']);
  }

  clearSelectedFiles(): void {
    let initialFileType = this.selectedFileType$.value;
    this.selectedFileType$.subscribe((newValue) => {
      if (newValue !== initialFileType) {
        this.preSelectedFiles$.next([]);
        this.selectedFiles$.next([]);
      }
      else {
        this.selectedFiles$.next(this.selectedFiles$.value);
      }
    });
  };

  private updateProcessedFileStatus(file: FileManagerDoc): void {
    if (!this.fileStatusIds.processed.includes(file.id!)) {
      this.fileStatusIds.processed.push(file.id!);
    }
  };

  private addToFileStatusError(file: FileManagerDoc): void {
    if (!this.fileStatusIds.error.includes(file.id!)) {
      this.fileStatusIds.error.push(file.id!);
    }
  };

  getParseFileNameToDisplayName(name: string, maxFileNameLength: number, formatOption: FileNameFormatOption = FileNameFormatOption.WITH_EXTENSION): string {
    return parseFileNameToDisplayName(name, maxFileNameLength, formatOption);
  }

  convertUploadToFileManagerDoc(upload: FileManagerUpload): FileManagerDoc {
    const file = {
      id: upload.id!,
      name: upload.file.name,
      displayName: parseFileNameToDisplayName(upload.file.name, 50),
      miniDisplayName: parseFileNameToDisplayName(upload.file.name, 30),
      shortDisplayName: parseShortFileDisplayName(upload.file.name),
      size: getReadableFileSize(upload.file.size),
      extension: getFileExtension(upload.file.name),
      date: formatDate(new Date(), 'MMM-dd-yyyy', 'en-US'),
      isSelected: true,
      status: FileUploadStatus.IN_PROGRESS,
      isSelectDisabled: false,
      isUnsupported: false
    };

    this.addPreSelectedFile(file);
    return file;
  }

  isSpreadsheetUsageAcknowledged(): Observable<boolean> {
    if (this._isSpreadsheetUsageAcknowledged !== undefined) {
      return of(this._isSpreadsheetUsageAcknowledged);
    }

    return this.spreadsheetsService.getUsageAcknowledgement()
      .pipe(
        map((usage) => usage.state === SpreadsheetAnalysisUsageConsent.ACKNOWLEDGED)
      );
  };

  acknowledgeSpreadsheetUsage(): Observable<void> {
    return this.spreadsheetsService.acknowledgeUsage({ state: SpreadsheetAnalysisUsageConsent.ACKNOWLEDGED })
      .pipe(
        tap(() => this._isSpreadsheetUsageAcknowledged = true)
      );
  };

  fileLimitExceeded(file: File): boolean {
    switch (file.type) {
      case 'text/csv':
        return file.size > this.CSV_MAX_FILE_SIZE;
      case 'application/vnd.ms-excel':
        return file.size > this.EXCEL_MAX_FILE_SIZE;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return file.size > this.EXCEL_MAX_FILE_SIZE;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return file.size > this.POWERPOINT_MAX_FILE_SIZE;
      case 'image/png':
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/webp':
        return file.size > this.IMAGE_MAX_FILE_SIZE;
      default:
        return false;
    }
  }

  getCategoryForFileType(fileType: string): FileUploadType {
    if (!fileType)
      throw new Error('Invalid file type');

    for (const category in FileTypes) {
      if (Object.values(FileTypes[category]).includes(fileType.toUpperCase())) {
        return FileUploadOptions[category];
      }
    }

    throw new Error(`File type ${fileType} not found`);
  }

  async isFilePasswordProtected(file: File): Promise<boolean> {
    const fileType = file.type.split('/')[1];
    switch (fileType) {
      case this.PDF_UPLOAD_TYPE:
      case this.DOCX_UPLOAD_TYPE:
      case this.XLSX_UPLOAD_TYPE:
      case this.PPTX_UPLOAD_TYPE:
        return  await this.checkFileForEncryption(file, fileType);
      default:
        return false;
    }
  }

  async checkFileForEncryption(file: File, type: string): Promise<boolean> {
    try {
      const reader = new FileReader();
      const arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });

      const blob = new Blob([arrayBuffer], { type });
        const text = await blob.text();
          return text.includes("Encrypt");

    } catch (error) {
      console.error('Error reading uploaded file:', error);
      return false;
    }
  }

  confirmSelectedFiles(): void {
    this.setSelectedFiles(this.preSelectedFiles$.value);
    this.clearPreSelectedFiles()
  }

  clearPreSelectedFiles(): void {
    this.preSelectedFiles$.next([]);
  }

  clearFixedFiles(): void {
    this.fixedFiles$.next([]);
  }

  handleSelectedFiles(selectedFiles: FileManagerDoc[]): void {
    const imageFiles = selectedFiles.filter(file => isFileManagerDocImageType(file));
    const nonImageFiles = selectedFiles.filter(file => !isFileManagerDocImageType(file));

    for (let i = 0; i < imageFiles.length; i++) {
      const file = imageFiles[i];
      if (!this.fixedFiles$.value.some(f => f.id === file.id)) {
        file.isSelectDisabled = true;
        file.isSelected = true;
        this.fixedFiles$.next(this.fixedFiles$.value.concat(file));
      }
    }

    for (let i = 0; i < nonImageFiles.length; i++) {
      const file = nonImageFiles[i];
      file.isSelected = true;
    }

    this.setSelectedFiles(nonImageFiles);
  }

  addUploadedFile(file: FileManagerUpload): void {
    console.log("To be implemented ", file);
  }

  removeUploadedFile(file: FileManagerUpload): void {
    console.log("To be implemented ", file);
  }

  updateUploadedFile(file: FileManagerUpload, status: typeof FileUploadStatus): void {
    console.log("To be implemented ", file, status);
  }

  cleanUploadedFiles(): void {
    console.log("To be implemented");
  }

  get fileManagerConfig(): FileManagerConfig {
    return this._fileManagerConfig;
  }

  set fileManagerConfig(value: FileManagerConfig) {
    this._fileManagerConfig = value;
  }
}
