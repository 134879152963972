import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AskOurDocsUserManagementPageNewComponent } from '@app/ask-our-docs-v2/ask-our-docs-user-management-page-new/ask-our-docs-user-management-page-new.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AskOurDocsConfigFormGuard implements CanDeactivate<AskOurDocsUserManagementPageNewComponent> {
  canDeactivate(component: AskOurDocsUserManagementPageNewComponent): Observable<boolean> | boolean {
    if(component.formChanged === true || component.promptLibraryComponent?.saveButtonEnabled || component.promptLibraryComponent?.hasEmptyFields()) {
      return new Observable<boolean>(observer => {
        component.alertRedirection().then((result : boolean) => {
          observer.next(result);
          observer.complete();
        });
      });
    }
    return true
  }
}
