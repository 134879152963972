<div id="input-component-wrapper" [ngClass]="promptWrapperCss">
  <textarea id="input-component-container"
    #promptTextarea
    autosize
    [minRows]="1"
    [maxRows]="7"
    [(ngModel)]="prompt"
    (keydown.enter)="onPromptKeydownEnter($event)"
    (keyup)="updateSubmitButtonState()"
    placeholder="Enter your prompt here">
  </textarea>
  <div id="input-component-controls-section">
    <div *ngIf="config.features.addFiles" class="input-component-control custom">
      <app-file-manager />
    </div>
    <div *ngIf="config.features.changeModel" class="input-component-control" id="model-control">
      <div class="model-button-wrapper" (click)="toggleModelMenu()" (clickOutside)="closeModelMenu()">
        <img src="../../../../assets/icons/model/model-icon.svg" alt="model-icon" width="20" />
        <div id="select-model-btn" appTooltip text="Change model" position="top" [visible]="showTooltip">
          <label>{{ selectedMenuModel.label }}</label>
          <img src="{{modelMenuImageSrc}}" width="8">
        </div>
      </div>
      <app-context-menu #modelMenu
        [items]="menuModels"
        [defaultSelectedItem]="selectedMenuModel"
        [slideDirection]="'down-to-up'"
        (itemClicked)="setModel($event)"
        (isContextMenuOpen)="setModelMenuState($event)" />
    </div>
    <div *ngIf="config.display.promptSettings" class="input-component-control" id="settings-control"
      (click)="onPromptSettingsClick()" appTooltip text="Prompt settings" position="top">
      <img ngSrc="assets/icons/filter/filter-horizontal.svg" alt="" height="24" width="24">
    </div>
    <div id="send-button"
      [ngClass]="submitButtonState!.cssClass"
      (click)="submitButtonState!.onClick()">
      <img class="send-icon" id="send-icon" [src]="submitButtonState!.url" alt="">
      <span>Stop response</span>
    </div>
  </div>
</div>

<div class="countdown-label-wrapper" *ngIf="showCounter()" [ngClass]="{'error' : isTextTrimmed || isPromptLengthExceeded}">
  <p id="text-trimmed" *ngIf="isTextTrimmed" class="error">
    Text cut, characters limit reached on model
  </p>
  <p id="text-limit" *ngIf="isPromptLengthExceeded" class="error">
    Character limit exceeded
  </p>
  <countdown-label [maxlength]="maxInputCharLimit" [value]="prompt"
    [ngStyle]="{'color': isPromptLengthExceeded ? '#F00' : '#F56A00'}" />
</div>

<div id="input-component-footer">
  <p>
    Inaccuracies may occur in Gene.AI, make sure to cross-verify critical information.
    Please <a [routerLink]="['/guidelines']">consult our guidelines</a>
    for additional information.
  </p>
</div>
