import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BannerService } from "@services/banner/banner.service";
import { Router } from "@angular/router";
import { featureFlags } from "@environments/environment";

@Component({
  selector: 'app-banner-modal',
  templateUrl: './banner-modal.component.html',
  styleUrls: ['./banner-modal.component.css']
})
export class BannerModalComponent implements OnInit {

  announcedPathFeature: string = '/agents';

  @Input() banner: boolean = true;
  @Output() toggleBannerModal = new EventEmitter<boolean>();

  constructor(
    protected bannerService: BannerService, protected router : Router) {
  }

  ngOnInit() {
    this.banner = this.bannerService.isBannerEnabled();
    this.toggleBannerModal.emit(this.banner);
  }

  closeBannerModal(): void {
    this.banner = false;
    this.toggleBannerModal.emit(this.banner);
  }

  isChecked(event: any): void {
    event.checked ? this.bannerService.disableBanner() : this.bannerService.enableBanner();
  }

  navigateToAnnouncedFeature() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([this.announcedPathFeature]));
  }

  protected readonly featureFlags = featureFlags;
}
