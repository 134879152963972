import { Inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { DBList, TableList, SaveDBDetailsRequestData } from "@app/shared/models/ask-our-data.model";
import { featureFlags } from '@app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AskOurDataService {
  protected readonly featureFlags = featureFlags;

  headers = {
    headers: {
      'Content-type': 'application/json'
    }
  };

  constructor( @Inject('ASK_DATA_BASE_API_URL') private baseUrl: string, private http: HttpClient ) { }

  getDBList(): Observable<DBList[]> {
    return this.http.get<DBList[]>(`${ this.baseUrl }/v1/database`, this.headers);
  }

  generateQuery(requestData: any): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/v1/database/sql/generate`, requestData, this.headers);
  }

  runQuery(runQueryRequestData: { id: string, sql: string, question: string, generate_summary: boolean, session_id: string, chat_id?: string | null }): Observable<any> {
    let url = `${ this.baseUrl }/v1/database/sql/run`;
   return this.http.post<any>(url, runQueryRequestData, this.headers);
  }

  dbConnect(id: string): Observable<any> {
    return this.http.get<any>(`${ this.baseUrl }/v1/db/connect-id/`+ id, this.headers);
  }

  verifyConnection(requestData: { id?: null | number, type:string, name: string, username: string, server: string, port?:number | null, password?: string | null }): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/v1/database/connect`, requestData, this.headers);
  }

  saveDBDetails(requestData: SaveDBDetailsRequestData): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/v1/database/configure`, requestData, this.headers);
  }

  updateDBDetails(requestData: SaveDBDetailsRequestData): Observable<any> {
    return this.http.put<any>(`${ this.baseUrl }/v1/database/configure`, requestData, this.headers);
  }

  getTables(id: string): Observable<TableList> {
    return this.http.get<TableList>(`${ this.baseUrl }/v1/database/${id}/tables`, this.headers);
  }

  saveTables(requestData: {"database_id": number | string, "tables_views_list": {"name":string, "is_selected": string, tyep?: string | undefined}[]}): Observable<any> {
     return this.http.post<any>(`${ this.baseUrl }/v1/database/tables`, requestData, this.headers);
  }

  getDDLStatus(id: string): Observable<any> {
   return this.http.get<any[]>(`${ this.baseUrl }/v1/database/${id}/ddl/status`, this.headers);
  }

  getDbDetails(id: string): Observable<any> {
    return this.http.get<any[]>(`${ this.baseUrl }/v1/database/${id}/configure`, this.headers);
  }

  getColumns(id: string): Observable<any> {
    return this.http.get<any[]>(`${ this.baseUrl }/v1/database/${id}/columns`, this.headers);
  }

  saveColumns(requestData: any): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/v1/database/columns/train`, requestData, this.headers);
  }

  saveDraftColumnCuration(requestData: any): Observable<any> {
    return this.http.put<any>(`${ this.baseUrl }/v1/database/columns`, requestData, this.headers);
  }

  getColumnStatus(id: string): Observable<any> {
    return this.http.get<any[]>(`${ this.baseUrl }/v1/database/${id}/columns/status`, this.headers);
   }

   setNavigationStatus(requestData: {user_id: string, database_id: number, page_name: string}): Observable<any> {
    return this.http.post<any[]>(`${ this.baseUrl }/v1/database/navigation`, requestData, this.headers);
   }

   getNavigationStatus(user_id: string, database_id: number): Observable<any> {
    return this.http.get<any[]>(`${ this.baseUrl }/v1/database/${database_id}/navigation/${user_id}`, this.headers);
   }

   saveDraftDDL(requestData: any): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/v1/database/tables/save`, requestData, this.headers);
   };

   trainSQLPairs(requestData: any): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/v1/database/sql/train`, requestData, this.headers);
   }

   generateChart(request: any): Observable<any> {
    return this.http.post<any>(`${ this.baseUrl }/v1/generate_plotly_figure`, request,  this.headers);
   }

   getSQLPairs(id: string): Observable<any> {
    return this.http.get<any[]>(`${ this.baseUrl }/v1/database/${id}/sql`, this.headers);
   }

   saveDraftSQLPairs(requestData: any): Observable<any> {
    return this.http.post<any[]>(`${ this.baseUrl }/v1/database/sql`, requestData, this.headers);
   }

   deleteSQLPair(database_id: number, id: number): Observable<any> {
    const url = `${ this.baseUrl }/v1/database/${database_id}/sql/${id}`;
    return this.http.delete<any[]>(url, this.headers);
   }

   callConversationChat(requestData: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/agent/process`;
    return this.http.post<any[]>(url, requestData, this.headers);
   }

   saveConsent(requestData: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/database/chat/acknowledge`;
    return this.http.post<any[]>(url, requestData, this.headers);
   }

   getSamplePrompts(database_id: number, number_of_prompts: number): Observable<any> {
    const url = `${ this.baseUrl }/v1/database/chat/${database_id}/prompt/${number_of_prompts}`;
    return this.http.get<any[]>(url, this.headers);
   }

   advancePlot(requestData: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/advance_plot`;
    return this.http.post<any[]>(url,requestData,  this.headers);
   }

   advancePrediction(requestData: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/advance_prediction`;
    return this.http.post<any[]>(url, requestData, this.headers);
   }

   getChatHistoryList(databaseId: any, userId: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/database/${databaseId}/user/${userId}/chat/titles`;
    return this.http.get<any[]>(url, this.headers);
   }

   deleteChat(databaseId: number | string, userId: number | string, sessionId: string): Observable<any> {
    const url = `${ this.baseUrl }/v1/database/${databaseId}/user/${userId}/session/${sessionId}/delete`;
    return this.http.delete<any[]>(url, this.headers);
   }

   renameChatTitle(requestData: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/database/${requestData.databaseId}/user/${requestData.userId}/chat/${requestData.chatId}/title`;
    return this.http.put<any[]>(url, { new_chat_title: requestData.new_chat_title }, this.headers);
   }

   getChatDetails(databaseId: number | string, userId: number | string, sessionId: string): Observable<any> {
    const url = `${ this.baseUrl }/v1/database/${databaseId}/user/${userId}/session/${sessionId}/chat`;
    return this.http.get<any[]>(url, this.headers);
   }

   codeInterpreterPlot(requestData: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/code_interpreter_plot`;
    return this.http.post<any[]>(url, requestData, this.headers);
   }

   codeInterpreterPlotly(requestData: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/code_interpreter_plotly`;
    return this.http.post<any[]>(url, requestData, this.headers);
   }
   
   saveChatHistory(requestData: any): Observable<any> {
    const url = `${ this.baseUrl }/v1/chats`;
    return this.http.post<any[]>(url, requestData, this.headers);
   }
}