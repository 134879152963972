import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Agent } from "@app/shared/models/agent";

@Component({
    selector: 'app-agent-rating-button',
    templateUrl: './agent-rating-button.component.html',
    styleUrls: ['./agent-rating-button.component.css']
})
export class AgentRatingButtonComponent implements OnChanges {
    @Input() agent?: Agent;
    @Output() onRatedEvent = new EventEmitter<boolean>();

    likedByUser: boolean = false;

    constructor() { }

    ngOnChanges(): void {
        this.likedByUser = this.agent?.rating?.likedByUser || false;
    }

    toggleLike(): void {
        this.onRatedEvent.emit(!this.likedByUser);
    }
}
