import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map } from "rxjs/operators";
import { askOurDocsRoles } from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AskOurDocsRoleGuard implements CanActivate {

  constructor(
    private oidcIdentityService: OidcSecurityService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAskOurDocSpecificRepoRoleByIndexName(route.params['indexName']).pipe(map((hasRole) => {
      if (hasRole){
        return true;
      }else{
        return this.router.createUrlTree(['/error']);
      }
    }));
  }

  hasAskOurDocsRoles() {
    return this.oidcIdentityService.userData$.pipe(
      map(userData => {
        let roles = userData?.userData?.['roles'];
        return roles?.some((role: string[]) => role.includes(askOurDocsRoles.IndexPrefix)) || false;
      })
    );
  }

  hasAskOurDocSpecificRepoRoleByIndexName(indexName: string): Observable<boolean> {
    return this.oidcIdentityService.userData$.pipe(
      map(userData => {
        let roles = userData?.userData?.['roles'];
        return (indexName.includes(askOurDocsRoles.IndexPrefix) && roles?.includes(indexName)) || false;
      })
    );
  }

}

