<div class="wrapper" [ngClass]="contentPosition">
  <div class="saved-prompts" *ngIf="savedPrompts; else chatWrapper">
    <app-saved-prompts
    (promptSelectEvent)="promptSelected($event)"
    (backToChatEvent)="showChat()" />
  </div>
</div>
<ng-template #chatWrapper>
  <app-prompt-settings-new (savedPromptsSelectedEvent)="showSavedPrompts()" [settings]="promptSettings" />
  <ng-content></ng-content>
  <div id="xls-not-supported-notification" *ngIf="showXLSUnsupportedNotification">
    <app-notification-card
      label="Gene.AI no longer supports the .xls file type. "
      message="Please save your files as .xlsx and re-upload to continue."
      type="warning"
      color="#E71316"
    />
  </div>
  <prompt-input-new
    [promptSettings]="promptSettings"
    [isStreamingResponse]="isStreamingResponse"
    [config]="promptInputConfig"
    (promptSubmit)="promptSubmit.emit($event)"
    (promptSettingsClick)="openPromptSettings()"
    (stopStreaming)="stopStreaming.emit($event)"
    (unsupportedFileFound)="handleUnsupportedFileEvent($event)"
  />
</ng-template>
