import { Inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {

  constructor(@Inject('BASE_API_URL') private baseUrl: string, private http: HttpClient) {
  }

  getPlatforms(): Observable<Array<string>> {
    return this.http.get<Array<string>>(this.baseUrl + '/v1/social-media/platforms', this.getHeaders())
  }
  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }
}
