import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { Chat } from 'src/app/shared/models/chat.model';
import { Message } from 'src/app/shared/models/message.model';
import { Prompt } from '@shared/models/prompt.model';
import { ChatAndPrompt } from "src/app/shared/models/chat-and-prompt.model";
import { SocialMediaPrompt } from 'src/app/shared/models/social-media-prompt.model';
import { ChatAndSocialMediaPrompt } from 'src/app/shared/models/chat-and-social-media-prompt.model';
import { StreamingService } from '../streaming/streaming.service';
import { PagedChatMetaData } from "@shared/models/paged-chat-metadata.model";
import { sortDirection } from "@shared/models/sort-direction.model";
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";
import { finalize } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    @Inject('BASE_API_URL') private baseUrl: string,
    private http: HttpClient,
    private streamingService: StreamingService,
    private chatHistoryUpdateService: ChatHistoryUpdateService
  ) {
  }

  promptChat(prompt: Prompt, useStreaming: boolean = false): Observable<ChatAndPrompt> {
    if (!useStreaming) {
      return this.http.post<ChatAndPrompt>(`${this.baseUrl}/v1/chats/prompt`, prompt, this.getHeaders());
    }

    const props = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(prompt),
    };
    let chatId: string;
    return this.streamingService.fetch(`${this.baseUrl}/v2/chats/prompt`, props, ChatAndPrompt)
      .pipe(
        tap((chatAndPrompt) => {
          if(!chatId && !!chatAndPrompt.chat.id) {
            chatId = chatAndPrompt.chat.id;
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(()=> this.chatHistoryUpdateService.notifyChatUpdated(chatId))
      );
  }

  promptImageChat(prompt: Prompt): Observable<ChatAndPrompt> {
    return this.http.post<ChatAndPrompt>(this.baseUrl + `/v1/chats/image/prompt`, prompt, this.getHeaders());
  }

  prompt(chatId: string, prompt: Prompt, useStreaming: boolean = false): Observable<Prompt> {
    if (!useStreaming) {
      return this.http.post<Prompt>(this.baseUrl + `/v1/chats/${chatId}/prompt`, prompt, this.getHeaders());
    }

    const props = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(prompt),
    };

    return this.streamingService.fetch(`${this.baseUrl}/v2/chats/${chatId}/prompt`, props, Prompt);
  }

  promptImage(chatId: string, prompt: Prompt): Observable<Prompt> {
    return this.http.post<Prompt>(this.baseUrl + `/v1/chats/image/${chatId}/prompt`, prompt, this.getHeaders());
  }

  regenerateResponse(chatId: string, prompt: Prompt, useStreaming: boolean = false): Observable<Prompt> {
    if (!useStreaming) {
      return this.http.post<Prompt>(this.baseUrl + `/v1/chats/${chatId}/prompt/${prompt.id}/regenerate`, this.getHeaders());
    }

    const props = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(prompt),
    };

    return this.streamingService.fetch(`${this.baseUrl}/v2/chats/${chatId}/prompt/${prompt.id}/regenerate`, props, Prompt);
  }

  getChats(historyType: string): Observable<Array<Chat>> {
    const options = {
      params: {
        type: historyType
      },
      headers: {
        'Content-type': 'application/json'
      }
    };
    return this.http.get<Array<Chat>>(this.baseUrl + `/v1/chats`, options).pipe(
      map((chats) => {
        return chats.map(chat => {
          chat.created = new Date(chat.created)
          return chat
        })
      })
    );
  }

  getChat(chatId: string): Observable<Chat> {
    return this.http.get<Chat>(this.baseUrl + `/v1/chats/${chatId}`, this.getHeaders()).pipe(
      map((chat) => {
          chat.created = new Date(chat.created)
          return chat
        }
      ));
  }

  getChatMessages(chatId: string): Observable<Array<Message>> {
    return this.http.get<Array<Message>>(this.baseUrl + `/v1/chats/${chatId}/messages`, this.getHeaders());
  }

  update(chat: Chat): Observable<void> {
    return this.http.put<void>(this.baseUrl + `/v1/chats/${chat.id}`, chat, this.getHeaders());
  }

  delete(chatId: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `/v1/chats/${chatId}`, this.getHeaders());
  }

  save(chat: Chat): Observable<Chat> {
    return this.http.post<Chat>(this.baseUrl + '/v1/chats', chat, this.getHeaders()).pipe(
      map(chat => {
        chat.created = new Date(chat.created)
        return chat;
      })
    );
  }

  socialMediaChat(prompt: SocialMediaPrompt): Observable<ChatAndSocialMediaPrompt> {
    return this.http.post<ChatAndSocialMediaPrompt>(this.baseUrl + '/v1/chats/social-media', prompt, this.getHeaders());
  }

  socialMediaPrompt(chatId: string, prompt: SocialMediaPrompt): Observable<SocialMediaPrompt> {
    return this.http.post<SocialMediaPrompt>(this.baseUrl + `/v1/chats/${chatId}/social-media`, prompt, this.getHeaders());
  }

  pagedChats(page: number, size: number, filterString?: string, sortDirection: sortDirection | undefined = 'DESC'): Observable<PagedChatMetaData> {
    const options = {
      params: {
        page,
        itemsPerPage: size,
        ...(filterString && { filter: filterString }),
        ...(sortDirection && { sortDirection })
      },
      headers: {
        'Content-type': 'application/json'
      }
    };

    return this.http.get<PagedChatMetaData>(this.baseUrl + `/v2/chats/page`, options).pipe(
      map((page) => {
        page.content = page.content.map(chatMetaData => {
          chatMetaData.created = new Date(chatMetaData.created);
          chatMetaData.updatedAt = new Date(chatMetaData.updatedAt);
          return chatMetaData;
        });
        return page;
      }));
  }

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }
}
