import { Injectable } from "@angular/core";
import { AskOurDocsService } from "../ask-our-docs.service";
import { FormControl, FormGroup } from "@angular/forms";
import {
  BehaviorSubject,
  catchError,
  delay,
  filter,
  map,
  Observable,
  of,
  scan,
  shareReplay,
  switchMap
} from "rxjs";
import { EmailFormatValidator } from "@shared/validators/email-format/email-format.validator";
import { NoDuplicatesValidator } from "@shared/validators/no-duplicates/no-duplicates.validator";
import { MaxLimitValidator } from "@shared/validators/max-limit/max-limit.validator";
import { AODAddUserSupportedFormErrors } from "@shared/constants/ask-our-docs/add-user-modal/supported-form-errors";

@Injectable({
  providedIn: 'root'
})
export class AskOurDocsAddUserModalService {
  private readonly MAX_ADMIN_LIMIT = 5;
  private readonly roleOptions = {
    User: "User",
    Admin: `Admin (${this.MAX_ADMIN_LIMIT} max)`
  };

  private readonly formGroup: FormGroup = new FormGroup({
    role: new FormControl(this.roleOptions.User),
    typedEmail: new FormControl(''),
    emails: new FormControl([] as string[], {
      validators: [
        NoDuplicatesValidator.validate,
        EmailFormatValidator.validate
      ]
    }),
    ids: new FormControl([] as string[], {
      validators: [
        NoDuplicatesValidator.validate
      ]
    })
  });

  private companyEmailValidator$: BehaviorSubject<{ email: string, id?: string, action: 'add' | 'remove' } | null>;
  private companyValidatedEmails$: Observable<{ email: string, id: string, isNonCompanyEmail: boolean, error: boolean }[]>;

  constructor(
    private askOurDocsService: AskOurDocsService
  ) {
    this.companyEmailValidator$ = this.buildCompanyEmailValidator();
    this.companyValidatedEmails$ = this.getCompanyValidatedEmailsListener();
  }

  get properties(): {
    formGroup: FormGroup,
    maxAdminLimit: number,
    roleOptions: { User: string, Admin: string }
  } {
    return {
      formGroup: this.formGroup,
      roleOptions: this.roleOptions,
      maxAdminLimit: this.MAX_ADMIN_LIMIT
    };
  };

  get isActionDisabled$(): Observable<boolean> {
    if (this.formGroup.controls['emails'].value.length === 0) {
      return of(true);
    }

    return this.isFormError$;
  };

  get isFormError$(): Observable<boolean> {
    return !this.isValidForm ? of(true) : this.nonCompanyEmailsProvided$.pipe(
      switchMap(nonCompanyEmailsProvided => nonCompanyEmailsProvided ? of(true) : this.failedToValidateEmails$)
    );
  };

  get isValidForm(): boolean {
    return this.formGroup.valid;
  };

  isExpectedFormError$(error: string): Observable<boolean> {
    if (error === AODAddUserSupportedFormErrors.NON_COMPANY_EMAILS) {
      return this.nonCompanyEmailsProvided$;
    }

    if (error === AODAddUserSupportedFormErrors.UNABLE_TO_VALIDATE_EMAIL) {
      return this.failedToValidateEmails$;
    }

    return of(this.formGroup.controls['emails'].hasError(error));
  };

  onRoleChange(event: any): void {
    this.formGroup.controls['role'].patchValue(event.selectedOption);
    this.updateEmailValidators();
  };

  updateEmailValidators(): void {
    const validators = [
      NoDuplicatesValidator.validate,
      EmailFormatValidator.validate
    ];

    if (this.formGroup.controls['role'].value === this.roleOptions.Admin) {
      validators.push((control) => MaxLimitValidator.validate(control, this.MAX_ADMIN_LIMIT));
    }

    this.formGroup.controls['emails'].setValidators(validators);
    this.formGroup.controls['emails'].updateValueAndValidity();
  };

  clearTypedEmail(): void {
    this.formGroup.controls['typedEmail'].patchValue('');
  };

  addEmail(email: string): void {
    const emailsControl = this.formGroup.controls['emails'];
    emailsControl.patchValue([...emailsControl.value, email]);

    if (this.isEmailInFormErrorList(email).error !== '') {
      return;
    }

    this.companyEmailValidator$.next({ email, action: 'add' });
  };

  isEmailInvalidForSubmission$(email: string): Observable<{ error: string }> {
    const formError = this.isEmailInFormErrorList(email).error;
    if (formError !== '') {
      return of({ error: formError });
    }

    return this.companyValidatedEmails$.pipe(
      map(emails => emails.find(e => e.email.toLowerCase() === email.toLowerCase())),
      map(validatedEmail => {
        if (!validatedEmail) {
          return { error: '' };
        }

        if (validatedEmail.error) {
          return { error: AODAddUserSupportedFormErrors.UNABLE_TO_VALIDATE_EMAIL };
        }

        return validatedEmail.isNonCompanyEmail ? { error: AODAddUserSupportedFormErrors.NON_COMPANY_EMAILS } : { error: '' };
      })
    );
  };

  removeLastEmailFromList(): void {
    const emailsControl = this.formGroup.controls['emails'];
    const idsControl = this.formGroup.controls['ids'];

    if (emailsControl.value.length === 0) {
      return;
    }

    const lastEmail = emailsControl.value[emailsControl.value.length - 1];

    if (emailsControl.value.length === idsControl.value.length) {
      idsControl.patchValue(idsControl.value.slice(0, -1));
    }

    emailsControl.patchValue(emailsControl.value.slice(0, -1));

    this.companyEmailValidator$.next({ email: lastEmail, action: 'remove' });
  };

  removeEmailAndId(email: string): void {
    const emailsControl = this.formGroup.controls['emails'];
    const emails = emailsControl.value;
    const index = emails.indexOf(email);

    const idsControl = this.formGroup.controls['ids'];
    const ids = idsControl.value;

    if (index === -1) {
      return;
    }

    if (emailsControl.value.length === idsControl.value.length) {
      idsControl.patchValue([...ids.slice(0, index), ...ids.slice(index + 1)]);
    }

    emailsControl.patchValue([...emails.slice(0, index), ...emails.slice(index + 1)]);

    this.companyEmailValidator$.next({ email, action: 'remove' });
  };

  clearEmails(): void {
    this.formGroup.controls['emails'].patchValue([]);
    this.formGroup.controls['ids'].patchValue([]);
    this.companyEmailValidator$ = this.buildCompanyEmailValidator();
    this.companyValidatedEmails$ = this.getCompanyValidatedEmailsListener();

  };

  private get nonCompanyEmailsProvided$(): Observable<boolean> {
    return this.companyValidatedEmails$.pipe(
      map(emails => emails.some(e => e.isNonCompanyEmail && e.error === false))
    );
  };

  private get failedToValidateEmails$(): Observable<boolean> {
    return this.companyValidatedEmails$.pipe(
      map(emails => emails.some(e => e.error === true))
    );
  };

  private buildCompanyEmailValidator(): BehaviorSubject<{ email: string, action: 'add' | 'remove' } | null> {
    return new BehaviorSubject<{ email: string, action: 'add' | 'remove' } | null>(null);
  };

  private getCompanyValidatedEmailsListener(): Observable<{ email: string, id: string, isNonCompanyEmail: boolean, error: boolean }[]> {
    const supportedFinUserErrorCodes = {
      RESOURCE_NOT_FOUND: 'Request_ResourceNotFound'
    };

    const supportErrorHandlingIds = {
      USER_NOT_FOUND_ERROR_ID: 'user-not-found-error',
      VALIDATING_USER_ERROR_ID: 'validating-user-error'
    };

    return this.companyEmailValidator$.pipe(
      delay(0),
      filter(event => event != null),
      switchMap(event => {

        if (event!.action === 'remove') {
          return of(event);
        }

        return this.askOurDocsService.findUserByEmail(event!.email).pipe(
          catchError((error) => of({
            id: (error.code === supportedFinUserErrorCodes.RESOURCE_NOT_FOUND)
              ? supportErrorHandlingIds.USER_NOT_FOUND_ERROR_ID
              : supportErrorHandlingIds.VALIDATING_USER_ERROR_ID
          })),
          map(user => (
            (user.id === supportErrorHandlingIds.VALIDATING_USER_ERROR_ID)
              ? {
                email: event!.email,
                isNonCompanyEmail: true,
                action: 'add',
                error: true,
                id: user.id
              }
              : {
                email: event!.email,
                isNonCompanyEmail: (user.id === supportErrorHandlingIds.USER_NOT_FOUND_ERROR_ID),
                action: 'add',
                error: false,
                id: user.id
              }
          ))
        );
      }),
      scan((acc, event) => {
        if (event!.action === 'add') {
          const idsControl = this.formGroup.controls['ids'];
          idsControl.patchValue([...idsControl.value, event!.id]);

          return [...acc, event] as { email: string, id: string, isNonCompanyEmail: boolean, error: boolean }[];
        }

        if (this.formGroup.controls['emails'].value.includes(event!.email)) {
          return acc;
        }

        return acc.filter(e => e.email !== event!.email);
      }, [] as { email: string, id: string, isNonCompanyEmail: boolean, error: boolean }[]),
      shareReplay(1)
    );
  };

  private isEmailInFormErrorList(email: string): { error: string } {
    const errors = this.formGroup.get('emails')?.errors;

    if (!errors) {
      return { error: '' };
    }

    const errorList: { [key: string]: any[] } = {
      duplicateEmails: errors['duplicateValues']?.map((email: string) => email.toLowerCase()) || [],
      invalidEmails: errors['invalidEmails']?.map((email: string) => email.toLowerCase()) || [],
      maxLimit: errors['maxLimit']?.map((email: string) => email.toLowerCase()) || []
    };

    const errorKey = Object.keys(errorList).find(key => errorList[key]?.includes(email.toLowerCase()));
    const errorKeyMap = {
      duplicateEmails: AODAddUserSupportedFormErrors.DUPLICATE_VALUES,
      invalidEmails: AODAddUserSupportedFormErrors.INVALID_EMAILS,
      maxLimit: AODAddUserSupportedFormErrors.MAX_LIMIT
    };

    const errorKeyMapped = errorKey ? errorKeyMap[errorKey as keyof typeof errorKeyMap] : '';
    return { error: errorKeyMapped };
  };
}
