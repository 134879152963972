import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { GroupMembershipService } from "@services/group-membership/group-membership.service";
import {
  catchError,
  concatMap,
  debounceTime,
  EMPTY,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap
} from "rxjs";
import { take, tap } from 'rxjs/operators';

import { KmdModalService, KmdPaginationComponent, KmdTabsComponent } from "gds-atom-components";
import { FormControl, FormGroup, FormArray, Validators, ValidatorFn, ValidationErrors,AbstractControl, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PagedAodGroupUser } from "@shared/models/paged-aod-group-user.model";
import {
  AodGroupUser,
  GroupUserRoleOptions,
  GroupUserRoleType,
  GroupUserSortOptions
} from "@shared/models/aod-group-user";
import { AlertService } from "@services/alert/alert.service";
import { AskOurDocsUserManagementAddUsersNewComponent } from "./ask-our-docs-user-management-add-users-new/ask-our-docs-user-management-add-users-new.component";
import { AskOurDocsUserManagementRemoveUsersNewComponent } from "./ask-our-docs-user-management-remove-users-new/ask-our-docs-user-management-remove-users-new.component";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { askOurDocsRoles } from "@shared/constants/roles/roles";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { AskOurDocsV2Service } from '@app/core/services/ask-our-docs-v2/ask-our-docs-v2.service';
import { environment, featureFlags } from "@app/environments/environment";
import { AdminPromptLibraryComponent } from "../components/admin-prompt-library/admin-prompt-library.component";

@Component({
  selector: 'app-ask-our-docs-user-management-page-new',
  templateUrl: './ask-our-docs-user-management-page-new.component.html',
  styleUrls: ['./ask-our-docs-user-management-page-new.component.css']
})
export class AskOurDocsUserManagementPageNewComponent implements OnInit, OnDestroy{
  
  @ViewChild('pagination') pagination!: KmdPaginationComponent;
  @ViewChild('headCheckbox') headCheckbox!: ElementRef;
  @ViewChild('addUsersModal') addUsersModal?: AskOurDocsUserManagementAddUsersNewComponent;
  @ViewChild('removeUsersModal') removeUsersModal?: AskOurDocsUserManagementRemoveUsersNewComponent;
  @ViewChild('tabsComponent') tabsComponent!: KmdTabsComponent;
  @ViewChild('promptLibrary') promptLibraryComponent!: AdminPromptLibraryComponent;

  protected readonly featureFlags = featureFlags;
  aodUrl: string = '';
  aodUrlPure: string = '';
  
  groupId!: string;
  indexName!: string;
  allUsersOriginal$!: Observable<any>;
  initialAllUsers$: Observable<PagedAodGroupUser> = of();
  allUsers$: Observable<PagedAodGroupUser> = of();
  repoName: string = "";
  activeIndex: number = 0;

  selectedUsers: Map<string, { email: string, role: string }> = new Map();
  repoAdmins: Map<string, { email: string, role: string }> = new Map();
  usersToBeAdded: any[] = [];
  usersInError: any[] = [];
  usersInSuccess: any[] = [];
  userEmails: string[] = [];
  promptSuggestions: boolean = featureFlags.promptSuggestions;
  isLinkModalVisible: boolean = false;
  isLinkSelected: boolean = false;
  isButtonEnabled: boolean = false;
  selectedText: string = '';
  linkAddress: string = '';
  modalForm: FormGroup;

  configForm: FormGroup = new FormGroup({});
  repoOptions: any[] = [];
  isBannerEnabled = false;
  isPromptEnabled = false;
  prompts: number[] = [];
  fileContent: any = null;
  alertMessage: string | null = null;
  alertType: string | null = null;
  showAlert: boolean = false;
  i: number = 0;
  isConfigurationTabActive = false;
  title_value: string = '';
  state: any = {};
  config: AngularEditorConfig = {
    showToolbar: false,
    editable: true,
    spellcheck: true,
    minHeight: '2.5rem',
    height: '2.5rem',
    placeholder: 'Enter Subtitle here...',
  };
   transformHTMLtoCustomFormat(html: string | null | undefined): string {
    if (!html) {
      return ''; 
    }
    html = html.replace(/&#160;/g, '');
    const regex = /<a href="([^"]+)"[^>]*>(.*?)<\/a>([.,!?;:]?)/g;
    const customText = html.replace(regex, '#%#$2|$1#%#$3');
    return customText;
  }

  transformHTMLtoCustomFormatSubtitle(html: string | null | undefined): string {
    if (!html) {
      return ''; 
    }
    html = html.replace(/&#160;/g, '');
    const regex = /<a href="([^"]+)"[^>]*>(.*?)<\/a>/g;
    const customText = html.replace(regex, '[$2|$1]');
    return customText;
  }

  transformCustomFormatToHTMLSubtitle(customText: string | null | undefined): string {
    if (!customText) {
      return ''; 
    }
    const regex = /\[(.*?)\|(.*?)\]/g;
    const html = customText.replace(regex, '<a href="$2" target="_blank">$1</a>');
    return html;
  }

  readonly DEBOUNCE_TIME = 200;
  RESULTS_PER_PAGE = 20;

  isConfigTabActive: boolean = false;

  protected readonly roleFilters = Object.values(GroupUserRoleOptions);
  protected readonly sortingFilters = Object.values(GroupUserSortOptions);
  protected readonly ALL_ROLES_FILTER = GroupUserRoleOptions.ALL_ROLES;

  private activeSortBy = GroupUserSortOptions.ROLE_ADMIN_FIRST;

  USER_ADDITION_SUCCESS_MESSAGE = "Users access granted successfully.";
  USER_ADDITION_ERROR_MESSAGE = "Request failed, please try again.";

  formGroup = new FormGroup({
    search: new FormControl('', { nonNullable: true }),
    sorting: new FormControl(this.activeSortBy, { nonNullable: true }),
    role: new FormControl(this.ALL_ROLES_FILTER, { nonNullable: true })
  });

  pageFormControl = new FormControl(0, { nonNullable: true })
  showRoles: boolean = false;
  userId: string = "";
  userName: string = "";
  isUserAdminSelfSelected: boolean = false;
  addAdminError: boolean = false;

  formChanged = false;
  tilePromptsInvalid = false;
  formChangeSubscription: Subscription | undefined;
  initialFormValue: any;
  hyperlinkInSubtitle: boolean = featureFlags.hyperlinkInSubtitle
  constructor(
    private groupMemberService: GroupMembershipService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private cdRef: ChangeDetectorRef,
    public oidcSecurityService: OidcSecurityService,
    private kmdModalService: KmdModalService,
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private configurationService: AskOurDocsV2Service,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      this.groupId = params["groupId"];
      this.indexName = params["indexName"];
    });

    this.oidcSecurityService.userData$.pipe(
      take(1)
    ).subscribe((result) => {
      this.userId = result.userData.oid;
      this.userName = result.userData.name;
      let firstName = this.userName.split(',')[1];
      this.title_value = 'Hello,' + firstName + ' How can I assist you today?';
    });
    if(featureFlags.shouldShowAodV2InsteadOfAod) {
      this.aodUrl = "/" + environment.aodUrl;
      this.aodUrlPure = environment.aodUrl;
    }
    else {
      this.aodUrl = "/" + environment.aodV2Url;
      this.aodUrlPure = environment.aodV2Url;
    }

    this.modalForm = this.fb.group({
      selectedText: [''],
      linkAddress: ['',[Validators.required, this.urlValidator()]]
    });
  }

  ngOnInit(): void {
    this.configForm = this.fb.group({
      selectedRepo: [''],
      version: [''],
      enable_tile: [false],
      enable_citation_download: [false],
      enable_repository_link: [false],
      enable_custom_banner: [false],
      tile_prompts: this.fb.array([], this.noDuplicateShortPromptsValidator),
      custom_banner_text: [''],
      index_name_map: [''],
      system_message: ['', Validators.required],
      usecase_description: [''],
      subtitle: ['', Validators.required],
      sample_questions: this.fb.array([], this.noDuplicateSampleQuestionsValidator),
    });

    this.addPrompts();

    this.configurationService.getFiles().subscribe({
      next: (repoOptions) => {
        this.repoOptions = repoOptions;
      },
      error: (err) => {
        console.error('Error fetching files', err);
      }
    });
    const url = this.route.snapshot.url.join('/');
    const regex = new RegExp(this.aodUrlPure + "/(.*?)/admin");
    const match = url.match(regex);
    if (match && match[1]) {
      this.onFileSelected(match[1]);
    }
    this.configForm.get('selectedRepo')?.valueChanges.subscribe((selectedFileName) => {
      this.onFileSelected(selectedFileName.value);
    });

    this.configForm.get('enable_custom_banner')?.valueChanges.subscribe(value => {
      this.isBannerEnabled = value;
    });

    this.configForm.get('enable_tile')?.valueChanges.subscribe(value => {
      this.isPromptEnabled = value;
      this.togglePrompt();
    });

    this.repoName = this.getAskOurDocRepoName(this.indexName);
    this.allUsersOriginal$ = this.getAodGroupUsers(this.groupId).pipe(
      tap(() => {
        // Function to be called after async operation completes
        setTimeout(() => {
          this.afterLoadingAllData();
        }, 100);
      })
    );


    this.initializeAllUsersStream();

    const search$ = this.formGroup.controls.search.valueChanges.pipe(
      debounceTime(this.DEBOUNCE_TIME),
      switchMap(() => {
        return this.reloadAllUsersToFirstPage();
      })
    );

    const sort$ = this.formGroup.controls.sorting.valueChanges.pipe(
      switchMap(() => {
        return this.reloadAllUsersToFirstPage();
      })
    );

    const roles$ = this.formGroup.controls.role.valueChanges.pipe(
      switchMap(() => {
        return this.reloadAllUsersToFirstPage();
      })
    );

    const pages$ = this.pageFormControl.valueChanges.pipe(
      switchMap((partial) => {
        return this.pagedAodGroupUser(this.formGroup.controls.role.value, this.formGroup.controls.sorting.value,
          this.formGroup.controls.search.value, partial, this.RESULTS_PER_PAGE);
      })
    )

    this.allUsers$ = merge(this.initialAllUsers$, search$, roles$, sort$, pages$);

    this.allUsers$.subscribe(() => {
      this.afterLoadingAllData();
    });

    this.formChangeSubscription = this.configForm.valueChanges.subscribe(() => {
      this.tilePromptsInvalid = (this.configForm.get('tile_prompts') as FormArray).controls.some(control => control.invalid);
      this.formChanged = !this.areObjectsEqual(this.configForm.value, this.initialFormValue);
    });
    this.formChanged = false;

    this.state = history.state;
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  isConfigFormReadyToSave(): boolean {
    return this.formChanged && this.configForm.valid;
  }

  isConfigFormReadyToSaveNav(): boolean {
    return (this.formChanged && this.configForm.valid) || this.promptLibraryComponent?.canSavePromptLibrary();
  }

  areObjectsEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  afterLoadingAllData() {
    this.route.queryParams.subscribe(params => {
      const tabParam = params['tab'];
      if (tabParam) {
        this.switchToFeedbackFormTab();
      }
    });
  }

  // Logic to switch to the specified tab
  switchToFeedbackFormTab() {
    this.activeIndex = 2;
  }

  initializeAllUsersStream() {
    this.initialAllUsers$ = this.pagedAodGroupUser(this.formGroup.controls.role.value,
      this.formGroup.controls.sorting.value, '', 0, this.RESULTS_PER_PAGE);
  }

  reloadTable() {
    this.formGroup.reset({
      search: '',
      sorting: this.activeSortBy,
      role: this.ALL_ROLES_FILTER
    });
    this.showRoles = false;
    this.selectedUsers.clear();
    this.pageFormControl.setValue(0);
    this.resetHeadCheckbox();

    this.allUsersOriginal$ = this.getAodGroupUsers(this.groupId);
    this.initializeAllUsersStream();

    this.cdRef.detectChanges();
  }

  onPageChange(event: any) {
    this.RESULTS_PER_PAGE = event.resultsPerPage;
    this.pageFormControl.patchValue(event.currentPage - 1);
    this.resetHeadCheckbox();
  }

  onRoleDropdownChange(event: any) {
    this.formGroup.controls.role.patchValue(event.selectedOption);
  }

  get activeRoleFilter() {
    return this.formGroup.controls.role.value;
  }

  clearSearch() {
    this.formGroup.controls.search.patchValue('');
  }

  isSearchEmpty() {
    return this.formGroup.controls.search.value === '';
  }

  pagedAodGroupUser(role: string, sort: string, searchTerm: string, page: number, itemsPerPage: number): Observable<PagedAodGroupUser> {
    const groupUsers$ = this.getSortedAodGroupUsers(sort);

    return groupUsers$.pipe(
      map(users => {
        const filteredUsers = this.search(users, searchTerm)
          .filter(user => {
            switch (role) {
              case GroupUserRoleOptions.ONLY_ADMINS:
                return user.role === GroupUserRoleType[0];
              case GroupUserRoleOptions.ONLY_USERS:
                return user.role === GroupUserRoleType[1];
              default:
                return true;
            }
          });

        const pagedGroupUsers = filteredUsers.slice(itemsPerPage * page, itemsPerPage * page + itemsPerPage);

        return {
          content: pagedGroupUsers,
          totalPages: filteredUsers.length / itemsPerPage,
          totalElements: filteredUsers.length,
          size: itemsPerPage,
          number: page,
          last: itemsPerPage * page + itemsPerPage == filteredUsers.length,
          numberOfElements: pagedGroupUsers.length,
          first: page === 0,
          empty: filteredUsers.length === 0
        };
      }),
      catchError(() => {
        this.alertService.showError("We encountered an unexpected error. Please try again.");
        return EMPTY;
      })
    );
  }

  sortByEmail() {
    this.activeSortBy = (this.activeSortBy === GroupUserSortOptions.USER_EMAIL_ASC)
      ? GroupUserSortOptions.USER_EMAIL_DESC
      : GroupUserSortOptions.USER_EMAIL_ASC;

    this.formGroup.controls.sorting.patchValue(this.activeSortBy);
  }

  sortByRole() {
    if (this.activeRoleFilter !== GroupUserRoleOptions.ALL_ROLES) {
      return;
    }

    this.activeSortBy = (this.activeSortBy === GroupUserSortOptions.ROLE_ADMIN_FIRST)
      ? GroupUserSortOptions.ROLE_USER_FIRST
      : GroupUserSortOptions.ROLE_ADMIN_FIRST;

    this.formGroup.controls.sorting.patchValue(this.activeSortBy);
  }

  search(users: AodGroupUser[], searchTerm: string) {
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return users.filter(user => 
      searchTerms.every(term => 
        user?.email ? user.email.toLowerCase().includes(term) : false
      )
    );  
  }

  toggleCheckboxes(event: Event, isHeadCheckbox: boolean) {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;
    const checkboxes = isHeadCheckbox
      ? document.querySelectorAll('input#user-checkbox') as NodeListOf<HTMLInputElement>
      : [target];

    const actions: { [key: string]: (checkbox: HTMLInputElement, trElement?: HTMLElement) => void } = {
      'true': (checkbox, trElement) => {
        const userId = checkbox.value;
        const userEmail = (trElement?.querySelector('.body-user-email') as HTMLElement)?.innerText;
        const userRole = (trElement?.querySelector('.body-role') as HTMLElement)?.innerText;

        if (userEmail && userRole) this.selectedUsers.set(userId, { email: userEmail, role: userRole });
        checkbox.checked = true;
        trElement?.classList.add('tr-checked');
      },
      'false': (checkbox, trElement) => {
        const userId = checkbox.value;
        this.selectedUsers.delete(userId);
        checkbox.checked = false;
        trElement?.classList.remove('tr-checked');
      }
    };

    checkboxes.forEach(checkbox => {
      const trElement = isHeadCheckbox ? checkbox.closest('tr') : this.findParentTr(checkbox);
      actions[isChecked.toString()](checkbox, trElement ?? undefined);
    });

    if (isHeadCheckbox) {
      document.querySelectorAll('tbody tr').forEach(tr => {
        tr.classList.toggle('tr-checked', isChecked);
      });
    }
    else {
      const totalElements = document.querySelectorAll('tbody tr').length;
      if (this.selectedUsers.size === totalElements) {
        this.headCheckbox.nativeElement.checked = true;
      } else {
        this.headCheckbox.nativeElement.checked = false;
      }
    }
  }


  findParentTr(element: HTMLElement): HTMLElement | null {
    let parent = element.parentElement;

    while (parent) {
      if (parent.tagName === 'TR') {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  }

  resetHeadCheckbox() {
    if (!this.headCheckbox) return;
    this.headCheckbox.nativeElement.checked = false;
    this.cdRef.detectChanges();
  }

  openAddUsersModal() {
    this.addUsersModal?.openModal();
  }

  openRemoveUsersModal() {
    this.removeUsersModal?.openModal();
  }

  private trimShortPrompt(prompts: any[]): any[] {
    return prompts.map(prompt => {
      if (prompt.shortPrompt.length > 50) {
        prompt.shortPrompt = prompt.shortPrompt.substring(0, 50);
      }
      return prompt;
    });
  }

  private getAodGroupUsers(groupId: string): Observable<any> {
    return forkJoin({
      adminsResponse: this.groupMemberService.getGroupOwners(groupId),
      usersResponse: this.groupMemberService.getGroupMembers(groupId),
    }).pipe(
      map(({ adminsResponse, usersResponse }) => ({
        admins: adminsResponse.value,
        users: usersResponse.value,
      })),
      map(({ admins, users }) => {
        const adminsMapped = admins
          .map((admin: any) => ({
            id: admin.id,
            email: admin.mail,
            role: GroupUserRoleType[0]
          }))
          .sort((a: any, b: any) => {
            if(a.email !== null){
              return a?.email.localeCompare(b?.email)
            }
          });

        const usersMapped = users
          .filter((user: any) => !adminsMapped.some((admin: any) => admin.id === user.id))
          .map((user: any) => ({
            id: user.id,
            email: user.mail,
            role: GroupUserRoleType[1]
          }))
          .sort((a: any, b: any) => {
            if(a.email !== null){
              return a?.email.localeCompare(b?.email)
            }
          });

        this.repoAdmins.clear();
        adminsMapped.forEach((admin: { id: string; email: any; role: any; }) => {
          this.repoAdmins.set(admin.id, { email: admin.email, role: admin.role });
        });
        return { adminsMapped, usersMapped };
      }),
      catchError(() => {
        this.alertService.showError("We encountered an unexpected error. Please try again.");
        return EMPTY;
      })
    );
  }

  private getSortedAodGroupUsers(sort: string): Observable<AodGroupUser[]> {
    return this.allUsersOriginal$.pipe(
      map(({ adminsMapped, usersMapped }) => {
        switch (sort) {
          case GroupUserSortOptions.USER_EMAIL_ASC:
            return usersMapped.concat(adminsMapped).sort((a: any, b: any) => a.email.localeCompare(b.email))
          case GroupUserSortOptions.USER_EMAIL_DESC:
            return usersMapped.concat(adminsMapped).sort((a: any, b: any) => b.email.localeCompare(a.email))
          case GroupUserSortOptions.ROLE_ADMIN_FIRST:
            return adminsMapped.concat(usersMapped);
          case GroupUserSortOptions.ROLE_USER_FIRST:
            return usersMapped.concat(adminsMapped);
          default:
            return adminsMapped.concat(usersMapped);
        }
      })
    );
  }

  private reloadAllUsersToFirstPage() {
    this.resetHeadCheckbox();

    if (this.pagination) {
      this.pagination.selectPage(1);
    } else {
      this.pageFormControl.patchValue(0);
    }

    return EMPTY;
  }

  private getAskOurDocRepoName(indexName: string) {
    return indexName.replace(askOurDocsRoles.IndexPrefix, "").replaceAll("_", " ");
  }

  clearSelection() {
    this.selectedUsers.clear();
    this.resetHeadCheckbox();
    document.querySelectorAll('tbody tr').forEach(tr => {
      tr.classList.toggle('tr-checked', false);
    });
  }

  getSelectionCounterLabel(selectedCount: number): string {
    return selectedCount > 1 ? `${selectedCount} users selected` : '1 user selected';
  }

  addUsers(users: any) {
    this.usersToBeAdded = users.ids;

    if (users.role.includes("Admin")) {
      this.addOwnersToGroup(this.usersToBeAdded);
    }
    if (!this.addAdminError) {
      this.addMembersToGroup(this.usersToBeAdded);
    } else {
      this.addAdminError = false;
      this.removeOwnersFromGroup(this.usersToBeAdded);
    }

    this.reloadAllUsersToFirstPage();
  }

  removeOwnersFromGroup(users: any) {
    users.map((user: string) => {
      return this.groupMemberService.removeOwnerFromGroup(this.groupId, user).pipe(
        catchError(() => {
          return of(null);
        })
      );
    })
  }

  addMembersToGroup(userIDs: any) {
    const members = userIDs.map((user: string) => {
      return this.groupMemberService.addMemberToGroup(this.groupId, user).pipe(
        map(() => {
          return user
        }),
        catchError((error) => {
          if (error.message.includes("references already exist")) {
            return user;
          }

          return of(null);
        })
      );
    });

    this.handleUserAdditionResponses(members);
  }

  addOwnersToGroup(userIDs: any) {
    if (userIDs.length > 5) {
      this.addAdminError = true;
      this.alertService.showError("Cannot add more than 5 admins at a time.");
      return;
    }

    const owners = userIDs.map((user: string) => {
      return this.groupMemberService.addOwnerToGroup(this.groupId, user).pipe(
        map(() => user),
        catchError((error) => {
          if (error.message.includes("references already exist")) {
            return user;
          }
          this.addAdminError = true;

          return of(null);
        })
      );
    });

    this.handleUserAdditionResponses(owners);
  }

  handleUserAdditionResponses(users: any) {
    forkJoin(users).subscribe((results: any) => {
      results.forEach((result: string | null) => {
        if (result) {
          this.usersInSuccess.push(result);
        } else {
          this.usersInError.push(result);
        }
      });

      this.reloadTable();

      if (this.usersInSuccess.length === this.usersToBeAdded.length) {
        this.alertService.showSuccess(this.USER_ADDITION_SUCCESS_MESSAGE);
      }

      if (this.usersInError.length > 0 && this.usersInError.length < this.usersToBeAdded.length && !this.addAdminError) {
        this.alertService.showError(`Failed to add ${this.usersInError.length} users. Please review and try again.`);
      }

      if (this.usersInError.length === this.usersToBeAdded.length || this.addAdminError) {
        this.alertService.showError(this.USER_ADDITION_ERROR_MESSAGE);
      }
    });
  }

  toggleAssignRoles() {
    this.showRoles = !this.showRoles;
  }

  grantAdminAccess(): void {
    let failedRequests = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const usersToAdminsObservables = Array.from(this.selectedUsers.entries()).map(([userId, { email, role }]) => {
      if (role === 'Admin') {
        return of(null);
      } else {
        return this.groupMemberService.addOwnerToGroup(this.groupId, userId).pipe(
          catchError(() => {
            failedRequests++;
            return of(null);
          })
        );
      }
    });

    from(usersToAdminsObservables).pipe(
      concatMap(observable => observable),
      catchError(() => {
        this.toggleAssignRoles();
        this.alertService.showError('Error occurred while assigning role, please try again later.');
        return of(null);
      })
    ).subscribe({
      complete: () => {
        if (failedRequests === 0) {
          this.toggleAssignRoles();
          this.reloadTable();
          this.alertService.showSuccess('Users role access changed successfully.');
        } else {
          this.toggleAssignRoles();
          this.alertService.showError('Error occurred while assigning role, please try again later.');
        }
        this.ngOnInit();
      }
    });
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    const isInsideComponent = this.elementRef.nativeElement.contains(targetElement);
    const isInsideAssignAodRoles = !!targetElement.closest('#assign-role');

    if (this.showRoles && this.selectedUsers.size === 0 || isInsideComponent && !isInsideAssignAodRoles) {
      this.showRoles = false;
    }
  }

  areAllAdminsSelected(): boolean {
    const adminIds = Array.from(this.repoAdmins.keys());
    const userIds = Array.from(this.selectedUsers.keys());

    return adminIds.every(id => userIds.includes(id));
  }

  revokeAdminAccess() {
    if (this.areAllAdminsSelected()) {
      this.toggleAssignRoles();
      this.alertService.showError('Unable to remove all admins. Please maintain at least one admin in the group.');
      return;
    }
    if (this.isAdminSelfSelected()) {
      this.kmdModalService.open('revokeSelfAdminModal');
      this.toggleAssignRoles();
      return;
    }
    this.adminsToUsers();
  }

  pushAdminToEnd() {
    const userAdmin = this.selectedUsers.get(this.userId);
    if (userAdmin) {
      this.selectedUsers.delete(this.userId);
      this.selectedUsers.set(this.userId, userAdmin);
    }
  }

  isAdminSelfSelected() {
    const selectedAdmins = new Map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Array.from(this.selectedUsers.entries()).filter(([userId, { role }]) => role === 'Admin')
    );
    this.isUserAdminSelfSelected = selectedAdmins.has(this.userId);
    return this.isUserAdminSelfSelected;

  }

  adminsToUsers() {
    from(Array.from(this.selectedUsers.entries())).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([userId, { role }]) => role === 'Admin'),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap(([userId, { email, role }]) =>
        this.groupMemberService.removeOwnerFromGroup(this.groupId, userId)
          .pipe(
            catchError(() => of(null))
          )
      )
    ).subscribe({
      error: () => {
        this.toggleAssignRoles();
        this.alertService.showError('We encountered an unexpected error. Please try again.');
      },
      complete: () => {
        this.toggleAssignRoles();
        this.reloadTable();
        this.alertService.showSuccess(`User role access changed successfully.`);
      }
    });
  }

  closeModal(modal: string) {
    this.kmdModalService.close(modal);
  }

  openModal(modal: string) {
    this.kmdModalService.open(modal);
  }

  cancelConfigFormChanges(modalName : string) {
    this.kmdModalService.close(modalName);
  }

  get tilePrompts(): FormArray {
    return this.configForm.get('tile_prompts') as FormArray;
  }

  get sampleQuestions(): FormArray {
    return this.configForm.get('sample_questions') as FormArray;
  }

  noDuplicateShortPromptsValidator: ValidatorFn = (formArray: AbstractControl) => {
    const controls = (formArray as FormArray).controls;
    const shortPrompts = controls.map(control => control.get('shortPrompt')?.value);
    const duplicateIndices = shortPrompts
      .map((prompt, index) => shortPrompts.indexOf(prompt) !== index ? index : -1)
      .filter(index => index !== -1);

    controls.forEach((control, index) => {
      if (duplicateIndices.includes(index)) {
        control.get('shortPrompt')?.setErrors({ duplicate: true });
      } else {
        if (control.get('shortPrompt')?.hasError('duplicate')) {
          control.get('shortPrompt')?.setErrors(null);
          control.get('shortPrompt')?.updateValueAndValidity();
        }
      }
    });

    return null;
  }

  noDuplicateSampleQuestionsValidator: ValidatorFn = (formArray: AbstractControl) => {
    const controls = (formArray as FormArray).controls;
    const questions = controls.map(control => control.value);
    const duplicateIndices = questions
      .map((question, index) => questions.indexOf(question) !== index ? index : -1)
      .filter(index => index !== -1);

    controls.forEach((control, index) => {
      if (duplicateIndices.includes(index)) {
        control.setErrors({ duplicate: true });
      } else {
        if (control.hasError('duplicate')) {
          control.setErrors(null);
          control.updateValueAndValidity();
        }
      }
    });

    return null;
  }

  shortPromptLengthValidator: ValidatorFn = (control: AbstractControl) => {
    const value = control.value;
    return value && value.length > 50 ? { tooLong: true } : null;
  };

  onFileSelected(fileName: string) {
    if (fileName) {
      this.configurationService.getFileContent(fileName).subscribe({
        next: (data) => {
          this.fileContent = data;
          this.clearFromArray(this.sampleQuestions);
          this.clearFromArray(this.tilePrompts);
          this.configForm.patchValue({ version: this.fileContent.version });
          this.configForm.patchValue({ enable_tile: this.fileContent.enable_tile });
          this.configForm.patchValue({ enable_citation_download: this.fileContent.enable_citation_download });
          this.configForm.patchValue({ enable_repository_link: this.fileContent.enable_repository_link });
          this.configForm.patchValue({ enable_custom_banner: this.fileContent.enable_custom_banner });
          this.configForm.patchValue({ custom_banner_text: this.fileContent.custom_banner_text });
          this.configForm.patchValue({ index_name_map: this.fileContent.index_name_map });
          this.configForm.patchValue({ system_message: this.fileContent.system_message });
          this.configForm.patchValue({ usecase_description: this.fileContent.usecase_description });
          if (this.hyperlinkInSubtitle) {
            this.configForm.patchValue({ subtitle: this.transformCustomFormatToHTMLSubtitle(this.fileContent.subtitle) });
          } else {
            this.configForm.patchValue({ subtitle: this.fileContent.subtitle });
          }
          if (Array.isArray(this.fileContent.sample_questions)) {
            this.setSampleQuestions(this.fileContent.sample_questions);
          }

          if (Array.isArray(this.fileContent.tile_prompts)) {
            const trimmedShortPrompts = this.trimShortPrompt(this.fileContent.tile_prompts);
            this.setTilePrompts(trimmedShortPrompts);
          }
          this.initialFormValue = this.configForm.value;
          this.formChanged = !this.areObjectsEqual(this.initialFormValue, this.configForm.value)
        },
        error: (err) => {
          console.error('Error fetching file content', err);
        }
      });
    }
  }

  setTilePrompts(prompts: any[]) {
    this.clearFromArray(this.tilePrompts);
    prompts.forEach(prompt => {
      const newEntry = {
        shortPrompt: [prompt.shortPrompt, Validators.required],
        longPrompt: [prompt.longPrompt, Validators.required]
      };
      this.tilePrompts.push(this.fb.group(newEntry, Validators.required));
    });
  }

  setSampleQuestions(questions: string[]) {
    this.clearFromArray(this.sampleQuestions);
    questions.forEach(question => {
      this.sampleQuestions.push(this.fb.control(question, Validators.required));
    });
  }


  addTilePromptItem(item: any) {
    this.tilePrompts.push(this.fb.control(item));
  }

  addSampleQuestionItem(item: any) {
    this.sampleQuestions.push(this.fb.control(item));
  }

  toggleBanner() {
    this.isBannerEnabled = this.configForm.get('enable_custom_banner')?.value;
  }

  togglePrompt() {
    this.isPromptEnabled = this.configForm.get('enable_tile')?.value;
    if (!this.isPromptEnabled) {
      if (Array.isArray(this.fileContent?.sample_questions)) {
        this.setSampleQuestions(this.fileContent.sample_questions);
      }
    }
  }

  addPrompts() {
    this.tilePrompts.push(this.fb.group({
      shortPrompt: ['', [Validators.required, this.shortPromptLengthValidator]],
      longPrompt: ['', Validators.required]
    }));
  }

  removePrompt(index: number): void {
    this.tilePrompts.removeAt(index);
  }

  addQuestion() {
    this.sampleQuestions.push(this.fb.control('', Validators.required));
  }

  removeQuestion(index: number): void {
    this.sampleQuestions.removeAt(index);
  }

  navigateToSourcePage() {
    const state = history.state;
    if(state.isNavigationSourceListingPage){
      this.navigateToRepoListingPage()
    } else {
      this.navigateAndOpenNewChat();
    }  
  }

  cancelAndDontSaveChanges() {
    this.closeModal('confirmation-modal-unsaved-changes');
    this.formChanged = false;
    this.navigateToSourcePage();
  }

  async cancelAndSaveChanges() {
    await this.submitForm();
    this.formChanged = false;
    this.navigateToSourcePage();
  }

  saveUpdatedForms() {
    if(this.isConfigFormReadyToSave()){
      this.submitForm();
    }
    if(this.promptLibraryComponent.canSavePromptLibrary()) {
      this.promptLibraryComponent.savePromptLibrary();
    }
    this.kmdModalService.close('confirmation-modal-unsaved-changes-nav');
  }

  submitForm() {
    if (this.configForm.invalid) {
      this.showAlertMessage('Please fill all the required fields', 'error');
      this.configForm.markAllAsTouched();
      return;
    }
    const formData = this.configForm.value;
    formData.custom_banner_text = this.transformHTMLtoCustomFormat(formData.custom_banner_text);
    if (this.hyperlinkInSubtitle) {
      formData.subtitle = this.transformHTMLtoCustomFormatSubtitle(formData.subtitle);
    }
    this.formChanged = false;
    this.initialFormValue = formData;


    this.configurationService.saveFileContent(this.indexName, formData).subscribe({
      next: () => {
        this.alertService.showSuccess('File updated successfully!');
        this.closeModal('confirmation-modal-save-changes');
        this.closeModal('confirmation-modal-unsaved-changes-nav');
        this.closeModal('confirmation-modal-unsaved-changes');
      },
      error: (err) => {
        console.error('Error saving file content:', err);
        this.alertService.showError('Error saving file content');
      }
    });

  }

  showAlertMessage(message: string, type: string) {
    this.alertMessage = message;
    this.alertType = type;
    this.showAlert = true;
    setTimeout(() => {
      this.showAlert = false;
      this.alertMessage = null;
      this.alertType = null;
    }, 10000);
  }

  resetForm() {
    const currentValues = this.configForm.value;
    this.configForm.reset({
      selectedRepo: '',
      enable_custom_banner: false,
      enable_tile: false,
      enable_citation_download: false,
      enable_repository_link: false,
      custom_banner_text: '',
      system_message: '',
      usecase_description: '',
      subtitle: '',
      version: currentValues.version,
      index_name_map: currentValues.index_name_map,
      sample_questions: currentValues.sample_questions,
      tile_prompts: currentValues.tile_prompts
    });
    this.clearFromArray(this.tilePrompts);
    this.clearFromArray(this.sampleQuestions);
  }

  clearFromArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  moveUp(formArray: FormArray, index: number) {
    if (index > 0) {
      const control = formArray.at(index);
      formArray.removeAt(index);
      formArray.insert(index - 1, control);
    }
  }

  moveDown(formArray: FormArray, index: number) {
    if (index < formArray.controls.length - 1) {
      const control = formArray.at(index);
      formArray.removeAt(index);
      formArray.insert(index + 1, control);
    }
  }

  onTabChange(event: any) {
    this.isConfigTabActive = event.detail.nextId === 'configTab';
  }

  navigateToFeedback() {
    const state = history.state;
    this.router.navigate(['feedback'], { relativeTo: this.route,state: { indexName: this.indexName, repoName: this.repoName, ...state } });
  }

  alertRedirection(): Promise<boolean> {
    return new Promise((resolve) => {
      if(this.promptLibraryComponent.canSavePromptLibrary() || this.isConfigFormReadyToSave() || this.promptLibraryComponent.hasEmptyFields() || !this.configForm.valid) {
        this.kmdModalService.open('confirmation-modal-unsaved-changes-nav');
        this.kmdModalService.closeModal$.subscribe((modalName: string) => {
          if (modalName === 'confirmation-modal-unsaved-changes-nav') {
            resolve(true);
          }
        });
      } else {
        resolve(true);
      }
    });
  }
  
  navigateAndOpenNewChat() {
    const state = history.state;
    this.router.navigate(['../../'], { relativeTo: this.route, state });
  }

  navigateToRepoListingPage() {
    const state = history.state;
    this.router.navigate(['../../../'], { relativeTo: this.route, state });
  }

  cancelConfigForm() {
    if(!this.isConfigFormReadyToSave() && !this.formChanged) {
      this.formChanged = false;
      this.navigateToSourcePage();
    } else {
      this.openModal('confirmation-modal-unsaved-changes');
    }
  }

  urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value ? control.value.trim() : '';
      if (!value) {
        return null;
      }
      const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
      const isValid = urlPattern.test(value);
      return isValid ? null : { invalidUrl: true };
    };
  }

  openLinkModal(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.modalForm.patchValue({ selectedText: this.selectedText });
    this.isLinkModalVisible = true;
  }

  closeLinkModal(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isLinkModalVisible = false;
    this.isButtonEnabled = false;
    this.selectedText = '';
    this.linkAddress = '';
    this.isLinkSelected = false;
    this.modalForm.reset();
  }

  checkSelection() {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const selectedText = selection.toString();
      this.isButtonEnabled = selectedText.length > 0;
      if (this.isButtonEnabled) {
        const range = selection.getRangeAt(0);
        const container = range.commonAncestorContainer;
        const anchorTag = this.findParentAnchorTag(container);
  
        if (anchorTag) {
          this.selectedText = anchorTag.textContent || '';
          this.linkAddress = anchorTag.getAttribute('href') || '';
          this.isLinkSelected = true;
          this.modalForm.patchValue({
            selectedText: this.selectedText,
            linkAddress: this.linkAddress
          });
        } else {
          this.selectedText = selectedText;
          this.linkAddress = '';
          this.isLinkSelected = false;
          this.modalForm.patchValue({
            selectedText: this.selectedText,
            linkAddress: this.linkAddress
          });
        }
      }
    }
  }

  findParentAnchorTag(node: Node): HTMLAnchorElement | null {
    while (node) {
      if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName === 'A') {
        return node as HTMLAnchorElement;
      }
      node = node.parentNode as Node;
    }
    return null;
  }

  insertLink(){

    const newText = `<a href="${this.modalForm.value.linkAddress}" target="_blank">${this.modalForm.value.selectedText}</a>`;

    const subtitleControl = this.configForm.get('subtitle');
    if (subtitleControl) {
      let currentValue = subtitleControl.value;

      const linkPattern = new RegExp(`<a [^>]*>${this.selectedText}</a>`, 'g');
      currentValue = currentValue.replace(linkPattern, this.selectedText);

      const genericLinkPattern = new RegExp(`<a [^>]*>(.*?)</a>`, 'g');
      currentValue = currentValue.replace(genericLinkPattern, '$1');

      const updatedValue = currentValue.replace(this.selectedText, newText);
      subtitleControl.setValue(updatedValue);
    }

    this.isLinkModalVisible = false;
    this.isButtonEnabled = false;
    this.selectedText = '';
    this.linkAddress = '';
    this.modalForm.reset();

    this.alertService.showSuccess("Link inserted successfully");

  }
  unlink() {
    const subtitleControl = this.configForm.get('subtitle');

    if (subtitleControl) {
      let currentValue = subtitleControl.value;

      // Remove the link around the selected text
      const linkPattern = new RegExp(`<a [^>]*>${this.selectedText}</a>`, 'g');
      currentValue = currentValue.replace(linkPattern, this.selectedText);

      subtitleControl.setValue(currentValue);
    }

    this.isLinkModalVisible = false;
    this.isButtonEnabled = false;
    this.selectedText = '';
    this.linkAddress = '';
    this.isLinkSelected = false;
    this.modalForm.reset();

    this.alertService.showSuccess("Link removed successfully");
  }

}
