<ng-container *ngFor="let chatMessage of visibleMessages; trackBy: trackMessage; let isLatestMessage=last">
  <div class="tr" [ngClass]="getChatTableClass()">
    <div class="td">
      <ng-template [ngIf]="chatMessage.hasSiblings()">
        <div class="nav-buttons">
          <button [ngClass]="!chatMessage.hasLeftSibling() ? 'disabled left' : ''"
            class="regenerate-arrow-buttons" (click)="branchTo(chatMessage.leftSibling())">
            <img *ngIf="chatMessage.hasLeftSibling(); else leftDisabled"
              src="assets/icons/regenerate/regenerate-chevron-left.svg">
            <ng-template #leftDisabled>
              <img src="assets/icons/regenerate/regenerate-chevron-left-disabled.svg">
            </ng-template>
          </button>
          <span>{{ chatMessage.siblingIndex()! + 1 }} / {{chatMessage.siblingsCount()}}</span>
          <button
            [ngClass]="!chatMessage.rightSibling() ? 'disabled right' : ''"
            class="regenerate-arrow-buttons" (click)="branchTo(chatMessage.rightSibling())">
            <img
              *ngIf="chatMessage.hasRightSibling(); else rightDisabled"
              src="assets/icons/regenerate/regenerate-chevron-right.svg">
            <ng-template #rightDisabled>
              <img src="assets/icons/regenerate/regenerate-chevron-right-disabled.svg">
            </ng-template>
          </button>
        </div>
      </ng-template>
    </div>
    <div class="td" [ngClass]="chatMessage.message.author.role === 'USER' ? 'white' : 'gray'">
      <div class="documents-selected" *ngIf="this.chatType=== 'ask_my_docs'">
        <div *ngIf="isValidMessage(chatMessage.message)" [id]="'docSelected' + chatMessage.message.id" class="doc-selected-badge"
             [tooltipWidth]=300 kmdTooltip="{{getAllFileReferencesByMessage(chatMessage.message)}}" tooltipPlacement="bottom"
             [tooltipDisabled]=isFileReferencesToolTipEnabled(chatMessage.message)>Documents selected:
          {{getFormattedFileReferencesByMessage(chatMessage.message)}}</div>
      </div>
      <div *ngIf="chatType !== 'image-creation'; else imageChat">
        <div id="non-image-creation-chat" class="chat-item">
          <app-circle-with-initials [user]="chatMessage.message.author.role === 'USER' ? 'User' : 'AskGene'"
            [username]="username || 'NA'"></app-circle-with-initials>
          <markdown id="message-body" class="markdown-text" [ngClass]="displayStreamingAnimation(chatMessage.message, isLatestMessage) ? 'streaming-response' : ''">
              {{ chatMessage.message.text }}
          </markdown>
          <ng-template [ngIf]="chatMessage.message.author.role === 'USER' && showSavePrompt">
            <img src="../../assets/icons/save/save-24px-mono.svg" class="gene-icon" id="save-prompt"
                 kmdTooltip="Save Prompt" (click)="openModal(chatMessage.message)" tooltipPlacement="left">
          </ng-template>
          <ng-template [ngIf]="chatMessage.message.author.role !== 'USER' && showCopyPrompt">
            <img src="assets/icons/copy/Size=16px,%20Style=Mono.svg" (click)="openCopyResponse($event, chatMessage.message.text)"
              class="gene-icon" id="copy-response-icon" kmdTooltip="Copy Response" tooltipPlacement="left">
          </ng-template>
        </div>
        <div *ngIf="chatMessage.message?.sources && chatMessage.message?.sources!.length > 0" class="web-search-sources-container">
          <ul *ngIf="chatType !== 'agents'; else agentSources">
            <b id="references">Sources:</b>
            <li *ngFor="let source of chatMessage.message?.sources">
              <a [href]="source.url" target="_blank">{{source.title}}{{showFilePage(source.url)}}</a>
            </li>
          </ul>
        </div>
      </div>
      <ng-template #imageChat>
        <div id="image-creation-chat" class="chat-item">
          <app-circle-with-initials [user]="chatMessage.message.author.role === 'USER' ? 'User' : 'AskGene'"
            [username]="username || 'NA'"></app-circle-with-initials>
          <ng-template [ngIf]="chatMessage.message.author.role === 'USER'">
            <markdown class="markdown-text" id="message-body">{{ chatMessage.message.text }}</markdown>
          </ng-template>
          <ng-template [ngIf]="chatMessage.message.author.role !== 'USER'">
            <div class="markdown-text" id="message-body">
              <app-chat-image-messages (responseSelectEventImage)="responseSelected($event)"
                [message]="chatMessage.message"></app-chat-image-messages>
            </div>
          </ng-template>
        </div>
      </ng-template>
      <ng-template #agentSources>
        <div class="agent-sources" id="referencesFor-{{chatMessage.message.id!}}" (click)="toggleAccordionIcon(chatMessage.message.id!)">
            <b >References</b><img class="caret"  src="assets/icons/caret/{{getAccordionItemIcon(chatMessage.message.id!)}}.svg">
        </div>
        <div *ngIf="getAccordionItemVisibility(chatMessage.message.id!)">
          <div class="agent-source" *ngIf="filteredSources(chatMessage.message?.sources, FILE_REFERENCE)?.length! > 0">
            <b class="agent-source-title">Ask My Docs file reference:</b>
            <div *ngFor="let source of filteredSources(chatMessage.message?.sources, FILE_REFERENCE);">
              <a class="agent-source-link" [href]="source.url" target="_blank">{{source.title}}{{showFilePage(source.url)}}</a>
            </div>
          </div>
          <div class="agent-source" *ngIf="filteredSources(chatMessage.message?.sources, REPOSITORY)?.length! > 0">
            <b class="agent-source-title">Ask Our Docs repository reference:</b>
            <div *ngFor="let source of filteredSources(chatMessage.message?.sources, REPOSITORY);">
              <a class="agent-source-link" [href]="source.url" target="_blank">{{decodeSourceTitle(source.title)}}</a>
            </div>
          </div>
          <div class="agent-source" *ngIf="filteredSources(chatMessage.message?.sources, WEB_SOURCE)?.length! > 0">
            <b class="agent-source-title">Web search sources:</b>
            <div *ngFor="let source of filteredSources(chatMessage.message?.sources, WEB_SOURCE);">
              <a class="agent-source-link" [href]="source.url" target="_blank">{{source.title}}</a>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-container>
  <div id="generating-response" *ngIf="generatingResponse" class="tr gray">
    <div class="td">
    </div>
    <div class="td">
      <div class="chat-item">
        <app-circle-with-initials [user]="'AskGene'"></app-circle-with-initials>
        <kmd-loading-wheel></kmd-loading-wheel>
          Generating response...
      </div>
    </div>
  </div>
</ng-container>

<copy-response #copyResponseComponent (responseSelectEvent)="responseSelected($event)" />

<app-modal-save-prompt id="modalSavePromptId" #modalSavePromptComponent [prompt]="selectedPrompt!"
  (promptSaveEvent)="promptSaved($event)" />
