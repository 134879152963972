<ng-container [ngSwitch]="type">
    <svg
      *ngSwitchCase="'dropdown'"
      id="dropdown-icon"
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        *ngIf="isOpen"
        id="caret-up"
        d="M10.0523 5.73926H1.61961C1.0357 5.73926 0.743288 5.01489 1.15617 4.59126L5.37253 0.26509C5.62848 0.00248432 6.04349 0.00248432 6.29943 0.26509L10.5158 4.59126C10.9287 5.01489 10.6363 5.73926 10.0523 5.73926Z"
        [attr.fill]="color"
      />
      <path
        *ngIf="!isOpen"
        id="caret-down"
        d="M1.61953 0.0375977L10.0523 0.0375977C10.6362 0.0375977 10.9286 0.761966 10.5157 1.1856L6.29934 5.51177C6.0434 5.77437 5.62839 5.77437 5.37245 5.51177L1.15608 1.1856C0.74317 0.761966 1.03562 0.0375977 1.61953 0.0375977Z"
        [attr.fill]="color"
      />
    </svg>
    <svg
      *ngSwitchCase="'sort'"
      id="sort-icon"
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="caret-up"
        d="M10.0523 5.73926H1.61961C1.0357 5.73926 0.743288 5.01489 1.15617 4.59126L5.37253 0.26509C5.62848 0.00248432 6.04349 0.00248432 6.29943 0.26509L10.5158 4.59126C10.9287 5.01489 10.6363 5.73926 10.0523 5.73926Z"
        [attr.fill]="color"
      />
      <path
        id="caret-down"
        d="M1.61953 6.0376L10.0523 6.0376C10.6362 6.0376 10.9286 6.76197 10.5157 7.1856L6.29934 11.5118C6.0434 11.7744 5.62839 11.7744 5.37245 11.5118L1.15608 7.1856C0.74317 6.76197 1.03562 6.0376 1.61953 6.0376Z"
        [attr.fill]="color"
        transform="translate(0, 2)"
      />
    </svg>
  </ng-container>
  