import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { featureFlags } from "@environments/environment";

@Directive({
  selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit {

  @Input() featureFlag!: string | string[];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('featureFlagElse') elseTemplateRef!: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
    if (this.isEnabled()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.elseTemplateRef) {
      this.viewContainer.createEmbeddedView(this.elseTemplateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  isEnabled() {
    let features = new Map(Object.entries(featureFlags))
    if ((typeof this.featureFlag) === 'string')
      return features.get(this.featureFlag.toString());

    return (this.featureFlag as string[]).every(flag => features.get(flag));
  }
}
