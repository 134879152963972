<div class="input-select-box">
  <div class="input-wrapper" [ngClass]="{'input-wrapper-focus': showDropdown}">
    <input kmdFormInput id="inputBoxId" type="text" [(ngModel)]="value" (input)="onInputChange()" [disabled]="readOnly"
           (focus)="showOptions()" (blur)="hideOptionsBlur()" (keydown)="onKeyDown($event)">
    <fa-icon *ngIf="!showDropdown" class="input-icon" [icon]="faChevronDown" (click)="focusInput()"></fa-icon>
    <fa-icon *ngIf="showDropdown" class="input-icon" [icon]="faChevronUp" (click)="hideOptions()"></fa-icon>
  </div>
  <ul *ngIf="showDropdown" [ngClass]="{'is-show-dropdown': showDropdown}">
    <li class="dropdown-li" *ngFor="let option of filteredOptions; let i = index" (click)="selectOption(option)"
      [ngClass]="{'li-selected': option.toLowerCase() === value.toLowerCase() || i === selectedOptionIndex}">{{
      option }}</li>
  </ul>
</div>
