import { Component, EventEmitter, HostListener, OnInit, Output } from "@angular/core";
import { WindowRef } from "@app/core/services/window/window-ref.service";
import { featureFlags } from "@app/environments/environment";
import { FileManagerService } from "@services/file-manager/file-manager.service";

@Component({
  selector: 'app-file-manager-action-controls',
  templateUrl: './file-manager-action-controls.component.html',
  styleUrls: ['./file-manager-action-controls.component.css']
})
export class FileManagerActionControlsComponent implements OnInit {

  @Output() onClose = new EventEmitter<void>();

  isMobileViewport: boolean;

  constructor(
    private fileManagerService: FileManagerService
  ) {
    this.isMobileViewport = false;
  };

  ngOnInit(): void {
    this.checkViewportSize();
  }

  get selectedFileCount() {
    return this.fileManagerService.preSelectedFiles$.value.length +
            this.fileManagerService.fixedFiles$.value.length;
  }

  get hideControls() {
    return this.fileManagerService.allFiles$.value.length === 0;
  }

  onCancel(): void {
    this.onClear();
    this.onClose.emit();
  }

  onAdd(): void {
    this.fileManagerService.confirmSelectedFiles();
    this.onClose.emit();
  }

  onClear(): void {
    if (featureFlags.imagePrompt) {
      this.fileManagerService.clearPreSelectedFiles();
    } else {
      this.fileManagerService.clearSelectedFiles();
    }
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(_: Event): void {
    this.checkViewportSize();
  }

  private get pageWidth(): number {
    return WindowRef.innerWidth;
  }

  private checkViewportSize(): void {
    this.isMobileViewport = this.pageWidth <= 768;
  }
}
