import { Component, ViewChild } from '@angular/core';
import { KmdPopoverComponent } from "gds-atom-components";

@Component({
  selector: 'app-user-context-menu',
  templateUrl: './user-context-menu.component.html',
  styleUrls: ['./user-context-menu.component.css']
})
export class UserContextMenuComponent {

  @ViewChild('contextMenuPopover') contextMenuPopover!: KmdPopoverComponent;

  constructor() {
  }

  showPopover(event: MouseEvent) {
    event.preventDefault();

    this.setPopoverPosition(event);

    this.contextMenuPopover.toggle(event, event.currentTarget);
  }

  setPopoverPosition(event: MouseEvent) {
    if (event.currentTarget instanceof HTMLElement) {
      const { scrollTop, scrollLeft } = this.getScrollTopAndLeftFromParents(event.currentTarget);
      this.contextMenuPopover.positionX = event.currentTarget.offsetLeft - scrollLeft + event.currentTarget.clientWidth / 2;
      this.contextMenuPopover.positionY = event.currentTarget.offsetTop - scrollTop + event.currentTarget.clientHeight / 2 + 10;
    }
  }

  getScrollTopAndLeftFromParents(element: HTMLElement): { scrollTop: number, scrollLeft: number } {
    let parentElement = element.parentElement;
    let scrollTop = 0;
    let scrollLeft = 0;
    let counter = 0;

    while (parentElement && counter < 10) {
      scrollTop = parentElement.scrollTop;
      scrollLeft = parentElement.scrollLeft;

      if (scrollTop != 0 || scrollLeft != 0) {
        break;
      }

      parentElement = parentElement.parentElement;
      counter++;
    }

    return { scrollTop, scrollLeft };
  }

  hidePopover(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPopover.hide();
  }

  redirect(selectedPage: string) {
    window.location.href = selectedPage;
  }

  openInnovationHub() {
    this.openExternalLink('http://geneaipilot.thermofisher.com/login2.cfm');
  }

  openRaiPolicy() {
    this.openExternalLink('https://thermofisher.sharepoint.com/:u:/r/sites/GenerativeAICenterofExcellence/SitePages/Responsible-AI-(RAI)-Framework.aspx');
  }

  submitFeedback() {
    this.openExternalLink('https://forms.office.com/Pages/ResponsePage.aspx?id=LXJ9toqqd0ehaevremo7Z9qQeGTbQTtBqZnILeRlahNURVlMSTRBMVBHQ1k4TUNTSjdUSEIzQjhXUy4u');
  }

  signOut() {
    window.location.href = '/sign-out';
  }

  private openExternalLink(url: string) {
    this.hidePopover(new MouseEvent('click'));
    window.open(url, '_blank');
  }
}
