import { AbstractControl, ValidationErrors } from "@angular/forms";

export class EmailFormatValidator {
    private static readonly VALID_EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    static validate(control: AbstractControl): null | ValidationErrors {
        const emails = control.value as string[];

        if (emails && Array.isArray(emails)) {
            const invalidEmails = emails.filter(email => !EmailFormatValidator.isValidEmail(email));

            if (invalidEmails.length > 0) {
                return { invalidEmails: invalidEmails };
            }
        }

        return null;
    };

    private static isValidEmail(email: string): boolean {
        return this.VALID_EMAIL_REGEX.test(email);
    };
};
