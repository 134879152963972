import { Component, Inject, ViewChild } from '@angular/core';
import { KmdKeyValueOption } from "@shared/models/kmd-key-value-option";
import { AskOurDocsService } from "@services/ask-our-docs/ask-our-docs.service";
import { FormControl, FormGroup } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  map,
  merge,
  mergeMap,
  Observable,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { Paged } from '@app/shared/models/paged.model';
import { KmdPaginationComponent } from "gds-atom-components";
import { GroupMembershipService } from "@services/group-membership/group-membership.service";
import { AlertService } from "@services/alert/alert.service";

@Component({
  selector: 'app-ask-our-docs-page',
  templateUrl: './ask-our-docs-page.component.html',
  styleUrls: ['./ask-our-docs-page.component.css']
})
export class AskOurDocsPageComponent {

  @ViewChild('pagination') pagination!: KmdPaginationComponent;

  repositories$: Observable<Paged<KmdKeyValueOption>>;

  isInfoLoading = true;
  hasRepositories = false;
  isUserRepoAdmin = false;

  filterByOptions = ["All repositories", "Repositories that I Admin", "Repos I Can Prompt Against"];
  selectedFilter = this.filterByOptions[0];
  selectedFilterSubject = new BehaviorSubject<string>(this.selectedFilter);

  repoOwnersDictionary: Array<{ key: string, owners: string, isAdmin: boolean }> = [];
  repoGroupsDictionary: Array<{ key: string, id: string }> = [];

  readonly buttonLabel = "Chat with Repository";
  readonly DEBOUNCE_TIME = 500;
  readonly RESULTS_PER_PAGE = 15;

  formGroup = new FormGroup({
    search: new FormControl('', {nonNullable: true}),
    page: new FormControl(0, {nonNullable: true})
  })

  constructor(
    private askOurDocsService: AskOurDocsService,
    private groupMembershipService: GroupMembershipService,
    private alertService: AlertService,
    @Inject('AOD_SUPER_ADMIN_ID') private aodSuperAdminId: string
  ) {
    const initialRepositories$ = this.askOurDocsService.pagedRoleRepositories('', 0, this.RESULTS_PER_PAGE, this.selectedFilter);

    const search$ = this.formGroup.controls.search.valueChanges.pipe(
      debounceTime(this.DEBOUNCE_TIME),
      switchMap(() => {
        this.pagination.selectPage(1)
        return EMPTY;
      })
    );

    const searchResults$ = this.formGroup.controls.page.valueChanges.pipe(
      switchMap((partial) => {
        return askOurDocsService.pagedRoleRepositories(this.formGroup.controls.search.value, partial, this.RESULTS_PER_PAGE, this.selectedFilter);
      })
    );

    const filterResults$ = this.selectedFilterSubject.pipe(
      switchMap((filter) => {
        return askOurDocsService.pagedRoleRepositories(this.formGroup.controls.search.value, 0, this.RESULTS_PER_PAGE, filter);
      }));

    this.repositories$ = merge(initialRepositories$, searchResults$, search$, filterResults$).pipe(
      tap((repositories) => this.setEachRepositoriesOwners(repositories)),
      catchError((err) => {
        this.alertService.showError("Error fetching repositories.");
        return throwError(() => err);
      })
    );
  }

  setEachRepositoriesOwners = (repositories: { content: any[] }) => {
    let resolvedOwnerCounter = 0;

    const repoContent = repositories.content;

    if (repoContent.length === 0) {
      this.isInfoLoading = false;
      this.hasRepositories = false;
      return;
    }

    repoContent.forEach((repository: { name: string, isAdmin: boolean }) => {
      if (repository.isAdmin && !this.isUserRepoAdmin) {
        this.isUserRepoAdmin = true
      }

      this.getRepoOwners(repository)
        .pipe(
          tap((owners: string) => {
            this.repoOwnersDictionary.push({
              key: repository.name,
              owners: owners,
              isAdmin: repository.isAdmin
            })
          }),
          finalize(() => {
            resolvedOwnerCounter++;
            if (resolvedOwnerCounter === repositories.content.length) {
              this.isInfoLoading = false;
              this.hasRepositories = true;
            }
          })
        )
        .subscribe();
    });
  };

  getRepoOwners(repository: any): Observable<string> {
    return this.groupMembershipService.getGroupByRepository(repository.name).pipe(
      filter(group => group && group.response.value),
      mergeMap(group => {
        let owners$: any;

        const repoInfo = group.response.value;

        if (repoInfo.length === 0) {
          owners$ = this.groupMembershipService.getGroupOwners(this.aodSuperAdminId)
          this.repoGroupsDictionary.push({key: repository.name, id: this.aodSuperAdminId});
        } else {
          const cleanedRepoName = repository.id.replace("dpe_geneai_index_", "").replace(/[.]/g, '');

          owners$ = repoInfo
            .map((group: any) => (group.displayName === cleanedRepoName) ? group.id:this.aodSuperAdminId)
            .map((groupId: string) => this.groupMembershipService.getGroupOwners(groupId));

          repoInfo
            .filter((group: any) => group.displayName === cleanedRepoName)
            .forEach((group: any) => this.repoGroupsDictionary.push({key: repository.name, id: group.id}));
        }

        return forkJoin(owners$);
      }),
      map((ownersArray: any) => {
        const allOwners = ownersArray.flatMap((owners: any) => owners.value);
        return Array.from(new Set(allOwners.map(
          (owner: { userPrincipalName: any }) => owner.userPrincipalName
        )))
          .sort((a: any, b: any) => a.toLowerCase().localeCompare(b.toLowerCase()))
          .join(", ");
      })
    );
  };


  getAODUrlPath(indexName: string) {
    return "/ask-our-docs/" + indexName;
  }

  onPageChange(event: any) {
    this.formGroup.patchValue(
      {
        page: event.currentPage - 1
      }
    )
  }

  onFilterDropdownChange = (newlySelectedFilter: string): void => {
    this.selectedFilter = newlySelectedFilter;
    this.selectedFilterSubject.next(newlySelectedFilter);
  };

  fetchOwnersByRepository = (repositoryName: string): string => {
    const cleanedRepoName = repositoryName.replace("dpe_geneai_index_", "");

    const item = this.repoOwnersDictionary.find(obj => obj.key === cleanedRepoName);

    return item ? item.owners:'';
  };

  fetchGroupByRepository = (repositoryName: string): string => {
    const cleanedRepoName = repositoryName.replace("dpe_geneai_index_", "");

    const normalizedRepoName = cleanedRepoName.replace(/_/g, ' ');

    const item = this.repoGroupsDictionary.find(obj => obj.key === normalizedRepoName);

    return item ? item.id:'';
  };
}
