<div class="save-prompt-detail-container">
    <div class="save-prompt-detail">
        <div class="header">
            <button class="back-button" (click)="closeSaveCurrentPromptModal()">
                <img src="../assets/icons/arrows/arrowLeft.svg" alt="Back">
            </button>
        </div>
        <div class="prompt-info">
            <div class="prompt-title-wrapper">
                <label for="prompt-title"><b>Title</b></label>
                <input id="prompt-title" type="text" [value]="promptTitle" [(ngModel)]="promptTitle">
            </div>
            <div class="prompt-description-wrapper">
                <label for="prompt-description"><b>Prompt</b></label>
                <textarea id="prompt-description" [value]="promptDescription"
                    [(ngModel)]="promptDescription"></textarea>
            </div>
        </div>
        <div class="controls">
            <button kmdInfoButton (click)="closeSaveCurrentPromptModal()">Cancel</button>
            <button kmdFeaturedButton (click)="savePrivatePrompt()">Save prompt</button>
        </div>
    </div>
</div>