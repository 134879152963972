import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Chat } from "@shared/models/chat.model";
import { Author, Message } from "@shared/models/message.model";
import { Prompt } from '@shared/models/prompt.model';
import { UserData } from "@shared/models/user-data.model";
import { ChatService } from "@services/chat/chat.service";
import { PromptListComponent } from "../promptlist/promptlist.component"
import { KmdModalService } from 'gds-atom-components';
import { PromptSettings } from "@shared/models/prompt-settings.model";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable, Subject, Subscription } from "rxjs";
import { ChatHistoryComponent } from "../chathistory/chathistory.component";
import { finalize, map, takeUntil } from "rxjs/operators";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { PromptService } from "@services/prompt/prompt.service";
import { PromptExample } from "@shared/models/prompt-example.model";
import { ExamplePromptComponent } from './example-prompt/example-prompt.component';
import { HttpErrorResponse } from "@angular/common/http";
import { ChatComponent } from '../chat/chat.component';
import { BannerModalComponent } from "@shared/components/banner-modal/banner-modal.component";
import { DrawerComponent } from '../drawer/drawer.component';
import { PromptInputComponent } from '@shared/components/prompt-input/prompt-input.component';
import { ChatHistory } from "@services/chathistory/chat-history";
import { featureFlags } from "@app/environments/environment";
import { Location } from "@angular/common";
import { UuidService } from "@services/uuid.service";

@Component({
  animations: [
    trigger('slideInOut', [
      state('in', style({width: '260px'})),
      state('out', style({width: '0px'})),
      transition('in <=> out', animate('300ms ease-in-out'))
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: 'ChatHistoryService', useClass: ChatHistory},
    {provide: 'chatHistoryType', useValue: 'ask_gene'}
  ],
  selector: 'app-ask-gene',
  styleUrls: ['./ask-gene.component.css'],
  templateUrl: './ask-gene.component.html'
})
export class AskGeneComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('chatWrapper') chatWrapper!: ElementRef;
  @ViewChild('regenerateButton', {read: ElementRef}) regenerateButton!: ElementRef;
  @ViewChild('modalPromptListComponent') modalPromptListComponent?: PromptListComponent;
  @ViewChild('appMenu') chatHistoryComponent!: ChatHistoryComponent;
  @ViewChild(DrawerComponent) drawerComponent!: DrawerComponent;
  @ViewChild('examplePromptComponent') examplePromptComponent?: ExamplePromptComponent;
  @ViewChild('bannerModalComponent') bannerModalComponent?: BannerModalComponent;
  @ViewChild('appChat') chatComponent?: ChatComponent;
  @ViewChild('promptInputComponent') promptInputComponent!: PromptInputComponent;

  userData$: Observable<UserData>;
  userName: string = '';
  randomExample$!: Observable<PromptExample>;
  chat?: Chat;
  chats$: Observable<Chat[]> = this.chatService.getChats("ask_gene");

  generatingResponse = false;
  streamingResponse = false;

  lastUserMessage?: Message
  lastSystemMessage?: Message

  chatHistoryState: string = 'out';
  showPromptSavedAlert = false;
  isLoading: boolean = false;
  promptSettings: PromptSettings = new PromptSettings(3, "PROFESSIONAL", false, "gpt-4-o");
  promptSavedText = 'Prompt Saved';
  showInvalidPrompt = false;
  invalidPromptText = 'Sorry, your prompt couldn\'t be processed. Please rephrase or shorten your prompt.<br>Make sure to review and comply with the Gene.AI Use Guidelines';
  activeView = "chat";
  showGeneralAlert = false;
  alertText = "We encountered an unexpected error. Please try again.";
  bannerEnabled = true;
  includeWebSearch = true;
  canApprove$: Observable<boolean>;
  promptExampleHeader = true;
  promptExamplesType = 'ASK_GENE';

  destroy$: Subject<boolean> = new Subject<boolean>();
  streamingSubscription$: Subscription | undefined;

  private chatMessagesContainer: any;

  constructor(
    private uuidService: UuidService,
    private chatService: ChatService,
    private promptService: PromptService,
    private kmdModalService: KmdModalService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public oidcSecurityService: OidcSecurityService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return new UserData(result.userData.name, result.userData.email)
      })
    )
    this.canApprove$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        return result.userData.roles.includes('prompt-request-approver')
      })
    );
    this.oidcSecurityService.userData$.subscribe((result) => {
      this.userName = result.userData.name;
    });
  }

  hasAPrompt(extras?: NavigationExtras): Prompt | undefined {
    const state = extras?.state as { prompt: Prompt };
    return state?.prompt
  }

  ngOnInit(): void {
    // TODO: Fix this, currently this is no longer needed as using prompt saved in new chat is not longer possible
    const navigation = this.router.getCurrentNavigation();
    let prompt = this.hasAPrompt(navigation?.extras)
    if (prompt) {
      this.promptInputComponent.setPrompt(prompt.title ? prompt.text : '');
      this.promptSettings = prompt.promptSettings;
      setTimeout(() => {
        this.promptInputComponent.resizeTextarea();
      });
    }

    this.route.params.subscribe(params => {
      this.randomExample$ = this.promptService.promptExamples(this.promptExamplesType).pipe(
        map(examples => {
          const randomIndex = Math.floor(Math.random() * examples.length);
          return examples[randomIndex]
        })
      )

      let id = params['id'];
      if (id != undefined) {
        this.cdRef.detectChanges();
        this.showChat();
        this.chat = new Chat("New chat", new Date())
        this.chat.id = id
        this.cdRef.detectChanges();
        this.chatService
          .getChatMessages(id)
          .pipe(
            finalize(() => this.scrollChatToBottom())
          )
          .subscribe((newMessages) => {

            this.chatComponent?.setMessages(newMessages);

              if (newMessages.length != 0)
                this.promptSettings = newMessages[newMessages.length - 1].promptSettings
              this.isLoading = false;
            }
            , (error) => {
              this.isLoading = false;
              let errors = [400, 401, 403, 404, 500, 503]
              if (errors.includes(error.status)) {
                this.router.navigate(['/error'], {state: {status: error.status}, replaceUrl: true})
              } else {
                this.router.navigate(['/error'], {state: {status: 500}, replaceUrl: true})
              }
            }
          )
      }
    });
  }

  ngAfterViewInit(): void {
    this.chatMessagesContainer = this.chatWrapper?.nativeElement;
    this.setRegenerateButtonPositionHandler();
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
      // For stop the streaming when navigate to another page
     if (featureFlags.streamingGPT && this.streamingSubscription$ != undefined){
       this.streamingSubscription$?.unsubscribe();
     }
  }

  setRegenerateButtonPositionHandler() {
    new ResizeObserver(elements =>
      elements
        .map(element => element.contentRect.height)
        .forEach(height => {
          this.relocateRegenerateButton(height);
          this.relocateStopGenerateButton();
        })
    ).observe(document.querySelector("#chat-container") as Element);
  }

  sendBlockingPrompt(inputPrompt: string) {
    if (inputPrompt.trim() == "" || this.isLoading) return;

    let promptValue = inputPrompt;
    this.promptInputComponent.setPrompt('');
    let author = new Author("USER")

    this.kmdModalService.open('loadingResponseModal');
    this.isLoading = true;

    let prompt = new Prompt(promptValue, this.promptSettings, this.lastSystemMessage?.id)
    let message = new Message(promptValue, author, new Date().toISOString(), Object.assign({}, this.promptSettings), this.lastSystemMessage?.id);

    if (this.chat?.id == undefined) {
      this.chatService.promptChat(prompt).subscribe({
        next: (chatAndPrompt) => {
          this.chat = chatAndPrompt.chat;
          this.chats$ = this.chatService.getChats("ask_gene");
          message.id = chatAndPrompt.prompt.id;
          this.chatComponent?.addMessage(message);
          this.chatComponent?.addMessage(chatAndPrompt.prompt.answer!);
          this.kmdModalService.close('loadingResponseModal');
          this.isLoading = false;
          this.router.navigate(['/ask-gene/' + this.chat?.id], {state: {prompt: chatAndPrompt.prompt}})
        }, error: (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.handlePromptError(err);
          this.promptInputComponent.setPrompt(promptValue);
        }
      });
    } else {
      this.chatService.prompt(this.chat.id, prompt).subscribe({
        next: (prompt) => {
          message.id = prompt.id
          message.parentId = prompt.parentId

          this.chatComponent?.addMessage(message);
          this.chatComponent?.addMessage(prompt.answer!);
          this.kmdModalService.close('loadingResponseModal');
          this.isLoading = false;
        }, error: (err: HttpErrorResponse) => {
          this.isLoading = false;
          this.handlePromptError(err);
          this.promptInputComponent.setPrompt(promptValue);
        }
      });
    }
  }

  get isSubmitDisabled() {
    return this.generatingResponse || this.streamingResponse;
  }

  sendStreamingPrompt(inputPrompt: string) {
    if (inputPrompt.trim() == "") return;

    let prompt = new Prompt(inputPrompt, this.promptSettings, this.lastSystemMessage?.id)
    let messageID = this.uuidService.random();
    prompt.id = messageID
    let message = new Message(inputPrompt, new Author("USER"), new Date(), Object.assign({}, this.promptSettings), this.lastSystemMessage?.id);
    message.id = messageID

    this.generatingResponse = true;
    this.scrollChatToBottom();
    this.relocateStopGenerateButton();

    const useStreaming = true;

    if (this.chat?.id == undefined) {
      this.chat = new Chat("New chat", new Date())
      this.chat.id = "unknown"

      this.cdRef.detectChanges()
      this.chatComponent?.addMessage(message);
      this.scrollChatToBottom();

      let isChatLoaded = false;

      this.streamingSubscription$ = this.chatService.promptChat(prompt, useStreaming)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.streamingResponse = false;
            this.generatingResponse = false;
            this.cdRef.detectChanges();
          }),
        )
        .subscribe({
          next: value => {
            this.generatingResponse = false;
            this.streamingResponse = true;

            if (!isChatLoaded) {
              const chatId = value.chat.id;
              this.chat!.id = chatId;
              this.location.replaceState(`/ask-gene/${chatId}`);
              isChatLoaded = true;
            }

            this.chatComponent?.addMessage(value.prompt.answer!)
            this.chats$ = this.chatService.getChats("ask_gene");
            this.scrollChatToBottom();
          },
          error: () => {
            this.showGeneralAlert = true;
          },
          complete: () => {
            this.cdRef.detectChanges()
          }
        });
    } else {
      this.chatComponent?.addMessage(message);
      this.scrollChatToBottom();

      this.streamingSubscription$ = this.chatService.prompt(this.chat?.id, prompt, useStreaming)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.streamingResponse = false;
            this.generatingResponse = false;
            this.cdRef.detectChanges();
          })
        )
        .subscribe({
          next: value => {
            this.generatingResponse = false;
            this.streamingResponse = true;
            this.chatComponent?.addMessage(value.answer!);
            this.scrollChatToBottom();
          },
          error: () => {
            this.showGeneralAlert = true;
          }
        });
    }
  }


  onSubmit(inputPrompt: string) {
    if (this.featureFlags.streamingGPT) {
      this.sendStreamingPrompt(inputPrompt);
    } else {
      this.sendBlockingPrompt(inputPrompt);
    }
  }

  handlePromptError(error: HttpErrorResponse) {
    this.kmdModalService.close('loadingResponseModal');
    this.showGeneralAlert = error.status == 500;
    this.showInvalidPrompt = error.status == 400;
  }

  setExampleCurrentPrompt(example: PromptExample) {
    this.promptInputComponent.setPromptAndFocus(example.text);

    // TODO: Fix this with ngZone or find a better way to do it
    // Find ngZone mocking solution
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.promptInputComponent.resizeTextarea();
      });
    });
  }

  moveToAskGene() {
    window.location.href = '/ask-gene';
  }

  handlePromptSaved() {
    this.showPromptSavedAlert = true
  }

  showPromptList(): void {
    this.activeView = "prompt-list"
  }

  showChat(event?: any) {
    event?.preventDefault()
    this.activeView = "chat"
  }

  isChatViewInactive(): boolean {
    return this.activeView != "chat";
  }

  promptSelected(prompt: Prompt) {
    this.promptInputComponent.setPrompt(prompt.text);
    this.promptSettings = prompt.promptSettings;
    this.activeView = "chat"
  }

  handleResponseSelected(responseText: string) {
    this.promptInputComponent.setAppendPrompt(responseText);
  }

  scrollChatToBottom() {
    this.chatMessagesContainer?.scroll({
      top: this.chatMessagesContainer.scrollHeight,
      left: 0,
      behavior: 'auto'
    });
    this.promptInputComponent.resizeTextarea();
    this.cdRef.detectChanges();
  };


  relocateRegenerateButton(promptInputHeight?: number) {
    if (this.regenerateButton == undefined) return;

    this.regenerateButton.nativeElement.style.bottom = (promptInputHeight! + 25) + 'px';
    this.cdRef.detectChanges();
  }

  relocateStopGenerateButton() {
    const inputTextarea = document.querySelector("#chat-container") as Element
    const stopButton = document.querySelector("#stopGenerateButton") as Element
    if (stopButton == undefined) return;
    stopButton.setAttribute('style', `bottom: ${inputTextarea.scrollHeight + 25}px`)
    this.cdRef.detectChanges();
  }

  toggleChatHistoryState() {
    this.chatHistoryState = this.chatHistoryState === 'in' ? 'out' : 'in';
  }

  isChatHistoryVisible() {
    return this.chatHistoryState == "in"
  }

  toggleByDirective() {
    if (this.isChatHistoryVisible()) {
      this.toggleChatHistoryState();
    }
  }

  displayRegenerateButton() {
    return this.lastUserMessage != undefined &&  !this.streamingResponse && !this.generatingResponse;
  }

  displayStopGenerateButton() {
    return this.streamingResponse;
  }

  stopGenerateResponse() {
    this.promptInputComponent.resizeTextarea();
    this.streamingSubscription$?.unsubscribe();
    this.destroy$.next(true);
    this.showGeneralAlert = false;
  }

  regenerateResponse() {
    let message = this.lastUserMessage!
    let prompt = new Prompt(message.text, this.promptSettings, this.lastUserMessage?.id)
    prompt.id = message.id

    if (featureFlags.streamingRegenerated) {
      this.regenerateResponseStreaming(prompt);
    } else {
      this.regenerateResponseBlocking(prompt);
    }
  }

  private regenerateResponseBlocking(prompt: Prompt) {
    this.generatingResponse = true;

    this.kmdModalService.open("loadingResponseModal")
    this.chatService.regenerateResponse(this.chat?.id!, prompt).subscribe({
      next: (prompt) => {
        this.chatComponent?.addMessage(prompt.answer!)
        this.kmdModalService.close("loadingResponseModal")
        this.generatingResponse = false;
      },
      error: () => {
        this.kmdModalService.close("loadingResponseModal")
        this.showGeneralAlert = true
        this.generatingResponse = false;
      }});
  }

  private regenerateResponseStreaming(prompt: Prompt) {
    this.generatingResponse = true;
    this.streamingResponse = true;

    this.chatService.regenerateResponse(this.chat?.id!, prompt, true)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.streamingResponse = false;
          this.generatingResponse = false;
          this.cdRef.detectChanges();
        }),
      ).subscribe({
        next: prompt => {
          this.generatingResponse = false;
          this.chatComponent?.addMessage(prompt.answer!)
        },
        error: () => {
          this.showGeneralAlert = true;
        },
        complete: () => {
          this.streamingResponse = false;
          this.generatingResponse = false;
        }
      })
  }

  openDrawer(): void {
    this.drawerComponent.openDrawer();
  }

  closeDrawer(): void {
    this.drawerComponent.dismissDrawer();
  }

  selectedChat(chat: Chat) {
    this.router.navigate(['/ask-gene/' + chat.id]);
  }

  deletedChat() {
    this.router.navigate(['/ask-gene']);
  }

  protected readonly featureFlags = featureFlags;
}
