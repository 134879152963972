import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable } from 'rxjs';
import { environment } from '@app/environments/environment';

interface FileItem {
    app: string;
    value: string;
}

@Injectable({
  providedIn: 'root'
})
export class AskOurDocsV2Service {
  bearerToken : string = '';
  idToken : string = '';
  jsonIdTokenHeader : { [key: string]: any } = {};
  jsonBearerTokenHeader : { [key: string]: any } = {};

  private apiUrl = environment.askOurDocsIframeUrlV2;
  
  constructor(private http: HttpClient, protected oidcSecurityService: OidcSecurityService) {
    this.getLatestAccessToken();
    this.getLatestIdToken();
  }

  getLatestAccessToken() {
    this.oidcSecurityService.getAccessToken().pipe(
      map((idToken) => {
        this.bearerToken = idToken;
        this.jsonBearerTokenHeader = {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.bearerToken}`
        }
        return true;
      }))
    .subscribe();
  }

  getLatestIdToken() {
    this.oidcSecurityService.getIdToken().pipe(
      map((idToken) => {
        this.idToken = idToken;
        this.jsonIdTokenHeader = {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${this.idToken}`
        }
        return true;
      }))
    .subscribe();
  }

  // Get Details API
  async getDetails (indexName: string): Promise<any> {
    return this.http.post(environment.askOurDocsIframeUrlV2 + '/api/get_details',
      {index_name : indexName},
      {
        headers: this.jsonBearerTokenHeader
      }).toPromise();
  }

  async conversationApi(conversationApiPayload: any): Promise<Response> {
    await this.getLatestAccessToken();
    let abortController = new AbortController();
    return fetch(environment.askOurDocsIframeUrlV2 + "/conversation", {
      method: "POST",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify(conversationApiPayload),
      signal: abortController.signal
    });
  }

  async saveToDB(data: any): Promise<Response> {
    await this.getLatestAccessToken();
    const response = await fetch(environment.askOurDocsIframeUrlV2 + "/api/save_to_db", {
      method: "POST",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify({
        data: data
      }),
    });
    return response;
  }

  async getSasToken(indexName: string): Promise<{sas_token: string, base_url: string}> {
    await this.getLatestAccessToken();
    const response = await fetch(environment.askOurDocsIframeUrlV2 + "/api/get_sas_token", {
      method: "POST",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify({ index_name: indexName })
    });
    const data = await response.json();
    return { sas_token: data.sas_token, base_url: data.base_url };
  }

  getChatHistory(indexName: string, offset? : number, limit? : number, order? : string): Promise<any> {
    const params = new HttpParams()
      .set('indexName', indexName)
      .set('offset', offset?.toString() || '0')
      .set('limit', limit?.toString() || '10000')
      .set('order', order?.toString() || 'DESC');
    return this.http.get(`${environment.askOurDocsIframeUrlV2}/history/list`, {
      params,
      headers: this.jsonBearerTokenHeader
    }).toPromise();
  }

  renameConversation(conversationId: string, newTitle: string): Promise<any> {
    const payload = {
      conversation_id: conversationId,
      new_title: newTitle
    };
    return this.http.post(`${environment.askOurDocsIframeUrlV2}/history/rename`, payload, {
      headers: this.jsonBearerTokenHeader
    }).toPromise();
  }

  deleteConversation(conversationId: string): Promise<any> {
    return this.http.delete(`${environment.askOurDocsIframeUrlV2}/history/delete`, {
      params: {
        conversation_id: conversationId
      },
      headers: {
        'Authorization': `Bearer ${this.bearerToken}`
      }
    }).toPromise();
  }

  openChat(conversationId : string){
    return this.http.get(`${environment.askOurDocsIframeUrlV2}/history/get`, {
      params:{
        conversationId: conversationId
      },
      headers: {
        'Authorization': `Bearer ${this.bearerToken}`
      }
    }).toPromise();
  }

  async getLastRunDate(indexer_name:string): Promise<{last_run_date: string}> {
    await this.getLatestAccessToken();
    const response = await fetch(environment.askOurDocsIframeUrlV2 + "/api/get_last_run_date", {
      method: "POST",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify({ indexer_name: indexer_name })
    });
    const data = await response.json();
    return data;
  }

  getFeedbackFormData(indexName: string): Observable<any> {
    const headers = new HttpHeaders(this.jsonBearerTokenHeader);
    return this.http.get(environment.askOurDocsIframeUrlV2 + `/api/get-feedback-form/${indexName}`, { headers }).pipe(
      map(response => {
        return response;
      })
    );
  }

  async saveFeedback(data: any, messageId: string): Promise<any> {
    const url = environment.askOurDocsIframeUrlV2 + `/api/feedback/${messageId}`;
    const headers = new HttpHeaders(this.jsonBearerTokenHeader);

    return this.http.post(url, { ...data }, { headers }).toPromise();
  }

  async saveCustomizableFeedbackForm(data: any): Promise<any> {
    const url = environment.askOurDocsIframeUrlV2 + `/api/save-admin-feedback-form`;
    const headers = new HttpHeaders(this.jsonBearerTokenHeader);

    return this.http.post(url,data, { headers }).toPromise();
  }

  getDefaultFeedbackForm(): Observable<any> {
    const headers = new HttpHeaders(this.jsonBearerTokenHeader);
    return this.http.get(environment.askOurDocsIframeUrlV2 + `/api/get-default-feedback-form`, { headers }).pipe(
      map(response => {
        return response;
      })
    );
  }

  // ConfigurationService methods
  getFiles(): Observable<FileItem[]> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.idToken}`
    });
    return this.http.get<FileItem[]>(environment.askOurDocsIframeUrlV2 +`/api/list-files`, { headers });
  }

  getFileContent(fileName: string): Observable<{ [key: string]: any }> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.idToken}`
    });
    return this.http.get<{ [key: string]: any }>(environment.askOurDocsIframeUrlV2 +`/api/get-file-content/${fileName}`, { headers });
  }

  saveFileContent(fileName: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.idToken}`
    });
    const url = environment.askOurDocsIframeUrlV2 +`/api/save-file-content/${fileName}`;
    return this.http.post(url, data, { headers });
  }

  async savePrompt(id: string, indexName: string, category: string, title: string, description: string): Promise<{message: string}> {
    await this.getLatestAccessToken();
    const response = await fetch(environment.askOurDocsIframeUrlV2 + "/api/save_prompt", {
      method: "POST",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify({
        promptId: id,
        indexName: indexName,
        category: category,
        promptTitle: title,
        promptDescription: description
      })
  });
  const data = await response.json();
        return { message: data.message };
  }

  async getSavedPromptsPrivate(indexName: string): Promise<{savedPrompts: any}> {
    await this.getLatestAccessToken();
    const response = await fetch(environment.askOurDocsIframeUrlV2 + "/api/fetch_prompts", {
      method: "POST",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify({ indexName: indexName })
  });
  const data = await response.json();
        return { savedPrompts: data.prompts };
  }

  async getSavedPromptsPublic(indexName: string): Promise<{savedPrompts: any}> {
    await this.getLatestAccessToken();
    const response = await fetch(environment.askOurDocsIframeUrlV2 + "/api/get-public-prompts", {
      method: "POST",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify({ indexName: indexName })
  });
  const data = await response.json();
        return { savedPrompts: data.tile_prompts };
  }

  async updatePrivatePrompt(indexName: string, prompt: any): Promise<{message: any}> {
    await this.getLatestAccessToken();
    const response = await fetch(environment.askOurDocsIframeUrlV2 + "/api/update_prompt", {
      method: "PUT",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify({
        indexName: indexName,
        promptId: prompt.id,
        promptTitle: prompt.promptTitle,
        promptDescription: prompt.promptDescription,
      })
  });
  const data = await response.json();
        return { message: data.message };
  }

  async deletePrivatePrompt(indexName: string, prompt: any): Promise<{message: any}> {
    await this.getLatestAccessToken();
    const response = await fetch(environment.askOurDocsIframeUrlV2 + "/api/delete_prompt", {
      method: "DELETE",
      headers: this.jsonBearerTokenHeader,
      body: JSON.stringify({
        indexName: indexName,
        promptId: prompt.id
      })
  });
  const data = await response.json();
        return { message: data.message };
  }
}

export { FileItem };