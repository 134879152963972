import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Router } from "@angular/router";

@Component({
  selector: 'app-sign-out',
  template: ''
})
export class SignOutComponent implements OnInit {
  constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {
  }

  ngOnInit() {
    this.oidcSecurityService.logoffLocal();
    this.router.navigate(['/signin']);
  }
}
