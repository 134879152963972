import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-warning-icon',
    templateUrl: './warning-icon.component.html',
    styleUrls: ['./warning-icon.component.css']
})
export class WarningIconComponent {
    @Input() width!: string;
    @Input() height!: string;
    @Input() color!: string;
};
