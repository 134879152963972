import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { ChatHistoryUpdateService } from "@app/core/chat-history-update/chat-history-update.service";
import { FileUpload } from '@app/shared/models/file-upload.model';
import { PagedAgents } from '@app/shared/models/paged-agents.model';
import { AgentBuilderRequest } from "@shared/models/agent-builder-request";
import { AgentChat } from "@shared/models/agent-chat";
import { AgentChatPrompt } from "@shared/models/agent-chat-prompt.model";
import { AgentShareRequest } from '@shared/models/agent-share-request.model';
import { Chat } from "@shared/models/chat.model";
import { MessageAndAgent } from "@shared/models/message-and-agent";
import { Prompt } from "@shared/models/prompt.model";
import { Observable, tap, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from "rxjs/operators";
import { Agent } from 'src/app/shared/models/agent';
import { Message } from 'src/app/shared/models/message.model';
import { StreamingService } from '../streaming/streaming.service';
import { AgentPublishError, AgentSaveError, AgentShareError, AgentUpdateError } from "./agent-errors";

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  constructor(
    @Inject('BASE_API_URL') private baseUrl: string,
    private http: HttpClient,
    private streamingService: StreamingService,
    private chatHistoryUpdateService: ChatHistoryUpdateService
  ) { }

  save(agent: Agent): Observable<Agent> {
    return this.http.post<Agent>(`${ this.baseUrl }/v1/agents`, agent, this.getHeaders());
  };

  update(agent: Agent): Observable<Agent> {
    return this.http.put<Agent>(`${ this.baseUrl }/v1/agents/${ agent.id }`, agent, this.getHeaders());
  };

  updatePublicAgent(agent: Agent): Observable<Agent> {
    return this.http.put<Agent>(`${ this.baseUrl }/public/v1/agents/${ agent.id }`, agent, this.getHeaders());
  };

  agents(): Observable<Agent[]> {
    return this.http.get<Agent[]>(`${ this.baseUrl }/v1/agents`, this.getHeaders());
  }

  publicAgents(): Observable<Agent[]> {
    return this.http.get<Agent[]>(`${ this.baseUrl }/public/v1/agents`, this.getHeaders());
  }

  createChatWithPublicAgent(id: string, prompt: Prompt): Observable<AgentChat> {
    return this.http.post<AgentChat>(`${ this.baseUrl }/public/v1/agents/${ id }/prompt`, prompt, this.getHeaders());
  }

  promptPublicAgent(agentID: string, chatID: string, prompt: Prompt): Observable<Prompt> {
    return this.http.post<Prompt>(`${ this.baseUrl }/public/v1/agents/${ agentID }/chat/${ chatID }/prompt`, prompt, this.getHeaders());
  }

  likeAgent(agentID: string): Observable<Agent> {
    return this.http.post<Agent>(`${ this.baseUrl }/public/v1/agents/${ agentID }/like`, {}, this.getHeaders());
  };

  unlikeAgent(agentID: string): Observable<Agent> {
    return this.http.post<Agent>(`${ this.baseUrl }/public/v1/agents/${ agentID }/unlike`, {}, this.getHeaders());
  };

  publishAgent(id: string): Observable<Agent> {
    return this.http.post<Agent>(`${ this.baseUrl }/v1/agents/${ id }/publish`, {}, this.getHeaders());
  }

  recommendAgent(id: string): Observable<Agent> {
    return this.http.post<Agent>(`${ this.baseUrl }/public/v1/agents/${ id }/recommend`, {}, this.getHeaders());
  }

  deleteAgentRecommendation(id: string): Observable<Agent> {
    return this.http.delete<Agent>(`${ this.baseUrl }/public/v1/agents/${ id }/recommend`, this.getHeaders());
  }

  agent(agentId: string): Observable<Agent> {
    return this.http.get<Agent>(`${ this.baseUrl }/v1/agents/${ agentId }`, this.getHeaders());
  }

  deletePublicAgent(agentId: string): Observable<void> {
    return this.http.delete<void>(`${ this.baseUrl }/public/v1/agents/${ agentId }`, this.getHeaders());
  }

  prompt(agent: Agent, message: Message): Observable<AgentChat> {
    return this.http.post<AgentChat>(`${ this.baseUrl }/v1/agents/${ agent.id }/prompt`, message, this.getHeaders());
  }

  promptToChat(agent: Agent, chat: Chat, message: Message): Observable<Prompt> {
    return this.http.post<Prompt>(`${ this.baseUrl }/v1/agents/${ agent.id }/chat/${ chat.id }/prompt`, message, this.getHeaders());
  }

  promptToChatV2(agent: Agent, chat: Chat, message: Message): Observable<Prompt> {
    const props = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(message),
    };

    return this.streamingService.fetch(`${this.baseUrl}/v2/agents/${ agent.id }/chat/${ chat.id }/prompt`, props, Prompt);
  }

  delete(agentId: string): Observable<void> {
    return this.http.delete<void>(`${ this.baseUrl }/v1/agents/${ agentId }`, this.getHeaders());
  }

  promptBuilder(message: Message, chatContext: Message[]): Observable<MessageAndAgent> {
    const request: AgentBuilderRequest = {
      message: message,
      chatContext: chatContext
    }
    return this.http.post<MessageAndAgent>(`${ this.baseUrl }/v1/agents/builder/prompt`, request, this.getHeaders());
  }

  getAgentsLinkedToFile(file: FileUpload): Observable<Array<Agent>> {
    return this.http.get<Array<Agent>>(`${this.baseUrl}/v1/agents?fileId=${file.id}`, this.getHeaders());
  }

  getPagedAgents(page: number, itemsPerPage: number, category?: string, sort?: string, sortDirection?: string, filterString?: string, type?: string): Observable<PagedAgents> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('itemsPerPage', itemsPerPage.toString());

    if(category){ params = params.append('category', category); }
    if(sort){ params = params.append('sort', sort); }
    if(sortDirection){ params = params.append('sortDirection', sortDirection); }
    if(filterString){ params = params.append('filterString', filterString); }
    if (type) { params = params.append('type', type); }

    const url = `${ this.baseUrl }/v1/agents/page?`;
    return this.http.get<PagedAgents>(url, { params: params });
  }

  getPagedPublicAgents(page: number, itemsPerPage: number, category?: string, sort?: string, sortDirection?: string, filterString?: string): Observable<PagedAgents> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('itemsPerPage', itemsPerPage.toString());

    if(category){ params = params.append('category', category); }
    if(sort){ params = params.append('sort', sort); }
    if(sortDirection){ params = params.append('sortDirection', sortDirection); }
    if(filterString){ params = params.append('filterString', filterString); }

    const url = `${ this.baseUrl }/public/v1/agents/page?`;
    return this.http.get<PagedAgents>(url, { params: params });
  }

  deleteByUser(agentId: string): Observable<void> {
    return this.http.delete<void>(`${ this.baseUrl }/v1/agents/${ agentId }/publish`, this.getHeaders());
  }

  promptV2(agent: Agent, message: Message): Observable<AgentChatPrompt> {
    const props = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(message),
    };
    let chatId: string;

    return this.streamingService.fetch(`${this.baseUrl}/v2/agents/${ agent.id }/prompt`, props, AgentChatPrompt)
      .pipe(
        tap((agentChatPrompt: AgentChatPrompt) => {
          if(!chatId && !!agentChatPrompt.agentChat.chat.id)
            chatId = agentChatPrompt.agentChat.chat.id;
        }),
        finalize(()=> this.chatHistoryUpdateService.notifyChatUpdated(chatId))
      );
  }

  share(request: AgentShareRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/v1/agents/${request.agentId}/share`, request, this.getHeaders());
  };

  unshare(agentId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/v1/agents/${agentId}/unshare`, {}, this.getHeaders());
  };

  saveAndShare(agent: Agent, azureGroupEmail: string, adminEmails: string[]): Observable<any> {
    return this.save(agent)
      .pipe(
        catchError((error) => throwError(() => new AgentSaveError(error))),
        switchMap((agent: Agent) => {
          const shareRequest: AgentShareRequest = {
            agentId: agent.id!,
            azureGroupEmail: azureGroupEmail,
            adminEmails: adminEmails
          };

          return this.share(shareRequest).pipe(
            catchError((error) => throwError(() => new AgentShareError(error, agent)))
          );
        }
      )
    );
  };

  saveAndPublish(agent: Agent): Observable<Agent> {
    return this.save(agent)
      .pipe(
        catchError((error) => throwError(() => new AgentSaveError(error))),
        switchMap((agent: Agent) => this.publishAgent(agent.id!)
          .pipe(
            catchError((error) => throwError(() => new AgentPublishError(error, agent)))
          )
        )
      );
  };

  updateAndShare(agent: Agent, azureGroupEmail: string, adminEmails: string[]): Observable<any> {
    return this.update(agent)
      .pipe(
        catchError((error) => throwError(() => new AgentUpdateError(error))),
        switchMap((agent: Agent) => {
          const shareRequest: AgentShareRequest = {
            agentId: agent.id!,
            azureGroupEmail: azureGroupEmail,
            adminEmails: adminEmails
          };

          return this.share(shareRequest).pipe(
            catchError((error) => throwError(() => new AgentShareError(error, agent))
          ));
        })
      );
  };

  updateAndPublish(agent: Agent): Observable<Agent> {
    return this.update(agent)
      .pipe(
        catchError((error) => throwError(() => new AgentUpdateError(error))),
        switchMap((agent: Agent) => this.publishAgent(agent.id!).pipe(
          catchError((error) => throwError(() => new AgentPublishError(error, agent)))
        ))
      );
  };

  updateAndUnshare(agent: Agent): Observable<any> {
    return this.update(agent)
      .pipe(
        catchError((error) => throwError(() => new AgentUpdateError(error))),
        switchMap((agent: Agent) => this.unshare(agent.id!).pipe(
          catchError((error) => throwError(() => new AgentShareError(error, agent))
        ))
      ));
  };

  files(agentId: string): Observable<FileUpload[]> {
    return this.http.get<FileUpload[]>(`${ this.baseUrl }/v1/agents/${ agentId }/files`, this.getHeaders());
  };

  private getHeaders() {
    return {
      headers: {
        'Content-type': 'application/json'
      }
    }
  }
}
