import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private readonly successesSubject$ = new Subject<string>();
  private readonly errorsSubject$ = new Subject<string>();

  constructor() { }


  successes() {
    return this.successesSubject$.asObservable();
  }

  errors() {
    return this.errorsSubject$.asObservable();
  }

  showSuccess(message: string) {
    this.successesSubject$.next(message);
  }

  showError(message: string) {
    this.errorsSubject$.next(message);
  }
}
