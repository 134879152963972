import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { featureFlags } from '@app/environments/environment';

import { ChipTypeConstant } from "@shared/constants/chip/chip-type";

@Component({
  selector: 'app-prompt-page',
  templateUrl: './prompt-page.component.html',
  styleUrls: ['./prompt-page.component.css']
})
export class PromptPageComponent implements OnInit, OnChanges{
  @Input() banner: boolean = true;
  @Input() showHeaderMessage: boolean = true;
  @Input() userName: string = '';
  @Input() repoDetails : any;
  @Input() isAODV2: boolean = false;
  @Output() exampleSelectEvent = new EventEmitter<any>();

  firstPart: string = '';
  highlightedPart: string = '';
  hyperLinkPart: string = '';
  lastPart: string = '';
  selectedChip: any = '';
  titleText : string = '';
  subtitle : string = '';
  subtitleFirstPart: string = '';
  subtitleHighlightedPart: string = '';
  subtitleHyperLinkPart: string = '';
  subtitleLastPart: string = '';  

  protected readonly featureFlags = featureFlags;
  protected readonly ChipTypeConstant = ChipTypeConstant;

  constructor(){}

  ngOnInit(): void {
    if (this.isAODV2 && this.repoDetails.randomize_suggestions && featureFlags.promptRandomization) {
      this.shuffleAndLimitTilePrompts();
    }
    if (this.isAODV2) {
      this.parseStringNew();
      this.parseString();
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['repoDetails'] &&
      this.repoDetails &&
      Object.keys(this.repoDetails).length > 0 &&
      this.isAODV2) {
        if (this.repoDetails.randomize_suggestions && featureFlags.promptRandomization) {
          this.shuffleAndLimitTilePrompts();
        }
        this.parseString();
        this.parseStringNew();
    }

    if(changes['repoDetails'] && this.repoDetails && this.repoDetails.subtitle) {
      this.subtitle = this.repoDetails.subtitle;
      this.parseStringNew();
    }

    if(changes['repoDetails'] && this.repoDetails && this.repoDetails.custom_banner_text) {
      this.titleText = this.repoDetails.custom_banner_text;
      this.parseString();
    }

    if(changes['repoDetails'] && this.repoDetails && this.repoDetails.prompt_suggestion_count) {
      this.repoDetails.tile_prompts = this.repoDetails.tile_prompts.slice(0, this.repoDetails.prompt_suggestion_count);
    }

    if(changes['userName']){
      this.userName = this.getUserName(this.userName);
    }
  }

  shuffleAndLimitTilePrompts() {
    if (this.repoDetails && this.repoDetails.tile_prompts) {
      this.repoDetails.tile_prompts = this.repoDetails.tile_prompts
        .sort(() => Math.random() - 0.5)
        .slice(0, this.repoDetails.prompt_suggestion_count);
    }
  }

  getUserName(name: string): string {
    return name ? ` ${name.split(' ')[1]},` : ',';
  }

  parseStringNew() {
    let inputString = this.repoDetails.subtitle;
    if (!inputString) return;
    const regex = /^(.*?)\[(.*?)\|(.*?)\](.*)$/;
    const matches = inputString.match(regex);
    if (matches) {
      this.subtitleFirstPart = matches[1];
      this.subtitleHighlightedPart = matches[2];
      this.subtitleHyperLinkPart = matches[3];
      this.subtitleLastPart = matches[4];
    } else {
      this.subtitleFirstPart = inputString;
      this.subtitleHighlightedPart = '';
      this.subtitleHyperLinkPart = '';
      this.subtitleLastPart = '';
    }
  }

  parseString() {
    let inputString = this.repoDetails.custom_banner_text;
    if (!inputString) return;
    
    const regex = /^(.*?)#%#(.*?)\|(.*?)#%#(.*)$/;
    const matches = inputString.match(regex);
    
    if (matches) {
      this.firstPart = matches[1];
      this.highlightedPart = matches[2];
      this.hyperLinkPart = matches[3];
      this.lastPart = matches[4];
    } else {
      this.firstPart = inputString;
      this.highlightedPart = '';
      this.hyperLinkPart = '';
      this.lastPart = '';
    }
  }

  getExamplePromptClass() {
    const bannerClass = this.banner ? 'with-banner' : 'with-no-banner';
    const revampClass = 'revamp-width';
    return `example-prompt example-prompt-${bannerClass} example-prompt-${revampClass}`;
  }

  exampleSelected(prompt: any) {
    this.selectedChip = prompt;
    if (this.repoDetails?.tile_prompts) {
    this.exampleSelectEvent.emit(prompt.longPrompt);
    } else {
      this.exampleSelectEvent.emit(prompt);
    }
    setTimeout(() => {
      this.selectedChip = null;
    }, 100);
  }
}
