import { Component } from '@angular/core';
import { isMobileResolution } from "@shared/constants/navbar/screen-resolutions";

@Component({
  selector: 'app-my-library',
  templateUrl: './my-library.component.html',
  styleUrls: ['./my-library.component.css']
})
export class MyLibraryComponent {
  protected readonly isMobileResolution = isMobileResolution;
}
