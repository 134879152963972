import { Inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { Translation } from 'src/app/shared/models/translation.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(@Inject('BASE_API_URL') private baseUrl: string, private http: HttpClient) {
  }

  translate(inputText: string, language: string): Observable<Translation> {
    return this.http.post<Translation>(this.baseUrl + '/v1/translations', { inputText, language },
      { headers: { 'Content-type': 'application/json' } });
  }
}
