<div class="chat-image">
  <object class="object-img-border img-border" [data]="message.imageUrl! | safe" type="image/png"
    (click)="showFullSizeImage()" (load)="showIconAndMessage()">
    <img src="/assets/images/404-not-found-or-expired.png" class="img-border" />
  </object>
  <span *ngIf="imagePresent && isImageExpired()" id="image-disclaimer-expired">The image you generated has expired. Any
    image generated via Gene.AI is only available for 24 hrs.</span>
  <span *ngIf="imagePresent && !isImageExpired()" id="image-disclaimer">Image generated in Gene.AI is available for 24
    hours only.</span>
  <a *ngIf="imagePresent && !isImageExpired()" id="image-download" class="image-download" (click)="downloadImage()">
    <i class="image-download-button"></i>
  </a>
</div>

<button class="view-revised-prompt-button" (click)="openModal(message.id!)">
  View revised prompt</button>

<kmd-modal class="modal-revised-prompt" id="modal-image-message-{{message.id}}" modalSize="small"
  headline="Revised prompt">
  <textarea class="revised-prompt-textarea" disabled rows="10">{{message.imageRevisedPrompt}}</textarea>

  <div class="modal-rp-buttons">
    <core-button class="cancel-revised-prompt-button" label="Cancel" type="button" variant="information" size="medium"
      (click)="closeModal(message.id!)">
    </core-button>
    <core-button class="copy-to-clipboard-button" label="Copy" type="button" variant="primary" size="medium"
      (click)="copyToClipboard(message.id!)">
    </core-button>
  </div>
</kmd-modal>

<div *ngIf="loading" class="loading-overlay">
  <div class="loading-text">Loading...</div>
  <kmd-loading-wheel></kmd-loading-wheel>
</div>