import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-external-link-icon',
    templateUrl: './external-link-icon.component.html',
    styleUrls: ['./external-link-icon.component.css']
})
export class ExternalLinkIconComponent {
    @Input() width!: string;
    @Input() height!: string;
    @Input() color!: string;
};
