import { Injectable } from '@angular/core';
import { Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatHistoryUpdateService {
  private chatUpdatedSource = new Subject<string | undefined>();

  updates() {
    return this.chatUpdatedSource.asObservable();
  }

  notifyChatUpdated(chatId: string | undefined) {
    this.chatUpdatedSource.next(chatId);
  }
}
