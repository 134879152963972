import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-close-icon',
    templateUrl: './close-icon.component.html',
    styleUrls: ['./close-icon.component.css']
})
export class CloseIconComponent {
    @Input() height: string = '24px';
    @Input() width: string = '24px';
    @Input() color: string = '#000';
};
