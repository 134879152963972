import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItem } from "@shared/models/context-menu-item";
import { slideUpDownAnimation } from "@shared/animations/slide-up-down.animation";

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu-dropdown.component.html',
  styleUrls: ['./context-menu-dropdown.component.css'],
  animations: [slideUpDownAnimation]
})
export class ContextMenuDropdownComponent implements OnInit {
  @Input() items: ContextMenuItem[] = [];
  @Input() defaultSelectedItem?: ContextMenuItem | null = null;
  @Input() slideDirection: 'up-to-down' | 'down-to-up' = 'up-to-down';
  @Output() itemClicked = new EventEmitter<ContextMenuItem>();
  @Output() isContextMenuOpen = new EventEmitter<boolean>();
  selectedItem: ContextMenuItem | null = null;
  isMenuOpen = false;

  ngOnInit(): void {
    if (this.defaultSelectedItem) {
      this.selectedItem = this.defaultSelectedItem;
    }
  }

  onItemClick(item: ContextMenuItem) {
    this.selectedItem = item;
    this.toggleMenu();
    this.itemClicked.emit(item);
  }

  getAnimationState() {
    if (this.slideDirection === 'up-to-down') {
      return this.isMenuOpen ? 'openUpToDown':'closedUpToDown';
    } else {
      return this.isMenuOpen ? 'openDownToUp':'closedDownToUp';
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.isContextMenuOpen.emit(this.isMenuOpen);
  }

  closeMenu() {
    this.isMenuOpen = false;
    this.isContextMenuOpen.emit(false);
  }
}
