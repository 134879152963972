 <div class="upload-files-container" *ngIf="uploadFiles.length">
    <div class="file-upload-list">
        <ng-container *ngFor="let data of uploadFiles">
            <div class="file-upload-summary" [ngClass]="getUploadFileStatus(data)">
                <div class="file-upload-details-wrapper">
                    <div class="file-upload-details">
                        <ng-container *ngIf="!isUploadStatusInProgress(data.status)">
                            <div [class]="getFileStatusClass(data.status!)">
                                <i></i>
                            </div>
                        </ng-container>
                        <div class="labels">
                            <p class="file-title">{{ data.file.name }}</p>
                            <p class="file-title mobile">{{ getMobileDisplayName(data.file.name) }}</p>
                        </div>
                        <div class="cancel upload-control mobile" (click)="showClearFileUploadModal(data)">
                            <i></i>
                        </div>
                    </div>
                    <ng-container *ngIf="isUploadProgressDisplayed(data)">
                        <div class="upload-progress">
                            <div class="progress-bar" [style.width]="data.progress + '%'"></div>
                        </div>
                    </ng-container>
                    <div class="file-upload-controls">
                        <div class="file-status uploading" *ngIf="data.status === uploadStatus.IN_PROGRESS">
                            <label>Uploading</label>
                        </div>
                      <div class="file-status error upload-error" *ngIf="data.status === uploadStatus.ERROR">
                          <label>Upload error</label>
                      </div>
                      <div class="file-status error file-size-exceeded" *ngIf="data.status === processingStatus.FILE_SIZE_EXCEEDED">
                        <label>File size exceeded</label>
                      </div>
                      <div class="file-status error language-error" *ngIf="data.status === processingStatus.LANGUAGE_ERROR">
                        <label>Language Error</label>
                      </div>
                      <div class="file-status error unsupported-language" *ngIf="data.status === processingStatus.UNSUPPORTED_LANGUAGE">
                        <label>Unsupported Language</label>
                      </div>
                      <div class="file-status error password-protected" *ngIf="data.status === uploadStatus.PASSWORD_PROTECTED">
                        <label>Password Protected</label>
                      </div>
                      <div class="cancel upload-control" (click)="showClearFileUploadModal(data)">
                          <i></i>
                      </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<kmd-modal [id]="'clear-file-upload-modal'" modalSize="mini" headline="Do you want to stop uploading?">
 <div id="text-confirm">
   <p>If you stop now, any uploaded files will be lost. </p>
 </div>
 <b>
   Do you want to proceed?
 </b>
 <div class="modal-buttons-container">
   <button id="stay-btn" (click)="closeClearFileUploadModal()" kmdInfoButton>No</button>
   <button id='switch-btn' (click)="removeFile()" kmdFeaturedButton>Yes</button>
 </div>
</kmd-modal>
