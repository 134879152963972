import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, map } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserData } from '@app/shared/models/user-data.model';
import { ChatAuthor, ChatMessage, SamplePrompt, ConversationChatRequestData } from '@shared/models/ask-our-data.model';
import { PromptSettings } from '@shared/models/prompt-settings.model';
import { PromptInputComponent } from '@app/shared/components/prompt-input/prompt-input.component';
import { AskOurDataService } from '@app/core/services/ask-our-data/ask-our-data.service';
import { ClipboardService } from "ngx-clipboard";
import { Messages } from '@app/shared/constants/ask-our-data-constants';
import { PlotlyService }  from 'src/app/core/services/ask-our-data/plotly.service';
import { featureFlags } from '@app/environments/environment';
import { AlertService } from '@app/core/services/alert/alert.service';
import { LZStringService } from '@app/core/services/ask-our-data/lz-string.service';
import { KmdModalService } from 'gds-atom-components';
import { ContextMenuComponent } from '@app/shared/components/context-menu/context-menu.component';
import { ContextMenuItem } from '@app/shared/components/context-menu/context-menu';
import { AodChatHistoryComponent } from '@app/ask-our-data/chat-with-db/chat-history/aod-chat-history.component';
import { isObject } from 'lodash';
import { AskOurDataUtils } from '@app/core/utils/ask-our-data-utils';

@Component({
  selector: 'app-chat-with-db',
  templateUrl: './chat-with-db.component.html',
  styleUrls: ['./chat-with-db.component.css']
})
export class ChatWithDbComponent implements OnInit, OnDestroy, AfterViewInit{
  @ViewChild('promptInputComponent') promptInputComponent!: PromptInputComponent;
  @ViewChild('ContextMenuComponent') modelDropdown!: ContextMenuComponent;
  @ViewChild('chatHistory') chatHistoryComponent!: AodChatHistoryComponent;
  userData$: Observable<UserData>;
  selectedChatId: string = '';
  createChartChatId: number | string = '';
  promptSettings: PromptSettings;
  isLoading: boolean = false;
  generatingResponse: boolean = false;
  receivedResponse = false;
  lastSystemMessage?: ChatMessage;
  dbname = '';
  dbId = '';
  ischatEmpty = true;
  isDbConnectErr = false;
  private subscriptions = new Subscription();
  generateSqlFailed = false;
  generatingChart: boolean[] = [];
  showLoader = false;

  messages: ChatMessage[] = [];
  visibleMessages: ChatMessage[] = [];
  showSavePrompt: boolean = false;
  showCopyPrompt: boolean = true;
  inputPrompt: string = '';
  generateSubscription: Subscription | undefined;
  runSqlSubscription: Subscription | undefined;
  sqlResponse = '';
  showErrorIcon: boolean[] = [];
  jsonResponse: any;
  resultObtained: boolean[] = [];
  protected readonly featureFlags = featureFlags;
  graphData: any[] = [];
  graphLayout: any[] = [];
  openGraph: boolean[] = [];
  chartGenerated: boolean[] = [];
  chartAPICalled: boolean[] = [];
  sessionId = AskOurDataUtils.generateSessionId();
  chatId = 1;
  chat_id: string | null = null;
  userScrolling = false;
  isConversationResponse: any = [];
  chatInteractions: any[] = [];
  oid = '';
  consent = false;
  consentAPIFailed = false;
  samplePrompts: SamplePrompt[] = [];
  chartType: any;
  chartTypes = [];
  xaxis: string = '';
  yaxis: string = '';
  headers: any[] = [];
  jsonResponses: any[] = [];
  selectedPromptValue = '';
  selectedSql = '';
  predictiveText: any[] = [];
  itemsList: ContextMenuItem[] = [];
  showModelDropdown: boolean = false;
  chatHistoryDataLimit = 20;
  userAuthor: ChatAuthor = new ChatAuthor("USER");
  systemAuthor: ChatAuthor = new ChatAuthor("SYSTEM");
  copyInterval: any[] = [];
  copyCount: any[] = [];
  savingHistory = false;
  chartSubscription: Subscription | undefined;
  saveInterval: any;
  sqlResultObtained: boolean[] = [];

  @HostListener('window:unload', [ '$event' ])
  unloadHandler() {
    AskOurDataUtils.removeLocalStorage();
  }


  constructor(private router: Router,
    private askOurDataService: AskOurDataService,
    public oidcSecurityService: OidcSecurityService,
    private cdRef: ChangeDetectorRef,
    private clipboard: ClipboardService,
    private alertService: AlertService,
    private plotly: PlotlyService,
    private lZStringService: LZStringService,
    private route: ActivatedRoute,
    private kmdModalService: KmdModalService) {
    this.userData$ = oidcSecurityService.userData$.pipe(
      map((result) => {
        this.oid = result.userData.oid;
        return new UserData(result.userData.name, result.userData.email)
      })
    );
    this.promptSettings = new PromptSettings(0, 'PROFESSIONAL', false, 'gpt-4-o');
  }

  ngOnInit(): void {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe(params => {
        this.dbId = params['id'];
        this.dbname = params['name'];
      });
    }
    this.itemsList = [
      {
        id: "0",
        label: "History",
        description: "See all chat history with the database",
        imageUrl: "../../../../assets/icons/clock/clock-24px-mono-new.svg"
      }
    ]
  }

  ngAfterViewInit(): void {
    this.showLoader = true; 
    this.askOurDataService.getNavigationStatus(this.oid, parseInt(this.dbId, 10)).subscribe({
      next: (response) => {
        this.showLoader = false;
        if(!response || !response.is_acknowledged) {
          this.kmdModalService.open('consentModal');
        }
      },
      error : (error) => {
        /* istanbul ignore else */
        if (error?.error?.error_message !== 'User navigation status not found') {
          this.consentAPIFailed = true;
        }
        this.showLoader = false;
        this.kmdModalService.open('consentModal');
      }
    }); 
  }

  change(type: string, chatId: number) {
    this.plotly.restyle('plot_child_chat_' + chatId, type);
  }

  async onSubmit(inputPrompt: string, regenerate: boolean = false) {
    this.chat_id = regenerate ? this.chat_id : null;
    this.saveChatHistory({}, inputPrompt, false, false);
    if (this.isLoading || inputPrompt.trim() === "") return;

    this.chatId++;
    this.resultObtained[this.chatId] = false;
    this.isConversationResponse[this.chatId] = false;
    this.showErrorIcon[this.chatId] = false;
    this.isDbConnectErr = false;
    this.inputPrompt = inputPrompt;
    this.receivedResponse = false;
    this.ischatEmpty = false;
    this.generatingResponse = true;
    this.generateSqlFailed = false;

    const promptValue = inputPrompt.trim();
    this.promptInputComponent.setPrompt('');
    const author = this.userAuthor;
    const message = new ChatMessage(promptValue, author, new Date().toISOString(), promptValue, this.lastSystemMessage?.id, this.chatId);
    message.id = 'chat_' + this.chatId;
    this.addMessage(message);
    this.isLoading = true;
    this.cdRef.detectChanges();
    this.scrollToChat('USER');

    const requestData: ConversationChatRequestData = {
      database_id: this.dbId,
      question: promptValue,
      session_id: this.sessionId,
      chat_history: this.chatInteractions.length > 0 ? this.chatInteractions : null,
      regenerate: featureFlags.chatHistoryRegenerateFix ? regenerate : undefined
    };

    this.generateSubscription = this.askOurDataService.callConversationChat(requestData).subscribe({
      next: (response: any) => this.handleChatResponse(response, promptValue),
      error: (error) => this.handleChatError(error, promptValue)
    });

    this.subscriptions.add(this.generateSubscription);
  }

  private handleChatResponse(response: any, promptValue: string) {
    this.chat_id = featureFlags.enableChatHistorySave ? this.chat_id : response?.chat_id;
    const isNotNullResponse = response?.response;

    if (isNotNullResponse) {
      const msg = response.response;
      const systemResponse = new ChatMessage(msg, this.systemAuthor, new Date().toISOString(), msg, response.id, this.chatId);
      systemResponse.id = 'child_chat_' + this.chatId;
      systemResponse.parentId = 'chat_' + this.chatId;

      if (response.tool_used === 'generate_sql') {
        let processHistory = response ? {...response} : {};
        
        if(featureFlags.enableChatHistorySave) {
          processHistory.message_type = 'SQL';
          processHistory.message_metadata = { token_usage: {run_sql: processHistory.message_metadata?.run_sql } };
          let processMetadataTokenUsage = response.message_metadata;
          if(processMetadataTokenUsage?.generate_sql) {
            processHistory.message_metadata.token_usage.generate_sql = processMetadataTokenUsage.generate_sql;
          }
          if(processMetadataTokenUsage?.conversational_chat) {
            processHistory.message_metadata.token_usage.conversational_chat = processMetadataTokenUsage.conversational_chat;
          }
          this.saveChatHistory(processHistory, promptValue);
        }
        this.runSql(msg, systemResponse, promptValue, response);
      } else if (response.tool_used === 'advance_analytics') {
        this.handlePredictiveResponse(response, promptValue, msg);
      } else {
        this.handleNonSqlResponse(response, promptValue, msg);
      }
    } else {
      this.handleEmptyResponse(response, promptValue);
    }
  }

  private handleNonSqlResponse(response: any, promptValue: string, msg: string) {
    let history = {...response};
    this.generatingResponse = false;
    this.receivedResponse = !featureFlags.enableChatHistorySave;
    this.isLoading = false;
    this.resultObtained[this.chatId] = true;
    this.isConversationResponse[this.chatId] = true;
    

    if (response.chat_history_summary) {
      this.chatInteractions = [];
      this.chatInteractions = [{
        role: "assistant",
        content: response.chat_history_summary,
        content_type: "chat_history_summary"
      }];
      history.chat_history_summary = response.chat_history_summary;
    } else {
      this.chatInteractions.push(
        { role: "user", content: promptValue, content_type: "Question" },
        { role: "assistant", content: response.response, content_type: "chat_response" }
      );
    }

    if(featureFlags.enableChatHistorySave) {
      history.summary = response?.response;
      history.message_type = 'Non-SQL';
      history.sql_generated = null;
      history.df_json = null;
      history.plotly_graph = null;
      if(history.message_metadata && history.message_metadata.token_usage) {
        history.message_metadata.token_usage.generate_sql = response?.message_metadata?.generate_sql;
      } else {
        history.message_metadata = { token_usage: { generate_sql: response?.message_metadata?.generate_sql } };
      }
    }
    const formattedResponse = `<div class="copyable-${this.chatId}">${response.response}</div>`;
    const finalSystemResponse = new ChatMessage(formattedResponse, this.systemAuthor, new Date().toISOString(), msg, '1', this.chatId);
    finalSystemResponse.id = 'child_chat_' + this.chatId;
    finalSystemResponse.parentId = 'chat_' + this.chatId;
    this.saveChatHistory(history, promptValue, true, true);
    this.addMessage(finalSystemResponse);
    this.cdRef.detectChanges();
    this.scrollToChat('SYSTEM');
  }

  private handlePredictiveResponse(response: any, promptValue: string, msg: string) {

    let history = {...response};

    if (response.chat_history_summary) {
      this.chatInteractions = [];
      this.chatInteractions = [{
        role: "assistant",
        content: response.chat_history_summary,
        content_type: "chat_history_summary"
      }];
      history.chat_history_summary = response.chat_history_summary;
    } else {
      this.chatInteractions.push(
        { role: "user", content: promptValue, content_type: "Question" },
        { role: "assistant", content: response.response, content_type: "chat_response" }
      );
    }
    if(history.message_metadata && history.message_metadata.token_usage) {
      history.message_metadata.token_usage.advance_analytics = response?.message_metadata?.advance_analytics;
    } else {
      history.message_metadata = { token_usage: { advance_analytics: response?.message_metadata?.advance_analytics } };
    }
    history.summary = response.response;
    history.message_type = 'Non-SQL';
    history.sql_generated = null;
    history.df_json = null;
    history.plotly_graph = null;
    this.saveChatHistory(history, promptValue);
    
    this.generatingResponse = false;
    this.receivedResponse = !featureFlags.enableChatHistorySave;
    this.isLoading = false;
    this.resultObtained[this.chatId] = true;
    this.isConversationResponse[this.chatId] = true;
    let predictiveText = JSON.parse(response?.response || "{}")?.result;

    const formattedResponse = `<div class="copyable-${this.chatId}">${predictiveText?.analysis_output || ''}</div>`;
    const finalSystemResponse = new ChatMessage(formattedResponse, this.systemAuthor, new Date().toISOString(), msg, '1', this.chatId);
    finalSystemResponse.id = 'child_chat_' + this.chatId;
    finalSystemResponse.parentId = 'chat_' + this.chatId;
    this.addMessage(finalSystemResponse);
    this.cdRef.detectChanges();
    this.scrollToChat('SYSTEM');
    this.getChart(predictiveText?.sql_query, predictiveText?.updated_dataframe, promptValue, this.chatId, history);
  }

  private handleEmptyResponse(response: any, promptValue: string) {
    let history = response? {...response} : {};

    if(featureFlags.enableChatHistorySave) {
      history.summary = null;
      history.message_type = 'Non-SQL'; // ERROR
      history.sql_generated = null;
      history.df_json = null;
      history.plotly_graph = null;
      if(history.message_metadata && history.message_metadata.token_usage) {
        history.message_metadata.token_usage.run_sql = response?.message_metadata?.run_sql;
      } else {
        history.message_metadata = { token_usage: { run_sql: response?.message_metadata?.run_sql } };
      }
      this.saveChatHistory(history, promptValue, true, false);
    }
    this.generatingResponse = false;
    this.receivedResponse = !featureFlags.enableChatHistorySave;
    this.isLoading = false;
    this.showErrorIcon[this.chatId] = true;
    const generateErr = Messages.GENERATE_ERROR;
    const systemResponse = new ChatMessage(generateErr, this.systemAuthor, new Date().toISOString(), '', '1', this.chatId);
    systemResponse.id = 'child_chat_' + this.chatId;
    systemResponse.parentId = 'chat_' + this.chatId;
    this.addMessage(systemResponse);
    this.cdRef.detectChanges();
    this.scrollToChat('SYSTEM');
  }

  private handleChatError(error: any, promptValue: string) {
    if (error.error?.error?.status_code === 204) {
      this.handleEmptyResponse(error, promptValue);
    } else {
      let history = error ? {...error.error} : {};
      history.summary = null;
      history.message_type = 'Error';
      history.sql_generated = null;
      history.df_json = null;
      history.plotly_graph = null;
      if (!history.message_metadata) {
        history.message_metadata = { error: { status_code: 504, error_message:'Gateway timed out' } };
      } 
      this.saveChatHistory(history, promptValue, true, false);
      this.handleErrorStatus(error);
    }
    this.receivedResponse = !featureFlags.enableChatHistorySave;
    this.cdRef.detectChanges();
    this.scrollToChat('SYSTEM');
  }

  private handleErrorStatus(error: any) {
    if (error.status === 503) {
      this.isDbConnectErr = true;
      this.generatingResponse = false;
      this.receivedResponse = false;
      this.isLoading = false;
    } else if (error.status === 400) {
      const errMsg = error.error?.error?.error_message;
      const generateErr = errMsg?.match(/^SQL injection\.*/g) ? Messages.NO_ACTION_ALLOWED : Messages.GENERATE_SQL_ERROR;
      this.showErrorIcon[this.chatId] = true;
      const systemResponse = new ChatMessage(generateErr, this.systemAuthor, new Date().toISOString(), '', 'parentId', this.chatId);
      systemResponse.id = 'child_chat_' + this.chatId;
      systemResponse.parentId = 'chat_' + this.chatId;
      this.addMessage(systemResponse);
      this.generateSqlFailed = true;
      this.generatingResponse = false;
      this.isLoading = false;
    } else{
      this.showErrorIcon[this.chatId] = true;
      const generateErr = Messages.INTERNAL_SERVER_ERROR;
      const systemResponse = new ChatMessage(generateErr, this.systemAuthor, new Date().toISOString(), '', 'parentId', this.chatId);
      systemResponse.id = 'child_chat_' + this.chatId;
      systemResponse.parentId = 'chat_' + this.chatId;
      this.addMessage(systemResponse);
      this.generateSqlFailed = true;
      this.generatingResponse = false;
      this.isLoading = false;
    }
  }

  copyToClipboard(text:  string | undefined, chatId: number, role: string, isText: boolean = true) {
    if(role !== 'USER' && !isText) {
      if(!this.copyCount[chatId] || this.copyCount[chatId] === 0) {
        this.copyResponse(chatId);
      }
    } else {
      if(text) {
        this.clipboard.copyFromContent(text);
        this.alertService.showSuccess(Messages.COPIED_TO_CLIPBOARD);
      }
    }
  }

  addMessage(message: ChatMessage) {
    let messageIndex = this.messages.findIndex(m => m.id === message.id);
    if (messageIndex === -1) {
      this.messages.push(message);
    } else {
      this.messages[messageIndex] = message;
    }
  }

  showChartContent(role: string, chatId: number) {
    let result = this.resultObtained[chatId] || this.generatingChart[chatId] || this.chartGenerated[chatId];
    return role !== 'USER' && (result || (!this.chartGenerated[chatId] && this.chartAPICalled[chatId]));
  }

  runSql(sql: string, systemResponse: ChatMessage, promptValue: string, processResponse: any): void {
    let chat_history = processResponse.chat_history_summary || '';
    let contextual_question = processResponse.contextual_question || '';
    this.isDbConnectErr = false;
    this.runSqlSubscription = this.askOurDataService.runQuery({
      id: this.dbId,
      sql: sql,
      question: promptValue,
      generate_summary: true,
      session_id: this.sessionId,
      chat_id: (this.chat_id || '').toString() || null
    }).subscribe({
      next: (response) => {
        this.chat_id = this.chat_id || response?.chat_id;
        let jsonResponse = JSON.parse(response?.df_json || "[]");
        if(response && jsonResponse.length > 0) {
          this.generatingResponse = false;

          this.resultObtained[this.chatId] = !featureFlags.enableChatHistorySave;
          this.sqlResultObtained[this.chatId] = true;
          this.chartGenerated[this.chatId] = false;
          this.chartAPICalled[this.chatId] = false;
          let history = {...response};

          if(chat_history) {

            this.chatInteractions = [];
            this.chatInteractions.push({
              "role": "assistant",
              "content": chat_history,
              "content_type": "chat_history_summary"
            });
            history.chat_history_summary = chat_history;

          } else {
            this.chatInteractions.push({
              "role": "user",
              "content": contextual_question || promptValue,
              "content_type": "Question"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": sql,
              "content_type": "sql"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": JSON.stringify(jsonResponse.slice(0, this.chatHistoryDataLimit)),
              "content_type": "sql_result"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": response ? response.summary : 'No results or match was found for the query',
              "content_type": "sql_result_summary"
            });
          }
          if(featureFlags.enableChatHistorySave) {
              history.summary = response?.summary;
              history.message_type = 'SQL';
              history.sql_generated = sql;
              history.df_json = response?.df_json;
              history.message_metadata = { token_usage: {run_sql: response.message_metadata?.run_sql } };
              let processMetadataTokenUsage = processResponse.message_metadata;
              if(processMetadataTokenUsage?.generate_sql) {
                history.message_metadata.token_usage.generate_sql = processMetadataTokenUsage.generate_sql;
              }
              if(processMetadataTokenUsage?.conversational_chat) {
                history.message_metadata.token_usage.conversational_chat = processMetadataTokenUsage.conversational_chat;
              }
              this.saveChatHistory(history, promptValue);
          }
          this.getChart(sql, response.df_json, promptValue, this.chatId, history);

          this.cdRef.detectChanges();
          this.sqlResponse = response.df_markdown;
          let responseMessage = this.createJsonTableData(jsonResponse, systemResponse, promptValue, sql);
          
          let systemRunSqlResponse = new ChatMessage(responseMessage, this.systemAuthor, new Date().toISOString(), promptValue, response.id, this.chatId, response.summary, sql);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.addMessage(systemRunSqlResponse);
          this.isLoading = false;
          this.receivedResponse = !featureFlags.enableChatHistorySave;
          this.cdRef.detectChanges();
          this.scrollToChat('SYSTEM');
        } else {
          let history = response ? {...response} : {};
          if(chat_history) {
            this.chatInteractions = [];
            this.chatInteractions.push({
              "role": "assistant",
              "content": chat_history,
              "content_type": "chat_history_summary"
            });
            history.chat_history_summary = chat_history;
          } else {
            this.chatInteractions.push({
              "role": "user",
              "content": contextual_question || promptValue,
              "content_type": "Question"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": sql,
              "content_type": "sql"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": JSON.stringify([]),
              "content_type": "sql_result"
            });
            this.chatInteractions.push({
              "role": "assistant",
              "content": response ? response.summary : 'No results or match was found for the query',
              "content_type": "sql_result_summary"
            });
          }

          if(featureFlags.enableChatHistorySave) {
            history.summary = response?.summary;
            history.message_type = 'SQL';
            history.sql_generated = sql;
            history.df_json = null;
            history.plotly_graph = null;
            history.message_metadata = { token_usage: { run_sql: response?.message_metadata?.run_sql } };
            let processMetadataTokenUsage = processResponse.message_metadata;
            if(processMetadataTokenUsage?.generate_sql) {
              history.message_metadata.token_usage.generate_sql = processMetadataTokenUsage.generate_sql;
            }
            if(processMetadataTokenUsage?.conversational_chat) {
              history.message_metadata.token_usage.conversational_chat = processMetadataTokenUsage.conversational_chat;
            }
            this.saveChatHistory(history, promptValue, true, false);
          }
          this.generateSqlFailed = true;
          systemResponse.parentId = 'parentId';
          this.showErrorIcon[this.chatId] = true;
          let sqlErr =  `<p>` + sql + `</p><p>` + Messages.NO_RESPONSE + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErr, this.systemAuthor, new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
          this.addMessage(systemRunSqlResponse);
          this.generatingResponse = false;
          this.isLoading = false;
          this.receivedResponse = !featureFlags.enableChatHistorySave;
          this.cdRef.detectChanges();
          this.scrollToChat('SYSTEM');
        }
      },
      error: (error) => {
        let history = {...error.error};

        if(featureFlags.enableChatHistorySave) {
          history.summary = null;
          history.message_type = 'SQL';
          history.message_type = 'Error'; // ERROR
          history.sql_generated = null;
          history.df_json = null;
          history.plotly_graph = null;
          if(!history.message_metadata) {
            if (error.status === 504) {
              history.message_metadata = { error: { status_code: 504, error_message:'Gateway timed out' }, token_usage: { } };
            } else {
              history.message_metadata = { token_usage: { } };
            }
          }
          history.message_metadata.token_usage = { run_sql: history?.message_metadata?.run_sql };
          let processMetadataTokenUsage = processResponse.message_metadata;
          if(processMetadataTokenUsage?.generate_sql) {
            history.message_metadata.token_usage.generate_sql = processMetadataTokenUsage.generate_sql;
          }
          if(processMetadataTokenUsage?.conversational_chat) {
            history.message_metadata.token_usage.conversational_chat = processMetadataTokenUsage.conversational_chat;
          }
          this.saveChatHistory(history, promptValue, true, false);
        }
        if (error.status === 503) {
          this.isDbConnectErr = true;
        } else if (error.status === 400) {
          this.generateSqlFailed = true;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.showErrorIcon[this.chatId] = true;
          let injectionErr = (error.error?.error_message.match(/^SQL injection\.*/g) ? Messages.NO_ACTION_ALLOWED :  Messages.INTERNAL_SERVER_ERROR);
          let sqlErr =  (error.error?.error_message.match(/^Syntax error\.*/g)) ? Messages.GENERATE_SQL_ERROR : injectionErr;
          let sqlErrMsg = `<p>` + sqlErr + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErrMsg, this.systemAuthor, new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemRunSqlResponse);
        } else if (error.status === 204) {
          this.generateSqlFailed = true;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.showErrorIcon[this.chatId] = true;
          let sqlErr =  `<p>` + sql + `</p><p>` + Messages.NO_RESPONSE + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErr, this.systemAuthor, new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemRunSqlResponse);
        } else {
          this.generateSqlFailed = true;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.showErrorIcon[this.chatId] = true;
          let sqlErr =  `<p>` + Messages.INTERNAL_SERVER_ERROR + `</p>`;
          let systemRunSqlResponse = new ChatMessage(sqlErr, this.systemAuthor, new Date().toISOString(), systemResponse.prompt, 'childId_'+this.chatId, this.chatId);
          systemRunSqlResponse.id = 'child_chat_' + this.chatId;
          systemRunSqlResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemRunSqlResponse);
        }
        this.isLoading = false;
        this.generatingResponse = false;
        this.receivedResponse = !featureFlags.enableChatHistorySave;
        this.cdRef.detectChanges();
        this.scrollToChat('SYSTEM');
      }
    });
    this.subscriptions.add(this.runSqlSubscription);
  }

  createJsonTableData(jsonResponse: any, systemResponse: ChatMessage, promptValue: string, sql: string) {
    return AskOurDataUtils.createJsonTableData(this.chatId, jsonResponse, systemResponse, promptValue, sql, this.dbname, this.dbId, this.sessionId, this.lZStringService);
  }

  scrollToChat(role: string) {
    let main = document.querySelector('.chat-overview-container');
    let childId = '#chat_response_' + role + '_' + this.chatId;
    let child = document.querySelector(childId);
    AskOurDataUtils.scrollToElm(main, child, 400, this.userScrolling)
  }

  viewGraph(id: number, sql: string | undefined, prompt: string | undefined) {
    if(featureFlags.aodAdvanceAnalytics) {
      this.jsonResponse = this.jsonResponses[id];
      this.createChartChatId = id;
      this.selectedSql = sql || '';
      this.selectedPromptValue = prompt || '';
      this.kmdModalService.open('createChart');
    } else {
      this.openGraph[id] = !this.openGraph[id];
      this.cdRef.detectChanges();
      this.generatingChart[id] = true;
      if(this.openGraph[id] && this.graphData[id]) {
        if (!featureFlags.askOurDataCodeInterpreter || featureFlags.askOurDataPlotlyCodeInterpreter) {
          this.plotly.plotLine("", "plot_child_chat_" + id, [...this.graphData[id]], { ...this.graphLayout[id] });
        }
        let graphDiv = document.getElementById('plot_end_'+ id);
        if (graphDiv !== null) {
          graphDiv.scrollIntoView();
        }
      } else if(!this.graphData[id]){
        this.alertService.showError('Unable to generate chart')
      }
      this.generatingChart[id] = false;
    }
  }

  private handleChartResponse(response: any, chatId: number, promptValue: string, history: any): void {
    this.chartAPICalled[chatId] = true;
    this.generatingChart[chatId] = false;
  
    if (response) {
      if(featureFlags.enableChatHistorySave) {
        history.plotly_graph = response.fig;
        history.analytics_type = response?.analytics_type;
        if(history.message_metadata && history.message_metadata?.token_usage) {
          history.message_metadata.token_usage.plotly_api = response?.message_metadata?.plotly_api;
        } else {
          history.message_metadata = { token_usage: { plotly_api: response?.message_metadata?.plotly_api } };
        }
        // handle the metadata
        this.saveChatHistory(history, promptValue, true, true);
      }
      this.chartGenerated[chatId] = true;
      const graph = JSON.parse(response.fig);
      this.graphData[chatId] = graph.data;
      this.graphLayout[chatId] = graph.layout;
  
      const keywordList = ['plot', 'graph', 'display', 'chart', 'visualize', 'draw', 'histogram', 'heatmap'];
      const promptValueLowerCase = promptValue.toLowerCase();
  
      if (
        promptValueLowerCase.split(' ').some((word) => keywordList.includes(word)) ||
        ['bar chart', 'line chart', 'pie chart'].some((chartType) => promptValueLowerCase.includes(chartType))
      ) {
        this.viewGraph(chatId, '', '');
      }
    }
  }
  
  private handleChartError(chatId: number, history: any, promptValue: string): void {
    history.plotly_graph = null;
    // handle the metadata
    this.saveChatHistory(history, promptValue, true, true);
    this.chartAPICalled[chatId] = true;
    this.generatingChart[chatId] = false;
    this.cdRef.detectChanges();
  }
  
  private generateChart(request: any, chatId: number, promptValue: string, history: any): void {
    this.chartSubscription = this.askOurDataService.generateChart(request).subscribe({
      next: (response) => this.handleChartResponse(response, chatId, promptValue, history),
      error: () => this.handleChartError(chatId, history, promptValue),
    });

    this.subscriptions.add(this.chartSubscription);
  }
  
  private codeInterpreterPlotly(request: any, chatId: number, promptValue: string, history: any): void {
    this.chartSubscription = this.askOurDataService.codeInterpreterPlotly(request).subscribe({
      next: (response) => this.handleChartResponse(response, chatId, promptValue, history),
      error: () => this.handleChartError(chatId, history, promptValue),
    });
    this.subscriptions.add(this.chartSubscription);
  }

  private handleCodeInterpreterPlotResponse(response: any, chatId: number, promptValue: string, history: any): void {
    this.chartAPICalled[chatId] = true;
    this.generatingChart[chatId] = false;
  
    if (response) {
      if(featureFlags.enableChatHistorySave) {
        history.plotly_graph = response.plot_base64;
        history.analytics_type = response?.analytics_type;
        if(history.message_metadata && history.message_metadata?.token_usage) {
          history.message_metadata.token_usage.plotly_api = response.message_metadata?.plotly_api;
        } else {
          history.message_metadata = { token_usage: { plotly_api: response.message_metadata?.plotly_api } };
        }
        // handle the metadata
        this.saveChatHistory(history, promptValue, true, true);
      }
      this.chartGenerated[chatId] = true;
      this.graphData[chatId] = `data:image/png;base64,${response.plot_base64}`;
      this.graphLayout[chatId] = {};
  
      const keywordList = ['plot', 'graph', 'display', 'chart', 'visualize', 'draw', 'histogram', 'heatmap'];
      const promptValueLowerCase = promptValue.toLowerCase();
  
      if (
        promptValueLowerCase.split(' ').some((word) => keywordList.includes(word)) ||
        ['bar chart', 'line chart', 'pie chart'].some((chartType) => promptValueLowerCase.includes(chartType))
      ) {
        this.viewGraph(chatId, '', '');
      }
    }
  }
  
  getChart(sql: string, chatResponse: any, promptValue: string, chatId: number, history: any): void {
    this.generatingChart[chatId] = true;
    const request = {
      id: this.dbId,
      question: promptValue,
      sql: sql,
      df: chatResponse,
      chat_id: (this.chat_id || '').toString() || null,                 
    };
  
    if (!featureFlags.askOurDataCodeInterpreter && !featureFlags.askOurDataPlotlyCodeInterpreter) {
      this.generateChart(request, chatId, promptValue, history);
    } else if (featureFlags.askOurDataPlotlyCodeInterpreter) {
      this.codeInterpreterPlotly(request, chatId, promptValue, history);
    } else {
      this.chartSubscription = this.askOurDataService.codeInterpreterPlot(request).subscribe({
        next: (response) => this.handleCodeInterpreterPlotResponse(response, chatId, promptValue, history),
        error: () => this.handleChartError(chatId, history, promptValue),
      });
      this.subscriptions.add(this.chartSubscription);
    }
  }

  regenerate() {
    this.messages.pop();
    this.openGraph[this.chatId] = false;
    this.graphData[this.chatId] = '';
    this.showErrorIcon[this.chatId] = false;
    this.chartGenerated[this.chatId] = false;
    this.chartAPICalled[this.chatId] = false;
    this.chatId--;
    this.onSubmit(this.inputPrompt, true);
  }

  toShowCopyPrompt(role: string, chatId: number): boolean {
    return  role === 'USER' || ((this.sqlResultObtained[chatId] || this.isConversationResponse[chatId]) && !this.showErrorIcon[chatId]);
  }

  stopResponse(called = false) {
    this.isLoading = false;
    this.generatingResponse = false;
    this.chartAPICalled[this.chatId] = false;
    this.generatingChart[this.chatId] = false;
    this.receivedResponse = true;
    this.generateSubscription?.unsubscribe();
    this.runSqlSubscription?.unsubscribe();
    this.chartSubscription?.unsubscribe();
    if(called) {
      this.saveInterval = setInterval(() => {
        if(!this.savingHistory) {
          clearInterval(this.saveInterval);
          this.receivedResponse = true;
        }
      }, 200);
    }
  }

  isSubmitDisabled(): boolean {
    return (this.isLoading || this.generatingResponse || this.isDbConnectErr) || (featureFlags.enableChatHistorySave && this.generatingChart[this.chatId] && !this.chartAPICalled[this.chatId]);
  }

  back() {
     this.router.navigate(['/ask-our-data']);
  }

  startNewChat() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate(['/ask-our-data/chat'],{ queryParams: { id: this.dbId, name: this.dbname }}));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if(this.saveInterval) {
      clearInterval(this.saveInterval);
    }
    AskOurDataUtils.removeLocalStorage();
  }

 scrollChat(event: any) {
    this.userScrolling = !(event.target!.offsetHeight + event.target!.scrollTop >= event.target!.scrollHeight);
 }

 copyResponse = (chatId: number): void => {
    this.copyCount[this.chatId] = 0;
    const divs = document.querySelectorAll('.copyable-'+ chatId);
    if (!divs || divs.length === 0) {
      this.alertService.showError('Unable to copy');
      return;
    }

    const tempContainer = document.createElement('div');
    tempContainer.id = "tempContainer";
    tempContainer.style.position = 'absolute';
    tempContainer.style.left = '-9999px';  
    tempContainer.style.whiteSpace = 'pre-wrap';
    document.body.appendChild(tempContainer);
 
    divs.forEach((div) => {
      const clonedDiv = div.cloneNode(true); 
      tempContainer.appendChild(clonedDiv);
      tempContainer.appendChild(document.createElement('br'));
    });

    
    if(!featureFlags.askOurDataCodeInterpreter) {
      let svgContentEle = document.querySelector('.plot_' + chatId + ' .svg-container svg') as HTMLOrSVGImageElement; 
      if (svgContentEle) {
        let img1 = document.createElement('img');
        let svgContentEles = document.querySelectorAll('.plot_' + chatId + ' .svg-container svg.main-svg'); 
        AskOurDataUtils.convertSvgToPngDataUri(svgContentEles, img1, tempContainer, this.plotly);
      } else {
        const chartContainer = document.createElement('div');
        chartContainer.style.position = 'absolute';
        chartContainer.style.left = '-9999px';  
        chartContainer.style.whiteSpace = 'pre-wrap';
        chartContainer.id = "plot_temp";
        document.body.appendChild(chartContainer);

        if(this.graphData[chatId]) {
          this.plotly.plotLine("", "plot_temp", [...this.graphData[chatId]], {...this.graphLayout[chatId]});
          let img1 = document.createElement('img');
          let svgContentEles = document.querySelectorAll('#plot_temp' + ' .svg-container svg.main-svg'); 
          AskOurDataUtils.convertSvgToPngDataUri(svgContentEles, img1, tempContainer, this.plotly);
        }
      }
    }
    else {
      let img1 = document.createElement('img');
      img1.src = this.graphData[chatId];
      tempContainer.appendChild(img1);
    }
    
    setTimeout(()=> {
      if(document.querySelectorAll('#tempContainer img').length > 0 || !this.graphData[chatId]) {
        if(this.copyInterval[this.chatId]) {
          clearInterval(this.copyInterval[this.chatId]);
          this.copyInterval[this.chatId] = null;
        }
        this.copyFromTemp(tempContainer);
      } else {
        this.copyInterval[this.chatId] = setInterval(() => {
          this.copyCount[this.chatId] = this.copyCount[this.chatId] ? this.copyCount[this.chatId] + 1 : 1;
          if(document.querySelectorAll('#tempContainer img').length > 0 || this.copyCount[this.chatId] > 10) {
            this.copyFromTemp(tempContainer);
            clearInterval(this.copyInterval[this.chatId]);
            this.copyInterval[this.chatId] = null;
          }
        }, 300);
      }
    }, 500);
     
  }

  copyFromTemp = (tempContainer: any): void => {
    this.copyCount[this.chatId] = 0;
    const range = document.createRange();
    range.selectNodeContents(tempContainer);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      this.alertService.showError('Unable to copy');
      return;
    }
    try {
      document.execCommand('copy');
      this.alertService.showSuccess('Copied to clipboard');
    } catch (err) {
      console.error('Failed to copy content:', err);
      this.alertService.showError('Unable to copy');
    }

    selection?.removeAllRanges();
    document.body.removeChild(tempContainer);

  }

  convertSvgToPngDataUri(svgDataEles: any, image: any, tempContainer: any): any {
       this.plotly.convertSVGsToSingleImage(svgDataEles).then(source => {
        image.addEventListener('load', () => {
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(image, 0, 0);
            const pngDataUri = canvas.toDataURL('image/png');
            image.src = pngDataUri;
            tempContainer.appendChild(image);
          } else {
            console.error('Failed to convert SVG to PNG');
          }
        })
        image.src = source;
      });
  }

  continue() {
    this.askOurDataService.saveConsent({
      "user_id": this.oid,
      "database_id": this.dbId
    }).subscribe();
    this.kmdModalService.close('consentModal');
  }

  isChecked(value: any){
    this.consent = value?.checked;
  }

  onChipClick($prompt: SamplePrompt) {
    this.promptInputComponent.setPromptAndFocus($prompt.question);
  }

  onSamplePromptsReceived(prompts: SamplePrompt[]) {
    this.samplePrompts = prompts;
  }

  isInputPromptSameAsSamplePrompt(inputPrompt: string, samplePrompts: SamplePrompt[]): number {
    const normalizedInput = inputPrompt.trim().toLowerCase().replace(/\s+/g, ' ');
    return samplePrompts.findIndex((samplePrompt) => 
      samplePrompt.question?.trim().toLowerCase().replace(/\s+/g, ' ') === normalizedInput
    );
  }

  handlePrompt(inputPrompt: string) {
    let samplePromptIndex = this.isInputPromptSameAsSamplePrompt(inputPrompt, this.samplePrompts);
    this.ischatEmpty = false;
    if (this.chatId === 1 && samplePromptIndex !== -1) {
      let samplePromptSql = this.samplePrompts[samplePromptIndex]?.sql;
      this.chatId ++;
      /* istanbul ignore else */
      if (inputPrompt.trim() == "") return;
      /* istanbul ignore else */
      if (this.isLoading) return;
      this.resultObtained[this.chatId] = false;
      this.sqlResultObtained[this.chatId] = false;
      this.showErrorIcon[this.chatId] = false;
      this.inputPrompt = inputPrompt;
      this.receivedResponse = false;
      let promptValue = (inputPrompt || '').trim();
      this.promptInputComponent.setPrompt('');
      let author = this.userAuthor;
      let message = new ChatMessage(promptValue , author, new Date().toISOString(), promptValue,'', this.chatId);
      message.id = 'chat_' + this.chatId;
      this.addMessage(message);
      this.chat_id = null;
      this.saveChatHistory({}, inputPrompt, false, false);
      this.generatingResponse = true;
      this.isLoading = true;
      this.cdRef.detectChanges();
      this.scrollToChat('USER');
      let systemResponse = new ChatMessage(samplePromptSql, this.systemAuthor, new Date().toISOString(), samplePromptSql, '', this.chatId);
      systemResponse.id = 'child_chat_' + this.chatId;
      systemResponse.parentId = 'chat_' + this.chatId;
      this.cdRef.detectChanges();
      this.runSql(samplePromptSql, systemResponse, promptValue, {});
    } else {
      this.onSubmit(inputPrompt);
    }
  }

  displayLoader(status: any) {
    this.showLoader = status;
  }

  displayChart(chartData: any) {
    let plotEle = document.getElementById('plot_child_chat_'+ chartData.chatId) as HTMLElement;
    if (chartData.isPredictive) {
      plotEle.innerHTML = '';
      this.predictiveText[chartData.chatId] = chartData.predictiveText;
    } else {
      this.predictiveText[chartData.chatId] = '';
      this.plotly.plotLine("", "plot_child_chat_"+ chartData.chatId, [...chartData.data], {...chartData.layout});
      let graphDiv = document.getElementById('plot_end_'+ chartData.chatId);
      if (graphDiv !== null) {
        graphDiv.scrollIntoView();
      }
    }
  }

  getData(id: any) {
    return this.predictiveText[id];
  }

  toggleDatabaseDropdown() {
    this.showModelDropdown = !this.showModelDropdown;
    this.modelDropdown.toggleMenu();
  }

  isContextMenuOpen(isOpen: any) {
    this.showModelDropdown = isOpen;
  }

  closeDropdownMenu() {
    if (this.showModelDropdown) {
      this.showModelDropdown = !this.showModelDropdown;
      this.modelDropdown.toggleMenu();
    }
  }

  menuItemSelected(menuItem: ContextMenuItem) {
    switch (menuItem.label) {
      case "History":
          if(featureFlags.aodDataChatHistory) {
            this.chatHistoryComponent.openChatHistory();
          }
        break;
      default:
    }
  }

  clearChat() {
    this.ischatEmpty = true;
    this.messages = [];
    this.showErrorIcon = [];
    this.stopResponse();
    this.chatId = 0;
    this.chatInteractions = [];
    this.receivedResponse = false;
    this.graphData = [];
    this.graphLayout = [];
  }
  
  // Display chat history
  displayChat(chatDetails: any) {
    this.clearChat();
    const userAuthor = this.userAuthor;
    const systemAuthor = this.systemAuthor;
    let chatIdList: number[] = [];

    chatDetails.forEach((chatDetail: any) => {
        if(chatDetail.chat_history_summary) {
          chatIdList.push(chatDetail.chat_id);
        }
    });

    let latestChatSummaryId = chatIdList.length > 0 ? chatIdList[chatIdList.length - 1] : chatDetails[0].chat_id;

    chatDetails.forEach((chatDetail: any, i: number) => {
      this.chatId++;
      this.chat_id = chatDetail.chat_id;

      const userMessage = new ChatMessage(chatDetail.user_query, userAuthor, new Date().toISOString(), chatDetail.user_query, '', this.chatId);
      userMessage.id = 'chat_' + this.chatId;
      this.addMessage(userMessage);

      this.chartGenerated[this.chatId] = false;

      const metadata = isObject(chatDetail.message_metadata) ? chatDetail.message_metadata : JSON.parse(chatDetail.message_metadata || '{}');
      let statusCode = metadata?.error?.status_code || metadata?.status_code
      if (statusCode) {
        if (chatDetail.sql_generated) {
          // this.chatId++;
          this.addChatInteraction(chatDetail, latestChatSummaryId);
          const systemResponse = this.createSystemMessage(chatDetail.sql_generated, userMessage.id);
          this.addMessage(systemResponse);
        }
        this.handleGenerateSqlErr(metadata.error || metadata, this.chatId, chatDetail.sql_generated);
      } else {
        this.handleNonSqlResponseForHistory(chatDetail, userMessage, systemAuthor, latestChatSummaryId);
      }

      if (i === chatDetails.length - 1) {
        this.ischatEmpty = false;
        this.sessionId = chatDetail.session_id;
        this.receivedResponse = true;
        this.inputPrompt = chatDetail.user_query;
      }
    });
  }

  private addChatInteraction(chatDetail: any, latestChatSummaryId: number) {
    let chat_id = chatDetail?.chat_id;
    let userQuery = chatDetail?.user_query;
    let sqlGenerated = chatDetail?.sql_generated;
    let chat_history = chatDetail?.chat_history_summary;
    if (chat_id > latestChatSummaryId || (chat_id === latestChatSummaryId && !chatDetail.chat_history_summary)) {
      this.chatInteractions.push(
        { role: "user", content: userQuery, content_type: "Question" },
        { role: "assistant", content: sqlGenerated, content_type: "sql" }
      );
    } else if (chat_id === latestChatSummaryId && chatDetail.chat_history_summary) {
      this.chatInteractions.push({
        "role": "assistant",
        "content": chat_history,
        "content_type": "chat_history_summary"
      });
    }
  }

  private createSystemMessage(content: string, parentId: string | undefined) {
    const systemResponse = new ChatMessage(content, this.systemAuthor, new Date().toISOString(), content, parentId, this.chatId);
    systemResponse.id = 'child_chat_' + this.chatId;
    systemResponse.parentId = 'chat_' + this.chatId;
    return systemResponse;
  }

  private handleNonSqlResponseForHistory(chatDetail: any, userMessage: ChatMessage, systemAuthor: ChatAuthor, latestChatSummaryId: number) {
    if (chatDetail.message_type === 'Non-SQL' ) {
      if(chatDetail.data_summary) {
        let predictiveText = chatDetail.data_summary;
        try {
          predictiveText = JSON.parse(chatDetail.data_summary || "{}")?.result;
          const formattedResponse = `<div class="copyable-${this.chatId}">${predictiveText?.analysis_output || ''}</div>`;
          const finalSystemResponse = new ChatMessage(formattedResponse, systemAuthor, new Date().toISOString(),chatDetail.user_query , '1', this.chatId);
          finalSystemResponse.id = 'child_chat_' + this.chatId;
          finalSystemResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(finalSystemResponse);
          this.handlePlotlyGraph(chatDetail)
        } catch (e) {
          console.log("Error parsing JSON:", e);
          const systemResponse = new ChatMessage(`<div class="copyable-${this.chatId}">${chatDetail.data_summary}</div>`, systemAuthor, new Date().toISOString(), chatDetail.user_query, userMessage.id, this.chatId);
          systemResponse.id = 'child_chat_' + this.chatId;
          systemResponse.parentId = 'chat_' + this.chatId;
          this.addMessage(systemResponse);
       }
        let chat_id = chatDetail?.chat_id;
        if (chat_id > latestChatSummaryId || (chat_id === latestChatSummaryId && !chatDetail.chat_history_summary)) {
          this.chatInteractions.push(
            { role: "user", content: chatDetail.user_query, content_type: "Question" },
            { role: "assistant", content: chatDetail.data_summary, content_type: "chat_response" }
          );
        } else if (chat_id === latestChatSummaryId && chatDetail.chat_history_summary) {
          this.chatInteractions.push({
            "role": "assistant",
            "content": chatDetail.chat_history_summary,
            "content_type": "chat_history_summary"
          });
        }
      } else {
        let chat_id = chatDetail?.chat_id;
        if (chat_id > latestChatSummaryId || (chat_id === latestChatSummaryId && !chatDetail.chat_history_summary)) {
          this.chatInteractions.push(
            { role: "user", content: chatDetail.user_query, content_type: "Question" }
          );
        } else if (chat_id === latestChatSummaryId && chatDetail.chat_history_summary) {
          this.chatInteractions.push({
            "role": "assistant",
            "content": chatDetail.chat_history_summary,
            "content_type": "chat_history_summary"
          });
        }
      }
    } else if (chatDetail.sql_generated) {
      this.handleSqlResponse(chatDetail, userMessage, latestChatSummaryId);
    }
  }

  private handleSqlResponse(chatDetail: any, userMessage: ChatMessage, latestChatSummaryId: number) {
    let isChatResponse = false;
    let systemResponse = this.createSystemMessage(chatDetail.sql_generated, userMessage.id);
    this.addChatInteraction(chatDetail, latestChatSummaryId);
    let chat_id = chatDetail?.chat_id;

    let systemRunSqlResponse: ChatMessage = new ChatMessage('', this.systemAuthor, new Date().toISOString(), '', '', this.chatId);
    if (chatDetail?.data_frame) {
      const jsonResponse = JSON.parse(chatDetail?.data_frame || "[]");
      const responseMessage = this.createJsonTableData(jsonResponse, systemResponse, chatDetail.user_query, chatDetail.sql_generated);
      systemRunSqlResponse = new ChatMessage(responseMessage, this.systemAuthor, new Date().toISOString(), chatDetail.user_query, userMessage.id, this.chatId, chatDetail.data_summary, chatDetail.sql_generated);

      if (chat_id > latestChatSummaryId || chat_id === latestChatSummaryId) {
        this.chatInteractions.push({
          role: "assistant",
          content: JSON.stringify(jsonResponse.slice(0, this.chatHistoryDataLimit)),
          content_type: "sql_result"
        });
        this.chatInteractions.push({
          role: "assistant",
          content: chatDetail.data_summary || 'No results or match was found for the query', 
          content_type: "sql_result_summary"
        });
      } 
      
    } else if(chatDetail.data_summary){
      isChatResponse = true;
      if (chat_id > latestChatSummaryId || chat_id === latestChatSummaryId) {
        this.chatInteractions.push(
          { role: "user", content: chatDetail.user_query, content_type: "Question" },
          { role: "assistant", content: chatDetail.data_summary, content_type: "chat_response" }
        );
      }

    } else {
      systemResponse = this.handleEmptySqlResponse(chatDetail, systemResponse, latestChatSummaryId);
    }

    systemRunSqlResponse.id = 'child_chat_' + this.chatId;
    systemRunSqlResponse.parentId = 'chat_' + this.chatId;

    if(isChatResponse) {
      const formattedResponse = `<div class="copyable-${this.chatId}">${chatDetail.data_summary}</div>`;
      const finalSystemResponse = new ChatMessage(formattedResponse, this.systemAuthor, new Date().toISOString(), chatDetail.user_query, userMessage.id, this.chatId);
      finalSystemResponse.id = 'child_chat_' + this.chatId;
      finalSystemResponse.parentId = 'chat_' + this.chatId;
      this.isConversationResponse[this.chatId] = true;
      this.addMessage(finalSystemResponse);
    } else {
      this.addMessage(systemResponse);
      if(systemRunSqlResponse.text) {
        this.addMessage(systemRunSqlResponse);
      }
    }

    if (chatDetail.plotly_graph) {
      this.handlePlotlyGraph(chatDetail);
    } else if (chatDetail?.data_frame || isChatResponse) {
      this.chartGenerated[this.chatId] = false;
      this.chartAPICalled[this.chatId] = !isChatResponse;
      this.resultObtained[this.chatId] = true;
      this.sqlResultObtained[this.chatId] = true;
    }
  }

  private handleEmptySqlResponse(chatDetail: any, systemResponse: ChatMessage, latestChatSummaryId: number) {

    let chat_id = chatDetail?.chat_id;
    if (chat_id > latestChatSummaryId || chat_id === latestChatSummaryId) {
      this.chatInteractions.push({
        role: "assistant",
        content: JSON.stringify([]),
        content_type: "sql_result"
      });
      this.chatInteractions.push({
        role: "assistant",
        content: 'No results or match was found for the query', 
        content_type: "sql_result_summary"
      });
    }

    if (chatDetail.sql_generated) {
      this.showErrorIcon[this.chatId] = true;
      const sqlErr = `<p>${chatDetail.sql_generated}</p><p>${Messages.NO_RESPONSE}</p>`;
      systemResponse = new ChatMessage(sqlErr, this.systemAuthor, new Date().toISOString(), systemResponse.prompt, 'childId_' + this.chatId, this.chatId);
    } else {
      systemResponse = new ChatMessage(Messages.NO_RESPONSE, this.systemAuthor, new Date().toISOString(), chatDetail.user_query, systemResponse.id, this.chatId);
    }
    return systemResponse;
  }

  private handlePlotlyGraph(chatDetail: any) {
    if(chatDetail.plotly_graph) {
      const graph = JSON.parse(chatDetail.plotly_graph);
      if(graph && !graph.data) {
        this.graphData[this.chatId] = `data:image/png;base64,${graph}`;
        this.graphLayout[this.chatId] = {};
      } else {
        this.graphData[this.chatId] = graph.data;
        this.graphLayout[this.chatId] = graph.layout;
      }
      this.viewGraph(this.chatId, '', '');
      this.chartGenerated[this.chatId] = true;
      this.chartAPICalled[this.chatId] = true;
      this.openGraph[this.chatId] = true;
      this.resultObtained[this.chatId] = true;
      this.sqlResultObtained[this.chatId] = true;
    }
    else {
      this.chartGenerated[this.chatId] = false;
      this.chartAPICalled[this.chatId] = true;
    }
  }

  handleGenerateSqlErr(error: any, chatId: any, sql: string | null) {
    this.showErrorIcon[chatId] = true;
    let injectionErrCheck = error.error_message.match(/^SQL injection\.*/g);
    let injectionErr = injectionErrCheck ? Messages.NO_ACTION_ALLOWED :  (!sql ? Messages.GENERATE_SQL_ERROR : Messages.NO_RESPONSE);
    let generateErr =  (error.error_message.match(/^Syntax error\.*/g)) ? Messages.GENERATE_SQL_ERROR : injectionErr;
    
    if (error.status_code === 504) {
      generateErr = '<p>' + Messages.INTERNAL_SERVER_ERROR + '</p>';
    } else  if(error.status_code === 204) {
      generateErr = '<p>' + sql + '</p>' + generateErr;
    }
    else if(error.status_code === 500) {
      generateErr = '<p>' + Messages.INTERNAL_SERVER_ERROR + '</p>';
    }
    let systemResponse = new ChatMessage(generateErr, this.systemAuthor, new Date().toISOString(), '', 'parentId', chatId);
    systemResponse.id = 'child_chat_' + chatId;
    systemResponse.parentId = 'chat_' + chatId;
    this.addMessage(systemResponse);
    this.generatingResponse = false;
  }

  //display chat history end

  copyCodeInterpreterChart(chatId: number) {
    
    const tempContainer = document.createElement('div');
    tempContainer.id = "tempContainer";
    tempContainer.style.position = 'absolute';
    tempContainer.style.left = '-9999px';  
    tempContainer.style.whiteSpace = 'pre-wrap';
    document.body.appendChild(tempContainer);

    let img1 = document.createElement('img');
    img1.src = this.graphData[chatId];
    tempContainer.appendChild(img1);

    setTimeout(()=> {
      if(document.querySelectorAll('#tempContainer img').length > 0 || !this.graphData[chatId]) {
        if(this.copyInterval[this.chatId]) {
          clearInterval(this.copyInterval[this.chatId]);
          this.copyInterval[this.chatId] = null;
        }
        this.copyFromTemp(tempContainer);
      } else {
        this.copyInterval[this.chatId] = setInterval(() => {
          this.copyCount[this.chatId] = this.copyCount[this.chatId] ? this.copyCount[this.chatId] + 1 : 1;
          if(document.querySelectorAll('#tempContainer img').length > 0 || this.copyCount[this.chatId] > 10) {
            this.copyFromTemp(tempContainer);
            clearInterval(this.copyInterval[this.chatId]);
            this.copyInterval[this.chatId] = null;
          }
        }, 300);
      }
    }, 500);
  }

  downloadCodeInterpreterChart(chatId: number) {
    try {
      let date = new Date();
    
      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = this.graphData[chatId];
      downloadLink.download = 'chart_' + date.getTime() + '.png';
      downloadLink.style.display = 'none';
    
      // Append, trigger download, and remove link
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    
      this.alertService.showSuccess('Chart downloaded successfully!');
    } catch (error) {
      console.error('Failed to download chart:', error);
      this.alertService.showError('Unable to download chart');
    }
  }
  
  saveChatHistory(history: any, promptValue: string, final = false, result = false) {
    if(!featureFlags.enableChatHistorySave) {
      return;
    }
    this.savingHistory = true;
    if(history?.message_metadata){
      if(history?.message_metadata.run_sql) {
        delete history?.message_metadata.run_sql;
      }
      if(history?.message_metadata.generate_sql) {
        delete history?.message_metadata.generate_sql;
      }
      if(history?.message_metadata.plotly_api) {
        delete history?.message_metadata.plotly_api;
      }
      if(history?.message_metadata.conversational_chat) {
        delete history?.message_metadata.conversational_chat;
      }
    }
    let request = {
      "user_id": this.oid,
      "session_id": this.sessionId,
      "chat_id": this.chat_id || null,
      "message_type": history?.message_type || 'Non-SQL',
      "sql_generated": history?.sql_generated || null,
      "database_id": parseInt(this.dbId, 10),
      "data_frame": history?.df_json || null,
      "data_summary": history?.summary || null,
      "plotly_graph": history?.plotly_graph ? JSON.stringify(history?.plotly_graph) : null,
      "message_metadata": history?.message_metadata || {},
      "user_query": promptValue,
      "analytics_type": history?.analytics_type || null,
      "chat_history_summary": history?.chat_history_summary || null
    };
    
    this.askOurDataService.saveChatHistory(request).subscribe({
      next: (saveResponse: any) => {
        this.savingHistory = false;
        this.chat_id = saveResponse?.chat_id;
        this.receivedResponse = final;
        this.resultObtained[this.chatId] = result;
      },
      error: () => {
        this.savingHistory = false;
        this.receivedResponse = final;
        this.resultObtained[this.chatId] = result;
      }
    });
  }

}