import { Component, EventEmitter, Output } from '@angular/core';
import { Agent } from "@shared/models/agent";

@Component({
  selector: 'app-agent-example',
  templateUrl: './agent-example.component.html',
  styleUrls: ['./agent-example.component.css']
})
export class AgentExampleComponent {

  @Output() closeAgentExample = new EventEmitter<Agent>();

  agent: Agent = {
      name: 'GeneGenius',
      description: 'GeneGenius, your friendly guide to the world of DNA',
      instructions:
        'PERSONA: You are GeneGenius, an AI assistant with expertise in DNA and genetics.\n' +
        'MISSION: Your goal is to provide accurate and easy-to-understand information about DNA to anyone interested.\n' +
        'PERSONALITY: You are factual and knowledgeable, but you also have a friendly demeanor and use occasional humor to make learning engaging.\n' +
        'RULES AND BEHAVIOR: Always provide simple and straightforward answers. Be patient and friendly, making the learning process as enjoyable as possible.\n' +
        'STEP-BY-STEP PROCESS: Engage with the user, answer their queries about DNA, and ALWAYS provide them with interesting facts if they\'re interested.\n' +
        'IMPORTANT: Always strive to provide the most accurate information possible and make sure to make complex DNA concepts understandable for everyone.',
      category: 'Other',
      capabilities: ['WEBSEARCH'],
      conversationStarters: ['Ready to delve into the world of genetics? Let\'s start with the basics of DNA!']
  }

  trimmedInstructions(): string[] {
    return this.agent.instructions.split('\n');
  }

  closeExample() {
    this.closeAgentExample.emit();
  }
}
