<div class="prompt-input-wrapper">
    <div class="request-input-wrapper">
        <div class="request-input-wrapper-top">
            <app-circle-with-initials [user]="'User'" [username]="username || 'NA'"></app-circle-with-initials>
            <textarea #textArea id="prompt" rows="1" (keydown.enter)="onEnterKey($event)"
                placeholder="Please type a prompt for Gene.AI here" class="request-input"
                [ngClass]="{'error-outline': isTextExceeded()}" (paste)="onPaste($event)"
                (keyup)="onPromptChange()" maxlength="{{maxInputCharLimit()}}" [(ngModel)]="prompt"
                (ngModelChange)="resizeTextarea()"></textarea>
            <div id="prompt-submit" class="request-input-icon-box" [ngClass]="submitState()" (click)="onSubmit()">
                <fa-icon class="request-input-icon" [icon]="faPaperPlane"></fa-icon>
            </div>
        </div>

        <div class="request-input-wrapper-bottom">
            <p id="text-trimmed" *ngIf="isTextTrimmed()" class="error-text">
                Text cut, characters limit reached on model
            </p>
            <p id="text-limit" *ngIf="isTextExceeded()" class="error-text">
                Character limit exceeded
            </p>
            <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
            <p id="example" *ngIf="promptExample && chatId != undefined"
                (click)="setExampleCurrentPrompt(promptExample)">
                Example<em>: </em><em id="example-title">{{
                    promptExample.title
                    }}</em></p>
            <countdown-label *ngIf="showCharacterCounter" [maxlength]=maxInputCharLimit() [value]="prompt" />
        </div>
    </div>
    <fa-icon *ngIf="showPromptSettingsButton" class="btn-prompt-settings exclude-from-drawer-directive" [icon]="faSlidersH"
                (click)="openDrawer()" kmdTooltip="Prompt Settings" tooltipPlacement="top"></fa-icon>
</div>
