import { Injectable } from "@angular/core";
import { Prompt } from "@app/shared/models/prompt.model";
import { PromptSubmitEvent } from "@shared/models/prompt-submit-event";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map, Observable, tap } from "rxjs";
import { UuidService } from "../uuid.service";
import { Author, Message } from "@shared/models/message.model";
import { ChatService } from "../chat/chat.service";
import { PromptSettings } from "@shared/models/prompt-settings.model";
import { ChatMessage } from "@app/chat/chat-message";
import { MessageNode } from "@app/shared/models/message-node.model";

@Injectable({
  providedIn: 'root'
})
export class ChatQAService {

  private _activePrompt: { prompt: Prompt, message: Message } | undefined;
  private _promptSettings: PromptSettings = new PromptSettings(3, "PROFESSIONAL", false, "gpt-4-o");
  private _activeParentId: string | undefined;

  constructor(
    private uuidService: UuidService,
    private chatService: ChatService,
    private oidcSecurityService: OidcSecurityService
  ) { };

  get userName$(): Observable<string> {
    return this.oidcSecurityService.userData$.pipe(
      map((result) => result.userData.name)
    );
  };

  get activePrompt(): { prompt: Prompt, message: Message } | undefined {
    return this._activePrompt;
  };

  get activeParentId(): string | undefined {
    return this._activeParentId;
  };

  set activeParentId(id: string | undefined) {
    this._activeParentId = id;
  };

  get promptSettings(): PromptSettings {
    return this._promptSettings;
  };

  set promptSettings(settings: PromptSettings) {
    this._promptSettings = settings;
  };

  clearActiveChat() {
    this._activeParentId = undefined;
    this._activePrompt = undefined;
    this._promptSettings = new PromptSettings(3, "PROFESSIONAL", false, "gpt-4-o");
  }

  setNewActivePrompt(event: PromptSubmitEvent): void {
    const messageId = this.uuidService.random();
    const prompt = new Prompt(event.prompt, event.settings, this._activeParentId, event.files);
    const message = new Message(event.prompt, new Author("USER"), new Date(), Object.assign({}, event.settings), this._activeParentId);

    prompt.id = messageId;
    message.id = messageId;

    this._activePrompt = { prompt, message };
  }

  createChat(prompt: Prompt): Observable<{
    chatId: string,
    message: Message | undefined,
    answer: {
      id: string,
      parentId: string | undefined
    }
  }> {
    return this.chatService.promptChat(prompt, true).pipe(
      map((response) => {
        return {
          chatId: response.chat.id!,
          answer: {
            id: response.prompt.answer?.id!,
            parentId: response.prompt.answer?.parentId,
          },
          message: response.prompt.answer,
        };
      }),
      tap((response) => this._activeParentId = response.answer.id)
    );
  };

  sendChat(chatId: string, prompt: Prompt): Observable<{
    chatId: string,
    message: Message,
    answer: {
      id: string,
      parentId: string | undefined
    }
  }> {
    return this.chatService.prompt(chatId, prompt, true).pipe(
      map((response) => {
        return {
          chatId: response.chatId!,
          answer: {
            id: response.answer!.id!,
            parentId: response.answer!.parentId,
          },
          message: response.answer!,
        };
      }),
      tap((response) => this._activeParentId = response.answer.id)
    );
  };

  getExistingMessages(chatId: string): Observable<MessageNode[]> {
    return this.chatService.getChatMessages(chatId)
      .pipe(
        map((messages) => this.buildMessageTree(messages))
      );
  };

  regenerateResponse(chatId: string, message: Message): Observable<{
    chatId: string,
    message: Message,
    answer: {
      id: string,
      parentId: string | undefined
    }
  }> {
    const prompt = new Prompt(message.text, message.promptSettings, message.parentId, undefined);
    prompt.id = message.id;

    return this.chatService.regenerateResponse(chatId, prompt, true).pipe(
      map((response) => {
        return {
          chatId: response.chatId!,
          answer: {
            id: response.answer!.id!,
            parentId: response.answer!.parentId,
          },
          message: response.answer!,
        };
      }),
      tap((response) => this._activeParentId = response.answer.id)
    );
  };

  buildMessageTree(messages: Message[]): MessageNode[] {
    const tree: MessageNode[] = [];
    const newestMessage = messages[messages.length - 1];

    messages.forEach((message) => {
      const id = message.id!;
      const role = message.author?.role === "USER" ? "USER" : "ASSISTANT";

      if (!message.parentId) {
        tree.push({ 
          id,
          role,
          child: [],
          sibling: [],
          siblingIndex: 0,
          content: new ChatMessage(message, undefined),
          isActive: false
        });

        return;
      }

      const parent = this.findMessageNodeById(tree, message.parentId!);

      if (!parent) {
        return;
      }

      parent.child.forEach(child => child.isActive = false);

      const newNode: MessageNode = {
        id,
        role,
        child: [],
        sibling: [],
        siblingIndex: parent.child.length,
        content: new ChatMessage(message, parent.content),
        isActive: false
      };

      parent.child.forEach((siblingNode) => {
        siblingNode.sibling.push(newNode);
        newNode.sibling.push(siblingNode);
      });

      parent.child.push(newNode);
    });

    this.setActivePathToTargetMessage(tree, newestMessage.id!);

    return tree;
  }

  setActivePathToTargetMessage(tree: MessageNode[], targetId: string): void {
    const findPathToNewestMessage = (node: MessageNode, targetId: string, path: MessageNode[]): boolean => {
      if (node.id === targetId) {
        path.push(node);
        return true;
      }

      for (const child of node.child) {
        if (findPathToNewestMessage(child, targetId, path)) {
          path.push(node);
          return true;
        }
      }

      return false;
    };

    const path: MessageNode[] = [];
    for (const rootNode of tree) {
      if (findPathToNewestMessage(rootNode, targetId, path)) {
        break;
      }
    }

    path.forEach((node) => node.isActive = true);
  }

  findMessageNodeById(tree: MessageNode[], id: string): MessageNode | null {
    for (const node of tree) {
      if (node.id === id) return node;
      const foundNode = this.findMessageNodeById(node.child, id);
      if (foundNode) return foundNode;
    }

    return null;
  }

  addMessageToTree(tree: MessageNode[], message: Message, parentId: string | null): MessageNode {
    const id = message.id!;
    const role = message.author?.role === "USER" ? "USER" : "ASSISTANT";

    const parentNode = this.findMessageNodeById(tree, parentId!);
    const parent = parentNode ? parentNode : null;

    const existingNode = this.findMessageNodeById(tree, id);
    if (existingNode) {
      existingNode.content = new ChatMessage(message, parent ? parent.content : undefined);
      existingNode.isActive = true;

      return existingNode;
    }

    const newNode: MessageNode = {
      id,
      role,
      child: [],
      sibling: [],
      siblingIndex: parent ? parent.child.length : 0,
      content: new ChatMessage(message, parent ? parent.content : undefined),
      isActive: true
    };

    if (parent) {
      parent.child.forEach(child => child.isActive = false);

      parent.child.forEach((siblingNode) => {
        siblingNode.sibling.push(newNode);
        newNode.sibling.push(siblingNode);
      });

      parent.child.push(newNode);
    } else {
      tree.push(newNode);
    }

    return newNode;
  };

  getActiveBranch(tree: MessageNode[]): MessageNode[] {
    const activeBranch: MessageNode[] = [];

    let node = tree.find((node) => node.isActive);

    while (node) {
      activeBranch.push(node);
      this._activeParentId = node.content.message.id;
      node = node.child.find((child) => child.isActive);
    }

    return activeBranch;
  };

  shiftActiveBranch(tree: MessageNode[], currentAssistantNode: MessageNode, nextSiblingIndex: number): MessageNode[] {
    const activeBranch = this.getActiveBranch(tree);
    const activeNode = activeBranch.find((node) => node.content.message.id === currentAssistantNode.content.message.id);

    if (!activeNode) {
      return activeBranch;
    }

    if (nextSiblingIndex < 0 || nextSiblingIndex >= activeNode.sibling.length + 1) {
      return activeBranch;
    }

    const sibling = activeNode.sibling.find((sibling) => sibling.siblingIndex === nextSiblingIndex);
    if (!sibling) {
      return activeBranch;
    }

    activeNode.isActive = false;
    sibling.isActive = true;

    const newestMessage = this.findNewestMessageFromRoot(sibling);
    this.setActivePathToTargetMessage(tree, newestMessage!.id);

    const newActiveBranch = this.getActiveBranch(tree);

    return newActiveBranch;
  };

  findNewestMessageFromRoot(root: MessageNode): MessageNode | null {
    let newestMessage = root;

    const findNewestMessage = (node: MessageNode): void => {
      if (node.content.message.created > newestMessage.content.message.created) {
        newestMessage = node;
      }

      node.child.forEach((child) => findNewestMessage(child));
    };

    findNewestMessage(root);

    return newestMessage;
  }
}
