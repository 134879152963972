import { Component, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PromptExample } from "@shared/models/prompt-example.model";
import { ChatWrapperComponent } from '@shared/components/chat-wrapper/chat-wrapper.component';
import { ChatQAService } from '@services/chat-qa/chat-qa.service';
import { PromptSubmitEvent } from '@shared/models/prompt-submit-event';
import { Router } from '@angular/router';
import { NavigationCacheService } from '@app/core/services/navigation-cache/navigation-cache.service';

@Component({
  selector: 'ask-gene-new',
  templateUrl: './ask-gene-new.component.html',
  styleUrls: ['./ask-gene-new.component.css']
})
export class AskGeneNewComponent {
  @ViewChild(ChatWrapperComponent) chatWrapperComponent!: ChatWrapperComponent;

  userName$: Observable<string> = of('');

  bannerEnabled: boolean = true;
  readonly bannerModalTitle = 'NEW! Upload documents directly in main chat!';
  readonly askMyDocsLink = 'https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/_layouts/15/stream.aspx?id=%2Fsites%2FGenerativeAICenterofExcellence%2FShared%20Documents%2FGene%2EAI%20Training%20Videos%2FAskMyDocs%2DNov2024%2Emp4&referrer=StreamWebApp%2EWeb&referrerScenario=AddressBarCopied%2Eview%2Ec39ee6f9%2D2cae%2D485a%2D918a%2D0357e7f3896b'
  readonly askMyCSVLink = 'https://thermofisher.sharepoint.com/sites/GenerativeAICenterofExcellence/_layouts/15/stream.aspx?id=%2Fsites%2FGenerativeAICenterofExcellence%2FShared%20Documents%2FGene%2EAI%20Training%20Videos%2FAskMySpreadsheet%2Emp4&referrer=StreamWebApp%2EWeb&referrerScenario=AddressBarCopied%2Eview%2Eeea2f2cc%2D6579%2D45e7%2D8cea%2D9ab7403dd3'

  constructor(
    public router: Router,
    private chatQAService: ChatQAService,
    private navigationCacheService: NavigationCacheService
  ) {
    this.userName$ = this.chatQAService.userName$;
    this.navigationCacheService.clearData();
  };

  setPrompt(prompt: PromptExample) {
    this.chatWrapperComponent.setPromptText(prompt.text);
  }

  onPromptSubmit(promptEvent: PromptSubmitEvent) {
    this.chatQAService.setNewActivePrompt(promptEvent);
    this.router.navigate([`/chat`]);
  }
}
