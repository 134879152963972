import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modals: { [key: string]: BehaviorSubject<boolean> } = {};

  registerModal(name: string) {
    if (!this.modals[name]) {
      this.modals[name] = new BehaviorSubject<boolean>(false);
    }
  }

  open(name: string) {
    if (this.modals[name]) {
      this.modals[name].next(true);
    }
  }

  close(name: string) {
    if (this.modals[name]) {
      this.modals[name].next(false);
    }
  }

  getModalState(name: string) {
    return this.modals[name]?.asObservable();
  }
}
