<div class="public-overview-container">
  <div class="property-container">
    <div class="property-wrapper">
      <div id="name" class="property">
        <label class="key">Name:</label>
        <p class="value">{{getLabelValue(agent?.name)}}</p>
      </div>
      <div id="category" class="property">
        <label class="key">Category:</label>
        <p class="value">{{getLabelValue(agent?.category)}}</p>
      </div>
      <div id="createdby" class="property">
        <label class="key">Created By:</label>
        <p class="value">{{username}}</p>
      </div>
    </div>
    <div class="property-wrapper">
      <div id="description" class="property">
        <label class="key">Description:</label>
        <p class="value">{{getLabelValue(agent?.description)}}</p>
      </div>
    </div>
    <div class="property-wrapper">
      <div id="conversation-starter" class="property">
        <label class="key">Conversation Starter:</label>
        <p class="value">{{getConversationStartersLabel()}}</p>
      </div>
    </div>
    <div class="property-wrapper">
      <div id="instructions" class="property">
        <label class="key">Instructions:</label>
        <p class="value">{{getLabelValue(agent?.instructions)}}</p>
      </div>
    </div>
  </div>
  <div class="actions-container">
    <div>
      <p class="agent-gudieline">By making my Agent public, I confirm that my Agent complies with the <a href="/guidelines" target="_blank">Gene.AI Guidelines</a> and agree to print my name in association with my agent.</p>
    </div>
    <div class="actions-wrapper">
      <div class="actions-wrapper left">
        <div id="edit-agent" class="action">
          <label>
            <a [routerLink]="['/agents', agent?.id, 'edit']">Edit agent</a>
          </label>
        </div>
      </div>
      <div class="actions-wrapper right">
        <div id="cancel" class="action">
          <core-button label="Cancel" variant="information" type="button" size="medium"
                       (click)="onCancel()"/>
        </div>
        <div id="make-public" class="action">
          <core-button label="Share Agent" variant="primary" type="button" size="medium"
                       (click)="makeAgentPublic()"/>
        </div>
      </div>
    </div>
  </div>
</div>
