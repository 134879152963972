import {Component, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { AgentsService } from "@services/agents/agents.service";
import { ActivatedRoute, Router } from "@angular/router";
import { mergeMap, Observer } from "rxjs";
import { map } from "rxjs/operators";
import { AgentFormComponent } from "../agent-form/agent-form.component";
import { Agent } from "@shared/models/agent";
import { HttpErrorResponse } from "@angular/common/http";
import { KmdModalService } from "gds-atom-components";
import { featureFlags } from "@environments/environment";

@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AgentEditComponent implements OnInit {

  @ViewChild('agentForm') agentForm!: AgentFormComponent;

  success: boolean = false;
  error: boolean = false;
  errorText: string = "";

  validForm: boolean = false;

  agent?: Agent;

  showExampleButton = true
  showExampleAgent = false;
  showAgentEditForm = true;

  windowWidth = window.innerWidth;

  constructor(private activatedRoute: ActivatedRoute,
              private agentsService: AgentsService,
              private modalService: KmdModalService,
              private router: Router) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = (event.target as Window).innerWidth;
    this.showAgentEditForm = !(this.windowWidth <= 1200 && this.showExampleAgent);
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      map(params => params['agentId']),
      mergeMap(agentId => this.agentsService.agent(agentId))
    ).subscribe({
      next: (agent) => {
        this.agent = agent;
      },
      error: () => {
        this.router.navigate(['/error'])
      }
    });
  }

  formStatusChanged(valid: boolean) {
    this.validForm = valid
  }

  submit() {
    this.agentForm.submit();
  }

  saveAgent(agent: Agent) {
    let observer: Partial<Observer<Agent>> = {
      next: (updatedAgent: Agent) => {
        this.success = true;
        this.agent = updatedAgent;
      },
      error: (err: HttpErrorResponse) => {
        this.error = true;
        this.errorText = this.errorMessage(err);
      }
    };
    if(agent.publishedByUser && featureFlags.editPublicAgent)
      this.agentsService.updatePublicAgent(agent).subscribe(observer);
    else
      this.agentsService.update(agent).subscribe(observer);
  }

  navigateToAgents() {
    this.modalService.open("cancel-edit-modal")
  }

  navigateToChatWithAgent() {
    this.router.navigate([`/agents/${ this.agent?.id }/chat`])
  }

  discardChanges() {
    this.router.navigate(['/agents'])
  }

  continueEditing() {
    this.modalService.close("cancel-edit-modal")
  }

  toggleAgentExample() {
    this.showExampleButton = !this.showExampleButton;
    this.showExampleAgent = !this.showExampleAgent;
    this.showAgentEditForm = !(this.windowWidth <= 1200 && this.showExampleAgent);
  }

  private errorMessage(err: HttpErrorResponse) {
    if (err.status === 409) {
      return "You already have an agent with this name. Please use a unique name for your agent."
    } else {
      return "An error occurred while updating the agent. Please try again later."
    }
  }
}
