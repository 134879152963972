import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from "rxjs";
import { KmdKeyValueOption } from "@shared/models/kmd-key-value-option";
import { map, mergeMap } from "rxjs/operators";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { askOurDocsRoles } from "@environments/environment";
import { Paged } from "@shared/models/paged.model";
import { GroupMembershipService } from '../group-membership/group-membership.service';
import { AskOurDocsSubject } from '@app/shared/models/ask-our-docs-subject';

@Injectable({
  providedIn: 'root'
})
export class AskOurDocsService {

  constructor(
    private oidcIdentityService: OidcSecurityService,
    private groupMembershipService: GroupMembershipService,
  ) { }

  roleRepositories(): Observable<KmdKeyValueOption[]> {
    return this.getUserRepositories().pipe(
      map(roles => {
        return roles.map(indexName => {
          return {
            id: indexName,
            name: this.getAskOurDocMenuText(indexName)
          }
        });
      })
    );
  }

  pagedRoleRepositories(searchTerm: string, page: number, itemsPerPage: number, filterBy?: string): Observable<Paged<KmdKeyValueOption>> {
    const roleRepositories$ = this.roleRepositories().pipe(
      mergeMap(roles => {
        if (roles.length === 0) {
          return of([]);
        }

        return forkJoin(roles.map(
          role => this.groupMembershipService.getGroupByRepository(role.name)
            .pipe(
              map(repo => ({
                ...role,
                isAdmin: repo.isOwner
              } as KmdKeyValueOption
              ))
            )
        ))
      })
    );

    return roleRepositories$.pipe(
      map(roles => {
        roles.sort((a, b) => a.name.localeCompare(b.name));

        let filteredRoles = roles.filter(role => role.name.toLowerCase().includes(searchTerm.toLowerCase()));
        if (filterBy === "Repositories that I Admin") {
          filteredRoles = filteredRoles.filter(role => role.isAdmin);
        }

        const pagedRepositories = filteredRoles.slice(itemsPerPage * page, itemsPerPage * page + itemsPerPage);

        return {
          content: pagedRepositories,
          totalPages: filteredRoles.length / itemsPerPage,
          totalElements: filteredRoles.length,
          size: itemsPerPage,
          number: page,
          last: itemsPerPage * page + itemsPerPage == filteredRoles.length,
          numberOfElements: pagedRepositories.length,
          first: page === 0,
          empty: filteredRoles.length === 0
        };
      })
    );
  }

  private getUserRepositories() {
    return this.oidcIdentityService.userData$.pipe(
      map(userData => this.getAskOurDocsRoles(userData.userData['roles']))
    );
  };

  private getAskOurDocsRoles(roles: string[]) {
    return roles?.filter(role => role.includes(askOurDocsRoles.IndexPrefix)) || [];
  }

  private getAskOurDocMenuText(indexName: string) {
    return indexName.replace(askOurDocsRoles.IndexPrefix, "").replaceAll("_", " ");
  }

  findUserByEmail(email: string): Observable<AskOurDocsSubject> {
    return this.groupMembershipService.getUserByEmail(email);
  };
}
