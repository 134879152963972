import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthenticatedResult, OidcSecurityService } from "angular-auth-oidc-client";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) { };

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((authResult: AuthenticatedResult) => {
        if (authResult.isAuthenticated) {
          this.router.navigate(['/ask-gene']);
          return false;
        }
        return true;
      })
    );
  }
};